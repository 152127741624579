//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import {
  LABEL_FOR_ASSET_DETAILS_YEAR,
  LABEL_FOR_CONDITION,
  LABEL_FOR_SALE_TYPE,
} from '~/constants'
import {
  LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN,
  LABEL_CONDITION,
  LABEL_DOCUMENT_SALE_TYPE,
  LABEL_DOCUMENT_REGISTRATION_PLATE,
  LABEL_DOCUMENT_VIN,
  LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY,
  LABEL_ASSET_TYPE_OPTION_VEHICLE,
  WEB_APP_JOURNEY,
  APPLICATION_ASSETS_VEHICLE,
  LABEL_ASSET_TYPE_STILL_DECIDING,
  SALE_TYPES,
  LABEL_DOCUMENT_VEHICLE,
  VEHICLE_DETAIL_TYPES_I_DONT_KNOW_VALUE,
  APPLICATION_ASSETS_OTHER,
} from '~/constants/applications'
export default {
  data() {
    return {
      LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN,
      LABEL_CONDITION,
      LABEL_DOCUMENT_VIN,
      LABEL_DOCUMENT_REGISTRATION_PLATE,
      LABEL_DOCUMENT_SALE_TYPE,
      APPLICATION_ASSETS_VEHICLE,
      LABEL_FOR_ASSET_DETAILS_YEAR,
      LABEL_FOR_CONDITION,
      LABEL_FOR_SALE_TYPE,
      LABEL_ASSET_TYPE_STILL_DECIDING,
      VEHICLE_DETAIL_TYPES_I_DONT_KNOW_VALUE,
      APPLICATION_ASSETS_OTHER,
    }
  },
  computed: {
    ...mapGetters('applications', ['getApplication']),
    isWebAppJourneyV4() {
      return (
        this.getApplication.webAppJourney ===
        WEB_APP_JOURNEY.STANDARD_V4_VERIFICATION
      )
    },
    isAssetVehicleUnknown() {
      if (this.assetData.vehicle) {
        return (
          this.assetData.vehicle?.assetType ===
          LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY
        )
      }
      return false
    },
    isAssetVehicle() {
      if (this.assetData.vehicle) {
        return this.assetData.vehicle?.assetType === LABEL_DOCUMENT_VEHICLE
      }
      return false
    },
    isSaletypeStillDeciding() {
      return (
        this.assetData?.vehicle?.purchaseSource ===
          LABEL_ASSET_TYPE_STILL_DECIDING && this.isWebAppJourneyV4
      )
    },
    isCertaintyStageStillDecidingV3() {
      return (
        this.assetData?.certaintyStage ===
        VEHICLE_DETAIL_TYPES_I_DONT_KNOW_VALUE
      )
    },
    isAssetTypeStillDeciding() {
      if (this.assetData.vehicle) {
        return (
          this.assetData.vehicle?.assetType === LABEL_ASSET_TYPE_STILL_DECIDING
        )
      }
      return false
    },
    Vehicle() {
      return this.assetData.vehicle?.codeDescription
        ? this.assetData.vehicle?.codeDescription
        : '--'
    },
    vehicleYear() {
      return this.assetData?.vehicle?.year
        ? this.assetData?.vehicle?.year
        : '--'
    },
    vehicleCondition() {
      return this.assetData?.vehicle?.condition
        ? this.assetData?.vehicle?.condition
        : '--'
    },
    vehiclePurchaseSource() {
      return this.assetData?.vehicle?.purchaseSource
        ? this.assetData?.vehicle?.purchaseSource
        : '--'
    },
    vehicleVIN() {
      return this.assetData?.vehicle?.vIN ? this.assetData?.vehicle?.vIN : '--'
    },
    vehicleRegistrationPlate() {
      return this.assetData?.vehicle?.registrationPlate
        ? this.assetData?.vehicle?.registrationPlate
        : '--'
    },
    vehicleDetailsEnteredManually() {
      const { vehicle } = this.assetData
      return (
        vehicle &&
        vehicle.assetType === LABEL_ASSET_TYPE_OPTION_VEHICLE &&
        !!vehicle?.customerNote
      )
    },
  },
  props: {
    assetData: {
      type: Object,
      default: () => ({}),
    },
    isRequirementTab: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    mapSaleTypeValuesToLabel(val) {
      if (val && val !== '--') {
        const saleTypeVal = SALE_TYPES.filter(
          (saleType) => saleType.value === val
        )
        return saleTypeVal[0]?.label
      }
      return '--'
    },
  },
}
