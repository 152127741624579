import { ERROR_MESSAGE_FOR_UNAUTHORIZED_ACCESS } from '~/constants'

/**
 * Ensures given values are truthy, and then joins them with a space, otherwise returning a placeholder if some are falsey.
 * @param {...String} props Properties to check and join
 */
export const displayPropertiesForTable = (...props) => {
  return props.every((p) => !!p || p === '') ? props.join(' ') : '--'
}

export const redirectWithNotification = ({ app, redirect }, page = '/') => {
  app.$notify({
    title: 'Error',
    message: ERROR_MESSAGE_FOR_UNAUTHORIZED_ACCESS,
    type: 'error',
    duration: 3000,
  })
  redirect(page)
}
