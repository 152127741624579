//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    name: {
      type: String,
      default: '',
      required: true,
    },
    dialogClassName: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '30%',
    },
    shouldCloseDialogOnClick: {
      type: Boolean,
      default: false,
    },
    shouldOpenDialog: {
      type: Boolean,
      default: false,
    },
    shouldDestroyDialogOnClose: {
      type: Boolean,
      default: true,
    },
    shouldCloseDialogOnEscape: {
      type: Boolean,
      default: false,
    },
    shouldAlignDialogInCenter: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showDialog: this.shouldOpenDialog,
    }
  },
  beforeDestroy() {
    this.$nuxt.$off(`${this.name}-dialog-toggle`)
  },
  mounted() {
    this.$nuxt.$on(`${this.name}-dialog-toggle`, ({ open }) => {
      this.toggleDialogBehaviour(open)
    })
  },

  methods: {
    toggleDialogBehaviour(shouldShowDialog) {
      this.showDialog = !!shouldShowDialog
    },
  },
}
