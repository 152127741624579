var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-main', {
    staticClass: "bg-white"
  }, [_c('el-row', {
    attrs: {
      "type": "flex",
      "justify": "center"
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "sm": 12,
      "md": 12,
      "lg": 8,
      "xl": 6
    }
  }, [_c('div', {
    staticClass: "accrelerate card-container authentication-wrapper"
  }, [_c('div', [_c('div', {
    staticClass: "flex mb-8"
  }, [_c('h1', [_vm._v("Enter login details")]), _vm._v(" "), _c('nuxt-link', {
    staticClass: "ml-auto text-sm text-primary font-extrabold",
    staticStyle: {
      "margin": "auto 0 auto auto"
    },
    attrs: {
      "to": "/auth/email-login",
      "type": "primary"
    }
  }, [_vm._v("\n              Login with Email\n            ")])], 1), _vm._v(" "), _c('div', [_c('el-form', {
    ref: "loginForm",
    staticClass: "card-view-form",
    attrs: {
      "model": _vm.loginData,
      "rules": _vm.rules,
      "hide-required-asterisk": ""
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Mobile number",
      "prop": "mobile"
    }
  }, [_c('el-input', {
    attrs: {
      "value": _vm.loginData.mobile,
      "inputmode": "numeric",
      "type": "text",
      "placeholder": "04XX-XXX-XXX",
      "round": ""
    },
    on: {
      "input": _vm.handleInput
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.authenticateNumber();
      }
    }
  })], 1), _vm._v(" "), _c('el-button', {
    staticClass: "button-md mb-6",
    attrs: {
      "type": "primary",
      "loading": _vm.isRequestCodeLoading
    },
    on: {
      "click": _vm.authenticateNumber
    }
  }, [_vm._v("\n                Send verification SMS\n              ")]), _vm._v(" "), _c('hr', {
    staticClass: "border-seprator"
  }), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": "SMS verification code",
      "prop": "oneTimePassword"
    }
  }, [_c('message-code', {
    ref: "otpField",
    attrs: {
      "disabled": !_vm.codeRequested
    },
    on: {
      "onComplete": _vm.userLogin
    },
    model: {
      value: _vm.loginData.oneTimePassword,
      callback: function ($$v) {
        _vm.$set(_vm.loginData, "oneTimePassword", $$v);
      },
      expression: "loginData.oneTimePassword"
    }
  })], 1), _vm._v(" "), _c('el-button', {
    staticClass: "button-md",
    attrs: {
      "type": "primary",
      "loading": _vm.isSubmitLoginLoading,
      "disabled": !_vm.codeRequested
    },
    on: {
      "click": _vm.userLogin
    }
  }, [_vm._v("\n                Submit code and login\n              ")])], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "text-center mt-8"
  }, [_c('p', {
    staticClass: "text-sm mb-2 text-light-black"
  }, [_vm._v("For any technical assistance")]), _vm._v(" "), _c('a', {
    staticClass: "text-sm text-primary font-extrabold",
    attrs: {
      "href": ("mailto:tech-support@carclarity.com.au?subject=" + (_vm.LABEL_FOR_CONTACT_SUPPORT_FOR_ACCELERATE)),
      "data-selector": "contact-support",
      "target": "_blank"
    }
  }, [_vm._v("\n              Contact Support\n            ")])])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }