var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    class: _vm.dialogClassName,
    attrs: {
      "width": _vm.width,
      "close-on-click-modal": _vm.shouldCloseDialogOnClick,
      "visible": _vm.showDialog,
      "destroy-on-close": _vm.shouldDestroyDialogOnClose,
      "close-on-press-escape": _vm.shouldCloseDialogOnEscape,
      "center": _vm.shouldAlignDialogInCenter,
      "title": _vm.title
    },
    on: {
      "update:visible": function ($event) {
        _vm.showDialog = $event;
      }
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }