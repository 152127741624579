//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    dropdownType: {
      type: String,
      default: 'primary',
    },
    dropdownIconParentClass: {
      type: String,
      default: 'el-dropdown-link',
    },
    iconClassName: {
      type: String,
      default: 'el-icon-more el-icon--right',
    },
    iconColor: {
      type: String,
      default: 'color-primary',
    },
    dropdownItemClass: {
      type: String,
      default: '',
    },
    dropDownItems: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onDropdownItemClick(item) {
      this.$emit('onDropdownItemClick', item)
    },
  },
}
