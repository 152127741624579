//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  LABEL_ACCOUNTANT_DETAILS,
  LABEL_ACCOUNTANT,
  LABEL_CONTACT_NAME,
  LABEL_PHONE,
  LABEL_EMAIL,
  MAINTAB_DOCS_KEY,
  LABEL_BACK_BUTTON,
  LABEL_SAVE_BUTTON,
  LABEL_SAVE_AND_NEXT,
  MAINTAB_REQUIRE_KEY,
  FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
  SUBTAB_PRIMARY_ADDRESS_KEY,
  LABEL_BUSINESS_STRUCTURE_COMPANY,
  SUBTAB_BUSINESS_SHAREHOLDER_KEY,
  LABEL_BUSINESS_TRUSTEE_TYPE_INDIVIDUAL,
  LABEL_BUSINESS_STRUCTURE_TRUST,
  SUBTAB_BUSINESS_BENEFICIARIES_KEY,
  LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY,
  BUSINESS_DETAILS_TAB_KEY,
  LABEL_ADD_ACCOUNTANT,
  LABEL_REMOVE,
  SUBTAB_BUSINESS_DETAILS_KEY,
  ACCOUNTANT_NAME_KEY,
  ACCOUNTANT_CONTACTNAME_KEY,
  ACCOUNTANT_EMAIL_KEY,
  ACCOUNTANT_PHONE_KEY,
} from '~/constants/applications'
import {
  REGEX_FOR_AUS_PHONE,
  ERROR_MESSAGE_FOR_INVALID_MOBILE,
  ERROR_MESSAGE_FOR_INVALID_EMAIL,
  ERROR_MESSAGE_FOR_EMAIL,
} from '../constants/index'
import { changeRoute } from '~/helpers/changeRoute'

export default {
  name: 'Accountant',
  props: {
    sharedData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      LABEL_ACCOUNTANT_DETAILS,
      LABEL_ACCOUNTANT,
      LABEL_CONTACT_NAME,
      LABEL_PHONE,
      LABEL_EMAIL,
      MAINTAB_DOCS_KEY,
      LABEL_BACK_BUTTON,
      LABEL_SAVE_BUTTON,
      LABEL_SAVE_AND_NEXT,
      LABEL_ADD_ACCOUNTANT,
      MAINTAB_REQUIRE_KEY,
      SUBTAB_BUSINESS_SHAREHOLDER_KEY,
      FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
      BUSINESS_DETAILS_TAB_KEY,
      LABEL_REMOVE,
      accountantData: { ...this.sharedData.businessData.accountant },
      accountForm: {},
      addAccountant: false,
    }
  },
  computed: {
    /* eslint-disable */
    checkAccoundataValue() {
      let result
      if (this.accountantData) {
        for (const [key, value] of Object.entries(this.accountantData)) {
          if (
            [
              ACCOUNTANT_NAME_KEY,
              ACCOUNTANT_CONTACTNAME_KEY,
              ACCOUNTANT_EMAIL_KEY,
              ACCOUNTANT_PHONE_KEY,
            ].includes(key)
          ) {
            if (value === null || value === undefined || value === '') {
              result = false
            } else {
              this.addAccountant = true
              result = true
              break
            }
          }
        }
      }
      return result
    },
    /* eslint-enable */
    rules() {
      return {
        phone: [
          {
            required: false,
            message: ERROR_MESSAGE_FOR_INVALID_MOBILE,
            trigger: 'change',
          },
          {
            pattern: REGEX_FOR_AUS_PHONE,
            message: ERROR_MESSAGE_FOR_INVALID_MOBILE,
            trigger: ['blur', 'change'],
          },
        ],
        email: [
          {
            required: false,
            message: ERROR_MESSAGE_FOR_EMAIL,
            trigger: 'change',
          },
          {
            type: 'email',
            message: ERROR_MESSAGE_FOR_INVALID_EMAIL,
            trigger: ['blur', 'change'],
          },
        ],
      }
    },
    /* eslint-disable */
    accountantForm() {
      this.accountantData = JSON.parse(
        JSON.stringify(this.sharedData.businessData.accountant)
      )
      const { name, contactName, phone, email } = this.accountantData

      this.accountForm = { name, contactName, phone, email }
      return { ...this.accountantData }
    },
  },
  methods: {
    removeData() {
      this.addAccountant = false
      this.accountForm = {}
    },
    async submit(redirect) {
      let isValid = false
      this.$refs.accountForm.validate((result) => {
        return (isValid = result)
      })
      if (!isValid) {
        this.addAccountant = false
        return
      }
      const { id, applicationId } = this.$route.params
      await this.$store.dispatch('applications/updateAccountantDetails', {
        accountantData: this.accountForm,
        id,
        applicationId,
      })

      if (redirect) {
        this.$nuxt.$emit(
          'updateBusinessDetailsTabComponent',
          `${BUSINESS_DETAILS_TAB_KEY}|${SUBTAB_BUSINESS_DETAILS_KEY}`
        )
        this.$nuxt.$emit('changeTab', {
          tab: FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
          subTab: 'primary_personal',
        })
      }
    },
    async back() {
      const { id, applicationId } = this.$route.params
      let previousTab = SUBTAB_PRIMARY_ADDRESS_KEY

      if (
        this.sharedData.businessData.businessStructure ===
        LABEL_BUSINESS_STRUCTURE_COMPANY
      ) {
        previousTab = SUBTAB_BUSINESS_SHAREHOLDER_KEY
      } else if (
        this.sharedData.businessData.businessStructure ===
          LABEL_BUSINESS_STRUCTURE_TRUST &&
        this.sharedData.businessData.trusteeType ===
          LABEL_BUSINESS_TRUSTEE_TYPE_INDIVIDUAL
      ) {
        previousTab = SUBTAB_BUSINESS_BENEFICIARIES_KEY
      } else if (
        this.sharedData.businessData.businessStructure ===
          LABEL_BUSINESS_STRUCTURE_TRUST &&
        this.sharedData.businessData.trusteeType ===
          LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY
      ) {
        previousTab = SUBTAB_BUSINESS_SHAREHOLDER_KEY
      }
      this.$nuxt.$emit(
        'updateBusinessDetailsTabComponent',
        `${BUSINESS_DETAILS_TAB_KEY}|${previousTab}`
      )
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: `/finance/applications/${id}/${applicationId}`,
        query: {
          maintab: BUSINESS_DETAILS_TAB_KEY,
          subtab: previousTab,
        },
      })
    },
  },
}
