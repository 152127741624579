var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex text-center"
  }, [_c('p', {
    staticClass: "text-sm text-grey-dark w-full"
  }, [_vm._v("Copyright © CarClarity " + _vm._s(new Date().getFullYear()))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }