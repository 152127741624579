import { format } from 'date-fns'
import {
  LABEL_LOAN_AMOUNT,
  LABEL_BALLOON_PCT,
  LABEL_PARTNER_FINANCE_REF_FEE,
} from '~/constants/applications'
import { displayPropertiesForTable } from '.'
import {
  getAmountWithOutCurrency,
  getLoanTermYears,
} from '~/helpers/application'

const getVehicleDetails = (vehicleData) => {
  if (
    vehicleData &&
    vehicleData?.makeCode?.length &&
    vehicleData?.familyCode?.length &&
    vehicleData?.code?.length
  ) {
    return displayPropertiesForTable(
      vehicleData?.year,
      vehicleData?.makeDescription,
      vehicleData?.familyDescription,
      vehicleData?.description
    )
  } else if (vehicleData && vehicleData?.description?.length) {
    return vehicleData.description
  } else if (vehicleData && vehicleData?.customerNote) {
    return vehicleData.customerNote
  } else {
    return '--'
  }
}
export const mapVRGForTable = (vrg, forSettlement) => {
  const {
    finance: { application },
    primaryUser,
    owner,
    tradeIn,
    referrerSubaccount,
    referrerUser,
    sFOpportunity,
  } = vrg
  const loanAmount = !isNaN(
    getAmountWithOutCurrency(application, LABEL_LOAN_AMOUNT, vrg)
  )
    ? getAmountWithOutCurrency(application, LABEL_LOAN_AMOUNT, vrg)
    : sFOpportunity?.loanAmount
    ? sFOpportunity?.loanAmount
    : '--'

  const balloonAmount = !isNaN(
    getAmountWithOutCurrency(application, LABEL_BALLOON_PCT, vrg)
  )
    ? getAmountWithOutCurrency(application, LABEL_BALLOON_PCT, vrg)
    : sFOpportunity?.settledBalloonPct
    ? sFOpportunity?.settledBalloonPct
    : '0' + '%'

  const commissionAmount = !isNaN(
    getAmountWithOutCurrency(application, LABEL_PARTNER_FINANCE_REF_FEE, vrg)
  )
    ? getAmountWithOutCurrency(application, LABEL_PARTNER_FINANCE_REF_FEE, vrg)
    : sFOpportunity?.partnerFinanceReferralFee
    ? sFOpportunity?.partnerFinanceReferralFee
    : '0'

  return {
    Id: application.shortId || '--',
    LoanAmount: loanAmount,
    User: displayPropertiesForTable(
      primaryUser.firstName,
      primaryUser.lastName
    ),
    Mobile: primaryUser.mobile || '--',
    Agent: owner?.name || '--',
    Subaccount: referrerSubaccount?.businessName || '--',
    Referrer: displayPropertiesForTable(
      referrerUser?.firstName,
      referrerUser?.lastName
    ),
    Asset: getVehicleDetails(application?.vehicle),
    BusinessName: displayPropertiesForTable(
      application.businessData?.businessName || '--'
    ),
    ABN: displayPropertiesForTable(application.businessData?.aBN || '--'),
    TradeIn: getVehicleDetails(tradeIn?.vehicle),
    LoanTerm: getLoanTermYears(application) || sFOpportunity?.settledLoanTerm,
    createdAt: format(new Date(vrg.createdAt), 'dd-MM-yyyy'),
    updatedAt: format(new Date(vrg.updatedAt), 'dd-MM-yyyy hh:mm aaa'),
    Status: sFOpportunity?.stage || vrg.sFLead?.status || '--',
    ...(forSettlement
      ? {
          Balloon: balloonAmount,
          Commission: commissionAmount,
          LoanAmount: loanAmount,
          LoanTerm:
            getLoanTermYears(application) || sFOpportunity?.settledLoanTerm,
        }
      : {}),
    referralMode: vrg.referralMode,
    notes: application.notes || [],
    __original: vrg,
    agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt:
      application.primaryApplicantData
        .agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt,
  }
}
