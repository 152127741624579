var render = function () {
  var _vm$zeroValueReasonFo;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingDialog,
      expression: "loadingDialog"
    }],
    staticClass: "missingFieldsDiv",
    attrs: {
      "visible": _vm.dialogOpened,
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "show-close": false,
      "destroy-on-close": "",
      "width": "460px",
      "data-selector": "zero-confirmation-dialog"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogOpened = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('el-button', {
          attrs: {
            "type": "text",
            "data-selector": "zc-m-cancel-btn"
          },
          on: {
            "click": function ($event) {
              return _vm.closeDialog();
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))]), _vm._v(" "), _c('el-button', {
          staticStyle: {
            "padding": "6.5px 12px"
          },
          attrs: {
            "type": "primary",
            "data-selector": "zc-m-yes-btn"
          },
          on: {
            "click": function ($event) {
              return _vm.handleClick();
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_YES_PROCEED))])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('h3', {
    staticClass: "title text-sm mt-1"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/warning-employement.svg")
    }
  })]), _vm._v(" "), _vm.title ? _c('h3', {
    staticClass: "title text-sm",
    attrs: {
      "data-selector": "zc-m-title"
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm._v(" "), _c('p', {
    staticClass: "text-sm mt-4 break-normal",
    attrs: {
      "data-selector": "zc-m-subtitle"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.subtitle)
    }
  }), _vm._v(" "), _c('ul', {
    staticClass: "mt-6 missingFields",
    staticStyle: {
      "background": "rgba(19, 181, 232, 0.1)",
      "padding": "0.5rem"
    }
  }, _vm._l(_vm.zeroFieldsData, function (error) {
    return _c('li', {
      key: error.key,
      staticClass: "text-sm mt-2 break-normal",
      staticStyle: {
        "text-align": "left",
        "color": "#303030"
      },
      attrs: {
        "data-selector": 'zero_' + error.split(' ').join('-').toLowerCase()
      }
    }, [_c('b', {
      domProps: {
        "innerHTML": _vm._s(error)
      }
    })]);
  }), 0), _vm._v(" "), _c('el-form', {
    ref: "zeroValueReasonForm",
    staticClass: "mt-6",
    attrs: {
      "model": _vm.zeroValueReasonForm,
      "rules": _vm.rules
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_REASON_FOR_ENTERING_ZERO,
      "prop": "zeroValueReasonType",
      "rules": _vm.rules.zeroValueReasonType,
      "id": "reason-zero-arrow",
      "data-selector": "reason-zero-input-container"
    }
  }, [_c('el-select', {
    staticClass: "text-center",
    attrs: {
      "filterable": "",
      "span": 4,
      "placeholder": "Select"
    },
    model: {
      value: _vm.zeroValueReasonForm.zeroValueReasonType,
      callback: function ($$v) {
        _vm.$set(_vm.zeroValueReasonForm, "zeroValueReasonType", $$v);
      },
      expression: "zeroValueReasonForm.zeroValueReasonType"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "reason-zero-options-container"
    }
  }, _vm._l(_vm.REASON_FOR_ENTERING_ZERO, function (item) {
    return _c('el-option', {
      key: item.value,
      staticStyle: {
        "color": "#1e2d55"
      },
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)])], 1), _vm._v(" "), ((_vm$zeroValueReasonFo = _vm.zeroValueReasonForm) === null || _vm$zeroValueReasonFo === void 0 ? void 0 : _vm$zeroValueReasonFo.zeroValueReasonType) === _vm.LABEL_OTHER ? _c('el-form-item', {
    staticClass: "mt-6",
    attrs: {
      "label": _vm.LABEL_PLEASE_SPECIFY_THE_REASON,
      "rules": _vm.rules.zeroValueReason,
      "prop": "zeroValueReason",
      "data-selector": "reason-zero-other-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text",
      "data-selector": "reason-zero-other-input"
    },
    model: {
      value: _vm.zeroValueReasonForm.zeroValueReason,
      callback: function ($$v) {
        _vm.$set(_vm.zeroValueReasonForm, "zeroValueReason", $$v);
      },
      expression: "zeroValueReasonForm.zeroValueReason"
    }
  })], 1) : _vm._e()], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }