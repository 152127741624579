export const handleCustomerNote = {
  methods: {
    checkAssetType(data) {
      const { assetType } = data
      if (assetType === this.sharedData?.vehicle?.assetType) {
        data.customerNote = this.sharedData?.vehicle?.customerNote
        data.assetDetail = this.sharedData?.vehicle?.assetDetail
      } else {
        data.customerNote = ''
        data.assetDetail = ''
      }
    },
  },
}
