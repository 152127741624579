var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex items-center mb-6",
    class: ({
      'justify-between': _vm.hasButton
    }, _vm.messageType),
    attrs: {
      "data-selector": "notif-container"
    }
  }, [_vm.messageType === 'success' ? [_c('img', {
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": _vm.messageType
    }
  })] : _vm._e(), _vm._v(" "), _vm.messageType === 'info' ? [_c('img', {
    attrs: {
      "src": require("@/assets/icons/info.svg"),
      "alt": _vm.messageType
    }
  })] : _vm._e(), _vm._v(" "), _vm.messageType === 'warning' ? [_c('img', {
    attrs: {
      "src": require("@/assets/icons/alert-triangle.svg"),
      "alt": _vm.messageType
    }
  })] : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "ml-4"
  }, [_vm.title ? _c('span', {
    staticClass: "text-xs font-extrabold approval-sub-message mb-2 inline-block",
    attrs: {
      "data-selector": "notif-title"
    }
  }, [_vm._v(_vm._s(_vm.title) + "\n    ")]) : _vm._e(), _vm._v(" "), _c('p', {
    staticClass: "text-xs approval-sub-message",
    attrs: {
      "data-selector": "notif-description"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.description)
    }
  })]), _vm._v(" "), _vm.hasButton ? _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "type": "primary",
      "data-selector": "notif-button"
    },
    on: {
      "click": function ($event) {
        return _vm.handleClick();
      }
    }
  }, [_vm._v(_vm._s(_vm.buttonLabel))]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }