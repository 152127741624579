var render = function () {
  var _vm$loanDetailsData, _vm$loanDetailsData$l, _vm$loanDetailsData2, _vm$loanDetailsData2$, _vm$loanDetailsData3, _vm$loanDetailsData3$, _vm$loanDetailsData3$2, _this$loanDetailsData, _this$loanDetailsData2, _this$loanDetailsData3, _this$loanDetailsData4, _this$loanDetailsData5, _this$loanDetailsData6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }],
    staticClass: "modal-income loan-details title-hide",
    attrs: {
      "width": "50%",
      "close-on-click-modal": false,
      "visible": _vm.openDialog,
      "destroy-on-close": "",
      "close-on-press-escape": false,
      "data-selector": "loan-details-modal"
    },
    on: {
      "update:visible": function ($event) {
        _vm.openDialog = $event;
      }
    }
  }, [_c('div', [_vm.loanDetailsForm ? [_c('div', {
    staticClass: "application-form"
  }, [_c('el-form', {
    ref: "loanDetailsData",
    staticClass: "laonSettlementPoup",
    attrs: {
      "model": _vm.loanDetailsData,
      "rules": _vm.rules
    }
  }, [(_vm$loanDetailsData = _vm.loanDetailsData) !== null && _vm$loanDetailsData !== void 0 && (_vm$loanDetailsData$l = _vm$loanDetailsData.loanDetails) !== null && _vm$loanDetailsData$l !== void 0 && _vm$loanDetailsData$l.settlement ? _c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('el-card', {
    staticClass: "card-child-p-0 pb-4",
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "ldm-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_LOAN_DETAILS))])])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.settlement.purchasePrice",
      "label": _vm.LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE,
      "data-selector": "ldm-purchase-price-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "ldm-purchase-price-input"
    },
    on: {
      "input": function ($event) {
        return _vm.calculateBallonAmount();
      }
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.purchasePrice,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "purchasePrice", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement.purchasePrice\n                        "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.settlement.depositAmount",
      "label": _vm.LABEL_DOCUMENT_DEPOSIT,
      "data-selector": "ldm-deposit-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "ldm-deposit-input"
    },
    on: {
      "input": function ($event) {
        return _vm.onInput('loanDetails.settlement.depositAmount');
      }
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.depositAmount,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "depositAmount", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement.depositAmount\n                        "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_NAF,
      "prop": "loanDetails.settlement.netAmountFinanced",
      "data-selector": "ldm-naf-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "ldm-naf-input"
    },
    on: {
      "input": function ($event) {
        return _vm.onInput('loanDetails.settlement.netAmountFinanced');
      }
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.netAmountFinanced,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "netAmountFinanced", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement\n                            .netAmountFinanced\n                        "
    }
  })], 1)], 1), _vm._v(" "), ((_vm$loanDetailsData2 = _vm.loanDetailsData) === null || _vm$loanDetailsData2 === void 0 ? void 0 : (_vm$loanDetailsData2$ = _vm$loanDetailsData2.tradeIn) === null || _vm$loanDetailsData2$ === void 0 ? void 0 : _vm$loanDetailsData2$.type) === _vm.APPLICATION_TRADE_IN_VEHICLE ? _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "tradeIn.tradeInAmount",
      "label": ""
    }
  }, [_c('label', {
    attrs: {
      "for": "cheese"
    }
  }, [_vm._v("\n                        " + _vm._s(_vm.LABEL_TRADE_IN_EQUITY) + "\n                        "), _c('b', [_vm._v("(" + _vm._s(_vm.LABEL_DOCUMENT_ESTIMETED_PRICE) + "\n                          "), _c('span', {
    staticStyle: {
      "color": "#49de3e"
    }
  }, [_vm._v(_vm._s(_vm.numberWithCommas((_vm$loanDetailsData3 = _vm.loanDetailsData) === null || _vm$loanDetailsData3 === void 0 ? void 0 : (_vm$loanDetailsData3$ = _vm$loanDetailsData3.tradeIn) === null || _vm$loanDetailsData3$ === void 0 ? void 0 : (_vm$loanDetailsData3$2 = _vm$loanDetailsData3$.latestValuation) === null || _vm$loanDetailsData3$2 === void 0 ? void 0 : _vm$loanDetailsData3$2.tradeInAvgCustomer)))]), _vm._v(")")])]), _vm._v(" "), _c('el-input', {
    attrs: {
      "type": "number",
      "autocomplete": "off"
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.tradeInAmount,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "tradeInAmount", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement.tradeInAmount\n                        "
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_vm.remainingLiability > 0 ? _c('el-form-item', [_c('label', [_vm._v(_vm._s(_vm.LABEL_TRADING_LIABILITIES) + " ")]), _vm._v(" "), _c('el-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "disabled": "",
      "type": "text",
      "autocomplete": "off"
    },
    model: {
      value: _vm.remainingLiability,
      callback: function ($$v) {
        _vm.remainingLiability = $$v;
      },
      expression: "remainingLiability"
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.settlement.loanAmount",
      "label": _vm.LABEL_DOCUMENT_LOAN_AMOUNT,
      "data-selector": "ldm-loan-amount-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "disabled": "",
      "data-selector": "ldm-loan-amount-input"
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.loanAmount,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "loanAmount", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement.loanAmount\n                        "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.settlement.balloonPct",
      "label": _vm.LABEL_DOCUMENT_BALLOON,
      "data-selector": "ldm-balloon-percent-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrictdecimal",
      rawName: "v-restrictdecimal.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "text",
      "autocomplete": "off",
      "data-selector": "ldm-balloon-percent-input"
    },
    on: {
      "input": function ($event) {
        return _vm.calculateBallonAmount();
      }
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.balloonPct,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "balloonPct", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement.balloonPct\n                        "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.settlement.balloonAmount",
      "label": _vm.LABEL_LOAN_BALLOON_DOLLAR,
      "data-selector": "ldm-balloon-amt-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "text",
      "autocomplete": "off",
      "data-selector": "ldm-balloon-amt-input"
    },
    on: {
      "input": function ($event) {
        return _vm.calculateBallonPct();
      }
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.balloonAmount,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "balloonAmount", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement.balloonAmount\n                        "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_LOAN_TREM,
      "prop": "loanDetails.settlement.loanTermYears",
      "data-selector": "ldm-loan-term-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.loanTermYears,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "loanTermYears", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement.loanTermYears\n                        "
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "ldm-loan-term-options-container"
    }
  }, _vm._l(7, function (data) {
    return _c('el-option', {
      key: data,
      attrs: {
        "label": data + ' years',
        "value": data
      }
    });
  }), 1)])], 1)], 1)], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('el-card', {
    staticClass: "card-child-p-0 pb-4",
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "ldm-rates-fees-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_RATES_FEES))])])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_BASE_RATE,
      "prop": "loanDetails.settlement.baseRate",
      "data-selector": "ldm-lender-base-rate-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrictdecimal",
      rawName: "v-restrictdecimal.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "ldm-lender-base-rate-input"
    },
    on: {
      "input": function ($event) {
        return _vm.onInput('loanDetails.settlement.baseRate');
      }
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.baseRate,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "baseRate", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement.baseRate\n                        "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_MONTHLY_REPAYMENT_AMOUNT,
      "prop": "loanDetails.settlement.monthlyRepayment",
      "data-selector": "ldm-monthly-repayment-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "ldm-monthly-repayment-input"
    },
    on: {
      "input": function ($event) {
        return _vm.onInput('loanDetails.settlement.monthlyRepayment');
      }
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.monthlyRepayment,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "monthlyRepayment", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement\n                            .monthlyRepayment\n                        "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_CUSTOMER_RATE,
      "prop": "loanDetails.settlement.customerRate",
      "data-selector": "ldm-customer-rate-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrictdecimal",
      rawName: "v-restrictdecimal.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "ldm-customer-rate-input"
    },
    on: {
      "input": function ($event) {
        return _vm.onInput('loanDetails.settlement.customerRate');
      }
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.customerRate,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "customerRate", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement.customerRate\n                        "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_LENDER_MONTHLY_FEE,
      "prop": "loanDetails.settlement.accountKeepingFee",
      "data-selector": "ldm-account-keeping-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "ldm-account-keeping-input"
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.accountKeepingFee,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "accountKeepingFee", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement\n                            .accountKeepingFee\n                        "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_LENDER_FEE,
      "prop": "loanDetails.settlement.lenderEstablishmentFee",
      "data-selector": "ldm-lender-est-fee-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "ldm-lender-est-fee-input"
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.lenderEstablishmentFee,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "lenderEstablishmentFee", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement\n                            .lenderEstablishmentFee\n                        "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_PSPR_FEE,
      "prop": "loanDetails.settlement.ppsrFee",
      "data-selector": "ldm-ppsr-fee-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "ldm-ppsr-fee-input"
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.ppsrFee,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "ppsrFee", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement.ppsrFee\n                        "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_ORIGINATION_FEE,
      "prop": "loanDetails.settlement.brokerFee",
      "data-selector": "ldm-broker-fee-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "ldm-broker-fee-input"
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.brokerFee,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "brokerFee", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement.brokerFee\n                        "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_PARTNER_REFERRAL_FEE,
      "prop": "loanDetails.settlement.partnerFinanceReferralFee",
      "data-selector": "ldm-partner-fin-ref-fee-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "disabled": "",
      "data-selector": "ldm-partner-fin-ref-fee-input"
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.partnerFinanceReferralFee,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "partnerFinanceReferralFee", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement\n                            .partnerFinanceReferralFee\n                        "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_LENDING_COMMISSION,
      "prop": "loanDetails.settlement.lendingCommision",
      "data-selector": "ldm-lending-commission-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "ldm-lending-commission-input"
    },
    model: {
      value: _vm.loanDetailsData.loanDetails.settlement.lendingCommision,
      callback: function ($$v) {
        _vm.$set(_vm.loanDetailsData.loanDetails.settlement, "lendingCommision", $$v);
      },
      expression: "\n                          loanDetailsData.loanDetails.settlement\n                            .lendingCommision\n                        "
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "mb-0 border-t border-light-grey pt-4 child-w-full flex items-center"
  }, [_c('el-button', {
    staticClass: "mr-auto",
    attrs: {
      "plain": "",
      "type": "text",
      "data-selector": "ldm-cancel-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CANCEL_BUTTON))]), _vm._v(" "), _c('el-button', {
    staticClass: "float-right",
    attrs: {
      "type": "primary",
      "loading": _vm.btnLoading,
      "data-selector": "ldm-update-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.checkZeroValFields();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_UPDATE) + "\n              ")])], 1)], 1)], 1)] : _vm._e()], 2)]), _vm._v(" "), _c('ZeroConfirmationDialog', {
    attrs: {
      "dialog-opened": _vm.openZeroConfirmationDialog,
      "title": _vm.LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_TITLE,
      "subtitle": _vm.LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_DISCRIPTION,
      "zero-fields-data": _vm.allZeroValueFieldsData,
      "submission-type": _vm.MAINTAB_LOANDETAILS_SETTLEMENT_LABEL,
      "zero-value-reason": (_this$loanDetailsData = this.loanDetailsData) === null || _this$loanDetailsData === void 0 ? void 0 : (_this$loanDetailsData2 = _this$loanDetailsData.loanDetails) === null || _this$loanDetailsData2 === void 0 ? void 0 : (_this$loanDetailsData3 = _this$loanDetailsData2.submission) === null || _this$loanDetailsData3 === void 0 ? void 0 : _this$loanDetailsData3.zeroValueReason,
      "zero-value-reason-type": (_this$loanDetailsData4 = this.loanDetailsData) === null || _this$loanDetailsData4 === void 0 ? void 0 : (_this$loanDetailsData5 = _this$loanDetailsData4.loanDetails) === null || _this$loanDetailsData5 === void 0 ? void 0 : (_this$loanDetailsData6 = _this$loanDetailsData5.submission) === null || _this$loanDetailsData6 === void 0 ? void 0 : _this$loanDetailsData6.zeroValueReasonType
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }