export default {
  getFinanceApplicationDetailsByVrgAndApplicationIdAPI({
    vehicleRequestGroupId,
    applicationId,
    $axios,
  }) {
    const url = `/vehicle-request-groups/${vehicleRequestGroupId}/finance/applications/${applicationId}`
    return $axios.get(url)
  },
  getUserDetailsByIdAPI({ userId, $axios }) {
    const url = `/user/${userId}`
    return $axios.get(url)
  },
  getAccountsAPI({
    isLender,
    isInsurer,
    isWarrantyProvider,
    isPartner,
    skip = 0,
    $axios,
  }) {
    let url = `/accounts?`
    if (isLender) {
      url += '&isLender=true'
    }
    if (isInsurer) {
      url += '&isInsurer=true'
    }
    if (isWarrantyProvider) {
      url += '&isWarrantyProvider=true'
    }
    if (isPartner) {
      url += '&isPartner=true'
    }
    if (skip !== null && skip !== undefined) {
      url += `&skip=${skip}`
    }
    return $axios.get(url)
  },
  getVehicleDetailsById({ vehicleId, $axios }) {
    const url = `/vehicles/${vehicleId}`
    return $axios.get(url)
  },
  getVehicleRequestGroupByIdAPI({ vehicleRequestGroupId, $axios }) {
    const url = `/vehicle-request-groups/${vehicleRequestGroupId}`
    return $axios.get(url)
  },
  updateFinanceApplicationDetailsByVrgAndApplicationIdAPI({
    vehicleRequestGroupId,
    applicationId,
    application,
    $axios,
  }) {
    const url = `/vehicle-request-groups/${vehicleRequestGroupId}/finance/applications/${applicationId}`
    return $axios.patch(url, { ...application })
  },
  updateVehicleDetailsByIdAPI({ vehicleId, vehicleData, $axios }) {
    const url = `/vehicles/${vehicleId}`
    return $axios.patch(url, vehicleData)
  },
  getAllMakesAPI({ $axios }) {
    const url = 'vehicles-meta-data/makes'
    return $axios.get(url)
  },
  getAllModelsByMakeCodeAPI({ makeCode, $axios }) {
    const url = `vehicles-meta-data/models?makeCode=${makeCode}`
    return $axios.get(url)
  },
  getAllYearsByMakeAndFamilyCodeAPI({ makeCode, familyCode, $axios }) {
    const url = `vehicles-meta-data/years?makeCode=${makeCode}&familyCode=${familyCode}`
    return $axios.get(url)
  },
  getAllModelTypesAPI({ makeCode, familyCode, year, $axios }) {
    const url = `vehicles-meta-data/vehicles?makeCode=${makeCode}&familyCode=${familyCode}&year=${year}`
    return $axios.get(url)
  },
  upsertUserAPI({ mobile, firstName, lastName, email, $axios }) {
    const url = `/user/upsert`
    const payload = {
      mobile,
      firstName,
      lastName,
      email,
    }
    return $axios.post(url, payload)
  },
}
