//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LABEL_FOR_CLOSE } from '~/constants/applications'
import { VEHICLE_TYPE_ASSET } from '~/constants/vehicles'
import { toCurrency } from '@/helpers/currency'
import { axiosErrorMessageExtractor } from '~/utils/error'
import {
  LABEL_RRP,
  LABEL_FOR_MAKE,
  LABEL_FOR_MODEL,
  LABEL_FOR_MODEL_TYPE,
  LABEL_FOR_ASSET_DETAILS_YEAR,
  LABEL_RRP_DESCRIPTION,
  LABEL_UNKNOWN_PRICE,
  ERROR_MESSAGE_FOR_TYPE,
  ERROR_MESSAGE_FOR_MAKE,
  ERROR_MESSAGE_FOR_MODEL,
  ERROR_MESSAGE_FOR_MODEL_YEAR,
  ERROR_MESSAGE_FOR_MODEL_TYPE,
} from '~/constants'
import { SIDEBAR_MENU_ITEM_RRP } from '~/constants/sidebar'
export default {
  props: {
    shouldShowVehiclerrpDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toCurrency,
      LABEL_FOR_MAKE,
      LABEL_FOR_MODEL,
      LABEL_FOR_CLOSE,
      LABEL_RRP,
      SIDEBAR_MENU_ITEM_RRP,
      LABEL_FOR_MODEL_TYPE,
      LABEL_FOR_ASSET_DETAILS_YEAR,
      LABEL_RRP_DESCRIPTION,
      LABEL_UNKNOWN_PRICE,
      VEHICLE_TYPE_ASSET,
      make: [],
      model_options: [],
      years_options: [],
      model_types_options: [],
      isDialogLoading: false,
      vehicleData: {
        makeCode: '',
        familyCode: '',
        year: '',
        codeDescription: '',
        tradeInAvgCustomer: '',
        imgUrl: '',
      },
      rules: {
        assetType: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
        makeCode: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MAKE,
            trigger: ['blur', 'change'],
          },
        ],
        familyCode: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL,
            trigger: ['blur', 'change'],
          },
        ],
        year: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_YEAR,
            trigger: ['blur', 'change'],
          },
        ],
        codeDescription: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  created() {
    this.setMakes()
  },
  mounted() {
    this.$nuxt.$on('close-loader', () => {
      this.isDialogLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },
  methods: {
    numberWithCommas(x) {
      return toCurrency(x)
    },
    async setMakes() {
      const { data = [] } = await this.$store.dispatch(
        `applications/getAllMakes`
      )
      this.make = data
    },
    closeVehiclerrpDialog() {
      //reset vehicleData fields
      this.vehicleData = {
        makeCode: '',
        familyCode: '',
        year: '',
        codeDescription: '',
        tradeInAvgCustomer: '',
        imgUrl: '',
      }
      setTimeout(() => {
        this.$refs.vehicleForm.clearValidate()
      }, 50)
      this.$emit('openCloseVehiclerrpDialog')
    },
    async setModels(shouldRemoveCurrentValue = true) {
      if (shouldRemoveCurrentValue) {
        this.vehicleData.familyCode = ''
        this.vehicleData.year = ''
        this.vehicleData.codeDescription = ''
        this.vehicleData.tradeInAvgCustomer = ''
        this.vehicleData.imgUrl = ''
      }

      const response = await this.$store.dispatch(`applications/getAllModels`, {
        makeCode: this.vehicleData.makeCode,
      })
      this.model_options = response.data
    },
    async setYears(shouldRemoveCurrentValue = true) {
      if (shouldRemoveCurrentValue) {
        this.vehicleData.year = ''
        this.vehicleData.tradeInAvgCustomer = ''
        this.vehicleData.imgUrl = ''
      }

      const response = await this.$store.dispatch(`applications/getAllYears`, {
        makeCode: this.vehicleData.makeCode,
        familyCode: this.vehicleData.familyCode,
      })
      this.years_options = response.data
    },
    async setModelTypes(shouldRemoveCurrentValue = true) {
      if (shouldRemoveCurrentValue) {
        this.vehicleData.codeDescription = ''
        this.vehicleData.tradeInAvgCustomer = ''
        this.vehicleData.imgUrl = ''
      }

      const response = await this.$store.dispatch(
        `applications/getAllModelTypes`,
        {
          makeCode: this.vehicleData.makeCode,
          familyCode: this.vehicleData.familyCode,
          year: this.vehicleData.year,
        }
      )
      this.model_types_options = response.data
    },
    async addVehicleDescription(desc) {
      try {
        const event = this.model_types_options.find(
          (x) => x.description === desc
        )
        if (event) {
          this.isDialogLoading = true
          this.vehicleData.codeDescription = event?.description
          const payload = {
            code: event?.code,
            codeDescription: event?.description,
            familyCode: event?.familyCode,
            familyDescription: event?.familyCode,
            makeCode: event?.makeCode,
            type: VEHICLE_TYPE_ASSET,
            year: event?.year,
            yearDescription: event?.year,
          }

          const response = await this.$store.dispatch(
            `applications/getCarEstimatePrice`,
            { payload }
          )
          if (response?.status === 200 && response?.data) {
            this.vehicleData.tradeInAvgCustomer =
              response?.data?.latestValuation?.tradeInAvgCustomer ||
              this.vehicleData.tradeInAvgCustomer
            const imageRequest = await this.$store.dispatch(
              `applications/getCarImage`,
              { makeCode: event?.code }
            )
            if (imageRequest?.status === 200 && imageRequest?.data?.length) {
              this.vehicleData.imgUrl = imageRequest?.data[0]?.url
            }
          }
        }
      } catch (err) {
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.isDialogLoading = false
      }
    },
  },
}
