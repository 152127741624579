var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border border-light-purple px-4 py-4 mb-6 rounded-2"
  }, [_c('div', {
    staticClass: "flex items-center mb-4"
  }, [_c('div', {
    staticClass: "el-row"
  }, [_vm.filesUploaded.length ? _c('img', {
    staticClass: "mr-3 check-icon",
    attrs: {
      "src": require("@/assets/icons/checkmark.svg"),
      "data-selector": "tile-icon-check"
    }
  }) : _c('img', {
    staticClass: "mr-3 alert-icon",
    attrs: {
      "src": require("@/assets/icons/alert-docs.svg"),
      "data-selector": "tile-icon-alert"
    }
  }), _vm._v(" "), _vm.documentPurpose === _vm.TITLE_FOR_OTHER ? _c('b', {
    attrs: {
      "data-selector": "tile-title"
    }
  }, [_vm._v(_vm._s(_vm.documentExtraName))]) : _c('b', {
    attrs: {
      "data-selector": "tile-title"
    }
  }, [_vm._v(_vm._s(_vm.getMappedNameWithPurpose(_vm.documentName)))])]), _vm._v(" "), _vm.isTypeAdmin ? _c('a', {
    staticClass: "ml-auto edit-document",
    attrs: {
      "href": "javascript:void(0)",
      "data-selector": "tile-edit-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.editDocuments();
      }
    }
  }, [_c('img', {
    staticClass: "ml-auto edit-document",
    attrs: {
      "src": require("assets/icons/edit.svg"),
      "alt": "Warning"
    }
  })]) : _vm._e(), _vm._v(" "), _vm.shouldShowDeleteButton && _vm.documentPurpose != _vm.TITLE_FOR_OTHER ? _c('i', {
    staticClass: "ml-5 cursor-pointer edit-document",
    attrs: {
      "data-selector": "tile-delete-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.openConfirmationDialog(_vm.documentName, _vm.ALL);
      }
    }
  }, [_c('img', {
    staticClass: "edit-document",
    attrs: {
      "src": require("@/assets/icons/delete.svg"),
      "alt": "delete"
    }
  })]) : _vm._e(), _vm._v(" "), _vm.shouldShowDeleteButton && _vm.documentPurpose === _vm.TITLE_FOR_OTHER ? _c('i', {
    staticClass: "ml-5 cursor-pointer edit-document",
    attrs: {
      "data-selector": "tile-delete-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.openConfirmationDialog(_vm.documentExtraName, _vm.ALL);
      }
    }
  }, [_c('img', {
    staticClass: "edit-document",
    attrs: {
      "src": require("@/assets/icons/delete.svg"),
      "alt": "delete"
    }
  })]) : _vm._e()]), _vm._v(" "), _vm.documentRequestedDate && !_vm.filesUploaded.length ? _c('p', {
    staticClass: "text-xs text-grey-dark my-4",
    attrs: {
      "data-selector": "action-timestamp-container"
    }
  }, [_c('span', {
    staticClass: "font-bolder mr-2",
    attrs: {
      "data-selector": "action-type"
    }
  }, [_vm._v(_vm._s(_vm.documentRequestedViaEmail ? _vm.LABEL_REQUESTED_VIA_EMAIL : _vm.LABEL_ADDED_AT) + "\n    ")]), _vm._v("\n    " + _vm._s(_vm.format(new Date(_vm.documentRequestedDate), 'dd-MM-yyyy hh:mm aaa')))]) : _vm._e(), _vm._v(" "), _vm.documentUploadedDate && _vm.filesUploaded.length > 0 ? _c('p', {
    staticClass: "text-xs text-grey-dark my-4",
    attrs: {
      "data-selector": "action-timestamp-container"
    }
  }, [_c('span', {
    staticClass: "font-bolder mr-2",
    attrs: {
      "data-selector": "action-type"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_UPLOADED_AT) + "\n    ")]), _vm._v("\n    " + _vm._s(_vm.format(new Date(_vm.documentUploadedDate), 'dd-MM-yyyy hh:mm aaa')))]) : _vm._e(), _vm._v(" "), !_vm.filesToUpload.length ? _c('el-upload', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    ref: "upload",
    staticClass: "upload-demo mb-4",
    attrs: {
      "drag": "",
      "show-file-list": false,
      "action": _vm.pathToUpdate,
      "auto-upload": false,
      "file-list": _vm.filesUploaded,
      "disabled": _vm.isApplicationSettled,
      "multiple": "",
      "on-change": _vm.newUploadDocuments,
      "data-selector": "upload-trigger"
    }
  }, [_c('i', {
    staticClass: "el-icon-upload"
  }), _vm._v(" "), _c('div', {
    staticClass: "el-upload__text"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_DROP) + "\n      "), _c('em', {
    staticClass: "documentname"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_UPLOAD))])])]) : _vm._e(), _vm._v(" "), _vm.filesToUpload.length ? _c('div', _vm._l(_vm.filesToUpload, function (file, index) {
    return _c('div', {
      key: index
    }, [_c('span', {
      staticClass: "text-primary"
    }, [_vm._v(_vm._s(file.name))]), _vm._v(" "), _c('div', [_c('el-progress', {
      attrs: {
        "percentage": file.percentage
      }
    })], 1)]);
  }), 0) : _vm._e(), _vm._v(" "), _vm.filesUploaded.length > 0 ? _c('div', {
    attrs: {
      "data-selector": "uploaded-files-container"
    }
  }, _vm._l(_vm.filesUploaded, function (file, ind) {
    return _c('div', {
      key: ind,
      staticClass: "flex mb-2 items-center justify-left"
    }, [file !== null && file !== void 0 && file.url ? _c('a', {
      staticClass: "text-primary mb-2 filename documentname",
      staticStyle: {
        "color": "#7100da"
      },
      attrs: {
        "href": ((_vm.baseUrl) + "/assets/fetch-file?path=" + (file.objectKey) + "&token=" + (_vm.token)),
        "rel": "noopener noreferrer",
        "target": "'_blank'",
        "data-selector": 'uploaded-file-name_' + ind
      }
    }, [_vm._v(_vm._s(file === null || file === void 0 ? void 0 : file.filename))]) : _vm._e(), _vm._v(" "), _c('el-row', {
      staticClass: "mt-4 mb-3 justify-end flex"
    }, [_vm.isPDF(file) ? _c('a', {
      staticClass: "w-auto text-primary cursor-pointer",
      attrs: {
        "href": ((_vm.baseUrl) + "/assets/fetch-file?path=" + (file.objectKey) + "&token=" + (_vm.token)),
        "rel": "noopener noreferrer",
        "target": "'_blank'",
        "data-selector": 'tile-download-file_' + ind
      }
    }, [_c('img', {
      staticClass: "edit-document",
      staticStyle: {
        "margin-top": "-2px"
      },
      attrs: {
        "src": require("@/assets/icons/download.svg"),
        "alt": "download"
      }
    })]) : _c('a', {
      staticClass: "w-auto text-primary cursor-pointer",
      attrs: {
        "data-selector": 'tile-download-file_' + ind
      },
      on: {
        "click": function ($event) {
          return _vm.download(file);
        }
      }
    }, [_c('img', {
      staticClass: "edit-document",
      staticStyle: {
        "margin-top": "-2px"
      },
      attrs: {
        "src": require("@/assets/icons/download.svg"),
        "alt": "download"
      }
    })]), _vm._v(" "), file !== null && file !== void 0 && file.id && _vm.shouldShowDeleteButton ? _c('i', {
      staticClass: "ml-4 w-auto cursor-pointer",
      attrs: {
        "data-selector": 'tile-delete-file_' + ind
      },
      on: {
        "click": function ($event) {
          return _vm.openConfirmationDialog(file);
        }
      }
    }, [_c('img', {
      staticClass: "edit-document",
      staticStyle: {
        "margin-top": "-2px"
      },
      attrs: {
        "src": require("@/assets/icons/delete.svg"),
        "alt": "delete"
      }
    })]) : _vm._e()])], 1);
  }), 0) : _vm._e(), _vm._v(" "), _vm.openChangeDocumentTypeDialog ? _c('div', [_c('ChangeDocumentTypeDialog', _vm._b({
    attrs: {
      "title": _vm.LABEL_CHANGE_DOCUMENT_TYPE,
      "subtitle": _vm.DESCRIPTION_FOR_CHANGE_DOCUMENT_TYPE,
      "reason": _vm.LABEL_NEW_DOCUMENT_TYPE,
      "dialog-opened": _vm.openChangeDocumentTypeDialog
    }
  }, 'ChangeDocumentTypeDialog', Object.assign({}, _vm.$props), false))], 1) : _vm._e(), _vm._v(" "), _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isDialogLoading,
      expression: "isDialogLoading"
    }],
    staticClass: "removeDocument",
    attrs: {
      "visible": _vm.dialogOpened,
      "width": "460px",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "destroy-on-close": "",
      "show-close": false,
      "data-selector": "delete-document-modal"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogOpened = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('el-button', {
          attrs: {
            "type": "text",
            "data-selector": "dd-m-cancel-btn"
          },
          on: {
            "click": function ($event) {
              return _vm.openCloseConfirmationDialog();
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))]), _vm._v(" "), _c('el-button', {
          staticStyle: {
            "padding": "6.5px 12px"
          },
          attrs: {
            "type": "primary",
            "data-selector": "dd-m-yes-btn"
          },
          on: {
            "click": function ($event) {
              return _vm.confirmDelete();
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_YES_PROCEED))])];
      },
      proxy: true
    }])
  }, [_c('el-form', [_c('div', {
    staticClass: "text-center"
  }, [_c('h3', {
    staticClass: "title text-sm"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/warning-employement.svg")
    }
  })]), _vm._v(" "), _c('h3', {
    staticClass: "font-bolder title text-sm mt-2",
    attrs: {
      "data-selector": "dd-modal-title"
    }
  }, [_vm._v(_vm._s(_vm.isRemoveDialogForAll ? _vm.LABEL_DELETE_DOCUMENT_TYPE : _vm.LABEL_DELETE_DOCUMENT))]), _vm._v(" "), !_vm.isRemoveDialogForAll ? _c('p', {
    staticClass: "description text-sm mt-4 break-word"
  }, [_vm._v(_vm._s(_vm.LABEL_DELETE_DOCUMENT_DESCRIPTION) + "\n          "), _c('b', {
    attrs: {
      "data-selector": "dd-filename"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.selectedDocumentForRemove.filename) + "\n          ")]), _vm._v("\n          in\n          "), _c('b', {
    attrs: {
      "data-selector": "dd-doc-name"
    }
  }, [_vm._v(_vm._s(_vm.getMappedNameWithPurpose(_vm.selectedDocumentForRemove.name)) + "?")]), _vm.dialogFor === _vm.LABEL_DOCUMENT_PRIMARY || _vm.dialogFor === _vm.LABEL_SHARED ? _c('span', [_vm._v(_vm._s(_vm.LABEL_DELETE_DOCUMENT_CUSTOMER_DASHBOARD))]) : _vm._e()]) : _vm._e(), _vm._v(" "), _vm.isRemoveDialogForAll && (_vm.dialogFor === _vm.LABEL_DOCUMENT_PRIMARY || _vm.dialogFor === _vm.LABEL_SHARED) ? _c('p', {
    staticClass: "description text-sm mt-4 break-normal"
  }, [_vm._v("\n          " + _vm._s(_vm.LABEL_DELETE_DOCUMENT_DESCRIPTION)), _c('b', {
    attrs: {
      "data-selector": "dd-doc-name"
    }
  }, [_vm._v(_vm._s(_vm.getMappedNameWithPurpose(_vm.removeDocumentName)))]), _vm._v("?\n          " + _vm._s(_vm.LABEL_DELETE_DOCUMENT_TYPE_DESCRIPTION_ALSO_CUSTOMER_DASHBOARD) + "\n        ")]) : _vm.isRemoveDialogForAll ? _c('p', {
    staticClass: "description text-sm mt-4 break-normal"
  }, [_vm._v("\n          " + _vm._s(_vm.LABEL_DELETE_DOCUMENT_DESCRIPTION)), _c('b', {
    attrs: {
      "data-selector": "dd-doc-name"
    }
  }, [_vm._v(_vm._s(_vm.getMappedNameWithPurpose(_vm.removeDocumentName)))]), _vm._v("?\n          " + _vm._s(_vm.LABEL_DELETE_DOCUMENT_TYPE_DESCRIPTION) + "\n        ")]) : _vm._e(), _vm._v(" "), _vm.dialogFor === _vm.LABEL_DOCUMENT_PRIMARY || _vm.dialogFor === _vm.LABEL_SHARED ? _c('p', {
    staticClass: "description text-sm mt-2"
  }, [_vm._v(_vm._s(_vm.LABEL_CONTINUE))]) : _vm._e()])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }