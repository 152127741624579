import { ACCELERATE, FUEL } from '~/constants/applications'
import Login from '~/pages/login.vue'
import EmailLogin from '~/pages/login-email.vue'

export const LOGIN_ROUTE_FOR_ACCELERATE = '/login'
export const LOGIN_ROUTE_FOR_FUEL = '/login-email'
export const COMMON_LOGIN_ROUTE = '/auth/login'
export const LOGIN_ROUTE_FOR_EMAIL_LOGIN_ACCELERATE = '/auth/email-login'

export const URL_NAME_FOR_ACCELERATE = 'login'
export const URL_NAME_FOR_ACCELERATE_EMAIL_LOGIN = 'auth-email-login'
export const URL_NAME_FOR_VERIFY_INVITATION = 'auth-verify-invitation'
export const URL_NAME_FOR_FORGOT_PASSWORD = 'email-password-reset'
export const URL_NAME_FOR_FORGOT_PASSWORD_ACCELERATE =
  'email-password-reset-accelerate'
export const URL_NAME_FOR_AUTH_VERIFY_RECOVERY = 'auth-verify-recovery'
export const URL_NAME_FOR_ESIGNATURE_ROUTE =
  'vrgId-finance-applications-applicationId-applicants-applicantId-esignatures'

export const PUBLIC_ROUTES = [
  URL_NAME_FOR_ACCELERATE,
  URL_NAME_FOR_VERIFY_INVITATION,
  URL_NAME_FOR_FORGOT_PASSWORD,
  URL_NAME_FOR_FORGOT_PASSWORD_ACCELERATE,
  URL_NAME_FOR_ACCELERATE_EMAIL_LOGIN,
  URL_NAME_FOR_ESIGNATURE_ROUTE,
  URL_NAME_FOR_AUTH_VERIFY_RECOVERY,
]

export const isAccelerate = () => {
  return window[ACCELERATE] === true
}

export const isFuel = () => {
  return window[FUEL] === true
}

export const getLoginRoute = () => {
  if (window[ACCELERATE]) {
    return LOGIN_ROUTE_FOR_ACCELERATE
  }
  return LOGIN_ROUTE_FOR_FUEL
}

export const getLoginComponent = () => {
  if (window[ACCELERATE]) {
    return Login
  }
  return EmailLogin
}
