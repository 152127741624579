export const ID_TYPE_DRIVER_LICENCE = 'Driver Licence'
export const ID_TYPE_PASSPORT = 'Passport'

export const ACCOUNT_CONTACT_ROLE_PARTNER_REFERRER = 'Partner Referrer'
export const ACCOUNT_CONTACT_ROLE_PARTNER_ADMINISTRATOR =
  'Partner Administrator'
export const ACCOUNT_CONTACT_ROLE_DIRECTOR = 'Director'
export const LABEL_TYPE_DRIVERS_LICENCE = "Driver's licence"
export const ACCOUNT_CONTACT_ROLES = [
  ACCOUNT_CONTACT_ROLE_PARTNER_REFERRER,
  ACCOUNT_CONTACT_ROLE_PARTNER_ADMINISTRATOR,
  ACCOUNT_CONTACT_ROLE_DIRECTOR,
]
