import {
  DEFAULT_APP_TITLE,
  APPLICATION_ROUTE_NAME,
  APPLICATION_TITLE,
} from '~/constants'
export default {
  head() {
    const titleFromConfig = this.headConfig?.title
    return {
      title: `${
        titleFromConfig
          ? `${titleFromConfig}`
          : this.$route.name === APPLICATION_ROUTE_NAME
          ? APPLICATION_TITLE
          : DEFAULT_APP_TITLE
      }`,
    }
  },
}
