export default {
  startTableLoading(state) {
    state.isTableLoading = true
  },
  stopTableLoading(state) {
    state.isTableLoading = false
  },
  setTableSettings(
    state,
    { totalDocs, page, totalPages, limit, pagingCounter }
  ) {
    state.tableSettings.totalDocs = totalDocs
    state.tableSettings.page = page
    state.tableSettings.totalPages = totalPages
    state.tableSettings.limit = limit
    state.tableSettings.pagingCounter = pagingCounter
  },
  setIpAddresses(state, ips) {
    state.ipAddresses = ips
  },
}
