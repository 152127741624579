var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('div', {
    staticClass: "border border-primary px-4 py-4 mb-4 rounded-2"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_vm.fileList.length <= 0 ? _c('img', {
    staticClass: "mr-3",
    attrs: {
      "src": require("@/assets/icons/alert-docs.svg")
    }
  }) : _vm._e(), _vm._v(" "), _vm.fileList.length > 0 ? _c('i', {
    staticClass: "el-icon-check text-success"
  }) : _vm._e(), _vm._v(" "), _c('b', {
    staticClass: "pl-2 mt-2"
  }, [_vm._v(_vm._s(_vm.LABEL_BANK_STATEMENT_SECURE))]), _vm._v(" "), !_vm.isTypeAgent ? _c('i', {
    staticClass: "ml-auto cursor-pointer delete-icon",
    on: {
      "click": function ($event) {
        return _vm.removeUpload(_vm.LABEL_BANK_STATEMENT_SECURE);
      }
    }
  }, [_c('img', {
    staticClass: "delete-icon",
    attrs: {
      "src": require("@/assets/icons/delete.svg"),
      "alt": "delete"
    }
  })]) : _vm._e()]), _vm._v(" "), _vm.fileList.length <= 0 ? _c('p', {
    staticClass: "text-xs text-grey-dark my-4"
  }, [_vm._v(_vm._s(_vm.LABEL_REQUESTED_AT) + "\n      " + _vm._s(_vm.format(new Date(_vm.bankStatementRequestedAt), 'dd/MM/yyyy hh:mm aaa')))]) : _vm._e(), _vm._v(" "), _vm.fileList.length > 0 ? _c('p', {
    staticClass: "text-xs text-grey-dark my-4"
  }, [_vm._v(_vm._s(_vm.LABEL_UPLOADED_AT) + "\n      " + _vm._s(_vm.format(new Date(_vm.bankStatementRequestedAt), 'dd/MM/yyyy hh:mm aaa')))]) : _vm._e(), _vm._v(" "), _c('el-upload', {
    ref: "upload",
    staticClass: "upload-demo",
    attrs: {
      "drag": "",
      "action": _vm.pathtoupdate,
      "show-file-list": false,
      "on-remove": _vm.handleRemove,
      "file-list": _vm.fileList,
      "on-preview": _vm.previewFile,
      "on-change": _vm.uploadBankStatements,
      "multiple": "",
      "auto-upload": false
    }
  }, [_c('i', {
    staticClass: "el-icon-upload"
  }), _vm._v(" "), _c('div', {
    staticClass: "el-upload__text"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_DROP) + " "), _c('em', [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_UPLOAD))])])]), _vm._v(" "), _vm.showProgress ? _c('p', {
    staticClass: "xs mt-2"
  }, [_vm._v(_vm._s(_vm.fileNameBeforeUpload))]) : _vm._e(), _vm._v(" "), _vm.showProgress ? _c('el-progress', {
    attrs: {
      "percentage": _vm.progressWidth
    }
  }) : _vm._e(), _vm._v(" "), _vm.fileList.length > 0 ? _c('div', _vm._l(_vm.fileList, function (file, ind) {
    return _c('div', {
      key: ind,
      staticClass: "flex mb-2 items-center justify-left"
    }, [file !== null && file !== void 0 && file.url ? _c('a', {
      staticClass: "text-primary mb-2",
      staticStyle: {
        "color": "#7100da"
      },
      attrs: {
        "href": ((_vm.baseUrl) + "/assets/fetch-file?path=" + (file.objectKey) + "&token=" + (_vm.token)),
        "rel": "noopener noreferrer",
        "target": "'_blank'"
      }
    }, [_vm._v(_vm._s(file === null || file === void 0 ? void 0 : file.name))]) : _c('a', {
      staticClass: "text-primary",
      attrs: {
        "href": file === null || file === void 0 ? void 0 : file.url,
        "target": "_blank"
      }
    }, [_vm._v("\n          " + _vm._s(file === null || file === void 0 ? void 0 : file.name) + "\n        ")]), _vm._v(" "), _c('el-row', {
      staticClass: "mt-4 mb-3 justify-end flex"
    }, [_vm.checkFileType(file) ? _c('a', {
      staticClass: "el-icon-download w-auto text-primary cursor-pointer",
      attrs: {
        "href": ((_vm.baseUrl) + "/assets/fetch-file?path=" + (file.objectKey) + "&token=" + (_vm.token)),
        "rel": "noopener noreferrer",
        "target": "'_blank'"
      }
    }) : _c('a', {
      staticClass: "el-icon-download w-auto text-primary cursor-pointer",
      on: {
        "click": function ($event) {
          return _vm.download(file);
        }
      }
    }), _vm._v(" "), !_vm.isTypeAgent ? _c('i', {
      staticClass: "ml-auto cursor-pointer delete-icon",
      on: {
        "click": function ($event) {
          return _vm.handleRemove(file);
        }
      }
    }, [_c('img', {
      staticClass: "delete-icon",
      attrs: {
        "src": require("@/assets/icons/delete.svg"),
        "alt": "delete"
      }
    })]) : _vm._e()])], 1);
  }), 0) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }