//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ERROR_MESSAGE_FOR_PURCHASE_PRICE,
  ERROR_MESSAGE_FOR_DEPOSIT,
  ERROR_MESSAGE_FOR_TRADE_IN,
  ERROR_MESSAGE_FOR_LOAN_TERM,
  ERROR_MESSAGE_FOR_BALLOON,
  ERROR_MESSAGE_FOR_BALLOON_ONLY,
  ERROR_MESSAGE_FOR_BUDGET_MONTHLY_REPAYMENT,
  ERROR_MESSAGE_FOR_NUMBER_ONLY,
} from '~/constants'

import {
  REQUESTED_LOAN_DETAILS,
  LABEL_DOCUMENT_LOAN_TYPE,
  LABEL_DOCUMENT_PURCHASE_PRICE,
  LABEL_DOCUMENT_DEPOSIT,
  LABEL_DOCUMENT_TRADE_IN,
  LABEL_DOCUMENT_LOAN_AMOUNT,
  LABEL_DOCUMENT_LOAN_TERM,
  LABEL_DOCUMENT_BALLOON,
  LABEL_DOCUMENT_BALLOON_ONLY,
  LABEL_DOCUMENT_BUDGETED_MONTHLY_REPAYMENT,
  LABEL_FOR_CANCEL_BUTTON,
  APPLICATION_TRADE_IN_VEHICLE,
} from '~/constants/applications'
import { checkAmount, validateBalloonPercentage } from '~/helpers/validators'

export default {
  props: {
    applicationDetails: {
      type: Object,
      default: null,
    },
    vehicleDetails: {
      type: Object,
      default: null,
    },
  },
  computed: {
    loanAmountTotal() {
      return (
        (this.requestLoanDetailsDialogForm.purchasePrice || 0) -
        (this.requestLoanDetailsDialogForm.vehicle?.tradeInAmount || 0) -
        (this.requestLoanDetailsDialogForm.vehicle?.financeDepositAmount || 0)
      )
    },
  },
  data() {
    const checkTradeIn = (_rule, value, callback) => {
      if (!value && value !== 0) {
        return callback(new Error(ERROR_MESSAGE_FOR_TRADE_IN))
      }

      if (!Number(value) && Number(value) !== 0) {
        callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY))
      } else {
        callback()
      }
    }

    const checkPurchasePrice = (_rule, value, callback) =>
      checkAmount(_rule, value, callback)
    const checkFinanceDepositAmount = (_rule, value, callback) =>
      checkAmount(
        _rule,
        value,
        callback,
        this.requestLoanDetailsDialogForm.purchasePrice
      )
    return {
      vehicleId: this.applicationDetails?.vehicle ?? '',
      requestLoanDetailsDialogForm: {
        ...this.applicationDetails,
        vehicle: { ...this.vehicleDetails },
      },
      rules: {
        purchasePrice: [
          {
            required: true,
            validator: checkPurchasePrice,
            trigger: ['blur', 'change'],
          },
        ],
        financeDepositAmount: [
          {
            required: true,
            validator: checkFinanceDepositAmount,
            trigger: ['blur', 'change'],
          },
        ],
        tradeInAmount: [
          {
            required: true,
            validator: checkTradeIn,
            trigger: ['blur', 'change'],
          },
        ],
        loanTermYears: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_LOAN_TERM,
            trigger: ['blur', 'change'],
          },
        ],

        balloonPct: [
          {
            required: true,
            validator: (_rule, value, callback) => {
              validateBalloonPercentage(
                value,
                callback,
                parseInt(this.requestLoanDetailsDialogForm.loanTermYears),
                ERROR_MESSAGE_FOR_BALLOON
              )
            },
            trigger: ['blur', 'change'],
          },
        ],
      },
      loading: false,
      ERROR_MESSAGE_FOR_PURCHASE_PRICE,
      ERROR_MESSAGE_FOR_DEPOSIT,
      ERROR_MESSAGE_FOR_TRADE_IN,
      ERROR_MESSAGE_FOR_LOAN_TERM,
      ERROR_MESSAGE_FOR_BALLOON,
      ERROR_MESSAGE_FOR_BALLOON_ONLY,
      ERROR_MESSAGE_FOR_BUDGET_MONTHLY_REPAYMENT,
      ERROR_MESSAGE_FOR_NUMBER_ONLY,
      REQUESTED_LOAN_DETAILS,
      LABEL_DOCUMENT_LOAN_TYPE,
      LABEL_DOCUMENT_PURCHASE_PRICE,
      LABEL_DOCUMENT_DEPOSIT,
      LABEL_DOCUMENT_TRADE_IN,
      LABEL_DOCUMENT_LOAN_AMOUNT,
      LABEL_DOCUMENT_LOAN_TERM,
      LABEL_DOCUMENT_BALLOON,
      LABEL_DOCUMENT_BALLOON_ONLY,
      LABEL_DOCUMENT_BUDGETED_MONTHLY_REPAYMENT,
      LABEL_FOR_CANCEL_BUTTON,
      APPLICATION_TRADE_IN_VEHICLE,
    }
  },
  methods: {
    calculateBalloonAmt() {
      this.requestLoanDetailsDialogForm.balloonAmount =
        (this.requestLoanDetailsDialogForm.balloonPct *
          this.requestLoanDetailsDialogForm.loanAmount) /
        100
    },
    calculateBalloonPerc() {
      this.requestLoanDetailsDialogForm.balloonPct =
        (100 * this.requestLoanDetailsDialogForm.balloonAmount) /
        this.requestLoanDetailsDialogForm.loanAmount
    },
    closeDialog() {
      this.$nuxt.$emit('requestLoanDetailsDialogForm-dialog-toggle', {
        open: false,
      })
    },
    async submitForm() {
      this.loading = true
      let isFormValid = false
      this.$refs.requestLoanDetailsDialogForm.validate((result) => {
        isFormValid = result
      })
      if (!isFormValid) {
        return
      }
      await this.$store.dispatch('vehicle/updateVehicleByID', {
        vehicleId: this.vehicleId,
        vehicleData: this.requestLoanDetailsDialogForm.vehicle,
      })
      const { id: vehicleRequestGroupId, applicationId } = this.$route.params
      await this.$store.dispatch(
        'finance/applications/application/updateApplication',
        {
          application: {
            ...this.requestLoanDetailsDialogForm,
            vehicle: this.vehicleId,
          },
          vehicleRequestGroupId,
          applicationId,
        }
      )
      this.loading = false
      this.closeDialog()
    },
  },
}
