var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading ? _c('el-form', {
    ref: "assetsLiabilitesData",
    attrs: {
      "rules": _vm.rules,
      "model": _vm.assetsLiabilitesData
    }
  }, [_c('el-card', {
    staticClass: "mt-6 assets-liabilities-commercial",
    attrs: {
      "data-selector": "assets-section"
    }
  }, [_c('div', {
    staticClass: "clearfix card-header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "assets-header-text"
    }
  }, [_vm._v(_vm._s(_vm.TITLE_FOR_ASSETS))])]), _vm._v(" "), _c('div', {
    staticClass: "hide-repated-label mt-6"
  }, [_vm.assetsLiabilitesData ? [_vm.assetsLiabilitesData.primaryApplicantData && _vm.assetsLiabilitesData.primaryApplicantData.assets ? _vm._l(_vm.assetsLiabilitesData.primaryApplicantData.assets, function (assetsSingle, index) {
    return _c('el-row', {
      key: index,
      staticClass: "flex flex-wrap",
      attrs: {
        "gutter": 40,
        "data-selector": 'asset-container_' + index
      }
    }, [_c('el-col', {
      attrs: {
        "xs": 12,
        "sm": 5,
        "xl": 4
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.LABEL_FOR_LAST_TYPE,
        "prop": 'primaryApplicantData.assets.' + index + '.type',
        "rules": _vm.rules.type,
        "data-selector": 'as-type-input-container_' + index
      }
    }, [_c('el-select', {
      attrs: {
        "filterable": "",
        "placeholder": "Select",
        "disabled": assetsSingle.hasLiability
      },
      model: {
        value: _vm.assetsLiabilitesData.primaryApplicantData.assets[index].type,
        callback: function ($$v) {
          _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.assets[index], "type", $$v);
        },
        expression: "\n                    assetsLiabilitesData.primaryApplicantData.assets[index]\n                      .type\n                  "
      }
    }, [_c('div', {
      attrs: {
        "data-selector": 'as-type-options-container_' + index
      }
    }, _vm._l(_vm.APPLICATION_ASSETS_OPTIONS, function (data) {
      return _c('el-option', {
        key: data.key,
        attrs: {
          "label": data.label,
          "value": data.value
        }
      });
    }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 12,
        "sm": 5,
        "xl": 4
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.LABEL_FOR_OWNERSHIP,
        "prop": 'primaryApplicantData.assets.' + index + '.ownership',
        "rules": _vm.rules.ownership,
        "data-selector": 'as-ownership-input-container_' + index
      }
    }, [_c('el-select', {
      attrs: {
        "placeholder": "Select",
        "filterable": ""
      },
      on: {
        "change": function ($event) {
          return _vm.onOwnershipChange({
            type: _vm.TITLE_FOR_ASSETS,
            payload: _vm.assetsLiabilitesData.primaryApplicantData.assets[index],
            index: index
          });
        }
      },
      model: {
        value: _vm.assetsLiabilitesData.primaryApplicantData.assets[index].ownership,
        callback: function ($$v) {
          _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.assets[index], "ownership", $$v);
        },
        expression: "\n                    assetsLiabilitesData.primaryApplicantData.assets[index]\n                      .ownership\n                  "
      }
    }, [_c('div', {
      attrs: {
        "data-selector": 'as-ownership-options-container_' + index
      }
    }, _vm._l(_vm.APPLICATION_CONTACT_ASSETS_OWNER_OPTIONS, function (data) {
      return _c('el-option', {
        key: data.key,
        attrs: {
          "label": data.label,
          "value": data.value
        }
      });
    }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 12,
        "sm": 5,
        "xl": 4
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.LABEL_FOR_OWNERSHIP_EQUITY,
        "prop": 'primaryApplicantData.assets.' + index + '.equity',
        "rules": _vm.rules.equity,
        "data-selector": 'as-own-perc-input-container_' + index
      }
    }, [_c('formatted-numeric-input', {
      directives: [{
        name: "restrict",
        rawName: "v-restrict.number",
        modifiers: {
          "number": true
        }
      }],
      staticClass: "text-right",
      attrs: {
        "disabled": _vm.shouldEquityBeDisabled(_vm.assetsLiabilitesData.primaryApplicantData.assets[index]),
        "data-selector": 'as-own-perc-input' + index
      },
      model: {
        value: _vm.assetsLiabilitesData.primaryApplicantData.assets[index].equity,
        callback: function ($$v) {
          _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.assets[index], "equity", $$v);
        },
        expression: "\n                    assetsLiabilitesData.primaryApplicantData.assets[index]\n                      .equity\n                  "
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 12,
        "sm": 4,
        "xl": 4
      }
    }, [_c('el-form-item', {
      attrs: {
        "prop": 'primaryApplicantData.assets.' + index + '.amount',
        "rules": _vm.rules.amount,
        "label": _vm.LABEL_FOR_VALUE,
        "data-selector": 'as-value-input-container_' + index
      }
    }, [_c('formatted-numeric-input', {
      directives: [{
        name: "restrict",
        rawName: "v-restrict.number",
        modifiers: {
          "number": true
        }
      }],
      staticClass: "text-right",
      attrs: {
        "data-selector": 'as-value-input_' + index
      },
      model: {
        value: _vm.assetsLiabilitesData.primaryApplicantData.assets[index].amount,
        callback: function ($$v) {
          _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.assets[index], "amount", $$v);
        },
        expression: "\n                    assetsLiabilitesData.primaryApplicantData.assets[index]\n                      .amount\n                  "
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      staticClass: "self-start",
      attrs: {
        "xs": 8,
        "sm": 5,
        "xl": 4
      }
    }, [_c('p', {
      staticClass: "requiredlabel-field"
    }, [_c('span', {
      staticClass: "el-form-item__label"
    }, [_vm._v(_vm._s(_vm.LABEL_FOR_OWNED_OUTRIGHT))])]), _vm._v(" "), _vm.showPayOutArray.includes(_vm.assetsLiabilitesData.primaryApplicantData.assets[index].type) ? _c('el-form-item', {
      staticClass: "w-full inline-block",
      attrs: {
        "prop": 'primaryApplicantData.assets.' + index + '.hasLiability',
        "rules": _vm.rules.hasLiability,
        "data-selector": 'as-or-input-container_' + index
      }
    }, [_c('div', {
      staticClass: "block"
    }, [_c('el-radio-group', {
      on: {
        "change": function ($event) {
          return _vm.setLiability(_vm.assetsLiabilitesData.primaryApplicantData.assets[index], index);
        }
      },
      model: {
        value: _vm.assetsLiabilitesData.primaryApplicantData.assets[index].hasLiability,
        callback: function ($$v) {
          _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.assets[index], "hasLiability", $$v);
        },
        expression: "\n                      assetsLiabilitesData.primaryApplicantData.assets[index]\n                        .hasLiability\n                    "
      }
    }, [_c('el-radio-button', {
      attrs: {
        "label": false,
        "data-selector": 'as-or-yes_' + index
      }
    }, [_vm._v("Yes")]), _vm._v(" "), _c('el-radio-button', {
      attrs: {
        "label": true,
        "data-selector": 'as-or-no_' + index
      }
    }, [_vm._v("No")])], 1)], 1)]) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
      staticClass: "self-center",
      attrs: {
        "xs": 4,
        "sm": 2,
        "xl": 2
      }
    }, [_c('el-button', {
      staticClass: "mb-6 text-button",
      attrs: {
        "type": "danger",
        "plain": "",
        "icon": "el-icon-delete",
        "data-selector": 'as-del-btn_' + index
      },
      on: {
        "click": function ($event) {
          return _vm.onDeleteAsset(index, _vm.assetsLiabilitesData.primaryApplicantData.assets[index].type);
        }
      }
    })], 1)], 1);
  }) : _vm._e()] : _vm._e(), _vm._v(" "), !_vm.hasAssets(_vm.assetsLiabilitesData) ? [_c('p', {
    attrs: {
      "data-selector": "no-assets-text"
    }
  }, [_vm._v(_vm._s(_vm.DESCRIPTION_IF_NO_ASSETS_FIRST_PART) + "\n          "), _c('b', [_vm._v("+ " + _vm._s(_vm.TITLE_FOR_ADD_ASSETS))]), _vm._v("\n          " + _vm._s(_vm.DESCRIPTION_IF_NO_ASSETS_SECOND_PART) + "\n        ")])] : _vm._e(), _vm._v(" "), _c('el-dialog', {
    attrs: {
      "visible": _vm.assetsDialog,
      "show-close": false,
      "width": "30%",
      "center": "",
      "data-selector": "remove-asset-modal"
    },
    on: {
      "update:visible": function ($event) {
        _vm.assetsDialog = $event;
      }
    }
  }, [_c('div', {
    staticClass: "el-dialog__header custom-dialog-header"
  }, [_c('img', {
    attrs: {
      "src": require("assets/icons/warning-employement.svg"),
      "alt": "Warning"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "el-dialog__title mt-2 font-extrabold",
    attrs: {
      "data-selector": "ram-title"
    }
  }, [_vm._v(_vm._s(_vm.dialogData.firstMessage))])]), _vm._v(" "), _c('p', {
    staticClass: "text-sm text-center",
    attrs: {
      "data-selector": "ram-subtitle"
    }
  }, [_vm._v(_vm._s(_vm.dialogData.secondMessage))]), _vm._v(" "), _c('div', {
    staticClass: "dialog-footer w-full flex justify-between",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "text",
      "data-selector": "ram-cancel-btn"
    },
    on: {
      "click": function ($event) {
        _vm.assetsDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c('el-button', {
    attrs: {
      "type": "primary",
      "data-selector": "ram-yes-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.removeData(_vm.dialogData.dialogFor, _vm.dialogData.index);
      }
    }
  }, [_vm._v("Yes. Proceed")])], 1)])], 2), _vm._v(" "), _c('el-col', {
    staticClass: "py-4 border-t border-light-grey mt-6"
  }, [_c('el-button', {
    staticClass: "font-extrabold center",
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus",
      "plain": "",
      "data-selector": "add-assets-btn"
    },
    on: {
      "click": _vm.addAssets
    }
  }, [_vm._v("Add Assets")])], 1)], 1), _vm._v(" "), _c('el-card', {
    staticClass: "mt-6 assets-liabilities-commercial",
    attrs: {
      "data-selector": "liabilities-section"
    }
  }, [_c('div', {
    staticClass: "clearfix card-header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "liab-header-text"
    }
  }, [_vm._v(_vm._s(_vm.TITLE_FOR_LIABILITES))])]), _vm._v(" "), _c('div', {
    staticClass: "hide-repated-label mt-6"
  }, [_vm.assetsLiabilitesData ? [_vm.assetsLiabilitesData.primaryApplicantData && _vm.assetsLiabilitesData.primaryApplicantData.liabilities ? _vm._l(_vm.assetsLiabilitesData.primaryApplicantData.liabilities, function (assetsSingle, index) {
    return _c('el-row', {
      key: index + 'a',
      staticClass: "flex responsive-wrap",
      attrs: {
        "gutter": 30,
        "data-selector": 'liab-container_' + index
      }
    }, [assetsSingle.type !== _vm.LIABILITY_TYPE_CONFIRMABLE_CREDIT ? [_c('el-col', {
      attrs: {
        "sm": 12,
        "md": 6,
        "lg": 5,
        "xl": 4
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.LABEL_FOR_LAST_TYPE,
        "prop": 'primaryApplicantData.liabilities.' + index + '.type',
        "rules": _vm.rules.type,
        "data-selector": 'liab-type-input-container_' + index
      }
    }, [_c('el-select', {
      attrs: {
        "filterable": "",
        "placeholder": "Select",
        "disabled": _vm.validateAssestLiabilityType(_vm.assetsLiabilitesData.primaryApplicantData.assets, assetsSingle._id)
      },
      model: {
        value: _vm.assetsLiabilitesData.primaryApplicantData.liabilities[index].type,
        callback: function ($$v) {
          _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.liabilities[index], "type", $$v);
        },
        expression: "\n                      assetsLiabilitesData.primaryApplicantData.liabilities[\n                        index\n                      ].type\n                    "
      }
    }, [_c('div', {
      attrs: {
        "data-selector": 'liab-type-options-container_' + index
      }
    }, _vm._l(_vm.APPLICATION_LIABILITY_OPTIONS, function (data) {
      return _c('el-option', {
        key: data.key,
        attrs: {
          "label": data.label,
          "value": data.value
        }
      });
    }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 12,
        "sm": 5,
        "xl": 3
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.LABEL_FOR_OWNER,
        "prop": 'primaryApplicantData.liabilities.' + index + '.ownership',
        "rules": _vm.rules.ownership,
        "data-selector": 'liab-owner-input-container_' + index
      }
    }, [_c('el-select', {
      attrs: {
        "placeholder": "Select",
        "filterable": ""
      },
      model: {
        value: _vm.assetsLiabilitesData.primaryApplicantData.liabilities[index].ownership,
        callback: function ($$v) {
          _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.liabilities[index], "ownership", $$v);
        },
        expression: "\n                      assetsLiabilitesData.primaryApplicantData.liabilities[\n                        index\n                      ].ownership\n                    "
      }
    }, [_c('div', {
      attrs: {
        "data-selector": 'liab-owner-options-container_' + index
      }
    }, _vm._l(_vm.APPLICATION_CONTACT_ASSETS_OWNER_OPTIONS, function (data) {
      return _c('el-option', {
        key: data.key,
        attrs: {
          "label": data.label,
          "value": data.value
        }
      });
    }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 12,
        "md": 6,
        "lg": 4,
        "xl": 3
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.LABEL_FOR_FINANCIER,
        "prop": 'primaryApplicantData.liabilities.' + index + '.lenderName',
        "rules": _vm.rules.lenderName,
        "data-selector": 'liab-financier-input-container_' + index
      }
    }, [_c('el-input', {
      attrs: {
        "data-selector": 'liab-financier-input_' + index
      },
      model: {
        value: _vm.assetsLiabilitesData.primaryApplicantData.liabilities[index].lenderName,
        callback: function ($$v) {
          _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.liabilities[index], "lenderName", $$v);
        },
        expression: "\n                      assetsLiabilitesData.primaryApplicantData.liabilities[\n                        index\n                      ].lenderName\n                    "
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 12,
        "md": 6,
        "lg": 4,
        "xl": 2
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.LABEL_FOR_BALANCE,
        "prop": 'primaryApplicantData.liabilities.' + index + '.outstandingAmount',
        "rules": _vm.rules.outstandingAmount,
        "data-selector": 'liab-balance-input-container_' + index
      }
    }, [_c('formatted-numeric-input', {
      directives: [{
        name: "restrict",
        rawName: "v-restrict.number",
        modifiers: {
          "number": true
        }
      }],
      staticClass: "text-right",
      attrs: {
        "data-selector": 'liab-balance-input_' + index
      },
      model: {
        value: _vm.assetsLiabilitesData.primaryApplicantData.liabilities[index].outstandingAmount,
        callback: function ($$v) {
          _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.liabilities[index], "outstandingAmount", $$v);
        },
        expression: "\n                      assetsLiabilitesData.primaryApplicantData.liabilities[\n                        index\n                      ].outstandingAmount\n                    "
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 12,
        "md": 6,
        "lg": 4,
        "xl": 2
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.LABEL_FOR_PAYMENT,
        "prop": 'primaryApplicantData.liabilities.' + index + '.installmentAmount',
        "rules": _vm.rules.installmentAmount,
        "data-selector": 'liab-payment-input-container_' + index
      }
    }, [_c('formatted-numeric-input', {
      directives: [{
        name: "restrict",
        rawName: "v-restrict.number",
        modifiers: {
          "number": true
        }
      }],
      staticClass: "text-right",
      attrs: {
        "data-selector": 'liab-payment-input_' + index
      },
      on: {
        "input": function ($event) {
          return _vm.calculateTotalLiabilities();
        }
      },
      model: {
        value: _vm.assetsLiabilitesData.primaryApplicantData.liabilities[index].installmentAmount,
        callback: function ($$v) {
          _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.liabilities[index], "installmentAmount", $$v);
        },
        expression: "\n                      assetsLiabilitesData.primaryApplicantData.liabilities[\n                        index\n                      ].installmentAmount\n                    "
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 12,
        "md": 6,
        "lg": 4,
        "xl": 3
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.LABEL_FOR_FREQUENCY,
        "prop": 'primaryApplicantData.liabilities.' + index + '.installmentFrequency',
        "rules": _vm.rules.installmentFrequency,
        "data-selector": 'liab-freq-input-container_' + index
      }
    }, [_c('el-select', {
      attrs: {
        "filterable": "",
        "placeholder": "Select"
      },
      model: {
        value: _vm.assetsLiabilitesData.primaryApplicantData.liabilities[index].installmentFrequency,
        callback: function ($$v) {
          _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.liabilities[index], "installmentFrequency", $$v);
        },
        expression: "\n                      assetsLiabilitesData.primaryApplicantData.liabilities[\n                        index\n                      ].installmentFrequency\n                    "
      }
    }, [_c('div', {
      attrs: {
        "data-selector": 'liab-freq-options-container_' + index
      }
    }, _vm._l(_vm.INCOME_FREQUENCY_OPTIONS, function (data) {
      return _c('el-option', {
        key: data.key,
        attrs: {
          "label": data.label,
          "value": data.value
        }
      });
    }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
      staticClass: "self-start",
      attrs: {
        "sm": 12,
        "md": 6,
        "lg": 4,
        "xl": 2
      }
    }, [_c('p', {
      staticClass: "requiredlabel-field"
    }, [_c('span', {
      staticClass: "el-form-item__label"
    }, [_vm._v(_vm._s(_vm.LABEL_FOR_LIMIT))])]), _vm._v(" "), _vm.showLimitArray.includes(_vm.assetsLiabilitesData.primaryApplicantData.liabilities[index].type) ? _c('el-form-item', {
      attrs: {
        "prop": 'primaryApplicantData.liabilities.' + index + '.creditLimit',
        "rules": _vm.rules.creditLimit,
        "data-selector": 'liab-limit-input-container_' + index
      }
    }, [_c('formatted-numeric-input', {
      directives: [{
        name: "restrict",
        rawName: "v-restrict.number",
        modifiers: {
          "number": true
        }
      }],
      staticClass: "text-right",
      attrs: {
        "data-selector": 'liab-limit-input_' + index
      },
      model: {
        value: _vm.assetsLiabilitesData.primaryApplicantData.liabilities[index].creditLimit,
        callback: function ($$v) {
          _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.liabilities[index], "creditLimit", $$v);
        },
        expression: "\n                      assetsLiabilitesData.primaryApplicantData.liabilities[\n                        index\n                      ].creditLimit\n                    "
      }
    })], 1) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
      staticClass: "grid px-2px self-start",
      attrs: {
        "sm": 6,
        "md": 4,
        "lg": 5,
        "xl": 3
      }
    }, [_c('p', {
      staticClass: "requiredlabel-field"
    }, [_c('span', {
      staticClass: "el-form-item__label"
    }, [_vm._v(_vm._s(_vm.LABEL_FOR_PAYOUT))])]), _vm._v(" "), _vm.showPayOutArray.includes(_vm.assetsLiabilitesData.primaryApplicantData.liabilities[index].type) ? _c('el-form-item', {
      attrs: {
        "prop": 'primaryApplicantData.liabilities.' + index + '.payout',
        "rules": _vm.rules.payout,
        "data-selector": 'liab-payout-input-container_' + index
      }
    }, [_c('div', {
      staticClass: "block"
    }, [_c('el-radio-group', {
      model: {
        value: _vm.assetsLiabilitesData.primaryApplicantData.liabilities[index].payout,
        callback: function ($$v) {
          _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.liabilities[index], "payout", $$v);
        },
        expression: "\n                        assetsLiabilitesData.primaryApplicantData.liabilities[\n                          index\n                        ].payout\n                      "
      }
    }, [_c('el-radio-button', {
      attrs: {
        "label": true,
        "data-selector": 'liab-payout-yes_' + index
      }
    }, [_vm._v("Yes")]), _vm._v(" "), _c('el-radio-button', {
      attrs: {
        "label": false,
        "data-selector": 'liab-payout-no_' + index
      }
    }, [_vm._v("No")])], 1)], 1)]) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
      staticClass: "self-center",
      attrs: {
        "sm": 6,
        "md": 2,
        "lg": 3,
        "xl": 2
      }
    }, [_c('el-button', {
      staticClass: "mb-6 text-button",
      attrs: {
        "type": "danger",
        "icon": "el-icon-delete",
        "plain": "",
        "data-selector": 'liab-del-btn_' + index
      },
      on: {
        "click": function ($event) {
          return _vm.onDeleteLiabilities(index, _vm.assetsLiabilitesData.primaryApplicantData.liabilities[index].type);
        }
      }
    })], 1)] : _vm._e()], 2);
  }) : _vm._e(), _vm._v(" "), !_vm.hasLiabilities(_vm.assetsLiabilitesData) ? [_c('p', {
    attrs: {
      "data-selector": "no-liabs-text"
    }
  }, [_vm._v(_vm._s(_vm.DESCRIPTION_IF_NO_LIABILITIES_FIRST_PART) + "\n            "), _c('b', [_vm._v("+ " + _vm._s(_vm.TITLE_FOR__ADD_LIABILITES))]), _vm._v("\n            " + _vm._s(_vm.DESCRIPTION_IF_NO_LIABILITIES_SECOND_PART) + "\n          ")])] : _vm._e()] : _vm._e()], 2), _vm._v(" "), _c('el-col', {
    staticClass: "py-4 border-t border-light-grey mt-6"
  }, [_c('el-button', {
    staticClass: "font-extrabold center",
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus",
      "plain": "",
      "data-selector": "add-liabs-btn"
    },
    on: {
      "click": _vm.addLiabilites
    }
  }, [_vm._v("Add Liability")])], 1)], 1), _vm._v(" "), _c('el-card', {
    staticClass: "mt-6 assets-liabilities-commercial",
    attrs: {
      "data-selector": "exp-cc-section"
    }
  }, [_c('div', {
    staticClass: "clearfix card-header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "exp-cc-header"
    }
  }, [_vm._v(_vm._s(_vm.EXPENSES_AND_COMFORTABLE_CREDIT_TITLE))])]), _vm._v(" "), _c('div', {
    staticClass: "mt-6"
  }, [_c('el-row', {
    staticClass: "flex items-center responsive-wrap",
    attrs: {
      "gutter": 30
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 12,
      "md": 12,
      "lg": 12,
      "xl": 12
    }
  }, [_c('text-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "value": _vm.assetsLiabilitesData.primaryApplicantData.householdExpenses,
      "placeholder": "0",
      "form-prop": "primaryApplicantData.householdExpenses",
      "label": _vm.HOUSE_HOLD_EXPENSES_MONTHLY_LABEL,
      "accept-only-numbers": "",
      "class-name": "text-left",
      "data-selector": "house-expenses-input"
    },
    model: {
      value: _vm.assetsLiabilitesData.primaryApplicantData.householdExpenses,
      callback: function ($$v) {
        _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData, "householdExpenses", $$v);
      },
      expression: "\n              assetsLiabilitesData.primaryApplicantData.householdExpenses\n            "
    }
  })], 1), _vm._v(" "), _c('el-col', {
    staticClass: "radio-group-fifty",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('span', {
    staticClass: "el-form-item__label"
  }, [_vm._v(_vm._s(_vm.DOES_APPLICANT_HAVE_CONFIRMABLE_CREDIT_LABEL))]), _vm._v(" "), _c('el-form-item', {
    staticClass: "w-full inline-block",
    attrs: {
      "prop": "primaryApplicantData.hasConfirmableCredit",
      "data-selector": "cc-input-container"
    }
  }, [_c('el-radio-group', {
    staticClass: "has-comfortable-credit-rb",
    model: {
      value: _vm.assetsLiabilitesData.primaryApplicantData.hasConfirmableCredit,
      callback: function ($$v) {
        _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData, "hasConfirmableCredit", $$v);
      },
      expression: "\n                assetsLiabilitesData.primaryApplicantData.hasConfirmableCredit\n              "
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": true,
      "data-selector": "cc-yes"
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c('el-radio-button', {
    attrs: {
      "label": false,
      "data-selector": "cc-no"
    }
  }, [_vm._v("No")])], 1)], 1)], 1)], 1), _vm._v(" "), _vm.assetsLiabilitesData.primaryApplicantData.hasConfirmableCredit ? _c('el-col', {
    staticClass: "py-2 border-t border-light-grey"
  }) : _vm._e(), _vm._v(" "), _vm.assetsLiabilitesData.primaryApplicantData.hasConfirmableCredit ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "primaryApplicantData.confirmableCreditProps.loanType",
      "label": _vm.LABEL_FOR_CONFIRMABLE_CREDIT_TYPE,
      "data-selector": "cc-type-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps.loanType,
      callback: function ($$v) {
        _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps, "loanType", $$v);
      },
      expression: "\n                assetsLiabilitesData.primaryApplicantData\n                  .confirmableCreditProps.loanType\n              "
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "cc-type-options-container"
    }
  }, _vm._l(_vm.CONFIRMABLE_CREDIT_TYPE_OPTIONS, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "primaryApplicantData.confirmableCreditProps.lenderName",
      "label": _vm.LABEL_FOR_CONFIRMABLE_CREDIT_LENDER,
      "data-selector": "cc-lender-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "data-selector": "cc-lender-input"
    },
    model: {
      value: _vm.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps.lenderName,
      callback: function ($$v) {
        _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps, "lenderName", $$v);
      },
      expression: "\n                assetsLiabilitesData.primaryApplicantData\n                  .confirmableCreditProps.lenderName\n              "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_CONFIRMABLE_CREDIT_LOAN_AMOUNT,
      "prop": "primaryApplicantData.confirmableCreditProps.loanAmount",
      "data-selector": "cc-loan-amt-input-container"
    }
  }, [_c('formatted-numeric-input', {
    attrs: {
      "value": _vm.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps.loanAmount,
      "accept-only-numbers": true,
      "placeholder": "0",
      "input-class": 'text-left',
      "data-selector": "cc-loan-amt-input"
    },
    model: {
      value: _vm.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps.loanAmount,
      callback: function ($$v) {
        _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps, "loanAmount", $$v);
      },
      expression: "\n                assetsLiabilitesData.primaryApplicantData\n                  .confirmableCreditProps.loanAmount\n              "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_CONFIRMABLE_CREDIT_REPAYMENT_AMOUNT,
      "prop": "primaryApplicantData.confirmableCreditProps.repaymentAmount",
      "data-selector": "cc-repay-amt-input-container"
    }
  }, [_c('formatted-numeric-input', {
    attrs: {
      "value": _vm.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps.repaymentAmount,
      "accept-only-numbers": true,
      "placeholder": "0",
      "input-class": 'text-left',
      "data-selector": "cc-repay-amt-input"
    },
    model: {
      value: _vm.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps.repaymentAmount,
      callback: function ($$v) {
        _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps, "repaymentAmount", $$v);
      },
      expression: "\n                assetsLiabilitesData.primaryApplicantData\n                  .confirmableCreditProps.repaymentAmount\n              "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_CONFIRMABLE_CREDIT_REPAYMENT_LOAN_STATUS,
      "prop": "primaryApplicantData.confirmableCreditProps.loanStatus",
      "data-selector": "cc-loan-status-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps.loanStatus,
      callback: function ($$v) {
        _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps, "loanStatus", $$v);
      },
      expression: "\n                assetsLiabilitesData.primaryApplicantData\n                  .confirmableCreditProps.loanStatus\n              "
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "cc-loan-status-options-container"
    }
  }, _vm._l(_vm.CONFIRMABLE_CREDIT_LOAN_STATUS_OPTIONS, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "primaryApplicantData.confirmableCreditProps.endDate",
      "label": _vm.LABEL_FOR_CONFIRMABLE_CREDIT_REPAYMENT_END_DATE,
      "data-selector": "cc-end-date-input-container"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "placeholder": "MM/YYYY",
      "format": "MM/yyyy"
    },
    model: {
      value: _vm.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps, "endDate", $$v);
      },
      expression: "\n                assetsLiabilitesData.primaryApplicantData\n                  .confirmableCreditProps.endDate\n              "
    }
  })], 1)], 1)], 1) : _vm._e()], 1)]), _vm._v(" "), _c('tab-footer', {
    attrs: {
      "save-btn-loading": _vm.saveBtnLoading,
      "save-and-next-btn-loading": _vm.saveAndNextBtnLoading,
      "show-back-button": true
    },
    on: {
      "submitForm": _vm.submit,
      "saveAndNext": _vm.submit,
      "goBack": _vm.back
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }