//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  LABEL_FOR_SELECT_ENTER_VEHICLE_MANUALLY,
  DESCRIPTION_FOR_SELECT_VEHICLE_MANUALLY,
  LABEL_FOR_ALREADY_VEHICLE_SELECTED,
  DESCRIPTION_FOR_SELECT_VEHICLE,
  LABEL_FOR_VEHICLE_DESCRIPTION,
  LABEL_FOR_ASSET_DETAILS_YEAR,
  TITLE_FOR_ASSET_DETAILS,
  LABEL_FOR_SELECT_VEHICLE,
  TITLE_FOR_OTHER_DETAILS,
  LABEL_FOR_MODEL_TYPE,
  LABEL_FOR_SALE_TYPE,
  LABEL_FOR_CONDITION,
  LABEL_FOR_MODEL,
  LABEL_FOR_MAKE,
  LABEL_FOR_VIN,
  LABEL_FOR_REGO,
  ERROR_MESSAGE_FOR_MAKE,
  ERROR_MESSAGE_FOR_MODEL,
  ERROR_MESSAGE_FOR_MODEL_YEAR,
  ERROR_MESSAGE_FOR_MODEL_TYPE,
  ERROR_MESSAGE_FOR_MODEL_DESCRIPTION,
  ERROR_MESSAGE_FOR_MAX_MODEL_DESCRIPTION,
  ERROR_MESSAGE_FOR_MODEL_CONDITION,
  ERROR_MESSAGE_FOR_TYPE,
  ERROR_MESSAGE_FOR_SALE_TYPE,
  LABEL_FOR_ASSET_DETAIL_TYPE,
  LABEL_RRP,
  LABEL_RRP_DESCRIPTION,
  LABEL_UNKNOWN_PRICE,
} from '~/constants'
import {
  VEHICLE_ASSET_DETAILS_CONDITION_OPTIONS,
  LABEL_DOCUMENT_MODEL_TYPE,
  SALE_TYPES,
  SALE_TYPE_OPTIONS,
  LABEL_DOCUMENT_TYPE,
  ASSET_TYPE_OPTIONS,
  LABEL_ASSET_TYPE_OPTION_VEHICLE,
  LABEL_ASSET_TYPE_OPTION_OTHER,
  MAINTAB_CREDIT_KEY,
  LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY,
  FINANCE_APPLICATION_TAB_LOAN_DETAILS_KEY,
  LABEL_ASSET_TYPE_STILL_DECIDING,
} from '~/constants/applications'
import { changeRoute } from '~/helpers/changeRoute'
import { scrollToElement } from '~/utils/scroll'
import { toCurrency } from '@/helpers/currency'
import { VEHICLE_TYPE_ASSET } from '~/constants/vehicles'
import { axiosErrorMessageExtractor } from '~/utils/error'
import { setVehicleYear } from '~/helpers/application'
export default {
  props: {
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
    isApplicationSettled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toCurrency,
      LABEL_RRP,
      LABEL_RRP_DESCRIPTION,
      LABEL_UNKNOWN_PRICE,
      VEHICLE_TYPE_ASSET,
      LABEL_FOR_MODEL,
      LABEL_FOR_MAKE,
      LABEL_FOR_VIN,
      LABEL_FOR_REGO,
      LABEL_FOR_SALE_TYPE,
      LABEL_FOR_CONDITION,
      LABEL_FOR_MODEL_TYPE,
      TITLE_FOR_OTHER_DETAILS,
      VEHICLE_ASSET_DETAILS_CONDITION_OPTIONS,
      SALE_TYPES,
      SALE_TYPE_OPTIONS,
      LABEL_FOR_SELECT_VEHICLE,
      TITLE_FOR_ASSET_DETAILS,
      LABEL_DOCUMENT_MODEL_TYPE,
      LABEL_FOR_ASSET_DETAILS_YEAR,
      LABEL_FOR_VEHICLE_DESCRIPTION,
      DESCRIPTION_FOR_SELECT_VEHICLE,
      LABEL_FOR_ALREADY_VEHICLE_SELECTED,
      LABEL_FOR_SELECT_ENTER_VEHICLE_MANUALLY,
      LABEL_FOR_ASSET_DETAIL_TYPE,
      DESCRIPTION_FOR_SELECT_VEHICLE_MANUALLY,
      MAINTAB_CREDIT_KEY,
      ERROR_MESSAGE_FOR_MAKE,
      ERROR_MESSAGE_FOR_MODEL,
      ERROR_MESSAGE_FOR_MODEL_YEAR,
      ERROR_MESSAGE_FOR_MODEL_TYPE,
      ERROR_MESSAGE_FOR_MODEL_DESCRIPTION,
      ERROR_MESSAGE_FOR_MAX_MODEL_DESCRIPTION,
      ERROR_MESSAGE_FOR_MODEL_CONDITION,
      ERROR_MESSAGE_FOR_SALE_TYPE,
      LABEL_DOCUMENT_TYPE,
      ASSET_TYPE_OPTIONS,
      LABEL_ASSET_TYPE_OPTION_OTHER,
      LABEL_ASSET_TYPE_OPTION_VEHICLE,
      LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY,
      vehicleAlreadySelected: Boolean(
        this.sharedData?.vehicle && this.sharedData?.vehicle?._id
      ),
      selecteVehicle: '',
      manuallySelected: false,
      modelDisabled: true,
      yearDisabled: true,
      make: [],
      model_options: [],
      years_options: [],
      model_types_options: [],

      rulesForAssetType: {
        assetType: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
        makeCode: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MAKE,
            trigger: ['blur', 'change'],
          },
        ],
        familyCode: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL,
            trigger: ['blur', 'change'],
          },
        ],
        year: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_YEAR,
            trigger: ['blur', 'change'],
          },
        ],
        codeDescription: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
      },
      rulesForCustomerNote: {
        customerNote: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_DESCRIPTION,
          },
          {
            min: 2,
            max: 300,
            message: ERROR_MESSAGE_FOR_MAX_MODEL_DESCRIPTION,
            trigger: ['blur', 'change'],
          },
        ],
        yearManual: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_YEAR,
            trigger: ['blur', 'change'],
          },
        ],
      },
      rules: {},
      vehicleData: { ...this.sharedData },
      saveBtnLoading: false,
      saveAndNextBtnLoading: false,
      isDialogLoading: false,
      imgUrl: '',
      tradeInAvgCustomer: '',
    }
  },
  created() {
    if (
      this.sharedData?.vehicle &&
      this.sharedData?.vehicle?.assetType !==
        this.LABEL_ASSET_TYPE_OPTION_VEHICLE
    ) {
      if (
        this.sharedData &&
        this.sharedData.vehicle &&
        !this.sharedData.vehicle.year &&
        !this.sharedData.vehicle.makeDescription &&
        !this.sharedData.vehicle.familyDescription &&
        !this.sharedData.vehicle.codeDescription &&
        this.sharedData.vehicle.customerNote
      ) {
        this.manuallySelected = false
      } else {
        this.manuallySelected = true
      }
    }

    if (this.sharedData?.vehicle?.customerNote) {
      this.manuallySelected = true
    }

    if (
      this.sharedData?.vehicle &&
      this.sharedData?.vehicle?.assetType ===
        this.LABEL_ASSET_TYPE_OPTION_VEHICLE
    ) {
      if (
        this.sharedData &&
        this.sharedData.vehicle &&
        this.sharedData.vehicle.year &&
        this.sharedData.vehicle.makeDescription &&
        this.sharedData.vehicle.familyDescription &&
        this.sharedData.vehicle.codeDescription
      ) {
        this.manuallySelected = false
      }
    }
    this.rules = this.rulesForAssetType
    this.setMakes()
  },
  mounted() {
    if (this.sharedData?.vehicle?.customerNote) {
      this.manuallySelected = true
    }

    if (
      this.sharedData?.vehicle &&
      this.sharedData?.vehicle?.assetType ===
        this.LABEL_ASSET_TYPE_OPTION_VEHICLE
    ) {
      if (
        this.sharedData &&
        this.sharedData.vehicle &&
        this.sharedData.vehicle.year &&
        this.sharedData.vehicle.makeDescription &&
        this.sharedData.vehicle.familyDescription &&
        this.sharedData.vehicle.codeDescription
      ) {
        this.manuallySelected = false
      }
    }
    if (this.sharedData?.vehicle?.code) {
      this.tradeInAvgCustomer =
        this.vehicleData?.vehicle?.latestValuation?.tradeInAvgCustomer
      this.getCarImage(this.sharedData?.vehicle?.code)
    }
    this.$nuxt.$on('close-loader', () => {
      this.isDialogLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },

  computed: {
    vehicleForm() {
      // eslint-disable-next-line
      this.vehicleData = JSON.parse(JSON.stringify(this.sharedData))

      return { ...this.sharedData }
    },
    modelTypeDisabled() {
      if (!this.manuallySelected) {
        if (
          this.vehicleData.vehicle.year &&
          this.vehicleData.vehicle.familyCode !== ''
        ) {
          return false
        }
        return true
      } else {
        return true
      }
    },
    purchaseSource: {
      get() {
        if (
          this.vehicleData.vehicle.purchaseSource ===
          LABEL_ASSET_TYPE_STILL_DECIDING
        ) {
          return ''
        } else {
          return this.vehicleData.vehicle.purchaseSource
        }
      },
      set(val) {
        if (val) {
          this.vehicleData.vehicle.purchaseSource = val
        }
      },
    },
    condition: {
      get() {
        if (
          this.vehicleData.vehicle.condition === LABEL_ASSET_TYPE_STILL_DECIDING
        ) {
          return ''
        } else {
          return this.vehicleData.vehicle.condition
        }
      },
      set(val) {
        if (val) {
          this.vehicleData.vehicle.condition = val
        }
      },
    },
    vehicleTypeOther() {
      if (
        !this.manuallySelected &&
        this.vehicleData.vehicle.assetType === LABEL_ASSET_TYPE_OPTION_VEHICLE
      ) {
        return true
      }
      return false
    },
    isAssetTypeVehicle() {
      return (
        this.manuallySelected &&
        this.vehicleData.vehicle.assetType === LABEL_ASSET_TYPE_OPTION_VEHICLE
      )
    },
    years() {
      let years_option = []
      const month = new Date().getMonth()
      const year =
        month >= 10 ? new Date().getFullYear() + 1 : new Date().getFullYear()
      let data = setVehicleYear(year, month >= 10 ? 26 : 25)
      years_option = data
      return years_option
    },
  },
  watch: {
    sharedData() {
      if (this.sharedData?.vehicle?.customerNote) {
        this.manuallySelected = true
      }

      if (
        this.sharedData?.vehicle &&
        this.sharedData?.vehicle?.assetType ===
          this.LABEL_ASSET_TYPE_OPTION_VEHICLE
      ) {
        if (
          this.sharedData &&
          this.sharedData.vehicle &&
          this.sharedData.vehicle.year &&
          this.sharedData.vehicle.makeDescription &&
          this.sharedData.vehicle.familyDescription &&
          this.sharedData.vehicle.codeDescription
        ) {
          this.manuallySelected = false
        }
      }
      this.vehicleAlreadySelected = Boolean(
        this.sharedData.vehicle && this.sharedData.vehicle._id
      )
    },
    manuallySelected(value) {
      this.$refs.vehicleForm.clearValidate()
      if (this.manuallySelected) {
        this.rules = this.rulesForCustomerNote
      } else {
        this.rules = this.rulesForAssetType
      }
      if (value && this.vehicleData.vehicle) {
        this.vehicleData.vehicle.code = ''
        this.vehicleData.vehicle.makeCode = ''
        this.vehicleData.vehicle.makeDescription = ''
        this.vehicleData.vehicle.familyCode = ''
        this.vehicleData.vehicle.familyDescription = ''
        this.vehicleData.vehicle.codeDescription = ''
        this.imgUrl = ''
        this.tradeInAvgCustomer = ''
      } else {
        this.vehicleData.vehicle.customerNote = ''
      }
    },
  },
  methods: {
    numberWithCommas(x) {
      return toCurrency(x)
    },
    checkAssetType() {
      if (
        this.vehicleData.vehicle?.assetType ===
        this.sharedData.vehicle?.assetType
      ) {
        this.vehicleData.vehicle.customerNote =
          this.sharedData.vehicle.customerNote
      } else {
        this.vehicleData.vehicle.customerNote = ''
      }
    },
    addVehicleDescription(desc) {
      const event = this.model_types_options.find((x) => x.description === desc)
      if (event) {
        this.vehicleData.vehicle.code = event.code
        this.vehicleData.vehicle.codeDescription = event.description
        this.vehicleData.vehicle.tradeInPrice = event.tradeInPrice
        this.vehicleData.vehicle.privatePrice = event.privatePrice
        this.vehicleData.vehicle.retailPrice = event.retailPrice
        this.addVehicleData(event)
      }
    },
    async addVehicleData(event) {
      try {
        this.isDialogLoading = true
        const payload = {
          code: event?.code,
          codeDescription: event?.description,
          familyCode: event?.familyCode,
          familyDescription: event?.familyCode,
          makeCode: event?.makeCode,
          type: VEHICLE_TYPE_ASSET,
          year: event?.year,
          yearDescription: event?.year,
        }
        const response = await this.$store.dispatch(
          `applications/getCarEstimatePrice`,
          { payload }
        )
        if (response?.status === 200 && response?.data) {
          this.tradeInAvgCustomer =
            response?.data?.latestValuation?.tradeInAvgCustomer
          this.getCarImage(event?.code)
        }
      } catch (err) {
        console.log('Error::-', err)
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.isDialogLoading = false
      }
    },
    async getCarImage(code) {
      const imageRequest = await this.$store.dispatch(
        `applications/getCarImage`,
        { makeCode: code }
      )
      if (imageRequest?.status === 200 && imageRequest?.data?.length) {
        this.imgUrl = imageRequest?.data[0]?.url
      }
    },
    async change(event) {
      if (event) {
        // Delete the vehicle.
        await this.$store.dispatch('applications/resetVehicleData')
        this.vehicleAlreadySelected = false
      } else {
        // Insert new vehicle.
        this.vehicleAlreadySelected = true
      }
    },
    async setMakes() {
      const { data = [] } = await this.$store.dispatch(
        `applications/getAllMakes`
      )
      this.make = data
    },
    async setModels(shouldRemoveCurrentValue = true) {
      const filterMakeDesciption = this.make.filter(
        (code) => code.makeCode === this.vehicleData.vehicle.makeCode
      )
      this.vehicleData.vehicle.makeDescription =
        filterMakeDesciption[0].description

      if (shouldRemoveCurrentValue) {
        this.vehicleData.vehicle.familyCode = ''
        this.vehicleData.vehicle.year = ''
        this.vehicleData.vehicle.codeDescription = ''
        this.tradeInAvgCustomer = ''
        this.imgUrl = ''
      }
      this.$nextTick(() => {
        this.$refs.vehicleForm.clearValidate()
      })
      const response = await this.$store.dispatch(`applications/getAllModels`, {
        makeCode: this.vehicleData.vehicle.makeCode,
      })
      this.model_options = response.data
    },
    async setYears(shouldRemoveCurrentValue = true) {
      const filterModelDesciption = this.model_options.filter(
        (code) => code.familyCode === this.vehicleData.vehicle.familyCode
      )
      this.vehicleData.vehicle.familyDescription =
        filterModelDesciption[0]?.description

      if (shouldRemoveCurrentValue) {
        this.vehicleData.vehicle.year = ''
        this.tradeInAvgCustomer = ''
        this.imgUrl = ''
      }
      this.$nextTick(() => {
        this.$refs.vehicleForm.clearValidate()
      })
      const response = await this.$store.dispatch(`applications/getAllYears`, {
        makeCode: this.vehicleData.vehicle.makeCode,
        familyCode: this.vehicleData.vehicle.familyCode,
      })
      this.years_options = response.data
    },
    async setModelTypes(shouldRemoveCurrentValue = true) {
      if (shouldRemoveCurrentValue) {
        this.vehicleData.vehicle.codeDescription = ''
      }
      this.$nextTick(() => {
        this.$refs.vehicleForm.clearValidate()
      })
      const response = await this.$store.dispatch(
        `applications/getAllModelTypes`,
        {
          makeCode: this.vehicleData.vehicle.makeCode,
          familyCode: this.vehicleData.vehicle.familyCode,
          year: this.vehicleData.vehicle.year,
        }
      )
      this.model_types_options = response.data
    },
    async submit(isRedirect = false) {
      this.saveBtnLoading = !isRedirect
      this.saveAndNextBtnLoading = !!isRedirect
      const { id, applicationId } = this.$route.params
      let isValid = false
      this.$refs.vehicleForm.validate((result) => {
        this.saveBtnLoading = false
        this.saveAndNextBtnLoading = false
        return (isValid = result)
      })

      if (!isValid) {
        return
      }

      if (
        (!this.manuallySelected && !isValid) ||
        (this.manuallySelected && !this.vehicleData.vehicle.customerNote) ||
        !isValid
      ) {
        const nodes = document.querySelectorAll('.assetsDetails .is-error')
        if (nodes && nodes.length > 0 && nodes[0]) {
          this.$nextTick(() => {
            scrollToElement(nodes[0])
          })
        }
        this.saveBtnLoading = false
        this.saveAndNextBtnLoading = false
        return
      }

      this.isDialogLoading = true
      await this.$store.dispatch('applications/updateVehicle', {
        vehicle: this.vehicleData.vehicle,
        id,
        applicationId,
      })
      this.isDialogLoading = false
      this.saveBtnLoading = false
      this.saveAndNextBtnLoading = false
      if (isRedirect) {
        if (this.isCommercial) {
          return this.$nuxt.$emit('changeTab', {
            tab: FINANCE_APPLICATION_TAB_LOAN_DETAILS_KEY,
          })
        }
        await changeRoute({
          route: this.$route,
          router: this.$router,
          path: `/finance/applications/${id}/${applicationId}`,
          query: {
            maintab: 'loanDetails',
          },
        })
        this.$nuxt.$emit('updateComponent', 'loanDetails')
      }
    },
    async backToPrevious() {
      if (this.isCommercial) {
        return this.$nuxt.$emit('changeTab', {
          tab: MAINTAB_CREDIT_KEY,
        })
      }
      await changeRoute({
        route: this.$route,
        router: this.$router,
        query: { ...this.$route.query, maintab: MAINTAB_CREDIT_KEY },
      })
      this.$nuxt.$emit('updateComponent', MAINTAB_CREDIT_KEY)
    },
  },
}
