var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loanDetailsForm ? _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }],
    staticClass: "loanWithdrawn pb-2",
    attrs: {
      "visible": _vm.dialogOpened,
      "width": "460px",
      "modalAppendToBody": false,
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "destroy-on-close": "",
      "show-close": false,
      "data-selector": "l-withdraw-modal"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogOpened = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('el-button', {
          attrs: {
            "type": "text",
            "data-selector": "lwm-cancel-btn"
          },
          on: {
            "click": function ($event) {
              return _vm.closeDialog();
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))]), _vm._v(" "), _c('el-button', {
          staticStyle: {
            "padding": "6.5px 12px"
          },
          attrs: {
            "type": "primary",
            "data-selector": "lwm-yes-btn"
          },
          on: {
            "click": function ($event) {
              return _vm.submitForm();
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_YES_PROCEED))])];
      },
      proxy: true
    }], null, false, 2436639290)
  }, [_c('el-form', {
    ref: "loanDetailsForm",
    attrs: {
      "rules": _vm.rules,
      "model": _vm.loanData
    }
  }, [_vm.loanData && _vm.loanData.loanDetails ? _c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/warning-employement.svg")
    }
  }), _vm._v(" "), _c('h3', {
    staticClass: "title text-sm mt-2 text-black",
    attrs: {
      "data-selector": "lwm-title"
    }
  }, [_vm._v(_vm._s(_vm.title))]), _vm._v(" "), _c('p', {
    staticClass: "description text-sm mt-4 mb-4",
    attrs: {
      "data-selector": "lwm-subtitle"
    }
  }, [_vm._v(_vm._s(_vm.subtitle))]), _vm._v(" "), _c('p', {
    staticClass: "title reason text-xs my-2",
    staticStyle: {
      "font-weight": "800"
    }
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("* ")]), _vm._v(" " + _vm._s(_vm.reason))]), _vm._v(" "), _c('el-form-item', {
    staticClass: "mb-0 justify-center",
    attrs: {
      "prop": "withdrawnReasonType",
      "data-selector": "lwm-withdraw-reason-input-container"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.loanData.withdrawnReasonType,
      callback: function ($$v) {
        _vm.$set(_vm.loanData, "withdrawnReasonType", $$v);
      },
      expression: "loanData.withdrawnReasonType"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "lwm-withdraw-reason-options-container"
    }
  }, _vm._l(_vm.LOAN_WITHDRAWN_REASONS_OPTIONS, function (item) {
    return _c('el-option', {
      key: item.key,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)])], 1), _vm._v(" "), _vm.isWithdrawOther ? _c('el-form-item', {
    staticClass: "mt-4 d-block w-full",
    attrs: {
      "prop": "withdrawnReason",
      "data-selector": "lwm-other-reason-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "placeholder": "Write your text here ...",
      "autocomplete": "off",
      "data-selector": "lwm-other-reason-input"
    },
    model: {
      value: _vm.loanData.withdrawnReason,
      callback: function ($$v) {
        _vm.$set(_vm.loanData, "withdrawnReason", $$v);
      },
      expression: "loanData.withdrawnReason"
    }
  })], 1) : _vm._e()], 1) : _vm._e()])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }