var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isRequestCodeLoading,
      expression: "isRequestCodeLoading"
    }],
    staticClass: "changePasswordDialog",
    attrs: {
      "visible": _vm.shouldShowChangePasswordDialog,
      "width": "28%",
      "center": "",
      "close-on-press-escape": false,
      "close-on-click-modal": false,
      "show-close": false,
      "modalAppendToBody": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.shouldShowChangePasswordDialog = $event;
      }
    }
  }, [_c('div', {
    staticClass: "authentication-wrapper"
  }, [_c('div', {
    staticClass: "el-dialog__header custom-dialog-header"
  }, [_c('p', {
    staticClass: "el-dialog__title mb-2 font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_CHANGE_PASSWORD))])]), _vm._v(" "), _c('el-form', {
    ref: "changePasswordForm",
    attrs: {
      "model": _vm.loginData,
      "rules": _vm.rules
    }
  }, [_c('el-form-item', {
    staticClass: "type-password",
    attrs: {
      "label": "Old Password",
      "prop": "oldPassword"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Enter Old Password",
      "type": _vm.passwordType,
      "autocomplete": "off"
    },
    model: {
      value: _vm.loginData.oldPassword,
      callback: function ($$v) {
        _vm.$set(_vm.loginData, "oldPassword", $$v);
      },
      expression: "loginData.oldPassword"
    }
  }), _vm._v(" "), _c('el-link', {
    attrs: {
      "underline": false
    }
  }, [_vm.passwordType == 'password' ? _c('img', {
    attrs: {
      "src": require("../assets/icons/show-password.svg"),
      "width": "14",
      "height": "14",
      "alt": "Show Password"
    },
    on: {
      "click": function ($event) {
        _vm.passwordType = 'text';
      }
    }
  }) : _c('img', {
    attrs: {
      "src": require("../assets/icons/hide-password.svg"),
      "width": "14",
      "height": "14",
      "alt": "Hide Password"
    },
    on: {
      "click": function ($event) {
        _vm.passwordType = 'password';
      }
    }
  })])], 1), _vm._v(" "), _c('el-form-item', {
    staticClass: "type-password",
    attrs: {
      "label": "New Password",
      "prop": "newPassword"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Enter New Password",
      "type": _vm.newPasswordType,
      "autocomplete": "off"
    },
    model: {
      value: _vm.loginData.newPassword,
      callback: function ($$v) {
        _vm.$set(_vm.loginData, "newPassword", $$v);
      },
      expression: "loginData.newPassword"
    }
  }), _vm._v(" "), _c('el-link', {
    attrs: {
      "underline": false
    }
  }, [_vm.newPasswordType == 'password' ? _c('img', {
    attrs: {
      "src": require("../assets/icons/show-password.svg"),
      "width": "14",
      "height": "14",
      "alt": "Show Password"
    },
    on: {
      "click": function ($event) {
        _vm.newPasswordType = 'text';
      }
    }
  }) : _c('img', {
    attrs: {
      "src": require("../assets/icons/hide-password.svg"),
      "width": "14",
      "height": "14",
      "alt": "Hide Password"
    },
    on: {
      "click": function ($event) {
        _vm.newPasswordType = 'password';
      }
    }
  })])], 1), _vm._v(" "), _c('div', {
    staticClass: "text-center mb-6"
  }, [_c('p', {
    staticClass: "text-sm mb-2 text-light-black mt-8"
  }, [_vm._v("For any technical assistance")]), _vm._v(" "), _c('a', {
    staticClass: "text-sm text-primary font-extrabold",
    attrs: {
      "href": "mailto:tech-support@carclarity.com.au?subject=Unable to change password",
      "target": "_blank"
    }
  }, [_vm._v("\n          Contact Support\n        ")])]), _vm._v(" "), _c('hr', {
    staticStyle: {
      "border-top": "0.1px",
      "margin": "0 -1rem"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "mx-1 mt-4"
  }, [_c('el-button', {
    staticClass: "btn-cancle",
    attrs: {
      "type": "text"
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))]), _vm._v(" "), _c('el-button', {
    staticClass: "float-right mb-4",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.authenticationChangePass
    }
  }, [_vm._v("\n          Update\n        ")])], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }