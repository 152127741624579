//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  LABEL_CREDIT_REPORT_GENERATED,
  LABEL_CREDIT_REPORT_ERROR,
  LABEL_FOR_COMPREHENSIVE,
  LABEL_CREDIT_HISTORY,
  LABEL_FOR_ONE_SCORE,
  LABEL_FOR_CLOSE,
  LABEL_CREDIT_AGENT,
  LABEL_CREDIT_ADMIN,
} from '~/constants/applications'
import { getSignedURL } from '@/utils/url'
import { mapErrorType } from '~/helpers/creditScore'
import { format } from 'date-fns'
import { validateUserRoles } from '~/helpers/user'
import {
  USER_TYPE_ADMIN,
  USER_TYPE_SUPER_ADMIN,
  USER_TYPE_AGENT,
  ERROR_TYPE_FILE_BAN,
  BODY_MESSAGE_FOR_ERROR_TYPE_FILE_BAN,
} from '~/constants'
export default {
  props: {
    primaryData: {
      type: Object,
      default: () => ({}),
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    isApplicationSettled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      LABEL_CREDIT_REPORT_GENERATED,
      LABEL_CREDIT_REPORT_ERROR,
      LABEL_FOR_ONE_SCORE,
      LABEL_FOR_COMPREHENSIVE,
      LABEL_CREDIT_HISTORY,
      LABEL_FOR_CLOSE,
      LABEL_CREDIT_AGENT,
      LABEL_CREDIT_ADMIN,
      USER_TYPE_ADMIN,
      USER_TYPE_SUPER_ADMIN,
      USER_TYPE_AGENT,
      openDrawer: false,
      dialogFor: null,
      applicantId: null,
      format,
      allCreditData: {},
      creditEnguiries: {},
      getSignedURL,
      mapErrorType,
      ERROR_TYPE_FILE_BAN,
      BODY_MESSAGE_FOR_ERROR_TYPE_FILE_BAN,
    }
  },
  mounted() {
    this.$nuxt.$on('show-drawer', (value) => {
      this.openDrawer = value.show
      const sortedObj = {}
      let data = value.creditData
      data.creditEnquiries.map((e) => {
        const fk = format(new Date(e.createdAt), 'LLL yyyy')
        sortedObj[fk] = sortedObj[fk] || []
        sortedObj[fk].push(e)
      })
      const sortedArr = Object.entries(sortedObj).sort(
        (a, b) => new Date(a[0]) - new Date(b[0])
      )
      this.creditEnguiries = sortedArr
      this.allCreditData = value.creditData
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('show-drawer')
  },
  methods: {
    checkTypes(systemRoleTypes, inputTypes) {
      return validateUserRoles(systemRoleTypes, inputTypes)
    },
  },
}
