var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }],
    staticClass: "editSubmissionConfirmDialog",
    attrs: {
      "visible": _vm.dialogOpened,
      "width": "460px",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "destroy-on-close": "",
      "show-close": false,
      "data-selector": "edit-submission-confirm-modal"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogOpened = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('el-button', {
          attrs: {
            "type": "text",
            "data-selector": "esc-m-cancel-btn"
          },
          on: {
            "click": function ($event) {
              return _vm.closeDialog();
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))]), _vm._v(" "), _c('el-button', {
          staticStyle: {
            "padding": "6.5px 12px"
          },
          attrs: {
            "type": "primary",
            "data-selector": "esc-m-yes-btn"
          },
          on: {
            "click": function ($event) {
              return _vm.submitForm();
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_YES_PROCEED))])];
      },
      proxy: true
    }])
  }, [_c('el-form', [_c('div', {
    staticClass: "text-center"
  }, [_c('h3', {
    staticClass: "title text-sm"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/warning-employement.svg")
    }
  })]), _vm._v(" "), _c('h3', {
    staticClass: "title text-sm mt-2",
    attrs: {
      "data-selector": "esc-m-title"
    }
  }, [_vm._v(_vm._s(_vm.title))]), _vm._v(" "), _c('p', {
    staticClass: "description text-sm mt-4 break-normal",
    attrs: {
      "data-selector": "esc-m-subtitle"
    }
  }, [_vm._v(_vm._s(_vm.subtitle))])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }