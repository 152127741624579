var render = function () {
  var _vm$applicationDetail, _vm$vehicleDetails, _vm$applicationDetail2, _vm$vehicleDetails2, _vm$vehicleDetails3, _vm$vehicleDetails4, _vm$vehicleDetails5, _vm$vehicleDetails6, _vm$vehicleDetails7, _vm$vehicleDetails8, _vm$vehicleDetails9, _vm$vehicleDetails10, _vm$vehicleDetails11, _vm$vehicleDetails12, _vm$vehicleDetails13, _vm$vehicleDetails14, _vm$vehicleDetails15, _vm$vehicleDetails16, _vm$vehicleDetails17;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "application-form requirements-form"
  }, [_c('el-form', {
    ref: "requirementsForm",
    attrs: {
      "rules": _vm.rules,
      "model": _vm.requirementsForm
    },
    on: {
      "submit": _vm.submitForm
    }
  }, [_c('el-card', [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_OBJECTIVES_AND_REQUIREMENT))])]), _vm._v(" "), _c('div', [_c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-sm"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("*")]), _vm._v(_vm._s(_vm.LABEL_FOR_WHAT_IS_THE_PURPOSE_OF_OBTAINING))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "purposeOfObtainingIncreasingOrRefinanceCredit"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.requirementsForm.purposeOfObtainingIncreasingOrRefinanceCredit,
      callback: function ($$v) {
        _vm.$set(_vm.requirementsForm, "purposeOfObtainingIncreasingOrRefinanceCredit", $$v);
      },
      expression: "\n                  requirementsForm.purposeOfObtainingIncreasingOrRefinanceCredit\n                "
    }
  }, _vm._l(_vm.PURPOSE_OF_OBTAINING_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.value,
        "value": data.value
      }
    }, [_vm._v(_vm._s(data.value))]);
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-2",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-sm"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("*")]), _vm._v(_vm._s(_vm.LABEL_FOR_WHAT_IS_THE_CUSTOMER_BUDGETTED))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "monthlyRepaymentBudget"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.requirementsForm.monthlyRepaymentBudget,
      callback: function ($$v) {
        _vm.$set(_vm.requirementsForm, "monthlyRepaymentBudget", $$v);
      },
      expression: "requirementsForm.monthlyRepaymentBudget"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-sm"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("*")]), _vm._v(_vm._s(_vm.LABEL_FOR_WHAT_LOAN_FEATURES_ARE_IMPORTANT))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_vm.requirementsForm.customerExpectedLoanFeatures ? _c('el-form-item', {
    staticClass: "mt-2",
    attrs: {
      "gutter": 40,
      "prop": "customerExpectedLoanFeatures"
    }
  }, [_c('el-checkbox-group', {
    staticStyle: {
      "border": "1px solid #dcdfe6",
      "border-radius": "0.25rem"
    },
    model: {
      value: _vm.requirementsForm.customerExpectedLoanFeatures,
      callback: function ($$v) {
        _vm.$set(_vm.requirementsForm, "customerExpectedLoanFeatures", $$v);
      },
      expression: "requirementsForm.customerExpectedLoanFeatures"
    }
  }, _vm._l(_vm.LOAN_FEATURE_OPTIONS, function (data) {
    return _c('p', {
      key: data.key,
      staticClass: "text-sm",
      staticStyle: {
        "margin-left": "20px"
      }
    }, [_c('el-checkbox', {
      attrs: {
        "label": data.value
      }
    })], 1);
  }), 0)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-2",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-sm"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("*")]), _vm._v(_vm._s(_vm.LABEL_DO_YOU_REQUIRE_CREDIT_TO_PAY_FOR_ADDITIONAL))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "isCreditRequiredForAdditionalCosts"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.requirementsForm.isCreditRequiredForAdditionalCosts,
      callback: function ($$v) {
        _vm.$set(_vm.requirementsForm, "isCreditRequiredForAdditionalCosts", $$v);
      },
      expression: "requirementsForm.isCreditRequiredForAdditionalCosts"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": true
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c('el-radio-button', {
    attrs: {
      "label": false
    }
  }, [_vm._v("No")])], 1)], 1)], 1)], 1), _vm._v(" "), _vm.requirementsForm.isCreditRequiredForAdditionalCosts ? _c('el-row', {
    staticClass: "mt-2",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-sm"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("*")]), _vm._v(_vm._s(_vm.LABEL_ARE_YOU_AWARE_OF_THE_COSTS))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "isAwareOfFinancingCosts"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.requirementsForm.isAwareOfFinancingCosts,
      callback: function ($$v) {
        _vm.$set(_vm.requirementsForm, "isAwareOfFinancingCosts", $$v);
      },
      expression: "requirementsForm.isAwareOfFinancingCosts"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": true
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c('el-radio-button', {
    attrs: {
      "label": false
    }
  }, [_vm._v("No")])], 1)], 1)], 1)], 1) : _vm._e()], 1)]), _vm._v(" "), _c('el-card', [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.REQUESTED_LOAN_DETAILS) + "\n          "), _c('a', {
    staticStyle: {
      "color": "#7100da"
    },
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": function ($event) {
        return _vm.openRequestLoanDetail();
      }
    }
  }, [_vm._v(_vm._s(_vm.DOCUMENT_EDIT_LINK))])])]), _vm._v(" "), _c('div', [_c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_LOAN_TYPE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2"
  }, [_vm._v(_vm._s(((_vm$applicationDetail = _vm.applicationDetails) === null || _vm$applicationDetail === void 0 ? void 0 : _vm$applicationDetail.loanType) || _vm.LABEL_NOT_AVAILABLE))])])])], 1), _vm._v(" "), _c('hr', {
    staticClass: "mb-0 border-t border-light-grey mt-4 child-w-full",
    staticStyle: {
      "border-top": "0.1px"
    }
  }), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_PURCHASE_PRICE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2"
  }, [_vm._v(_vm._s(_vm.applicationDetails.purchasePrice >= 0 ? _vm.toCurrency(_vm.applicationDetails.purchasePrice) : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_TRADE_IN))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2"
  }, [_vm._v("\n                " + _vm._s(_vm.vehicleDetails.tradeInAmount >= 0 ? _vm.toCurrency(_vm.vehicleDetails.tradeInAmount) : _vm.LABEL_NOT_AVAILABLE) + "\n              ")])])])], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_DEPOSIT))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2"
  }, [_vm._v(_vm._s(((_vm$vehicleDetails = _vm.vehicleDetails) === null || _vm$vehicleDetails === void 0 ? void 0 : _vm$vehicleDetails.financeDepositAmount) >= 0 ? _vm.toCurrency(_vm.vehicleDetails.financeDepositAmount) : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_LOAN_AMOUNT))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2"
  }, [_vm._v(_vm._s(_vm.applicationDetails.loanAmount >= 0 ? _vm.toCurrency(_vm.applicationDetails.loanAmount) : _vm.LABEL_NOT_AVAILABLE))])])])], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_BALLOON))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2"
  }, [_vm._v(_vm._s((_vm$applicationDetail2 = _vm.applicationDetails) !== null && _vm$applicationDetail2 !== void 0 && _vm$applicationDetail2.balloonPct ? parseFloat(_vm.loaapplicationDetails.balloonPct).toFixed(2) + '%' : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_BALLOON_ONLY))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2"
  }, [_vm._v(_vm._s(_vm.applicationDetails.balloonAmount >= 0 ? _vm.toCurrency(_vm.applicationDetails.balloonAmount) : _vm.LABEL_NOT_AVAILABLE))])])])], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_LOAN_TERM))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2"
  }, [_vm._v(_vm._s(_vm.applicationDetails.loanTermYears ? _vm.applicationDetails.loanTermYears + " " + _vm.LABEL_YEARS : _vm.LABEL_NOT_AVAILABLE))])])])], 1)], 1)]), _vm._v(" "), _vm.applicationDetails.loanType !== 'Personal Loan' ? _c('el-card', [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_ASSET_SUMMARY) + "\n          "), _c('a', {
    staticStyle: {
      "color": "#7100da"
    },
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": function ($event) {
        return _vm.openRequestAssetDetail();
      }
    }
  }, [_vm._v(_vm._s(_vm.DOCUMENT_EDIT_LINK))])])]), _vm._v(" "), _c('div', [((_vm$vehicleDetails2 = _vm.vehicleDetails) === null || _vm$vehicleDetails2 === void 0 ? void 0 : _vm$vehicleDetails2.assetType) === _vm.LABEL_DOCUMENT_VEHICLE ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_VEHICLE))])]), _vm._v(" "), _c('div', [(_vm$vehicleDetails3 = _vm.vehicleDetails) !== null && _vm$vehicleDetails3 !== void 0 && _vm$vehicleDetails3.year && (_vm$vehicleDetails4 = _vm.vehicleDetails) !== null && _vm$vehicleDetails4 !== void 0 && _vm$vehicleDetails4.makeDescription && (_vm$vehicleDetails5 = _vm.vehicleDetails) !== null && _vm$vehicleDetails5 !== void 0 && _vm$vehicleDetails5.familyDescription ? _c('p', {
    staticClass: "text-sm mt-2"
  }, [_vm._v(_vm._s(((_vm$vehicleDetails6 = _vm.vehicleDetails) === null || _vm$vehicleDetails6 === void 0 ? void 0 : _vm$vehicleDetails6.year) || '') + "\n                " + _vm._s(((_vm$vehicleDetails7 = _vm.vehicleDetails) === null || _vm$vehicleDetails7 === void 0 ? void 0 : _vm$vehicleDetails7.makeDescription) || '') + "\n                " + _vm._s(((_vm$vehicleDetails8 = _vm.vehicleDetails) === null || _vm$vehicleDetails8 === void 0 ? void 0 : _vm$vehicleDetails8.familyDescription) || '') + "\n              ")]) : _c('p', {
    staticClass: "text-sm mt-2"
  }, [_vm._v(_vm._s(_vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_MODEL_TYPE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2"
  }, [_vm._v(_vm._s(((_vm$vehicleDetails9 = _vm.vehicleDetails) === null || _vm$vehicleDetails9 === void 0 ? void 0 : _vm$vehicleDetails9.codeDescription) || _vm.LABEL_NOT_AVAILABLE))])])])], 1) : _vm._e(), _vm._v(" "), (_vm$vehicleDetails10 = _vm.vehicleDetails) !== null && _vm$vehicleDetails10 !== void 0 && _vm$vehicleDetails10.assetType ? _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 40
    }
  }, [((_vm$vehicleDetails11 = _vm.vehicleDetails) === null || _vm$vehicleDetails11 === void 0 ? void 0 : _vm$vehicleDetails11.assetType) === _vm.LABEL_ASSET_TYPE_OPTION_OTHER ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_ASSET_TYPE_OPTION_OTHER))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2"
  }, [_vm._v(_vm._s(((_vm$vehicleDetails12 = _vm.vehicleDetails) === null || _vm$vehicleDetails12 === void 0 ? void 0 : _vm$vehicleDetails12.assetDetail) || _vm.LABEL_NOT_AVAILABLE))])])]) : _vm._e(), _vm._v(" "), ((_vm$vehicleDetails13 = _vm.vehicleDetails) === null || _vm$vehicleDetails13 === void 0 ? void 0 : _vm$vehicleDetails13.assetType) === _vm.LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2"
  }, [_vm._v(_vm._s(((_vm$vehicleDetails14 = _vm.vehicleDetails) === null || _vm$vehicleDetails14 === void 0 ? void 0 : _vm$vehicleDetails14.assetDetail) || _vm.LABEL_NOT_AVAILABLE))])])]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), ((_vm$vehicleDetails15 = _vm.vehicleDetails) === null || _vm$vehicleDetails15 === void 0 ? void 0 : _vm$vehicleDetails15.assetType) !== _vm.LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY ? _c('el-row', {
    staticClass: "mt-4",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_CONDITION))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2"
  }, [_vm._v(_vm._s(((_vm$vehicleDetails16 = _vm.vehicleDetails) === null || _vm$vehicleDetails16 === void 0 ? void 0 : _vm$vehicleDetails16.condition) || _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_SALE_TYPE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2"
  }, [_vm._v(_vm._s(((_vm$vehicleDetails17 = _vm.vehicleDetails) === null || _vm$vehicleDetails17 === void 0 ? void 0 : _vm$vehicleDetails17.purchaseSource) || _vm.LABEL_NOT_AVAILABLE))])])])], 1) : _vm._e()], 1)]) : _vm._e(), _vm._v(" "), _c('request-loan-details-dialog', {
    attrs: {
      "application-details": _vm.applicationDetails,
      "vehicle-details": _vm.vehicleDetails
    }
  }), _vm._v(" "), _c('asset-details-summary-dialog', {
    attrs: {
      "application-details": _vm.applicationDetails,
      "vehicle-details": _vm.vehicleDetails
    }
  }), _vm._v(" "), _c('tab-footer', {
    attrs: {
      "save-btn-loading": _vm.saveBtnLoading,
      "save-and-next-btn-loading": _vm.saveAndNextBtnLoading
    },
    on: {
      "submitForm": _vm.submitForm,
      "saveAndNext": _vm.submitForm
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }