var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.formRules.length ? _c('div', {
    staticClass: "el-input",
    class: {
      'is-disabled': _vm.disabled
    }
  }, [_c('custom-numeric', _vm._b({
    class: 'el-input__inner ' + _vm.inputClass,
    attrs: {
      "separator": ",",
      "empty-value": _vm.emptyValue,
      "output-type": "String",
      "value": _vm.value,
      "data-selector": _vm.dataSelector
    },
    on: {
      "input": _vm.onInput,
      "change": _vm.onChange,
      "blur": _vm.onBlur
    },
    nativeOn: {
      "keypress": function ($event) {
        return _vm.onKeyPress.apply(null, arguments);
      },
      "keydown": function ($event) {
        return _vm.onKeyDown.apply(null, arguments);
      },
      "focus": function ($event) {
        return $event.target.select();
      }
    }
  }, 'custom-numeric', {
    disabled: _vm.disabled,
    min: _vm.min,
    max: _vm.max,
    placeholder: _vm.placeholder,
    precision: _vm.precision,
    value: _vm.value
  }, false))], 1) : _c('el-form-item', {
    class: _vm.formClassName,
    attrs: {
      "prop": _vm.formProp,
      "label": _vm.label,
      "rules": _vm.formRules
    }
  }, [_c('div', {
    staticClass: "el-input",
    class: {
      'is-disabled': _vm.disabled
    }
  }, [_c('custom-numeric', _vm._b({
    class: 'el-input__inner ' + _vm.inputClass,
    attrs: {
      "separator": ",",
      "empty-value": "0",
      "output-type": "String",
      "value": _vm.value,
      "data-selector": _vm.dataSelector
    },
    on: {
      "input": _vm.onInput,
      "change": _vm.onChange,
      "blur": _vm.onBlur
    },
    nativeOn: {
      "keypress": function ($event) {
        return _vm.onKeyPress.apply(null, arguments);
      },
      "keydown": function ($event) {
        return _vm.onKeyDown.apply(null, arguments);
      },
      "focus": function ($event) {
        return $event.target.select();
      }
    }
  }, 'custom-numeric', {
    disabled: _vm.disabled,
    min: _vm.min,
    max: _vm.max,
    placeholder: _vm.placeholder,
    precision: _vm.precision,
    value: _vm.value
  }, false))], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }