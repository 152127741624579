//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  LABEL_MARK_APPLICATION_AS_SETTLED,
  LABEL_FOR_MISSING_DOCUMENTS,
  LABEL_FOR_MISSING_DOCUMENTS_DESCRIPTION,
  REGEX_ERROR_MESSAGE_FOR_DOCUMENTS_ARE_MISSING,
  DEFAULT_DOCUMENT_TYPES,
  MAINTAB_DOCS_KEY,
  MAINTAB_SETTLEMENT_KEY,
  SETTLEMENT_MENU_DOCUMENT_KEY,
  LABEL_CANCEL,
  LABEL_GO_TO_DOCUMENTS,
  LABEL_GO_TO_SETTLEMENT_DOCUMENT,
} from '~/constants/applications'
import { changeRoute } from '~/helpers/changeRoute'
import { axiosErrorMessageExtractor } from '~/utils/error'
export default {
  props: {
    dialogOpened: {
      type: Boolean,
      default: false,
    },
    errMessage: {
      type: String,
      default: '',
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
    isFromSettlement: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      LABEL_CANCEL,
      LABEL_GO_TO_DOCUMENTS,
      LABEL_GO_TO_SETTLEMENT_DOCUMENT,
      LABEL_MARK_APPLICATION_AS_SETTLED,
      LABEL_FOR_MISSING_DOCUMENTS,
      LABEL_FOR_MISSING_DOCUMENTS_DESCRIPTION,
      isDialogLoading: false,
      missingDocuments: [],
      shouldIncludeInDefaultDocument: false,
    }
  },
  mounted() {
    this.$nuxt.$on('close-loader', () => {
      this.isDialogLoading = false
      this.closeDialog()
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },
  watch: {
    dialogOpened(val) {
      if (val) this.getMissingDocuments(this.errMessage)
    },
  },
  methods: {
    getMissingDocuments(errMessage) {
      let result = axiosErrorMessageExtractor(errMessage).match(
        REGEX_ERROR_MESSAGE_FOR_DOCUMENTS_ARE_MISSING
      )[1]
      this.missingDocuments = result.replace(/(\[)|(\])/g, '').split(',')
      if (this.missingDocuments) {
        this.shouldIncludeInDefaultDocument = false
        const mapDefaultDocumentsTypesByKey = Object.create(null)
        DEFAULT_DOCUMENT_TYPES.forEach((doc) => {
          if (!mapDefaultDocumentsTypesByKey[doc.key]) {
            mapDefaultDocumentsTypesByKey[doc.key] = doc
          }
        })
        this.missingDocuments.forEach((missDoc, i) => {
          const documents = missDoc.includes('/')
            ? missDoc.split('/')
            : [missDoc]
          if (documents.length) {
            documents.forEach((x) => {
              if (mapDefaultDocumentsTypesByKey[x.trim()]) {
                this.shouldIncludeInDefaultDocument = true
                this.missingDocuments.splice(i, 1)
                this.missingDocuments.unshift(missDoc)
              }
            })
          }
        })
      }
    },
    async closeMissingDocDialogAndRedictToDocumentTab() {
      this.closeDialog()
      if (this.shouldIncludeInDefaultDocument || !this.isFromSettlement) {
        const { id, applicationId } = this.$route.params
        if (!this.isCommercial) {
          const tab = this.shouldIncludeInDefaultDocument
            ? MAINTAB_DOCS_KEY
            : MAINTAB_SETTLEMENT_KEY
          await changeRoute({
            route: this.$route,
            router: this.$router,
            path: `/finance/applications/${id}/${applicationId}`,
            query: {
              maintab: tab,
            },
          })
          this.$nuxt.$emit('updateComponent', tab)
        }
      } else {
        const tabName =
          MAINTAB_SETTLEMENT_KEY + '|' + SETTLEMENT_MENU_DOCUMENT_KEY
        this.$nuxt.$emit('updateSettlementSubComponent', tabName)
      }
    },
    closeDialog() {
      this.$emit('closeMissingDocDialog')
    },
  },
}
