//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  LABEL_ASSET_TYPE_OPTION_VEHICLE,
  LABEL_DOCUMENT_LOAN_AMOUNT,
  LABEL_LOAN_TREM,
  LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE,
  LABEL_FOR_BALLOON_PERCENT,
  LABEL_FOR_CLOSE,
  LABEL_FOR_DEPOSIT,
  LABEL_TRADE_IN_EQUITY,
  LABEL_LOAN_BALLOON_DOLLAR,
  LABEL_LOAN_DETAILS,
  LABEL_YEARS,
  LABEL_CUSTOMER_RATE,
} from '@/constants/applications'
import { toCurrency } from '@/helpers/currency'
import { format } from 'date-fns'

import {
  LABEL_FOR_COMPARISON_RATE_FOR_LENDER,
  LABEL_FOR_CONDITION,
  LABEL_FOR_CREATED_AT,
  LABEL_FOR_FORTNIGHT,
  LABEL_FOR_MONTLY,
  LABEL_FOR_REPAYMENTS,
  LABEL_FOR_SALE_TYPE,
  LABEL_FOR_SUMMARY,
  LABEL_FOR_WEEK,
  TITLE_FOR_ASSET_DETAILS,
  LABEL_SELECTED_BY_CUSTOMER,
  LABEL_FIXED_RATE,
} from '~/constants'
import { LABEL_ASSET_YEAR } from '~/constants/referrals'
export default {
  name: 'lenderDetailsDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    lenderDetailsData: {
      type: Object,
      default: () => ({}),
    },
    loanDetails: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      format,
      LABEL_ASSET_YEAR,
      LABEL_FOR_CONDITION,
      LABEL_FOR_SALE_TYPE,
      LABEL_CUSTOMER_RATE,
      LABEL_FOR_COMPARISON_RATE_FOR_LENDER,
      LABEL_FOR_MONTLY,
      LABEL_FOR_SUMMARY,
      LABEL_FOR_CREATED_AT,
      LABEL_DOCUMENT_LOAN_AMOUNT,
      LABEL_FOR_CLOSE,
      LABEL_FOR_REPAYMENTS,
      LABEL_FOR_FORTNIGHT,
      LABEL_FOR_WEEK,
      LABEL_LOAN_DETAILS,
      LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE,
      LABEL_FOR_DEPOSIT,
      LABEL_TRADE_IN_EQUITY,
      LABEL_FOR_BALLOON_PERCENT,
      LABEL_LOAN_BALLOON_DOLLAR,
      LABEL_LOAN_TREM,
      TITLE_FOR_ASSET_DETAILS,
      LABEL_ASSET_TYPE_OPTION_VEHICLE,
      LABEL_SELECTED_BY_CUSTOMER,
      LABEL_FIXED_RATE,
      LABEL_YEARS,
    }
  },
  methods: {
    numberWithCommas(x) {
      return toCurrency(x)
    },
    closeDialog() {
      this.$nuxt.$emit('close-lender-detail-dialog', false)
    },
  },
}
