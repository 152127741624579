export const idString = (id) => {
  // handle case where it is a populated path
  if (_.isObject(id)) {
    return id?._id?.toString()
  }
  return id ? id.toString() : undefined
}

export const mongoObjectId = () => {
  // eslint-disable-next-line no-bitwise
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16)
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, () => {
        // eslint-disable-next-line no-bitwise
        return ((Math.random() * 16) | 0).toString(16)
      })
      .toLowerCase()
  )
}
