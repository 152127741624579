var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    staticClass: "creditfetch",
    attrs: {
      "visible": _vm.showDialog,
      "width": "460px",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "destroy-on-close": "",
      "center": "",
      "show-close": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.showDialog = $event;
      }
    }
  }, [_c('div', {
    staticClass: "el-dialog__header custom-dialog-header"
  }, [_c('img', {
    attrs: {
      "src": require("assets/icons/warning-employement.svg"),
      "alt": "Warning"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "el-dialog__title mt-2 font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_UNABLE_FETCH_PDF))])]), _vm._v(" "), _c('p', {
    staticClass: "text-center px-6 my-6",
    staticStyle: {
      "word-break": "break-word"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.CREDIT_FETCH_ERROR_DISCRIPTION) + "\n  ")]), _vm._v(" "), _c('p', {
    staticClass: "text-center px-6 my-5",
    staticStyle: {
      "word-break": "break-word"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.CREDIT_FETCH_ERROR_DISCRIPTION_SECOND) + "\n  ")]), _vm._v(" "), _c('div', {
    staticClass: "dialog-footer text-center",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": function ($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CLOSE))])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }