var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isSubmitLoading || _vm.isEmailAndSubmitLoading,
      expression: "isSubmitLoading || isEmailAndSubmitLoading"
    }],
    staticClass: "document-dialog",
    attrs: {
      "visible": _vm.showDialog,
      "width": "49%",
      "center": "",
      "close-on-press-escape": false,
      "close-on-click-modal": false,
      "show-close": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.showDialog = $event;
      }
    }
  }, [_c('div', {
    staticClass: "document-list"
  }, [_c('span', {
    attrs: {
      "data-selector": "modal-title"
    }
  }, [_c('b', {
    staticClass: "text-black"
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_DOCUMENT))]), _vm._v("\n      " + _vm._s(_vm.getDialogFor))]), _vm._v(" "), !_vm.isFromSettlementTab ? _c('NotificationBar', {
    staticClass: "mt-2 el-alert--warning el-alert-message-warning document-notification-bar",
    attrs: {
      "message-type": "warning",
      "description": _vm.LABEL_EMAIL_AND_ADD_IS_THE_ONLY_AVAIALABLE_ELECTRONIC_BANK_STATEMENT,
      "has-button": false,
      "data-selector": "email-add-alert"
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "application-form mt-4"
  }, [_c('el-row', {
    staticClass: "flex items flex-wrap",
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('el-card', {
    staticClass: "card-child-p-0 border-y-collapse box-fixed-height",
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "available-header-text"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_AVAILABLE) + " (" + _vm._s(_vm.availableCount) + ")")])]), _vm._v(" "), _c('div', {
    staticClass: "my-4"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Type something",
      "data-selector": "doc-name-input"
    },
    model: {
      value: _vm.searchDoc,
      callback: function ($$v) {
        _vm.searchDoc = $$v;
      },
      expression: "searchDoc"
    }
  }, [_c('i', {
    staticClass: "el-input__icon el-icon-search",
    attrs: {
      "slot": "prefix"
    },
    slot: "prefix"
  })])], 1), _vm._v(" "), _c('div', {
    staticClass: "rounded-2 border border-light-grey"
  }, _vm._l(_vm.availableDocs, function (doc, ind) {
    return doc.selected === false ? _c('div', {
      key: doc.key,
      staticClass: "border-b border-light-grey selective-options"
    }, [_vm._v("\n                " + _vm._s(doc[ind]) + "\n                "), (_vm.searchDoc != null ? doc.key.toLowerCase().includes(_vm.searchDoc.toLowerCase()) && doc.selected === false : doc.selected === false) ? _c('el-checkbox', {
      staticClass: "text-black",
      attrs: {
        "data-selector": ("doc-name_" + (doc.value.toLowerCase().split(' ').join('-')))
      },
      on: {
        "change": function ($event) {
          return _vm.checkBoxSelect(ind);
        }
      },
      model: {
        value: _vm.availableDocs[ind].selected,
        callback: function ($$v) {
          _vm.$set(_vm.availableDocs[ind], "selected", $$v);
        },
        expression: "availableDocs[ind].selected"
      }
    }, [_vm._v(_vm._s(doc.key))]) : _vm._e()], 1) : _vm._e();
  }), 0)])], 1), _vm._v(" "), _c('el-col', {
    staticClass: "flex flex-wrap",
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('div', [_c('el-card', {
    staticClass: "card-child-p-0 border-y-collapse box-fixed-height",
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    staticClass: "clearfix p-0",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "selected-header-text"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_SELECTED) + " (" + _vm._s(_vm.selectedCount) + ")")])]), _vm._v(" "), _c('p', {
    staticClass: "my-4 word-break-cls"
  }, [_vm._v("\n                " + _vm._s(_vm.LABEL_SELECT_ONE_OR_MORE_DOCUMENT_FIRST) + "\n                "), _c('b', [_vm._v(_vm._s(_vm.LABEL_SELECT_ONE_OR_MORE_DOCUMENT_SECOND))])]), _vm._v(" "), _vm._l(_vm.availableDocs, function (doc, ind) {
    return doc.selected && !doc.added && !doc.uploadedAt ? _c('div', {
      key: doc.key,
      staticClass: "border-b border-light-grey selective-options"
    }, [_c('div', {
      staticClass: "flex el-checkbox",
      attrs: {
        "data-selector": "selected-doc-container"
      }
    }, [_c('p', {
      staticClass: "text-black white-space-normal break-word",
      attrs: {
        "data-selector": "selected-doc-name"
      }
    }, [_vm._v(_vm._s(doc.key))]), _vm._v(" "), doc.selected ? _c('i', {
      staticClass: "el-icon-close ml-auto",
      attrs: {
        "data-selector": "remove-selected-doc"
      },
      on: {
        "click": function ($event) {
          return _vm.removeSelection(ind);
        }
      }
    }) : _vm._e()])]) : _vm._e();
  })], 2), _vm._v(" "), _vm.showExtraInput ? _c('el-row', {
    staticClass: "mt-4"
  }, [_c('el-col', {
    attrs: {
      "span": 20
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": _vm.LABEL_OTHER_DOCUMENT_NAME,
      "data-selector": "other-doc-name-input"
    },
    on: {
      "input": function ($event) {
        _vm.showOtherDocErr = false;
      }
    },
    model: {
      value: _vm.extraDoc,
      callback: function ($$v) {
        _vm.extraDoc = $$v;
      },
      expression: "extraDoc"
    }
  }), _vm._v(" "), _vm.showOtherDocErr ? _c('p', {
    staticClass: "mt-2 text-xs text-danger"
  }, [_vm._v(_vm._s(_vm.ERROR_MESSAGE_FOR_OTHER_DOCUMENT_NAME))]) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
    staticClass: "ml-1 mt-2 text-center cursor-pointer",
    attrs: {
      "span": 2
    }
  }, [_c('i', {
    staticClass: "el-icon-check",
    attrs: {
      "data-selector": "add-other-doc-ok"
    },
    on: {
      "click": function ($event) {
        return _vm.addExtraDoc();
      }
    }
  })]), _vm._v(" "), _c('el-col', {
    staticClass: "ml-1 mt-2 text-center cursor-pointer",
    attrs: {
      "span": 2
    }
  }, [_c('i', {
    staticClass: "el-icon-close",
    attrs: {
      "data-selector": "add-other-doc-cancel"
    },
    on: {
      "click": function ($event) {
        return _vm.removeExtraDoc();
      }
    }
  })])], 1) : _vm._e(), _vm._v(" "), !_vm.showExtraInput ? _c('div', {
    staticClass: "mt-2 mb-2 w-full"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "icon": "el-icon-plus",
      "data-selector": "add-other-doc-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.addOtherDocumentInput();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ADD_OTHER_DOCUMENT))])], 1) : _vm._e()], 1), _vm._v(" "), !_vm.isFromSettlementTab ? _c('el-row', {
    staticClass: "mt-auto el-row px-2 py-2",
    staticStyle: {
      "background": "rgba(19, 181, 232, 0.1)"
    }
  }, [_c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('img', {
    staticClass: "ml-auto",
    attrs: {
      "src": require("@/assets/icons/info.svg")
    }
  }), _vm._v(" "), _c('p', {
    staticClass: "ml-4",
    staticStyle: {
      "word-break": "break-word"
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.LABEL_SELECT_THE_OPTION_EMAIL_AND_ADD_FIRST) + "\n                  "), _c('b', [_vm._v(_vm._s(_vm.LABEL_SELECT_THE_OPTION_EMAIL_AND_ADD_SECOND))]), _vm._v("\n                  " + _vm._s(_vm.LABEL_SELECT_THE_OPTION_EMAIL_AND_ADD_THIRD) + "\n                ")])])])], 1) : _vm._e()], 1)], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
    staticClass: "document-footer mt-4 border-t border-light-grey"
  }, [_c('el-col', {
    staticClass: "mt-4",
    attrs: {
      "span": 4
    }
  }, [_c('el-button', {
    staticClass: "w-auto cancel-btn",
    attrs: {
      "plain": "",
      "disabled": _vm.isSubmitLoading || _vm.isEmailAndSubmitLoading,
      "data-selector": "m-cancel-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.closeDocDialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))])], 1), _vm._v(" "), _c('el-col', {
    staticClass: "mt-4 justify-end flex",
    attrs: {
      "span": 20
    }
  }, [!_vm.isFromSettlementTab ? _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "loading": _vm.isEmailAndSubmitLoading,
      "disabled": _vm.isSubmitLoading || _vm.isEmailAndSubmitLoading,
      "data-selector": "m-email-add-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.emailAndSubmit();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_EMAIL_AND_ADD))]) : _vm._e(), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary",
      "loading": _vm.isSubmitLoading,
      "disabled": _vm.submitButtonDisableCondition,
      "data-selector": "m-add-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v(_vm._s(_vm.isFromSettlementTab ? _vm.LABEL_FOR_ADD_BUTTON : _vm.LABEL_ADD_ONLY))])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }