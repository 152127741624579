//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { REQUIREMENTS_RULES } from '~/rules/finance-application/requirements.rule'
import {
  LABEL_FOR_OBJECTIVES_AND_REQUIREMENT,
  LABEL_FOR_WHAT_IS_THE_PURPOSE_OF_OBTAINING,
  LABEL_FOR_WHAT_IS_THE_CUSTOMER_BUDGETTED,
  LABEL_FOR_WHAT_LOAN_FEATURES_ARE_IMPORTANT,
  BALLON_ON_THE_LOAN,
  FLEXIBILITY_TO_MAKE_ADDITIONAL_REPLAYMENT,
  NO_EARLY_EXIT_FEES,
  LOWEST_RATE_POSSIBLE,
  REQUESTED_LOAN_DETAILS,
  DOCUMENT_EDIT_LINK,
  LABEL_DOCUMENT_LOAN_TYPE,
  LABEL_DOCUMENT_CONSUMER_CAR_LOAN,
  LABEL_DOCUMENT_PURCHASE_PRICE,
  LABEL_DOCUMENT_LOAN_TERM,
  LABEL_DOCUMENT_DEPOSIT,
  LABEL_DOCUMENT_BALLOON,
  LABEL_DOCUMENT_BALLOON_ONLY,
  LABEL_DOCUMENT_TRADE_IN,
  LABEL_DOCUMENT_BUDGETED_MONTHLY_REPAYMENT,
  LABEL_DOCUMENT_LOAN_AMOUNT,
  LABEL_DOCUMENT_ASSET_SUMMARY,
  LABEL_DOCUMENT_VEHICLE,
  LABEL_DOCUMENT_MODEL_TYPE,
  LABEL_DOCUMENT_CONDITION,
  LABEL_DOCUMENT_SALE_TYPE,
  LABEL_REQUIREMENT_SAVE_BUTTON,
  LABEL_REQUIREMENT_SAVE_AND_NEXT_BUTTON,
  LOAN_FEATURE_OPTIONS,
  PURPOSE_OF_OBTAINING_OPTIONS,
  LABEL_ASSET_TYPE_OPTION_OTHER,
  LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN,
  LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY,
  LABEL_NOT_AVAILABLE,
  LABEL_DO_YOU_REQUIRE_CREDIT_TO_PAY_FOR_ADDITIONAL,
  LABEL_ARE_YOU_AWARE_OF_THE_COSTS,
  LABEL_YEARS,
} from '~/constants/applications'

import {
  ERROR_MESSAGE_FOR_PURPOSE_OF_OBTAINING,
  ERROR_MESSAGE_FOR_BUDGETTED_MONTHLY_REPAYMENT,
  ERROR_MESSAGE_FOR_NUMBER_ONLY,
  ERROR_MESSAGE_FOR_LOAN_FEATURES,
  ERROR_MESSAGE_PLEASE_SELECT_FIELD,
} from '~/constants'

import { toCurrency } from '~/helpers/currency'
import commonMixin from '~/mixins/common.mixin'
import TabFooter from '~/components/FinanceApplication/Tabs/TabFooter'
import RequestLoanDetailsDialog from '../../Dialogs/RequestLoanDetailsDialog.vue'
import AssetDetailsSummaryDialog from '../../Dialogs/AssetDetailsSummaryDialog'
export default {
  mixins: [commonMixin],
  components: {
    TabFooter,
    RequestLoanDetailsDialog,
    AssetDetailsSummaryDialog,
  },
  props: {
    applicationDetails: {
      type: Object,
      default: null,
    },
    vehicleDetails: {
      type: Object,
      default: null,
    },
    userDetails: {
      type: Object,
      default: null,
    },
    accountDetails: {
      type: Array,
      default: null,
    },
    vehicleRequestGroup: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      saveBtnLoading: false,
      saveAndNextBtnLoading: false,
      rules: REQUIREMENTS_RULES,
      requirementsForm: {
        monthlyRepaymentBudget: '',
        ...this.applicationDetails,
      },
      LABEL_FOR_OBJECTIVES_AND_REQUIREMENT,
      LABEL_FOR_WHAT_IS_THE_PURPOSE_OF_OBTAINING,
      LABEL_FOR_WHAT_IS_THE_CUSTOMER_BUDGETTED,
      LABEL_FOR_WHAT_LOAN_FEATURES_ARE_IMPORTANT,
      BALLON_ON_THE_LOAN,
      FLEXIBILITY_TO_MAKE_ADDITIONAL_REPLAYMENT,
      NO_EARLY_EXIT_FEES,
      LOWEST_RATE_POSSIBLE,
      REQUESTED_LOAN_DETAILS,
      DOCUMENT_EDIT_LINK,
      LABEL_DOCUMENT_LOAN_TYPE,
      LABEL_DOCUMENT_CONSUMER_CAR_LOAN,
      LABEL_DOCUMENT_PURCHASE_PRICE,
      LABEL_DOCUMENT_LOAN_TERM,
      LABEL_DOCUMENT_DEPOSIT,
      LABEL_DOCUMENT_BALLOON,
      LABEL_DOCUMENT_BALLOON_ONLY,
      LABEL_DOCUMENT_TRADE_IN,
      LABEL_DOCUMENT_BUDGETED_MONTHLY_REPAYMENT,
      LABEL_DOCUMENT_LOAN_AMOUNT,
      LABEL_DOCUMENT_ASSET_SUMMARY,
      LABEL_DOCUMENT_VEHICLE,
      LABEL_DOCUMENT_MODEL_TYPE,
      LABEL_DOCUMENT_CONDITION,
      LABEL_DOCUMENT_SALE_TYPE,
      LABEL_REQUIREMENT_SAVE_BUTTON,
      LABEL_REQUIREMENT_SAVE_AND_NEXT_BUTTON,
      LOAN_FEATURE_OPTIONS,
      PURPOSE_OF_OBTAINING_OPTIONS,
      LABEL_ASSET_TYPE_OPTION_OTHER,
      LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN,
      LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY,
      LABEL_NOT_AVAILABLE,
      LABEL_DO_YOU_REQUIRE_CREDIT_TO_PAY_FOR_ADDITIONAL,
      LABEL_ARE_YOU_AWARE_OF_THE_COSTS,
      LABEL_YEARS,
      ERROR_MESSAGE_FOR_PURPOSE_OF_OBTAINING,
      ERROR_MESSAGE_FOR_BUDGETTED_MONTHLY_REPAYMENT,
      ERROR_MESSAGE_FOR_NUMBER_ONLY,
      ERROR_MESSAGE_FOR_LOAN_FEATURES,
      ERROR_MESSAGE_PLEASE_SELECT_FIELD,
    }
  },
  methods: {
    ...{ toCurrency },
    async submitForm(event) {
      const { gotoNext } = event ?? {}
      this.saveBtnLoading = !gotoNext
      this.saveAndNextBtnLoading = !!gotoNext
      let isValid = false
      this.$refs.requirementsForm.validate((result) => {
        isValid = result
      })
      if (!isValid) {
        return
      }
      const { id: vehicleRequestGroupId, applicationId } = this.$route.params
      await this.$store.dispatch(
        'finance/applications/application/updateApplication',
        {
          application: { ...this.requirementsForm },
          vehicleRequestGroupId,
          applicationId,
        }
      )
      this.saveBtnLoading = false
      this.saveAndNextBtnLoading = false
      if (gotoNext) {
        this.$nuxt.$emit('changeTab', { tab: 'applicant' })
      }
    },
    openRequestLoanDetail() {
      this.$nuxt.$emit('requestLoanDetailsDialogForm-dialog-toggle', {
        open: true,
      })
    },
    openRequestAssetDetail() {
      this.$nuxt.$emit('assetDetailsSummaryDialog-dialog-toggle', {
        open: true,
      })
    },
  },
}
