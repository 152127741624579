//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { format } from 'date-fns'
import {
  LABEL_FOR_YEARS,
  LABEL_FOR_YEAR,
  LABEL_FOR_MONTHS,
  LABEL_FOR_MONTH,
  TITLE_FOR_ASSET_DETAILS,
} from '~/constants'
import {
  LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY,
  LABEL_ASSET_TYPE_OPTION_OTHER,
  LABEL_ASSET_TYPE_OPTION_VEHICLE,
} from '~/constants/applications'

export default {
  props: {
    dialogData: {
      type: Object,
      default: () => ({}),
    },
    visibleSync: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      format,
      TITLE_FOR_ASSET_DETAILS,
      // dialogData: this.dialogData,
      LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY,
      LABEL_ASSET_TYPE_OPTION_VEHICLE,
      LABEL_ASSET_TYPE_OPTION_OTHER,
    }
  },
  computed: {
    /* eslint-disable */
    isYearOrYears: function () {
      if (!this.dialogData.atYears) {
        return ''
      }
      return this.dialogData.atYears > 1 ? LABEL_FOR_YEARS : LABEL_FOR_YEAR
    },
    isMonthOrMonths: function () {
      if (!this.dialogData.atAdditionalMonths) {
        return ''
      }
      return this.dialogData.atAdditionalMonths > 1
        ? LABEL_FOR_MONTHS
        : LABEL_FOR_MONTH
    },
    /* eslint-enable */
  },
  methods: {
    closeDialog() {
      this.$nuxt.$emit('closeAssetDialog')
    },
  },
}
