//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Navbar from '@/components/Navbar.vue'
import { USER_SESSION_DATA_KEY } from '@/constants'
import { URLS_FOR_HIDE_NAVBAR_DROPDOWN_AND_SIDEBAR } from '@/constants/navbar'
import ReferralSidebar from '~/components/Referrals/ReferralSidebar.vue'
import { COMMON_LOGIN_ROUTE } from '~/helpers/portal'
import { changeRoute } from '~/helpers/changeRoute'
import isDesktop from '../mixins/device-mixin'
import { datadogRum } from '@datadog/browser-rum'

export default {
  components: { Navbar, ReferralSidebar },
  data() {
    return {
      URLName: '',
      isCollapse: isDesktop ? false : true,
    }
  },
  mixins: [isDesktop],
  watch: {
    isDesktop() {
      if (!this.isDesktop && !this.isCollapse) {
        this.isCollapse = true
      } else {
        this.isCollapse = false
      }
    },
  },
  computed: {
    shouldRenderSidebar() {
      return (
        this.$auth.loggedIn &&
        !URLS_FOR_HIDE_NAVBAR_DROPDOWN_AND_SIDEBAR.includes(this.URLName)
      )
    },
  },
  created() {
    if (!this.$auth.loggedIn) {
      changeRoute({
        route: this.$route,
        router: this.$router,
        path: COMMON_LOGIN_ROUTE,
      })
      return redirect(COMMON_LOGIN_ROUTE)
    }
  },
  mounted() {
    this.URLName = this.$route.name
    if (this.$auth.loggedIn) {
      const { user } = this.$auth
      if (user && user._id) {
        datadogRum.setUser({
          id: user._id,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          type: user.type,
          mobile: user.mobile,
          role: user.type,
        })
      }
    }
    this.$nuxt.$on('set-collapse', () => {
      this.isCollapse = !this.isCollapse
    })
    this.isCollapse = this.isDesktop ? false : true
  },
  methods: {
    async onLogout() {
      this.$auth.logout()
      this.$auth.$storage.removeUniversal(USER_SESSION_DATA_KEY)
      await this.$auth.logout()
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: COMMON_LOGIN_ROUTE,
      })
    },
  },
}
