//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { format, differenceInYears } from 'date-fns'
import {
  ERROR_MESSAGE_FOR_NUMBER_ONLY,
  ERROR_MESSAGE_FOR_NUMBER_LENGTH,
  ERROR_MESSAGE_FOR_URGENCY,
  ERROR_MESSAGE_FOR_VEHICLE_DETAILS,
  ERROR_MESSAGE_FOR_CAR_SOURCING,
  ERROR_MESSAGE_FOR_3_NUMBER_LENGTH,
  ERROR_MESSAGE_FOR_BUSINESS_NAME,
  ERROR_MESSAGE_FOR_ABN_LENGTH,
  ERROR_MESSAGE_FOR_ABN,
  LABEL_CONSUMER,
  LABEL_COMMERCIAL,
  ERROR_MESSAGE_FOR_BUSINESS_STRUCTURE,
  ERROR_MESSAGE_FOR_ABN_ACTIVE_FROM_DATE,
  ERROR_MESSAGE_NO_OF_DIRECTORS,
  ERROR_MESSAGE_NO_OF_PARTNERS,
  ERROR_MESSAGE_FOR_NO_OF_TRUSTEE_DIRECTORS,
  ERROR_MESSAGE_FOR_TRUSTEE_ACN,
  ERROR_MESSAGE_FOR_TRUSTEE_BUSINESS_NAME,
  ERROR_MESSAGE_FOR_TRUSTEE_TYPE,
  ERROR_MESSAGE_TRUSTEE_FIRST_NAME,
  ERROR_MESSAGE_TRUSTEE_LAST_NAME,
  ERROR_MESSAGE_FOR_TRUSTEE_ACN_MAX_LENGTH,
  ERROR_MESSAGE_FOR_DIRECTORS_DELETION,
  ERROR_MESSAGE_FOR_VALID_TRUSTEE_ACN,
  ERROR_MESSAGE_FOR_VALID_ABN,
} from '@/constants'

import {
  BUSINESS_STRUCTURE_OPTIONS,
  LABEL_BUSINESS_DETAILS,
  LABEL_ENTER_BUSINESS_DETAILS_MANUALLY,
  LABEL_BUSINESS_NAME,
  LABEL_PLACE_HOLDER_ABN,
  LABEL_BUSINESS_STRUCTURE,
  LABEL_SEARCH_BY_BUSINESS_NAME_OR_ABN,
  LABEL_BUSINESS_ABN,
  LABEL_FOR_ANNUAL_TURNOVER,
  LABEL_COMMERCIAL_CAR_LOAN,
  LABEL_CONSUMER_CAR_LOAN,
  DEFAULT_VEHICLE_GROUP_OBJECT,
  LABEL_CAN_NOT_FIND_THE_BUSINESS,
  NUMBER_OF_DIRECTORES_OPTIONS,
  NUMBER_OF_PARTNERS_OPTIONS,
  LABEL_BUSINESS_STRUCTURE_SOLE_TRADER,
  LABEL_EXPENSES_SAVE_BUTTON,
  LABEL_EXPENSES_SAVE_AND_NEXT_BUTTON,
  LABEL_FOR_NUMBER_OF_DIRECTORS,
  LABEL_FOR_OCCUPATION,
  LABEL_FOR_INDUSTRY,
  LABEL_FOR_REGISTERED_FOR_GST_FORM,
  LABEL_FOR_REGISTERED_GST,
  LABEL_FOR_ABN_ACTIVE_FORM,
  BUSINESS_DETAILS_TAB_KEY,
  SUBTAB_PRIMARY_ADDRESS_KEY,
  LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
  LABEL_BUSINESS_STRUCTURE_COMPANY,
  LABEL_FOR_NUMBER_OF_PARTNERS,
  LABEL_TRUSTEE_DETAILS,
  BUSINESS_TRUSTEE_TYPE_OPTIONS,
  LABEL_TRUSTEE_TYPE,
  LABEL_NO_OF_TRUSTEE_DIRECTORS,
  LABEL_TRUSTEE_ABN,
  LABEL_TRUSTEE_NAME,
  LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY,
  LABEL_BUSINESS_TRUSTEE_LASTNAME,
  LABEL_BUSINESS_TRUSTEE_FIRSTNAME,
  LABEL_BUSINESS_TRUSTEE_TYPE_INDIVIDUAL,
  LABEL_BUSINESS_STRUCTURE_TRUST,
  LABEL_TRUSTEE_ACN,
  ALL_INDUSTRY_OPTIONS,
  ERROR_MESSAGE_FOR_REGISTERED_FOR_GST_FROM,
  LABEL_FOR_PARTNERS,
  LABEL_TRUSTEE_DIRECTORS,
  LABEL_FOR_DIRECTORS,
  LABEL_BUSINESS_ABN_LESS_THAN_3_YEAR,
} from '~/constants/applications'

import { validateABN, validatePositiveNumber } from '../helpers/validators'
import { changeRoute } from '~/helpers/changeRoute'
import BusinessStructureValidationDialog from './UtilComponents/BusinessStructureValidationDialog.vue'
import NotificationBar from '~/components/UtilComponents/NotificationBar.vue'
export default {
  name: 'BusinessDetails',
  props: {
    sharedData: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    BusinessStructureValidationDialog,
    NotificationBar,
  },
  data() {
    return {
      format,
      applicationData: { ...this.sharedData },
      ERROR_MESSAGE_FOR_VALID_TRUSTEE_ACN,
      ERROR_MESSAGE_FOR_TRUSTEE_ACN_MAX_LENGTH,
      ERROR_MESSAGE_FOR_VALID_ABN,
      ERROR_MESSAGE_FOR_NUMBER_ONLY,
      ERROR_MESSAGE_FOR_NUMBER_LENGTH,
      ERROR_MESSAGE_FOR_3_NUMBER_LENGTH,
      ERROR_MESSAGE_NO_OF_DIRECTORS,
      ERROR_MESSAGE_NO_OF_PARTNERS,
      ERROR_MESSAGE_FOR_URGENCY,
      NUMBER_OF_DIRECTORES_OPTIONS,
      NUMBER_OF_PARTNERS_OPTIONS,
      LABEL_FOR_ANNUAL_TURNOVER,
      ERROR_MESSAGE_FOR_VEHICLE_DETAILS,
      ERROR_MESSAGE_FOR_CAR_SOURCING,
      ERROR_MESSAGE_FOR_BUSINESS_NAME,
      ERROR_MESSAGE_FOR_ABN_LENGTH,
      ERROR_MESSAGE_FOR_ABN,
      ERROR_MESSAGE_FOR_BUSINESS_STRUCTURE,
      ERROR_MESSAGE_FOR_ABN_ACTIVE_FROM_DATE,
      LABEL_BUSINESS_STRUCTURE_SOLE_TRADER,
      BUSINESS_STRUCTURE_OPTIONS,
      LABEL_CONSUMER,
      LABEL_COMMERCIAL,
      LABEL_SEARCH_BY_BUSINESS_NAME_OR_ABN,
      LABEL_BUSINESS_DETAILS,
      LABEL_ENTER_BUSINESS_DETAILS_MANUALLY,
      LABEL_CAN_NOT_FIND_THE_BUSINESS,
      LABEL_EXPENSES_SAVE_BUTTON,
      LABEL_EXPENSES_SAVE_AND_NEXT_BUTTON,
      LABEL_FOR_NUMBER_OF_DIRECTORS,
      LABEL_FOR_OCCUPATION,
      LABEL_FOR_INDUSTRY,
      LABEL_FOR_REGISTERED_FOR_GST_FORM,
      LABEL_FOR_REGISTERED_GST,
      LABEL_FOR_ABN_ACTIVE_FORM,
      LABEL_PLACE_HOLDER_ABN,
      LABEL_BUSINESS_STRUCTURE,
      LABEL_BUSINESS_NAME,
      LABEL_BUSINESS_ABN,
      LABEL_COMMERCIAL_CAR_LOAN,
      LABEL_CONSUMER_CAR_LOAN,
      DEFAULT_VEHICLE_GROUP_OBJECT,
      BUSINESS_DETAILS_TAB_KEY,
      SUBTAB_PRIMARY_ADDRESS_KEY,
      LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
      LABEL_BUSINESS_STRUCTURE_COMPANY,
      LABEL_FOR_NUMBER_OF_PARTNERS,
      LABEL_TRUSTEE_DETAILS,
      BUSINESS_TRUSTEE_TYPE_OPTIONS,
      LABEL_TRUSTEE_TYPE,
      ERROR_MESSAGE_FOR_NO_OF_TRUSTEE_DIRECTORS,
      ERROR_MESSAGE_FOR_TRUSTEE_ACN,
      ERROR_MESSAGE_FOR_TRUSTEE_BUSINESS_NAME,
      ERROR_MESSAGE_FOR_TRUSTEE_TYPE,
      LABEL_NO_OF_TRUSTEE_DIRECTORS,
      LABEL_TRUSTEE_ABN,
      LABEL_TRUSTEE_NAME,
      LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY,
      LABEL_BUSINESS_TRUSTEE_LASTNAME,
      LABEL_BUSINESS_TRUSTEE_FIRSTNAME,
      LABEL_BUSINESS_TRUSTEE_TYPE_INDIVIDUAL,
      LABEL_BUSINESS_STRUCTURE_TRUST,
      ERROR_MESSAGE_TRUSTEE_FIRST_NAME,
      ERROR_MESSAGE_TRUSTEE_LAST_NAME,
      LABEL_TRUSTEE_ACN,
      ALL_INDUSTRY_OPTIONS,
      LABEL_BUSINESS_ABN_LESS_THAN_3_YEAR,
      rules: {
        urgency: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_URGENCY,
            trigger: ['blur', 'change'],
          },
        ],
        vehicleDetails: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_VEHICLE_DETAILS,
            trigger: ['blur', 'change'],
          },
        ],
        carSourcing: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_CAR_SOURCING,
            trigger: ['blur', 'change'],
          },
        ],
        'businessData.occupation': [
          {
            validator: (_rule, value, callback) => {
              validatePositiveNumber(value, callback)
            },
            trigger: ['blur', 'change'],
          },
        ],
        'businessData.noOfDirectors': [
          {
            required: true,
            trigger: ['blur', 'change'],
          },
        ],
        'businessData.noOfPartners': [
          {
            required: true,
            trigger: ['blur', 'change'],
          },
        ],
      },
      tempList: [],
      links: [],
      state: '',
      searchByABN: '',
      isCommercial: false,
      enterTheDetailsManually: true,
      employment: '',
      creditHistory: '',
      vehicleDetails: '',
      carSourcing: '',
      background: '',
      directoresData: [],
      saveBtnLoading: false,
      saveAndNextBtnLoading: false,
      getIsDialogLoading: false,
      gSTEffectiveFromValidation: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      aBNEffectiveFromValidation: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      validateABN,
      businessStructureValidationDialog: false,
      oldBusinessStructure: null,
      newBusinessStructure: null,
      resetBusinessStructure: false,
    }
  },
  computed: {
    /* eslint-disable */
    businessForm() {
      this.applicationData = JSON.parse(JSON.stringify(this.sharedData))
      return { ...this.applicationData }
    },
    isSoleTrader() {
      return (
        this.applicationData?.businessData?.businessStructure ===
        LABEL_BUSINESS_STRUCTURE_SOLE_TRADER
      )
    },
    isPartnership() {
      return (
        this.applicationData?.businessData?.businessStructure ===
        LABEL_BUSINESS_STRUCTURE_PARTNERSHIP
      )
    },
    isCompany() {
      return (
        this.applicationData?.businessData?.businessStructure ===
        LABEL_BUSINESS_STRUCTURE_COMPANY
      )
    },
    isTrust() {
      return (
        this.applicationData?.businessData?.businessStructure ===
        LABEL_BUSINESS_STRUCTURE_TRUST
      )
    },
    GstValidation() {
      if (this.applicationData.businessData.isRegisteredForGST) {
        return [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_REGISTERED_FOR_GST_FROM,
            trigger: ['blur', 'change'],
          },
        ]
      } else {
        return []
      }
    },
    isTrustAndTrusteeTypeCompany() {
      return (
        this.applicationData?.businessData?.businessStructure ===
          LABEL_BUSINESS_STRUCTURE_TRUST &&
        this.applicationData?.businessData?.trusteeType ===
          LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY
      )
    },
    getDirectorsErrorMessageLabel() {
      if (this.isPartnership) {
        return LABEL_FOR_PARTNERS
      } else if (this.isTrustAndTrusteeTypeCompany) {
        return LABEL_TRUSTEE_DIRECTORS
      }
      return LABEL_FOR_DIRECTORS
    },
    hasABNlessThanThreeYears() {
      const year = differenceInYears(
        new Date(),
        new Date(this.applicationData.businessData.aBNEffectiveFrom)
      )
      return year < 3
    },
    /* eslint-enable */
  },
  watch: {
    'applicationData.businessData.businessStructure'(value) {
      if (value !== LABEL_BUSINESS_STRUCTURE_TRUST) {
        this.checkContacts(value)
      }
    },
  },
  mounted() {
    this.ALL_INDUSTRY_OPTIONS = ALL_INDUSTRY_OPTIONS.sort((a, b) => {
      return a.value < b.value && -1
      return a.value > b.value && 1
      return 0
    })
    this.$nuxt.$on('close-alert-dialog', () => {
      this.businessStructureValidationDialog = false
      this.applicationData.businessData.businessStructure =
        this.sharedData.businessData.businessStructure
      this.applicationData.businessData.trusteeType =
        this.sharedData.businessData.trusteeType
    })
    this.$nuxt.$on('reset-businessStructure', () => {
      this.resetBusinessStructureData()
    })
    this.$nuxt.$on('update-business-structure', () => {
      if (this.isCompany || this.isTrustAndTrusteeTypeCompany) {
        this.handleChange(8, false)
      } else if (this.isPartnership) {
        this.handleChange(2, false)
      } else if (this.isSoleTrader || !this.isTrustAndTrusteeTypeCompany) {
        this.handleChange(1, false)
      }
      this.businessStructureValidationDialog = false
    })
    this.applicationData?.businessData?.hasABN
      ? (this.enterTheDetailsManually = false)
      : (this.enterTheDetailsManually = true)

    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },
  methods: {
    resetBusinessStructureData() {
      const totalApplicantsCount = this.sharedData?.otherApplicants?.length + 1
      if (this.isCompany || this.isTrustAndTrusteeTypeCompany) {
        if (parseInt(8) < totalApplicantsCount) {
          this.resetBusinessStructure = true
        }
      } else if (this.isPartnership) {
        if (parseInt(2) < totalApplicantsCount) {
          this.resetBusinessStructure = true
        }
      } else if (this.isSoleTrader || !this.isTrustAndTrusteeTypeCompany) {
        if (parseInt(1) < totalApplicantsCount) {
          this.resetBusinessStructure = true
        }
      }
      if (this.resetBusinessStructure) {
        this.applicationData.businessData.businessStructure =
          this.sharedData.businessData.businessStructure
        this.applicationData.businessData.trusteeType =
          this.sharedData.businessData.trusteeType
        this.resetBusinessStructure = false
      }
    },
    resetFormfield() {
      this.$refs.businessForm.clearValidate(`businessData.gSTEffectiveFrom`)
    },

    handleChange(event, value = true) {
      const totalApplicants = this.sharedData.otherApplicants.length + 1
      if (parseInt(event) < totalApplicants) {
        if (value) {
          this.$nuxt.$emit(
            'show-commercial-users-dialog',
            ERROR_MESSAGE_FOR_DIRECTORS_DELETION(
              this.getDirectorsErrorMessageLabel
            )
          )
        } else {
          this.$nuxt.$emit('show-commercial-users-dialog')
        }
        this.applicationData.businessData.noOfDirectors =
          this.sharedData.businessData.noOfDirectors
        this.applicationData.businessData.noOfPartners =
          this.sharedData.businessData.noOfPartners
        this.applicationData.businessData.noOfTrusteeDirectors =
          this.sharedData.businessData.noOfTrusteeDirectors
        return
      }
      if (totalApplicants < this.otherApplicants)
        this.$nuxt.$emit(
          'numberOfDirectoresVal',
          this.applicationData.businessData.noOfDirectors
        )
      this.$nuxt.$emit(
        'numberOfPartnersVal',
        this.applicationData.businessData.noOfPartners
      )
    },
    async querySearch(queryString, cb) {
      if (queryString !== '' && queryString !== undefined) {
        const res = await this.$store.dispatch(
          `applications/getBusinessSearchABN`,
          {
            business: encodeURIComponent(queryString),
          }
        )
        if (res && res.data) {
          return cb(res.data)
        }
      }
    },
    createFilter(queryString) {
      return (link) => {
        return (
          link.name.toLowerCase().includes(queryString.toLowerCase()) ||
          link.abn.toLowerCase().includes(queryString.toLowerCase())
        )
      }
    },

    checkTrustee(businessStructure, trusteeType) {
      if (businessStructure === LABEL_BUSINESS_STRUCTURE_TRUST) {
        if (trusteeType === LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY) {
          return (
            LABEL_BUSINESS_STRUCTURE_TRUST +
            '-' +
            LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY
          )
        } else {
          return (
            LABEL_BUSINESS_STRUCTURE_TRUST +
            '-' +
            LABEL_BUSINESS_TRUSTEE_TYPE_INDIVIDUAL
          )
        }
      } else {
        return businessStructure
      }
    },
    checkContacts(type) {
      const totalApplicants = this.sharedData.otherApplicants.length + 1

      if (this.isPartnership) {
        if (totalApplicants > 2) {
          this.oldBusinessStructure = this.checkTrustee(
            this.sharedData.businessData.businessStructure,
            this.sharedData.businessData.trusteeType
          )
          this.newBusinessStructure = this.checkTrustee(
            type,
            this.isTrustAndTrusteeTypeCompany
          )
          this.businessStructureValidationDialog = true
        }
      } else if (this.isSoleTrader || this.isTrust) {
        if (totalApplicants > 1 && !this.isTrustAndTrusteeTypeCompany) {
          this.oldBusinessStructure = this.checkTrustee(
            this.sharedData.businessData.businessStructure,
            this.sharedData.businessData.trusteeType
          )
          this.newBusinessStructure = this.checkTrustee(
            type,
            this.isTrustAndTrusteeTypeCompany
          )
          this.businessStructureValidationDialog = true
        }
      }
    },
    async handleSelect(item) {
      this.applicationData.businessData.businessName = item.name
      this.applicationData.businessData.aBN = item.abn

      const res = await this.$store.dispatch(
        `applications/getBusinessStructure`,
        {
          abn: item.abn,
        }
      )
      if (res?.data[0]?.businessStructure) {
        this.applicationData.businessData.businessStructure =
          res.data[0].businessStructure
      }
      if (res?.data[0]?.legalName) {
        this.applicationData.businessData.businessName = res.data[0].legalName
      }
      if (res?.data[0]?.entityStatus?.effectiveFrom) {
        this.applicationData.businessData.aBNEffectiveFrom =
          res.data[0].entityStatus.effectiveFrom
      }

      if (res?.data[0]?.goodsAndServicesTax) {
        this.applicationData.businessData.gSTEffectiveFrom =
          res.data[0].goodsAndServicesTax.effectiveFrom
        this.applicationData.businessData.isRegisteredForGST = true
      } else {
        this.applicationData.businessData.gSTEffectiveFrom = ''
        this.applicationData.businessData.isRegisteredForGST = false
      }
    },
    async submit(redirect) {
      this.saveBtnLoading = !redirect
      this.saveAndNextBtnLoading = !!redirect
      const { id, applicationId } = this.$route.params
      let isValid = false
      this.$refs.businessForm.validate((result) => {
        return (isValid = result)
      })

      if (!isValid) {
        this.saveBtnLoading = false
        this.saveAndNextBtnLoading = false
        return
      }

      //  Check no. of directors
      if (
        this.isCompany ||
        this.isPartnership ||
        this.isTrustAndTrusteeTypeCompany
      ) {
        const totalApplicants = this.sharedData.otherApplicants.length + 1
        if (this.applicationData.noOfDirectors < totalApplicants) {
          this.$nuxt.$emit('show-commercial-users-dialog')
          return
        }
      }

      this.getIsDialogLoading = true
      await this.$store.dispatch('applications/updateBusinessDetails', {
        applicationData: this.applicationData,
        id,
        applicationId,
      })
      this.$nuxt.$emit('updateTrusteeName', this.applicationData.businessData)
      this.getIsDialogLoading = false

      this.saveBtnLoading = false
      this.saveAndNextBtnLoading = false
      if (redirect) {
        this.$nuxt.$emit(
          'updateBusinessDetailsTabComponent',
          `${BUSINESS_DETAILS_TAB_KEY}|${SUBTAB_PRIMARY_ADDRESS_KEY}`
        )
        await changeRoute({
          route: this.$route,
          router: this.$router,
          path: `/finance/applications/${id}/${applicationId}`,
          query: {
            maintab: BUSINESS_DETAILS_TAB_KEY,
            subtab: SUBTAB_PRIMARY_ADDRESS_KEY,
          },
        })
      }
    },
  },
}
