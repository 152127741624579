var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }]
  }, [_c('el-card', {
    staticClass: "application-form address-tab-form"
  }, [_vm.addressForm ? _c('el-form', {
    ref: "addressForm",
    attrs: {
      "model": _vm.addressesData
    }
  }, [_c('div', [_c('el-row', {
    staticClass: "mt-2 mb-4",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    staticClass: "flex items-center",
    attrs: {
      "sm": 24,
      "lg": 16
    }
  }, [_c('span', {
    staticClass: "text-sm"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("*")]), _vm._v(_vm._s(_vm.LABEL_IS_THE_BUSINESS_ADDRESS_SAME_AS_RESIDENCIAL))]), _vm._v(" "), _c('el-form-item', {
    staticClass: "ml-5 inline-block mb-0",
    attrs: {
      "prop": "isBusinessAddressSameAsResidence",
      "rules": _vm.rules.isBusinessAddressSameAsResidence,
      "data-selector": "same-address-input-container"
    }
  }, [_c('el-radio-group', {
    attrs: {
      "disabled": _vm.secondaryData.length > 0
    },
    model: {
      value: _vm.addressesData.isBusinessAddressSameAsResidence,
      callback: function ($$v) {
        _vm.$set(_vm.addressesData, "isBusinessAddressSameAsResidence", $$v);
      },
      expression: "addressesData.isBusinessAddressSameAsResidence"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": true,
      "data-selector": "same-address-yes"
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c('el-radio-button', {
    attrs: {
      "label": false,
      "data-selector": "same-address-no"
    }
  }, [_vm._v("No")])], 1)], 1)], 1)], 1), _vm._v(" "), !_vm.addressesData.isBusinessAddressSameAsResidence ? _c('span', {
    attrs: {
      "data-selector": "bd-address-form-section"
    }
  }, [_c('div', {
    staticClass: "pt-0 el-card__header"
  }, [_c('div', {
    staticClass: "clearfix items-center flex justify-between",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "bd-current-address-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CURRENT_ADDRESS))])])]), _vm._v(" "), !_vm.addressesData.isBusinessAddressSameAsResidence ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24
    }
  }, [_c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Enter full address"
    }
  }, [_c('div', {
    staticClass: "el-input el-input--suffix"
  }, [_vm.isDefaultAddress ? _c('VueGoogleAutocomplete', {
    staticClass: "form-control el-input el-input__inner",
    attrs: {
      "id": 'googleSearch_' + _vm.addressesData.address,
      "placeholder": _vm.EMPLOYER_FULL_ADDRESS_PLACE_HOLDER,
      "country": ['au'],
      "name": "googleAddress",
      "value": _vm.doesAddressHaveCompleteMeta(_vm.addressesData.billingAddress) ? _vm.addressesData.billingAddress.address : '',
      "preloaded-data": _vm.doesAddressHaveCompleteMeta(_vm.addressesData.billingAddress) ? _vm.addressesData.address : '',
      "data-selector": "bd-full-address-input"
    },
    on: {
      "placechanged": function ($event) {
        return _vm.getAddressData($event);
      }
    }
  }) : _vm._e(), _vm._v(" "), _c('span', {
    staticClass: "el-input__suffix"
  }, [_c('i', {
    staticClass: "el-input__icon el-icon-search font-extrabold text-black"
  })])], 1)])], 1)], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Unit / Street",
      "prop": "billingAddress.addressLine1",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_UNIT_STREET,
        trigger: ['blur', 'change']
      }, {
        min: 3,
        max: 60,
        message: _vm.ERROR_MESSAGE_FOR_UNIT_STREET_LENGTH,
        trigger: ['blur', 'change']
      }],
      "data-selector": "bd-unit-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "data-selector": "bd-unit-input"
    },
    model: {
      value: _vm.addressesData.billingAddress.addressLine1,
      callback: function ($$v) {
        _vm.$set(_vm.addressesData.billingAddress, "addressLine1", $$v);
      },
      expression: "addressesData.billingAddress.addressLine1"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Suburb",
      "prop": "billingAddress.city",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_CITY,
        trigger: ['blur', 'change']
      }, {
        min: 3,
        max: 30,
        message: _vm.ERROR_MESSAGE_FOR_CITY_LENGTH,
        trigger: ['blur', 'change']
      }],
      "data-selector": "bd-suburb-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "data-selector": "bd-suburb-input"
    },
    model: {
      value: _vm.addressesData.billingAddress.city,
      callback: function ($$v) {
        _vm.$set(_vm.addressesData.billingAddress, "city", $$v);
      },
      expression: "addressesData.billingAddress.city"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    staticClass: "mb-0",
    attrs: {
      "label": "State",
      "prop": "billingAddress.state",
      "rules": _vm.stateValidation(_vm.addressesData.billingAddress.country),
      "data-selector": "bd-state-input-container"
    }
  }, [_vm.addressesData.billingAddress.country === _vm.LABEL_AUSTRALIA ? _c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.addressesData.billingAddress.state,
      callback: function ($$v) {
        _vm.$set(_vm.addressesData.billingAddress, "state", $$v);
      },
      expression: "addressesData.billingAddress.state"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "bd-state-options-container"
    }
  }, _vm._l(_vm.APPLICANT_STATE_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)]) : _c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "select",
      "disabled": "true"
    }
  }, _vm._l(_vm.APPLICANT_STATE_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Postcode",
      "prop": "billingAddress.postcode",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_POSTCODE,
        trigger: ['blur', 'change']
      }],
      "data-selector": "bd-postcode-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "data-selector": "bd-postcode-input"
    },
    model: {
      value: _vm.addressesData.billingAddress.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.addressesData.billingAddress, "postcode", $$v);
      },
      expression: "addressesData.billingAddress.postcode"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Country",
      "prop": "billingAddress.country",
      "data-selector": "bd-country-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "select country"
    },
    on: {
      "input": function ($event) {
        return _vm.setCountry(_vm.addressesData.billingAddress);
      }
    },
    model: {
      value: _vm.addressesData.billingAddress.country,
      callback: function ($$v) {
        _vm.$set(_vm.addressesData.billingAddress, "country", $$v);
      },
      expression: "addressesData.billingAddress.country"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "bd-country-options-container"
    }
  }, _vm._l(_vm.COUNTRIES, function (data) {
    return _c('el-option', {
      key: data.code,
      attrs: {
        "label": data.value,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c('el-row', [_c('el-col', {
    staticClass: "pt-5 border-t border-b border-light-grey",
    attrs: {
      "sm": 24
    }
  }, [_c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "md": 12,
      "lg": 6
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Time at address (Years)",
      "prop": "billingAddress.atYears",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_TIME_AT_YEARS,
        trigger: ['blur', 'change']
      }],
      "data-selector": "bd-years-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.addressesData.billingAddress.atYears,
      callback: function ($$v) {
        _vm.$set(_vm.addressesData.billingAddress, "atYears", $$v);
      },
      expression: "addressesData.billingAddress.atYears"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "bd-years-options-container"
    }
  }, _vm._l(_vm.dateRange.YEAR_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.value,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "md": 12,
      "lg": 6
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Time at address (Months)",
      "prop": "billingAddress.atAdditionalMonths",
      "rules": {
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_TIME_AT_MONTHS,
        trigger: ['blur', 'change']
      },
      "data-selector": "bd-months-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.addressesData.billingAddress.atAdditionalMonths,
      callback: function ($$v) {
        _vm.$set(_vm.addressesData.billingAddress, "atAdditionalMonths", $$v);
      },
      expression: "\n                        addressesData.billingAddress.atAdditionalMonths\n                      "
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "bd-months-options-container"
    }
  }, _vm._l(_vm.dateRange.MONTH_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.value,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Situation",
      "prop": "billingAddress.livingSituation",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_LIVING_SITUATION,
        trigger: ['blur', 'change']
      }],
      "data-selector": "bd-situation-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.addressesData.billingAddress.livingSituation,
      callback: function ($$v) {
        _vm.$set(_vm.addressesData.billingAddress, "livingSituation", $$v);
      },
      expression: "addressesData.billingAddress.livingSituation"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "bd-situation-options-container"
    }
  }, _vm._l(_vm.LIVING_SITUATIONS_MAY_PAY_RENT_OR_BOARD, function (data) {
    return _c('el-option', {
      key: data.value,
      attrs: {
        "label": data.value,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1), _vm._v(" "), _vm.checklivingSituation(_vm.addressesData.billingAddress.livingSituation) ? _c('el-col', {
    staticClass: "mb-6 flex items-center",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('span', {
    staticClass: "text-sm"
  }, [_vm._v(_vm._s(_vm.LABEL_RENTING_THROUGH_AGENT))]), _vm._v(" "), _c('el-form-item', {
    staticClass: "ml-auto inline-block mb-0",
    attrs: {
      "prop": "areYouRentingThroughAgent",
      "data-selector": "bd-rta-input-container"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.addressesData.billingAddress.areYouRentingThroughAgent,
      callback: function ($$v) {
        _vm.$set(_vm.addressesData.billingAddress, "areYouRentingThroughAgent", $$v);
      },
      expression: "\n                        addressesData.billingAddress.areYouRentingThroughAgent\n                      "
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": true,
      "data-selector": "bd-rta-yes"
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c('el-radio-button', {
    attrs: {
      "label": false,
      "data-selector": "bd-rta-no"
    }
  }, [_vm._v("No")])], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.checklivingSituation(_vm.addressesData.billingAddress.livingSituation) && _vm.addressesData.billingAddress.areYouRentingThroughAgent === true ? _c('div', {
    staticClass: "full-width flex"
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('text-input', {
    attrs: {
      "value": _vm.addressesData.billingAddress.realEstateAgentName,
      "form-prop": 'billingAddress.realEstateAgentName',
      "form-rule": [{
        max: 30,
        message: _vm.ERROR_MESSAGE_FOR_MAX_LENGTH_OCCUPATION,
        trigger: ['blur', 'change']
      }],
      "label": "Real estate agent name",
      "data-selector": "bd-agent-name-input"
    },
    model: {
      value: _vm.addressesData.billingAddress.realEstateAgentName,
      callback: function ($$v) {
        _vm.$set(_vm.addressesData.billingAddress, "realEstateAgentName", $$v);
      },
      expression: "\n                        addressesData.billingAddress.realEstateAgentName\n                      "
    }
  })], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('text-input', {
    attrs: {
      "value": _vm.addressesData.billingAddress.realEstateAgentPhoneNumber,
      "form-prop": 'billingAddress.realEstateAgentPhoneNumber',
      "accept-only-numbers": true,
      "form-rule": [{
        pattern: /^[0-9]/,
        trigger: 'blur',
        message: _vm.ERROR_MESSAGE_FOR_NUMBER_ONLY
      }, {
        max: 10,
        message: _vm.ERROR_MESSAGE_FOR_NUMBER_LENGTH,
        trigger: ['blur', 'change']
      }],
      "label": "Real estate agent number",
      "data-selector": "bd-agent-number-input"
    },
    model: {
      value: _vm.addressesData.billingAddress.realEstateAgentPhoneNumber,
      callback: function ($$v) {
        _vm.$set(_vm.addressesData.billingAddress, "realEstateAgentPhoneNumber", $$v);
      },
      expression: "\n                        addressesData.billingAddress\n                          .realEstateAgentPhoneNumber\n                      "
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.checklivingSituation(_vm.addressesData.billingAddress.livingSituation) && _vm.addressesData.billingAddress.areYouRentingThroughAgent === false ? _c('el-col', {
    staticClass: "flex items-center",
    attrs: {
      "sm": 48,
      "lg": 42
    }
  }, [_c('el-form-item', {
    staticClass: "gentNotes",
    attrs: {
      "label": _vm.LABEL_FOR_NOTES,
      "prop": "notesAboutTenant"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "autosize": {
        minRows: 5,
        maxRows: 5
      },
      "cols": 51,
      "maxlength": "200",
      "placeholder": _vm.PLACEHOLDER_WRITE_ANY_NOTES,
      "data-selector": "bd-notes-textarea"
    },
    model: {
      value: _vm.addressesData.billingAddress.notesAboutTenant,
      callback: function ($$v) {
        _vm.$set(_vm.addressesData.billingAddress, "notesAboutTenant", $$v);
      },
      expression: "addressesData.billingAddress.notesAboutTenant"
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e()], 1)]) : _vm._e()], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 mb-8 justify-end flex"
  }, [_c('div', {
    staticClass: "border-r border-light-grey mr-6 pr-6"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "data-selector": "bd-add-back-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.back();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_BACK_BUTTON))])], 1), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "data-selector": "bd-add-save-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(false);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_SAVE_BUTTON))]), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary",
      "data-selector": "bd-add-save-next-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(true);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_SAVE_AND_NEXT))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }