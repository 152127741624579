//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  REGEX_FOR_AUS_MOBILES,
  ERROR_MESSAGE_FOR_MOBILE,
  ERROR_MESSAGE_FOR_INVALID_MOBILE,
} from '@/constants/index'
import {
  LABEL_CANCEL,
  LABEL_SWITCH_TO_THAT_USER,
  LABEL_TITLE_IMPERSONATE_ACCELERATE,
} from '~/constants/applications'

export default {
  props: {
    shouldShowImpersonateDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      impersonateForm: {
        mobile: '',
      },
      isLoading: false,

      mobileNumberRules: () => [
        {
          required: true,
          message: ERROR_MESSAGE_FOR_MOBILE,
          trigger: 'change',
        },
        {
          pattern: REGEX_FOR_AUS_MOBILES,
          message: ERROR_MESSAGE_FOR_INVALID_MOBILE,
          trigger: 'change',
        },
      ],
      LABEL_CANCEL,
      LABEL_SWITCH_TO_THAT_USER,
      LABEL_TITLE_IMPERSONATE_ACCELERATE,
    }
  },
  computed: {},
  methods: {
    closeDialog() {
      this.$nuxt.$emit('closeImpersonateAccelerateDialog')
    },
    async impersonate() {
      try {
        let isValid = false
        this.$refs.impersonateForm.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          return
        }
        this.isLoading = true
        await this.$emit(
          'impersonateAccelerateUser',
          this.impersonateForm.mobile
        )
      } finally {
        this.$refs.impersonateForm.resetFields(`mobile`)
        this.isLoading = false
      }
    },
  },
}
