import _ from 'lodash'

export const axiosErrorMessageExtractor = (
  e,
  fallbackMsg = 'Your request could not be processed'
) => {
  try {
    if (e?.message === 'Network Error') {
      return 'New updates are getting deployed right now. Please try to login again few minutes'
    } else {
      return e?.response?.data?.error || fallbackMsg
    }
  } catch (err) {
    return fallbackMsg
  }
}

export const axiosStatusCode = (e) => _.get(e, 'response.status')
