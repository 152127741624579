var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isDialogLoading,
      expression: "isDialogLoading"
    }],
    attrs: {
      "visible": _vm.dialogOpened,
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "destroy-on-close": "",
      "show-close": false,
      "width": "460px",
      "center": "",
      "data-selector": "missing-document-modal"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogOpened = $event;
      }
    }
  }, [_c('div', {
    staticClass: "el-dialog__header custom-dialog-header"
  }, [_c('img', {
    attrs: {
      "src": require("assets/icons/warning-employement.svg"),
      "alt": "Warning"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "el-dialog__title mt-2 text-md font-extrabold",
    attrs: {
      "data-selector": "m-msd-title"
    }
  }, [_vm._v(_vm._s(((_vm.LABEL_MARK_APPLICATION_AS_SETTLED) + " | " + (_vm.LABEL_FOR_MISSING_DOCUMENTS))))])]), _vm._v(" "), _c('p', {
    staticClass: "text-sm mb-4 text-center",
    staticStyle: {
      "word-break": "break-word"
    },
    attrs: {
      "data-selector": "m-msd-desction"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.LABEL_FOR_MISSING_DOCUMENTS_DESCRIPTION)
    }
  }), _vm._v(" "), _c('ul', {
    staticClass: "mt-4 missingDocments",
    staticStyle: {
      "background": "rgba(19, 181, 232, 0.1)",
      "padding": "0.5rem",
      "width": "420px"
    },
    attrs: {
      "data-selector": "document-list-container"
    }
  }, _vm._l(_vm.missingDocuments, function (missingDocument) {
    return _c('li', {
      key: missingDocument,
      staticClass: "description text-sm mt-2 break-normal",
      staticStyle: {
        "text-align": "left"
      },
      attrs: {
        "data-selector": "each-document"
      }
    }, [_c('b', [_vm._v(" " + _vm._s(missingDocument))])]);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "dialog-footer w-full flex justify-between",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "text",
      "data-selector": "msd-cancel-btn"
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))]), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary",
      "data-selector": "msd-goto-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.closeMissingDocDialogAndRedictToDocumentTab();
      }
    }
  }, [_vm._v(_vm._s(_vm.shouldIncludeInDefaultDocument ? _vm.LABEL_GO_TO_DOCUMENTS : _vm.LABEL_GO_TO_SETTLEMENT_DOCUMENT))])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }