//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TextInput',
  props: {
    label: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    className: {
      type: String,
      default: '',
    },
    formClassName: {
      type: String,
      default: '',
    },
    formProp: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    prefixIconPath: {
      type: String,
      default: null,
    },
    rows: {
      type: Number,
      default: 1,
    },
    formRule: {
      type: Array,
      default: () => [],
    },
    acceptOnlyNumbers: {
      type: Boolean,
      default: false,
    },
    dataSelector: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
    }
  },
  watch: {
    value() {
      this.inputValue = this.value
    },
  },
  methods: {
    onChange(event) {
      this.$emit('onChange', event)
      this.$emit('change', event)
    },
    onBlur(event) {
      this.$emit('onBlur', event)
      this.$emit('blur', event)
    },
    onInput(event) {
      this.$emit('onInput', event)
      this.$emit('input', event)
    },
    onClear(event) {
      this.$emit('onClear', event)
      this.$emit('clear', event)
    },
    onKeyDown(event) {
      if (this.acceptOnlyNumbers) {
        const keyCode = event.keyCode ? event.keyCode : event.which
        // disables up and down arrow key to avoid converting big numbers to exponential
        if (keyCode === 38 || keyCode === 40) {
          event.preventDefault()
        }
      }
    },
    onKeyPress(event) {
      if (this.acceptOnlyNumbers) {
        const keyCode = event.keyCode ? event.keyCode : event.which
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
          event.preventDefault()
        }
      }
    },
  },
}
