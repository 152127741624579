//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  LABEL_CANCEL,
  LABEL_I_HAVE_REVIEWED_COMPLETE_CPD,
  LABEL_DESCRIPTION_FOR_CPD_VIEW_AND_SEND,
  LABEL_DESCRIPTION_FOR_CPD_VIEW_AND_SEND_SETTLEMENT,
  LABEL_TITLE_FOR_CPD_VIEW_AND_SEND_SETTLEMENT,
  LABEL_TITLE_FOR_CPD_VIEW_AND_SEND,
  LABLE_TITLE_FOR_CPD_SEND,
  REGEX_ERROR_MESSAGE_FOR_DOCUMENTS_ARE_MISSING,
} from '@/constants/applications'
import { axiosErrorMessageExtractor } from '~/utils/error'

export default {
  name: 'LoanDetails',
  props: {
    dialogOpened: {
      type: Boolean,
      default: () => false,
    },
    cpdUrl: {
      type: String,
      default: () => null,
    },
    isPreSettlement: {
      type: Boolean,
      default: () => false,
    },
    isSettlement: {
      type: Boolean,
      default: () => false,
    },
    submitButtonLabel: {
      type: String,
      default: () => null,
    },
    isPreSettlementUpdate: {
      type: Boolean,
      default: () => false,
    },
    isFromSettlement: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      LABEL_CANCEL,
      LABEL_I_HAVE_REVIEWED_COMPLETE_CPD,
      getIsDialogLoading: false,
      checked: false,
      LABEL_DESCRIPTION_FOR_CPD_VIEW_AND_SEND,
      LABEL_DESCRIPTION_FOR_CPD_VIEW_AND_SEND_SETTLEMENT,
      LABEL_TITLE_FOR_CPD_VIEW_AND_SEND_SETTLEMENT,
      LABEL_TITLE_FOR_CPD_VIEW_AND_SEND,
      LABLE_TITLE_FOR_CPD_SEND,
    }
  },
  computed: {
    getCpdUrl() {
      return `${this.cpdUrl}&g=${(Math.random() + 1).toString(36).substring(7)}`
    },
  },
  methods: {
    closeDialog(sendAPI = false) {
      this.getIsDialogLoading = false
      this.checked = false

      let eventName = this.isPreSettlement
        ? 'close-send-proposal-dialog-pre-settlement'
        : this.isSettlement
        ? 'close-send-proposal-dialog-settlement'
        : this.isPreSettlementUpdate
        ? 'close-send-proposal-dialog-pre-settlement-update'
        : 'close-send-proposal-dialog-submission'
      this.$nuxt.$emit(eventName, sendAPI)
    },
    async submitForm() {
      try {
        this.getIsDialogLoading = true
        if (this.isPreSettlementUpdate) {
          this.isDialogLoading = true
          const { id, applicationId } = this.$route.params
          await this.$store.dispatch('applications/validateDocuments', {
            id,
            applicationId,
          })
        }

        if (!this.isPreSettlement && !this.isSettlement) {
          const { id, applicationId } = this.$route.params
          this.$store
            .dispatch('applications/sendProposal', {
              id,
              applicationId,
            })
            .then(() => {
              this.closeDialog(false)
            })
        } else {
          this.closeDialog(true)
        }
      } catch (err) {
        if (
          axiosErrorMessageExtractor(err).match(
            REGEX_ERROR_MESSAGE_FOR_DOCUMENTS_ARE_MISSING
          )
        ) {
          this.closeDialog(false)
          if (this.isFromSettlement) {
            this.$nuxt.$emit('show-settlement-missing-document', err)
          } else {
            this.$nuxt.$emit('show-pre-settlement-missing-document', err)
          }
        } else {
          this.$notify({
            title: 'Error',
            message: axiosErrorMessageExtractor(err),
            type: 'error',
            duration: 5000,
          })
        }
      }
    },
  },
  beforeDestroy() {
    this.getIsDialogLoading = false
    this.checked = false
  },
}
