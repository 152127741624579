export default () => ({
  cloneApplication: null,
  application: {
    primaryData: {},
    secondaryData: [],
    applicantData: null,
    loanDetails: {
      lender: '',
      lenderEstablishmentFee: '',
      ppsrFee: '',
      brokerFee: '',
      partnerFinanceReferralFee: '',
      lendingCommision: '',
      doesApplicantAgreeWithProposedLoanDetails: false,
      doesSatisfyConsumersObjectivesAndRequirements: false,
      doesApplicantComplyWithFinancialObligations: false,
      doesFutureChangesImpactPropsedLoan: false,
      isNoKnownOrLikelyFutureChangeInCircumstancesAgreed: false,
      howDoesSelectedLenderMeetCustomersNeed: '',
      baseRate: '',
      customerRate: '',
      accountKeepingFee: '',
      naf: '',
      monthlyRepayment: '',
      status: '',
      submittedAt: '',
      submission: {
        purchasePrice: '',
        tradeInAmount: '',
        depositAmount: '',
        balloonPct: '',
        loanTermYears: 0,
        balloonAmount: '',
        monthlyRepayment: '',
        baseRate: '',
        customerRate: '',
        accountKeepingFee: '',
        lenderEstablishmentFee: '',
        partnerFinanceReferralFee: '',
        ppsrFee: '',
        brokerFee: '',
        lendingCommision: '',
        sendProposalAt: '',
        zeroValueReason: '',
        zeroValueReasonType: '',
      },
      approval: {
        outcomeOfAnApplication: '',
        purchasePrice: '',
        tradeInAmount: '',
        depositAmount: '',
        balloonPct: '',
        loanTermYears: '',
        balloonAmount: '',
        monthlyRepayment: '',
        baseRate: '',
        customerRate: '',
        accountKeepingFee: '',
        lenderEstablishmentFee: '',
        partnerFinanceReferralFee: '',
        ppsrFee: '',
        brokerFee: '',
        lendingCommision: '',
        approvalExpiresAt: '',
        settlementEta: '',
        approvalConditions: '',
        withdrawnReasonType: '',
        withdrawnReason: '',
        declinedReasonType: '',
        declinedReason: '',
        doesApprovedAsSubmitted: null,
      },
      preliminaryAssessmentAt: null,
      creditProposalDisclosureAt: null,
      creditProposalDisclosureSentAt: null,
    },
    leadSource: '',
    balloonPct: 0,
    customerReasonForBalloon: null,
    customerReasonForBalloonExplanation: null,
    purposeOfObtainingIncreasingOrRefinanceCredit: '',
    monthlyRepaymentBudget: null,
    customerExpectedLoanFeatures: [],
    businessData: {
      aBN: '',
      aBNRegisteredYearsRange: '',
      assets: [],
      businessName: '',
      businessStructure: '',
      completedBusinessInfoAt: null,
      createdAt: null,
      noOfDirectors: null,
      noOfPartners: null,
      occupation: null,
      annualTurnover: null,
      directors: [],
      gSTRegisteredYearsRange: '',
      hasABN: false,
      isRegisteredForGST: false,
      liabilities: [],
      tradingName: '',
      tradingYearsRange: '',
      updatedAt: null,
    },
    certaintyStage: '',
    completedLeadApplicationAt: null,
    completedTradeInAt: null,
    createdAt: null,
    isTradeInFinanced: false,
    loanTermYears: '',
    loanType: '',
    otherApplicants: [],
    primaryApplicantData: {
      addresses: [
        {
          address: '',
          addressLine1: '',
          addressLine2: '',
          atAdditionalMonths: null,
          atYears: null,
          city: '',
          country: '',
          isCurrent: true,
          postcode: '',
          state: '',
          livingSituation: '',
        },
      ],
      agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt:
        null,
      applicantType: 'Primary',
      assets: [
        {
          amount: 0,
          hasLiability: false,
          type: '',
          vehicle: '',
        },
      ],
      completedAssetsAt: null,
      completedEmploymentAndIncomeAt: null,
      completedExpensesAt: null,
      completedLiabilitiesAt: null,
      completedPersonalInfoAt: null,
      createdAt: null,
      currentWorkStatus: '',
      customerSuppliedCreditRatingDepiction: '',
      documents: [],
      employments: [
        {
          employerName: '',
          position: '',
          status: '',
          isCurrent: false,
          atYears: null,
          atAdditionalMonths: null,
          isAdditionalJob: false,
          netIncome: null,
          netIncomeFrequency: 'Monthly',
          hasExtraEarnings: null,
          commissionPretax: 0,
          commissionFrequency: '',
          bonusPretax: 0,
          bonusFrequency: '',
          overtimePretax: 0,
          overtimeFrequency: '',
          allowancesPretax: 0,
          allowancesFrequency: '',
        },
      ],
      expenses: [],
      hasAdditionalJobs: null,
      hasAdditionalLiabilities: null,
      hasAdditionalVehicles: null,
      hasCreditCardsOrStoreCards: null,
      hasNonEmploymentIncomes: null,
      hasPartnerIncome: null,
      hasPersonalLoans: null,
      liabilities: [],
      livingSituation: '',
      nonEmploymentIncomes: {},
      numberOfDependants: 0,
      personalNetMonthlyIncomeGuesstimate: 0,
      primaryApplicant: {
        createdAt: null,
        dateOfBirth: '',
        driverLicence: '',
        driverLicenceExpiry: null,
        driverLicenceState: '',
        driverLicenceType: '',
        driverLicenceCardNumber: '',
        email: '',
        firstName: '',
        isEmailVerified: false,
        isEnabled: true,
        lastName: '',
        leadSourceSystem: '',
        mailingAddress: {
          country: '',
          isCurrent: false,
          addressLine1: '',
          state: '',
        },
        mobile: '',
        salutation: '',
        timezone: '',
        type: '',
        updatedAt: null,
      },
      relationshipStatus: '',
      residencyStatus: '',
      updatedAt: null,
    },
    productType: '',
    purchasePrice: 0,
    saleType: '',
    shortId: '',
    type: '',
    updatedAt: null,
    vehicle: {
      type: '',
      assetType: '',
      dataProvider: '',
      account: '',
      financeDepositAmount: 0,
      condition: '',
      vIN: '',
      purchaseSource: '',
      year: '',
      code: '',
      familyCode: '',
      makeCode: '',
      makeDescription: '',
      familyDescription: '',
      codeDescription: '',
      use: '',
      user: '',
      images: [],
      colours: [],
      options: [],
      optionPacks: [],
      accessories: [],
      bodyStyle: '',
      valuations: [
        {
          source: '',
          code: '',
          avgKm: '',
          gdKm: '',
          goodRetail: '',
          avgRetail: '',
          retailPrice: '',
          newPrice: '',
          goodWhs: '',
          avgWhs: '',
          tradeInPrice: '',
          tradeInMin: '',
          tradeInMax: '',
          tradeInAvg: '',
          tradeInPriceCustomer: '',
          tradeInMinCustomer: '',
          tradeInMaxCustomer: '',
          tradeInAvgCustomer: '',
          privatePrice: '',
          privateMin: '',
          privateMax: '',
          privateAvg: '',
          createdAt: '',
        },
      ],
      createdAt: '',
      updatedAt: '',
      badge: '',
      badge2nd: '',
      bodyConfig: '',
      cylinders: '',
      description: '',
      doorNum: '',
      drive: '',
      engineDesc: '',
      engineSize: '',
      extraInfo: '',
      fuelType: '',
      gearLocation: '',
      gearNum: '',
      gearType: '',
      importFlag: '',
      induction: '',
      limitedEdition: '',
      month: '',
      newVehicleFlag: '',
      rbid: '',
      roofLine: '',
      sequencenNo: '',
      series: '',
      seriesModelYear: '',
      wheelBaseConfig: '',
      latestValuation: {
        source: '',
        _id: '',
        code: '',
        avgKm: '',
        gdKm: '',
        goodRetail: '',
        avgRetail: '',
        retailPrice: '',
        newPrice: '',
        goodWhs: '',
        avgWhs: '',
        tradeInPrice: '',
        tradeInMin: '',
        tradeInMax: '',
        tradeInAvg: '',
        tradeInPriceCustomer: '',
        tradeInMinCustomer: '',
        tradeInMaxCustomer: '',
        tradeInAvgCustomer: '',
        privatePrice: '',
        privateMin: '',
        privateMax: '',
        privateAvg: '',
        createdAt: '',
      },
      __v: '',
      relevantAuthorityPrice: '',
      registrationPlate: '',
      customerNote: '',
    },
  },
  isLoading: false,
  applicationStatus: '',
  leadSource: '',
  referralMode: '',
  referrerAccount: '',
  sFLead: {
    status: '',
  },
  owner: {},
  sFOpportunity: {},
  accountData: [],
  initialLeadFunnel: '',
  totalNetSurplus: null,
  matchesGenerated: false,
  generatedMatchesData: [],
  lenderMatchesSettings: {},
  lenderMatchesApplicationMatchesForm: {},
})
