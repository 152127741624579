var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    staticClass: "font-extrabold",
    attrs: {
      "visible": _vm.showDialog,
      "width": "30%",
      "center": "",
      "close-on-press-escape": false,
      "close-on-click-modal": false,
      "show-close": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.showDialog = $event;
      }
    }
  }, [!_vm.showError ? _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }]
  }, [_c('div', {
    staticClass: "el-dialog__header custom-dialog-header"
  }, [_c('img', {
    attrs: {
      "src": require("assets/icons/warning-employement.svg"),
      "alt": "Warning"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "el-dialog__title mt-2"
  }, [_vm._v(_vm._s(_vm.RUN_CREDIT_CHECK_TITLE))])]), _vm._v(" "), _c('p', {
    staticClass: "font-medium secondary-color text-center px-5 my-6",
    staticStyle: {
      "word-break": "break-word"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.CREDIT_RUN_REPORT_DESCRIPTION.replace(_vm.REGEX_FOR_APPLICATION_TYPE, _vm.dialogFor).replace(_vm.REGEX_FOR_APPLICATION_NAME, _vm.dialogData && _vm.dialogData.fullName))
    }
  }), _c('div', {
    staticClass: "mt-6 pt-5 flex justify-between border-t border-light-grey"
  }, [_c('el-button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": _vm.closeReportDialog
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))]), _vm._v(" "), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.runCreditReport
    }
  }, [_vm._v("Yes. Proceed")])], 1)]) : _c('div', [_c('div', {
    staticClass: "el-dialog__header custom-dialog-header"
  }, [_c('img', {
    attrs: {
      "src": require("assets/icons/warning-employement.svg"),
      "alt": "Warning"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "el-dialog__title mt-2"
  }, [_vm._v(_vm._s(_vm.headerOfError))])]), _vm._v(" "), _c('p', {
    staticClass: "font-medium secondary-color text-center px-5 my-6",
    staticStyle: {
      "word-break": "break-word"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.bodyOfError) + "\n    ")]), _vm._v(" "), _c('p', {
    staticClass: "font-medium secondary-color text-center px-5 my-6",
    staticStyle: {
      "word-break": "break-word"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.noOfAttempts)
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "mt-6 pt-5 border-t border-light-grey text-center"
  }, [_c('el-button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": _vm.closeReportDialog
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CLOSE))])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }