//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ERROR_MESSAGE_FOR_EMAIL,
  ERROR_MESSAGE_FOR_INVALID_EMAIL,
  ERROR_MESSAGE_FOR_OLD_PASSWORD,
  ERROR_MESSAGE_FOR_NEW_PASSWORD,
  ERROR_MESSAGE_FOR_PASSWORD_LENGTH,
  PASSWORD_CHANGE_SUCCESS,
  USER_SESSION_DATA_KEY,
  REGEX_FOR_PASSWORD,
} from '@/constants'
import { LABEL_CHANGE_PASSWORD, LABEL_CANCEL } from '@/constants/applications'
import { axiosErrorMessageExtractor } from '../utils/error'
import { changeRoute } from '~/helpers/changeRoute'

export default {
  middleware: 'auth',
  props: {
    shouldShowChangePasswordDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      LABEL_CHANGE_PASSWORD,
      LABEL_CANCEL,
      isRequestCodeLoading: false,
      isSubmitLoginLoading: false,
      email: '',
      oldPassword: '',
      newPassword: '',
      loginData: {
        email: '',
        oldPassword: '',
        newPassword: '',
      },
      passwordType: 'password',
      newPasswordType: 'password',
    }
  },
  computed: {
    rules() {
      return {
        email: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_EMAIL,
            trigger: ['blur', 'authenticationEmail', 'change'],
          },
          {
            type: 'email',
            message: ERROR_MESSAGE_FOR_INVALID_EMAIL,
            trigger: ['blur', 'change', 'authenticationEmail'],
          },
        ],
        newPassword: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_NEW_PASSWORD,
            trigger: ['blur', 'change'],
          },
          {
            pattern: REGEX_FOR_PASSWORD,
            message: ERROR_MESSAGE_FOR_PASSWORD_LENGTH,
            trigger: ['blur', 'change'],
          },
        ],
        oldPassword: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_OLD_PASSWORD,
            trigger: ['blur', 'change'],
          },
          {
            pattern: REGEX_FOR_PASSWORD,
            message: ERROR_MESSAGE_FOR_PASSWORD_LENGTH,
            trigger: ['blur', 'change'],
          },
        ],
      }
    },
  },
  methods: {
    closeDialog() {
      this.$refs.changePasswordForm.resetFields()
      this.$emit('openCloseChangePasswordDialog')
    },
    async authenticationChangePass() {
      let isValid = false
      this.$refs.changePasswordForm.validate((result) => (isValid = result))
      if (!isValid) {
        return
      }
      try {
        this.isRequestCodeLoading = true
        if (!this.loginData) {
          return
        }
        const { _id } = this.$auth.$storage.getUniversal(USER_SESSION_DATA_KEY)
        const params = {
          oldPassword: this.loginData.oldPassword,
          newPassword: this.loginData.newPassword,
        }
        const response = await this.$axios.patch(
          `/user/${_id}/update-email-password`,
          params
        )
        if (response.status === 204) {
          this.$notify({
            title: 'Success',
            message: PASSWORD_CHANGE_SUCCESS,
            type: 'success',
            duration: 5000,
          })
          this.$refs.changePasswordForm.resetFields()
          this._timeout = setTimeout(() => {
            changeRoute({
              route: this.$route,
              router: this.$router,
              path: '/',
            })
          }, 4000)
        }
      } catch (err) {
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.isRequestCodeLoading = false
        this.closeDialog()
      }
    },
  },
}
