import isEqual from 'lodash/isEqual'

export const changeRoute = async (props) => {
  const {
    route: $route,
    router: $router,
    path: redirectPath,
    query: redirectPathQueryParams,
  } = props
  const { path, query } = $route
  if (isEqual(path, redirectPath) && isEqual(query, redirectPathQueryParams)) {
    return
  }
  try {
    await $router.push({
      path: redirectPath ?? path,
      query: redirectPathQueryParams ?? query,
    })
  } catch (err) {
    console.error('err ', err)
  }
}
