//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  LABEL_FOR_NOTES,
  PLACEHOLDER_WRITE_ANY_NOTES,
  ERROR_MESSAGE_FOR_UNIT_STREET,
  ERROR_MESSAGE_FOR_SUBURB,
  ERROR_MESSAGE_FOR_POSTCODE,
  ERROR_MESSAGE_FOR_COUNTRY,
  ERROR_MESSAGE_FOR_TIME_AT_YEARS,
  ERROR_MESSAGE_FOR_TIME_AT_MONTHS,
  ERROR_MESSAGE_FOR_LIVING_SITUATION,
  ERROR_MESSAGE_FOR_UNIT_STREET_LENGTH,
  ERROR_MESSAGE_FOR_CITY,
  ERROR_MESSAGE_FOR_CITY_LENGTH,
  ERROR_MESSAGE_FOR_STATE,
  ERROR_MESSAGE_PLEASE_SELECT_FIELD,
  ERROR_MESSAGE_FOR_MAX_LENGTH_OCCUPATION,
  ERROR_MESSAGE_FOR_NUMBER_LENGTH,
  ERROR_MESSAGE_FOR_NUMBER_ONLY,
} from '@/constants'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import {
  APPLICANT_STATE_OPTIONS,
  LIVING_SITUATIONS_MAY_PAY_RENT_OR_BOARD,
  LIVING_SITUATION_TENANT_OWN_HOME_WITH_MORTGAGE,
  LIVING_SITUATION_TENANT_NO_MORTGAGE,
  YEAR_OPTIONS,
  EMPLOYER_FULL_ADDRESS_PLACE_HOLDER,
  MONTH_OPTIONS,
  LABEL_CURRENT_ADDRESS,
  LABEL_BACK_BUTTON,
  LABEL_SAVE_BUTTON,
  LABEL_SAVE_AND_NEXT,
  LABEL_IS_THE_BUSINESS_ADDRESS_SAME_AS_RESIDENCIAL,
  LABEL_RENTING_THROUGH_AGENT,
  BUSINESS_DETAILS_TAB_KEY,
  SUBTAB_BUSINESS_ACCOUNTANT_KEY,
  SUBTAB_BUSINESS_DETAILS_KEY,
  SUBTAB_BUSINESS_BENEFICIARIES_KEY,
  SUBTAB_BUSINESS_SHAREHOLDER_KEY,
  LABEL_BUSINESS_STRUCTURE_COMPANY,
  LABEL_BUSINESS_STRUCTURE_TRUST,
  LABEL_BUSINESS_TRUSTEE_TYPE_INDIVIDUAL,
  LABEL_BUSINESS_STRUCTURE_SOLE_TRADER,
  LABEL_AUSTRALIA,
} from '@/constants/applications'
import { COUNTRIES } from '@/constants/countries'
import { changeRoute } from '~/helpers/changeRoute'
import { stateValidation, setState } from '~/helpers/address'
import { doesAddressHaveCompleteMeta } from '~/helpers/application'
export default {
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    activeForm: {
      type: String,
      default: 'personal',
    },
    primaryData: {
      type: Object,
      default: () => ({}),
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      setState,
      stateValidation,
      APPLICANT_STATE_OPTIONS,
      PLACEHOLDER_WRITE_ANY_NOTES,
      doesAddressHaveCompleteMeta,
      LIVING_SITUATIONS_MAY_PAY_RENT_OR_BOARD,
      LABEL_CURRENT_ADDRESS,
      LABEL_BACK_BUTTON,
      LABEL_FOR_NOTES,
      LABEL_SAVE_BUTTON,
      LABEL_SAVE_AND_NEXT,
      LABEL_IS_THE_BUSINESS_ADDRESS_SAME_AS_RESIDENCIAL,
      LABEL_RENTING_THROUGH_AGENT,
      LIVING_SITUATION_TENANT_OWN_HOME_WITH_MORTGAGE,
      LIVING_SITUATION_TENANT_NO_MORTGAGE,
      ERROR_MESSAGE_FOR_STATE,
      ERROR_MESSAGE_FOR_UNIT_STREET,
      ERROR_MESSAGE_FOR_SUBURB,
      ERROR_MESSAGE_FOR_POSTCODE,
      ERROR_MESSAGE_FOR_NUMBER_ONLY,
      ERROR_MESSAGE_FOR_COUNTRY,
      ERROR_MESSAGE_FOR_TIME_AT_YEARS,
      ERROR_MESSAGE_FOR_TIME_AT_MONTHS,
      ERROR_MESSAGE_FOR_LIVING_SITUATION,
      ERROR_MESSAGE_FOR_UNIT_STREET_LENGTH,
      ERROR_MESSAGE_FOR_CITY,
      EMPLOYER_FULL_ADDRESS_PLACE_HOLDER,
      ERROR_MESSAGE_FOR_CITY_LENGTH,
      ERROR_MESSAGE_FOR_NUMBER_LENGTH,
      ERROR_MESSAGE_FOR_MAX_LENGTH_OCCUPATION,
      ERROR_MESSAGE_PLEASE_SELECT_FIELD,
      BUSINESS_DETAILS_TAB_KEY,
      SUBTAB_BUSINESS_ACCOUNTANT_KEY,
      SUBTAB_BUSINESS_DETAILS_KEY,
      SUBTAB_BUSINESS_SHAREHOLDER_KEY,
      LABEL_BUSINESS_STRUCTURE_TRUST,
      LABEL_BUSINESS_TRUSTEE_TYPE_INDIVIDUAL,
      LABEL_BUSINESS_STRUCTURE_SOLE_TRADER,
      LABEL_AUSTRALIA,
      YEAR_OPTIONS,
      addressOptions: {
        isBusinessAddressSameAsResidence: null,
        address: '',
        billingAddress: {
          postcode: '',
          state: '',
          city: '',
          country: '',
          unitNumber: '',
          streetNumber: '',
          streetName: '',
          addressLine1: '',
          areYouRentingThroughAgent: true,
          notesAboutTenant: '',
          realEstateAgentName: '',
          realEstateAgentPhoneNumber: '',
        },
      },
      addressesData: {
        ...this.addressOptions,
        ...this.sharedData.businessData,
      },
      googleSelected: false,
      COUNTRIES,
      addresses: [],
      SUBTAB_BUSINESS_BENEFICIARIES_KEY,
      LABEL_BUSINESS_STRUCTURE_COMPANY,
      getIsDialogLoading: false,
      isDefaultAddress: true,
      rules: {
        isBusinessAddressSameAsResidence: [
          {
            required: true,
            message: ERROR_MESSAGE_PLEASE_SELECT_FIELD,
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  mounted() {
    if (this.addressesData.isBusinessAddressSameAsResidence === null) {
      this.addressesData.billingAddress.postcode = ''
      this.addressesData.billingAddress.state = ''
      this.$nextTick(() => {
        this.$refs.addressForm.fields
          .find((field) => field.prop == 'billingAddress.postcode')
          .clearValidate()
        this.$refs.addressForm.fields
          .find((field) => field.prop == 'billingAddress.state')
          .clearValidate()
      })
    }
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },
  computed: {
    /* eslint-disable */
    addressForm() {
      const isBusinessAddressSameAsResidence =
        this.addressesData?.isBusinessAddressSameAsResidence
      this.addressesData = {
        ...this.addressOptions,
        ...JSON.parse(JSON.stringify(this.sharedData.businessData)),
      }
      this.addressesData.isBusinessAddressSameAsResidence =
        isBusinessAddressSameAsResidence
      return { ...this.sharedData }
    },

    /* eslint-enable */
    dateRange() {
      return { YEAR_OPTIONS, MONTH_OPTIONS }
    },
  },
  methods: {
    checklivingSituation(livingSituation) {
      return [
        LIVING_SITUATION_TENANT_OWN_HOME_WITH_MORTGAGE,
        LIVING_SITUATION_TENANT_NO_MORTGAGE,
      ].includes(livingSituation)
    },
    createFilter(queryString) {
      return (link) => {
        return (
          link.value.toLowerCase().includes(queryString.toLowerCase()) !== -1
        )
      }
    },
    /* eslint-disable */
    getAddressData: function (addressData) {
      if (addressData) {
        this.$set(
          this.addressesData.billingAddress,
          'address',
          `${addressData.route}, ${addressData.locality} ${addressData.administrative_area_level_1} ${addressData.postal_code}`
        )
        this.$set(
          this.addressesData.billingAddress,
          'addressLine1',
          `${addressData.street_number ? addressData.street_number : ''} ${
            addressData.route
          }`
        )
        this.$set(
          this.addressesData.billingAddress,
          'city',
          addressData.locality
        )
        this.$set(
          this.addressesData.billingAddress,
          'state',
          addressData.administrative_area_level_1
        )
        this.$set(
          this.addressesData.billingAddress,
          'postcode',
          addressData.postal_code
        )
        this.$set(
          this.addressesData.billingAddress,
          'country',
          addressData.country
        )
        this.$set(
          this.addressesData.billingAddress,
          'streetName',
          addressData.route
        )
        if (addressData?.street_number) {
          let data =
            addressData.street_number.includes('/') &&
            addressData.street_number.includes('-')
              ? addressData.street_number.replace(/[/]/g, ' ')
              : addressData.street_number.replace(/[-/]/g, ' ')
          data = data.split(/[\s]/).reverse()
          this.$set(
            this.addressesData.billingAddress,
            'streetNumber',
            data[0] || ''
          )
          this.$set(
            this.addressesData.billingAddress,
            'unitNumber',
            data[1] && /^\d+$/.test(data[1]) ? data[1] : ''
          )
        }
      }
    },
    async submit(isRedirect) {
      let isValid = false
      this.$refs.addressForm.validate((result) => {
        return (isValid = result)
      })
      if (!isValid) {
        return
      }
      this.getIsDialogLoading = true
      const { id, applicationId } = this.$route.params
      await this.$store.dispatch(`applications/updateBusinessAddress`, {
        businessAddress: this.addressesData,
        id,
        applicationId,
      })
      this.getIsDialogLoading = false

      if (isRedirect) {
        let redirectTab = SUBTAB_BUSINESS_ACCOUNTANT_KEY
        if (
          this.sharedData.businessData.businessStructure ===
          LABEL_BUSINESS_STRUCTURE_COMPANY
        ) {
          redirectTab = SUBTAB_BUSINESS_SHAREHOLDER_KEY
        } else if (
          this.sharedData.businessData.businessStructure ===
          LABEL_BUSINESS_STRUCTURE_TRUST
        ) {
          redirectTab = SUBTAB_BUSINESS_BENEFICIARIES_KEY
        }
        this.$nuxt.$emit(
          'updateBusinessDetailsTabComponent',
          `${BUSINESS_DETAILS_TAB_KEY}|${redirectTab}`
        )
        await changeRoute({
          route: this.$route,
          router: this.$router,
          path: `/finance/applications/${id}/${applicationId}`,
          query: {
            maintab: BUSINESS_DETAILS_TAB_KEY,
            subtab: redirectTab,
          },
        })
      }
    },
    async back() {
      const { id, applicationId } = this.$route.params
      this.$nuxt.$emit(
        'updateBusinessDetailsTabComponent',
        `${BUSINESS_DETAILS_TAB_KEY}|${SUBTAB_BUSINESS_DETAILS_KEY}`
      )
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: `/finance/applications/${id}/${applicationId}`,
        query: {
          maintab: BUSINESS_DETAILS_TAB_KEY,
          subtab: SUBTAB_BUSINESS_DETAILS_KEY,
        },
      })
    },
    async setCountry(addressData) {
      this.isDefaultAddress = false
      await setState(addressData)
      this.isDefaultAddress = true
    },
  },
}
