//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ERROR_MESSAGE_FOR_TYPE,
  ERROR_MESSAGE_FOR_MAKE,
  ERROR_MESSAGE_FOR_MODEL,
  ERROR_MESSAGE_FOR_MODEL_YEAR,
  ERROR_MESSAGE_FOR_MODEL_TYPE,
  ERROR_MESSAGE_FOR_MODEL_CONDITION,
  ERROR_MESSAGE_FOR_SALE_TYPE,
  ERROR_MESSAGE_FOR_DETAILS,
  ERROR_MESSAGE_FOR_MODEL_DESCRIPTION,
  ERROR_MESSAGE_FOR_MAX_20_CHARS,
  LABEL_FOR_VEHICLE_DESCRIPTION,
} from '@/constants'

import {
  LABEL_FOR_CANCEL_BUTTON,
  LABEL_DOCUMENT_ASSET_DETAILS,
  LABEL_DOCUMENT_TYPE,
  LABEL_DOCUMENT_MAKE,
  LABEL_DOCUMENT_YEAR,
  LABEL_DOCUMENT_MODEL_TYPE,
  LABEL_DOCUMENT_CONDITION,
  LABEL_DOCUMENT_SALE_TYPE,
  LABEL_DOCUMENT_MODEL,
  LABEL_DOCUMENT_DETAILS,
  LABEL_ASSET_TYPE_OPTION_VEHICLE,
  PLACEHOLDER_FOR_ASSET_DESCRIPTION,
  LABEL_ASSET_TYPE_OPTION_OTHER,
  LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY,
  LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN,
  ASSET_TYPE_OPTIONS,
  SALE_TYPE_OPTIONS,
  CONDITION_OPTIONS,
  LABEL_DOCUMENT_VIN,
  LABEL_DOCUMENT_REGISTRATION_PLATE,
} from '@/constants/applications'
import { handleCustomerNote } from '~/mixins/customernote.mixin'

export default {
  mixins: [handleCustomerNote],
  props: {
    vehicleDetails: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      LABEL_FOR_CANCEL_BUTTON,
      LABEL_DOCUMENT_ASSET_DETAILS,
      LABEL_DOCUMENT_TYPE,
      LABEL_DOCUMENT_MAKE,
      LABEL_DOCUMENT_YEAR,
      LABEL_DOCUMENT_MODEL_TYPE,
      LABEL_DOCUMENT_CONDITION,
      LABEL_DOCUMENT_SALE_TYPE,
      LABEL_DOCUMENT_MODEL,
      LABEL_DOCUMENT_DETAILS,
      LABEL_ASSET_TYPE_OPTION_VEHICLE,
      PLACEHOLDER_FOR_ASSET_DESCRIPTION,
      LABEL_ASSET_TYPE_OPTION_OTHER,
      LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY,
      LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN,
      ASSET_TYPE_OPTIONS,
      SALE_TYPE_OPTIONS,
      CONDITION_OPTIONS,
      LABEL_DOCUMENT_VIN,
      LABEL_DOCUMENT_REGISTRATION_PLATE,
      ERROR_MESSAGE_FOR_TYPE,
      ERROR_MESSAGE_FOR_MAKE,
      ERROR_MESSAGE_FOR_MODEL,
      ERROR_MESSAGE_FOR_MODEL_YEAR,
      ERROR_MESSAGE_FOR_MODEL_TYPE,
      ERROR_MESSAGE_FOR_MODEL_CONDITION,
      ERROR_MESSAGE_FOR_SALE_TYPE,
      ERROR_MESSAGE_FOR_DETAILS,
      ERROR_MESSAGE_FOR_MODEL_DESCRIPTION,
      ERROR_MESSAGE_FOR_MAX_20_CHARS,
      LABEL_FOR_VEHICLE_DESCRIPTION,
      loading: false,
      rules: {
        assetType: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
        makeCode: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MAKE,
            trigger: ['blur', 'change'],
          },
        ],
        familyCode: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL,
            trigger: ['blur', 'change'],
          },
        ],
        year: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_YEAR,
            trigger: ['blur', 'change'],
          },
        ],
        codeDescription: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
        condition: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_CONDITION,
            trigger: ['blur', 'change'],
          },
        ],
        purchaseSource: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_SALE_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
        'vehicle.assetDetail': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_DETAILS,
            trigger: ['blur', 'change'],
          },
        ],
        vIN: [
          {
            max: 20,
            message: ERROR_MESSAGE_FOR_MAX_20_CHARS,
            trigger: ['blur', 'change'],
          },
        ],
        registrationPlate: [
          {
            max: 20,
            message: ERROR_MESSAGE_FOR_MAX_20_CHARS,
            trigger: ['blur', 'change'],
          },
        ],
        customerNote: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_DESCRIPTION,
            trigger: ['blur', 'change'],
          },
        ],
      },
      assetDetailsSummaryForm: {
        vehicle: { ...this.vehicleDetails },
      },
      manuallySelected: false,
      modelDisabled: true,
      yearDisabled: true,
      modelTypeDisabled: true,
      MAKE: [],
      MODEL_OPTIONS: [],
      YEARS_OPTIONS: [],
      MODEL_TYPES_OPTIONS: [],
    }
  },
  watch: {
    manuallySelected(value) {
      if (value && this.assetDetailsSummaryForm.vehicle) {
        this.assetDetailsSummaryForm.vehicle.code = ''
        this.assetDetailsSummaryForm.vehicle.makeCode = ''
        this.assetDetailsSummaryForm.vehicle.makeDescription = ''
        this.assetDetailsSummaryForm.vehicle.familyCode = ''
        this.assetDetailsSummaryForm.vehicle.familyDescription = ''
        this.assetDetailsSummaryForm.vehicle.year = ''
        this.assetDetailsSummaryForm.vehicle.codeDescription = ''
      } else {
        this.assetDetailsSummaryForm.vehicle.customerNote = ''
      }
    },
  },
  created() {
    if (
      this.assetDetailsSummaryForm.vehicle &&
      this.assetDetailsSummaryForm.vehicle.assetType !==
        this.LABEL_ASSET_TYPE_OPTION_VEHICLE
    ) {
      if (
        this.assetDetailsSummaryForm.appData &&
        this.assetDetailsSummaryForm.appData.vehicle &&
        !this.assetDetailsSummaryForm.appData.vehicle.year &&
        !this.assetDetailsSummaryForm.appData.vehicle.makeDescription &&
        !this.assetDetailsSummaryForm.appData.vehicle.familyDescription &&
        !this.assetDetailsSummaryForm.appData.vehicle.codeDescription &&
        this.assetDetailsSummaryForm.appData.vehicle.customerNote
      ) {
        this.manuallySelected = false
      } else {
        this.manuallySelected = true
      }
    }
  },
  async mounted() {
    this.MAKE = await this.$store.dispatch(`vehicle/getAllMakes`)
  },
  methods: {
    closeDialog() {
      this.$nuxt.$emit('assetDetailsSummaryDialog-dialog-toggle', {
        open: false,
      })
    },
    async submitForm() {
      let isFormValid = false
      this.$refs.assetDetailsSummaryForm.validate((result) => {
        isFormValid = result
      })
      if (!isFormValid) {
        return
      }
      await this.$store.dispatch('vehicle/updateVehicleByID', {
        vehicleId: this.vehicleDetails?._id,
        vehicleData: this.assetDetailsSummaryForm.vehicle,
        showSuccess: true,
      })
      this.closeDialog()
    },
    async setModels() {
      this.modelDisabled = false
      this.yearDisabled = true
      this.modelTypeDisabled = true

      this.assetDetailsSummaryForm.vehicle.familyCode = ''
      this.assetDetailsSummaryForm.vehicle.year = ''
      this.assetDetailsSummaryForm.vehicle.codeDescription = ''
      this.MODEL_OPTIONS = await this.$store.dispatch(`vehicle/getAllModels`, {
        makeCode: this.assetDetailsSummaryForm.vehicle.makeCode,
      })
    },
    async setYears() {
      this.yearDisabled = false
      this.modelTypeDisabled = true
      this.assetDetailsSummaryForm.vehicle.year = ''
      this.assetDetailsSummaryForm.vehicle.codeDescription = ''

      this.YEARS_OPTIONS = await this.$store.dispatch(`vehicle/getAllYears`, {
        makeCode: this.assetDetailsSummaryForm.vehicle.makeCode,
        familyCode: this.assetDetailsSummaryForm.vehicle.familyCode,
      })
    },
    async setModelTypes() {
      this.modelTypeDisabled = false
      this.assetDetailsSummaryForm.vehicle.codeDescription = ''
      this.MODEL_TYPES_OPTIONS = await this.$store.dispatch(
        `vehicle/getAllModelTypes`,
        {
          makeCode: this.assetDetailsSummaryForm.vehicle.makeCode,
          familyCode: this.assetDetailsSummaryForm.vehicle.familyCode,
          year: this.assetDetailsSummaryForm.vehicle.year,
        }
      )
    },
  },
}
