var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "md": 6,
      "lg": 4
    }
  }, [_c('el-menu', {
    staticClass: "el-menu-vertical-demo",
    attrs: {
      "unique-opened": true,
      "default-active": _vm.activeMenuIndex
    }
  }, [_c('el-menu-item', {
    staticClass: "el-menu-vertical-demo",
    class: _vm.SETTLEMENT_MENU_DOCUMENT_KEY,
    attrs: {
      "index": "0",
      "data-selector": "st-documents-link"
    },
    on: {
      "click": function ($event) {
        return _vm.sectionRedirect(_vm.SETTLEMENT_MENU_DOCUMENT_KEY);
      }
    }
  }, [_c('span', {
    staticClass: "text-sm"
  }, [_vm._v(_vm._s(_vm.SETTLEMENT_MENU_DOCUMENT_LABEL))])]), _vm._v(" "), _vm.shouldDisplaySellerDetailsTab ? _c('el-menu-item', {
    class: _vm.SETTLEMENT_MENU_SELLER_DETAILS_KEY,
    attrs: {
      "index": "1",
      "disabled": _vm.ShouldDisabledSubTab,
      "data-selector": "st-seller-details-link"
    },
    on: {
      "click": function ($event) {
        return _vm.sectionRedirect(_vm.SETTLEMENT_MENU_SELLER_DETAILS_KEY);
      }
    }
  }, [_c('span', {
    staticClass: "text-sm"
  }, [_vm._v(_vm._s(_vm.SETTLEMENT_MENU_SELLER_DETAILS_LABEL))])]) : _vm._e(), _vm._v(" "), _c('el-menu-item', {
    class: _vm.SETTLEMENT_MENU_LOAN_DETAILS_KEY,
    attrs: {
      "index": "2",
      "disabled": _vm.ShouldDisabledSubTab,
      "data-selector": "st-loan-details-link"
    },
    on: {
      "click": function ($event) {
        return _vm.sectionRedirect(_vm.SETTLEMENT_MENU_LOAN_DETAILS_KEY);
      }
    }
  }, [_c('span', {
    staticClass: "text-sm"
  }, [_vm._v(_vm._s(_vm.SETTLEMENT_MENU_LOAN_DETAILS_LABEL))])]), _vm._v(" "), _c('el-menu-item', {
    class: _vm.SETTLEMENT_MENU_LOAN_WARRANTY_AND_INSURANCE_KEY,
    attrs: {
      "index": "3",
      "disabled": _vm.ShouldDisabledSubTab,
      "data-selector": "st-warranty-link"
    },
    on: {
      "click": function ($event) {
        return _vm.sectionRedirect(_vm.SETTLEMENT_MENU_LOAN_WARRANTY_AND_INSURANCE_KEY);
      }
    }
  }, [_c('span', {
    staticClass: "text-sm"
  }, [_vm._v(_vm._s(_vm.SETTLEMENT_MENU_LOAN_WARRANTY_AND_INSURANCE_LABEL))])])], 1)], 1), _vm._v(" "), _vm.activeSubTab === _vm.SETTLEMENT_MENU_DOCUMENT_KEY ? _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 18,
      "lg": 20
    }
  }, [_c('Documents', _vm._b({
    attrs: {
      "isTabDisabled": _vm.ShouldDisabledSubTab
    },
    on: {
      "sectionRedirect": _vm.sectionRedirect
    }
  }, 'Documents', Object.assign({}, _vm.$props), false))], 1) : _vm._e(), _vm._v(" "), _vm.activeSubTab === _vm.SETTLEMENT_MENU_LOAN_DETAILS_KEY ? _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 18,
      "lg": 20
    }
  }, [_c('LoanDetails', _vm._b({
    on: {
      "sectionRedirect": _vm.sectionRedirect
    }
  }, 'LoanDetails', Object.assign({}, _vm.$props), false))], 1) : _vm._e(), _vm._v(" "), _vm.activeSubTab === _vm.SETTLEMENT_MENU_SELLER_DETAILS_KEY ? _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 18,
      "lg": 20
    }
  }, [_c('SellerDetails', _vm._b({
    on: {
      "sectionRedirect": _vm.sectionRedirect
    }
  }, 'SellerDetails', Object.assign({}, _vm.$props), false))], 1) : _vm._e(), _vm._v(" "), _vm.activeSubTab === _vm.SETTLEMENT_MENU_LOAN_WARRANTY_AND_INSURANCE_KEY ? _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 18,
      "lg": 20
    }
  }, [_c('WarrentyInsurance', _vm._b({
    on: {
      "sectionRedirect": _vm.sectionRedirect
    }
  }, 'WarrentyInsurance', Object.assign({}, _vm.$props), false))], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }