//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  APPLICATION_STATUS_APPROVED,
  MAINTAB_LOANDETAILS_DECISION_KEY,
  MAINTAB_LOANDETAILS_DECISION_LABEL,
  MAINTAB_LOANDETAILS_SETTLEMENT_KEY,
  MAINTAB_LOANDETAILS_PRE_SETTLEMENT_LABEL,
  MAINTAB_LOANDETAILS_SUBMISSION_KEY,
  MAINTAB_LOANDETAILS_SUBMISSION_LABEL,
} from '~/constants/applications'
import { changeRoute } from '~/helpers/changeRoute'
export default {
  props: {
    primaryData: {
      type: Object,
      default: () => ({}),
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    accountData: {
      type: Array,
      default: () => [],
    },
    loanDetails: {
      type: Object,
      default: () => ({}),
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeSubTab: MAINTAB_LOANDETAILS_SUBMISSION_KEY,
      MAINTAB_LOANDETAILS_DECISION_LABEL,
      MAINTAB_LOANDETAILS_PRE_SETTLEMENT_LABEL,
      MAINTAB_LOANDETAILS_SUBMISSION_LABEL,
      MAINTAB_LOANDETAILS_DECISION_KEY,
      MAINTAB_LOANDETAILS_SETTLEMENT_KEY,
      APPLICATION_STATUS_APPROVED,
      MAINTAB_LOANDETAILS_SUBMISSION_KEY,
    }
  },

  methods: {
    getDefaltActive() {
      if (this.$route.query?.subtab) {
        this.activeSubTab = this.$route.query.subtab
      }
      return this.activeSubTab === MAINTAB_LOANDETAILS_SETTLEMENT_KEY
        ? '2'
        : this.activeSubTab === MAINTAB_LOANDETAILS_DECISION_KEY
        ? '1'
        : '0'
    },
    async sectionRedirect(subTabName) {
      this.activeSubTab = subTabName
      await changeRoute({
        route: this.$route,
        router: this.$router,
        query: { ...this.$route.query, subtab: subTabName },
      })
    },
  },
}
