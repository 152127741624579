var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-aside', {
    class: _vm.isCollapse ? ' ' : 'aside-wrapper',
    attrs: {
      "width": _vm.isCollapse ? '0px' : '220px'
    }
  }, [_c('el-menu', {
    staticClass: "sideBar",
    attrs: {
      "default-active": _vm.isActiveMenuIndex,
      "default-openeds": ['1', '2']
    }
  }, [_c('div', {
    staticStyle: {
      "padding": "12px 0px 6px 20px"
    },
    attrs: {
      "data-selector": "fuel-menu"
    },
    on: {
      "click": function ($event) {
        return _vm.$nuxt.$emit('set-menu-collapse', true);
      }
    }
  }, [_c('img', {
    staticClass: "cursor-pointer",
    attrs: {
      "src": require("@/assets/icons/menu.svg")
    }
  })]), _vm._v(" "), _c('el-menu-item-group', [_c('el-submenu', {
    attrs: {
      "index": "1"
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('span', [_vm._v(_vm._s(_vm.SIDEBAR_MENU_ITEM_FINANCE))])]), _vm._v(" "), _c('el-menu-item-group', [_c('el-menu-item', {
    on: {
      "click": function ($event) {
        return _vm.appNavigation();
      }
    }
  }, [_c('NuxtLink', {
    attrs: {
      "to": "/finance/applications",
      "data-selector": "applications-link"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.SIDEBAR_MENU_ITEM_APPLICATIONS) + "\n              ")])], 1)], 1)], 2), _vm._v(" "), _c('el-submenu', {
    attrs: {
      "index": "2"
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('span', [_vm._v(_vm._s(_vm.SIDEBAR_MENU_UTILITIES))])]), _vm._v(" "), _c('el-menu-item-group', [_c('el-menu-item', {
    attrs: {
      "index": "2-1"
    },
    on: {
      "click": function ($event) {
        return _vm.openCloseVehiclerrpDialog();
      }
    }
  }, [_c('a', {
    class: _vm.isShowVehiclerrpDialog ? 'nuxt-link-active' : '',
    attrs: {
      "data-selector": "rrp-link"
    }
  }, [_vm._v(_vm._s(_vm.SIDEBAR_MENU_ITEM_RRP) + "\n              ")])]), _vm._v(" "), _c('el-menu-item', {
    attrs: {
      "index": "2-2"
    }
  }, [_c('NuxtLink', {
    attrs: {
      "to": "/quick-quote",
      "data-selector": "quick-quote-link"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.SIDEBAR_MENU_QUICK_QUOTE) + "\n              ")])], 1)], 1)], 2), _vm._v(" "), _vm.checkTypeAdmin ? [_c('el-menu-item', {
    attrs: {
      "index": "1"
    }
  }, [_c('NuxtLink', {
    attrs: {
      "to": "/users",
      "data-selector": "users-link"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.SIDEBAR_MENU_USERS) + "\n            ")])], 1), _vm._v(" "), _c('el-menu-item', {
    attrs: {
      "index": "2"
    }
  }, [_c('NuxtLink', {
    attrs: {
      "to": "/notifications",
      "data-selector": "notifications-link"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.SIDEBAR_MENU_NOTIFICATIONS) + "\n            ")])], 1), _vm._v(" "), _c('el-submenu', {
    attrs: {
      "index": "3"
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('span', {
    attrs: {
      "data-selector": "settings-collapse"
    }
  }, [_vm._v(_vm._s(_vm.SIDEBAR_MENU_SETTINGS))])]), _vm._v(" "), _c('el-menu-item-group', [_c('el-menu-item', {
    attrs: {
      "index": "3-1"
    }
  }, [_c('NuxtLink', {
    attrs: {
      "to": "/settings/ip-addresses",
      "data-selector": "whitelisted-ip-link"
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.SIDEBAR_MENU_WHITELISTED_IP_ADDRESSES) + "\n                ")])], 1)], 1)], 2)] : _vm._e()], 2)], 1)], 1), _vm._v(" "), _c('Vehiclerrp', {
    attrs: {
      "should-show-vehiclerrp-dialog": _vm.isShowVehiclerrpDialog
    },
    on: {
      "openCloseVehiclerrpDialog": function ($event) {
        return _vm.openCloseVehiclerrpDialog();
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }