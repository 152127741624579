var render = function () {
  var _vm$requestLoanDetail;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-dialog', {
    attrs: {
      "name": "requestLoanDetailsDialogForm",
      "title": _vm.REQUESTED_LOAN_DETAILS,
      "loading": _vm.loading
    }
  }, [_c('el-form', {
    ref: "requestLoanDetailsDialogForm",
    staticClass: "demo-ruleForm mt-4 text-xs",
    attrs: {
      "model": _vm.requestLoanDetailsDialogForm,
      "rules": _vm.rules
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_LOAN_TYPE,
      "prop": "loanType"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "disabled": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.requestLoanDetailsDialogForm.loanType,
      callback: function ($$v) {
        _vm.$set(_vm.requestLoanDetailsDialogForm, "loanType", $$v);
      },
      expression: "requestLoanDetailsDialogForm.loanType"
    }
  })], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_PURCHASE_PRICE,
      "prop": "purchasePrice"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text",
      "autocomplete": "off"
    },
    model: {
      value: _vm.requestLoanDetailsDialogForm.purchasePrice,
      callback: function ($$v) {
        _vm.$set(_vm.requestLoanDetailsDialogForm, "purchasePrice", $$v);
      },
      expression: "requestLoanDetailsDialogForm.purchasePrice"
    }
  })], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_DEPOSIT,
      "prop": "vehicle.financeDepositAmount",
      "rules": _vm.rules.financeDepositAmount
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text",
      "autocomplete": "off"
    },
    model: {
      value: _vm.requestLoanDetailsDialogForm.vehicle.financeDepositAmount,
      callback: function ($$v) {
        _vm.$set(_vm.requestLoanDetailsDialogForm.vehicle, "financeDepositAmount", $$v);
      },
      expression: "requestLoanDetailsDialogForm.vehicle.financeDepositAmount"
    }
  })], 1), _vm._v(" "), ((_vm$requestLoanDetail = _vm.requestLoanDetailsDialogForm.vehicle) === null || _vm$requestLoanDetail === void 0 ? void 0 : _vm$requestLoanDetail.type) === _vm.APPLICATION_TRADE_IN_VEHICLE ? _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_TRADE_IN,
      "prop": "vehicle.tradeInAmount",
      "rules": _vm.rules.tradeInAmount
    }
  }, [_c('el-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "number",
      "autocomplete": "off"
    },
    model: {
      value: _vm.requestLoanDetailsDialogForm.vehicle.tradeInAmount,
      callback: function ($$v) {
        _vm.$set(_vm.requestLoanDetailsDialogForm.vehicle, "tradeInAmount", $$v);
      },
      expression: "requestLoanDetailsDialogForm.vehicle.tradeInAmount"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_LOAN_AMOUNT,
      "prop": "loanAmount"
    }
  }, [_c('el-input', {
    attrs: {
      "disabled": "",
      "type": "text",
      "autocomplete": "off"
    },
    model: {
      value: _vm.loanAmountTotal,
      callback: function ($$v) {
        _vm.loanAmountTotal = $$v;
      },
      expression: "loanAmountTotal"
    }
  })], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_BALLOON,
      "prop": "balloonPct"
    }
  }, [_c('el-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "text",
      "autocomplete": "off"
    },
    on: {
      "input": function ($event) {
        return _vm.calculateBalloonAmt();
      }
    },
    model: {
      value: _vm.requestLoanDetailsDialogForm.balloonPct,
      callback: function ($$v) {
        _vm.$set(_vm.requestLoanDetailsDialogForm, "balloonPct", $$v);
      },
      expression: "requestLoanDetailsDialogForm.balloonPct"
    }
  })], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_BALLOON_ONLY,
      "prop": "ballonAmount"
    }
  }, [_c('el-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "text",
      "autocomplete": "off"
    },
    on: {
      "input": function ($event) {
        return _vm.calculateBalloonPerc();
      }
    },
    model: {
      value: _vm.requestLoanDetailsDialogForm.balloonAmount,
      callback: function ($$v) {
        _vm.$set(_vm.requestLoanDetailsDialogForm, "balloonAmount", $$v);
      },
      expression: "requestLoanDetailsDialogForm.balloonAmount"
    }
  })], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_LOAN_TERM,
      "prop": "loanTermYears"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.requestLoanDetailsDialogForm.loanTermYears,
      callback: function ($$v) {
        _vm.$set(_vm.requestLoanDetailsDialogForm, "loanTermYears", $$v);
      },
      expression: "requestLoanDetailsDialogForm.loanTermYears"
    }
  }, _vm._l(7, function (data) {
    return _c('el-option', {
      key: data,
      attrs: {
        "label": data + ' years',
        "value": data
      }
    });
  }), 1)], 1), _vm._v(" "), _c('el-form-item', {
    staticClass: "mb-0 border-t border-light-grey pt-5 child-w-full"
  }, [_c('el-button', {
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": function ($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CANCEL_BUTTON))]), _vm._v(" "), _c('el-button', {
    staticClass: "float-right",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm();
      }
    }
  }, [_vm._v("Update\n      ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }