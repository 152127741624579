export const handleDatePickerDisable = {
  data() {
    return {
      pastPickerDateDisabled: {
        disabledDate: (date) => {
          const currentDate = new Date().getTime()
          const datePicker = date.getTime()
          return currentDate >= datePicker
        },
      },
    }
  },
}
