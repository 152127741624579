export default {
  getTableDataAPI({
    status,
    currentPage,
    search,
    sortOrder,
    type,
    isFinance,
    isCarBuy,
    isUser,
    isIpAddresses,
    sortKey,
    sortDirection,
    $axios,
  }) {
    let requestUrl = ''
    if (isCarBuy) {
      requestUrl = '/vehicle-request-groups/requests?'
    }
    if (isFinance) {
      requestUrl = '/vehicle-request-groups/applications?'
    }
    if (isUser) {
      requestUrl = '/user?'
    }
    if (isIpAddresses) {
      requestUrl = '/ip-addresses?'
    }
    if (status) {
      requestUrl += '&status=' + status
    }
    if (currentPage) {
      requestUrl += '&page=' + currentPage
    }
    if (search) {
      requestUrl += '&requestId=' + encodeURIComponent(search)
    }
    if (sortOrder) {
      requestUrl += '&sortOrder=' + sortOrder
    }
    if (type && type !== 'All') {
      requestUrl += '&type=' + type
    }
    if (sortKey && sortDirection) {
      const sortField = (sortDirection === 'desc' ? '-' : '') + sortKey
      requestUrl += '&sort=' + sortField
    }

    return $axios.get(requestUrl)
  },
}
