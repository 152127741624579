var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }],
    staticClass: "application-form"
  }, [_vm.hasABNlessThanThreeYears ? _c('NotificationBar', {
    staticClass: "el-alert--warning el-alert-message-warning font-extrabold",
    attrs: {
      "message-type": "warning",
      "description": _vm.LABEL_BUSINESS_ABN_LESS_THAN_3_YEAR,
      "has-button": false
    }
  }) : _vm._e(), _vm._v(" "), _c('el-form', {
    ref: "businessForm",
    attrs: {
      "model": _vm.applicationData
    }
  }, [_vm.businessForm ? [_c('el-card', {
    staticClass: "add-ref-step-parent-cls"
  }, [_c('div', {
    staticClass: "clearfix p-0",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "business-details-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_BUSINESS_DETAILS))])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_SEARCH_BY_BUSINESS_NAME_OR_ABN,
      "data-selector": "b-live-search-input-container"
    }
  }, [_c('el-autocomplete', {
    attrs: {
      "popper-class": "my-autocomplete",
      "fetch-suggestions": _vm.querySearch,
      "placeholder": _vm.LABEL_SEARCH_BY_BUSINESS_NAME_OR_ABN,
      "disabled": _vm.enterTheDetailsManually
    },
    on: {
      "select": _vm.handleSelect
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "value",
          attrs: {
            "data-selector": "dp-b-name"
          }
        }, [_c('b', [_vm._v(_vm._s(item.name))])]), _vm._v(" "), _c('span', {
          staticClass: "link",
          attrs: {
            "data-selector": "dp-b-abn"
          }
        }, [_vm._v(_vm._s(item.abn))]), _c('br'), _vm._v(" "), _c('span', {
          staticClass: "link",
          attrs: {
            "data-selector": "dp-b-post-state"
          }
        }, [_vm._v(_vm._s(item.location.postCode) + "\n                    " + _vm._s(item.location.state))])];
      }
    }], null, false, 629108218),
    model: {
      value: _vm.searchByABN,
      callback: function ($$v) {
        _vm.searchByABN = $$v;
      },
      expression: "searchByABN"
    }
  }, [_c('i', {
    staticClass: "el-icon-search el-input__icon",
    attrs: {
      "slot": "prefix"
    },
    slot: "prefix"
  })])], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    staticClass: "mt-6",
    attrs: {
      "data-selector": "b-cant-find-input-container"
    }
  }, [_c('el-checkbox', {
    attrs: {
      "data-selector": "b-cant-find-lbl"
    },
    on: {
      "change": _vm.resetFormfield
    },
    model: {
      value: _vm.enterTheDetailsManually,
      callback: function ($$v) {
        _vm.enterTheDetailsManually = $$v;
      },
      expression: "enterTheDetailsManually"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CAN_NOT_FIND_THE_BUSINESS))])], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_BUSINESS_NAME,
      "prop": "businessData.businessName",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_BUSINESS_NAME,
        trigger: ['blur', 'change']
      }],
      "data-selector": "business-name-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "disabled": !_vm.enterTheDetailsManually,
      "type": "text",
      "data-selector": "business-name-input"
    },
    model: {
      value: _vm.applicationData.businessData.businessName,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "businessName", $$v);
      },
      expression: "applicationData.businessData.businessName"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('text-input', {
    attrs: {
      "value": _vm.applicationData.businessData.aBN,
      "placeholder": _vm.LABEL_PLACE_HOLDER_ABN,
      "label": _vm.LABEL_BUSINESS_ABN,
      "accept-only-numbers": true,
      "form-rule": [{
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_ABN,
        trigger: ['blur', 'change']
      }, {
        required: true,
        pattern: /^[0-9]+$/,
        message: _vm.ERROR_MESSAGE_FOR_VALID_ABN,
        trigger: ['blur', 'change']
      }, {
        min: 11,
        max: 11,
        message: _vm.ERROR_MESSAGE_FOR_ABN_LENGTH,
        trigger: ['blur', 'change']
      }],
      "form-prop": "businessData.aBN",
      "data-selector": "business-abn-input"
    },
    model: {
      value: _vm.applicationData.businessData.aBN,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "aBN", $$v);
      },
      expression: "applicationData.businessData.aBN"
    }
  })], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "businessData.businessStructure",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_BUSINESS_STRUCTURE,
        trigger: ['blur', 'change']
      }],
      "label": _vm.LABEL_BUSINESS_STRUCTURE,
      "data-selector": "business-struct-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select",
      "disabled": !_vm.enterTheDetailsManually
    },
    model: {
      value: _vm.applicationData.businessData.businessStructure,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "businessStructure", $$v);
      },
      expression: "applicationData.businessData.businessStructure"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "business-struct-options-container"
    }
  }, _vm._l(_vm.BUSINESS_STRUCTURE_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "businessData.aBNEffectiveFrom",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_ABN_ACTIVE_FROM_DATE,
        trigger: ['blur', 'change']
      }],
      "label": _vm.LABEL_FOR_ABN_ACTIVE_FORM,
      "data-selector": "abn-active-from-input-container"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "disabled": !_vm.enterTheDetailsManually,
      "type": "date",
      "placeholder": "DD/MM/YYY",
      "format": "dd/MM/yyyy",
      "picker-options": _vm.aBNEffectiveFromValidation
    },
    model: {
      value: _vm.applicationData.businessData.aBNEffectiveFrom,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "aBNEffectiveFrom", $$v);
      },
      expression: "applicationData.businessData.aBNEffectiveFrom"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    staticClass: "radio-group-fifty",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_REGISTERED_GST,
      "data-selector": "b-gst-input-container"
    }
  }, [_c('div', {
    staticClass: "block"
  }, [_c('el-radio-group', {
    attrs: {
      "disabled": !_vm.enterTheDetailsManually
    },
    on: {
      "change": _vm.resetFormfield
    },
    model: {
      value: _vm.applicationData.businessData.isRegisteredForGST,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "isRegisteredForGST", $$v);
      },
      expression: "applicationData.businessData.isRegisteredForGST"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": true,
      "data-selector": "b-gst-yes"
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c('el-radio-button', {
    attrs: {
      "label": false,
      "data-selector": "b-gst-no"
    }
  }, [_vm._v("No")])], 1)], 1)])], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_REGISTERED_FOR_GST_FORM,
      "prop": "businessData.gSTEffectiveFrom",
      "rules": _vm.GstValidation,
      "data-selector": "gst-reg-from-input-container"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "disabled": !_vm.enterTheDetailsManually,
      "type": "date",
      "picker-options": _vm.gSTEffectiveFromValidation,
      "placeholder": "DD/MM/YYY",
      "format": "dd/MM/yyyy"
    },
    model: {
      value: _vm.applicationData.businessData.gSTEffectiveFrom,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "gSTEffectiveFrom", $$v);
      },
      expression: "applicationData.businessData.gSTEffectiveFrom"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "border-t border-light-grey mt-2 pt-6"
  }, [_c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_INDUSTRY,
      "data-selector": "b-industry-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.applicationData.businessData.industry,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "industry", $$v);
      },
      expression: "applicationData.businessData.industry"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "b-industry-options-container"
    }
  }, _vm._l(_vm.ALL_INDUSTRY_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1), _vm._v(" "), _vm.isCompany || _vm.isPartnership || _vm.isTrust ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_ANNUAL_TURNOVER,
      "prop": "businessData.annualTurnover",
      "data-selector": "b-annual-turnover-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "b-annual-turnover-input"
    },
    model: {
      value: _vm.applicationData.businessData.annualTurnover,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "annualTurnover", $$v);
      },
      expression: "applicationData.businessData.annualTurnover"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isSoleTrader ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_OCCUPATION,
      "data-selector": "b-occu-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "data-selector": "b-occu-input"
    },
    model: {
      value: _vm.applicationData.businessData.occupation,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "occupation", $$v);
      },
      expression: "applicationData.businessData.occupation"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isCompany ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_NUMBER_OF_DIRECTORS,
      "prop": "businessData.noOfDirectors",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_NO_OF_DIRECTORS,
        trigger: ['blur', 'change']
      }],
      "data-selector": "b-num-dirs-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    on: {
      "change": _vm.handleChange
    },
    model: {
      value: _vm.applicationData.businessData.noOfDirectors,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "noOfDirectors", $$v);
      },
      expression: "applicationData.businessData.noOfDirectors"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "b-num-dirs-options-container"
    }
  }, _vm._l(_vm.NUMBER_OF_DIRECTORES_OPTIONS, function (item) {
    return _c('el-option', {
      key: item.key,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isPartnership ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_NUMBER_OF_PARTNERS,
      "prop": "businessData.noOfPartners",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_NO_OF_PARTNERS,
        trigger: ['blur', 'change']
      }],
      "data-selector": "b-num-partners-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    on: {
      "change": _vm.handleChange
    },
    model: {
      value: _vm.applicationData.businessData.noOfPartners,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "noOfPartners", $$v);
      },
      expression: "applicationData.businessData.noOfPartners"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "b-num-partners-options-container"
    }
  }, _vm._l(_vm.NUMBER_OF_PARTNERS_OPTIONS, function (item) {
    return _c('el-option', {
      key: item.key,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)])], 1)], 1) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _vm.isTrust ? _c('el-card', {
    staticClass: "add-ref-step-parent-cls",
    attrs: {
      "data-selector": "trustee-section"
    }
  }, [_c('div', {
    staticClass: "clearfix p-0",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "trustee-details-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_TRUSTEE_DETAILS))])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "businessData.trusteeType",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_TRUSTEE_TYPE,
        trigger: ['blur', 'change']
      }],
      "label": _vm.LABEL_TRUSTEE_TYPE,
      "data-selector": "trustee-type-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    on: {
      "change": function ($event) {
        return _vm.checkContacts(_vm.LABEL_BUSINESS_STRUCTURE_TRUST);
      }
    },
    model: {
      value: _vm.applicationData.businessData.trusteeType,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "trusteeType", $$v);
      },
      expression: "applicationData.businessData.trusteeType"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "trustee-type-options-container"
    }
  }, _vm._l(_vm.BUSINESS_TRUSTEE_TYPE_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1)], 1), _vm._v(" "), _vm.applicationData.businessData.trusteeType === _vm.LABEL_BUSINESS_TRUSTEE_TYPE_INDIVIDUAL ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "businessData.trusteeFirstName",
      "label": _vm.LABEL_BUSINESS_TRUSTEE_FIRSTNAME,
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_TRUSTEE_FIRST_NAME,
        trigger: ['blur', 'change']
      }],
      "data-selector": "trustee-fname-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "data-selector": "trustee-fname-input"
    },
    model: {
      value: _vm.applicationData.businessData.trusteeFirstName,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "trusteeFirstName", $$v);
      },
      expression: "applicationData.businessData.trusteeFirstName"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "businessData.trusteeLastName",
      "label": _vm.LABEL_BUSINESS_TRUSTEE_LASTNAME,
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_TRUSTEE_LAST_NAME,
        trigger: ['blur', 'change']
      }],
      "data-selector": "trustee-lname-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "data-selector": "trustee-lname-input"
    },
    model: {
      value: _vm.applicationData.businessData.trusteeLastName,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "trusteeLastName", $$v);
      },
      expression: "applicationData.businessData.trusteeLastName"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.applicationData.businessData.trusteeType === _vm.LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "businessData.trusteeBusinessName",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_TRUSTEE_BUSINESS_NAME,
        trigger: ['blur', 'change']
      }],
      "label": _vm.LABEL_TRUSTEE_NAME,
      "data-selector": "trustee-name-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "data-selector": "trustee-name-input"
    },
    model: {
      value: _vm.applicationData.businessData.trusteeBusinessName,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "trusteeBusinessName", $$v);
      },
      expression: "applicationData.businessData.trusteeBusinessName"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('text-input', {
    attrs: {
      "value": _vm.applicationData.businessData.trusteeACN,
      "placeholder": "XXXXXXXX",
      "label": _vm.LABEL_TRUSTEE_ACN,
      "accept-only-numbers": true,
      "form-rule": [{
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_TRUSTEE_ACN,
        trigger: ['blur', 'change']
      }, {
        max: 9,
        message: _vm.ERROR_MESSAGE_FOR_TRUSTEE_ACN_MAX_LENGTH,
        trigger: ['blur', 'change']
      }, {
        required: true,
        pattern: /^[0-9]+$/,
        message: _vm.ERROR_MESSAGE_FOR_VALID_TRUSTEE_ACN,
        trigger: ['blur', 'change']
      }],
      "form-prop": "businessData.trusteeACN",
      "data-selector": "trustee-acn-input-container"
    },
    model: {
      value: _vm.applicationData.businessData.trusteeACN,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "trusteeACN", $$v);
      },
      expression: "applicationData.businessData.trusteeACN"
    }
  })], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "businessData.noOfTrusteeDirectors",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_NO_OF_TRUSTEE_DIRECTORS,
        trigger: ['blur', 'change']
      }],
      "label": _vm.LABEL_NO_OF_TRUSTEE_DIRECTORS,
      "data-selector": "trustee-dirs-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    on: {
      "change": _vm.handleChange
    },
    model: {
      value: _vm.applicationData.businessData.noOfTrusteeDirectors,
      callback: function ($$v) {
        _vm.$set(_vm.applicationData.businessData, "noOfTrusteeDirectors", $$v);
      },
      expression: "applicationData.businessData.noOfTrusteeDirectors"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "trustee-dirs-options-container"
    }
  }, _vm._l(_vm.NUMBER_OF_DIRECTORES_OPTIONS, function (item) {
    return _c('el-option', {
      key: item.key,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)])], 1)], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 mb-8 justify-end flex"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "loading": _vm.saveBtnLoading,
      "data-selector": "b-bd-save-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(false);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_EXPENSES_SAVE_BUTTON))]), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary",
      "plain": "",
      "loading": _vm.saveAndNextBtnLoading,
      "data-selector": "b-bd-save-next-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(true);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_EXPENSES_SAVE_AND_NEXT_BUTTON))])], 1)] : _vm._e()], 2), _vm._v(" "), _c('BusinessStructureValidationDialog', {
    attrs: {
      "dialog-opened": _vm.businessStructureValidationDialog,
      "old-business-structure": _vm.oldBusinessStructure,
      "new-business-structure": _vm.newBusinessStructure
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }