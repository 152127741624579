var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "is-referrals": _vm.shouldRenderSidebar
    },
    on: {
      "logout": _vm.onLogout
    }
  }), _vm._v(" "), _c('el-container', {
    staticClass: "mainScrollBar",
    staticStyle: {
      "padding-top": "56px"
    }
  }, [_vm.shouldRenderSidebar ? _c('ReferralSidebar', {
    attrs: {
      "is-collapse": _vm.isCollapse
    }
  }) : _vm._e(), _vm._v(" "), _c('el-container', {
    staticClass: "accelerate"
  }, [_c('NuxtChild', {
    attrs: {
      "is-collapse": _vm.isCollapse
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }