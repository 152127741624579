//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TabFooter from '~/components/FinanceApplication/Tabs/TabFooter'
import { toCurrency } from '@/helpers/currency'
import {
  validatePercentage,
  validatePositiveNumber,
  validateAssestLiabilityType,
} from '~/helpers/validators'
import { changeRoute } from '~/helpers/changeRoute'
import {
  TITLE_FOR_ASSETS,
  TITLE_FOR_LIABILITES,
  TITLE_OF_ASSETS_REMOVE,
  TITLE_OF_LABILITIES_REMOVE,
  TITLE_FOR_ADD_ASSETS,
  TITLE_FOR__ADD_LIABILITES,
  USER_SESSION_DATA_KEY,
  LABEL_FOR_LAST_TYPE,
  LABEL_FOR_OWNER,
  LABEL_FOR_OWNERSHIP,
  LABEL_FOR_OWNERSHIP_EQUITY,
  LABEL_FOR_VALUE,
  LABEL_FOR_OWNED_OUTRIGHT,
  LABEL_FOR_FINANCIER,
  LABEL_FOR_BALANCE,
  LABEL_FOR_PAYMENT,
  LABEL_FOR_FREQUENCY,
  LABEL_FOR_LIMIT,
  LABEL_FOR_PAYOUT,
  REGEX_FOR_LIABILITIES,
  REGEX_FOR_ASSET,
  DESCRIPTION_FOR_REMOVE_ASSETS,
  DESCRIPTION_FOR_REMOVE_LIABILITIES,
  DESCRIPTION_IF_NO_ASSETS_FIRST_PART,
  DESCRIPTION_IF_NO_ASSETS_SECOND_PART,
  DESCRIPTION_IF_NO_LIABILITIES_FIRST_PART,
  DESCRIPTION_IF_NO_LIABILITIES_SECOND_PART,
  ERROR_MESSAGE_FOR_OWNER,
  ERROR_MESSAGE_FOR_SELECT_TYPE_ASSETS,
  ERROR_MESSAGE_FOR_INVALID,
  ERROR_MESSAGE_FOR_MAX_TWENTY_CHARACTERS,
  TITLE_FOR_CREDIT_COMMITMENTS,
  CREDIT_COMMITMENT_UP_TO_DATE_LABEL,
  ERROR_MESSAGE_FOR_CREDIT_COMMITMENT,
  EXPENSES_AND_COMFORTABLE_CREDIT_TITLE,
  HOUSE_HOLD_EXPENSES_MONTHLY_LABEL,
  ERROR_MESSAGE_FOR_HOUSE_HOLD_EXPENSES,
  DOES_APPLICANT_HAVE_CONFIRMABLE_CREDIT_LABEL,
  EXPENSE_TYPE_HOUSEHOLD,
} from '~/constants'
import {
  LIABILITY_TYPE_HOME_LOAN,
  LIABILITY_TYPE_INVESTMENT_HOME_LOAN,
  LIABILITY_TYPE_RECREATION_ASSET_LOAN,
  LIABILITY_TYPE_EQUIPMENT_LOAN,
  LIABILITY_TYPE_PERSONAL_LOAN,
  LIABILITY_TYPE_VEHICLE_LOAN,
  LIABILITY_TYPE_CREDIT_CARD,
  LIABILITY_TYPE_OVERDRAFT,
  LIABILITY_TYPE_BUY_NOW_PAY_LATER,
  APPLICATION_ASSETS_OPTIONS,
  APPLICATION_LIABILITY_OPTIONS,
  APPLICATION_CONTACT_ASSETS_OWNER_OPTIONS,
  APPLICATION_ASSETS_HOME_PROPERTY,
  APPLICATION_ASSETS_INVISTMENT_PROPERTY,
  APPLICATION_ASSETS_VEHICLE,
  APPLICATION_ASSETS_TOOLS_EQUIPMENT,
  APPLICATION_ASSETS_RECREATIONAL,
  INCOME_EXPENSE_FREQUENCY_MONTHLY,
  INCOME_FREQUENCY_OPTIONS,
  ASSETS_INFO,
  LIABILITES_INFO,
  APPLICATION_ASSETS_OWNER_PRIMARY,
  APPLICATION_ASSETS_TYPES,
  APPLICATION_ASSETS_OWNER_JOINT,
  APPLICATION_ASSETS_OWNER_SECONDARY,
  INCOME_EXPENSE_FREQUENCY_WEEKLY,
  INCOME_EXPENSE_FREQUENCY_FORTNIGHTLY,
  INCOME_EXPENSE_FREQUENCY_YEARLY,
  ASSETS_TO_LIABILITY_MAPPING,
  LIABILITES_TO_ASSET_MAPPING,
  FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
  SUBTAB_PRIMARY_ADDRESS_KEY,
  LABEL_EXPENSE_FREQUENCY_MONTHLY,
  LABEL_FOR_CONFIRMABLE_CREDIT_TYPE,
  LABEL_FOR_CONFIRMABLE_CREDIT_LENDER,
  LABEL_FOR_CONFIRMABLE_CREDIT_LOAN_AMOUNT,
  LABEL_FOR_CONFIRMABLE_CREDIT_REPAYMENT_AMOUNT,
  LABEL_FOR_CONFIRMABLE_CREDIT_REPAYMENT_LOAN_STATUS,
  LABEL_FOR_CONFIRMABLE_CREDIT_REPAYMENT_END_DATE,
  CONFIRMABLE_CREDIT_TYPE_OPTIONS,
  CONFIRMABLE_CREDIT_LOAN_STATUS_OPTIONS,
  LIABILITY_TYPE_CONFIRMABLE_CREDIT,
  LOAN_TYPE_BUSINESS,
  ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT_LOAN_TYPE,
  ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT_LENDER,
  ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT_LOAN_AMOUNT,
  ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT_LOAN_STATUS,
  ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT,
  ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT_END_DATE,
  CONFIRMABLE_CREDIT_TYPE_PAID_OUT_VALUE,
  SUBTAB_PRIMARY_EMPLOYMENT_KEY,
  ERROR_MESSAGE_FOR_HAS_LIABILITY,
  SUBTAB_ASSETS_AND_LIABILITIES_KEY,
  APPLICATION_ASSETS_OWNER_SOLE,
} from '~/constants/applications'
import cloneDeep from 'lodash/cloneDeep'
import { mongoObjectId } from '~/utils/mongo'

export default {
  components: {
    TabFooter,
  },
  props: {
    sharedData: {
      type: Object,
      default: null,
    },
    vehicleDetails: {
      type: Object,
      default: null,
    },
    userDetails: {
      type: Object,
      default: null,
    },
    secondaryApplicant: {
      type: Object,
      default: null,
    },
    accountDetails: {
      type: Array,
      default: null,
    },
    vehicleRequestGroup: {
      type: Object,
      default: null,
    },
    activeMenu: {
      type: String,
      default: '1',
    },
    activeSubTab: {
      type: String,
      default: '1',
    },
  },
  data() {
    const checkAmount = (_rule, value, callback) =>
      validatePositiveNumber(value, callback)
    const checkEquity = (_rule, value, callback) =>
      validatePercentage(value, callback)
    /* eslint-enable */
    return {
      validateAssestLiabilityType,
      SUBTAB_ASSETS_AND_LIABILITIES_KEY,
      SUBTAB_PRIMARY_EMPLOYMENT_KEY,
      CONFIRMABLE_CREDIT_TYPE_PAID_OUT_VALUE,
      DOES_APPLICANT_HAVE_CONFIRMABLE_CREDIT_LABEL,
      ERROR_MESSAGE_FOR_HOUSE_HOLD_EXPENSES,
      HOUSE_HOLD_EXPENSES_MONTHLY_LABEL,
      TITLE_FOR_ASSETS,
      TITLE_FOR_LIABILITES,
      TITLE_OF_ASSETS_REMOVE,
      TITLE_OF_LABILITIES_REMOVE,
      INCOME_FREQUENCY_OPTIONS,
      INCOME_EXPENSE_FREQUENCY_MONTHLY,
      APPLICATION_ASSETS_OPTIONS,
      APPLICATION_LIABILITY_OPTIONS,
      APPLICATION_CONTACT_ASSETS_OWNER_OPTIONS,
      APPLICATION_ASSETS_OWNER_PRIMARY,
      LIABILITY_TYPE_HOME_LOAN,
      LIABILITY_TYPE_INVESTMENT_HOME_LOAN,
      LIABILITY_TYPE_OVERDRAFT,
      LIABILITES_INFO,
      APPLICATION_ASSETS_OWNER_SECONDARY,
      APPLICATION_ASSETS_OWNER_JOINT,
      ASSETS_INFO,
      USER_SESSION_DATA_KEY,
      LABEL_FOR_LAST_TYPE,
      LABEL_FOR_OWNER,
      LABEL_FOR_OWNERSHIP,
      LABEL_FOR_OWNERSHIP_EQUITY,
      LABEL_FOR_VALUE,
      LABEL_FOR_OWNED_OUTRIGHT,
      LABEL_FOR_FINANCIER,
      LABEL_FOR_BALANCE,
      LABEL_FOR_PAYMENT,
      LABEL_FOR_FREQUENCY,
      LABEL_FOR_LIMIT,
      LABEL_FOR_PAYOUT,
      TITLE_FOR_ADD_ASSETS,
      TITLE_FOR__ADD_LIABILITES,
      DESCRIPTION_IF_NO_ASSETS_FIRST_PART,
      DESCRIPTION_IF_NO_ASSETS_SECOND_PART,
      DESCRIPTION_IF_NO_LIABILITIES_FIRST_PART,
      DESCRIPTION_IF_NO_LIABILITIES_SECOND_PART,
      INCOME_EXPENSE_FREQUENCY_WEEKLY,
      INCOME_EXPENSE_FREQUENCY_FORTNIGHTLY,
      INCOME_EXPENSE_FREQUENCY_YEARLY,
      TITLE_FOR_CREDIT_COMMITMENTS,
      CREDIT_COMMITMENT_UP_TO_DATE_LABEL,
      ERROR_MESSAGE_FOR_CREDIT_COMMITMENT,
      EXPENSES_AND_COMFORTABLE_CREDIT_TITLE,
      LABEL_FOR_CONFIRMABLE_CREDIT_TYPE,
      LABEL_FOR_CONFIRMABLE_CREDIT_LENDER,
      LABEL_FOR_CONFIRMABLE_CREDIT_LOAN_AMOUNT,
      LABEL_FOR_CONFIRMABLE_CREDIT_REPAYMENT_AMOUNT,
      LABEL_FOR_CONFIRMABLE_CREDIT_REPAYMENT_LOAN_STATUS,
      LABEL_FOR_CONFIRMABLE_CREDIT_REPAYMENT_END_DATE,
      CONFIRMABLE_CREDIT_TYPE_OPTIONS,
      CONFIRMABLE_CREDIT_LOAN_STATUS_OPTIONS,
      LIABILITY_TYPE_CONFIRMABLE_CREDIT,
      LOAN_TYPE_BUSINESS,
      ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT_LOAN_TYPE,
      ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT_LENDER,
      ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT_LOAN_AMOUNT,
      ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT_LOAN_STATUS,
      ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT,
      ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT_END_DATE,
      assetsDialog: false,
      dialogData: {
        firstMessage: '',
        secondMessage: '',
        dialogFor: '',
        index: '',
      },
      totalLiabilitiesPrimaryApplicant: 0,
      totalLiabilitiesSecondaryApplicant: 0,
      totalLiabilitiesJointApplicant: 0,
      showPayOutArray: [
        LIABILITY_TYPE_VEHICLE_LOAN,
        LIABILITY_TYPE_RECREATION_ASSET_LOAN,
        LIABILITY_TYPE_EQUIPMENT_LOAN,
        LIABILITY_TYPE_PERSONAL_LOAN,
        APPLICATION_ASSETS_HOME_PROPERTY,
        APPLICATION_ASSETS_INVISTMENT_PROPERTY,
        APPLICATION_ASSETS_VEHICLE,
        APPLICATION_ASSETS_TOOLS_EQUIPMENT,
        APPLICATION_ASSETS_RECREATIONAL,
      ],
      showLimitArray: [
        LIABILITY_TYPE_CREDIT_CARD,
        LIABILITY_TYPE_OVERDRAFT,
        LIABILITY_TYPE_BUY_NOW_PAY_LATER,
      ],
      assetToliability: ASSETS_TO_LIABILITY_MAPPING,
      liabilityToAsset: LIABILITES_TO_ASSET_MAPPING,
      rules: {
        type: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_SELECT_TYPE_ASSETS,
            trigger: ['blur', 'change'],
          },
        ],
        amount: [
          {
            required: true,
            type: 'number',
            validator: checkAmount,
            trigger: ['blur', 'change'],
          },
        ],
        hasLiability: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_HAS_LIABILITY,
            trigger: ['blur', 'change'],
          },
        ],
        lenderName: [
          {
            max: 20,
            min: 1,
            message: ERROR_MESSAGE_FOR_MAX_TWENTY_CHARACTERS,
            trigger: 'blur',
          },
        ],
        outstandingAmount: [
          {
            required: true,
            type: 'number',
            validator: checkAmount,
            trigger: ['blur', 'change'],
          },
        ],
        installmentAmount: [
          {
            required: true,
            type: 'number',
            validator: checkAmount,
            trigger: ['blur', 'change'],
          },
        ],
        installmentFrequency: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_INVALID,
            trigger: 'blur',
          },
        ],
        creditLimit: [
          {
            required: true,
            type: 'number',
            validator: checkAmount,
            trigger: ['blur', 'change'],
          },
        ],
        payout: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_INVALID,
            trigger: ['blur', 'change'],
          },
        ],
        creditCommitment: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_CREDIT_COMMITMENT,
            trigger: ['blur', 'change'],
          },
        ],
        ownership: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_OWNER,
            trigger: ['blur', 'change'],
          },
        ],
        equity: [
          {
            required: false,
            type: 'number',
            validator: checkEquity,
            trigger: ['blur', 'change'],
          },
        ],
      },
      defaultConfirmableCreditProps: {
        loanType: '',
        lenderName: '',
        loanAmount: 0,
        repaymentAmount: 0,
        loanStatus: '',
        endDate: null,
      },
      assetsLiabilitesData: {
        primaryApplicantData: null,
      },
      saveBtnLoading: false,
      saveAndNextBtnLoading: false,
      isPrimary: true,
      secondaryApplicantIndex: 0,
      loading: true,
    }
  },
  watch: {
    '$route.query.subtab'() {
      if (
        this.$route.query.maintab ===
          FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY &&
        this.$route.query.subtab?.includes(SUBTAB_ASSETS_AND_LIABILITIES_KEY)
      )
        this.updateData()
    },
  },
  computed: {
    getHouseholdExpenses() {
      return (
        this.assetsLiabilitesData?.primaryApplicantData?.expenses?.find(
          (expense) => expense.type === EXPENSE_TYPE_HOUSEHOLD
        )?.amount ?? null
      )
    },
  },
  mounted() {
    this.updateData()
    this.changePrimaryOwnerShipToSole()
    this.$nuxt.$on('update-assets-and-liabilities-subtab', () => {
      this.updateData()
      this.changePrimaryOwnerShipToSole()
    })
  },
  methods: {
    shouldEquityBeDisabled(asset) {
      return asset.ownership !== APPLICATION_ASSETS_OWNER_JOINT
    },
    changePrimaryOwnerShipToSole() {
      this.assetsLiabilitesData?.primaryApplicantData?.assets?.forEach(
        (asset) => {
          if (asset.ownership === APPLICATION_ASSETS_OWNER_PRIMARY) {
            asset.ownership = APPLICATION_ASSETS_OWNER_SOLE
            asset.equity = 100
          }
        }
      )
      this.assetsLiabilitesData?.primaryApplicantData?.liabilities?.forEach(
        (asset) => {
          if (asset.ownership === APPLICATION_ASSETS_OWNER_PRIMARY) {
            asset.ownership = APPLICATION_ASSETS_OWNER_SOLE
          }
        }
      )
    },
    updateData() {
      const { subtab: subTab = '' } = this.$route.query
      this.isPrimary = subTab.indexOf('primary_') !== -1
      this.secondaryApplicantIndex = this.primary
        ? 0
        : parseInt(subTab.split('_')[1]) - 2 ?? 0

      this.assetsLiabilitesData.primaryApplicantData = {
        ...(this.isPrimary &&
          cloneDeep(this.sharedData?.primaryApplicantData ?? {})),
        ...(!this.isPrimary &&
          cloneDeep(
            this.sharedData?.otherApplicants[this.secondaryApplicantIndex] ?? {}
          )),
        householdExpenses: null,
        areAllCreditCommitmentsUptoDate:
          (this.isPrimary
            ? this.sharedData?.primaryApplicantData
                ?.areAllCreditCommitmentsUptoDate
            : this.sharedData?.otherApplicants[this.secondaryApplicantIndex]
                ?.areAllCreditCommitmentsUptoDate) ?? false,
        confirmableCreditProps: {
          loanType: '',
          lenderName: '',
          loanAmount: 0,
          repaymentAmount: 0,
          loanStatus: '',
          endDate: null,
        },
      }
      if (this.assetsLiabilitesData?.primaryApplicantData) {
        this.assetsLiabilitesData.primaryApplicantData.householdExpenses =
          this.assetsLiabilitesData?.primaryApplicantData?.expenses?.find(
            (expense) => expense.type === EXPENSE_TYPE_HOUSEHOLD
          )?.amount ?? null
        this.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps =
          this.assetsLiabilitesData?.primaryApplicantData?.liabilities?.find(
            (liability) => liability.type === LIABILITY_TYPE_CONFIRMABLE_CREDIT
          ) ?? { ...cloneDeep(this.defaultConfirmableCreditProps) }

        if (
          !this.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps
            .repaymentAmount
        ) {
          this.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps.repaymentAmount =
            this.assetsLiabilitesData.primaryApplicantData.confirmableCreditProps.installmentAmount
        }
      }
      this.loading = false
    },
    async submit(event) {
      const { gotoNext } = event
      this.saveBtnLoading = !gotoNext
      this.saveAndNextBtnLoading = !!gotoNext
      try {
        let missingAssetsForLiability = this.getMissingAssetsForLiability()
        let missingLiabilitiesForAssets = this.getMissingLiabilitiesForAssets()

        if (Object.keys(missingAssetsForLiability).length) {
          this.saveBtnLoading = false
          this.saveAndNextBtnLoading = false
          this.$notify({
            title: 'Error',
            message:
              'We could not find (' +
              Object.values(missingAssetsForLiability).join(', ') +
              ') against already added (' +
              Object.keys(missingAssetsForLiability).join(', ') +
              ') respectively',
            type: 'error',
            duration: 6000,
          })
          return
        }
        if (Object.keys(missingLiabilitiesForAssets).length) {
          this.saveBtnLoading = false
          this.saveAndNextBtnLoading = false
          this.$notify({
            title: 'Error',
            message:
              'We could not find (' +
              Object.values(missingLiabilitiesForAssets).join(', ') +
              ') against already added (' +
              Object.keys(missingLiabilitiesForAssets).join(', ') +
              ') respectively',
            type: 'error',
            duration: 6000,
          })
          return
        }
        const { id, applicationId } = this.$route.params
        const isValid = await this.$refs.assetsLiabilitesData.validate()
        if (!isValid) {
          this.saveBtnLoading = false
          this.saveAndNextBtnLoading = false
          return
        }

        this.assetsLiabilitesData.primaryApplicantData.assets = [
          ...this.assetsLiabilitesData.primaryApplicantData.assets,
        ]
        this.assetsLiabilitesData.primaryApplicantData.liabilities = [
          ...this.assetsLiabilitesData.primaryApplicantData.liabilities,
        ]
        const primaryExpenses =
          this.assetsLiabilitesData.primaryApplicantData.expenses
        let primaryApplicantAssets =
          this.assetsLiabilitesData.primaryApplicantData.assets.filter(
            (asset) => {
              return [
                APPLICATION_ASSETS_OWNER_SOLE,
                APPLICATION_ASSETS_OWNER_JOINT,
              ].includes(asset.ownership)
            }
          )
        if (this.assetsLiabilitesData.primaryApplicantData.householdExpenses) {
          if (
            !primaryExpenses.find(
              (primaryExpense) => primaryExpense.type === EXPENSE_TYPE_HOUSEHOLD
            )
          ) {
            primaryExpenses.push({
              type: EXPENSE_TYPE_HOUSEHOLD,
              amount: parseInt(
                this.assetsLiabilitesData.primaryApplicantData.householdExpenses
              ),
              frequency: LABEL_EXPENSE_FREQUENCY_MONTHLY,
            })
          } else {
            primaryExpenses.map((primaryExpense) => {
              if (primaryExpense.type === EXPENSE_TYPE_HOUSEHOLD) {
                primaryExpense.amount = parseInt(
                  this.assetsLiabilitesData.primaryApplicantData
                    .householdExpenses
                )
              }
              return primaryExpense
            })
          }
        }
        const primaryApplicantLiabilities =
          this.assetsLiabilitesData.primaryApplicantData.liabilities.filter(
            (liability) => liability.type !== LIABILITY_TYPE_CONFIRMABLE_CREDIT
          )
        if (
          this.assetsLiabilitesData.primaryApplicantData.hasConfirmableCredit
        ) {
          primaryApplicantLiabilities.push({
            type: LIABILITY_TYPE_CONFIRMABLE_CREDIT,
            loanType:
              this.assetsLiabilitesData.primaryApplicantData
                .confirmableCreditProps.loanType,
            lenderName:
              this.assetsLiabilitesData.primaryApplicantData
                .confirmableCreditProps.lenderName,
            loanAmount:
              this.assetsLiabilitesData.primaryApplicantData
                .confirmableCreditProps.loanAmount,
            installmentAmount:
              this.assetsLiabilitesData.primaryApplicantData
                .confirmableCreditProps.repaymentAmount,
            loanStatus:
              this.assetsLiabilitesData.primaryApplicantData
                .confirmableCreditProps.loanStatus,
            endDate:
              this.assetsLiabilitesData.primaryApplicantData
                .confirmableCreditProps.endDate,
          })
        }
        await this.$store.dispatch(
          'applications/updateContactAssetsLiabilities',
          {
            id,
            applicationId,
            hasConfirmableCredit:
              this.assetsLiabilitesData.primaryApplicantData
                .hasConfirmableCredit,
            expenses: primaryExpenses,
            liabilities: primaryApplicantLiabilities,
            assets: primaryApplicantAssets,
            isPrimary: this.isPrimary,
            secondaryIndex: this.secondaryApplicantIndex,
          }
        )
        this.saveBtnLoading = false
        this.saveAndNextBtnLoading = false
        if (gotoNext) {
          this.$nuxt.$emit('changeContactsTab', {
            tab: FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
            subTab: `${
              this.isPrimary
                ? 'primary_' + SUBTAB_PRIMARY_EMPLOYMENT_KEY
                : 'secondary_' +
                  this.secondaryApplicantIndex +
                  '_' +
                  SUBTAB_PRIMARY_EMPLOYMENT_KEY
            }`,
            isPrimary: this.isPrimary,
            index: this.secondaryApplicantIndex,
          })
        }
      } catch (err) {
        console.log('err ', err)
      } finally {
        this.saveBtnLoading = false
        this.saveAndNextBtnLoading = false
      }
    },
    async back() {
      if (this.$route.query.secondsubtab) {
        await this.gotoPreviousTab('secondsubtab')
      } else {
        await this.gotoPreviousTab('subTab')
        this.$nuxt.$emit('changeContactsTab', {
          tab: FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
          subTab: `${
            this.isPrimary
              ? 'primary_' + SUBTAB_PRIMARY_ADDRESS_KEY
              : 'secondary_' +
                this.secondaryApplicantIndex +
                '_' +
                SUBTAB_PRIMARY_ADDRESS_KEY
          }`,
          isPrimary: this.isPrimary,
          index: this.secondaryApplicantIndex,
        })
      }
    },
    gotoPreviousTab(tab) {
      changeRoute({
        route: this.$route,
        router: this.$router,
        query: {
          ...this.$route.query,
          [tab]: `${
            this.isPrimary
              ? 'primary_' + SUBTAB_PRIMARY_ADDRESS_KEY
              : 'secondary_' +
                this.secondaryApplicantIndex +
                '_' +
                SUBTAB_PRIMARY_ADDRESS_KEY
          }`,
          isPrimary: this.isPrimary,
          index: this.secondaryApplicantIndex,
        },
      })
    },
    calculateTotalLiabilities() {
      this.totalLiabilitiesPrimaryApplicant = 0
      this.totalLiabilitiesSecondaryApplicant = 0
      this.totalLiabilitiesJointApplicant = 0
      if (this.assetsLiabilitesData?.primaryApplicantData?.liabilities) {
        this.assetsLiabilitesData?.primaryApplicantData?.liabilities?.forEach(
          (liability) => {
            if (liability.ownership === this.APPLICATION_ASSETS_OWNER_PRIMARY) {
              this.totalLiabilitiesPrimaryApplicant += parseInt(
                liability.installmentAmount
              )
            } else if (
              liability.ownership === this.APPLICATION_ASSETS_OWNER_JOINT
            ) {
              this.totalLiabilitiesJointApplicant += parseInt(
                liability.installmentAmount
              )
            }
          }
        )
      }
    },
    numberWithCommas(x) {
      return toCurrency(x)
    },
    checkFrequency(incomeAmount, frequency) {
      if (frequency === INCOME_EXPENSE_FREQUENCY_WEEKLY) {
        return (incomeAmount * 52) / 12
      } else if (frequency === INCOME_EXPENSE_FREQUENCY_FORTNIGHTLY) {
        return (incomeAmount * 26) / 12
      } else if (frequency === INCOME_EXPENSE_FREQUENCY_YEARLY) {
        return incomeAmount / 12
      } else if (frequency === INCOME_EXPENSE_FREQUENCY_MONTHLY) {
        return incomeAmount
      } else {
        return incomeAmount
      }
    },
    hasPrimaryAssets(assetsData) {
      return (
        Array.isArray(assetsData.primaryApplicantData.assets) &&
        Boolean(assetsData.primaryApplicantData.assets.length)
      )
    },
    hasDisabled() {
      return !this.assetsLiabilitesData.otherApplicants.length
    },
    hasAssets(assetsData) {
      return this.hasPrimaryAssets(assetsData)
    },
    hasPrimaryLiabilities(liabilitiesData) {
      return (
        Array.isArray(liabilitiesData.primaryApplicantData.liabilities) &&
        Boolean(liabilitiesData.primaryApplicantData.liabilities.length)
      )
    },
    hasLiabilities(liabilitiesData) {
      return this.hasPrimaryLiabilities(liabilitiesData)
    },
    onDeleteAsset(indexNum, assetType) {
      if (
        this.assetToliability[assetType] &&
        this.assetsLiabilitesData.primaryApplicantData.assets[indexNum]
          .hasLiability
      ) {
        this.dialogData = {
          firstMessage: TITLE_OF_ASSETS_REMOVE.replace(
            REGEX_FOR_ASSET,
            assetType
          ),
          secondMessage: DESCRIPTION_FOR_REMOVE_ASSETS.replace(
            REGEX_FOR_ASSET,
            assetType
          ).replace(REGEX_FOR_LIABILITIES, this.assetToliability[assetType]),
          dialogFor: TITLE_FOR_ASSETS,
          index: indexNum,
        }
        this.assetsDialog = true
      } else {
        this.removeData(TITLE_FOR_ASSETS, indexNum)
      }
    },
    onDeleteLiabilities(indexNum, liabilitiesType) {
      if (this.liabilityToAsset[liabilitiesType]) {
        this.dialogData = {
          firstMessage: TITLE_OF_LABILITIES_REMOVE.replace(
            REGEX_FOR_LIABILITIES,
            liabilitiesType
          ),
          secondMessage: DESCRIPTION_FOR_REMOVE_LIABILITIES.replace(
            REGEX_FOR_LIABILITIES,
            liabilitiesType
          ).replace(REGEX_FOR_ASSET, this.liabilityToAsset[liabilitiesType]),
          dialogFor: TITLE_FOR_LIABILITES,
          index: indexNum,
        }
        this.assetsDialog = true
      } else {
        this.removeData(TITLE_FOR_LIABILITES, indexNum)
      }

      this.calculateTotalLiabilities()
    },

    removeData(arrayFor, index) {
      if (arrayFor === this.TITLE_FOR_ASSETS) {
        const asset =
          this.assetsLiabilitesData?.primaryApplicantData?.assets[index]
        if (asset) {
          if (asset.hasLiability && asset.liability) {
            this.assetsLiabilitesData.primaryApplicantData.liabilities =
              this.assetsLiabilitesData.primaryApplicantData.liabilities.filter(
                ({ _id }) => _id !== asset.liability
              )
          }
          this.assetsLiabilitesData.primaryApplicantData.assets.splice(index, 1)
        }
      } else {
        const liability =
          this.assetsLiabilitesData?.primaryApplicantData?.liabilities[index]
        if (liability) {
          if (liability._id) {
            this.assetsLiabilitesData.primaryApplicantData.assets =
              this.assetsLiabilitesData.primaryApplicantData.assets.filter(
                (asset) => asset.liability !== liability._id
              )
          }
          this.assetsLiabilitesData.primaryApplicantData.liabilities.splice(
            index,
            1
          )
        }
      }
      this.assetsDialog = false
    },
    addAssets() {
      this.assetsLiabilitesData.primaryApplicantData.assets.push({
        ...ASSETS_INFO,
      })
    },
    addLiabilites(override = {}) {
      this.assetsLiabilitesData.primaryApplicantData.liabilities.push({
        ...LIABILITES_INFO,
        ...override,
      })
    },

    getMissingAssetsForLiability() {
      let missingAssetsForLiability = {}
      this.assetsLiabilitesData.primaryApplicantData.liabilities
        .filter(
          (liability) => liability.type !== LIABILITY_TYPE_CONFIRMABLE_CREDIT
        )
        .forEach((element) => {
          let checkVal = null
          if (this.liabilityToAsset.hasOwnProperty(element.type)) {
            checkVal = this.liabilityToAsset[element.type]
            if (
              !this.assetsLiabilitesData?.primaryApplicantData?.assets?.some(
                ({ liability }) => liability === element._id
              )
            ) {
              missingAssetsForLiability[element.type] =
                APPLICATION_ASSETS_TYPES.filter(
                  (i) => i.value === checkVal
                )[0].label
            }
          }
        })
      return missingAssetsForLiability
    },
    getMissingLiabilitiesForAssets() {
      let missingLiabilitiesForAssets = {}
      this.assetsLiabilitesData?.primaryApplicantData?.assets?.forEach(
        (element, index) => {
          let checkAssetVal = null
          if (
            this.assetsLiabilitesData.primaryApplicantData.assets[index]
              .hasLiability
          ) {
            if (this.assetToliability.hasOwnProperty(element.type)) {
              checkAssetVal = this.assetToliability[element.type]
              if (
                !this.assetsLiabilitesData.primaryApplicantData.liabilities.some(
                  ({ _id }) => _id === element.liability
                )
              ) {
                missingLiabilitiesForAssets[element.type] =
                  APPLICATION_ASSETS_TYPES.filter(
                    (i) => i.value === checkAssetVal ?? ''
                  )[0]?.label
              }
            }
          }
        }
      )

      return missingLiabilitiesForAssets
    },

    /**
     * Toggle liabilites when `Owned outright` is changed.
     */
    setLiability(asset, index) {
      if (asset.hasLiability) {
        // If user selects `No`.
        const objectId = mongoObjectId()
        this.addLiabilites({
          _id: objectId,
          ownership: asset.ownership,
          type: ASSETS_TO_LIABILITY_MAPPING[asset.type],
        })
        this.assetsLiabilitesData.primaryApplicantData.assets[index].liability =
          objectId
      } else {
        // If user selects `Yes`
        this.assetsLiabilitesData.primaryApplicantData.liabilities =
          this.assetsLiabilitesData.primaryApplicantData.liabilities.filter(
            ({ _id }) => _id !== asset.liability
          )
        this.assetsLiabilitesData.primaryApplicantData.assets[index].liability =
          undefined
      }
    },

    /**
     * Toggle connected asset or liability when change in ownership.
     */
    onOwnershipChange({ type, payload, index }) {
      if (type === TITLE_FOR_ASSETS) {
        // When ownership in asset is changed.
        const idx =
          this.assetsLiabilitesData.primaryApplicantData.liabilities.findIndex(
            ({ _id }) => _id === payload.liability
          )
        idx !== -1 &&
          (this.assetsLiabilitesData.primaryApplicantData.liabilities[
            idx
          ].ownership = payload.ownership)
        if (payload.ownership === APPLICATION_ASSETS_OWNER_SOLE) {
          this.assetsLiabilitesData.primaryApplicantData.assets[
            index
          ].equity = 100
        }
      } else {
        // When ownership is liability is changed.
        const idx =
          this.assetsLiabilitesData.primaryApplicantData.assets.findIndex(
            ({ liability }) => liability === payload._id
          )
        idx !== -1 &&
          (this.assetsLiabilitesData.primaryApplicantData.assets[
            idx
          ].ownership = payload.ownership)
      }
    },
  },
}
