import Vue from 'vue'
Vue.directive('restrict', {
  bind(el, binding) {
    el.addEventListener('keydown', (e) => {
      if (
        binding.modifiers['number'] &&
        ((e.keyCode >= 37 && e.keyCode <= 57) ||
          (e.keyCode >= 96 && e.keyCode <= 105) ||
          [8, 190, 110, 67, 86, 65, 9].includes(e.keyCode))
      ) {
        return
      }
      e.preventDefault()
    })
  },
})

Vue.directive('restrictnumber', {
  bind(el, binding) {
    el.addEventListener('keydown', (e) => {
      if (
        binding.modifiers['number'] &&
        ((e.keyCode >= 37 && e.keyCode <= 57) ||
          (e.keyCode >= 96 && e.keyCode <= 105) ||
          [8, 190, 110, 67, 86, 65, 9, 189].includes(e.keyCode))
      ) {
        return
      }
      e.preventDefault()
    })
  },
})

Vue.directive('restrictdecimal', {
  bind(el, binding) {
    el.addEventListener('keydown', (e) => {
      if (e.keyCode == 110 || e.keyCode == 190) {
        return
      }
      if (
        binding.modifiers['number'] &&
        ((e.keyCode >= 37 && e.keyCode <= 57) ||
          (e.keyCode >= 96 && e.keyCode <= 105) ||
          [8, 190, 110, 67, 86, 65, 9].includes(e.keyCode))
      ) {
        return
      }
      e.preventDefault()
    })
  },
})

Vue.directive('restrictdecimal', {
  bind(el, binding) {
    el.addEventListener('keydown', (e) => {
      if (e.keyCode == 110 || e.keyCode == 190) {
        return
      }
      if (
        binding.modifiers['number'] &&
        ((e.keyCode >= 37 && e.keyCode <= 57) ||
          (e.keyCode >= 96 && e.keyCode <= 105) ||
          [8, 190, 110, 67, 86, 65, 9].includes(e.keyCode))
      ) {
        return
      }
      e.preventDefault()
    })
  },
})

Vue.directive('restrictdecimalDigit', {
  bind(el, binding) {
    el.addEventListener('keydown', (e) => {
      if (
        binding.modifiers['number'] &&
        ((e.keyCode >= 37 && e.keyCode <= 57) ||
          (e.keyCode >= 96 && e.keyCode <= 105) ||
          [8, 67, 86, 65, 9].includes(e.keyCode))
      ) {
        return
      }
      e.preventDefault()
    })
  },
})
