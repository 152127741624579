const requestUrl = '/ip-addresses'

export default {
  async fetchIpAddresses({ commit }, { currentPage = 1 }) {
    try {
      const requests = await this.$axios.get(requestUrl, {
        params: {
          page: currentPage,
        },
      })
      const { data } = requests
      commit('setIpAddresses', data.docs)
      commit('setTableSettings', {
        totalDocs: data.totalDocs,
        page: data.page,
        totalPages: data.totalPages,
        limit: data.limit,
        pagingCounter: data.pagingCounter,
      })
    } catch (err) {
      console.log('error', err)
      this.$notify.error({
        title: 'Error',
        message: 'Error getting the requests :(',
      })
    } finally {
      commit('stopTableLoading')
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async getIpAddressById({}, _id) {
    try {
      if (!_id) {
        return
      }
      const requests = await this.$axios.get(`${requestUrl}/${_id}`)
      return requests.data
    } catch (err) {
      console.log('error', err)
      this.$notify.error({
        title: 'Error',
        message: 'Error getting the requests :(',
      })
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async createIpAddress({}, ipAddressBody) {
    try {
      const requests = await this.$axios.post(requestUrl, ipAddressBody)
      return requests.data
    } catch (err) {
      console.log('error', err)
      this.$notify.error({
        title: 'Error',
        message: 'Error getting the requests :(',
      })
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async updateIpAddress({}, ipAddressBody) {
    try {
      if (!ipAddressBody._id) {
        return
      }
      const requests = await this.$axios.patch(
        `${requestUrl}/${ipAddressBody._id}`,
        ipAddressBody
      )
      return requests.data
    } catch (err) {
      console.log('error', err)
      this.$notify.error({
        title: 'Error',
        message: 'Error getting the requests :(',
      })
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async deleteIpAddressById({}, _id) {
    try {
      if (!_id) {
        return
      }
      const requests = await this.$axios.delete(`${requestUrl}/${_id}`)
      return requests.data
    } catch (err) {
      console.log('error', err)
      this.$notify.error({
        title: 'Error',
        message: 'Error getting the requests :(',
      })
    }
  },
}
