import commonService from '~/services/common.service'
import { axiosErrorMessageExtractor } from '~/utils/error'

export default {
  async fetchAccountsAction(
    { commit },
    { isLender = true, isInsurer, isWarrantyProvider, isPartner }
  ) {
    try {
      const { data: accounts } = await commonService.getAccountsAPI({
        isLender,
        isInsurer,
        isWarrantyProvider,
        isPartner,
        ...this,
      })
      commit('setAccounts', { accounts })
    } catch (err) {
      console.error(err)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(err),
      })
    }
  },
  reset({ commit }) {
    commit('reset')
  },
}
