//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import isDesktop from '@/mixins/device-mixin'
import {
  LABEL_NAVIGATION_DUPLICATED,
  LABEL_REFERRALS,
  LABEL_SETTLEMENTS,
} from '~/constants/applications'
export default {
  data() {
    return {
      LABEL_SETTLEMENTS,
      LABEL_REFERRALS,
      LABEL_NAVIGATION_DUPLICATED,
    }
  },
  computed: {
    ...mapGetters('referrals', ['getIsTableLoading']),
    getRoute() {
      return this.$route.path
    },
  },
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [isDesktop],
  methods: {
    closeEvent() {
      if (!this.isDesktop) {
        this.$nuxt.$emit('set-collapse')
      }
    },
    appNavigation(path, isReferral) {
      this.$router.push(path).catch((err) => {
        if (err.name === LABEL_NAVIGATION_DUPLICATED) {
          isReferral
            ? this.$root.$emit('update-referral-lists')
            : this.$root.$emit('update-settlements-lists')
        }
      })
      this.closeEvent()
    },
  },
}
