var render = function () {
  var _vm$assetData$vehicle, _vm$assetData$vehicle2, _vm$assetData$vehicle3, _vm$assetData$vehicle4, _vm$assetData$vehicle5, _vm$assetData$vehicle6, _vm$assetData$vehicle7, _vm$assetData$vehicle8, _vm$assetData$vehicle9, _vm$assetData$vehicle10, _vm$assetData$vehicle11, _vm$assetData$vehicle12, _vm$assetData$vehicle13, _vm$assetData$vehicle14, _vm$assetData$vehicle15, _vm$assetData$vehicle16, _vm$assetData$vehicle17;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [!_vm.isAssetVehicleUnknown ? _c('el-col', {
    attrs: {
      "sm": 24
    }
  }, [_c('div', {
    staticClass: "pt-4"
  }, [_c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 12
    }
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "asset-type"
    }
  }, [_vm._v(_vm._s(_vm.isAssetVehicle ? _vm.APPLICATION_ASSETS_VEHICLE : _vm.APPLICATION_ASSETS_OTHER))]), _vm._v(" "), _vm.isSaletypeStillDeciding || _vm.isCertaintyStageStillDecidingV3 ? _c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "vehicle-details"
    }
  }, [_c('span', [_vm._v("--")])]) : _c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "vehicle-details"
    }
  }, [_vm.vehicleDetailsEnteredManually || !_vm.isAssetVehicle ? _c('span', [_vm._v("\n              " + _vm._s(((_vm$assetData$vehicle = _vm.assetData.vehicle) === null || _vm$assetData$vehicle === void 0 ? void 0 : _vm$assetData$vehicle.customerNote) || '--') + "\n            ")]) : _c('span', [((_vm$assetData$vehicle2 = _vm.assetData.vehicle) === null || _vm$assetData$vehicle2 === void 0 ? void 0 : _vm$assetData$vehicle2.year) !== '' && ((_vm$assetData$vehicle3 = _vm.assetData.vehicle) === null || _vm$assetData$vehicle3 === void 0 ? void 0 : _vm$assetData$vehicle3.makeDescription) !== '' && ((_vm$assetData$vehicle4 = _vm.assetData.vehicle) === null || _vm$assetData$vehicle4 === void 0 ? void 0 : _vm$assetData$vehicle4.familyDescription) !== '' && ((_vm$assetData$vehicle5 = _vm.assetData.vehicle) === null || _vm$assetData$vehicle5 === void 0 ? void 0 : _vm$assetData$vehicle5.codeDescription) !== '' ? _c('span', [_vm._v("\n                " + _vm._s(((_vm$assetData$vehicle6 = _vm.assetData.vehicle) === null || _vm$assetData$vehicle6 === void 0 ? void 0 : _vm$assetData$vehicle6.year) || '') + "\n                " + _vm._s((_vm$assetData$vehicle7 = _vm.assetData.vehicle) !== null && _vm$assetData$vehicle7 !== void 0 && _vm$assetData$vehicle7.makeDescription ? (((_vm$assetData$vehicle8 = _vm.assetData.vehicle) === null || _vm$assetData$vehicle8 === void 0 ? void 0 : _vm$assetData$vehicle8.makeDescription) + " - ") : '') + "\n                " + _vm._s(((_vm$assetData$vehicle9 = _vm.assetData.vehicle) === null || _vm$assetData$vehicle9 === void 0 ? void 0 : _vm$assetData$vehicle9.familyDescription) || '') + "\n                " + _vm._s(((_vm$assetData$vehicle10 = _vm.assetData.vehicle) === null || _vm$assetData$vehicle10 === void 0 ? void 0 : _vm$assetData$vehicle10.codeDescription) || '') + "\n              ")]) : _c('span', [_vm._v("--")])])])]), _vm._v(" "), _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 12
    }
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "year-label"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_ASSET_DETAILS_YEAR))]), _vm._v(" "), _c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "year-value"
    }
  }, [_vm._v(_vm._s(!_vm.isSaletypeStillDeciding ? _vm.vehicleYear : '--'))])])], 1)], 1)]) : _c('el-col', [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "asset-type"
    }
  }, [_vm._v(_vm._s(_vm.isAssetVehicleUnknown ? _vm.LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN : (_vm$assetData$vehicle11 = _vm.assetData.vehicle) === null || _vm$assetData$vehicle11 === void 0 ? void 0 : _vm$assetData$vehicle11.assetType))])]), _vm._v(" "), _c('div', {
    staticClass: "pb-4"
  }, [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "vehicle-details"
    }
  }, [_vm.vehicleDetailsEnteredManually || !_vm.isAssetVehicle ? _c('span', [_vm._v("\n          " + _vm._s(((_vm$assetData$vehicle12 = _vm.assetData.vehicle) === null || _vm$assetData$vehicle12 === void 0 ? void 0 : _vm$assetData$vehicle12.customerNote) || '') + "\n        ")]) : _c('span', [_vm._v("\n          " + _vm._s(((_vm$assetData$vehicle13 = _vm.assetData.vehicle) === null || _vm$assetData$vehicle13 === void 0 ? void 0 : _vm$assetData$vehicle13.year) || '') + "\n          " + _vm._s((_vm$assetData$vehicle14 = _vm.assetData.vehicle) !== null && _vm$assetData$vehicle14 !== void 0 && _vm$assetData$vehicle14.makeDescription ? (((_vm$assetData$vehicle15 = _vm.assetData.vehicle) === null || _vm$assetData$vehicle15 === void 0 ? void 0 : _vm$assetData$vehicle15.makeDescription) + " - ") : '') + "\n          " + _vm._s(((_vm$assetData$vehicle16 = _vm.assetData.vehicle) === null || _vm$assetData$vehicle16 === void 0 ? void 0 : _vm$assetData$vehicle16.familyDescription) || '') + "\n          " + _vm._s(((_vm$assetData$vehicle17 = _vm.assetData.vehicle) === null || _vm$assetData$vehicle17 === void 0 ? void 0 : _vm$assetData$vehicle17.codeDescription) || '') + "\n        ")])])])]), _vm._v(" "), !_vm.isAssetVehicleUnknown ? _c('el-col', {
    attrs: {
      "sm": 24
    }
  }, [_c('div', {
    staticClass: "border-t border-light-grey pt-4"
  }, [_c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 12
    }
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "condition-label"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CONDITION))]), _vm._v(" "), _c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "condition-value"
    }
  }, [_vm._v(_vm._s(_vm.vehicleCondition !== _vm.LABEL_ASSET_TYPE_STILL_DECIDING ? _vm.vehicleCondition : '--'))])]), _vm._v(" "), _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 12
    }
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "sale-type-label"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_SALE_TYPE))]), _vm._v(" "), _c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "sale-type"
    }
  }, [_vm._v(_vm._s(!_vm.isSaletypeStillDeciding ? _vm.mapSaleTypeValuesToLabel(_vm.vehiclePurchaseSource) : '--'))])]), _vm._v(" "), !_vm.isRequirementTab && _vm.isAssetVehicle ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 12
    }
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "vin-label"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_VIN))]), _vm._v(" "), _c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "vin"
    }
  }, [_vm._v(_vm._s(_vm.vehicleVIN))])]) : _vm._e(), _vm._v(" "), !_vm.isRequirementTab && _vm.isAssetVehicle ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 12
    }
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "rego-label"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_REGISTRATION_PLATE))]), _vm._v(" "), _c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "rego"
    }
  }, [_vm._v(_vm._s(_vm.vehicleRegistrationPlate))])]) : _vm._e()], 1)], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }