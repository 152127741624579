var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "position": "fixed",
      "left": "0",
      "right": "0",
      "top": "0",
      "z-index": "999"
    }
  }, [_c('el-header', {
    staticClass: "header",
    class: {
      fixed: _vm.shouldRender && !_vm.isReferrals
    },
    style: _vm.setLogoPosition,
    attrs: {
      "height": "56px"
    }
  }, [_c('div', {
    staticClass: "header-section"
  }, [!_vm.isMobile ? _c('img', {
    staticClass: "header-logo",
    attrs: {
      "src": require("../assets/icons/cc-logo.svg")
    }
  }) : _c('img', {
    staticClass: "header-logo",
    staticStyle: {
      "width": "unset"
    },
    attrs: {
      "src": require("../assets/icons/cc-logo-full.svg")
    }
  }), _vm._v(" "), !_vm.isReferralHost ? _c('div', {
    staticClass: "header-tag"
  }, [_vm.isPortalTitleVisible ? _c('span', {
    staticClass: "top-tag"
  }, [_vm._v(_vm._s(_vm.FUEL))]) : _vm._e()]) : _vm._e(), _vm._v(" "), _vm.isReferralHost && !_vm.$auth.loggedIn ? _c('div', {
    staticClass: "header-tag"
  }, [_vm.isPortalTitleVisible ? _c('span', {
    staticClass: "top-tag"
  }, [_vm._v(_vm._s(_vm.LABEL_ACCELERATE) + "\n        ")]) : _vm._e()]) : _vm._e(), _vm._v(" "), _vm.isReferralHost && _vm.partnerName && _vm.isPortalTitleVisible ? _c('div', {
    staticClass: "flex"
  }, [_c('i', {
    staticClass: "el-icon-close mx-1 flex items-center",
    staticStyle: {
      "font-size": "10px",
      "font-weight": "700",
      "padding-top": "1px",
      "margin-left": "3px !important"
    }
  }), _vm._v(" "), _c('div', [_c('span', {
    staticClass: "partner-name flex items-center"
  }, [_vm._v(_vm._s(_vm.partnerName))])])]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "header-section"
  }), _vm._v(" "), _c('div', {
    staticClass: "header-section"
  }, [_vm.isDropDownVisible ? [_c('el-dropdown', {
    attrs: {
      "trigger": "click"
    }
  }, [_c('div', {
    staticClass: "flex items-center",
    staticStyle: {
      "cursor": "pointer"
    }
  }, [_c('img', {
    staticClass: "header-profile-graphic",
    staticStyle: {
      "margin": "0.25rem"
    },
    attrs: {
      "data-selector": "menu-trigger",
      "src": require("../assets/icons/graphic-user.svg")
    }
  }), _vm.userName ? _c('span', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm.userName))]) : _vm._e()]), _vm._v(" "), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown",
      "data-selector": "user-menu-container"
    },
    slot: "dropdown"
  }, [_vm.doesImpersonateOptionVisible ? _c('el-dropdown-item', {
    attrs: {
      "data-selector": "impersonate"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.openDialog.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "dropdown-menu-link"
  }, [_vm._v(_vm._s(_vm.LABEL_IMPERSONATE))])]) : _vm._e(), _vm._v(" "), _vm.doesImpersonateOptionVisible ? _c('el-dropdown-item', {
    attrs: {
      "data-selector": "impersonate"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.openDialogForImpersonateAccelerate.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "dropdown-menu-link"
  }, [_vm._v(_vm._s(_vm.LABEL_IMPERSONATE_ACCELERATE))])]) : _vm._e(), _vm._v(" "), !_vm.isReferralHost ? _c('el-dropdown-item', {
    attrs: {
      "data-selector": "change-password"
    }
  }, [_c('NuxtLink', {
    staticClass: "dropdown-menu-link",
    attrs: {
      "to": "/change-password"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CHANGE_PASSWORD))])], 1) : _vm._e(), _vm._v(" "), _vm.isReferralHost ? _c('el-dropdown-item', {
    attrs: {
      "data-selector": "change-password"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.openCloseChangePasswordDialog.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "dropdown-menu-link"
  }, [_vm._v(_vm._s(_vm.LABEL_CHANGE_PASSWORD))])]) : _vm._e(), _vm._v(" "), _c('el-dropdown-item', {
    attrs: {
      "data-selector": "logout"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.$emit('logout');
      }
    }
  }, [_c('span', {
    staticClass: "dropdown-menu-link"
  }, [_vm._v(_vm._s(_vm.LABEL_LOGOUT))])])], 1)], 1)] : _vm._e()], 2)]), _vm._v(" "), _c('ImpersonateDialog', {
    attrs: {
      "should-show-impersonate-dialog": _vm.openImpersonateDialog
    },
    on: {
      "impersonateUser": _vm.impersonateUser
    }
  }), _vm._v(" "), _c('ImpersonateDialogAccelerate', {
    attrs: {
      "should-show-impersonate-dialog": _vm.openImpersonateAccelerateDialog
    },
    on: {
      "impersonateAccelerateUser": _vm.impersonateAccelerateUser
    }
  }), _vm._v(" "), _c('ChangePasswordAccelerate', {
    attrs: {
      "should-show-change-password-dialog": _vm.openChangePasswordDialog
    },
    on: {
      "openCloseChangePasswordDialog": _vm.openCloseChangePasswordDialog
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }