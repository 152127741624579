//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import format from 'date-fns/format'
import {
  LABEL_FOR_LAST_UPDATED_AT,
  PLACEHOLDER_FOR_WRITE_TEXT,
  LABEL_FOR_DESCRIPTION,
  LABEL_FOR_BROKER_NOTES,
  ERROR_MESSAGE_FOR_MODEL_DESCRIPTION,
  ERROR_MESSAGE_FOR_MAX_TWO_THOUSAND_CHARACTERS,
} from '~/constants'

export default {
  props: {
    sharedData: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.ruleForm.description = this.sharedData?.notes[0]?.description
      ? this.sharedData?.notes[0]?.description
      : ''

    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },
  computed: {
    updatedAt() {
      return format(
        new Date(this.sharedData?.notes[0]?.updatedAt),
        'dd/MM/yyyy hh:mm aaa'
      )
    },
  },
  data() {
    return {
      format,
      LABEL_FOR_LAST_UPDATED_AT,
      PLACEHOLDER_FOR_WRITE_TEXT,
      LABEL_FOR_DESCRIPTION,
      LABEL_FOR_BROKER_NOTES,
      ruleForm: {
        description: '',
      },
      rules: {
        description: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MODEL_DESCRIPTION,
            trigger: ['blur', 'change'],
          },
          {
            max: 2000,
            message: ERROR_MESSAGE_FOR_MAX_TWO_THOUSAND_CHARACTERS,
            trigger: ['blur', 'change'],
          },
        ],
      },
      getIsDialogLoading: false,
      ERROR_MESSAGE_FOR_MODEL_DESCRIPTION,
    }
  },
  methods: {
    async submit() {
      let isValid = false
      this.$refs.ruleForm.validate((result) => {
        return (isValid = result)
      })
      if (!isValid) {
        return
      }
      this.getIsDialogLoading = true

      const { id, applicationId } = this.$route.params
      await this.$store.dispatch('applications/updateNotes', {
        notes: this.ruleForm.description,
        id,
        applicationId,
      })
      this.getIsDialogLoading = false
    },
  },
}
