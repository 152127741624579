//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { toCurrency } from '@/helpers/currency'

import {
  LABEL_FOR_REPAYMENTS,
  LABEL_FOR_COMPARISON_RATE,
  LABEL_FOR_LENDING_COMMISSION,
  LABEL_FOR_ORIGINATION_FEE,
  LABEL_FOR_TOTAL_FINANCE_INCOME,
  LABEL_FOR_ACCOUNT_KEEPING,
  LABEL_FOR_ESTABLISHMENT,
  LABEL_FOR_PSPR,
  LABEL_FOR_FEES,
  KEY_FOR_FEATURES,
  KEY_FOR_FEES,
  KEY_FOR_DOCUMENTS,
  LABEL_FOR_DOCUMENTS,
  LABEL_FOR_FEATURES,
  LABEL_FOR_MONTLY,
  LABEL_FOR_FORTNIGHT,
  LABEL_FOR_WEEK,
  LABEL_FOR_KNOCKOUTS,
  KEY_FOR_KNOCKOUTS,
  LABEL_FOR_BASE,
  LABEL_FOR_BROKER_LOADING,
  ERROR_MESSAGE_FOR_LOAN_TERM,
  LABEL_FOR_ORIGINATION_FEE_CAPITAL,
  LABEL_FOR_COMPARISON_RATE_FOR_LENDER,
  LABEL_FOR_CUSTOMER_RATE_FOR_LENDER,
  LABEL_FOR_BASE_RATE_FOR_LENDER,
  LABEL_FOR_MONTH,
} from '~/constants'
import {
  INCOME_EXPENSE_FREQUENCY_FORTNIGHTLY,
  INCOME_EXPENSE_FREQUENCY_WEEKLY,
  LABEL_DOCUMENT_DETAILS,
  LABEL_FOR_LOAN_TERM,
  LABEL_EXPENSE_FREQUENCY_FORTNIGHTLY,
  LABEL_EXPENSE_FREQUENCY_MONTHLY,
  LABEL_EXPENSE_FREQUENCY_WEEKLY,
  LABEL_NAF,
  LABEL_CUSTOMER_RATE,
  LABEL_LENDER_MONTHLY_FEE,
  LABEL_LENDER_FEE,
  ERROR_MESSAGES_FOR_ORIGINATIONFEE,
  ERROR_MESSAGES_FOR_ORIGINATIONFEE_EQUAL_OR_GREATER_TO_ZERO,
  ERROR_MESSAGES_FOR_BROKERLOADING,
  LABEL_DOCUMENT_LOAN_TERM,
  LABEL_FOR_BALLOON_PERCENT,
  LABEL_LOAN_BALLOON_DOLLAR,
} from '~/constants/applications'
import { formatNumber } from '~/utils/string'
export default {
  data() {
    var validateBrokerLoading = (rule, value, callback) => {
      this.brokerLoadingisValid = true
      if (value === '' || value > this.maxBrokerLoading || value < 0) {
        this.brokerLoadingisValid = false
        callback(
          new Error(ERROR_MESSAGES_FOR_BROKERLOADING + this.maxBrokerLoading)
        )
      } else {
        this.brokerLoadingisValid = true
        callback()
      }
    }
    var validateOriginationFee = (rule, value, callback) => {
      this.brokerLoadingisValid = true
      if (value < 0 || value === '') {
        callback(
          new Error(ERROR_MESSAGES_FOR_ORIGINATIONFEE_EQUAL_OR_GREATER_TO_ZERO)
        )
      } else if (value > this.lenderData?.commission?.brokerFeeLimit) {
        callback(
          new Error(
            ERROR_MESSAGES_FOR_ORIGINATIONFEE +
              this.lenderData.commission.brokerFeeLimit
          )
        )
      } else {
        callback()
      }
    }
    return {
      ERROR_MESSAGE_FOR_LOAN_TERM,
      KEY_FOR_KNOCKOUTS,
      LABEL_FOR_KNOCKOUTS,
      LABEL_FOR_REPAYMENTS,
      LABEL_CUSTOMER_RATE,
      LABEL_FOR_COMPARISON_RATE,
      LABEL_FOR_LENDING_COMMISSION,
      LABEL_FOR_ORIGINATION_FEE,
      LABEL_FOR_TOTAL_FINANCE_INCOME,
      LABEL_LENDER_MONTHLY_FEE,
      LABEL_LENDER_FEE,
      LABEL_FOR_ACCOUNT_KEEPING,
      LABEL_FOR_ESTABLISHMENT,
      LABEL_FOR_FEES,
      LABEL_FOR_PSPR,
      KEY_FOR_FEATURES,
      KEY_FOR_FEES,
      KEY_FOR_DOCUMENTS,
      LABEL_FOR_DOCUMENTS,
      LABEL_FOR_FEATURES,
      LABEL_FOR_MONTLY,
      LABEL_FOR_FORTNIGHT,
      LABEL_FOR_WEEK,
      activeTab: KEY_FOR_FEATURES,
      showTabs: false,
      INCOME_EXPENSE_FREQUENCY_WEEKLY,
      INCOME_EXPENSE_FREQUENCY_FORTNIGHTLY,
      LABEL_NAF,
      LABEL_FOR_BASE,
      repaymentsType: LABEL_FOR_MONTH,
      matchSettings: {
        brokerLoading: 0,
        originationFee: 0,
        loanTermYears: 0,
      },
      LABEL_DOCUMENT_DETAILS,
      LABEL_FOR_LOAN_TERM,
      LABEL_FOR_BROKER_LOADING,
      value4: '',
      matchSettingsRules: {
        originationFee: [
          {
            required: true,
            validator: validateOriginationFee,
            trigger: ['blur', 'change'],
          },
        ],
        brokerLoading: [
          {
            required: true,

            validator: validateBrokerLoading,
            trigger: ['blur', 'change'],
          },
        ],
      },
      brokerLoadingisValid: true,
      LABEL_FOR_ORIGINATION_FEE_CAPITAL,
      matchSettingLoading: false,
      LABEL_EXPENSE_FREQUENCY_FORTNIGHTLY,
      LABEL_EXPENSE_FREQUENCY_MONTHLY,
      LABEL_EXPENSE_FREQUENCY_WEEKLY,
      LABEL_FOR_COMPARISON_RATE_FOR_LENDER,
      LABEL_FOR_CUSTOMER_RATE_FOR_LENDER,
      LABEL_FOR_BASE_RATE_FOR_LENDER,
      ERROR_MESSAGES_FOR_ORIGINATIONFEE,
      ERROR_MESSAGES_FOR_ORIGINATIONFEE_EQUAL_OR_GREATER_TO_ZERO,
      ERROR_MESSAGES_FOR_BROKERLOADING,
      loanTermYearsList: [],
      prevValBrokerLoading: null,
      isIndividualMatch: false,
      LABEL_LOAN_BALLOON_DOLLAR,
      LABEL_FOR_BALLOON_PERCENT,
      formatNumber,
    }
  },
  watch: {
    'lenderData.income.originationFeeIncGST'(value) {
      this.matchSettings = JSON.parse(JSON.stringify(this.matchSettings))
      this.matchSettings.originationFee = Number(value)
      this.matchSettings = JSON.parse(JSON.stringify(this.matchSettings))
    },
    'lenderData.brokerLoading'(value) {
      this.matchSettings.brokerLoading = _.cloneDeep(Number(value))
    },
    lenderData(value) {
      this.loanTermYearsList = _.range(
        this.lenderData?.minLoanTermYears,
        this.lenderData?.maxLoanTermYears + 1
      )
      this.matchSettings = JSON.parse(JSON.stringify(this.matchSettings))
      this.matchSettings.loanTermYears = Number(
        JSON.parse(
          JSON.stringify(
            this.isGenerateLenderMatches
              ? this.globalSettings?.loanTermYears
              : this.matchSettings?.loanTermYears
          )
        )
      )
      this.matchSettings = JSON.parse(JSON.stringify(this.matchSettings))
    },
  },
  mounted() {
    setTimeout(() => {
      this.updateMatchSettings()
      this.matchSettings.originationFee = Number(
        JSON.parse(
          JSON.stringify(this.lenderData?.income?.originationFeeIncGST)
        )
      )
      this.showTabs = true
      if (this.lenderData?.individualSettings) {
        this.matchSettings = _.cloneDeep(this.lenderData?.individualSettings)
      }
    }, 500)
    this.$nuxt.$on('update-match-settings', (val) => {
      setTimeout(() => {
        this.updateMatchSettings()
        if (val) {
          this.matchSettings.originationFee = Number(
            JSON.parse(
              JSON.stringify(this.lenderData?.income?.originationFeeIncGST)
            )
          )
        }
        if (
          this.lenderData?.minLoanTermYears >
            this.globalSettings.loanTermYears &&
          !this.isIndividualMatch
        ) {
          this.matchSettings.loanTermYears = Number(
            JSON.parse(JSON.stringify(this.lenderData?.minLoanTermYears))
          )
        } else if (
          this.lenderData?.maxLoanTermYears <
            this.globalSettings.loanTermYears &&
          !this.isIndividualMatch
        ) {
          this.matchSettings.loanTermYears = Number(
            JSON.parse(JSON.stringify(this.lenderData?.maxLoanTermYears))
          )
        } else {
          this.matchSettings.loanTermYears = Number(
            JSON.parse(
              JSON.stringify(
                this.isGenerateLenderMatches
                  ? this.globalSettings?.loanTermYears
                  : this.matchSettings?.loanTermYears
              )
            )
          )
        }
        this.isIndividualMatch = false
      }, 500)
      this.$refs.matchSettings.clearValidate()
    })
    this.$nuxt.$on('close-loader', () => {
      this.matchSettingLoading = false
    })
    this.$nuxt.$on('close-match-settingloading', () => {
      this.matchSettingLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
    this.$nuxt.$off('update-match-settings')
  },
  props: {
    lenderData: {
      type: Object,
      default: () => ({}),
    },
    globalSettings: {
      type: Object,
      default: () => ({}),
    },
    repayments: {
      type: String,
      default: 'Monthly',
    },
    isConsumer: {
      type: Boolean,
      default: false,
    },
    maxOriginationFee: {
      type: Boolean,
      default: false,
    },
    isGenerateLenderMatches: { type: Boolean, default: false },
    maxBrokerLoading: {
      type: Number,
      default: 0,
    },
    facts: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    async adjustIndividualMatch(fromSlider = '') {
      this.isIndividualMatch = true
      if (
        fromSlider === 'fromSlider' &&
        this.prevValBrokerLoading === this.matchSettings.brokerLoading
      ) {
        return false
      } else {
        this.prevValBrokerLoading = this.matchSettings.brokerLoading
      }
      let isValid = false
      this.$refs.matchSettings.validate((result) => {
        return (isValid = result)
      })
      if (!isValid) {
        return
      }
      this.matchSettingLoading = true
      if (this.lenderData?.matchId) {
        this.matchSettings.adjustedMatchId = this.lenderData?.matchId
      } else {
        this.matchSettings.adjustedMatchId = this.lenderData?.adjustedMatchId
          ? this.lenderData?.adjustedMatchId
          : this.lenderData?._id
      }
      if (this.lenderData?.productID) {
        this.matchSettings.productID = this.lenderData?.productID
      }
      this.$nuxt.$emit('onIndividualMatchHandler', this.matchSettings)
    },
    updateMatchSettings() {
      this.matchSettings.brokerLoading = Number(this.lenderData?.brokerLoading)
      this.prevValBrokerLoading = this.matchSettings.brokerLoading
      this.matchSettings.originationFee = Number(
        JSON.parse(
          JSON.stringify(
            Math.min(
              Number(this.globalSettings?.originationFee),
              this.maxOriginationFee >
                this.lenderData?.income?.originationFeeIncGST
                ? this.lenderData?.income?.originationFeeIncGST
                : this.maxOriginationFee
            )
          )
        )
      )
      if (this.lenderData?.individualSettings?.loanTermYears) {
        this.matchSettings.loanTermYears = Number(
          JSON.parse(
            JSON.stringify(this.lenderData?.individualSettings?.loanTermYears)
          )
        )
      } else {
        this.matchSettings.loanTermYears = Number(
          JSON.parse(
            JSON.stringify(Math.min(this.globalSettings?.loanTermYears, 7))
          )
        )
      }

      this.loanTermYearsList = _.range(
        this.lenderData?.minLoanTermYears,
        this.lenderData?.maxLoanTermYears + 1
      )
      this.matchSettings = JSON.parse(JSON.stringify(this.matchSettings))

      if (
        this.lenderData?.minLoanTermYears > this.globalSettings.loanTermYears &&
        !this.isIndividualMatch
      ) {
        this.matchSettings.loanTermYears = Number(
          JSON.stringify(this.lenderData?.minLoanTermYears)
        )
      } else if (
        this.lenderData?.maxLoanTermYears < this.globalSettings.loanTermYears &&
        !this.isIndividualMatch
      ) {
        this.matchSettings.loanTermYears = Number(
          JSON.parse(JSON.stringify(this.lenderData?.maxLoanTermYears))
        )
      } else {
        this.matchSettings.loanTermYears = Number(
          JSON.parse(
            JSON.stringify(
              this.isGenerateLenderMatches
                ? this.globalSettings?.loanTermYears
                : this.matchSettings?.loanTermYears
            )
          )
        )
      }
      this.matchSettings = JSON.parse(JSON.stringify(this.matchSettings))
    },
    changeToFloat(val) {
      if (val) {
        this.matchSettings.brokerLoading = parseFloat(val)
      }
    },
    numberWithCommas(x) {
      return toCurrency(x)
    },
    getRepayments() {
      if (this.repayments === INCOME_EXPENSE_FREQUENCY_WEEKLY) {
        this.repaymentsType = LABEL_EXPENSE_FREQUENCY_WEEKLY
        return this.lenderData?.quote?.weeklyRepayment
      } else if (this.repayments === INCOME_EXPENSE_FREQUENCY_FORTNIGHTLY) {
        this.repaymentsType = LABEL_EXPENSE_FREQUENCY_FORTNIGHTLY
        return this.lenderData?.quote?.fortnightlyRepayment
      } else {
        this.repaymentsType = LABEL_EXPENSE_FREQUENCY_MONTHLY
        return this.lenderData?.quote?.monthlyRepayment
      }
    },
  },
}
