//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LABEL_YES, LABEL_NO } from '~/constants/applications'
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    formProp: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputValue: this.value,
      LABEL_YES,
      LABEL_NO,
    }
  },
  methods: {
    onChange(event) {
      this.$emit('onChange', event)
    },
    onBlur(event) {
      this.$emit('onBlur', event)
    },
    onInput(event) {
      this.$emit('onInput', event)
    },
  },
}
