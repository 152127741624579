//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  LABEL_LOAN_SUBMIT_APPLICATION,
  LABEL_GO_TO,
  LABEL_CANCEL,
  REASON_FOR_ENTERING_ZERO,
  LABEL_REASON_FOR_ENTERING_ZERO,
  LABEL_PLEASE_SPECIFY_THE_REASON,
  LABEL_YES_PROCEED,
  LABEL_OTHER,
  ERROR_MESSAGES_FOR_ZERO_VALUE_REASON,
  ERROR_MESSAGES_FOR_ZERO_VALUE_REASON_TYPE,
  MAINTAB_LOANDETAILS_DECISION_LABEL,
  MAINTAB_LOANDETAILS_SETTLEMENT_LABEL,
} from '@/constants/applications'

export default {
  name: 'LoanDetails',
  props: {
    dialogOpened: {
      type: Boolean,
      default: () => true,
    },
    title: {
      type: String,
      default: () => null,
    },
    subtitle: {
      type: String,
      default: () => null,
    },
    zeroFieldsData: {
      type: Array,
      default: () => [],
    },
    loadingDialog: {
      type: Boolean,
      default: () => false,
    },
    isCommercial: {
      type: Boolean,
      default: () => false,
    },
    submissionType: {
      type: String,
      default: () => null,
    },
    zeroValueReason: {
      type: String,
      default: () => null,
    },
    zeroValueReasonType: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      zeroValueReasonForm: {
        zeroValueReason: this.zeroValueReason,
        zeroValueReasonType: this.zeroValueReasonType,
      },
      rules: {
        zeroValueReason: [
          {
            required: true,
            message: ERROR_MESSAGES_FOR_ZERO_VALUE_REASON,
            trigger: ['blur', 'change'],
          },
        ],
        zeroValueReasonType: [
          {
            required: true,
            message: ERROR_MESSAGES_FOR_ZERO_VALUE_REASON_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
      },
      LABEL_LOAN_SUBMIT_APPLICATION,
      LABEL_GO_TO,
      LABEL_CANCEL,
      REASON_FOR_ENTERING_ZERO,
      LABEL_REASON_FOR_ENTERING_ZERO,
      getIsDialogLoading: false,
      LABEL_PLEASE_SPECIFY_THE_REASON,
      LABEL_YES_PROCEED,
      LABEL_OTHER,
      ERROR_MESSAGES_FOR_ZERO_VALUE_REASON,
      ERROR_MESSAGES_FOR_ZERO_VALUE_REASON_TYPE,
      MAINTAB_LOANDETAILS_DECISION_LABEL,
      MAINTAB_LOANDETAILS_SETTLEMENT_LABEL,
    }
  },

  methods: {
    closeDialog() {
      if (this.submissionType === MAINTAB_LOANDETAILS_DECISION_LABEL) {
        this.$nuxt.$emit('close-zero-confirmation-dialog-loan-decision')
      } else if (this.submissionType === MAINTAB_LOANDETAILS_SETTLEMENT_LABEL) {
        this.$nuxt.$emit('close-zero-confirmation-dialog-pre-settlement')
      } else {
        this.$nuxt.$emit('close-Zero-Confirmation-Dialog')
      }
    },
    async handleClick() {
      let isValid = false
      this.$refs?.zeroValueReasonForm?.validate((result) => {
        return (isValid = result)
      })
      if (isValid) {
        if (this.submissionType === MAINTAB_LOANDETAILS_DECISION_LABEL) {
          this.$nuxt.$emit('submit-loan-decision', this.zeroValueReasonForm)
        } else if (
          this.submissionType === MAINTAB_LOANDETAILS_SETTLEMENT_LABEL
        ) {
          this.$nuxt.$emit('submit-pre-settlement', this.zeroValueReasonForm)
        } else {
          this.$nuxt.$emit('alert-submit-event-click', this.zeroValueReasonForm)
        }
        this.closeDialog()
      }
    },
  },
}
