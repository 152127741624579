var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.secondaryApplicantData ? _c('el-form', {
    ref: "secondaryApplicantData",
    attrs: {
      "rules": _vm.rulesSecondary,
      "model": _vm.secondaryApplicantData
    }
  }, [_c('el-card', {
    staticClass: "application-form"
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_RELATIONSHIP_WITH_PRIMARY_APPLICANT))])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.EMPLOYER_STATUS,
      "prop": "relation"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.secondaryApplicantData.relation,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "relation", $$v);
      },
      expression: "secondaryApplicantData.relation"
    }
  }, _vm._l(_vm.APPLICANT_RELATIONSHIP_WITH_PRIMARY_APPLICANT_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c('el-card', {
    staticClass: "application-form"
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_BASIC_DETAILS))])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Prefix",
      "prop": "salutation"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.secondaryApplicantData.salutation,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "salutation", $$v);
      },
      expression: "secondaryApplicantData.salutation"
    }
  }, _vm._l(_vm.APPLICANT_PREFIXES_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "First Name",
      "prop": "firstName"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.secondaryApplicantData.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "firstName", $$v);
      },
      expression: "secondaryApplicantData.firstName"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Middle name",
      "prop": "middleName"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.secondaryApplicantData.middleName,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "middleName", $$v);
      },
      expression: "secondaryApplicantData.middleName"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Last Name",
      "prop": "lastName"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.secondaryApplicantData.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "lastName", $$v);
      },
      expression: "secondaryApplicantData.lastName"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Gender",
      "prop": "gender"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.secondaryApplicantData.gender,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "gender", $$v);
      },
      expression: "secondaryApplicantData.gender"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "Male",
      "value": "Male"
    }
  }), _vm._v(" "), _c('el-option', {
    attrs: {
      "label": "Female",
      "value": "Female"
    }
  }), _vm._v(" "), _c('el-option', {
    attrs: {
      "label": "Other",
      "value": "Other"
    }
  }), _vm._v(" "), _c('el-option', {
    attrs: {
      "label": "Unknown",
      "value": "Unknown"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Date of birth",
      "prop": "dateOfBirth"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "format": "dd/MM/yyyy",
      "picker-options": _vm.dateOfBirthSort,
      "placeholder": "DD/MM/YYYY"
    },
    model: {
      value: _vm.secondaryApplicantData.dateOfBirth,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "dateOfBirth", $$v);
      },
      expression: "secondaryApplicantData.dateOfBirth"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_vm.secondaryApplicantData.mailingAddress ? _c('el-form-item', {
    attrs: {
      "label": "State",
      "prop": "mailingAddress.state"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.secondaryApplicantData.mailingAddress.state,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData.mailingAddress, "state", $$v);
      },
      expression: "secondaryApplicantData.mailingAddress.state"
    }
  }, _vm._l(_vm.APPLICANT_STATE_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "clearfix card-header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v("Contact details")])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "email",
      "label": "Email"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "name@domain.com",
      "disabled": _vm.isEditMode
    },
    model: {
      value: _vm.secondaryApplicantData.email,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "email", $$v);
      },
      expression: "secondaryApplicantData.email"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Phone",
      "prop": "mobile"
    }
  }, [_c('el-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "text",
      "placeholder": "04XXXXXXXXX",
      "disabled": _vm.isEditMode
    },
    model: {
      value: _vm.secondaryApplicantData.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "mobile", $$v);
      },
      expression: "secondaryApplicantData.mobile"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Phone (Home)",
      "prop": "homePhone"
    }
  }, [_c('el-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "text",
      "placeholder": "XXXXXXXX",
      "disabled": _vm.isEditMode
    },
    model: {
      value: _vm.secondaryApplicantData.homePhone,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "homePhone", $$v);
      },
      expression: "secondaryApplicantData.homePhone"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "clearfix card-header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v("Residency")])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Status",
      "prop": "residencyStatus"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.secondaryApplicantData.residencyStatus,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "residencyStatus", $$v);
      },
      expression: "secondaryApplicantData.residencyStatus"
    }
  }, _vm._l(_vm.RESIDENCY_STATUS_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _vm.secondaryApplicantData.residencyStatus === _vm.RESIDENCY_STATUS_VISA_OTHER_VALUE ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_VISA_SUBCLASS,
      "prop": "visaOther"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.secondaryApplicantData.visaOther,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "visaOther", $$v);
      },
      expression: "secondaryApplicantData.visaOther"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    staticClass: "mb-0",
    attrs: {
      "label": "Situation",
      "prop": "livingSituation"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.secondaryApplicantData.livingSituation,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "livingSituation", $$v);
      },
      expression: "secondaryApplicantData.livingSituation"
    }
  }, _vm._l(_vm.LIVING_SITUATIONS_MAY_PAY_RENT_OR_BOARD, function (data) {
    return _c('el-option', {
      key: data.value,
      attrs: {
        "label": data.value,
        "value": data.value
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "clearfix card-header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v("Relationship")])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Status",
      "prop": "relation"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.secondaryApplicantData.relation,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "relation", $$v);
      },
      expression: "secondaryApplicantData.relation"
    }
  }, _vm._l(_vm.APPLICANT_RELATIONSHIP_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Dependants",
      "prop": "numberOfDependants"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "select",
      "value": _vm.secondaryApplicantData.numberOfDependants
    },
    on: {
      "change": _vm.handleDependantChange
    }
  }, _vm._l(11, function (n, i) {
    return _c('el-option', {
      key: i,
      attrs: {
        "label": i,
        "value": i
      }
    });
  }), 1)], 1), _vm._v(" "), _vm.secondaryApplicantData.numberOfDependants ? _vm._l(parseInt(_vm.secondaryApplicantData.numberOfDependants), function (data, i) {
    return _c('el-form-item', {
      key: i,
      attrs: {
        "label": 'Dependant ' + data + ' (Age)',
        "prop": 'dependant' + i
      }
    }, [_c('el-input', {
      model: {
        value: _vm.secondaryApplicantData.dependants[i].age,
        callback: function ($$v) {
          _vm.$set(_vm.secondaryApplicantData.dependants[i], "age", $$v);
        },
        expression: "secondaryApplicantData.dependants[i].age"
      }
    })], 1);
  }) : _vm._e()], 2)], 1), _vm._v(" "), _c('div', {
    staticClass: "clearfix card-header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v("Identification")])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "idType"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.secondaryApplicantData.idType,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "idType", $$v);
      },
      expression: "secondaryApplicantData.idType"
    }
  }, [_c('el-radio', {
    attrs: {
      "label": _vm.ID_TYPE_DRIVER_LICENCE
    }
  }, [_vm._v(_vm._s(_vm.LABEL_TYPE_DRIVERS_LICENCE))]), _vm._v(" "), _c('el-radio', {
    attrs: {
      "label": _vm.ID_TYPE_PASSPORT
    }
  }, [_vm._v(_vm._s(_vm.ID_TYPE_PASSPORT))])], 1)], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 24
    }
  }), _vm._v(" "), _vm.isIdTypeLicence ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Licence type",
      "prop": "driverLicenceType"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.secondaryApplicantData.driverLicenceType,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "driverLicenceType", $$v);
      },
      expression: "secondaryApplicantData.driverLicenceType"
    }
  }, _vm._l(_vm.APPLICANT_DRIVER_LICENCE_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isIdTypeLicence ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Licence state",
      "prop": "driverLicenceState"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.secondaryApplicantData.driverLicenceState,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "driverLicenceState", $$v);
      },
      expression: "secondaryApplicantData.driverLicenceState"
    }
  }, _vm._l(_vm.APPLICANT_STATE_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isIdTypeLicence ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Licence number",
      "prop": "driverLicence"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.secondaryApplicantData.driverLicence,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "driverLicence", $$v);
      },
      expression: "secondaryApplicantData.driverLicence"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isIdTypeLicence ? _c('el-col', {
    attrs: {
      "xs": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Licence date of expiry",
      "prop": "driverLicenceExpiry"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "format": "dd/MM/yyyy",
      "placeholder": "DD/MM/YYYY"
    },
    model: {
      value: _vm.secondaryApplicantData.driverLicenceExpiry,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "driverLicenceExpiry", $$v);
      },
      expression: "secondaryApplicantData.driverLicenceExpiry"
    }
  })], 1)], 1) : _vm._e()], 1), _vm._v(" "), !_vm.isIdTypeLicence ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Passport number",
      "prop": "passportNumber"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.secondaryApplicantData.passportNumber,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "passportNumber", $$v);
      },
      expression: "secondaryApplicantData.passportNumber"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Passport date of expiry",
      "prop": "passportDateOfExpiry"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "format": "dd/MM/yyyy",
      "placeholder": "DD/MM/YYYY"
    },
    model: {
      value: _vm.secondaryApplicantData.passportDateOfExpiry,
      callback: function ($$v) {
        _vm.$set(_vm.secondaryApplicantData, "passportDateOfExpiry", $$v);
      },
      expression: "secondaryApplicantData.passportDateOfExpiry"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c('tab-footer', {
    attrs: {
      "saveBtnLoading": _vm.saveBtnLoading,
      "saveAndNextBtnLoading": _vm.saveAndNextBtnLoading
    },
    on: {
      "submitForm": _vm.submitForm,
      "saveAndNext": _vm.submitForm
    }
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }