var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-button', {
    class: _vm.className,
    attrs: {
      "type": _vm.btnType,
      "plain": _vm.isPlain,
      "loading": _vm.loading,
      "round": _vm.isRound,
      "native-type": _vm.nativeType,
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.onClick
    }
  }, [_vm._v(_vm._s(_vm.label))]);
}
var staticRenderFns = []

export { render, staticRenderFns }