export const SIDEBAR_MENU_ITEM_FINANCE = 'Finance'
export const SIDEBAR_MENU_ITEM_APPLICATIONS = 'Applications'
export const SIDEBAR_MENU_CARBUYING = 'Car Buying'
export const SIDEBAR_MENU_USERS = 'Users'
export const SIDEBAR_MENU_SETTINGS = 'Settings'
export const SIDEBAR_MENU_WHITELISTED_IP_ADDRESSES = 'Whitelisted IP addresses'
export const SIDEBAR_MENU_NOTIFICATIONS = 'Notifications'
export const SIDEBAR_MENU_UTILITIES = 'Utilities'
export const SIDEBAR_MENU_ITEM_RRP = 'Vehicle RRP'
export const SIDEBAR_MENU_QUICK_QUOTE = 'Quick quote'
