var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "application-form"
  }, [_c('el-card', [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_ALL_EXPENSES))])]), _vm._v(" "), _vm.expensesForm ? [_c('el-form', {
    ref: "expensesForm",
    attrs: {
      "model": _vm.expensesForm
    }
  }, [_c('div', [_c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-row', [_vm._v(" ")])], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": _vm.defaultColLength
    }
  }, [_c('el-form-item', {
    staticClass: "font-extrabold requiredlabel-field"
  }, [_c('span', {
    staticClass: "text-xs"
  }, [_vm._v(_vm._s(_vm.LABEL_PRIMARY_APPLICANT))])])], 1), _vm._v(" "), _vm.doesApplicantExpenseExist(_vm.expensesForm, _vm.LABEL_SECONDARY_STRING, _vm.LABEL_MORE_THAN_ZERO) ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": _vm.defaultColLength
    }
  }, [_c('el-form-item', {
    staticClass: "font-extrabold requiredlabel-field"
  }, [_c('span', {
    staticClass: "text-xs"
  }, [_vm._v(_vm._s(_vm.LABEL_SECONDARY_APPLICANT))])])], 1) : _vm._e(), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": _vm.defaultColLength
    }
  }, [_c('el-form-item', {
    staticClass: "font-extrabold requiredlabel-field"
  }, [_c('span', {
    staticClass: "text-xs"
  }, [_vm._v(_vm._s(_vm.LABEL_FREQUENCY))])])], 1)], 1)], 1), _vm._v(" "), _vm.doesApplicantExpenseExist(_vm.expensesForm, _vm.LABEL_SECONDARY_STRING, _vm.LABEL_MORE_THAN_ZERO) ? _c('div', {
    staticClass: "text-xs"
  }, [_c('el-form-item', {
    staticClass: "flex items-center fontsize-small",
    attrs: {
      "label": _vm.LABEL_EXPENSE_DECLARATION,
      "lg": 6,
      "sm": 24,
      "prop": 'expensesForm.primaryApplicantData.expensesDisclaimer',
      "rules": _vm.rules.expensesDeclared
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    on: {
      "input": function ($event) {
        return _vm.updateAmount();
      }
    },
    model: {
      value: _vm.expensesForm.primaryApplicantData.expensesDisclaimer,
      callback: function ($$v) {
        _vm.$set(_vm.expensesForm.primaryApplicantData, "expensesDisclaimer", $$v);
      },
      expression: "expensesForm.primaryApplicantData.expensesDisclaimer"
    }
  }, [_c('el-option', {
    attrs: {
      "label": _vm.LABEL_SHARED,
      "value": "Shared"
    }
  }), _vm._v(" "), _c('el-option', {
    attrs: {
      "label": _vm.LABEL_INDIVIDUAL,
      "value": "Individual"
    }
  })], 1)], 1)], 1) : _vm._e()])] : _vm._e()], 2), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 mb-8 justify-end flex"
  }, [_c('div', {
    staticClass: "border-r border-light-grey mr-6 pr-6"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.back();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_EXPENSES_BACK_BUTTON))])], 1), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.submit(false);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_EXPENSES_SAVE_BUTTON))]), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(true);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_EXPENSES_SAVE_AND_NEXT_BUTTON))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }