//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  SALE_TYPES,
  SALE_TYPE_PRIVATE,
  SETTLEMENT_MENU_SELLER_DETAILS_LABEL,
  LABEL_NEXT,
  LABEL_CANCEL,
  LABEL_UPDATE,
  LABEL_FOR_FIRST_NAME,
  LABEL_FOR_LAST_NAME,
  LABEL_FOR_EMAIL,
  LABEL_FOR_PHONE_NUMBER,
  LABEL_FOR_DEALERSHIP_NAME,
  LABEL_FOR_DEALERSHIP_ABN,
  SETTLEMENT_MENU_DOCUMENT_KEY,
  SETTLEMENT_MENU_LOAN_DETAILS_KEY,
  DOCUMENT_EDIT_LINK,
} from '@/constants/applications'
import {
  USER_TYPE_ADMIN,
  USER_TYPE_SUPER_ADMIN,
  APPLICATION_STATUS_SETTLED,
  APPLICATION_STATUS_PRE_SETTLEMENT,
  REGEX_FOR_AUS_PHONE,
  LABEL_FOR_SALE_TYPE,
  ERROR_MESSAGE_FOR_FIRST_NAME,
  ERROR_MESSAGE_FOR_LAST_NAME,
  ERROR_MESSAGE_FOR_EMAIL,
  ERROR_MESSAGE_FOR_INVALID_EMAIL,
  ERROR_MESSAGE_FOR_MOBILE,
  ERROR_MESSAGE_FOR_INVALID_MOBILE,
  ERROR_MESSAGE_FOR_DEALERSHIP_NAME,
  ERROR_MESSAGE_FOR_VALID_DEALERSHIP_ABN,
  ERROR_MESSAGE_FOR_DEALERSHIP_ABN_LENGTH,
} from '@/constants'
import TabFooter from '~/components/FinanceApplication/Tabs/TabFooter'
import { axiosErrorMessageExtractor } from '~/utils/error'
import { validateUserRoles } from '@/helpers/user'
export default {
  components: {
    TabFooter,
  },
  props: {
    primaryData: {
      type: Object,
      default: () => ({}),
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    isApplicationSettled: {
      type: Boolean,
      default: false,
    },
    isFromSettlementTab: {
      type: Boolean,
      default: true,
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      USER_TYPE_ADMIN,
      USER_TYPE_SUPER_ADMIN,
      APPLICATION_STATUS_SETTLED,
      APPLICATION_STATUS_PRE_SETTLEMENT,
      SALE_TYPES,
      SALE_TYPE_PRIVATE,
      SETTLEMENT_MENU_SELLER_DETAILS_LABEL,
      SETTLEMENT_MENU_DOCUMENT_KEY,
      SETTLEMENT_MENU_LOAN_DETAILS_KEY,
      LABEL_NEXT,
      LABEL_CANCEL,
      LABEL_UPDATE,
      LABEL_FOR_SALE_TYPE,
      LABEL_FOR_FIRST_NAME,
      LABEL_FOR_LAST_NAME,
      LABEL_FOR_EMAIL,
      LABEL_FOR_PHONE_NUMBER,
      LABEL_FOR_DEALERSHIP_NAME,
      LABEL_FOR_DEALERSHIP_ABN,
      saveBtnLoading: false,
      saveAndNextBtnLoading: false,
      isDialogLoading: false,
      DOCUMENT_EDIT_LINK,
      sellerDetails: {
        saleType: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        dealershipName: '',
        dealershipABN: '',
      },
      shouldShowSellerDialog: false,
      rules: {
        firstName: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_FIRST_NAME,
            trigger: ['blur', 'change'],
          },
        ],
        lastName: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_LAST_NAME,
            trigger: ['blur', 'change'],
          },
        ],
        email: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_EMAIL,
            trigger: ['blur', 'change'],
          },
          {
            type: 'email',
            message: ERROR_MESSAGE_FOR_INVALID_EMAIL,
            trigger: ['blur', 'change'],
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MOBILE,
            trigger: ['blur', 'change'],
          },
          {
            pattern: REGEX_FOR_AUS_PHONE,
            message: ERROR_MESSAGE_FOR_INVALID_MOBILE,
            trigger: 'change',
          },
        ],
        dealershipName: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_DEALERSHIP_NAME,
            trigger: ['blur', 'change'],
          },
        ],
        dealershipABN: [
          {
            pattern: /^[0-9]+$/,
            message: ERROR_MESSAGE_FOR_VALID_DEALERSHIP_ABN,
            trigger: ['blur', 'change'],
          },
          {
            min: 11,
            max: 11,
            message: ERROR_MESSAGE_FOR_DEALERSHIP_ABN_LENGTH,
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  mounted() {
    this.setSellerDetails()
    this.$nuxt.$on('close-loader', () => {
      this.isDialogLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },

  computed: {
    getSalesType() {
      const type = SALE_TYPES.find(
        (x) => x.value === this.sellerDetails.saleType
      )
      return type?.label || '--'
    },
    shouldShowDealershipData() {
      return this.sellerDetails.saleType !== SALE_TYPE_PRIVATE
    },
    isSettled() {
      return this.sharedData.status === APPLICATION_STATUS_SETTLED
    },
    isPreSettlementStatus() {
      return this.sharedData?.status === APPLICATION_STATUS_PRE_SETTLEMENT
    },
    canEditSellerDetails() {
      return (
        validateUserRoles(this.$auth?.user?.systemRoleTypes, [
          USER_TYPE_ADMIN,
          USER_TYPE_SUPER_ADMIN,
        ]) && this.isSettled
      )
    },
  },
  methods: {
    setSellerDetails() {
      const sellerDetails =
        this.sharedData.loanDetails?.settlement?.sellerDetails
      this.sellerDetails.saleType =
        this.sharedData.vehicle?.purchaseSource || ''
      if (sellerDetails) {
        this.sellerDetails.firstName = sellerDetails.firstName || ''
        this.sellerDetails.lastName = sellerDetails.lastName || ''
        this.sellerDetails.email = sellerDetails.email || ''
        this.sellerDetails.phoneNumber = sellerDetails.phoneNumber || ''
        this.sellerDetails.dealershipName = sellerDetails.dealershipName || ''
        this.sellerDetails.dealershipABN = sellerDetails.dealershipABN || ''
      }
    },
    async goBack() {
      this.$emit('sectionRedirect', SETTLEMENT_MENU_DOCUMENT_KEY)
    },
    async gotoNext() {
      this.$emit('sectionRedirect', SETTLEMENT_MENU_LOAN_DETAILS_KEY)
    },
    async submitForm(event) {
      try {
        const { gotoNext: goNext = false } = event ?? {}
        let isValid = false
        this.$refs.sellerDetailSubmission.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          return
        }
        this.saveBtnLoading = !goNext
        this.saveAndNextBtnLoading = !!goNext
        await this.saveSellerDetails()
        if (goNext) {
          this.gotoNext()
        }
      } catch (err) {
        console.log('Error:-', err)
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.saveBtnLoading = false
        this.saveAndNextBtnLoading = false
      }
    },
    async saveSellerDetails() {
      const { id, applicationId } = this.$route.params
      await this.$store.dispatch('applications/updateSellerDetails', {
        sellerDetails: this.sellerDetails,
        id,
        applicationId,
      })
    },
    openCloseSellerDialog() {
      this.isDialogLoading = false
      this.shouldShowSellerDialog = !this.shouldShowSellerDialog
    },
    async updateSellerDetails() {
      try {
        let isValid = false
        this.$refs.sellerDetailDialog.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          return
        }
        this.isDialogLoading = true
        await this.saveSellerDetails()
        this.openCloseSellerDialog()
      } catch (err) {
        console.log('Error:-', err)
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      }
    },
  },
}
