var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-aside', {
    staticClass: "sidebar-outer",
    class: _vm.isCollapse ? ' ' : 'sidebar-open',
    attrs: {
      "width": _vm.isCollapse ? '0px' : '220px'
    }
  }, [_c('el-menu', {
    staticClass: "sideBar"
  }, [_c('div', {
    staticStyle: {
      "padding": "12px 0px 6px 20px"
    },
    attrs: {
      "data-selector": "referral-menu"
    },
    on: {
      "click": function ($event) {
        return _vm.$nuxt.$emit('set-collapse');
      }
    }
  }, [_c('img', {
    staticClass: "cursor-pointer",
    attrs: {
      "src": require("@/assets/icons/menu.svg")
    }
  })]), _vm._v(" "), _c('el-menu-item-group', [_c('el-menu-item', {
    attrs: {
      "index": "1"
    },
    on: {
      "click": function ($event) {
        return _vm.appNavigation('/referrals', true);
      }
    }
  }, [_c('NuxtLink', {
    class: _vm.getRoute === '/referrals' || _vm.getRoute === '/referrals/' ? 'nuxt-link-active' : '',
    style: (_vm.getRoute === '/referrals' || _vm.getRoute === '/referrals/') && _vm.getIsTableLoading ? 'pointer-events: none' : '',
    attrs: {
      "to": "/referrals",
      "exact": "",
      "data-selector": "referral-link"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_REFERRALS))])], 1), _vm._v(" "), _c('el-menu-item', {
    attrs: {
      "index": "2"
    },
    on: {
      "click": function ($event) {
        return _vm.appNavigation('/referrals/settlements', false);
      }
    }
  }, [_c('NuxtLink', {
    attrs: {
      "to": "/referrals/settlements",
      "data-selector": "settlements-link"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_SETTLEMENTS))])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }