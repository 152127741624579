var render = function () {
  var _vm$vehicleData, _vm$vehicleData$vehic, _vm$vehicleData2, _vm$vehicleData2$vehi, _vm$vehicleData3, _vm$vehicleData3$vehi, _vm$vehicleData4, _vm$vehicleData4$vehi, _vm$vehicleData5, _vm$vehicleData5$vehi, _vm$vehicleData6, _vm$vehicleData6$vehi, _vm$vehicleData7, _vm$vehicleData7$vehi, _vm$vehicleData8, _vm$vehicleData8$vehi, _vm$vehicleData9, _vm$vehicleData9$vehi, _vm$vehicleData10, _vm$vehicleData10$veh, _vm$vehicleData11, _vm$vehicleData11$veh, _vm$vehicleData12, _vm$vehicleData12$veh;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isDialogLoading,
      expression: "isDialogLoading"
    }],
    staticClass: "application-form asset-detail_form"
  }, [_vm.vehicleForm ? [_c('el-form', {
    ref: "vehicleForm",
    staticClass: "assetsDetails",
    attrs: {
      "model": _vm.vehicleData
    }
  }, [_c('el-card', {
    staticClass: "user-form-wrap"
  }, [_c('el-row', {
    staticClass: "flex items-center flex-wrap mb-2",
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    staticClass: "requiredlabel-field",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-sm asterisk"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_ASSET_DETAIL_TYPE))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [(_vm$vehicleData = _vm.vehicleData) !== null && _vm$vehicleData !== void 0 && (_vm$vehicleData$vehic = _vm$vehicleData.vehicle) !== null && _vm$vehicleData$vehic !== void 0 && _vm$vehicleData$vehic.assetType ? _c('el-form-item', {
    staticClass: "mb-0",
    attrs: {
      "prop": "vehicle.assetType",
      "rules": _vm.rules.assetType,
      "data-selector": "asset-type-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    on: {
      "change": function ($event) {
        return _vm.checkAssetType();
      }
    },
    model: {
      value: _vm.vehicleData.vehicle.assetType,
      callback: function ($$v) {
        _vm.$set(_vm.vehicleData.vehicle, "assetType", $$v);
      },
      expression: "vehicleData.vehicle.assetType"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "asset-type-options-container"
    }
  }, _vm._l(_vm.ASSET_TYPE_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1) : _vm._e()], 1)], 1), _vm._v(" "), ((_vm$vehicleData2 = _vm.vehicleData) === null || _vm$vehicleData2 === void 0 ? void 0 : (_vm$vehicleData2$vehi = _vm$vehicleData2.vehicle) === null || _vm$vehicleData2$vehi === void 0 ? void 0 : _vm$vehicleData2$vehi.assetType) === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE || ((_vm$vehicleData3 = _vm.vehicleData) === null || _vm$vehicleData3 === void 0 ? void 0 : (_vm$vehicleData3$vehi = _vm$vehicleData3.vehicle) === null || _vm$vehicleData3$vehi === void 0 ? void 0 : _vm$vehicleData3$vehi.assetType) === _vm.LABEL_ASSET_TYPE_OPTION_OTHER || ((_vm$vehicleData4 = _vm.vehicleData) === null || _vm$vehicleData4 === void 0 ? void 0 : (_vm$vehicleData4$vehi = _vm$vehicleData4.vehicle) === null || _vm$vehicleData4$vehi === void 0 ? void 0 : _vm$vehicleData4$vehi.assetType) === _vm.LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY ? _c('el-card', {
    staticClass: "p-0 child-px-0",
    attrs: {
      "shadow": "never",
      "body-style": {
        paddingLeft: '0px',
        paddingRight: '0px'
      },
      "data-selector": "asset-details-section"
    }
  }, [_c('div', {
    staticClass: "clearfix p-0",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "asset-details-header"
    }
  }, [_vm._v(_vm._s(_vm.TITLE_FOR_ASSET_DETAILS))])]), _vm._v(" "), ((_vm$vehicleData5 = _vm.vehicleData) === null || _vm$vehicleData5 === void 0 ? void 0 : (_vm$vehicleData5$vehi = _vm$vehicleData5.vehicle) === null || _vm$vehicleData5$vehi === void 0 ? void 0 : _vm$vehicleData5$vehi.assetType) === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE && ((_vm$vehicleData6 = _vm.vehicleData) === null || _vm$vehicleData6 === void 0 ? void 0 : (_vm$vehicleData6$vehi = _vm$vehicleData6.vehicle) === null || _vm$vehicleData6$vehi === void 0 ? void 0 : _vm$vehicleData6$vehi.assetType) !== _vm.LABEL_ASSET_TYPE_OPTION_OTHER && ((_vm$vehicleData7 = _vm.vehicleData) === null || _vm$vehicleData7 === void 0 ? void 0 : (_vm$vehicleData7$vehi = _vm$vehicleData7.vehicle) === null || _vm$vehicleData7$vehi === void 0 ? void 0 : _vm$vehicleData7$vehi.assetType) !== _vm.LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY ? _c('el-form-item', {
    staticClass: "radio-with_contnt"
  }, [_c('el-row', {
    attrs: {
      "gutter": 30
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('el-radio', {
    attrs: {
      "label": false,
      "data-selector": "select-vehicle-label"
    },
    model: {
      value: _vm.manuallySelected,
      callback: function ($$v) {
        _vm.manuallySelected = $$v;
      },
      expression: "manuallySelected"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.LABEL_FOR_SELECT_VEHICLE))]), _vm._v(" "), _c('p', [_vm._v(_vm._s(_vm.DESCRIPTION_FOR_SELECT_VEHICLE))])])], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('el-radio', {
    attrs: {
      "label": true,
      "data-selector": "enter-manually-label"
    },
    model: {
      value: _vm.manuallySelected,
      callback: function ($$v) {
        _vm.manuallySelected = $$v;
      },
      expression: "manuallySelected"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.LABEL_FOR_SELECT_ENTER_VEHICLE_MANUALLY))]), _vm._v(" "), _c('p', [_vm._v(_vm._s(_vm.DESCRIPTION_FOR_SELECT_VEHICLE_MANUALLY))])])], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 30
    }
  }, [((_vm$vehicleData8 = _vm.vehicleData) === null || _vm$vehicleData8 === void 0 ? void 0 : (_vm$vehicleData8$vehi = _vm$vehicleData8.vehicle) === null || _vm$vehicleData8$vehi === void 0 ? void 0 : _vm$vehicleData8$vehi.assetType) === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE && ((_vm$vehicleData9 = _vm.vehicleData) === null || _vm$vehicleData9 === void 0 ? void 0 : (_vm$vehicleData9$vehi = _vm$vehicleData9.vehicle) === null || _vm$vehicleData9$vehi === void 0 ? void 0 : _vm$vehicleData9$vehi.assetType) !== _vm.LABEL_ASSET_TYPE_OPTION_OTHER && ((_vm$vehicleData10 = _vm.vehicleData) === null || _vm$vehicleData10 === void 0 ? void 0 : (_vm$vehicleData10$veh = _vm$vehicleData10.vehicle) === null || _vm$vehicleData10$veh === void 0 ? void 0 : _vm$vehicleData10$veh.assetType) !== _vm.LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY ? _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_MAKE,
      "prop": "vehicle.makeCode",
      "rules": _vm.rules.makeCode,
      "data-selector": "make-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "Select",
      "disabled": _vm.manuallySelected
    },
    on: {
      "change": function ($event) {
        return _vm.setModels();
      },
      "focus": _vm.setMakes
    },
    model: {
      value: _vm.vehicleData.vehicle.makeCode,
      callback: function ($$v) {
        _vm.$set(_vm.vehicleData.vehicle, "makeCode", $$v);
      },
      expression: "vehicleData.vehicle.makeCode"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "make-options-container"
    }
  }, _vm._l(_vm.make, function (data) {
    return _c('el-option', {
      key: data.makeCode,
      attrs: {
        "label": data.description,
        "value": data.makeCode
      }
    });
  }), 1)])], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_MODEL,
      "prop": "vehicle.familyCode",
      "rules": _vm.rules.familyCode,
      "data-selector": "model-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "disabled": !_vm.vehicleData.vehicle.makeCode || _vm.manuallySelected,
      "placeholder": "Select"
    },
    on: {
      "change": function ($event) {
        return _vm.setYears();
      },
      "focus": function ($event) {
        return _vm.setModels(false);
      }
    },
    model: {
      value: _vm.vehicleData.vehicle.familyCode,
      callback: function ($$v) {
        _vm.$set(_vm.vehicleData.vehicle, "familyCode", $$v);
      },
      expression: "vehicleData.vehicle.familyCode"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "model-options-container"
    }
  }, _vm._l(_vm.model_options, function (data) {
    return _c('el-option', {
      key: data.familyCode,
      attrs: {
        "label": data.description,
        "value": data.familyCode
      }
    });
  }), 1)])], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_ASSET_DETAILS_YEAR,
      "prop": "vehicle.year",
      "rules": _vm.rules.year,
      "data-selector": "year-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "disabled": !_vm.vehicleData.vehicle.familyCode || _vm.manuallySelected,
      "placeholder": "Select"
    },
    on: {
      "change": function ($event) {
        return _vm.setModelTypes();
      },
      "focus": function ($event) {
        return _vm.setYears(false);
      }
    },
    model: {
      value: _vm.vehicleData.vehicle.year,
      callback: function ($$v) {
        _vm.$set(_vm.vehicleData.vehicle, "year", $$v);
      },
      expression: "vehicleData.vehicle.year"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "year-options-container"
    }
  }, _vm._l(_vm.years_options, function (data) {
    return _c('el-option', {
      key: data,
      attrs: {
        "label": data,
        "value": data
      }
    });
  }), 1)])], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_MODEL_TYPE,
      "prop": "vehicle.codeDescription",
      "rules": _vm.rules.codeDescription,
      "data-selector": "model-type-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "disabled": _vm.modelTypeDisabled,
      "placeholder": "Select"
    },
    on: {
      "focus": function ($event) {
        return _vm.setModelTypes(false);
      },
      "change": function ($event) {
        return _vm.addVehicleDescription($event);
      }
    },
    model: {
      value: _vm.vehicleData.vehicle.codeDescription,
      callback: function ($$v) {
        _vm.$set(_vm.vehicleData.vehicle, "codeDescription", $$v);
      },
      expression: "vehicleData.vehicle.codeDescription"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "model-type-options-container"
    }
  }, _vm._l(_vm.model_types_options, function (data) {
    return _c('el-option', {
      key: data.code,
      attrs: {
        "label": data.description,
        "value": data.description
      }
    });
  }), 1)])], 1), _vm._v(" "), _c('div', {
    staticClass: "car-area"
  }, [_c('div', {
    staticClass: "car-img"
  }, [_vm.imgUrl ? _c('img', {
    staticClass: "ml-auto",
    attrs: {
      "src": _vm.imgUrl
    }
  }) : _c('img', {
    staticClass: "ml-auto",
    attrs: {
      "src": require("@/assets/icons/vector.svg"),
      "data-selector": "car-image"
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "description"
  }, [_c('div', {
    staticClass: "description-text"
  }, [_c('span', {
    staticClass: "font-medium text-xs"
  }, [_vm._v(_vm._s(_vm.LABEL_RRP))]), _vm._v(" "), _c('p', {
    staticClass: "font-bolder text-success text-lg text-md",
    staticStyle: {
      "padding-top": "2px"
    },
    attrs: {
      "data-selector": "car-price"
    }
  }, [_vm._v(_vm._s(_vm.tradeInAvgCustomer ? _vm.numberWithCommas(_vm.tradeInAvgCustomer) : _vm.LABEL_UNKNOWN_PRICE))]), _vm._v(" "), _c('span', {
    staticClass: "text-extra-small",
    attrs: {
      "data-selector": "price-comment"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_RRP_DESCRIPTION))])])])])], 1) : _vm._e(), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_VEHICLE_DESCRIPTION,
      "prop": "vehicle.customerNote",
      "rules": _vm.rules.customerNote,
      "data-selector": "manual-desc-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 2,
      "placeholder": "Please Input",
      "disabled": _vm.vehicleTypeOther,
      "data-selector": "manual-desc-input"
    },
    model: {
      value: _vm.vehicleData.vehicle.customerNote,
      callback: function ($$v) {
        _vm.$set(_vm.vehicleData.vehicle, "customerNote", $$v);
      },
      expression: "vehicleData.vehicle.customerNote"
    }
  })], 1), _vm._v(" "), _vm.vehicleData.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE ? _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_ASSET_DETAILS_YEAR,
      "prop": "vehicle.year",
      "rules": _vm.rules.yearManual,
      "data-selector": "year-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "Select",
      "disabled": _vm.vehicleTypeOther
    },
    model: {
      value: _vm.vehicleData.vehicle.year,
      callback: function ($$v) {
        _vm.$set(_vm.vehicleData.vehicle, "year", $$v);
      },
      expression: "vehicleData.vehicle.year"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "year-options-container"
    }
  }, _vm._l(_vm.years, function (data) {
    return _c('el-option', {
      key: data,
      attrs: {
        "label": data,
        "value": data
      }
    });
  }), 1)])], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm._v(" "), (_vm$vehicleData11 = _vm.vehicleData) !== null && _vm$vehicleData11 !== void 0 && (_vm$vehicleData11$veh = _vm$vehicleData11.vehicle) !== null && _vm$vehicleData11$veh !== void 0 && _vm$vehicleData11$veh.assetType && ((_vm$vehicleData12 = _vm.vehicleData) === null || _vm$vehicleData12 === void 0 ? void 0 : (_vm$vehicleData12$veh = _vm$vehicleData12.vehicle) === null || _vm$vehicleData12$veh === void 0 ? void 0 : _vm$vehicleData12$veh.assetType) !== _vm.LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY ? _c('el-card', {
    staticClass: "p-0 child-px-0",
    attrs: {
      "body-style": {
        paddingLeft: '0px',
        paddingRight: '0px'
      },
      "shadow": "never",
      "data-selector": "other-details-section"
    }
  }, [_c('div', {
    staticClass: "clearfix p-0",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "other-details-header"
    }
  }, [_vm._v(_vm._s(_vm.TITLE_FOR_OTHER_DETAILS))])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 30
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_CONDITION
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select",
      "data-selector": "condition-input-container"
    },
    model: {
      value: _vm.condition,
      callback: function ($$v) {
        _vm.condition = $$v;
      },
      expression: "condition"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "condition-options-container"
    }
  }, _vm._l(_vm.VEHICLE_ASSET_DETAILS_CONDITION_OPTIONS, function (item) {
    return _c('el-option', {
      key: item.key,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_SALE_TYPE
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select",
      "data-selector": "sale-type-input-container"
    },
    model: {
      value: _vm.purchaseSource,
      callback: function ($$v) {
        _vm.purchaseSource = $$v;
      },
      expression: "purchaseSource"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "sale-type-options-container"
    }
  }, _vm._l(_vm.SALE_TYPE_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1), _vm._v(" "), _vm.vehicleData.vehicle.assetType !== _vm.LABEL_ASSET_TYPE_OPTION_OTHER ? _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_VIN,
      "data-selector": "vin-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Please input",
      "minlength": "2",
      "maxlength": "20",
      "data-selector": "vin-input"
    },
    model: {
      value: _vm.vehicleData.vehicle.vIN,
      callback: function ($$v) {
        _vm.$set(_vm.vehicleData.vehicle, "vIN", $$v);
      },
      expression: "vehicleData.vehicle.vIN"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.vehicleData.vehicle.assetType !== _vm.LABEL_ASSET_TYPE_OPTION_OTHER ? _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_REGO,
      "data-selector": "rego-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Please input",
      "minlength": "2",
      "maxlength": "10",
      "data-selector": "rego-input"
    },
    model: {
      value: _vm.vehicleData.vehicle.registrationPlate,
      callback: function ($$v) {
        _vm.$set(_vm.vehicleData.vehicle, "registrationPlate", $$v);
      },
      expression: "vehicleData.vehicle.registrationPlate"
    }
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 mb-8 justify-end flex"
  }, [_c('div', {
    staticClass: "border-r border-light-grey mr-6 pr-6"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "data-selector": "asset-details-back-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.backToPrevious();
      }
    }
  }, [_vm._v("Back")])], 1), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "loading": _vm.saveBtnLoading,
      "disabled": _vm.isApplicationSettled,
      "data-selector": "asset-details-save-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(false);
      }
    }
  }, [_vm._v("Save")]), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary",
      "loading": _vm.saveAndNextBtnLoading,
      "disabled": _vm.isApplicationSettled,
      "data-selector": "asset-details-save-next-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(true);
      }
    }
  }, [_vm._v("Save & Next")])], 1)], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }