//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TabFooter from '~/components/FinanceApplication/Tabs/TabFooter'
import {
  ERROR_MESSAGE_FOR_EMAIL,
  ERROR_MESSAGE_FOR_INVALID_EMAIL,
  ERROR_MESSAGE_FOR_MOBILE,
  ERROR_MESSAGE_FOR_INVALID_MOBILE,
  ERROR_MESSAGE_FOR_LAST_NAME,
  ERROR_MESSAGE_FOR_STATE,
  ERROR_MESSAGE_FOR_FIRST_NAME,
  REGEX_FOR_AUS_MOBILES,
  ERROR_MESSAGE_FOR_VISA_OTHER,
  REGEX_FOR_AUS_PHONE,
  ERROR_MESSAGE_FOR_STATUS,
  ERROR_MESSAGE_FOR_DRIVER_LICENCE_STATE,
  ERROR_MESSAGE_FOR_DRIVER_LICENCE_TYPE,
  ERROR_MESSAGE_FOR_ID_TYPE,
  ERROR_MESSAGE_FOR_DEPENDANTS,
  ERROR_MESSAGE_FOR_DATE_OF_BIRTH,
  ERROR_MESSAGE_FOR_LICENCE_DATE_OF_EXPIRY,
  ERROR_MESSAGE_FOR_GENDER,
  ERROR_MESSAGE_FOR_LIVING_SITUATION,
  ERROR_MESSAGE_FOR_DRIVER_LICENCE,
  ERROR_MESSAGE_FOR_PASSPORT_DATE_OF_EXPIRY,
  ERROR_MESSAGE_FOR_PASSPORT_NUMBER,
  ERROR_MESSAGE_FOR_18_YEARS_AGE,
  ERROR_MESSAGE_FOR_DATE_LESS_THAN_10_YEARS_CURR_YEAR,
} from '~/constants'
import {
  APPLICANT_PREFIXES_OPTIONS,
  APPLICANT_STATE_OPTIONS,
  RESIDENCY_STATUS_OPTIONS,
  APPLICANT_RELATIONSHIP_OPTIONS,
  APPLICANT_DRIVER_LICENCE_OPTIONS,
  RESIDENCY_STATUS_VISA_OTHER_VALUE,
  LIVING_SITUATIONS_MAY_PAY_RENT_OR_BOARD,
  DEPENDANTS_LIST,
  APPLICANT_RELATIONSHIP_STATUS_SINGLE,
  LABEL_FOR_BASIC_DETAILS,
  LABEL_FOR_RELATIONSHIP_WITH_PRIMARY_APPLICANT,
  EMPLOYER_STATUS,
  APPLICANT_RELATIONSHIP_WITH_PRIMARY_APPLICANT_OPTIONS,
  LABEL_VISA_SUBCLASS,
} from '~/constants/applications'
import {
  isdateOfBirthGTE18YearsAndLessThan110Years,
  isdateLessThan10Years,
} from '~/helpers/validators'
import {
  ID_TYPE_DRIVER_LICENCE,
  ID_TYPE_PASSPORT,
  LABEL_TYPE_DRIVERS_LICENCE,
} from '~/constants/user'
import { setResidencyStatus } from '~/helpers/application'
export default {
  components: {
    TabFooter,
  },
  props: {
    applicationDetails: {
      type: Object,
      default: null,
    },
    vehicleDetails: {
      type: Object,
      default: null,
    },
    userDetails: {
      type: Object,
      default: null,
    },
    secondaryApplicant: {
      type: Object,
      default: null,
    },
    accountDetails: {
      type: Array,
      default: null,
    },
    vehicleRequestGroup: {
      type: Object,
      default: null,
    },
    activeMenu: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      isEditMode: false,
      secondaryApplicantData: {
        ...this.secondaryApplicant,
        ...this.applicationDetails.otherApplicants[0],
      },
      LABEL_FOR_RELATIONSHIP_WITH_PRIMARY_APPLICANT,
      APPLICANT_RELATIONSHIP_WITH_PRIMARY_APPLICANT_OPTIONS,
      EMPLOYER_STATUS,
      ERROR_MESSAGE_FOR_EMAIL,
      ERROR_MESSAGE_FOR_INVALID_EMAIL,
      ERROR_MESSAGE_FOR_MOBILE,
      ERROR_MESSAGE_FOR_INVALID_MOBILE,
      ERROR_MESSAGE_FOR_LAST_NAME,
      ERROR_MESSAGE_FOR_STATE,
      ERROR_MESSAGE_FOR_FIRST_NAME,
      REGEX_FOR_AUS_MOBILES,
      ERROR_MESSAGE_FOR_VISA_OTHER,
      REGEX_FOR_AUS_PHONE,
      ERROR_MESSAGE_FOR_STATUS,
      ERROR_MESSAGE_FOR_DRIVER_LICENCE_STATE,
      ERROR_MESSAGE_FOR_DRIVER_LICENCE_TYPE,
      ERROR_MESSAGE_FOR_ID_TYPE,
      ERROR_MESSAGE_FOR_DEPENDANTS,
      ERROR_MESSAGE_FOR_DATE_OF_BIRTH,
      ERROR_MESSAGE_FOR_LICENCE_DATE_OF_EXPIRY,
      ERROR_MESSAGE_FOR_GENDER,
      ERROR_MESSAGE_FOR_LIVING_SITUATION,
      ERROR_MESSAGE_FOR_DRIVER_LICENCE,
      ERROR_MESSAGE_FOR_PASSPORT_DATE_OF_EXPIRY,
      ERROR_MESSAGE_FOR_PASSPORT_NUMBER,
      ERROR_MESSAGE_FOR_18_YEARS_AGE,
      ERROR_MESSAGE_FOR_DATE_LESS_THAN_10_YEARS_CURR_YEAR,
      LABEL_VISA_SUBCLASS,
      APPLICANT_PREFIXES_OPTIONS,
      APPLICANT_STATE_OPTIONS,
      RESIDENCY_STATUS_OPTIONS,
      APPLICANT_RELATIONSHIP_OPTIONS,
      APPLICANT_DRIVER_LICENCE_OPTIONS,
      RESIDENCY_STATUS_VISA_OTHER_VALUE,
      LIVING_SITUATIONS_MAY_PAY_RENT_OR_BOARD,
      DEPENDANTS_LIST,
      APPLICANT_RELATIONSHIP_STATUS_SINGLE,
      LABEL_FOR_BASIC_DETAILS,
      ID_TYPE_DRIVER_LICENCE,
      LABEL_TYPE_DRIVERS_LICENCE,
      ID_TYPE_PASSPORT,
      rulesSecondary: {
        residencyStatus: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_STATUS,
            trigger: ['blur', 'change'],
          },
        ],
        livingSituation: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_LIVING_SITUATION,
            trigger: ['blur', 'change'],
          },
        ],
        relation: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_STATUS,
            trigger: ['blur', 'change'],
          },
        ],
        driverLicenceState: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_DRIVER_LICENCE_STATE,
            trigger: ['blur', 'change'],
          },
        ],
        driverLicenceType: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_DRIVER_LICENCE_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
        dateOfBirth: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_DATE_OF_BIRTH,
            trigger: ['blur', 'change'],
          },
          {
            validator: (_rule, value, callback) => {
              if (
                !value ||
                (value && !isdateOfBirthGTE18YearsAndLessThan110Years(value))
              ) {
                callback(new Error(ERROR_MESSAGE_FOR_18_YEARS_AGE))
              } else {
                callback()
              }
            },
            message: ERROR_MESSAGE_FOR_18_YEARS_AGE,
            trigger: 'change',
          },
        ],
        driverLicenceExpiry: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_LICENCE_DATE_OF_EXPIRY,
            trigger: 'change',
          },
          {
            validator: (_rule, value, callback) => {
              if (!value || (value && !isdateLessThan10Years(value))) {
                callback(
                  new Error(ERROR_MESSAGE_FOR_DATE_LESS_THAN_10_YEARS_CURR_YEAR)
                )
              } else {
                callback()
              }
            },
            message: ERROR_MESSAGE_FOR_DATE_LESS_THAN_10_YEARS_CURR_YEAR,
            trigger: 'change',
          },
        ],
        idType: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_ID_TYPE,
            trigger: ['blur', 'change'],
          },
        ],
        numberOfDependants: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_DEPENDANTS,
            trigger: ['blur', 'change'],
          },
        ],
        visaOther: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_VISA_OTHER,
            trigger: ['blur', 'change'],
          },
        ],
        firstName: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_FIRST_NAME,
            trigger: ['blur', 'change'],
          },
        ],
        gender: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_GENDER,
            trigger: ['blur', 'change'],
          },
        ],
        'mailingAddress.state': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_STATE,
            trigger: ['blur', 'change'],
          },
        ],
        lastName: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_LAST_NAME,
            trigger: ['blur', 'change'],
          },
        ],
        mobile: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_MOBILE,
            trigger: 'change',
          },
          {
            pattern: REGEX_FOR_AUS_MOBILES,
            message: ERROR_MESSAGE_FOR_INVALID_MOBILE,
            trigger: 'change',
          },
        ],
        homePhone: [
          {
            message: ERROR_MESSAGE_FOR_MOBILE,
            trigger: 'change',
          },
          {
            pattern: REGEX_FOR_AUS_PHONE,
            message: ERROR_MESSAGE_FOR_INVALID_MOBILE,
            trigger: 'change',
          },
        ],
        driverLicence: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_DRIVER_LICENCE,
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_EMAIL,
            trigger: ['blur', 'change'],
          },
          {
            type: 'email',
            message: ERROR_MESSAGE_FOR_INVALID_EMAIL,
            trigger: ['blur', 'change'],
          },
        ],
        passportNumber: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_PASSPORT_NUMBER,
            trigger: ['blur', 'change'],
          },
        ],
        passportDateOfExpiry: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_PASSPORT_DATE_OF_EXPIRY,
            trigger: 'change',
          },
        ],
        relation: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_STATUS,
            trigger: ['blur', 'change'],
          },
        ],
      },
      dateOfBirthSort: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      saveBtnLoading: false,
      saveAndNextBtnLoading: false,
    }
  },
  computed: {
    isIdTypeLicence() {
      return this.secondaryApplicantData?.idType === ID_TYPE_DRIVER_LICENCE
    },
  },
  mounted() {
    setResidencyStatus(this.secondaryApplicantData)
  },
  methods: {
    submitForm(event) {
      console.log('event ', event)
    },
    handleDependantChange(event) {
      const data = this.secondaryApplicantData
      data.numberOfDependants = event

      //   this.$nextTick(() => {

      //   })
      this.$set(this.secondaryApplicantData, 'numberOfDependants', event)
      if (data.numberOfDependants > data.dependants.length) {
        const updateDependants = event - data.dependants.length
        for (let index = 0; index < updateDependants; index++) {
          data.dependants.push({ age: 0 })
        }
      } else if (data.numberOfDependants < data.dependants.length) {
        const updateDependants = data.dependants.length - event
        for (let index = 0; index < updateDependants; index++) {
          data.dependants.pop()
        }
      }
    },
  },
}
