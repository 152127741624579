
import accounting from 'accounting-js'
import VueNumeric from 'vue-numeric'
// TODO: should PR this to the original lib (allowing strip zeros)
export default {
  extends: VueNumeric,
  methods: {
    onFocusHandler(e) {
      this.$emit('focus', e)
      if (this.valueNumber === 0 || this.valueNumber === '0') {
        this.amount = null
      } else {
        this.amount = accounting.formatMoney(this.valueNumber, {
          symbol: '',
          format: '%v',
          thousand: '',
          decimal: this.decimalSeparatorSymbol,
          precision: this.precision ? Number(this.precision) : 2,
          // The only change
          stripZeros: true,
        })
      }
    },
    format(value) {
      if (Number(this.precision) > 0) {
        return accounting.formatMoney(value, {
          symbol: this.currency,
          format: this.symbolPosition,
          precision: Number(this.precision),
          decimal: this.decimalSeparatorSymbol,
          thousand: this.thousandSeparatorSymbol,
          stripZeros: true,
        })
      }
      return value ? value : ''
    },
  },
}
