//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MessageCode',
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    fields: {
      type: Number,
      default: 4,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { fields } = this
    this.inputRefs = []
    for (let i = 0; i < fields; i++) {
      this.inputRefs.push(`smscode_${i}`)
    }
    return {
      values: [],
    }
  },
  computed: {
    focusIndex() {
      let index = 0
      while (index < this.fields) {
        if (isNaN(this.values[index])) {
          return index
        }
        index++
      }
      return this.fields - 1
    },
  },
  watch: {
    value() {
      this.updateValue(this.value)
    },
  },
  mounted() {
    this.updateValue(this.value)
  },
  methods: {
    updateValue(value, triggerComplete = true) {
      if (value === null) {
        value = ''
      }
      const values = []
      // Stripped all non numeric characters
      const stripped = value.toString().replace(/\D/g, '')
      for (let count = 0; count < this.fields; count++) {
        values.push(stripped[count])
      }
      this.values = values
      this.onTriggerChange(triggerComplete)
    },
    onPaste(e) {
      this.updateValue(e.clipboardData.getData('text'), false)
      this.onFocus()
    },
    onFocus() {
      const next = this.inputRefs[this.focusIndex]
      const element = this.$refs[next][0]
      element.focus()
      element.select()
    },
    onValueChange(e) {
      const { fields } = this
      let { value } = e.target
      const nextIndex = this.focusIndex + 1
      const vals = [...this.values]
      value = value.toString().replace(/\D/g, '')
      if (value && value !== '') {
        if (value.length > 1) {
          value = value[value.length - 1]
        }
        if (nextIndex <= fields) {
          const next = this.inputRefs[nextIndex]
          vals[this.focusIndex] = value
          this.values = vals
          this.onTriggerChange(false)
          if (next) {
            this.$refs[next][0].focus()
            this.$refs[next][0].select()
          }
        } else {
          vals.pop()
          vals.push(value)
          this.values = vals
          this.onTriggerChange()
        }
      } else {
        this.values = vals
        this.onTriggerChange()
      }
    },
    onKeyDown(e) {
      const BACKSPACE = 8
      const index = this.focusIndex
      const prevIndex = index - 1
      const vals = [...this.values]
      switch (e.keyCode) {
        case BACKSPACE: {
          e.preventDefault()
          if (vals[index]) {
            vals[index] = undefined
          } else {
            vals[prevIndex] = undefined
          }
          this.values = vals
          this.onTriggerChange(false)
          this.onFocus()
          break
        }
        default:
      }
    },
    onTriggerChange(triggerComplete = true) {
      const value = this.values.join('')
      this.$emit('input', value)
      if (value.length === this.fields && triggerComplete) {
        this.$emit('onComplete', value)
      }
    },
  },
}
