var render = function () {
  var _vm$selectedlenderDet, _vm$selectedlenderDet2, _vm$selectedlenderDet3, _vm$selectedlenderDet4, _vm$selectedlenderDet5, _vm$selectedlenderDet6, _vm$selectedlenderDet7, _vm$applicationMatche, _vm$applicationMatche2, _vm$applicationMatche3, _vm$applicationMatche4, _vm$applicationMatche5, _vm$sharedData, _vm$sharedData$busine, _vm$sharedData2, _vm$sharedData2$busin, _vm$sharedData3, _vm$sharedData3$busin, _vm$sharedData4, _vm$sharedData4$busin, _vm$sharedData5, _vm$sharedData5$busin, _vm$sharedData6, _vm$sharedData6$busin, _vm$sharedData7, _vm$sharedData7$busin, _vm$sharedData8, _vm$sharedData8$busin, _vm$sharedData9, _vm$sharedData9$busin, _vm$sharedData10, _vm$sharedData10$busi, _vm$sharedData11, _vm$sharedData11$busi, _vm$sharedData12, _vm$sharedData12$busi, _vm$sharedData13, _vm$sharedData13$busi, _vm$sharedData14, _vm$sharedData14$othe, _vm$sharedData15, _vm$sharedData16, _vm$sharedData17, _vm$generatedLenderMa, _vm$generatedLenderMa2, _vm$generatedLenderMa3, _vm$generatedLenderMa4, _vm$generatedLenderMa5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "matchesForm"
  }, [_c('el-card', [_c('span', {
    staticClass: "font-bolder text-extra-small cls-legend-select-by-customer"
  }, [_vm._v("\n      " + _vm._s(_vm.LABEL_SELECTED_BY_CUSTOMER))]), _vm._v(" "), _c('el-card', {
    staticClass: "cls-matches-lender-details-border"
  }, [_vm.selectedlenderDetailsData ? _c('div', {
    staticClass: "flex-wrap justify-between",
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    },
    attrs: {
      "type": "flex"
    }
  }, [_c('div', {
    staticClass: "flex items-end",
    staticStyle: {
      "gap": "40px"
    }
  }, [_c('div', [_c('img', {
    staticStyle: {
      "max-height": "50px"
    },
    attrs: {
      "src": (_vm$selectedlenderDet = _vm.selectedlenderDetailsData) === null || _vm$selectedlenderDet === void 0 ? void 0 : _vm$selectedlenderDet.lenderLogoUrl
    }
  }), _vm._v(" "), _c('p', {
    staticClass: "text-xs text-grey-dark pl-2"
  }, [_vm._v("\n              at\n              " + _vm._s(_vm.format(new Date((_vm$selectedlenderDet2 = _vm.selectedlenderDetailsData) === null || _vm$selectedlenderDet2 === void 0 ? void 0 : _vm$selectedlenderDet2.createdAt), 'dd/MM/yyyy hh:mm aaa')))])]), _vm._v(" "), _c('div', {
    staticClass: "mt-2",
    staticStyle: {
      "padding-right": "40px",
      "border-right": "1px solid #e8e8e8"
    }
  }, [_c('h2', {
    staticClass: "text-xs text-black font-bolder mx-3"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CONFIRMABLE_CREDIT_REPAYMENT_AMOUNT))]), _vm._v(" "), _c('div', {
    staticClass: "mt-2 flex"
  }, [_c('div', {
    staticClass: "w-auto mx-3"
  }, [_c('h2', {
    staticClass: "font-bolder text-success text-md"
  }, [_vm._v(_vm._s(_vm.numberWithCommas((_vm$selectedlenderDet3 = _vm.selectedlenderDetailsData) === null || _vm$selectedlenderDet3 === void 0 ? void 0 : _vm$selectedlenderDet3.monthlyRepayment)))]), _c('p', {
    staticClass: "text-xs mt-2 timeLineColor"
  }, [_vm._v(_vm._s(_vm.LABEL_EXPENSE_FREQUENCY_MONTHLY))])]), _vm._v(" "), _c('div', {
    staticClass: "w-auto mx-3"
  }, [_c('h2', {
    staticClass: "font-bolder text-success text-md"
  }, [_vm._v(_vm._s(_vm.numberWithCommas((_vm$selectedlenderDet4 = _vm.selectedlenderDetailsData) === null || _vm$selectedlenderDet4 === void 0 ? void 0 : _vm$selectedlenderDet4.fortnightlyRepayment)))]), _c('p', {
    staticClass: "text-xs mt-2 timeLineColor"
  }, [_vm._v(_vm._s(_vm.LABEL_EXPENSE_FREQUENCY_FORTNIGHTLY))])]), _vm._v(" "), _c('div', {
    staticClass: "w-auto mx-3"
  }, [_c('h2', {
    staticClass: "font-bolder text-success text-md"
  }, [_vm._v(_vm._s(_vm.numberWithCommas((_vm$selectedlenderDet5 = _vm.selectedlenderDetailsData) === null || _vm$selectedlenderDet5 === void 0 ? void 0 : _vm$selectedlenderDet5.weeklyRepayment)))]), _c('p', {
    staticClass: "text-xs mt-2 timeLineColor"
  }, [_vm._v(_vm._s(_vm.LABEL_EXPENSE_FREQUENCY_WEEKLY))])])])]), _vm._v(" "), _c('div', [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "mt-2 w-auto mr-8"
  }, [_c('h2', {
    staticClass: "text-xs text-capitalize fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CUSTOMER_RATE_FOR_LENDER))]), _vm._v(" "), _c('h2', {
    staticClass: "text-md text-black mt-2"
  }, [_vm._v(_vm._s(parseFloat(((_vm$selectedlenderDet6 = _vm.selectedlenderDetailsData) === null || _vm$selectedlenderDet6 === void 0 ? void 0 : _vm$selectedlenderDet6.rate) * 100).toFixed(2)) + "%")]), _vm._v(" "), _c('p', {
    staticClass: "text-xs text-grey-dark mt-2"
  }, [_vm._v(_vm._s(_vm.LABEL_FIXED_RATE))])]), _vm._v(" "), _c('div', {
    staticClass: "w-auto mr-3"
  }, [_c('h2', {
    staticClass: "text-xs text-capitalize fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_COMPARISON_RATE_FOR_LENDER))]), _vm._v(" "), _c('h2', {
    staticClass: "text-md text-black mt-2",
    staticStyle: {
      "margin-bottom": "15px"
    }
  }, [_vm._v(_vm._s(parseFloat(((_vm$selectedlenderDet7 = _vm.selectedlenderDetailsData) === null || _vm$selectedlenderDet7 === void 0 ? void 0 : _vm$selectedlenderDet7.comparisonRate) * 100).toFixed(2)) + "%")])])])])]), _vm._v(" "), _c('div', [_c('div', [_c('el-link', {
    staticClass: "font-bolder",
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "type": "primary",
      "underline": false
    },
    on: {
      "click": _vm.openSelectedByCustomerModal
    }
  }, [_vm._v(_vm._s(_vm.LABEL_VIEW_DETAILS))])], 1)])]) : _c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light notMatchesFound",
    attrs: {
      "role": "alert"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/alert-triangle.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold text-xs"
  }, [_vm._v("\n            " + _vm._s(_vm.LABEL_FOR_NO_PRELIMINARY_LENDER_MATCHES_ARE_FOUND_FOR_THE_CUSTOMER))])])])])], 1), _vm._v(" "), _c('el-card', {
    staticClass: "mt-4",
    attrs: {
      "shadow": "always"
    }
  }, [_c('el-card', {
    staticClass: "quote-details cls-matches-lender-details-border mb-4"
  }, [_c('el-collapse', {
    attrs: {
      "accordion": "",
      "id": "quote-details-collapse"
    },
    on: {
      "change": function ($event) {
        return _vm.quoteDetailsMenuCollapse($event);
      }
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c('el-collapse-item', {
    attrs: {
      "name": "1"
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('span', {
    staticClass: "text-xs font-extrabold pl-06"
  }, [_vm._v(_vm._s(_vm.LABEL_QUOTE_DETAILS))]), _vm._v(" "), _c('span', {
    staticClass: "text-xs font-extrabold text-primary",
    staticStyle: {
      "margin-left": "auto",
      "margin-right": "7px"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.collapseMenuStatus))])]), _vm._v(" "), _vm.applicationMatchesForm ? _c('div', {
    staticStyle: {
      "padding": "16px"
    }
  }, [_vm.applicationMatchesForm ? _c('el-form', {
    ref: "applicationMatchesForm",
    staticClass: "demo-ruleForm text-xs",
    attrs: {
      "model": _vm.applicationMatchesForm,
      "rules": _vm.rules
    }
  }, [_c('el-row', {
    staticClass: "mb-child-xs-4 flex overflow-auto",
    attrs: {
      "gutter": 16
    }
  }, [_c('el-col', {
    staticClass: "cls-loan-details-matches px-4",
    attrs: {
      "xs": 24,
      "sm": 12,
      "lg": _vm.isLoanTypePersonal ? 12 : 8
    }
  }, [_c('p', {
    staticClass: "font-bolder text-sm"
  }, [_vm._v(_vm._s(_vm.LABEL_LOAN_DETAILS))]), _vm._v(" "), _c('el-form-item', {
    staticClass: "mt-4",
    attrs: {
      "prop": "loanAmount"
    }
  }, [_c('span', {
    staticClass: "el-form-item__label mb-0",
    staticStyle: {
      "color": "#bdbdbd",
      "padding": "0px"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_PLACEHOLDER_LOAN_AMOUNT_REQUIREMENT) + "\n                      "), _c('p', {
    staticClass: "el-form-item__label"
  }, [_vm._v("\n                        " + _vm._s(_vm.LABEL_DOCUMENT_LOAN_AMOUNT))])]), _vm._v(" "), _c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "disabled": "",
      "acceptOnlyNumbers": true
    },
    model: {
      value: _vm.loanAmountTotal,
      callback: function ($$v) {
        _vm.loanAmountTotal = $$v;
      },
      expression: "loanAmountTotal"
    }
  })], 1), _vm._v(" "), _c('hr', {
    staticClass: "my-6",
    staticStyle: {
      "border-top": "0.1px"
    }
  }), _vm._v(" "), _c('el-form-item', {
    staticClass: "mb-6",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE,
      "prop": "purchasePrice"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "acceptOnlyNumbers": true
    },
    on: {
      "input": function ($event) {
        return _vm.calculateBalloonAmt();
      }
    },
    model: {
      value: _vm.applicationMatchesForm.purchasePrice,
      callback: function ($$v) {
        _vm.$set(_vm.applicationMatchesForm, "purchasePrice", $$v);
      },
      expression: "applicationMatchesForm.purchasePrice"
    }
  })], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 16
    }
  }, [((_vm$applicationMatche = _vm.applicationMatchesForm) === null || _vm$applicationMatche === void 0 ? void 0 : (_vm$applicationMatche2 = _vm$applicationMatche.vehicle) === null || _vm$applicationMatche2 === void 0 ? void 0 : _vm$applicationMatche2.financeDepositAmount) >= 0 ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    staticClass: "mb-6",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_DEPOSIT,
      "rules": _vm.rules.financeDepositAmount,
      "prop": "vehicle.financeDepositAmount"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "acceptOnlyNumbers": true
    },
    on: {
      "input": function ($event) {
        return _vm.calculateBalloonAmt();
      }
    },
    model: {
      value: _vm.applicationMatchesForm.vehicle.financeDepositAmount,
      callback: function ($$v) {
        _vm.$set(_vm.applicationMatchesForm.vehicle, "financeDepositAmount", $$v);
      },
      expression: "\n                            applicationMatchesForm.vehicle\n                              .financeDepositAmount\n                          "
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    staticClass: "mb-6",
    attrs: {
      "prop": "tradeInAmount",
      "label": _vm.LABEL_TRADE_IN_EQUITY
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    nativeOn: {
      "keypress": function ($event) {
        return _vm.onKeyPressForTradeInAmount.apply(null, arguments);
      }
    },
    model: {
      value: _vm.tradeInAmount,
      callback: function ($$v) {
        _vm.tradeInAmount = $$v;
      },
      expression: "tradeInAmount"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 16
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    staticClass: "mb-6",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_BALLOON,
      "prop": "balloonPct"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "text",
      "autocomplete": "off",
      "acceptOnlyNumbers": true
    },
    on: {
      "input": function ($event) {
        return _vm.calculateBalloonAmt();
      }
    },
    model: {
      value: _vm.applicationMatchesForm.balloonPct,
      callback: function ($$v) {
        _vm.$set(_vm.applicationMatchesForm, "balloonPct", $$v);
      },
      expression: "applicationMatchesForm.balloonPct"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    staticClass: "mb-6",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_BALLOON_ONLY,
      "prop": "ballonAmount"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "acceptOnlyNumbers": true
    },
    on: {
      "input": function ($event) {
        return _vm.calculateBalloonPerc();
      }
    },
    model: {
      value: _vm.applicationMatchesForm.balloonAmount,
      callback: function ($$v) {
        _vm.$set(_vm.applicationMatchesForm, "balloonAmount", $$v);
      },
      expression: "applicationMatchesForm.balloonAmount"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_LOAN_TREM,
      "rules": _vm.rules.loanTermYears,
      "props": "loanTermYears"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.applicationMatchesForm.loanTermYears,
      callback: function ($$v) {
        _vm.$set(_vm.applicationMatchesForm, "loanTermYears", $$v);
      },
      expression: "applicationMatchesForm.loanTermYears"
    }
  }, _vm._l(7, function (data) {
    return _c('el-option', {
      key: data,
      attrs: {
        "label": data + (data === 1 ? ' year' : ' years'),
        "value": data
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), !_vm.isLoanTypePersonal ? _c('el-col', {
    staticClass: "cls-asset-details-matches px-4",
    attrs: {
      "xs": 24,
      "sm": 12,
      "lg": 8
    }
  }, [_c('p', {
    staticClass: "font-bolder text-sm"
  }, [_vm._v(_vm._s(_vm.LABEL_ASSET_DETAILS))]), _vm._v(" "), (_vm$applicationMatche3 = _vm.applicationMatchesForm) !== null && _vm$applicationMatche3 !== void 0 && _vm$applicationMatche3.vehicle && (_vm$applicationMatche4 = _vm.applicationMatchesForm) !== null && _vm$applicationMatche4 !== void 0 && (_vm$applicationMatche5 = _vm$applicationMatche4.vehicle) !== null && _vm$applicationMatche5 !== void 0 && _vm$applicationMatche5.assetType ? _c('el-form-item', {
    class: _vm.isAssetTypeVehicle ? 'border-b border-light-grey pb-6 my-4' : 'my-4',
    attrs: {
      "label": _vm.LABEL_DOCUMENT_TYPE,
      "prop": "vehicle.assetType",
      "rules": _vm.rules.assetType
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    on: {
      "change": function ($event) {
        return _vm.checkAssetType(_vm.applicationMatchesForm.vehicle);
      }
    },
    model: {
      value: _vm.applicationMatchesForm.vehicle.assetType,
      callback: function ($$v) {
        _vm.$set(_vm.applicationMatchesForm.vehicle, "assetType", $$v);
      },
      expression: "applicationMatchesForm.vehicle.assetType"
    }
  }, _vm._l(_vm.ASSET_TYPE_OPTIONS_FOR_QUOTE_DETAILS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.isAssetTypeVehicle && !_vm.isAssetTypeOther && !_vm.isAssetTypeUnknown ? _c('el-form-item', {
    staticClass: "radio-with_contnt asset-radio mb-1"
  }, [_c('el-radio', {
    attrs: {
      "label": false
    },
    on: {
      "change": _vm.assetsManuallySelected
    },
    model: {
      value: _vm.manuallySelected,
      callback: function ($$v) {
        _vm.manuallySelected = $$v;
      },
      expression: "manuallySelected"
    }
  }, [_vm._v("\n                      " + _vm._s(_vm.LABEL_FOR_SELECT_VEHICLE) + "\n                    ")]), _vm._v(" "), _c('el-radio', {
    attrs: {
      "label": true
    },
    on: {
      "change": _vm.assetsManuallySelected
    },
    model: {
      value: _vm.manuallySelected,
      callback: function ($$v) {
        _vm.manuallySelected = $$v;
      },
      expression: "manuallySelected"
    }
  }, [_vm._v("\n                      " + _vm._s(_vm.LABEL_FOR_ENTER_DETAILS_MANUALLY) + "\n                    ")])], 1) : _vm._e(), _vm._v(" "), _vm.manuallySelected && _vm.isAssetTypeVehicle || _vm.isAssetTypeOther ? _c('el-form-item', {
    class: _vm.isAssetTypeUnknown ? 'mb-6' : 'mb-4',
    staticStyle: {
      "margin-top": "14px"
    },
    attrs: {
      "label": _vm.LABEL_FOR_VEHICLE_DESCRIPTION,
      "prop": "vehicle.customerNote",
      "rules": _vm.rules.customerNote
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 2,
      "placeholder": _vm.PLACEHOLDER_FOR_ASSET_DESCRIPTION
    },
    model: {
      value: _vm.applicationMatchesForm.vehicle.customerNote,
      callback: function ($$v) {
        _vm.$set(_vm.applicationMatchesForm.vehicle, "customerNote", $$v);
      },
      expression: "applicationMatchesForm.vehicle.customerNote"
    }
  })], 1) : _vm._e(), _vm._v(" "), !_vm.manuallySelected || _vm.isAssetTypeUnknown ? _c('div', [_c('el-row', {
    attrs: {
      "gutter": 16
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_vm.isAssetTypeVehicle ? _c('el-form-item', {
    staticClass: "mb-6",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_MAKE,
      "prop": "vehicle.makeCode",
      "rules": _vm.rules.makeCode
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    on: {
      "change": function ($event) {
        return _vm.setModels();
      }
    },
    model: {
      value: _vm.applicationMatchesForm.vehicle.makeCode,
      callback: function ($$v) {
        _vm.$set(_vm.applicationMatchesForm.vehicle, "makeCode", $$v);
      },
      expression: "applicationMatchesForm.vehicle.makeCode"
    }
  }, _vm._l(_vm.MAKE, function (data) {
    return _c('el-option', {
      key: data.makeCode,
      attrs: {
        "label": data.description,
        "value": data.makeCode
      },
      on: {
        "change": function ($event) {
          return _vm.setModels();
        }
      }
    });
  }), 1)], 1) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_vm.isAssetTypeVehicle ? _c('el-form-item', {
    staticClass: "mb-6",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_MODEL,
      "prop": "vehicle.familyCode",
      "rules": _vm.rules.familyCode
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "disabled": _vm.modelDisabled,
      "placeholder": "select"
    },
    on: {
      "change": function ($event) {
        return _vm.setYears();
      },
      "focus": function ($event) {
        return _vm.setModels(false);
      }
    },
    model: {
      value: _vm.applicationMatchesForm.vehicle.familyCode,
      callback: function ($$v) {
        _vm.$set(_vm.applicationMatchesForm.vehicle, "familyCode", $$v);
      },
      expression: "\n                              applicationMatchesForm.vehicle.familyCode\n                            "
    }
  }, _vm._l(_vm.MODEL_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.familyCode,
      attrs: {
        "label": data.description,
        "value": data.familyCode
      }
    });
  }), 1)], 1) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_vm.isAssetTypeVehicle && (!_vm.manuallySelected || _vm.isAssetTypeUnknown) ? _c('el-form-item', {
    staticClass: "mb-6",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_YEAR,
      "prop": "vehicle.year",
      "rules": _vm.rules.year
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "disabled": _vm.yearDisabled,
      "placeholder": "select"
    },
    on: {
      "change": function ($event) {
        return _vm.setModelTypes();
      },
      "focus": function ($event) {
        return _vm.setYears(false);
      }
    },
    model: {
      value: _vm.applicationMatchesForm.vehicle.year,
      callback: function ($$v) {
        _vm.$set(_vm.applicationMatchesForm.vehicle, "year", $$v);
      },
      expression: "applicationMatchesForm.vehicle.year"
    }
  }, _vm._l(_vm.YEARS_OPTIONS, function (data) {
    return _c('el-option', {
      key: data,
      attrs: {
        "label": data,
        "value": data
      }
    });
  }), 1)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _vm.isAssetTypeVehicle && (!_vm.manuallySelected || _vm.isAssetTypeUnknown) ? _c('el-form-item', {
    staticClass: "mb-6",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_MODEL_TYPE,
      "prop": "vehicle.codeDescription",
      "rules": _vm.rules.codeDescription
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "disabled": _vm.modelTypeDisabled,
      "placeholder": "select"
    },
    on: {
      "change": function ($event) {
        return _vm.setModelTypes(false);
      }
    },
    model: {
      value: _vm.applicationMatchesForm.vehicle.codeDescription,
      callback: function ($$v) {
        _vm.$set(_vm.applicationMatchesForm.vehicle, "codeDescription", $$v);
      },
      expression: "\n                          applicationMatchesForm.vehicle.codeDescription\n                        "
    }
  }, _vm._l(_vm.MODEL_TYPES_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.code,
      attrs: {
        "label": data.description,
        "value": data.code
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.isAssetTypeUnknown ? _c('el-form-item', {
    staticClass: "mb-6",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_DETAILS
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 2
    },
    model: {
      value: _vm.applicationMatchesForm.vehicle.assetDetail,
      callback: function ($$v) {
        _vm.$set(_vm.applicationMatchesForm.vehicle, "assetDetail", $$v);
      },
      expression: "applicationMatchesForm.vehicle.assetDetail"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c('div', {
    class: {
      'border-light-grey': _vm.isAssetTypeVehicleOrOther
    }
  }, [_vm.isAssetTypeVehicleOrOther ? _c('el-row', {
    attrs: {
      "gutter": 16
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_vm.isAssetTypeVehicle && _vm.manuallySelected || _vm.isAssetTypeOther ? _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_YEAR,
      "prop": "vehicle.year",
      "rules": _vm.rules.year
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.applicationMatchesForm.vehicle.year,
      callback: function ($$v) {
        _vm.$set(_vm.applicationMatchesForm.vehicle, "year", $$v);
      },
      expression: "applicationMatchesForm.vehicle.year"
    }
  }, _vm._l(_vm.years, function (year) {
    return _c('el-option', {
      key: year,
      attrs: {
        "label": year,
        "value": year
      }
    });
  }), 1)], 1) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    staticClass: "mb-6",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_CONDITION,
      "prop": "vehicle.condition",
      "rules": _vm.rules.condition
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    on: {
      "change": _vm.changeCondition
    },
    model: {
      value: _vm.applicationMatchesForm.vehicle.condition,
      callback: function ($$v) {
        _vm.$set(_vm.applicationMatchesForm.vehicle, "condition", $$v);
      },
      expression: "applicationMatchesForm.vehicle.condition"
    }
  }, _vm._l(_vm.CONDITION_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c('el-col', {
    class: _vm.isAssetTypeVehicle && _vm.manuallySelected || _vm.isAssetTypeOther ? 'clear-both' : '',
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    staticClass: "mb-6",
    attrs: {
      "label": _vm.LABEL_DOCUMENT_SALE_TYPE,
      "prop": "vehicle.purchaseSource",
      "rules": _vm.rules.purchaseSource
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.applicationMatchesForm.vehicle.purchaseSource,
      callback: function ($$v) {
        _vm.$set(_vm.applicationMatchesForm.vehicle, "purchaseSource", $$v);
      },
      expression: "\n                              applicationMatchesForm.vehicle.purchaseSource\n                            "
    }
  }, _vm._l(_vm.SALE_TYPE_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    staticClass: "mb-6",
    attrs: {
      "label": _vm.LABEL_VEHICLE_VALUATION,
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_AMOUNT_REQUIRED,
        trigger: ['blur', 'change']
      }]
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "acceptOnlyNumbers": true
    },
    model: {
      value: _vm.vehicleValuation,
      callback: function ($$v) {
        _vm.vehicleValuation = $$v;
      },
      expression: "vehicleValuation"
    }
  })], 1)], 1)], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm._v(" "), _c('el-col', {
    staticClass: "px-4",
    attrs: {
      "xs": 24,
      "sm": 12,
      "lg": _vm.isLoanTypePersonal ? 12 : 8
    }
  }, [!_vm.isConsumer ? _c('div', [_c('p', {
    staticClass: "font-bolder text-sm"
  }, [_vm._v(_vm._s(_vm.LABEL_BUSINESS_DETAILS))]), _vm._v(" "), _c('p', {
    staticClass: "text-sm summary-tag summary-tag-bsn-name",
    staticStyle: {
      "white-space": "initial"
    }
  }, [_vm._v("\n                      " + _vm._s(((_vm$sharedData = _vm.sharedData) === null || _vm$sharedData === void 0 ? void 0 : (_vm$sharedData$busine = _vm$sharedData.businessData) === null || _vm$sharedData$busine === void 0 ? void 0 : _vm$sharedData$busine.businessName) || '--'))]), _vm._v(" "), _c('el-row', {
    staticClass: "mt-5 center-icon"
  }, [_c('el-col', {
    staticClass: "flex",
    attrs: {
      "sm": 24,
      "lg": 4
    }
  }, [_c('div', {
    staticClass: "quote-details-icons"
  }, [_c('img', {
    staticStyle: {
      "height": "18px",
      "margin": "6px"
    },
    attrs: {
      "src": require("@/assets/icons/office-building.svg")
    }
  })])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 20
    }
  }, [_c('span', {
    staticClass: "font-bolder"
  }, [_vm._v(_vm._s(_vm.LABEL_STRUCTURE))]), _vm._v(" "), _c('p', {}, [_vm._v("\n                          " + _vm._s(((_vm$sharedData2 = _vm.sharedData) === null || _vm$sharedData2 === void 0 ? void 0 : (_vm$sharedData2$busin = _vm$sharedData2.businessData) === null || _vm$sharedData2$busin === void 0 ? void 0 : _vm$sharedData2$busin.businessStructure) || '--'))])]), _vm._v(" "), _c('el-col', {
    staticClass: "flex",
    attrs: {
      "sm": 24,
      "lg": 4
    }
  }, [_c('div', {
    staticClass: "quote-details-icons"
  }, [_c('img', {
    staticStyle: {
      "height": "18px",
      "margin": "6px"
    },
    attrs: {
      "src": require("@/assets/icons/business-center.svg")
    }
  })])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 20
    }
  }, [_c('span', {
    staticClass: "font-bolder"
  }, [_vm._v(_vm._s(_vm.LABEL_ABN))]), _vm._v(" "), _c('p', {}, [_vm._v("\n                          " + _vm._s((_vm$sharedData3 = _vm.sharedData) !== null && _vm$sharedData3 !== void 0 && (_vm$sharedData3$busin = _vm$sharedData3.businessData) !== null && _vm$sharedData3$busin !== void 0 && _vm$sharedData3$busin.aBN ? (((_vm$sharedData4 = _vm.sharedData) === null || _vm$sharedData4 === void 0 ? void 0 : (_vm$sharedData4$busin = _vm$sharedData4.businessData) === null || _vm$sharedData4$busin === void 0 ? void 0 : _vm$sharedData4$busin.aBN.substring(0, 2)) + " " + ((_vm$sharedData5 = _vm.sharedData) === null || _vm$sharedData5 === void 0 ? void 0 : (_vm$sharedData5$busin = _vm$sharedData5.businessData) === null || _vm$sharedData5$busin === void 0 ? void 0 : _vm$sharedData5$busin.aBN.substring(2, 5)) + " " + ((_vm$sharedData6 = _vm.sharedData) === null || _vm$sharedData6 === void 0 ? void 0 : (_vm$sharedData6$busin = _vm$sharedData6.businessData) === null || _vm$sharedData6$busin === void 0 ? void 0 : _vm$sharedData6$busin.aBN.substring(5, 8)) + " " + ((_vm$sharedData7 = _vm.sharedData) === null || _vm$sharedData7 === void 0 ? void 0 : (_vm$sharedData7$busin = _vm$sharedData7.businessData) === null || _vm$sharedData7$busin === void 0 ? void 0 : _vm$sharedData7$busin.aBN.substring(8)) + " ") : '--') + "\n                        ")])])], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 center-icon"
  }, [_c('el-col', {
    staticClass: "flex",
    attrs: {
      "sm": 24,
      "lg": 4
    }
  }, [_c('div', {
    staticClass: "quote-details-icons"
  }, [_c('img', {
    staticStyle: {
      "height": "18px",
      "margin": "6px"
    },
    attrs: {
      "src": require("@/assets/icons/access_time.svg")
    }
  })])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 20
    }
  }, [_c('span', {
    staticClass: "font-bolder"
  }, [_vm._v(_vm._s(_vm.LABEL_ABN_LENGTH))]), _vm._v(" "), _c('p', {}, [_vm._v("\n                          " + _vm._s(_vm.totalMonths((_vm$sharedData8 = _vm.sharedData) === null || _vm$sharedData8 === void 0 ? void 0 : (_vm$sharedData8$busin = _vm$sharedData8.businessData) === null || _vm$sharedData8$busin === void 0 ? void 0 : _vm$sharedData8$busin.aBNEffectiveFrom) || '0') + "\n                          " + _vm._s(_vm.totalMonths((_vm$sharedData9 = _vm.sharedData) === null || _vm$sharedData9 === void 0 ? void 0 : (_vm$sharedData9$busin = _vm$sharedData9.businessData) === null || _vm$sharedData9$busin === void 0 ? void 0 : _vm$sharedData9$busin.aBNEffectiveFrom) > 1 && _vm.totalMonths((_vm$sharedData10 = _vm.sharedData) === null || _vm$sharedData10 === void 0 ? void 0 : (_vm$sharedData10$busi = _vm$sharedData10.businessData) === null || _vm$sharedData10$busi === void 0 ? void 0 : _vm$sharedData10$busi.aBNEffectiveFrom) ? _vm.LABEL_FOR_MONTHS : _vm.LABEL_FOR_MONTH) + "\n                        ")])]), _vm._v(" "), _c('el-col', {
    staticClass: "flex",
    attrs: {
      "sm": 24,
      "lg": 4
    }
  }, [_c('div', {
    staticClass: "quote-details-icons"
  }, [_c('img', {
    staticStyle: {
      "height": "18px",
      "margin": "6px"
    },
    attrs: {
      "src": require("@/assets/icons/GST-registration.svg")
    }
  })])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 20
    }
  }, [_c('span', {
    staticClass: "font-bolder"
  }, [_vm._v(_vm._s(_vm.LABEL_GST_REGISTRATION))]), _vm._v(" "), _c('p', {}, [_vm._v("\n                          " + _vm._s(_vm.totalMonths((_vm$sharedData11 = _vm.sharedData) === null || _vm$sharedData11 === void 0 ? void 0 : (_vm$sharedData11$busi = _vm$sharedData11.businessData) === null || _vm$sharedData11$busi === void 0 ? void 0 : _vm$sharedData11$busi.gSTEffectiveFrom) || '0') + "\n                          " + _vm._s(_vm.totalMonths((_vm$sharedData12 = _vm.sharedData) === null || _vm$sharedData12 === void 0 ? void 0 : (_vm$sharedData12$busi = _vm$sharedData12.businessData) === null || _vm$sharedData12$busi === void 0 ? void 0 : _vm$sharedData12$busi.gSTEffectiveFrom) > 1 && _vm.totalMonths((_vm$sharedData13 = _vm.sharedData) === null || _vm$sharedData13 === void 0 ? void 0 : (_vm$sharedData13$busi = _vm$sharedData13.businessData) === null || _vm$sharedData13$busi === void 0 ? void 0 : _vm$sharedData13$busi.gSTEffectiveFrom) ? _vm.LABEL_FOR_MONTHS : _vm.LABEL_FOR_MONTH))])])], 1), _vm._v(" "), _c('hr', {
    staticClass: "my-4",
    staticStyle: {
      "border": "1px solid #e8e8e8"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('p', {
    staticClass: "font-bolder text-sm"
  }, [_vm._v(_vm._s(_vm.isConsumer ? _vm.LABEL_FOR_APPLICANT_PROFILE : 'Contacts profile'))]), _vm._v(" "), _c('div', {
    staticClass: "contactsProfile"
  }, [_c('el-tabs', {
    class: ((_vm$sharedData14 = _vm.sharedData) === null || _vm$sharedData14 === void 0 ? void 0 : (_vm$sharedData14$othe = _vm$sharedData14.otherApplicants) === null || _vm$sharedData14$othe === void 0 ? void 0 : _vm$sharedData14$othe.length) === 0 ? 'contacts-profile' : 'mt-5',
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.activeName1,
      callback: function ($$v) {
        _vm.activeName1 = $$v;
      },
      expression: "activeName1"
    }
  }, [_c('el-tab-pane', {
    attrs: {
      "label": (_vm$sharedData15 = _vm.sharedData) !== null && _vm$sharedData15 !== void 0 && _vm$sharedData15.otherApplicants.length ? 'Primary' : '',
      "name": "first",
      "id": "contact-profile-tab-arrow"
    }
  }, [_c('ApplicantProfile', {
    attrs: {
      "applicant-data": (_vm$sharedData16 = _vm.sharedData) === null || _vm$sharedData16 === void 0 ? void 0 : _vm$sharedData16.primaryApplicantData,
      "isprimary": true,
      "is-consumer": _vm.isConsumer
    }
  })], 1), _vm._v(" "), _vm._l((_vm$sharedData17 = _vm.sharedData) === null || _vm$sharedData17 === void 0 ? void 0 : _vm$sharedData17.otherApplicants, function (data, index) {
    var _vm$sharedData18;

    return _c('el-tab-pane', {
      key: index,
      attrs: {
        "name": 'Secondary ' + (index + 1),
        "label": ((_vm$sharedData18 = _vm.sharedData) === null || _vm$sharedData18 === void 0 ? void 0 : _vm$sharedData18.otherApplicants.length) > 1 ? 'Secondary ' + (index + 1) : 'Secondary'
      }
    }, [_c('ApplicantProfile', {
      attrs: {
        "applicant-data": data,
        "isprimary": false,
        "secondary-index": index,
        "is-consumer": _vm.isConsumer
      }
    })], 1);
  })], 2)], 1)])], 1)], 1) : _vm._e()], 1) : _vm._e()], 2)], 1), _vm._v(" "), _vm.activeName ? _c('el-row', {
    staticClass: "border-t",
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "lg": 24
    }
  }, [_c('el-button', {
    staticClass: "w-auto",
    staticStyle: {
      "float": "right",
      "margin": "16px"
    },
    attrs: {
      "type": "primary",
      "loading": !_vm.btnLoading
    },
    on: {
      "click": _vm.generateLenderMatches
    }
  }, [_vm._v(_vm._s(_vm.LABEL_GENERATE_MATCHES))])], 1)], 1) : _vm._e()], 1), _vm._v(" "), _c('el-card', {
    staticClass: "application-form lender-matches notes-tab lender-match-wb bg-none",
    staticStyle: {
      "margin": "0px !important"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    staticStyle: {
      "padding-left": "8px"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_MATCHES))])]), _vm._v(" "), (_vm$generatedLenderMa = _vm.generatedLenderMatches) !== null && _vm$generatedLenderMa !== void 0 && _vm$generatedLenderMa.length ? _c('div', {
    staticClass: "lender-wrap"
  }, [_c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light",
    staticStyle: {
      "background": "rgba(0, 184, 73, 0.1)",
      "border-radius": "4px"
    },
    attrs: {
      "role": "alert"
    }
  }, [_c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content",
    staticStyle: {
      "padding-left": "0px",
      "padding-top": "8px",
      "padding-bottom": "8px"
    }
  }, [_c('span', {
    staticClass: "el-alert__title is-bold"
  }, [_vm._v(_vm._s((_vm$generatedLenderMa2 = _vm.generatedLenderMatches) === null || _vm$generatedLenderMa2 === void 0 ? void 0 : _vm$generatedLenderMa2.length) + "\n              " + _vm._s(_vm.LABEL_FOR_LENDER_MATCHES_FOUND))]), _vm._v(" "), (_vm$generatedLenderMa3 = _vm.generatedLenderMatches) !== null && _vm$generatedLenderMa3 !== void 0 && _vm$generatedLenderMa3.length && (_vm$generatedLenderMa4 = _vm.generatedLenderMatches[0]) !== null && _vm$generatedLenderMa4 !== void 0 && _vm$generatedLenderMa4.updatedAt ? _c('p', {
    staticClass: "el-alert__description",
    staticStyle: {
      "color": "#7a7a7a"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_LAST_UPDATED_AT) + "\n              " + _vm._s(_vm.format(new Date((_vm$generatedLenderMa5 = _vm.generatedLenderMatches[0]) === null || _vm$generatedLenderMa5 === void 0 ? void 0 : _vm$generatedLenderMa5.updatedAt), 'dd/MM/yyyy hh:mm aaa')) + "\n            ")]) : _vm._e()])]), _vm._v(" "), _c('hr', {
    staticClass: "my-6",
    staticStyle: {
      "border-top": "0.1px"
    }
  }), _vm._v(" "), _c('el-row', {
    staticClass: "mb-child-xs-4 flex flex-wrap dynamic-pricing-filters",
    attrs: {
      "gutter": 16
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "sm": 12,
      "lg": 16
    }
  }, [_c('el-form', {
    ref: "globalSettings",
    staticClass: "flex",
    attrs: {
      "model": _vm.globalSettings,
      "rules": _vm.globalSettingsRules
    }
  }, [_c('div', {
    staticClass: "mr-6",
    staticStyle: {
      "max-width": "137px"
    }
  }, [_c('el-form-item', {
    staticClass: "text-xs",
    attrs: {
      "label": _vm.LABEL_LOAN_TREM,
      "prop": "loanTermYears"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.globalSettings.loanTermYears,
      callback: function ($$v) {
        _vm.$set(_vm.globalSettings, "loanTermYears", $$v);
      },
      expression: "globalSettings.loanTermYears"
    }
  }, _vm._l(_vm.loanTermYearsList, function (data) {
    return _c('el-option', {
      key: data,
      attrs: {
        "label": data + (data === 1 ? ' year' : ' years'),
        "value": data
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "mr-6",
    staticStyle: {
      "max-width": "179px"
    }
  }, [_c('el-form-item', {
    staticClass: "mb-6 text-xs",
    attrs: {
      "prop": "originationFee"
    }
  }, [_c('label', {
    staticClass: "el-form-item__label",
    attrs: {
      "for": "originationFee"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_ORIGINATION_FEE_CAPITAL) + "\n                    "), _c('b', [_vm._v("(Max $" + _vm._s(_vm.maxOriginationFee) + ")")])]), _vm._v(" "), _c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "rows": 2,
      "acceptOnlyNumbers": true
    },
    model: {
      value: _vm.globalSettings.originationFee,
      callback: function ($$v) {
        _vm.$set(_vm.globalSettings, "originationFee", $$v);
      },
      expression: "globalSettings.originationFee"
    }
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "el-checkbox-group mr-6",
    staticStyle: {
      "min-width": "319px"
    }
  }, [_c('span', {
    staticClass: "el-form-item__label",
    staticStyle: {
      "text-align": "start"
    },
    attrs: {
      "for": "brokerLoading"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_BROKER_LOADING) + " %\n                  "), _c('b', [_vm._v("(Max " + _vm._s(_vm.maxBrokerLoading.toFixed(2)) + "%)")])]), _c('el-form-item', {
    staticClass: "el-checkbox-group text-xs",
    attrs: {
      "prop": "brokerLoading"
    }
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('el-input', {
    staticClass: "mixed-input",
    staticStyle: {
      "max-width": "147px"
    },
    attrs: {
      "placeholder": "Please input",
      "type": "number"
    },
    on: {
      "input": _vm.changeval
    },
    model: {
      value: _vm.globalSettings.brokerLoading,
      callback: function ($$v) {
        _vm.$set(_vm.globalSettings, "brokerLoading", $$v);
      },
      expression: "globalSettings.brokerLoading"
    }
  }), _vm._v(" "), _c('el-slider', {
    staticClass: "mixed-input-slider border border-color border-color-grey p-2",
    staticStyle: {
      "width": "100%",
      "padding-inline": "24px",
      "background-color": "rgba(113, 0, 218, 0.03)",
      "border-left": "0px",
      "border-radius": "0px 4px 4px 0px"
    },
    style: _vm.brokerLoadingisValid ? 'border-color : #dbdfe6' : 'border-color : #F56C6C',
    attrs: {
      "show-tooltip": false,
      "max": _vm.maxBrokerLoading,
      "min": 0,
      "step": "0.01"
    },
    model: {
      value: _vm.globalSettings.brokerLoading,
      callback: function ($$v) {
        _vm.$set(_vm.globalSettings, "brokerLoading", $$v);
      },
      expression: "globalSettings.brokerLoading"
    }
  })], 1)])], 1), _vm._v(" "), _c('div', {
    staticClass: "mt-6"
  }, [_c('el-button', {
    staticClass: "mt-1",
    attrs: {
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.submitGlobalSettings();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_APPLY))])], 1)])], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "sm": 12,
      "lg": 8
    }
  }, [_c('el-form', {
    ref: "formStatus",
    staticClass: "demo-form-inline flex items-end float-right pl-6 border-l",
    attrs: {
      "inline": true,
      "model": _vm.formStatus
    }
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('div', [_c('el-row', [_c('span', {
    staticClass: "text-xs text-black"
  }, [_vm._v(_vm._s(_vm.LABEL_SORTED_BY))])]), _vm._v(" "), _c('el-row', [_c('el-form-item', {
    staticClass: "mt-1",
    staticStyle: {
      "margin-right": "1.5rem"
    },
    attrs: {
      "prop": "sortedBy"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": ""
    },
    on: {
      "change": _vm.sortLenderMatches
    },
    model: {
      value: _vm.sortedBy,
      callback: function ($$v) {
        _vm.sortedBy = $$v;
      },
      expression: "sortedBy"
    }
  }, _vm._l(_vm.LENDER_MATCHES_SORTED_BY, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c('div', [_c('el-row', [_c('span', {
    staticClass: "text-xs text-black"
  }, [_vm._v(_vm._s(_vm.LABEL_SHOW_REPAYMENT) + "\n                    ")])]), _vm._v(" "), _c('el-row', [_c('el-form-item', {
    staticClass: "mt-1",
    attrs: {
      "prop": "repayments",
      "rules": [{
        required: true,
        message: _vm.ERROR_MESSAGE_FOR_REPAYMENTS,
        trigger: 'blur'
      }]
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "default-first-option": ""
    },
    on: {
      "change": _vm.updateMatchesSettings
    },
    model: {
      value: _vm.repayments,
      callback: function ($$v) {
        _vm.repayments = $$v;
      },
      expression: "repayments"
    }
  }, _vm._l(_vm.LENDER_MATCHES_SHOW_REPAYMENT, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1)], 1)], 1)])])], 1)], 1), _vm._v(" "), _c('el-row', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.globalSettingsLoading,
      expression: "globalSettingsLoading"
    }],
    attrs: {
      "gutter": 24
    }
  }, [_c('div', {
    staticClass: "mb-child-xs-4 flex overflow-auto"
  }, _vm._l(_vm.generatedLenderMatches, function (lenderData) {
    var _lenderData$commissio, _lenderData$commissio2;

    return _c('LenderMatch', {
      key: lenderData === null || lenderData === void 0 ? void 0 : lenderData._id,
      attrs: {
        "lender-data": lenderData,
        "repayments": _vm.repayments,
        "is-consumer": _vm.isConsumer,
        "global-settings": _vm.globalSettings,
        "max-origination-fee": _vm.maxOriginationFee,
        "facts": _vm.factsResponse,
        "is-generate-lender-matches": _vm.isGenerateLenderMatches,
        "max-broker-loading": _vm.isConsumer ? lenderData === null || lenderData === void 0 ? void 0 : (_lenderData$commissio = lenderData.commission) === null || _lenderData$commissio === void 0 ? void 0 : _lenderData$commissio.dialDown : lenderData === null || lenderData === void 0 ? void 0 : (_lenderData$commissio2 = lenderData.commission) === null || _lenderData$commissio2 === void 0 ? void 0 : _lenderData$commissio2.maxBrokerIncomePctNAFPct
      },
      on: {
        "onIndividualMatchHandler": _vm.onIndividualMatchHandler
      }
    });
  }), 1)])], 1) : _c('div', [_c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light notMatchesFound p-4",
    attrs: {
      "role": "alert"
    }
  }, [_c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/alert-triangle.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold"
  }, [_vm._v("\n              " + _vm._s(_vm.LABEL_FOR_NO_MATCHES_FOUND))])])])])])], 1), _vm._v(" "), _c('lenderDetailsDialog', {
    attrs: {
      "show-dialog": _vm.isLenderDetailsDialogOpen,
      "lender-details-data": _vm.selectedlenderDetailsData,
      "loan-details": _vm.sharedData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }