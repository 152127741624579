export const getBalloonAmount = (
  balloonPct = 0,
  purchasePrice = 0,
  tradeInAmount = 0,
  depositAmount = 0
) => {
  return (
    (balloonPct / 100) *
    (purchasePrice - tradeInAmount - depositAmount)
  ).toFixed()
}

export const getBalloonAmountWithApi = async (amount = 0, balloonPct = 0) => {
  const responseData = await fetch(
    `${process.env.VUE_APP_TORQ_MATCHES_API_URL}/helpers/calc/balloon?amount=${amount}&balloonPct=${balloonPct}`,
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
      },
    }
  ).then((response) => {
    if (response.status !== 204) {
      return response.json()
    }
    return
  })

  return responseData.balloonAmount >= 0 ? responseData.balloonAmount : 0
}
