//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  LABEL_REQUESTED_AT,
  LABEL_UPLOADED_AT,
  LABEL_BANK_STATEMENT_SECURE,
  LABEL_DOCUMENT_DROP,
  LABEL_DOCUMENT_UPLOAD,
  LABEL_DOCUMENT_PRIMARY,
  LABEL_DOCUMENT_SECONDARY,
  LABEL_PDF,
  LABEL_FILE_TYPE,
} from '@/constants/applications'
import { USER_SESSION_DATA_KEY, USER_TYPE_AGENT } from '~/constants'
import { format } from 'date-fns'
import { getSignedURL } from '~/utils/url'
import { validateUserRoles } from '~/helpers/user'
export default {
  props: {
    baseUrl: {
      type: String,
      default: process.env.VUE_APP_SERVER_URL,
    },
    token: {
      type: String,
      default: localStorage.getItem('auth._token.local'),
    },
    applicantData: {
      type: Object,
      default: () => null,
    },
    fileData: {
      type: Array,
      default: () => [],
    },
    dialogFor: {
      type: String,
      default: () => null,
    },
    bankStatementRequestedAt: {
      type: String,
      default: () => null,
    },
    selectedBankDocuments: {
      type: Array,
      default: () => [],
    },
    documentData: {
      type: Array,
      default: () => [],
    },
    secondaryIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      format,
      pathtoupdate: '/assets/signed-url',
      LABEL_REQUESTED_AT,
      LABEL_UPLOADED_AT,
      LABEL_BANK_STATEMENT_SECURE,
      LABEL_DOCUMENT_DROP,
      LABEL_DOCUMENT_UPLOAD,
      LABEL_DOCUMENT_PRIMARY,
      LABEL_DOCUMENT_SECONDARY,
      USER_TYPE_AGENT,
      USER_SESSION_DATA_KEY,
      fileList: [],
      documentUploadedDate: '',
      fileNameBeforeUpload: '',
      showProgress: false,
      LABEL_FILE_TYPE,
    }
  },
  created() {
    // eslint-disable-next-line array-callback-return
    this.updatedFileList()
  },
  computed: {
    isTypeAgent() {
      const userRoles = this.$auth.$storage.getUniversal(
        USER_SESSION_DATA_KEY
      )?.systemRoleTypes
      return validateUserRoles(userRoles, [USER_TYPE_AGENT])
    },
  },
  watch: {
    fileData() {
      this.updatedFileList()
    },
  },
  methods: {
    download(file) {
      if (file) {
        this.$axios({
          method: 'get',
          url: getSignedURL(file.url, this.$auth),
          responseType: 'blob',
        }).then((response) => {
          if (response !== undefined) {
            this.downloadFile(response, file)
          }
        })
      }
    },
    downloadFile(response, file) {
      let headers = response.headers
      let blob = new Blob([response.data], { type: headers['content-type'] })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = file.name
      link.click()
      link.remove()
    },
    updatedFileList() {
      this.fileList = []
      this.fileData.map((file) => {
        if (file.purpose === this.LABEL_BANK_STATEMENT_SECURE) {
          this.fileList.push(file)
        }
      })
    },
    checkFileType(file) {
      return file?.filename?.split('.').pop() === LABEL_PDF.toLowerCase()
    },
    removeUpload(documentType) {
      let actionName = null
      let userType = null
      if (this.dialogFor === this.LABEL_DOCUMENT_PRIMARY) {
        actionName = 'removeSelectedBankStatementDocument'
        userType = 'updateDocumentsList'
      } else if (this.dialogFor === this.LABEL_DOCUMENT_SECONDARY) {
        actionName = 'removeOtherSelectedBankStatementDocument'
        userType = 'updateOtherDocumentsList'
      } else {
        actionName = 'removeSharedSelectedDocument'
        userType = 'updateSharedDocumentsList'
      }

      this.updateData(actionName, userType, documentType)

      this.fileList = this.documentData.filter((doc) => {
        return doc.purpose === this.documentName
          ? {
              name: doc.name,
              url: doc.url,
              filename: doc.filename,
            }
          : true
      })
    },
    async uploadBankStatements(file) {
      this.fileNameBeforeUpload = file.name
      const { id, applicationId } = this.$route.params
      const responseSignURL = await this.$store.dispatch(
        `applications/updateSignedUrl`,
        {
          name: file.name,
          type: file.raw.type,
          user: this.applicantData._id,
          vrgId: id,
          applicationId,
        }
      )
      this.req = new XMLHttpRequest()
      if (this.req.upload) {
        this.req.open('PUT', responseSignURL.data?.uploadURL, true)
        this.showProgress = true
      }

      this.req.upload.addEventListener(
        'progress',
        (e) => {
          this.progressWidth = parseInt(`${(e.loaded / e.total) * 100}`)
        },
        false
      )

      this.req.onabort = () => {
        this.progressWidth = 0
        this.showProgress = false
        this.req = ''
      }

      this.req.addEventListener(
        'load',
        async () => {
          this.req = ''
          this.showProgress = false
          this.progressWidth = 0

          this.fileList.push({
            name: file.name,
            url: responseSignURL.data?.finalURL,
          })

          const documents = {
            purpose: this.LABEL_BANK_STATEMENT_SECURE,
            name: file.name,
            url: responseSignURL.data?.finalURL,
          }
          const actionName =
            this.dialogFor === this.LABEL_DOCUMENT_PRIMARY
              ? 'updateDocuments'
              : 'updateOtherDocuments'

          const { id, applicationId } = this.$route.params
          await this.$store.dispatch(`applications/${actionName}`, {
            documents,
            id,
            applicationId,
            secondaryIndex: this.secondaryIndex,
          })
        },
        false
      )

      this.req.send(file.raw)
    },
    previewFile(file) {
      window.open(getSignedURL(file.url, this.$auth), '_blank')
    },
    async handleRemove(file) {
      const actionName =
        this.dialogFor === this.LABEL_DOCUMENT_PRIMARY
          ? 'removeDocument'
          : 'removeOtherDocument'
      const { id, applicationId } = this.$route.params
      const url = file.url
      await this.$store.dispatch(`applications/${actionName}`, {
        url,
        id,
        applicationId,
        secondaryIndex: this.secondaryIndex,
      })
    },
    async updateData(actionName, updateListName, documentType = null) {
      const { id, applicationId } = this.$route.params
      await this.$store.dispatch(`applications/${actionName}`, {
        documentNum: this.secondaryIndex,
        documentType,
        id,
        applicationId,
      })

      this.$nuxt.$emit(updateListName)
    },
  },
}
