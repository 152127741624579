var render = function () {
  var _vm$sharedData, _vm$sharedData$notes$;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }]
  }, [_c('el-card', {
    staticClass: "application-form notes-tab child-pb-0"
  }, [(_vm$sharedData = _vm.sharedData) !== null && _vm$sharedData !== void 0 && (_vm$sharedData$notes$ = _vm$sharedData.notes[0]) !== null && _vm$sharedData$notes$ !== void 0 && _vm$sharedData$notes$.updatedAt ? _c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light mb-4",
    staticStyle: {
      "background": "rgba(247, 173, 30, 0.1)",
      "border-radius": "4px",
      "padding": "16px"
    },
    attrs: {
      "role": "alert"
    }
  }, [_c('div', {
    staticClass: "el-alert__content",
    staticStyle: {
      "padding": "0px"
    }
  }, [_c('span', {
    staticClass: "el-alert__title is-bold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_LAST_UPDATED_AT))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description"
  }, [_vm._v(_vm._s(_vm.updatedAt))])])]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "clearfix",
    staticStyle: {
      "padding": "9.6px 16px"
    },
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_BROKER_NOTES))])]), _vm._v(" "), false ? _c('el-alert', {
    staticClass: "inline-flex w-auto mb-4 text-black",
    attrs: {
      "title": _vm.LABEL_FOR_LAST_UPDATED_AT,
      "description": "Date",
      "type": "warning",
      "closable": false
    }
  }) : _vm._e(), _vm._v(" "), _c('el-row', {
    staticClass: "mb-child-xs-4",
    attrs: {
      "gutter": 16
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "sm": 12,
      "lg": 24
    }
  }, [_c('el-form', {
    ref: "ruleForm",
    staticClass: "demo-ruleForm",
    attrs: {
      "model": _vm.ruleForm,
      "rules": _vm.rules
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_DESCRIPTION,
      "prop": "description"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "autosize": {
        minRows: 17,
        maxRows: 40
      },
      "cols": 20,
      "placeholder": _vm.PLACEHOLDER_FOR_WRITE_TEXT
    },
    model: {
      value: _vm.ruleForm.description,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "description", $$v);
      },
      expression: "ruleForm.description"
    }
  })], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 mb-8 justify-end flex"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("Save notes")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }