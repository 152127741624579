//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ERROR_MESSAGE_FOR_EMAIL,
  ERROR_MESSAGE_FOR_INVALID_EMAIL,
} from '@/constants/index'
export default {
  props: {
    shouldShowImpersonateDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      impersonateForm: {
        email: '',
      },
      isLoading: false,
      rules: {
        email: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_EMAIL,
            trigger: ['blur', 'change'],
          },
          {
            type: 'email',
            message: ERROR_MESSAGE_FOR_INVALID_EMAIL,
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  methods: {
    closeDialog() {
      this.$nuxt.$emit('closeImpersonateDialog')
    },
    async impersonate() {
      try {
        let isValid = false
        this.$refs.impersonateForm.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          return
        }
        this.isLoading = true
        await this.$emit('impersonateUser', this.impersonateForm.email)
      } finally {
        this.$refs.impersonateForm.resetFields(`email`)
        this.isLoading = false
      }
    },
  },
}
