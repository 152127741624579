import { APPLICATION_STATUS_STARTED } from '~/constants/applications'
import { ID_TYPE_DRIVER_LICENCE, ID_TYPE_PASSPORT } from '~/constants/user'

/**
 * Return the type of an id on the basis of uploaded ids.
 * Currently, It gives first priority to `passport`.
 * @param {*} idTypes
 * @returns
 * INPUT: ["Driver Licence", "Passport"]
 * OUTPUT: "Passport"
 */
export const getTypeOfId = (idTypes = []) => {
  if (idTypes.find((i) => i === ID_TYPE_PASSPORT)) {
    return ID_TYPE_PASSPORT
  } else if (idTypes.find((i) => i === ID_TYPE_DRIVER_LICENCE)) {
    return ID_TYPE_DRIVER_LICENCE
  }
}

export const isPrimaryApplicant = (application, applicantId) => {
  return application.primaryApplicantData.primaryApplicant._id === applicantId
}

export const getOtherApplicantIndex = (application, applicantId) => {
  if (application?.otherApplicants?.length) {
    return application.otherApplicants.findIndex(
      (i) => i.applicant._id === applicantId
    )
  }
  return -1
}
export const downloadPdfFromBase64 = ({ base64Data, pdfName }) => {
  const linkSource = `data:application/pdf;base64,${base64Data}`
  const downloadLink = document.createElement('a')
  const fileName = `${pdfName}.pdf`
  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}

export const getPdfNameOfPreliminaryAssessment = (application) => {
  const {
    shortId,
    primaryApplicantData: {
      primaryApplicant: { firstName, lastName },
    },
  } = application
  return `application_${shortId}_${firstName}_${lastName}`
}

export const areAllSelectedDocumentsUploaded = (documents = []) => {
  return documents.every((doc) => doc.uploadedAt)
}

export const shouldChangeTheApplicationStatusToAwaitingDocs = ({
  selectedDocuments = [],
  applicationStatus = '',
}) => {
  if (!applicationStatus || applicationStatus === APPLICATION_STATUS_STARTED) {
    return !areAllSelectedDocumentsUploaded(selectedDocuments)
  }
  return false
}

export const shouldChangeTheApplicationStatusToAwaitingDocsForBankStatement = ({
  applicationStatus = '',
}) => {
  return !applicationStatus || applicationStatus === APPLICATION_STATUS_STARTED
}
