//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ERROR_MESSAGE_FOR_18_YEARS_AGE,
  ERROR_MESSAGE_FOR_DATE_OF_BIRTH,
  ERROR_MESSAGE_FOR_FIRST_NAME,
  ERROR_MESSAGE_FOR_LAST_NAME,
} from '~/constants'
import {
  LABEL_SAVE_BUTTON,
  LABEL_SAVE_AND_NEXT,
  LABEL_ADD_SHARE_HOLDER,
  LABEL_BENEFICIARIES_DETAILS,
  LABEL_BACK_BUTTON,
  LABEL_FOR_FIRST_NAME,
  LABEL_FOR_LAST_NAME,
  LABEL_DATE_OF_BIRTH,
  BUSINESS_DETAILS_TAB_KEY,
  SUBTAB_BUSINESS_SHAREHOLDER_KEY,
  LABEL_ADD_BENEFICIARY,
  LABEL_SHARE_HOLDER_DETAILS,
  SUBTAB_BUSINESS_ACCOUNTANT_KEY,
  SUBTAB_PRIMARY_ADDRESS_KEY,
  SUBTAB_BUSINESS_BENEFICIARIES_KEY,
  LABEL_BUSINESS_STRUCTURE_COMPANY,
  LABEL_BUSINESS_STRUCTURE_TRUST,
  LABEL_BUSINESS_TRUSTEE_TYPE_INDIVIDUAL,
  LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY,
} from '~/constants/applications'
import { changeRoute } from '~/helpers/changeRoute'
import { isdateOfBirthGTE18YearsAndLessThan110Years } from '~/helpers/validators'

export default {
  name: 'CommonBenefieciaryShareHolder',
  props: {
    commonData: {
      type: Array,
      default: () => [],
    },
    isBeneficiary: {
      type: Boolean,
      default: false,
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    isBeneficiary() {
      this.formData = JSON.parse(JSON.stringify(this.commonData))
    },
  },
  data() {
    return {
      LABEL_SAVE_BUTTON,
      LABEL_SAVE_AND_NEXT,
      LABEL_ADD_SHARE_HOLDER,
      LABEL_BENEFICIARIES_DETAILS,
      LABEL_BACK_BUTTON,
      LABEL_FOR_FIRST_NAME,
      LABEL_FOR_LAST_NAME,
      LABEL_DATE_OF_BIRTH,
      BUSINESS_DETAILS_TAB_KEY,
      SUBTAB_BUSINESS_SHAREHOLDER_KEY,
      LABEL_ADD_BENEFICIARY,
      LABEL_SHARE_HOLDER_DETAILS,
      SUBTAB_BUSINESS_ACCOUNTANT_KEY,
      ERROR_MESSAGE_FOR_18_YEARS_AGE,
      ERROR_MESSAGE_FOR_DATE_OF_BIRTH,
      ERROR_MESSAGE_FOR_FIRST_NAME,
      ERROR_MESSAGE_FOR_LAST_NAME,
      formData: JSON.parse(JSON.stringify(this.commonData)),

      rules: {
        firstName: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_FIRST_NAME,
            trigger: ['blur', 'change'],
          },
        ],
        lastName: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_LAST_NAME,
            trigger: ['blur', 'change'],
          },
        ],
        dob: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_DATE_OF_BIRTH,
            trigger: ['blur', 'change'],
          },
          {
            validator: (_rule, value, callback) => {
              if (
                !value ||
                (value && !isdateOfBirthGTE18YearsAndLessThan110Years(value))
              ) {
                callback(new Error(ERROR_MESSAGE_FOR_18_YEARS_AGE))
              } else {
                callback()
              }
            },
            message: ERROR_MESSAGE_FOR_18_YEARS_AGE,
            trigger: 'change',
          },
        ],
      },
      getIsDialogLoading: false,
    }
  },

  mounted() {
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },
  computed: {
    isTrusteeTypeCompany() {
      return (
        this.sharedData?.businessData?.businessStructure ===
          LABEL_BUSINESS_STRUCTURE_TRUST &&
        this.sharedData.businessData?.trusteeType ===
          LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY
      )
    },
  },
  methods: {
    addMoreData() {
      this.formData.push({
        firstName: '',
        lastName: '',
        dob: null,
      })
    },
    removeData(index) {
      this.formData.splice(index, 1)
    },
    async submit(redirect) {
      const { id, applicationId } = this.$route.params
      const actionName = this.isBeneficiary
        ? 'updateBeneficiariesDetails'
        : 'updateShareHolderDetails'

      let isValid = false

      this.$refs.formData.validate((result) => {
        return (isValid = result)
      })
      if (!isValid) {
        return
      }
      this.getIsDialogLoading = true
      await this.$store.dispatch(`applications/${actionName}`, {
        formData: this.formData,
        id,
        applicationId,
      })
      this.getIsDialogLoading = false
      if (redirect) {
        const nextSubtab =
          this.isBeneficiary && this.isTrusteeTypeCompany
            ? SUBTAB_BUSINESS_SHAREHOLDER_KEY
            : SUBTAB_BUSINESS_ACCOUNTANT_KEY
        await changeRoute({
          route: this.$route,
          router: this.$router,
          path: `/finance/applications/${id}/${applicationId}`,
          query: {
            maintab: BUSINESS_DETAILS_TAB_KEY,
            subtab: nextSubtab,
          },
        })
        this.$nuxt.$emit(
          'updateBusinessDetailsTabComponent',
          `${BUSINESS_DETAILS_TAB_KEY}|${nextSubtab}`
        )
      }
    },
    async back() {
      const { id, applicationId } = this.$route.params
      let previousTab = SUBTAB_PRIMARY_ADDRESS_KEY
      if (!this.isBeneficiary) {
        if (
          this.sharedData.businessData.businessStructure ===
            LABEL_BUSINESS_STRUCTURE_TRUST &&
          this.sharedData.businessData.trusteeType ===
            LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY
        ) {
          previousTab = SUBTAB_BUSINESS_BENEFICIARIES_KEY
        }
      }
      this.$nuxt.$emit(
        'updateBusinessDetailsTabComponent',
        `${BUSINESS_DETAILS_TAB_KEY}|${previousTab}`
      )
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: `/finance/applications/${id}/${applicationId}`,
        query: {
          maintab: BUSINESS_DETAILS_TAB_KEY,
          subtab: previousTab,
        },
      })
    },
  },
}
