//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ERROR_MESSAGE_FOR_EMAIL,
  ERROR_MESSAGE_FOR_INVALID_EMAIL,
  ERROR_MESSAGE_FOR_MOBILE,
  ERROR_MESSAGE_FOR_INVALID_MOBILE,
  ERROR_MESSAGE_FOR_LAST_NAME,
  ERROR_MESSAGE_FOR_FIRST_NAME,
  REGEX_FOR_AUS_MOBILES,
  ERROR_MESSAGE_FOR_STATUS,
} from '@/constants'
import {
  APPLICANT_RELATIONSHIP_WITH_PRIMARY_APPLICANT_OPTIONS,
  DEFAULT_EXPENSE_CONSTANT,
  LABEL_FOR_FIRST_NAME,
  LABEL_FOR_LAST_NAME,
  LABEL_FOR_MOBILE,
  LABEL_FOR_EMAIL,
  LABEL_FOR_RELATIONSHIP_WITH_PRIMARY_APPLICANT,
  LABEL_FOR_ADD_BUTTON,
  LABEL_FOR_CANCEL_BUTTON,
  LABEL_FOR_ADD_APPLICANT,
  LABEL_FOR_SECONDARY,
} from '@/constants/applications'
import cloneDeep from 'lodash/cloneDeep'
import { validateDistinctMobileNumber } from '~/helpers/validators'

export default {
  name: 'ApplicantSecondaryForm',
  props: {
    applicationDetails: {
      type: Object,
      default: () => ({}),
    },
    isConsumer: {
      type: Boolean,
      default: true,
    },
    pageTitle: {
      type: String,
      default: LABEL_FOR_ADD_APPLICANT,
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      APPLICANT_RELATIONSHIP_WITH_PRIMARY_APPLICANT_OPTIONS,
      secondApplicantDialog: false,
      DEFAULT_EXPENSE_CONSTANT,
      LABEL_FOR_FIRST_NAME,
      LABEL_FOR_LAST_NAME,
      LABEL_FOR_MOBILE,
      LABEL_FOR_EMAIL,
      LABEL_FOR_RELATIONSHIP_WITH_PRIMARY_APPLICANT,
      LABEL_FOR_ADD_BUTTON,
      LABEL_FOR_CANCEL_BUTTON,
      LABEL_FOR_ADD_APPLICANT,
      LABEL_FOR_SECONDARY,
      ruleForm: {
        firstName: '',
        lastName: '',
        mobile: '',
        email: '',
        relationshipStatusWithPrimaryApplicant: '',
      },
      rules: {
        firstName: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_FIRST_NAME,
            trigger: ['blur', 'change'],
          },
        ],
        lastName: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_LAST_NAME,
            trigger: ['blur', 'change'],
          },
        ],
        email: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_EMAIL,
            trigger: ['blur', 'change'],
          },
          {
            type: 'email',
            message: ERROR_MESSAGE_FOR_INVALID_EMAIL,
            trigger: ['blur', 'change'],
          },
        ],
        relationshipStatusWithPrimaryApplicant: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_STATUS,
            trigger: ['blur', 'change'],
          },
        ],
      },
      mobileNumberRules: (currentMobileNumber = '') => [
        {
          required: true,
          message: ERROR_MESSAGE_FOR_MOBILE,
          trigger: ['blur', 'change'],
        },
        {
          pattern: REGEX_FOR_AUS_MOBILES,
          validator: (_rule, value, callback) =>
            validateDistinctMobileNumber(
              _rule,
              value,
              callback,
              currentMobileNumber,
              this.existingMobileNumbers
            ),
          trigger: ['blur', 'change'],
        },
      ],
    }
  },
  computed: {
    existingMobileNumbers() {
      return [
        ...this.applicationDetails.otherApplicants.map(
          (otherApplicant) => otherApplicant?.applicant?.mobile
        ),
        this.sharedData?.primaryApplicantData?.primaryApplicant?.mobile,
      ].filter(Boolean)
    },
  },
  methods: {
    openSecondApplicantDialog() {
      this.secondApplicantDialog = true
    },
    async submitForm(formName) {
      this.loading = true
      const { id, applicationId } = this.$route.params
      let isValid = false
      this.$refs[formName].validate((result) => {
        // eslint-disable-next-line no-undef
        return (isValid = result)
      })
      if (!isValid) {
        this.loading = false
        return
      }
      const otherApplicants = [
        ...cloneDeep(this.applicationDetails.otherApplicants),
        {
          applicantType: 'Secondary',
          primaryApplicant: undefined,
          addresses: [],
          currentWorkStatus: '',
          dependants: [],
          employmentIncomeNetYearly: 0,
          applicant: {
            mobile: this.ruleForm.mobile,
            firstName: this.ruleForm.firstName,
            lastName: this.ruleForm.lastName,
            email: this.ruleForm.email,
          },
          employments: [],
          nonEmployementIncome: [],
          expenses: DEFAULT_EXPENSE_CONSTANT,
          livingSituation: '',
          numberOfDependants: 0,
          personalNetMonthlyIncomeGuesstimate: 0,
          residencyStatus: '',
          selectedDocuments: [],
          relationshipStatusWithPrimaryApplicant:
            this.ruleForm.relationshipStatusWithPrimaryApplicant ?? '',
          creditEnquiries: {},
          createdAt: '',
          currentWorkStatus: '',
          documents: [],
          expenses: [],
          liabilities: [],
          nonEmploymentIncomes: [],
          selectedDocuments: [],
          selectedDocuments: [],
          customerSuppliedCreditRatingDepiction: '',
          completedEmploymentAndIncomeAt: null,
          completedExpensesAt: null,
          completedLiabilitiesAt: null,
          completedMainApplicationAt: null,
          completedPersonalInfoAt: null,
          hasAdditionalJobs: null,
          hasAdditionalLiabilities: null,
          hasAdditionalVehicles: null,
          hasCreditCardsOrStoreCards: null,
          hasNonEmploymentIncomes: null,
          hasPartnerIncome: null,
          hasPersonalLoans: null,
          incomeNetYearly: null,
          incomePretaxYearly: null,
          incomeWithPartnerNetYearly: null,
          updatedAt: '',
        },
      ]

      await this.$store.dispatch('applications/updateOtherApplicantData', {
        otherApplicant: otherApplicants,
        id,
        applicationId,
      })
      this.ruleForm = {
        firstName: '',
        lastName: '',
        mobile: '',
        email: '',
        relationshipStatusWithPrimaryApplicant: '',
      }
      this.loading = false
      this.closeDialog()
    },
    closeDialog() {
      this.$nuxt.$emit('addSecondaryApplicantDialog-dialog-toggle', {
        open: false,
      })
    },
  },
}
