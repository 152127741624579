//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ERROR_MESSAGE_FOR_FREQUENCY_REQUIRED,
  ERROR_MESSAGE_FOR_AMOUNT_REQUIRED,
  ERROR_MESSAGE_FOR_EXPENSES_DECLARED,
  ERROR_MESSAGE_FOR_NUMBER_ONLY,
  ERROR_MESSAGE_FOR_NUMBER_LENGTH,
} from '~/constants'

import {
  LABEL_EXPENSES_BACK_BUTTON,
  LABEL_EXPENSES_SAVE_BUTTON,
  LABEL_EXPENSES_SAVE_AND_NEXT_BUTTON,
  LABEL_TOTAL_EXPENSES_TOP,
  LABEL_PRIMARY_APPLICANT,
  LABEL_SECONDARY_APPLICANT,
  LABEL_TOTAL_EXPENSES,
  LABEL_ALL_EXPENSES,
  LABEL_FREQUENCY,
  DEFAULT_EXPENSE_CONSTANT,
  LABEL_EXPENSE_FREQUENCY_WEEKLY,
  LABEL_EXPENSE_FREQUENCY_FORTNIGHTLY,
  LABEL_EXPENSE_FREQUENCY_MONTHLY,
  LABEL_EXPENSE_FREQUENCY_YEARLY,
  EXPENSE_FREQUENCY_OPTIONS,
  LABEL_EXPENSE_DECLARATION,
  LABEL_HELP_TEXT_GOES_HERE,
  LABEL_PRIMARY_STRING,
  LABEL_SECONDARY_STRING,
  LABEL_MORE_THAN_ZERO,
  LABEL_IS_ZERO,
  MAINTAB_INCOME_KEY,
  MAINTAB_ASSETS_KEY,
  APPLICATION_ASSETS_OWNER_PRIMARY,
  APPLICATION_ASSETS_OWNER_SECONDARY,
  APPLICATION_ASSETS_OWNER_JOINT,
  LABEL_INDIVIDUAL,
  LABEL_SHARED,
} from '~/constants/applications'
import { changeRoute } from '~/helpers/changeRoute'

import { toCurrency } from '~/helpers/currency'
import { validatePositiveNumber } from '~/helpers/validators'
export default {
  props: {
    applicationDetails: {
      type: Object,
      default: null,
    },
  },
  data() {
    const checkIncome = (_rule, value, callback) =>
      validatePositiveNumber(value, callback)
    return {
      ERROR_MESSAGE_FOR_FREQUENCY_REQUIRED,
      ERROR_MESSAGE_FOR_AMOUNT_REQUIRED,
      ERROR_MESSAGE_FOR_EXPENSES_DECLARED,
      ERROR_MESSAGE_FOR_NUMBER_ONLY,
      ERROR_MESSAGE_FOR_NUMBER_LENGTH,
      LABEL_EXPENSES_BACK_BUTTON,
      LABEL_EXPENSES_SAVE_BUTTON,
      LABEL_EXPENSES_SAVE_AND_NEXT_BUTTON,
      LABEL_TOTAL_EXPENSES_TOP,
      LABEL_PRIMARY_APPLICANT,
      LABEL_SECONDARY_APPLICANT,
      LABEL_TOTAL_EXPENSES,
      LABEL_ALL_EXPENSES,
      LABEL_FREQUENCY,
      DEFAULT_EXPENSE_CONSTANT,
      LABEL_EXPENSE_FREQUENCY_WEEKLY,
      LABEL_EXPENSE_FREQUENCY_FORTNIGHTLY,
      LABEL_EXPENSE_FREQUENCY_MONTHLY,
      LABEL_EXPENSE_FREQUENCY_YEARLY,
      EXPENSE_FREQUENCY_OPTIONS,
      LABEL_EXPENSE_DECLARATION,
      LABEL_HELP_TEXT_GOES_HERE,
      LABEL_PRIMARY_STRING,
      LABEL_SECONDARY_STRING,
      LABEL_MORE_THAN_ZERO,
      LABEL_IS_ZERO,
      MAINTAB_INCOME_KEY,
      MAINTAB_ASSETS_KEY,
      APPLICATION_ASSETS_OWNER_PRIMARY,
      APPLICATION_ASSETS_OWNER_SECONDARY,
      APPLICATION_ASSETS_OWNER_JOINT,
      LABEL_INDIVIDUAL,
      LABEL_SHARED,
      rules: {
        amount: [
          {
            required: true,
            validator: checkIncome,
            type: 'number',
          },
        ],
        secondaryAmount: [
          {
            required: true,
            validator: checkIncome,
            type: 'number',
          },
        ],
        frequency: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_FREQUENCY_REQUIRED,
            trigger: ['blur', 'change'],
          },
        ],
        expensesDeclared: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_EXPENSES_DECLARED,
            trigger: ['blur', 'change'],
          },
        ],
      },
      expensesForm: {
        primaryApplicantData: {
          ...this.applicationDetails.primaryApplicantData,
        },
        secondaryApplicantData: [...this.applicationDetails.otherApplicants],
      },
      defaultColLength: 6,
      totalExpensePrimaryApplicant: 0,
      totalExpenseSecondaryApplicant: 0,
      updateAmountwait: [],
    }
  },
  computed: {
    totalExpenseApplicant() {
      return parseInt(
        this.totalExpensePrimaryApplicant + this.totalExpenseSecondaryApplicant
      )
    },
    totalIncomePrimaryApplicant() {
      let total = 0
      if (this.expensesForm.primaryApplicantData.employments.length > 0) {
        this.expensesForm.primaryApplicantData.employments.forEach(
          (employment) => {
            if (employment.netIncome)
              total += this.checkFrequency(
                employment.netIncome,
                employment.netIncomeFrequency
              )
          }
        )
      }
      return total
    },
    totalIncomeSecondaryApplicant() {
      let total = 0
      if (
        this.expensesForm.secondaryApplicantData.length > 0 &&
        this.expensesForm.secondaryApplicantData[0].employments.length > 0
      ) {
        this.expensesForm.secondaryApplicantData[0].employments.forEach(
          (employment) => {
            if (employment.netIncome)
              total += this.checkFrequency(
                employment.netIncome,
                employment.netIncomeFrequency
              )
          }
        )
      }
      return total
    },
    totalIncomeOther() {
      let total = 0
      if (
        this.expensesForm.primaryApplicantData.nonEmploymentIncomes.length > 0
      ) {
        this.expensesForm.primaryApplicantData.nonEmploymentIncomes.forEach(
          (employment) => {
            if (employment.netAmount)
              total += this.checkFrequency(
                employment.netAmount,
                employment.frequency
              )
          }
        )
      }
      return total
    },
    totalIncomeSpousal() {
      let total = 0
      if (
        this.expensesForm.primaryApplicantData.partnerNetIncome &&
        this.expensesForm.primaryApplicantData.partnerNetIncomeFrequency
      ) {
        total = this.checkFrequency(
          this.expensesForm.primaryApplicantData.partnerNetIncome,
          this.expensesForm.primaryApplicantData.partnerNetIncomeFrequency
        )
      }
      return total
    },
    totalLiabilitiesPrimaryApplicant() {
      let total = 0
      if (
        this.expensesForm.primaryApplicantData &&
        this.expensesForm.primaryApplicantData.liabilities
      ) {
        this.expensesForm.primaryApplicantData.liabilities.forEach(
          (liability) => {
            if (liability.ownership === this.APPLICATION_ASSETS_OWNER_PRIMARY)
              total += parseInt(liability.installmentAmount)
          }
        )
      }
      return total
    },
    totalLiabilitiesSecondaryApplicant() {
      let total = 0
      if (
        this.expensesForm.secondaryApplicantData &&
        this.expensesForm.secondaryApplicantData[0] &&
        this.expensesForm.secondaryApplicantData[0].liabilities
      ) {
        this.expensesForm.secondaryApplicantData[0].liabilities.forEach(
          (liability) => {
            if (liability.ownership === this.APPLICATION_ASSETS_OWNER_SECONDARY)
              total += parseInt(liability.installmentAmount)
          }
        )
      }
      return total
    },
    totalLiabilitiesJointApplicant() {
      let total = 0
      if (
        this.expensesForm.primaryApplicantData &&
        this.expensesForm.primaryApplicantData.liabilities
      ) {
        this.expensesForm.primaryApplicantData.liabilities.forEach(
          (liability) => {
            if (liability.ownership === this.APPLICATION_ASSETS_OWNER_JOINT)
              total += parseInt(liability.installmentAmount)
          }
        )
      }
      return total
    },
  },

  methods: {
    ...{ toCurrency },
    doDeepCloneData() {
      this.expensesForm = {
        primaryApplicantData: _.cloneDeep(
          this.applicationDetails.primaryApplicantData
        ),
        secondaryApplicantData: _.cloneDeep(
          this.applicationDetails.secondaryApplicantData
        ),
      }
    },
    numberWithCommas(x) {
      return toCurrency(x)
    },
    doesApplicantExpenseExist(
      applicantData,
      type,
      checklength,
      indexnum = null
    ) {
      let checkArr = []
      if (type === this.LABEL_PRIMARY_STRING) {
        if (
          applicantData.primaryApplicantData &&
          applicantData.primaryApplicantData.expenses
        ) {
          checkArr = applicantData.primaryApplicantData
        }
      } else if (
        applicantData.secondaryApplicantData &&
        applicantData.secondaryApplicantData[0] &&
        applicantData.secondaryApplicantData[0].expenses
      ) {
        checkArr = applicantData.secondaryApplicantData[0].expenses
      }

      if (indexnum) {
        return checkArr[indexnum]
      }
      if (checklength === this.LABEL_MORE_THAN_ZERO) {
        return checkArr.length > 0
      } else {
        return checkArr.length <= 0
      }
    },
    async checkArrayFormat() {
      const defaultExpenses = this.DEFAULT_EXPENSE_CONSTANT
      if (
        this.doesApplicantExpenseExist(
          this.expensesForm,
          this.LABEL_PRIMARY_STRING,
          this.LABEL_IS_ZERO
        )
      ) {
        await new Promise((r) => setTimeout(r, 800))
        if (
          this.doesApplicantExpenseExist(
            this.expensesForm,
            this.LABEL_PRIMARY_STRING,
            this.LABEL_IS_ZERO
          )
        ) {
          const { id: vehicleRequestGroupId, applicationId } =
            this.$route.params
          await this.$store.dispatch(
            'finance/applications/application/updateApplication',
            {
              application: {
                ...this.applicationDetails,
                primaryApplicantData: {
                  ...this.applicationDetails.primaryApplicantData,
                  expenses: [],
                },
              },
              vehicleRequestGroupId,
              applicationId,
            }
          )
          this.doDeepCloneData()
        }
      }
      if (
        this.doesApplicantExpenseExist(
          this.expensesForm,
          this.LABEL_SECONDARY_STRING,
          this.LABEL_IS_ZERO
        )
      ) {
        await new Promise((r) => setTimeout(r, 800))
        if (
          this.doesApplicantExpenseExist(
            this.expensesForm,
            this.LABEL_SECONDARY_STRING,
            this.LABEL_IS_ZERO
          )
        ) {
          const { id: vehicleRequestGroupId, applicationId } =
            this.$route.params
          await this.$store.dispatch(
            'finance/applications/application/updateApplication',
            {
              application: {
                ...this.applicationDetails,
                otherApplicants: this.applicationDetails.map((r) => ({
                  ...r,
                  expenses: defaultExpenses,
                })),
              },
              vehicleRequestGroupId,
              applicationId,
            }
          )
          // this.$store.dispatch(
          //   'applications/updateApplicationBlankSecondaryExpense',
          //   {
          //     secondaryExpense: defaultExpenses,
          //   }
          // )

          this.doDeepCloneData()
        }
      }

      if (
        this.doesApplicantExpenseExist(
          this.expensesForm,
          this.LABEL_PRIMARY_STRING,
          this.LABEL_MORE_THAN_ZERO
        )
      ) {
        // map missing objects to primary data
        const mapPrimaryExpensedBasedOnTitle = Object.create(null)
        for (const expense of this.expensesForm.primaryApplicantData.expenses) {
          if (!mapPrimaryExpensedBasedOnTitle[expense.type]) {
            mapPrimaryExpensedBasedOnTitle[expense.type] = expense
          }
        }

        // eslint-disable-next-line array-callback-return
        for (const defaultExpense of defaultExpenses) {
          if (!mapPrimaryExpensedBasedOnTitle[defaultExpense.type]) {
            this.expensesForm.primaryApplicantData.expenses.push(defaultExpense)
          }
        }

        await this.$store.dispatch(
          'applications/updateApplicationBlankExpense',
          {
            expenses: this.expensesForm.primaryApplicantData.expenses,
          }
        )

        this.doDeepCloneData()
      }

      // map missing objects to secondary data
      if (
        this.doesApplicantExpenseExist(
          this.expensesForm,
          this.LABEL_SECONDARY_STRING,
          this.LABEL_MORE_THAN_ZERO
        )
      ) {
        const mapSecondaryExpensedBasedOnTitle = Object.create(null)
        for (const expense of this.expensesForm.secondaryApplicantData[0]
          .expenses) {
          if (!mapSecondaryExpensedBasedOnTitle[expense.type]) {
            mapSecondaryExpensedBasedOnTitle[expense.type] = expense
          }
        }

        // eslint-disable-next-line array-callback-return
        for (const defaultExpense of defaultExpenses) {
          if (!mapSecondaryExpensedBasedOnTitle[defaultExpense.type]) {
            this.expensesForm.secondaryApplicantData[0].expenses.push(
              defaultExpense
            )
          }
        }

        await this.$store.dispatch(
          'applications/updateApplicationBlankSecondaryExpense',
          {
            secondaryExpense:
              this.expensesForm.secondaryApplicantData[0].expenses,
          }
        )

        this.doDeepCloneData()
      }
    },
    updateAmount() {
      this.totalExpensePrimaryApplicant = 0
      this.totalExpenseSecondaryApplicant = 0
      this.expenseCalculation(
        this.expensesForm.primaryApplicantData,
        this.LABEL_PRIMARY_STRING
      )

      if (
        this.doesApplicantExpenseExist(
          this.expensesForm,
          this.LABEL_SECONDARY_STRING,
          this.LABEL_MORE_THAN_ZERO
        )
      ) {
        this.expenseCalculation(
          this.expensesForm.secondaryApplicantData[0],
          this.LABEL_SECONDARY_STRING
        )
      }
    },
    expenseCalculation(mainArray, type) {
      if (mainArray && mainArray.expenses) {
        // eslint-disable-next-line array-callback-return
        mainArray.expenses.map((expense) => {
          if (expense.amount > 0) {
            let expenseAmount = parseInt(expense.amount)

            if (type === this.LABEL_PRIMARY_STRING) {
              expenseAmount = this.checkFrequency(
                expenseAmount,
                expense.frequency
              )
            } else {
              const expenseType = expense.type
              // eslint-disable-next-line array-callback-return
              this.expensesForm.primaryApplicantData.expenses.map(
                (matchFreq) => {
                  if (matchFreq.type === expenseType) {
                    expenseAmount = this.checkFrequency(
                      expenseAmount,
                      matchFreq.frequency
                    )
                  }
                }
              )
            }

            if (expenseAmount) {
              if (type === this.LABEL_PRIMARY_STRING) {
                this.totalExpensePrimaryApplicant += expenseAmount
              } else {
                this.totalExpenseSecondaryApplicant += expenseAmount
              }
            }
          }
        })
      }
    },
    checkFrequency(expenseAmount, frequency) {
      if (frequency === this.LABEL_EXPENSE_FREQUENCY_WEEKLY) {
        return (expenseAmount * 52) / 12
      } else if (frequency === this.LABEL_EXPENSE_FREQUENCY_FORTNIGHTLY) {
        return (expenseAmount * 26) / 12
      } else if (frequency === this.LABEL_EXPENSE_FREQUENCY_YEARLY) {
        return expenseAmount / 12
      } else if (frequency === this.LABEL_EXPENSE_FREQUENCY_MONTHLY) {
        return expenseAmount
      } else {
        return expenseAmount
      }
    },
    async back() {
      const { id, applicationId } = this.$route.params
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: `/finance/applications/${id}/${applicationId}`,
        query: {
          maintab: this.MAINTAB_INCOME_KEY,
        },
      })
    },
    async submit(redirect) {
      const { id, applicationId } = this.$route.params
      let isValid = false
      this.$refs.expensesForm.validate((result) => {
        return (isValid = result)
      })
      if (!isValid) {
        return
      }

      await this.$store.dispatch('applications/updateApplicationExpenses', {
        expenses: this.expensesForm,
        id,
        applicationId,
      })

      if (redirect) {
        await changeRoute({
          route: this.$route,
          router: this.$router,
          path: `/finance/applications/${id}/${applicationId}`,
          query: {
            maintab: this.MAINTAB_ASSETS_KEY,
          },
        })
      }
    },
  },
}
