export default {
  async fetchNotifications(
    { commit },
    {
      currentPage = 1,
      sortKey = 'createdAt',
      sortDirection = 'desc',
      status = 'All',
      search = '',
    }
  ) {
    try {
      const sort = (sortDirection === 'desc' ? '-' : '') + sortKey
      const params = new URLSearchParams({ sort })

      if (status !== 'All') {
        params.set('status', status)
      }
      if (currentPage) {
        params.set('page', currentPage)
      }
      if (search) {
        params.set('search', search)
      }
      const requestUrl = `/notifications?${params}`
      commit('startTableLoading')

      const notifications = await this.$axios.get(requestUrl)
      const { data } = notifications
      commit('setTableSettings', {
        totalDocs: data.totalDocs,
        page: data.page,
        totalPages: data.totalPages,
        limit: data.limit,
        pagingCounter: data.pagingCounter,
      })
      const tableData = data.docs.map((notification) => {
        return notification
      })
      commit('setNotifications', tableData)
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: 'Error getting notifications :(',
      })
    } finally {
      commit('stopTableLoading')
    }
  },
}
