import { difference } from 'lodash'
import {
  ACCOUNT_CONTACT_ROLE_PARTNER_ADMINISTRATOR,
  ACCOUNT_CONTACT_ROLE_PARTNER_REFERRER,
} from '~/constants/user'

export const isAccountRelationActiveReferrer = (relation) =>
  relation.isActive &&
  (relation.roles.includes(ACCOUNT_CONTACT_ROLE_PARTNER_REFERRER) ||
    relation.roles.includes(ACCOUNT_CONTACT_ROLE_PARTNER_ADMINISTRATOR))

export const isAccountRelationActiveReferrerAdmin = (relation) =>
  relation.isActive &&
  relation.roles.includes(ACCOUNT_CONTACT_ROLE_PARTNER_ADMINISTRATOR)

export const hasActiveReferrerRelation = (user) =>
  !!user.accountRelations.find(isAccountRelationActiveReferrer)

export const fullName = (user) => {
  return `${user.firstName || ''} ${user.lastName || ''}`
}

export const validateUserRoles = (loggedUserSystemRoleTypes, suppliedRoles) => {
  if (!loggedUserSystemRoleTypes?.length) {
    return false
  }
  const loggedUserSystemRolesOnly = loggedUserSystemRoleTypes.map(
    ({ role }) => role
  )
  /**
   * variance is number of roles supplied already present in system roles
   * if variance is less then existing roles.. its means variance is subset of existing roles and validation is a success
   */
  const variance = difference(loggedUserSystemRolesOnly, suppliedRoles)
  console.log('variance', variance)
  if (variance.length < loggedUserSystemRolesOnly.length) {
    return true
  }
  return false
}
