var render = function () {
  var _vm$sharedData, _vm$loanData, _vm$loanData$appData, _vm$loanData2, _vm$loanData2$appData, _vm$loanData3, _vm$loanData3$appData, _vm$loanData3$appData2, _vm$loanData3$appData3, _vm$loanData4, _vm$loanData4$appData, _vm$loanData4$appData2, _vm$loanData4$appData3, _vm$loanData5, _vm$loanData5$appData, _vm$loanData5$appData2, _vm$numberWithCommas, _vm$loanData$appData2, _vm$loanData$appData3, _vm$loanData$appData4, _vm$loanData6, _vm$loanData6$appData, _vm$loanData6$appData2, _vm$loanData$appData$, _vm$loanData$appData5, _vm$loanData$appData6, _vm$loanData$appData7, _vm$loanData7, _vm$loanData7$appData, _vm$loanData7$appData2, _vm$loanData$appData$2, _vm$loanData$appData8, _vm$loanData$appData9, _vm$loanData$appData10, _vm$loanData8, _vm$loanData8$appData, _vm$loanData8$appData2, _vm$loanData9, _vm$loanData9$appData, _vm$loanData9$appData2, _vm$loanData10, _vm$loanData10$appDat, _vm$loanData10$appDat2, _vm$loanData11;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isDialogLoading,
      expression: "isDialogLoading"
    }],
    staticClass: "loan-details-form"
  }, [_vm.shouldShowPreSettlementBanner ? _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light bg-primary mb-4 alertbox",
    staticStyle: {
      "background": "rgba(0, 184, 73, 0.1)",
      "border-radius": "4px"
    },
    attrs: {
      "role": "alert",
      "data-selector": "ps-banner"
    }
  }, [false ? _c('span', {
    staticClass: "rounded-fifty border border-dark order-label text-center text-xs mr-4"
  }, [_vm._v("1")]) : _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay",
      "data-selector": "ps-banner-ok-icon"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "ps-banner-title"
    }
  }, [_vm._v(_vm._s(_vm.SUCCESS_TITLE_FOR_PRE_SETTLEMENT))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "ps-banner-desc"
    }
  }, [_vm._v(_vm._s(_vm.SUCCESS_DESCRIPTION_FOR_PRE_SETTLEMENT) + "\n          " + _vm._s(_vm.format(new Date(_vm.loanData.appData.loanDetails.settlement.preSettledAt), 'dd/MM/yyyy hh:mm aaa')))])])])]) : _vm._e(), _vm._v(" "), _vm.shouldDisplaySuccessCpdAlert ? _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light bg-primary mb-4 alertbox",
    staticStyle: {
      "background": "rgba(0, 184, 73, 0.1)",
      "border-radius": "4px"
    },
    attrs: {
      "role": "alert",
      "data-selector": "ps-cpd-sent-banner"
    }
  }, [false ? _c('span', {
    staticClass: "rounded-fifty border border-dark order-label text-center text-xs mr-4"
  }, [_vm._v("1")]) : _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay",
      "data-selector": "ps-cpd-sent-icon"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "ps-cpd-alert-title"
    }
  }, [_vm._v(_vm._s(_vm.SUCCESS_TITLE_FOR_CREDIT_PROPSAL))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "ps-cpd-alert-desc"
    }
  }, [_vm._v(_vm._s(_vm.SUCCESS_DESCRIPTION_FOR_CREDIT_PROPSAL) + "\n          " + _vm._s(_vm.format(new Date(_vm.loanData.appData.loanDetails.creditProposalDisclosureSentAt), 'dd/MM/yyyy hh:mm aaa')))])]), _vm._v(" "), _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "type": "primary",
      "plain": "",
      "data-selector": "ps-cpd-alert-view-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.viewAssetment(_vm.LABEL_CREDIT_PROPOSAL_DISCLOSURE);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_VIEW))])], 1)]) : _vm._e(), _vm._v(" "), _vm.shouldDisplayCpdAlert ? _c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light mb-4 mt-4 alertbox",
    staticStyle: {
      "background": "rgba(247, 173, 30, 0.1)",
      "border-radius": "4px"
    },
    attrs: {
      "role": "alert",
      "data-selector": "ps-cpd-not-sent-banner"
    }
  }, [_c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/alert-triangle.svg"),
      "alt": "okay",
      "data-selector": "ps-cpd-alert-icon"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "ps-cpd-alert-title"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CREDIT_PROPOSAL_DISCLOSURE))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "ps-cpd-alert-desc"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CREDIT_PROPOSAL_DISCLOSURE_DETAILS) + "\n      ")])]), _vm._v(" "), _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "data-selector": "ps-cpd-review-send-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.reviewAndSendProposal(_vm.LABEL_CREDIT_PROPOSAL_DISCLOSURE);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_REVIEW_AND_SEND_PROPOSAL))])], 1) : _vm._e(), _vm._v(" "), ((_vm$sharedData = _vm.sharedData) === null || _vm$sharedData === void 0 ? void 0 : _vm$sharedData.loanType) !== _vm.LOAN_TYPE_PERSONAL_LOAN ? _c('el-card', {
    staticClass: "application-form pb-child-none mb-4",
    attrs: {
      "data-selector": "ps-asset-details-section"
    }
  }, [_c('div', {
    staticClass: "items-center flex justify-between",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "ps-asset-details-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_ASSET_DETAILS)), ((_vm$loanData = _vm.loanData) === null || _vm$loanData === void 0 ? void 0 : (_vm$loanData$appData = _vm$loanData.appData) === null || _vm$loanData$appData === void 0 ? void 0 : _vm$loanData$appData.status) !== _vm.APPLICATION_STATUS_SETTLED ? _c('a', {
    staticClass: "pl-2",
    staticStyle: {
      "color": "#7100da"
    },
    attrs: {
      "href": "javascript:void(0)",
      "data-selector": "ps-ad-edit-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.openRequestAssetDetail();
      }
    }
  }, [_vm._v(_vm._s(_vm.DOCUMENT_EDIT_LINK))]) : _vm._e(), _vm._v(" "), ((_vm$loanData2 = _vm.loanData) === null || _vm$loanData2 === void 0 ? void 0 : (_vm$loanData2$appData = _vm$loanData2.appData) === null || _vm$loanData2$appData === void 0 ? void 0 : _vm$loanData2$appData.status) === _vm.APPLICATION_STATUS_SETTLED ? _c('a', {
    staticClass: "pl-2",
    staticStyle: {
      "color": "#7100da"
    },
    attrs: {
      "href": "javascript:void(0)",
      "data-selector": "ps-ad-view-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.openViewAssetDetail();
      }
    }
  }, [_vm._v(_vm._s(_vm.DOCUMENT_VIEW_LINK))]) : _vm._e()])]), _vm._v(" "), _c('div', [!_vm.hasValidAssetData && _vm.showAssetValidationWarning ? _c('NotificationBar', {
    staticClass: "el-alert--warning el-alert-message-warning",
    attrs: {
      "message-type": "warning",
      "description": _vm.WARNING_MESSAGE_FOR_ASSET_DETAIL,
      "has-button": false
    }
  }) : _vm._e(), _vm._v(" "), _c('AssetInfo', {
    attrs: {
      "asset-data": _vm.loanData.appData
    }
  })], 1), _vm._v(" "), _c('LoanDetailsAssetDialog', {
    attrs: {
      "open-dialog-asset-details-settlement": _vm.isAssetDetailDialogOpen,
      "shared-data": _vm.loanData.appData
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.loanDetailsForm ? [_c('el-form', {
    ref: "loanDetailsForm",
    attrs: {
      "model": _vm.loanData
    }
  }, [_c('el-card', {
    staticClass: "lender-form application-form mb-4"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "el-card__header px-0"
  }, [_c('div', {
    staticClass: "items-center flex justify-between"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "ps-lender-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_LENDER)), !_vm.isSettled ? _c('a', {
    staticClass: "pl-2",
    staticStyle: {
      "color": "#7100da"
    },
    attrs: {
      "href": "javascript:void(0)",
      "data-selector": "ps-lender-edit-btn"
    },
    on: {
      "click": function ($event) {
        _vm.openLenderDialog = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.DOCUMENT_EDIT_LINK))]) : _vm._e()])])]), _vm._v(" "), _c('div', [!_vm.hasLenderData ? _c('NotificationBar', {
    staticClass: "el-alert--warning el-alert-message-warning",
    attrs: {
      "message-type": "warning",
      "description": _vm.WARNING_MESSAGE_FOR_LENDER,
      "has-button": false
    }
  }) : _vm._e(), _vm._v(" "), _c('el-row', {
    staticClass: "mb-2",
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_NAME))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-lender-name"
    }
  }, [_vm._v("\n                    " + _vm._s(_vm.getBusinessName ? _vm.getBusinessName : '--'))])])]), _vm._v(" "), _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_LOAN_ID))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-loan-id"
    }
  }, [_vm._v(_vm._s((_vm$loanData3 = _vm.loanData) !== null && _vm$loanData3 !== void 0 && (_vm$loanData3$appData = _vm$loanData3.appData) !== null && _vm$loanData3$appData !== void 0 && (_vm$loanData3$appData2 = _vm$loanData3$appData.loanDetails) !== null && _vm$loanData3$appData2 !== void 0 && (_vm$loanData3$appData3 = _vm$loanData3$appData2.settlement) !== null && _vm$loanData3$appData3 !== void 0 && _vm$loanData3$appData3.loanId ? (_vm$loanData4 = _vm.loanData) === null || _vm$loanData4 === void 0 ? void 0 : (_vm$loanData4$appData = _vm$loanData4.appData) === null || _vm$loanData4$appData === void 0 ? void 0 : (_vm$loanData4$appData2 = _vm$loanData4$appData.loanDetails) === null || _vm$loanData4$appData2 === void 0 ? void 0 : (_vm$loanData4$appData3 = _vm$loanData4$appData2.settlement) === null || _vm$loanData4$appData3 === void 0 ? void 0 : _vm$loanData4$appData3.loanId : '--'))])])])], 1)], 1)]), _vm._v(" "), _c('div', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "el-card__header px-0"
  }, [_c('div', {
    staticClass: "items-center flex justify-between"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "ps-loan-details-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_LOAN_DETAILS)), _vm.canEditLoanDetails ? _c('a', {
    staticClass: "pl-2",
    staticStyle: {
      "color": "#7100da"
    },
    attrs: {
      "href": "javascript:void(0)",
      "data-selector": "ps-loan-details-edit-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.openLoanDetailsForms();
      }
    }
  }, [_vm._v(_vm._s(_vm.DOCUMENT_EDIT_LINK))]) : _vm._e()])])]), _vm._v(" "), _c('div', [_vm.loanData.appData.loanDetails.settlement ? _c('el-row', {
    staticClass: "mb-2",
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-purchase-price"
    }
  }, [_vm._v("\n                    " + _vm._s(_vm.loanData.appData.loanDetails.settlement.purchasePrice >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.purchasePrice) : '--'))])])]), _vm._v(" "), ((_vm$loanData5 = _vm.loanData) === null || _vm$loanData5 === void 0 ? void 0 : (_vm$loanData5$appData = _vm$loanData5.appData) === null || _vm$loanData5$appData === void 0 ? void 0 : (_vm$loanData5$appData2 = _vm$loanData5$appData.tradeIn) === null || _vm$loanData5$appData2 === void 0 ? void 0 : _vm$loanData5$appData2.type) === _vm.APPLICATION_TRADE_IN_VEHICLE ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "text-xs font-extrabold"
  }, [_c('label', {
    attrs: {
      "for": "cheese"
    }
  }, [_vm._v("\n                    " + _vm._s(_vm.LABEL_TRADE_IN_EQUITY) + "\n                  ")])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-trade-in-price"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.settlement.tradeInAmount >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.tradeInAmount) : '--'))])])]) : _vm._e(), _vm._v(" "), _vm.remainingLiability ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v("\n                    " + _vm._s(_vm.LABEL_TRADING_LIABILITIES) + "\n                  ")])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1"
  }, [_vm._v("\n                    " + _vm._s(_vm.numberWithCommas(_vm.remainingLiability)) + "\n                  ")])])]) : _vm._e(), _vm._v(" "), _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_DEPOSIT))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-deposit"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.settlement.depositAmount >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.depositAmount) : '--'))])])]), _vm._v(" "), _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_NAF))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-naf"
    }
  }, [_vm._v("\n                    " + _vm._s((_vm$numberWithCommas = _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.netAmountFinanced)) !== null && _vm$numberWithCommas !== void 0 ? _vm$numberWithCommas : _vm.LABEL_NOT_AVAILABLE) + "\n                  ")])])]), _vm._v(" "), _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_BALLOON))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-balloon-percent"
    }
  }, [_vm._v(_vm._s((_vm$loanData$appData2 = _vm.loanData.appData) !== null && _vm$loanData$appData2 !== void 0 && (_vm$loanData$appData3 = _vm$loanData$appData2.loanDetails) !== null && _vm$loanData$appData3 !== void 0 && (_vm$loanData$appData4 = _vm$loanData$appData3.settlement) !== null && _vm$loanData$appData4 !== void 0 && _vm$loanData$appData4.balloonPct ? parseFloat(_vm.loanData.appData.loanDetails.settlement.balloonPct).toFixed(2) + '%' : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_LOAN_BALLOON_DOLLAR))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-balloon-amount"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.settlement.balloonAmount >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.balloonAmount) : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_LOAN_TREM))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-loan-term"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.settlement.loanTermYears ? _vm.yearViewOption(_vm.loanData.appData.loanDetails.settlement.loanTermYears) : _vm.LABEL_NOT_AVAILABLE))])])])], 1) : _vm._e()], 1)]), _vm._v(" "), _c('div', {
    staticClass: "el-card__header px-0"
  }, [_c('div', {
    staticClass: "items-center flex justify-between"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "ps-rates-fees-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_RATES_FEES)), _vm.canEditLoanDetails ? _c('a', {
    staticClass: "pl-2",
    staticStyle: {
      "color": "#7100da"
    },
    attrs: {
      "href": "javascript:void(0)",
      "data-selector": "ps-rates-fees-edit-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.openLoanDetailsForms();
      }
    }
  }, [_vm._v(_vm._s(_vm.DOCUMENT_EDIT_LINK))]) : _vm._e()])])]), _vm._v(" "), _c('div', [(_vm$loanData6 = _vm.loanData) !== null && _vm$loanData6 !== void 0 && (_vm$loanData6$appData = _vm$loanData6.appData) !== null && _vm$loanData6$appData !== void 0 && (_vm$loanData6$appData2 = _vm$loanData6$appData.loanDetails) !== null && _vm$loanData6$appData2 !== void 0 && _vm$loanData6$appData2.settlement ? _c('el-row', {
    staticClass: "mb-4",
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_BASE_RATE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-lender-base-rate"
    }
  }, [_vm._v(_vm._s((_vm$loanData$appData$ = (_vm$loanData$appData5 = _vm.loanData.appData) === null || _vm$loanData$appData5 === void 0 ? void 0 : (_vm$loanData$appData6 = _vm$loanData$appData5.loanDetails) === null || _vm$loanData$appData6 === void 0 ? void 0 : (_vm$loanData$appData7 = _vm$loanData$appData6.settlement) === null || _vm$loanData$appData7 === void 0 ? void 0 : _vm$loanData$appData7.baseRate) !== null && _vm$loanData$appData$ !== void 0 ? _vm$loanData$appData$ : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_MONTHLY_REPAYMENT_AMOUNT))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-monthly-repayment"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.settlement.monthlyRepayment >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.monthlyRepayment) : _vm.LABEL_NOT_AVAILABLE))])])])], 1) : _vm._e(), _vm._v(" "), (_vm$loanData7 = _vm.loanData) !== null && _vm$loanData7 !== void 0 && (_vm$loanData7$appData = _vm$loanData7.appData) !== null && _vm$loanData7$appData !== void 0 && (_vm$loanData7$appData2 = _vm$loanData7$appData.loanDetails) !== null && _vm$loanData7$appData2 !== void 0 && _vm$loanData7$appData2.settlement ? _c('el-row', {
    staticClass: "mb-4",
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_CUSTOMER_RATE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-customer-rate"
    }
  }, [_vm._v("\n                  " + _vm._s((_vm$loanData$appData$2 = (_vm$loanData$appData8 = _vm.loanData.appData) === null || _vm$loanData$appData8 === void 0 ? void 0 : (_vm$loanData$appData9 = _vm$loanData$appData8.loanDetails) === null || _vm$loanData$appData9 === void 0 ? void 0 : (_vm$loanData$appData10 = _vm$loanData$appData9.settlement) === null || _vm$loanData$appData10 === void 0 ? void 0 : _vm$loanData$appData10.customerRate) !== null && _vm$loanData$appData$2 !== void 0 ? _vm$loanData$appData$2 : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_LENDER_MONTHLY_FEE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-account-keeping-fee"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.settlement.accountKeepingFee >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.accountKeepingFee) : _vm.LABEL_NOT_AVAILABLE))])])])], 1) : _vm._e(), _vm._v(" "), (_vm$loanData8 = _vm.loanData) !== null && _vm$loanData8 !== void 0 && (_vm$loanData8$appData = _vm$loanData8.appData) !== null && _vm$loanData8$appData !== void 0 && (_vm$loanData8$appData2 = _vm$loanData8$appData.loanDetails) !== null && _vm$loanData8$appData2 !== void 0 && _vm$loanData8$appData2.settlement ? _c('el-row', {
    staticClass: "mb-4",
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_LENDER_FEE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-lender-est-fee"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.settlement.lenderEstablishmentFee >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.lenderEstablishmentFee) : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_PSPR_FEE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-ppsr-fee"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.settlement.ppsrFee >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.ppsrFee) : _vm.LABEL_NOT_AVAILABLE))])])])], 1) : _vm._e(), _vm._v(" "), (_vm$loanData9 = _vm.loanData) !== null && _vm$loanData9 !== void 0 && (_vm$loanData9$appData = _vm$loanData9.appData) !== null && _vm$loanData9$appData !== void 0 && (_vm$loanData9$appData2 = _vm$loanData9$appData.loanDetails) !== null && _vm$loanData9$appData2 !== void 0 && _vm$loanData9$appData2.settlement ? _c('el-row', {
    staticClass: "mb-4",
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_ORIGINATION_FEE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-broker-fee"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.settlement.brokerFee >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.brokerFee) : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_PARTNER_REFERRAL_FEE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-partner-fin-ref-fee"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.settlement.partnerFinanceReferralFee >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.partnerFinanceReferralFee) : _vm.LABEL_NOT_AVAILABLE))])])])], 1) : _vm._e(), _vm._v(" "), (_vm$loanData10 = _vm.loanData) !== null && _vm$loanData10 !== void 0 && (_vm$loanData10$appDat = _vm$loanData10.appData) !== null && _vm$loanData10$appDat !== void 0 && (_vm$loanData10$appDat2 = _vm$loanData10$appDat.loanDetails) !== null && _vm$loanData10$appDat2 !== void 0 && _vm$loanData10$appDat2.settlement ? _c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_LENDING_COMMISSION))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "ps-saved-lending-commission"
    }
  }, [_vm._v(_vm._s(_vm.loanData.appData.loanDetails.settlement.lendingCommision >= 0 ? _vm.numberWithCommas(_vm.loanData.appData.loanDetails.settlement.lendingCommision) : _vm.LABEL_NOT_AVAILABLE))])])])], 1) : _vm._e()], 1)])], 1), _vm._v(" "), _c('LoanDetailsFormDialog', {
    attrs: {
      "open-dialog": _vm.loanDetailsFormDialog,
      "shared-data": _vm.loanData.appData
    }
  })] : _vm._e(), _vm._v(" "), _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isDialogLoading,
      expression: "isDialogLoading"
    }],
    staticClass: "title-hide",
    attrs: {
      "visible": _vm.preSettlementDailog,
      "show-close": false,
      "width": "26%",
      "center": "",
      "data-selector": "ps-save-modal"
    },
    on: {
      "update:visible": function ($event) {
        _vm.preSettlementDailog = $event;
      }
    }
  }, [_c('div', {
    staticClass: "el-dialog__header custom-dialog-header mt-2"
  }, [_c('img', {
    attrs: {
      "src": require("assets/icons/warning-employement.svg"),
      "alt": "Warning"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "text-black mt-2 font-extrabold",
    attrs: {
      "data-selector": "ps-sm-title"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_SAVE_PRE_SETTLEMENT))])]), _vm._v(" "), _c('p', {
    staticClass: "text-sm text-center",
    staticStyle: {
      "word-break": "break-word"
    },
    attrs: {
      "data-selector": "ps-sm-subtitle"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ARE_YOU_SURE_YOU_WANT_TO_SAVE_PRE_SETTLEMENT))]), _vm._v(" "), _c('div', {
    staticClass: "dialog-footer w-full flex justify-between",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "text",
      "data-selector": "ps-sm-cancel-btn"
    },
    on: {
      "click": function ($event) {
        _vm.preSettlementDailog = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))]), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto sm",
    attrs: {
      "type": "primary",
      "loading": _vm.submitBtnLoading,
      "data-selector": "ps-sm-yes-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.preSettleApplication();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_YES_PROCEED))])], 1)]), _vm._v(" "), _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isDialogLoading,
      expression: "isDialogLoading"
    }],
    attrs: {
      "visible": _vm.shouldDisplayMissingDocDialog,
      "show-close": false,
      "width": "460px",
      "center": ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.shouldDisplayMissingDocDialog = $event;
      }
    }
  }, [_c('div', {
    staticClass: "el-dialog__header custom-dialog-header"
  }, [_c('img', {
    attrs: {
      "src": require("assets/icons/warning-employement.svg"),
      "alt": "Warning"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "el-dialog__title mt-2 font-extrabold"
  }, [_vm._v(_vm._s(((_vm.LABEL_FOR_STATUS_UPDATE) + " | " + (_vm.LABEL_FOR_MISSING_DOCUMENTS))))])]), _vm._v(" "), _c('p', {
    staticClass: "text-sm mb-4 text-center",
    staticStyle: {
      "word-break": "break-word"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.LABEL_FOR_MISSING_DOCUMENTS_DESCRIPTION)
    }
  }), _vm._v(" "), _c('ul', {
    staticClass: "mt-4 missingDocments",
    staticStyle: {
      "background": "rgba(19, 181, 232, 0.1)",
      "padding": "0.5rem",
      "width": "420px"
    }
  }, _vm._l(_vm.missingDocuments, function (missingDocument) {
    return _c('li', {
      key: missingDocument,
      staticClass: "description text-sm mt-2 break-normal",
      staticStyle: {
        "text-align": "left"
      }
    }, [_c('b', [_vm._v(" " + _vm._s(missingDocument))])]);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "dialog-footer w-full flex justify-between",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": function ($event) {
        _vm.areRequiredDocsUploadedBeforeSettlement = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))]), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.closeMissingDocDialogAndRedictToDocumentTab();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_GO_TO_DOCUMENTS))])], 1)]), _vm._v(" "), _vm.loanData.appData.status !== _vm.APPLICATION_STATUS_SETTLED && _vm.loanData.appData.status !== _vm.APPLICATION_STATUS_PRE_SETTLEMENT ? _c('el-row', {
    staticClass: "mt-6 mb-8 justify-end flex"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary",
      "loading": _vm.submitBtnLoading
    },
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_SAVE_PRE_SETTLEMENT))])], 1) : _vm._e(), _vm._v(" "), _c('LenderDialog', {
    attrs: {
      "open-dialog": _vm.openLenderDialog,
      "shared-data": (_vm$loanData11 = _vm.loanData) === null || _vm$loanData11 === void 0 ? void 0 : _vm$loanData11.appData
    }
  }), _vm._v(" "), _c('AssetDetailsView', {
    attrs: {
      "dialog-data": _vm.loanData.appData,
      "visible-sync": _vm.assetViewDialog
    }
  }), _vm._v(" "), _c('CpdSendConfirmDialog', {
    attrs: {
      "dialog-opened": _vm.openSendProposalConfirmation,
      "cpd-url": _vm.cpdUrl,
      "is-pre-settlement": _vm.isPreSettlement,
      "is-settlement": _vm.isSettlement,
      "is-pre-settlement-update": _vm.isPreSettlementUpdate,
      "submit-button-label": _vm.SAVE_AND_SEND_LABEL
    }
  }), _vm._v(" "), _c('MissingDocumntDialog', _vm._b({
    attrs: {
      "dialog-opened": _vm.ShouldDisplayMissingDocumentDialog,
      "err-message": _vm.errMessage
    },
    on: {
      "closeMissingDocDialog": _vm.closeMissingDocDialog
    }
  }, 'MissingDocumntDialog', Object.assign({}, _vm.$props), false)), _vm._v(" "), _vm.isFromSettlementTab ? _c('tab-footer', {
    attrs: {
      "save-btn-label": _vm.LABEL_NEXT,
      "show-back-button": true,
      "show-next-button": false
    },
    on: {
      "goBack": function ($event) {
        return _vm.goBack();
      },
      "submitForm": function ($event) {
        return _vm.goNext(_vm.SETTLEMENT_MENU_LOAN_WARRANTY_AND_INSURANCE_KEY);
      }
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }