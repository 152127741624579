const AUSTRALIAN_COUNTRY_CODE = '61'

export const numbersOnly = (str = '') =>
  String(str)
    .split('')
    .filter((v) => /\d/.test(v))
    .join('')

export const mobileWithoutCountryCode = (mobile_ = '') => {
  const mobile = numbersOnly(mobile_)
  const startCountryCodeRegex = /^[+]?61/
  if (startCountryCodeRegex.test(mobile)) {
    const [startStr] = startCountryCodeRegex.exec(mobile)
    return mobile.slice(startStr.length)
  }
  return mobile
}

export const mobileWithCountryCode = (mobile_ = '') => {
  let mobile = mobileWithoutCountryCode(mobile_)
  mobile = mobile.startsWith('0') ? mobile.slice(1) : mobile
  return `${AUSTRALIAN_COUNTRY_CODE}${mobile}`
}
