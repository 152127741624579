var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }],
    staticClass: "modal-income loan-details title-hide",
    attrs: {
      "width": "32%",
      "close-on-click-modal": false,
      "visible": _vm.openDialog,
      "destroy-on-close": "",
      "close-on-press-escape": false,
      "data-selector": "warranty-edit-modal"
    },
    on: {
      "update:visible": function ($event) {
        _vm.openDialog = $event;
      }
    }
  }, [_c('div', [_vm.loanDetailsForm ? [_c('div', {
    staticClass: "application-form"
  }, [_c('el-form', {
    ref: "warrantyData",
    attrs: {
      "model": _vm.warrantyData,
      "rules": _vm.rules
    }
  }, [_c('el-card', {
    staticClass: "card-child-p-0 pb-4",
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_ADD_WARRANTY))])])]), _vm._v(" "), _vm.warrantyData.loanDetails.settlement ? _c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.settlement.warranty.provider",
      "label": _vm.LABEL_FOR_PROVIDER,
      "data-selector": "wem-provider-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.warrantyData.loanDetails.settlement.warranty.provider,
      callback: function ($$v) {
        _vm.$set(_vm.warrantyData.loanDetails.settlement.warranty, "provider", $$v);
      },
      expression: "\n                    warrantyData.loanDetails.settlement.warranty.provider\n                  "
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "wem-provider-options-container"
    }
  }, _vm._l(_vm.PROVIDERS, function (data) {
    return _c('el-option', {
      key: data._id,
      attrs: {
        "label": data.businessName,
        "value": data._id
      }
    });
  }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.settlement.warranty.product",
      "label": _vm.LABEL_FOR_PRODUCT,
      "data-selector": "wem-product-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "data-selector": "wem-product-input"
    },
    model: {
      value: _vm.warrantyData.loanDetails.settlement.warranty.product,
      callback: function ($$v) {
        _vm.$set(_vm.warrantyData.loanDetails.settlement.warranty, "product", $$v);
      },
      expression: "\n                    warrantyData.loanDetails.settlement.warranty.product\n                  "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.settlement.warranty.wholesale",
      "label": _vm.LABEL_FOR_WHOLESALE,
      "data-selector": "wem-wholesale-input-container"
    }
  }, [_c('formatted-numeric-input', {
    attrs: {
      "data-selector": "wem-wholesale-input"
    },
    on: {
      "input": _vm.calculateCommission
    },
    model: {
      value: _vm.warrantyData.loanDetails.settlement.warranty.wholesale,
      callback: function ($$v) {
        _vm.$set(_vm.warrantyData.loanDetails.settlement.warranty, "wholesale", $$v);
      },
      expression: "\n                    warrantyData.loanDetails.settlement.warranty.wholesale\n                  "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.settlement.warranty.retail",
      "label": _vm.LABEL_FOR_RETAIL,
      "data-selector": "wem-retail-input-container"
    }
  }, [_c('formatted-numeric-input', {
    attrs: {
      "data-selector": "wem-retail-input"
    },
    on: {
      "input": _vm.calculateCommission
    },
    model: {
      value: _vm.warrantyData.loanDetails.settlement.warranty.retail,
      callback: function ($$v) {
        _vm.$set(_vm.warrantyData.loanDetails.settlement.warranty, "retail", $$v);
      },
      expression: "\n                    warrantyData.loanDetails.settlement.warranty.retail\n                  "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.settlement.warranty.commission",
      "label": _vm.LABEL_FOR_COMMISSION,
      "data-selector": "wem-commission-input-container"
    }
  }, [_c('formatted-numeric-input', {
    attrs: {
      "disabled": "",
      "data-selector": "wem-commission-input"
    },
    model: {
      value: _vm.warrantyData.loanDetails.settlement.warranty.commission,
      callback: function ($$v) {
        _vm.$set(_vm.warrantyData.loanDetails.settlement.warranty, "commission", $$v);
      },
      expression: "\n                    warrantyData.loanDetails.settlement.warranty.commission\n                  "
    }
  })], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "mb-0 border-t border-light-grey pt-4 child-w-full flex items-center"
  }, [_c('el-button', {
    staticClass: "mr-auto",
    attrs: {
      "plain": "",
      "type": "text",
      "data-selector": "wem-cancel-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CANCEL_BUTTON))]), _vm._v(" "), _c('el-button', {
    attrs: {
      "plain": "",
      "type": "danger",
      "data-selector": "wem-remove-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm(true);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_REMOVE))]), _vm._v(" "), _c('el-button', {
    attrs: {
      "type": "primary",
      "data-selector": "wem-update-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm(false);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_UPDATE) + "\n            ")])], 1)], 1)], 1)] : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }