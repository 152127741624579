//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getSignedURL } from '~/utils/url.js'
import { handleCreditScore } from '../../helpers/creditScore'
import {
  CREDIT_HISTORY_TYPES_0_499,
  CREDIT_HISTORY_TYPES_500_599,
  CREDIT_HISTORY_TYPES_600_699,
  CREDIT_HISTORY_TYPES_700,
  LABEL_FOR_COMPREHENSIVE,
  LABEL_FOR_ONE_SCORE,
  LABEL_LAST_ENQUIRY,
} from '~/constants/applications'
import {
  LABEL_FOR_MONTHS,
  LABEL_CREDIT_SCORES,
  LABEL_RHI_STATUS,
  LABEL_DEFAULTS,
  LABEL_ENQUIRIES,
  LABEL_FILE_AGE,
  LABEL_VEDA,
  LABEL_FROM_APPLICANT,
  LABEL_CREDIT_FILE_AGE,
  LABEL_CREDIT_ENQUIRIES,
  LABEL_FOR_MONTH,
} from '~/constants'

import {
  CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_AVERAGE,
  CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_EXCELLENT,
  CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_FAIR,
  CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_POOR,
  CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_UNSURE,
} from '@/constants/referrals'

import { getFullName } from '@/utils/fullname'

import {
  differenceInCalendarYears,
  differenceInCalendarMonths,
  format,
} from 'date-fns'

export default {
  data() {
    return {
      LABEL_FOR_COMPREHENSIVE,
      LABEL_FOR_ONE_SCORE,
      LABEL_FOR_MONTHS,
      LABEL_CREDIT_SCORES,
      LABEL_RHI_STATUS,
      LABEL_DEFAULTS,
      LABEL_ENQUIRIES,
      LABEL_FILE_AGE,
      LABEL_VEDA,
      getFullName,
      applicantInfo: this.isprimary
        ? this.applicantData?.primaryApplicant
        : this.applicantData?.applicant,

      format,
      LABEL_LAST_ENQUIRY,
      LABEL_FROM_APPLICANT,
      CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_AVERAGE,
      CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_EXCELLENT,
      CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_FAIR,
      CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_POOR,
      CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_UNSURE,
      CREDIT_HISTORY_TYPES_0_499,
      CREDIT_HISTORY_TYPES_500_599,
      CREDIT_HISTORY_TYPES_600_699,
      CREDIT_HISTORY_TYPES_700,
      LABEL_CREDIT_FILE_AGE,
      LABEL_CREDIT_ENQUIRIES,
      LABEL_FOR_MONTH,
    }
  },
  props: {
    applicantData: {
      type: Object,
      default: () => null,
    },
    isprimary: {
      type: Boolean,
      default: true,
    },
    isConsumer: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    applicantName() {
      return getFullName(this.applicantData)
    },
    getFileAge(data) {
      if (data) {
        const y = Math.floor(data / 12) // whole years
        const m = Math.floor(data % 12) // treat remainder as fraction of a year
        return `${y} years ${parseInt(m)} months`
      }
      return `--`
    },
    totalMonths(data) {
      let totalMonths = 0
      if (data[0]) {
        const years = (data[0].atYears || 0) * 12
        const months = data[0].atAdditionalMonths || 0
        totalMonths = years + months
      } else {
        totalMonths = 0
      }
      return totalMonths.toFixed()
    },
    getAge(data) {
      if (data?.dateOfBirth) {
        let year = differenceInCalendarYears(
          new Date(),
          new Date(data?.dateOfBirth)
        )

        let month =
          differenceInCalendarMonths(new Date(), new Date(data?.dateOfBirth)) -
          year * 12
        if (0 > month) {
          if (0 < year) {
            year = year - 1
            month = 12 + month
          }
        }

        return `${year} years ${month} months`
      }
      return `--`
    },
    previewFile(url) {
      window.open(getSignedURL(url, this.$auth), '_blank')
    },
    getCreditScore(val) {
      return handleCreditScore(val)
    },

    getCreditScoreFromApplicant() {
      const creditScoreFromApplicant =
        this.applicantData?.customerSuppliedCreditRatingDepiction
      if (
        creditScoreFromApplicant ===
        CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_EXCELLENT
      ) {
        return CREDIT_HISTORY_TYPES_700
      } else if (
        creditScoreFromApplicant ===
        CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_AVERAGE
      ) {
        return CREDIT_HISTORY_TYPES_600_699
      } else if (
        creditScoreFromApplicant ===
        CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_FAIR
      ) {
        return CREDIT_HISTORY_TYPES_500_599
      } else if (
        creditScoreFromApplicant ===
        CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_POOR
      ) {
        return CREDIT_HISTORY_TYPES_0_499
      } else if (
        creditScoreFromApplicant ===
        CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_UNSURE
      ) {
        return 'Not sure'
      } else {
        return '--'
      }
    },

    sortCreditEnquiries(creditEnquiries) {
      if (!creditEnquiries || !creditEnquiries?.length) {
        return
      }
      return [...creditEnquiries]
        .filter(({ isError }) => !isError)
        .sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )?.[0]
    },
  },
}
