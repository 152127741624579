//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  LABEL_LOAN_SEND_PROPSAL,
  LABEL_LOAN_SUBMIT_APPLICATION,
  LABEL_YES_PROCEED,
  LABEL_CANCEL,
} from '@/constants/applications'

export default {
  name: 'LoanDetails',
  props: {
    dialogOpened: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => null,
    },
    subtitle: {
      type: String,
      default: () => null,
    },
    type: {
      type: String,
      default: () => null,
    },
    netValue: {
      type: Number,
      default: () => null,
    },
    loadingDialog: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      LABEL_LOAN_SEND_PROPSAL,
      LABEL_LOAN_SUBMIT_APPLICATION,
      LABEL_YES_PROCEED,
      LABEL_CANCEL,
      getIsDialogLoading: false,
    }
  },
  methods: {
    closeDialog() {
      this.$nuxt.$emit('close-alert-dialog', this.type)
    },
    handleClick() {
      console.log('click')
      this.$nuxt.$emit('alert-submit-event-click')
    },
  },
}
