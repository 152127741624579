var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "visible": _vm.dialogOpened,
      "width": "460px",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "destroy-on-close": "",
      "show-close": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogOpened = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function ($event) {
              return _vm.closeDialog();
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))]), _vm._v(" "), _c('el-button', {
          staticStyle: {
            "width": "155px"
          },
          attrs: {
            "type": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.handleClick();
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_GO_TO_CONTACT_LIST))])];
      },
      proxy: true
    }])
  }, [_c('el-form', [_c('div', {
    staticClass: "text-center"
  }, [_c('h3', {
    staticClass: "title text-sm"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/warning-employement.svg")
    }
  })]), _vm._v(" "), true ? _c('h3', {
    staticClass: "title text-sm"
  }, [_vm._v(_vm._s(_vm.TITLE_FOR_UPDATE_BUSINESS_STRUCTURE))]) : _vm._e(), _vm._v(" "), _c('p', {
    staticClass: "description text-sm mt-2 break-normal"
  }, [_vm._v(_vm._s(_vm.DESCRIPTION_FOR_UPDATE_BUSINESS_STRUCTURE_FIRST_PART)), _c('b', [_vm._v(_vm._s(_vm.oldBusinessStructure))]), _vm._v(" to\n        "), _c('b', [_vm._v(_vm._s(_vm.newBusinessStructure))])]), _vm._v(" "), _c('p', {
    staticClass: "description text-sm mt-2 break-normal",
    domProps: {
      "innerHTML": _vm._s(_vm.businessStructureDescription)
    }
  })])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }