var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dropdown', {
    attrs: {
      "trigger": "click"
    }
  }, [_c('span', {
    class: _vm.dropdownIconParentClass,
    attrs: {
      "type": _vm.dropdownType
    }
  }, [_c('i', {
    class: ((_vm.iconClassName) + " " + (_vm.iconColor))
  })]), _vm._v(" "), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', {
    class: _vm.dropdownItemClass
  }, [_vm._l(_vm.dropDownItems, function (item, i) {
    return [_c('p', {
      key: i,
      class: item.className,
      on: {
        "click": function ($event) {
          return _vm.onDropdownItemClick(item);
        }
      }
    }, [_vm._v("\n          " + _vm._s(item.label))])];
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }