export const yearOption = (num) => ({
  value: num,
  label: `${num} ${num === 1 ? 'year' : 'years'}`,
})

export const yearViewOption = (num) => {
  return num === 1 ? num + ' year' : num + ' years'
}

export const generateOptionsFromTypesWhereKeyIsSameAsValue = (data) => {
  return data.map((row, index) => {
    return {
      key: index,
      value: row.value,
      label: row.label,
    }
  })
}

export const generateOptionsWithSameValueAndLabel = (data) => {
  return data.map((row, index) => {
    return {
      key: index,
      value: row,
      label: row,
    }
  })
}

export const generateOptionsFromTypesWhereTheKeyIsSameAsValue = (data) => {
  return data.map((val, index) => {
    return {
      key: index,
      value: val,
      label: val,
    }
  })
}
