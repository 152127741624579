var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "md": 6,
      "lg": 4
    }
  }, [_c('el-menu', {
    staticClass: "el-menu-vertical-demo subtab tab-side-menu",
    attrs: {
      "default-active": _vm.activeMenu,
      "unique-opened": true,
      "default-openeds": [_vm.activeMenu > 0 ? _vm.activeMenu : _vm.defaultOpenMenu],
      "mode": "vertical"
    },
    on: {
      "open": _vm.setActiveSubMenu
    }
  }, [_c('el-menu-item-group', {
    attrs: {
      "data-selector": "applicant-nav-group_0"
    }
  }, [_vm.userDetails ? _c('el-submenu', {
    attrs: {
      "index": "1",
      "data-selector": "expand-trigger_0"
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('p', {
    staticClass: "font-extrabold text-primary text-sm applicant-name",
    attrs: {
      "data-selector": "applicant-name_0"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.userDetails.firstName) + "\n              "), _vm.isConsumer ? _c('span', {
    staticClass: "w-full inline-block text-sm font-normal"
  }, [_vm._v(_vm._s(_vm.applicationDetails.primaryApplicantData.applicantType))]) : _vm._e()])]), _vm._v(" "), _c('el-menu-item-group', [_vm._l(_vm.SUBTABS, function (item) {
    return _c('el-menu-item', {
      key: item.keyId,
      class: 'px-0 primary_' + item.keyId,
      attrs: {
        "index": 'primary_' + item.keyId,
        "data-selector": 'tab-' + item.keyId + '-container'
      }
    }, [_c('el-tabs', {
      staticClass: "px-0",
      on: {
        "tab-click": function (event) {
          return _vm.handleClickSubTabs(event, 'primary');
        }
      },
      model: {
        value: _vm.activePrimarySubTab,
        callback: function ($$v) {
          _vm.activePrimarySubTab = $$v;
        },
        expression: "activePrimarySubTab"
      }
    }, [_c('el-tab-pane', {
      attrs: {
        "label": item.label,
        "name": 'primary_' + item.keyId
      }
    })], 1)], 1);
  }), _vm._v(" "), _vm.isConsumer ? _c('el-col', {
    staticClass: "py-4 border-t text-center"
  }, [_c('el-button', {
    staticClass: "font-extrabold center",
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus",
      "plain": "",
      "disabled": _vm.disableAddEntityButton
    },
    on: {
      "click": _vm.openSecondApplicantDialog
    }
  }, [_vm._v(_vm._s(_vm.getAddEntitiesBtnName))])], 1) : _vm._e()], 2)], 2) : _vm._e()], 1), _vm._v(" "), _vm.hasSecondaryApplicant ? _c('el-menu-item-group', {
    attrs: {
      "data-selector": "applicant-nav-group-rest"
    }
  }, _vm._l(_vm.secondaryApplicants, function (secondaryApplicant, i) {
    return _c('el-submenu', {
      key: i,
      attrs: {
        "index": _vm.getSecondaryTabSubMenuIndex(i),
        "data-selector": ("expand-trigger_" + (i + 1))
      }
    }, [_c('template', {
      slot: "title"
    }, [_c('p', {
      staticClass: "font-extrabold text-primary text-sm applicant-name",
      attrs: {
        "data-selector": ("applicant-name_" + (i + 1))
      }
    }, [_vm._v("\n              " + _vm._s(secondaryApplicant.applicant.firstName) + "\n              "), _vm.isConsumer ? _c('span', {
      staticClass: "w-full inline-block text-sm font-normal"
    }, [_vm._v(_vm._s(secondaryApplicant.applicantType))]) : _vm._e()])]), _vm._v(" "), _vm.isConsumer ? _c('el-menu-item-group', _vm._l(_vm.APPLICATIONS_SECONDARY_SUBTAB, function (item) {
      return _c('el-menu-item', {
        key: item.keyId,
        staticClass: "px-0",
        attrs: {
          "index": 'secondary_' + _vm.getSecondaryTabSubMenuIndex(i) + '_' + item.keyId
        }
      }, [_c('el-tabs', {
        staticClass: "px-0",
        on: {
          "tab-click": function (event) {
            return _vm.handleClickSubTabs(event, 'secondary', i);
          }
        },
        model: {
          value: _vm.activeSecondarySubTab,
          callback: function ($$v) {
            _vm.activeSecondarySubTab = $$v;
          },
          expression: "activeSecondarySubTab"
        }
      }, [_c('el-tab-pane', {
        attrs: {
          "label": item.label,
          "name": 'secondary_' + _vm.getSecondaryTabSubMenuIndex(i) + '_' + item.keyId
        }
      })], 1)], 1);
    }), 1) : _vm._e(), _vm._v(" "), !_vm.isConsumer ? _c('el-menu-item-group', _vm._l(_vm.SUBTABS, function (item) {
      return _c('el-menu-item', {
        key: item.keyId,
        class: 'px-0 secondary_' + _vm.getSecondaryTabSubMenuIndex(i) + '_' + item.keyId,
        attrs: {
          "index": 'secondary_' + _vm.getSecondaryTabSubMenuIndex(i) + '_' + item.keyId,
          "data-selector": 'tab-' + item.keyId + '-container'
        }
      }, [_c('el-tabs', {
        staticClass: "px-0",
        on: {
          "tab-click": function (event) {
            return _vm.handleClickSubTabs(event, 'secondary', i);
          }
        },
        model: {
          value: _vm.activeSecondarySubTab,
          callback: function ($$v) {
            _vm.activeSecondarySubTab = $$v;
          },
          expression: "activeSecondarySubTab"
        }
      }, [_c('el-tab-pane', {
        attrs: {
          "label": item.label,
          "name": 'secondary_' + _vm.getSecondaryTabSubMenuIndex(i) + '_' + item.keyId
        }
      })], 1)], 1);
    }), 1) : _vm._e()], 2);
  }), 1) : _vm._e(), _vm._v(" "), _vm.showAddEntityButton && !_vm.isConsumer ? _c('el-col', {
    staticClass: "py-4 border-t text-center"
  }, [_c('el-button', {
    staticClass: "font-extrabold center",
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus",
      "plain": "",
      "disabled": _vm.disableAddEntityButton,
      "data-selector": "c-add-applicant-btn"
    },
    on: {
      "click": _vm.openSecondApplicantDialog
    }
  }, [_vm._v(_vm._s(_vm.getAddEntitiesBtnName))])], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c('el-col', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "tab-menu-content",
    attrs: {
      "xs": 24,
      "md": 18,
      "lg": 20
    }
  }, [!_vm.loading ? _c(_vm.getActiveSubTabComponent, _vm._b({
    tag: "component"
  }, 'component', Object.assign({}, _vm.$props, {
    activeMenu: _vm.activeMenu,
    activeSubTab: _vm.activeSubTab,
    activeSecondSubTab: _vm.activeSecondarySubTab,
    activeApplicantIndex: _vm.activeApplicantIndex
  }), false)) : _vm._e()], 1), _vm._v(" "), _c('add-secondary-applicant-dialog', {
    attrs: {
      "application-details": _vm.applicationDetails,
      "is-consumer": _vm.isConsumer,
      "page-title": _vm.addSecondaryApplicantDialogTitle,
      "shared-data": _vm.sharedData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }