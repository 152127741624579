import {
  APPLICANT_STATE_NSW_VALUE,
  APPLICANT_STATE_VIC_VALUE,
  APPLICANT_STATE_QLD_VALUE,
  APPLICANT_STATE_WA_VALUE,
  APPLICANT_STATE_SA_VALUE,
  APPLICANT_STATE_ACT_VALUE,
  APPLICANT_STATE_TAS_VALUE,
  APPLICANT_STATE_NT_VALUE,
  LABEL_AUSTRALIA,
} from '../constants/applications'
import { ERROR_MESSAGE_FOR_STATE } from '@/constants'
export const postcodeToState = (postcode = '') => {
  if (!postcode) {
    return undefined
  }
  // eslint-disable-next-line eqeqeq
  if (Number(postcode) != postcode) {
    return undefined
  }
  postcode = Number(postcode)

  // NSW
  if (postcode >= 1000 && postcode <= 1999) {
    return APPLICANT_STATE_NSW_VALUE
  }
  if (postcode >= 2000 && postcode <= 2599) {
    return APPLICANT_STATE_NSW_VALUE
  }
  if (postcode >= 2619 && postcode <= 2899) {
    return APPLICANT_STATE_NSW_VALUE
  }
  if (postcode >= 2921 && postcode <= 2999) {
    return APPLICANT_STATE_NSW_VALUE
  }

  // ACT
  // range here is actually 0200—0299 (LVRs and PO Boxes only)
  if (postcode >= 200 && postcode <= 299) {
    return APPLICANT_STATE_ACT_VALUE
  }
  if (postcode >= 2600 && postcode <= 2618) {
    return APPLICANT_STATE_ACT_VALUE
  }
  if (postcode >= 2900 && postcode <= 2920) {
    return APPLICANT_STATE_ACT_VALUE
  }

  // VIC
  if (postcode >= 3000 && postcode <= 3999) {
    return APPLICANT_STATE_VIC_VALUE
  }
  if (postcode >= 8000 && postcode <= 8999) {
    return APPLICANT_STATE_VIC_VALUE
  }

  // QLD
  if (postcode >= 4000 && postcode <= 4999) {
    return APPLICANT_STATE_QLD_VALUE
  }
  if (postcode >= 9000 && postcode <= 9999) {
    return APPLICANT_STATE_QLD_VALUE
  }

  // SA
  if (postcode >= 5000 && postcode <= 5799) {
    return APPLICANT_STATE_SA_VALUE
  }
  if (postcode >= 5800 && postcode <= 5999) {
    return APPLICANT_STATE_SA_VALUE
  }

  // WA
  if (postcode >= 6000 && postcode <= 6797) {
    return APPLICANT_STATE_WA_VALUE
  }
  if (postcode >= 6800 && postcode <= 6999) {
    return APPLICANT_STATE_WA_VALUE
  }

  // TAS
  if (postcode >= 7000 && postcode <= 7799) {
    return APPLICANT_STATE_TAS_VALUE
  }
  if (postcode >= 7800 && postcode <= 7999) {
    return APPLICANT_STATE_TAS_VALUE
  }

  // NT
  // actual range 0800—0899
  if (postcode >= 800 && postcode <= 899) {
    return APPLICANT_STATE_NT_VALUE
  }
  // actual range 0900—0999
  if (postcode >= 900 && postcode <= 999) {
    return APPLICANT_STATE_NT_VALUE
  }

  return undefined
}
export const stateValidation = (country) => {
  if (country === LABEL_AUSTRALIA) {
    return [
      {
        required: true,
        message: ERROR_MESSAGE_FOR_STATE,
        trigger: ['blur', 'change'],
      },
    ]
  } else {
    return []
  }
}
export const setState = (addressesData) => {
  if (addressesData?.country !== LABEL_AUSTRALIA) {
    addressesData.state = ''
  } else {
    addressesData.state = APPLICANT_STATE_NSW_VALUE
  }
}
