// import Vue from 'vue'
import {
  USER_SESSION_DATA_KEY,
  USER_TYPE_ADMIN,
  USER_TYPE_REFERRER,
  PERMITTED_AGENT_TYPES,
  USER_TYPE_SUPER_ADMIN,
} from '@/constants'
import { validateUserRoles } from '~/helpers/user'

export default function ({ $auth }, inject) {
  inject('authHelpers', {
    get $authUser() {
      return $auth.$storage.getUniversal(USER_SESSION_DATA_KEY)
    },
    get isUserAgent() {
      return validateUserRoles(
        this.$authUser?.systemRoleTypes,
        PERMITTED_AGENT_TYPES
      )
    },
    get isUserAdmin() {
      return validateUserRoles(this.$authUser?.systemRoleTypes, [
        USER_TYPE_ADMIN,
      ])
    },
    get isUserReferrer() {
      return validateUserRoles(this.$authUser?.systemRoleTypes, [
        USER_TYPE_REFERRER,
      ])
    },
    get isUserSuperAdmin() {
      return validateUserRoles(this.$authUser?.systemRoleTypes, [
        USER_TYPE_SUPER_ADMIN,
      ])
    },
  })
}
