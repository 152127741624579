//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SAVE_LABEL, SAVE_AND_NEXT_LABEL } from '~/constants/applications'
export default {
  props: {
    saveBtnLabel: {
      type: String,
      default: SAVE_LABEL,
    },
    saveAndNextBtnLabel: {
      type: String,
      default: SAVE_AND_NEXT_LABEL,
    },
    saveBtnLoading: {
      type: Boolean,
      default: false,
    },
    saveAndNextBtnLoading: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    showSaveBtn: {
      type: Boolean,
      default: true,
    },
    showNextButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      SAVE_LABEL,
      SAVE_AND_NEXT_LABEL,
    }
  },
  methods: {
    submitForm() {
      this.$emit('submitForm', { gotoNext: false })
    },
    saveAndNext() {
      this.$emit('saveAndNext', { gotoNext: true })
    },
    goBack() {
      this.$emit('goBack')
    },
  },
}
