//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  SUBTAB_PRIMARY_PERSONAL_KEY,
  APPLICATIONS_PRIMARY_SUBTAB,
  APPLICATIONS_SECONDARY_SUBTAB,
  SUBTAB_SECONDARY_EMPLOYMENT_KEY,
  FINANCE_APPLICATION_TAB_APPLICANT_KEY,
  SUBTAB_SECONDAY_PERSONAL_KEY,
  APPLICATIONS_COMMERCIAL_SUBTAB,
  SUBTAB_PRIMARY_ADDRESS_KEY,
  FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
  LABEL_FOR_ADD_APPLICANT,
  LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
  LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY,
  LABEL_FOR_ADD_DIRECTOR,
  LABEL_FOR_ADD_PARTNER,
  LABEL_FOR_ADD_TRUSTEE,
  LABEL_BUSINESS_STRUCTURE_COMPANY,
  LABEL_BUSINESS_STRUCTURE_TRUST,
  LABEL_FOR_ADD_SECONDARY_APPLICANT_PARTNER,
  LABEL_FOR_ADD_SECONDARY_APPLICANT_TRUSTEE,
  LABEL_PRIMARY_STRING,
} from '~/constants/applications'
import Personal from './Subtabs/Personal.vue'
import Address from './Subtabs/Address.vue'
import AddSecondaryApplicantDialog from '../../Dialogs/AddSecondaryApplicantDialog.vue'
import PersonalSecondary from './Subtabs/PersonalSecondary.vue'
import AssetsAndLiabilitiesSubTab from './Subtabs/AssetsAndLiabilitiesSubTab.vue'
import ApplicantEmployment from '@/components/Applications/ApplicantEmployment.vue'

export default {
  components: {
    AddSecondaryApplicantDialog,
  },
  props: {
    applicationDetails: {
      type: Object,
      default: null,
    },
    vehicleDetails: {
      type: Object,
      default: null,
    },
    userDetails: {
      type: Object,
      default: null,
    },
    secondaryApplicant: {
      type: Object,
      default: null,
    },
    accountDetails: {
      type: Array,
      default: null,
    },
    vehicleRequestGroup: {
      type: Object,
      default: null,
    },
    isConsumer: {
      type: Boolean,
      default: false,
    },
    primaryData: {
      type: Object,
      default: () => ({}),
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    '$route.query.subtab'() {
      const subTabsStrings = [
        '_personal',
        '_address',
        '_assetsAndLiabilities',
        '_employment',
      ]
      if (
        subTabsStrings.some((checkString) =>
          this.$route.query.subtab?.includes(checkString)
        )
      ) {
        this.updateData()
      }
    },
  },
  computed: {
    secondaryApplicants() {
      return this.sharedData?.otherApplicants ?? []
    },
    hasSecondaryApplicant() {
      return this.applicationDetails?.otherApplicants?.length
    },
    addSecondaryApplicantDialogTitle() {
      const { businessData } = this.applicationDetails
      if (!this.isConsumer && this.hasTrustCompany) {
        return LABEL_FOR_ADD_SECONDARY_APPLICANT_TRUSTEE
      } else if (
        !this.isConsumer &&
        businessData?.businessStructure === LABEL_BUSINESS_STRUCTURE_PARTNERSHIP
      ) {
        return LABEL_FOR_ADD_SECONDARY_APPLICANT_PARTNER
      } else if (
        !this.isConsumer &&
        businessData?.businessStructure === LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY
      ) {
        return LABEL_FOR_ADD_DIRECTOR
      }
      return LABEL_FOR_ADD_APPLICANT
    },
    hasPartners() {
      const { businessData } = this.applicationDetails
      return (
        businessData?.businessStructure ===
          LABEL_BUSINESS_STRUCTURE_PARTNERSHIP && businessData?.noOfPartners > 0
      )
    },
    valueOfDirectors() {
      const { businessData } = this.applicationDetails
      if (this.hasPartners) {
        return businessData?.noOfPartners
      } else if (this.hasTrustCompany) {
        return businessData?.noOfTrusteeDirectors
      } else {
        return businessData?.noOfDirectors
      }
    },
    lengthOfContacts() {
      const noOfPrimaryApplicant = this.applicationDetails?.primaryApplicantData
        ?.primaryApplicant
        ? 1
        : null
      const noOfSecondaryApplicant =
        this.applicationDetails?.otherApplicants.length
      if (noOfPrimaryApplicant && noOfSecondaryApplicant === 0) {
        return noOfPrimaryApplicant
      }
      return noOfPrimaryApplicant + noOfSecondaryApplicant
    },
    hasNoOfDirectorsMatched() {
      return this.valueOfDirectors === this.lengthOfContacts
    },
    lessNoOfDirectors() {
      return this.valueOfDirectors >= this.lengthOfContacts
    },
    hasTrustCompany() {
      const { businessData } = this.applicationDetails
      return (
        businessData?.businessStructure === LABEL_BUSINESS_STRUCTURE_TRUST &&
        businessData?.trusteeType === LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY
      )
    },
    getAddEntitiesBtnName() {
      if (!this.isConsumer) {
        if (
          this.applicationDetails.businessData.businessStructure ===
          LABEL_BUSINESS_STRUCTURE_COMPANY
        ) {
          return LABEL_FOR_ADD_DIRECTOR
        } else if (
          this.applicationDetails.businessData.businessStructure ===
          LABEL_BUSINESS_STRUCTURE_PARTNERSHIP
        ) {
          return LABEL_FOR_ADD_PARTNER
        } else if (
          this.applicationDetails.businessData.businessStructure ===
            LABEL_BUSINESS_STRUCTURE_TRUST &&
          this.applicationDetails.businessData.trusteeType ===
            LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY
        ) {
          return LABEL_FOR_ADD_TRUSTEE
        }
      }
      return LABEL_FOR_ADD_APPLICANT
    },
    showAddEntityButton() {
      if (this.isConsumer) {
        return true
      } else {
        if (
          [
            LABEL_BUSINESS_STRUCTURE_COMPANY,
            LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
          ].includes(this.applicationDetails.businessData.businessStructure) ||
          (this.applicationDetails.businessData.businessStructure ===
            LABEL_BUSINESS_STRUCTURE_TRUST &&
            this.applicationDetails.businessData.trusteeType ===
              LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY)
        ) {
          return true
        }
      }
      return false
    },
    disableAddEntityButton() {
      if (
        ([
          LABEL_BUSINESS_STRUCTURE_COMPANY,
          LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
        ].includes(this.applicationDetails.businessData.businessStructure) ||
          (this.applicationDetails.businessData.businessStructure ===
            LABEL_BUSINESS_STRUCTURE_TRUST &&
            this.applicationDetails.businessData.trusteeType ===
              LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY)) &&
        !this.hasNoOfDirectorsMatched &&
        this.lessNoOfDirectors
      ) {
        return false
      }
      return true
    },
    secondaryApplicantData() {
      return this?.applicationDetails?.otherApplicant?.length
        ? this.applicationDetails.otherApplicant[0]
        : {}
    },
    getActiveSubTabComponent() {
      const subTab = this.activeSubTab.substring(
        this.activeSubTab.lastIndexOf('_') + 1,
        this.activeSubTab.length
      )

      if (subTab === SUBTAB_PRIMARY_PERSONAL_KEY) {
        return Personal
      } else if (subTab === SUBTAB_SECONDAY_PERSONAL_KEY) {
        return PersonalSecondary
      } else if (subTab === SUBTAB_SECONDARY_EMPLOYMENT_KEY) {
        return ApplicantEmployment
      } else if (subTab === SUBTAB_PRIMARY_ADDRESS_KEY) {
        return Address
      } else {
        return AssetsAndLiabilitiesSubTab
      }
    },
    SUBTABS() {
      return this.isConsumer
        ? APPLICATIONS_PRIMARY_SUBTAB
        : APPLICATIONS_COMMERCIAL_SUBTAB
    },
    hasOtherApplicantsInfo() {
      return this.applicationDetails?.otherApplicans?.length
    },
  },
  mounted() {
    this.updateData()
    this.$nuxt.$on('close-loader', () => {
      this.loading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('changeContactsTab')
    this.$nuxt.$off('close-loader')
  },
  data() {
    return {
      defaultOpenMenu: '1',
      activeMenu: this.applicationDetails.primaryApplicantData ? '1' : '2',
      activeSubTab: `primary_${SUBTAB_PRIMARY_PERSONAL_KEY}`,
      activePrimarySubTab: `primary_${SUBTAB_PRIMARY_PERSONAL_KEY}`,
      activeSecondarySubTab: '',
      activeApplicantIndex: 0,
      APPLICATIONS_PRIMARY_SUBTAB,
      APPLICATIONS_SECONDARY_SUBTAB,
      loading: false,
      LABEL_PRIMARY_STRING,
      subTab: this.$route.query.subtab,
    }
  },
  methods: {
    updateData() {
      const { maintab: tab, subtab: subTab } = this.$route.query
      this.activeSubTab =
        subTab ?? this.activeSubTab ?? `primary_${SUBTAB_PRIMARY_PERSONAL_KEY}`
      const isPrimary = subTab && subTab.includes('primary_')
      const index = isPrimary
        ? '1'
        : `${parseInt(subTab?.split('_')[1])}` || '1'
      this.setActiveSubMenu(index)
      if (isPrimary) {
        this.activePrimarySubTab = this.activeSubTab
      } else {
        this.activeSecondarySubTab = this.activeSubTab
      }
      this.$nuxt.$emit('changeTab', {
        tab,
        subTab:
          subTab ??
          this.activeSubTab ??
          `primary_${SUBTAB_PRIMARY_PERSONAL_KEY}`,
      })
      this.$nuxt.$on('changeContactsTab', ({ subTab, isPrimary, index }) =>
        this.handleClickSubTabs(
          { name: subTab },
          isPrimary ? 'primary' : 'secondary',
          index
        )
      )
    },
    getSecondaryTabSubMenuIndex(index) {
      return `${index + 2}`
    },
    openSecondApplicantDialog() {
      this.$nuxt.$emit('addSecondaryApplicantDialog-dialog-toggle', {
        open: true,
      })
    },
    async handleClickSubTabs(event, applicantType, index) {
      const { name } = event
      const tab = this.isConsumer
        ? FINANCE_APPLICATION_TAB_APPLICANT_KEY
        : FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY
      let subTab = name
      if (applicantType === 'primary') {
        subTab = `primary_${subTab?.split('primary_')[1] ?? subTab}`
        this.activeSubTab = this.activePrimarySubTab = subTab
        this.activeSecondarySubTab = ''
      } else {
        subTab = `secondary_${index + 2}_${subTab?.split('_')[2] ?? subTab}`
        this.activeSubTab = this.activeSecondarySubTab = subTab
      }
      this.activeApplicantIndex = index
      this.$nuxt.$emit('changeTab', {
        tab,
        subTab,
      })
      this.setActiveSubMenu(
        index === 0 && applicantType === LABEL_PRIMARY_STRING
          ? '1'
          : `${index + 2}`
      )
      await new Promise((r) => setTimeout(r, 1000))
      this.loading = false
    },
    setActiveSubMenu(index) {
      this.activeMenu = index
    },
  },
}
