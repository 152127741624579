import commonService from '~/services/common.service'
import { axiosErrorMessageExtractor } from '~/utils/error'

export default {
  async fetchFinanceApplicationDetailsByVrgAndApplicationId(
    { commit },
    { vehicleRequestGroupId, applicationId }
  ) {
    try {
      const { data: application } =
        await commonService.getFinanceApplicationDetailsByVrgAndApplicationIdAPI(
          { vehicleRequestGroupId, applicationId, ...this }
        )
      commit('setFinanceApplicationDetails', { application })
    } catch (err) {
      console.error(err)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(err),
      })
    }
  },
  async updateApplication(
    { commit },
    { vehicleRequestGroupId, applicationId, application }
  ) {
    try {
      const { data } =
        await commonService.updateFinanceApplicationDetailsByVrgAndApplicationIdAPI(
          { vehicleRequestGroupId, applicationId, application, ...this }
        )
      this.$notify.success({
        title: 'Success',
        message: 'Application updated successfully',
      })
      commit('setFinanceApplicationDetails', { application: data })
    } catch (err) {
      console.error(err)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(err),
      })
    }
  },
  reset({ commit }) {
    commit('reset')
  },
}
