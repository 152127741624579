var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "should-render": _vm.shouldRenderSidebar
    },
    on: {
      "logout": _vm.onLogout
    }
  }), _vm._v(" "), _c('el-container', {
    staticClass: "main-container"
  }, [_vm.shouldRenderSidebar ? _c('Sidebar', {
    attrs: {
      "is-collapse": _vm.isCollapse
    }
  }) : _vm._e(), _vm._v(" "), _c('el-container', {
    class: {
      'layout-wrapper': _vm.shouldRenderSidebar,
      'pl-0 layout-wrapper-top-bar': _vm.isCollapse
    }
  }, [_c('Nuxt', {
    attrs: {
      "is-collapse": _vm.isCollapse
    }
  })], 1)], 1), _vm._v(" "), !_vm.shouldRenderSidebar ? _c('footerCopyRight') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }