//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  SETTLEMENT_MENU_DOCUMENT_LABEL,
  SETTLEMENT_MENU_SELLER_DETAILS_LABEL,
  SETTLEMENT_MENU_LOAN_DETAILS_LABEL,
  SETTLEMENT_MENU_LOAN_WARRANTY_AND_INSURANCE_LABEL,
  SETTLEMENT_MENU_DOCUMENT_KEY,
  SETTLEMENT_MENU_SELLER_DETAILS_KEY,
  SETTLEMENT_MENU_LOAN_DETAILS_KEY,
  SETTLEMENT_MENU_LOAN_WARRANTY_AND_INSURANCE_KEY,
  LOAN_TYPE_PERSONAL_LOAN,
} from '@/constants/applications'
import {
  APPLICATION_STATUS_PRE_SETTLEMENT,
  APPLICATION_STATUS_SETTLED,
} from '@/constants'
import { mapGetters } from 'vuex'
import ApplicantSecondaryForm from '~/components/Applications/ApplicantSecondaryForm'
import Documents from './Documents.vue'
import LoanDetails from './LoanSettlement.vue'
import SellerDetails from './SellerDetails.vue'
import WarrentyInsurance from './Warranty-insurance.vue'
export default {
  name: 'ApplicationSubTab',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ApplicantSecondaryForm,
    Documents,
    LoanDetails,
    SellerDetails,
    WarrentyInsurance,
  },
  props: {
    primaryData: {
      type: Object,
      default: () => ({}),
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    isApplicationSettled: {
      type: Boolean,
      default: false,
    },
    isFromSettlementTab: {
      type: Boolean,
      default: true,
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeMenuIndex: '0',
      SETTLEMENT_MENU_DOCUMENT_LABEL,
      SETTLEMENT_MENU_SELLER_DETAILS_LABEL,
      SETTLEMENT_MENU_LOAN_DETAILS_LABEL,
      SETTLEMENT_MENU_LOAN_WARRANTY_AND_INSURANCE_LABEL,
      SETTLEMENT_MENU_DOCUMENT_KEY,
      activeSubTab: SETTLEMENT_MENU_DOCUMENT_KEY,
      SETTLEMENT_MENU_SELLER_DETAILS_KEY,
      SETTLEMENT_MENU_LOAN_DETAILS_KEY,
      SETTLEMENT_MENU_LOAN_WARRANTY_AND_INSURANCE_KEY,
      APPLICATION_STATUS_PRE_SETTLEMENT,
      APPLICATION_STATUS_SETTLED,
      subTabArray: [
        SETTLEMENT_MENU_DOCUMENT_KEY,
        SETTLEMENT_MENU_SELLER_DETAILS_KEY,
        SETTLEMENT_MENU_LOAN_DETAILS_KEY,
        SETTLEMENT_MENU_LOAN_WARRANTY_AND_INSURANCE_KEY,
      ],
    }
  },
  computed: {
    ...mapGetters('applications', [
      'getPrimaryApplicantData',
      'getOtherApplicants',
    ]),
    appData() {
      return { ...this.$store.getters['applications/getPrimaryApplicantData'] }
    },
    ShouldDisabledSubTab() {
      return ![
        APPLICATION_STATUS_SETTLED,
        APPLICATION_STATUS_PRE_SETTLEMENT,
      ].includes(this.sharedData?.status)
    },
    shouldDisplaySellerDetailsTab() {
      return this.sharedData.loanType !== LOAN_TYPE_PERSONAL_LOAN
    },
  },

  mounted() {
    this.$nuxt.$on('updateSettlementSubComponent', (val) => {
      if (val.includes('|')) {
        const tab = val.split('|')
        this.sectionRedirect(tab[1])
        document.getElementsByClassName(tab[1])[0]?.scrollIntoView()
      }
    })
  },

  methods: {
    async sectionRedirect(subTabName) {
      this.activeSubTab = subTabName
      this.activeMenuIndex = this.subTabArray.findIndex((x) => x === subTabName)
    },
  },
}
