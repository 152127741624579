import JSZip from 'jszip'
import JSZipUtils from '../helpers/jszip-utils'
import { BLOCKED_DOCUMENTS } from '../constants/applications'

export const zipDownload = (filesForDownload, zipFileCounter, folderName) => {
  let zip = new JSZip()
  let count = 0
  filesForDownload.map((filesForDownload) => {
    filesForDownload.map((file) => {
      if (!BLOCKED_DOCUMENTS.includes(file.purpose)) {
        return JSZipUtils.getBinaryContent(file.path, (err, data) => {
          let fileName = file.name?.split('.').slice(0, -1).join('.')

          let extention = file.name.substring(file.name.lastIndexOf('.') + 1)
          zip
            .folder(`${file.label}/${file.purpose}`)
            .file(`${fileName}-${count}.${extention}`, data, {
              binary: true,
            })
          count++
          if (zipFileCounter === 0) {
            zipFileCounter++
            setTimeout(() => {
              zip.generateAsync({ type: 'blob' }).then((content) => {
                const objectUrl = URL.createObjectURL(content)
                const link = document.createElement('a')
                link.download = `${folderName.firstName}-${folderName.lastName}-${folderName.shortId}`
                link.href = objectUrl
                link.click()
              })
            }, 2000)
          }
        })
      }
    })
  })
}
