var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "visible": _vm.shouldShowVehiclerrpDialog,
      "width": "35%",
      "center": "",
      "close-on-press-escape": false,
      "close-on-click-modal": false,
      "show-close": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.shouldShowVehiclerrpDialog = $event;
      }
    }
  }, [_c('div', {
    staticClass: "custom-dialog-header"
  }, [_c('span', {
    staticClass: "custom-header mb-6 pt-0 px-0 d-block"
  }, [_c('div', {
    staticClass: "clearfix items-center flex justify-between",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "font-bolder"
  }, [_vm._v(_vm._s(_vm.SIDEBAR_MENU_ITEM_RRP))])])])]), _vm._v(" "), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isDialogLoading,
      expression: "isDialogLoading"
    }]
  }, [_c('el-form', {
    ref: "vehicleForm",
    attrs: {
      "model": _vm.vehicleData
    }
  }, [_c('el-row', [_c('el-col', [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_MAKE,
      "prop": "makeCode",
      "rules": _vm.rules.makeCode
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    on: {
      "change": function ($event) {
        return _vm.setModels();
      },
      "focus": _vm.setMakes
    },
    model: {
      value: _vm.vehicleData.makeCode,
      callback: function ($$v) {
        _vm.$set(_vm.vehicleData, "makeCode", $$v);
      },
      expression: "vehicleData.makeCode"
    }
  }, _vm._l(_vm.make, function (data) {
    return _c('el-option', {
      key: data.makeCode,
      attrs: {
        "label": data.description,
        "value": data.makeCode
      }
    });
  }), 1)], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_MODEL,
      "prop": "familyCode",
      "rules": _vm.rules.familyCode
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "disabled": !_vm.vehicleData.makeCode,
      "placeholder": "Select"
    },
    on: {
      "change": function ($event) {
        return _vm.setYears();
      },
      "focus": function ($event) {
        return _vm.setModels(false);
      }
    },
    model: {
      value: _vm.vehicleData.familyCode,
      callback: function ($$v) {
        _vm.$set(_vm.vehicleData, "familyCode", $$v);
      },
      expression: "vehicleData.familyCode"
    }
  }, _vm._l(_vm.model_options, function (data) {
    return _c('el-option', {
      key: data.familyCode,
      attrs: {
        "label": data.description,
        "value": data.familyCode
      }
    });
  }), 1)], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_ASSET_DETAILS_YEAR,
      "prop": "year",
      "rules": _vm.rules.year
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "disabled": !_vm.vehicleData.familyCode,
      "placeholder": "Select"
    },
    on: {
      "change": function ($event) {
        return _vm.setModelTypes();
      },
      "focus": function ($event) {
        return _vm.setYears(false);
      }
    },
    model: {
      value: _vm.vehicleData.year,
      callback: function ($$v) {
        _vm.$set(_vm.vehicleData, "year", $$v);
      },
      expression: "vehicleData.year"
    }
  }, _vm._l(_vm.years_options, function (data) {
    return _c('el-option', {
      key: data,
      attrs: {
        "label": data,
        "value": data
      }
    });
  }), 1)], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_MODEL_TYPE,
      "prop": "codeDescription",
      "rules": _vm.rules.codeDescription
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "disabled": !_vm.vehicleData.year,
      "placeholder": "Select"
    },
    on: {
      "focus": function ($event) {
        return _vm.setModelTypes(false);
      },
      "change": function ($event) {
        return _vm.addVehicleDescription($event);
      }
    },
    model: {
      value: _vm.vehicleData.codeDescription,
      callback: function ($$v) {
        _vm.$set(_vm.vehicleData, "codeDescription", $$v);
      },
      expression: "vehicleData.codeDescription"
    }
  }, _vm._l(_vm.model_types_options, function (data) {
    return _c('el-option', {
      key: data.code,
      attrs: {
        "label": data.description,
        "value": data.description
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "car-area"
  }, [_c('div', {
    staticClass: "car-img"
  }, [_vm.vehicleData.imgUrl ? _c('img', {
    staticClass: "ml-auto",
    attrs: {
      "src": _vm.vehicleData.imgUrl
    }
  }) : _c('img', {
    staticClass: "ml-auto",
    attrs: {
      "src": require("@/assets/icons/vector.svg")
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "description"
  }, [_c('div', {
    staticClass: "description-text"
  }, [_c('span', {
    staticClass: "font-medium text-xs"
  }, [_vm._v(_vm._s(_vm.LABEL_RRP))]), _vm._v(" "), _c('p', {
    staticClass: "font-bolder text-success text-lg text-md",
    staticStyle: {
      "padding-top": "2px"
    }
  }, [_vm._v(_vm._s(_vm.vehicleData.tradeInAvgCustomer ? _vm.numberWithCommas(_vm.vehicleData.tradeInAvgCustomer) : _vm.LABEL_UNKNOWN_PRICE))]), _vm._v(" "), _c('span', {
    staticClass: "text-extra-small"
  }, [_vm._v(_vm._s(_vm.LABEL_RRP_DESCRIPTION))])])])]), _vm._v(" "), _c('div', {
    staticClass: "footer mt-4 pt-4 border-t border-light-grey flex"
  }, [_c('el-button', {
    staticClass: "text-md",
    nativeOn: {
      "click": function ($event) {
        return _vm.closeVehiclerrpDialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CLOSE))])], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }