var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-form-item', {
    class: _vm.formClassName,
    attrs: {
      "label": _vm.label,
      "prop": _vm.formProp,
      "rules": _vm.formRule
    }
  }, [_vm.isMultiple ? _c('el-select', {
    class: _vm.className,
    attrs: {
      "filterable": "",
      "multiple": "",
      "collapse-tags": "",
      "placeholder": _vm.placeholder,
      "disabled": _vm.disabled
    },
    on: {
      "input": _vm.onInput,
      "change": _vm.onChange,
      "blur": _vm.onBlur
    },
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": _vm.optionsContainerDs
    }
  }, _vm._l(_vm.selectOptions, function (option) {
    return _c('el-option', {
      key: option.key,
      attrs: {
        "label": option.label,
        "value": option.value
      }
    });
  }), 1)]) : _c('el-select', {
    class: _vm.className,
    attrs: {
      "filterable": "",
      "placeholder": _vm.placeholder,
      "disabled": _vm.disabled
    },
    on: {
      "input": _vm.onInput,
      "change": _vm.onChange,
      "blur": _vm.onBlur
    },
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": _vm.optionsContainerDs
    }
  }, _vm._l(_vm.selectOptions, function (option) {
    return _c('el-option', {
      key: option.key,
      attrs: {
        "label": option.label,
        "value": option.value
      }
    });
  }), 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }