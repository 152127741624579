var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "md": 6,
      "lg": 4
    }
  }, [_c('el-menu', {
    staticClass: "el-menu-vertical-demo",
    attrs: {
      "default-active": _vm.getBusinessDefaltActive()
    }
  }, [_c('el-menu-item-group', [_c('el-menu-item', {
    attrs: {
      "index": "0",
      "data-selector": "bd-details-link"
    },
    on: {
      "click": function ($event) {
        return _vm.sectionRedirect(_vm.SUBTAB_BUSINESS_DETAILS_KEY);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.SUBTAB_BUSINESS_DETAILS_LABEL))])]), _vm._v(" "), _c('el-menu-item', {
    attrs: {
      "index": "1",
      "data-selector": "bd-address-link"
    },
    on: {
      "click": function ($event) {
        return _vm.sectionRedirect(_vm.SUBTAB_PRIMARY_ADDRESS_KEY);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.SUBTAB_PRIMARY_ADDRESS_LABEL))])]), _vm._v(" "), _vm.showBeneficiaries ? _c('el-menu-item', {
    attrs: {
      "index": "2",
      "data-selector": "bd-beneficiaries-link"
    },
    on: {
      "click": function ($event) {
        return _vm.sectionRedirect(_vm.SUBTAB_BUSINESS_BENEFICIARIES_KEY);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.SUBTAB_BUSINESS_BENEFICIARIES_LABEL))])]) : _vm._e(), _vm._v(" "), _vm.isCompanyStructure ? _c('el-menu-item', {
    attrs: {
      "index": "3",
      "data-selector": "bd-shareholders-link"
    },
    on: {
      "click": function ($event) {
        return _vm.sectionRedirect(_vm.SUBTAB_BUSINESS_SHAREHOLDER_KEY);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.SUBTAB_BUSINESS_SHAREHOLDER_LABEL))])]) : _vm._e(), _vm._v(" "), _c('el-menu-item', {
    attrs: {
      "index": "4",
      "data-selector": "bd-accountant-link"
    },
    on: {
      "click": function ($event) {
        return _vm.sectionRedirect(_vm.SUBTAB_BUSINESS_ACCOUNTANT_KEY);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.SUBTAB_BUSINESS_ACCOUNTANT_LABEL))])])], 1)], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 18,
      "lg": 20
    }
  }, [_vm.activeMainTab === _vm.BUSINESS_DETAILS_TAB_KEY && (!_vm.activeSubTab || _vm.activeSubTab === _vm.SUBTAB_BUSINESS_DETAILS_KEY || _vm.activeSubTab === _vm.SUBTAB_PRIMARY_PERSONAL_KEY) ? _c('BusinessDetails', _vm._b({}, 'BusinessDetails', Object.assign({}, _vm.$props), false)) : _vm._e(), _vm._v(" "), _vm.activeSubTab === _vm.SUBTAB_PRIMARY_ADDRESS_KEY ? _c('BusinessAddress', _vm._b({}, 'BusinessAddress', Object.assign({}, _vm.$props), false)) : _vm._e(), _vm._v(" "), _vm.activeSubTab === _vm.SUBTAB_BUSINESS_ACCOUNTANT_KEY ? _c('Accountant', _vm._b({}, 'Accountant', Object.assign({}, _vm.$props), false)) : _vm._e(), _vm._v(" "), _vm.activeSubTab === _vm.SUBTAB_BUSINESS_BENEFICIARIES_KEY ? _c('CommonBenefieciaryShareHolder', {
    attrs: {
      "common-data": _vm.sharedData.businessData.beneficiaries,
      "is-beneficiary": true,
      "sharedData": _vm.sharedData
    }
  }) : _vm._e(), _vm._v(" "), _vm.activeSubTab === _vm.SUBTAB_BUSINESS_SHAREHOLDER_KEY ? _c('CommonBenefieciaryShareHolder', {
    attrs: {
      "common-data": _vm.sharedData.businessData.shareHolders,
      "is-beneficiary": false,
      "sharedData": _vm.sharedData
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }