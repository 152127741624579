import commonService from '~/services/common.service'
import { axiosErrorMessageExtractor } from '~/utils/error'

export default {
  async fetchVehicleDetailsByID({ commit }, { vehicleId }) {
    try {
      const { data: vehicle } = await commonService.getVehicleDetailsById({
        vehicleId,
        ...this,
      })
      commit('setVehicleDetails', { vehicle })
    } catch (err) {
      console.error(err)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(err),
      })
    }
  },
  reset({ commit }) {
    commit('reset')
  },
  async updateVehicleByID({ commit }, { vehicleId, vehicleData, showSuccess }) {
    try {
      const { data: vehicle } = await commonService.updateVehicleDetailsByIdAPI(
        {
          vehicleId,
          vehicleData,
          ...this,
        }
      )
      commit('setVehicleDetails', { vehicle })
      if (showSuccess) {
        this.$notify.success({
          title: 'Success',
          message: 'Vehicle details updated successfully',
        })
      }
    } catch (err) {
      console.error(err)
      this.$notify.error({
        title: 'Error',
        message: axiosErrorMessageExtractor(err),
      })
    }
  },
  async getAllMakes() {
    try {
      const { data } = await commonService.getAllMakesAPI({ ...this })
      return data
    } catch (error) {
      console.log(error)
      this.$notify.error({
        title: 'Error',
        message: 'Error in getting models :(',
      })
    }
  },
  async getAllModels({}, { makeCode }) {
    try {
      const { data } = await commonService.getAllModelsByMakeCodeAPI({
        makeCode,
        ...this,
      })
      return data
    } catch (error) {
      console.log(error)
      this.$notify.error({
        title: 'Error',
        message: 'Error in getting models :(',
      })
    }
  },
  async getAllYears({}, { makeCode, familyCode }) {
    try {
      const { data } = await commonService.getAllYearsByMakeAndFamilyCodeAPI({
        makeCode,
        familyCode,
        ...this,
      })
      return data
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: 'Error in getting models :(',
      })
    }
  },
  async getAllModelTypes({}, { makeCode, familyCode, year }) {
    try {
      const { data } = await commonService.getAllModelTypesAPI({
        makeCode,
        familyCode,
        year,
        ...this,
      })
      return data
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: 'Error in getting models types :(',
      })
    }
  },
}
