import Vue from 'vue'
import Router from 'vue-router'
import { LABEL_REFERRALS } from '@/constants'
import { REFERRAL_HOSTS } from './subdomainConfig'
import { COMMON_ROUTES } from '~/helpers/routes'
import { ACCELERATE, FUEL } from '~/constants/applications'

Vue.use(Router)

const getCommonRoutes = (defaultRoutes) => {
  return defaultRoutes.filter((route) => COMMON_ROUTES.includes(route.name))
}

const getDefaultRoutes = (defaultRoutes) => {
  return defaultRoutes.filter(
    (defaultRoute) => !defaultRoute.name.includes(LABEL_REFERRALS)
  )
}

function referralRoutes(defaultRoutes) {
  const commonRoutes = getCommonRoutes(defaultRoutes)
  const referralRoutes = defaultRoutes.filter(
    (route) =>
      route.path.includes(`/${LABEL_REFERRALS}`) ||
      route.name.includes(
        'vrgId-finance-applications-applicationId-applicants-applicantId-esignatures'
      )
  )
  const defaultRoute = defaultRoutes.find(
    (route) => route.name === LABEL_REFERRALS
  )
  return [
    ...commonRoutes,
    ...referralRoutes,
    { ...defaultRoute, name: 'referral-index', path: '/' },
  ]
}

function fixRoutes(defaultRoutes, hostname) {
  if (REFERRAL_HOSTS.includes(hostname)) {
    window[ACCELERATE] = true
    window[FUEL] = false
    return referralRoutes(defaultRoutes)
  }
  window[ACCELERATE] = false
  window[FUEL] = true
  return getDefaultRoutes(defaultRoutes)
}

export function createRouter(ssrContext, createDefaultRouter, routerOptions) {
  const options = routerOptions || createDefaultRouter(ssrContext).options
  const hostname = ssrContext ? ssrContext.req.headers.host : location.host
  return new Router({
    ...options,
    routes: fixRoutes(options.routes, hostname),
  })
}
