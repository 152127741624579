var render = function () {
  var _vm$employmentData, _vm$employmentData2, _vm$employmentData3, _vm$employmentData4, _vm$employmentData5, _vm$employmentData5$e, _vm$employmentData6, _vm$employmentData6$e;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }]
  }, [_vm.isButtonDisable ? _c('div', {
    staticClass: "alertMessage el-alert el-alert--primary text-black is-light",
    attrs: {
      "role": "alert"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/alert-triangle.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title font-bolder",
    attrs: {
      "data-selector": "employment-duration-alert"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.MESSAGE_MINIMUM_THREE_YEARS_REQUIRED))])])]) : _vm._e(), _vm._v(" "), _c('el-card', {
    staticClass: "mt-4 mb-2"
  }, [_vm.isConsumer ? _c('el-row', [_c('el-col', [_vm._v("\n        " + _vm._s(_vm.MESSAGE_IS_APPLICANT_CUURENTLY_EMPLOYED) + "\n        "), _c('el-radio-group', {
    staticClass: "pl-6",
    attrs: {
      "plain": "",
      "size": "small",
      "id": "radio-button-transition"
    },
    on: {
      "change": function ($event) {
        return _vm.employementChange();
      }
    },
    model: {
      value: _vm.currentlyEmployed,
      callback: function ($$v) {
        _vm.currentlyEmployed = $$v;
      },
      expression: "currentlyEmployed"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": "Yes",
      "data-selector": "curr-emp-yes"
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c('el-radio-button', {
    attrs: {
      "label": "No",
      "data-selector": "curr-emp-no"
    }
  }, [_vm._v("No")])], 1)], 1)], 1) : _vm._e(), _vm._v(" "), !_vm.isConsumer && !((_vm$employmentData = _vm.employmentData) !== null && _vm$employmentData !== void 0 && _vm$employmentData.employments.length) ? _c('el-row', [_c('el-col', [_c('span', {
    staticClass: "custom-header mb-6 pt-4 px-0 d-block"
  }, [_c('div', {
    staticClass: "clearfix items-center flex justify-between",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs"
  }, [_c('b', [_vm._v("Employment")]), _vm._v(" " + _vm._s(_vm.LABEL_EMPLOYMENT_OPTIONAL) + "\n            ")])])]), _vm._v("\n        " + _vm._s(_vm.LABEL_ADD_EMPLOYMENT) + " "), _c('b', [_vm._v(_vm._s(_vm.LABEL_ADD_EMPLOYMENT_SECOND) + " ")]), _vm._v(_vm._s(_vm.LABEL_ADD_EMPLOYMENT_THIRD) + "\n      ")])], 1) : _vm._e(), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ((_vm$employmentData2 = _vm.employmentData) === null || _vm$employmentData2 === void 0 ? void 0 : _vm$employmentData2.employments.length) > 0,
      expression: "employmentData?.employments.length > 0"
    }],
    staticClass: "application-form",
    attrs: {
      "data-selector": "employment-form"
    }
  }, [_c('el-form', {
    ref: "employmentData",
    staticClass: "employmentPersonal",
    attrs: {
      "model": _vm.employmentData
    }
  }, _vm._l(_vm.employmentData.employments, function (employment, index) {
    return _c('div', {
      key: index,
      attrs: {
        "data-selector": 'employment_' + index
      }
    }, [_c('span', {
      class: index == 0 ? 'px-0 d-block el-card__header' : 'el-card__header'
    }, [_c('div', {
      staticClass: "clearfix items-center flex justify-between",
      attrs: {
        "slot": "header"
      },
      slot: "header"
    }, [_c('p', {
      staticClass: "text-xs font-extrabold",
      attrs: {
        "data-selector": "header-text"
      }
    }, [_vm._v("Employment " + _vm._s(index + 1))]), _vm._v(" "), _c('el-link', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: index >= 1 || !_vm.isConsumer,
        expression: "index >= 1 || !isConsumer"
      }],
      staticClass: "font-extrabold remove-employment",
      attrs: {
        "underline": false,
        "type": "danger",
        "data-selector": 'remove-employment_' + index
      },
      on: {
        "click": function ($event) {
          return _vm.removeEmployment(index);
        }
      }
    }, [_vm._v("Remove")])], 1)]), _vm._v(" "), _c('el-row', {
      attrs: {
        "gutter": 40
      }
    }, [_c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.EMPLOYER_NAME,
        "prop": 'employments.' + index + '.employerName',
        "rules": _vm.rules.employerName,
        "data-selector": "employer-name-input-container"
      }
    }, [_c('el-input', {
      attrs: {
        "data-selector": "employer-name-input"
      },
      model: {
        value: _vm.employmentData.employments[index].employerName,
        callback: function ($$v) {
          _vm.$set(_vm.employmentData.employments[index], "employerName", $$v);
        },
        expression: "employmentData.employments[index].employerName"
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.EMPLOYER_OCCUPATION,
        "prop": 'employments.' + index + '.position',
        "rules": _vm.rules.position,
        "data-selector": "occupation-input-container"
      }
    }, [_c('el-input', {
      attrs: {
        "data-selector": "occupation-input"
      },
      model: {
        value: _vm.employmentData.employments[index].position,
        callback: function ($$v) {
          _vm.$set(_vm.employmentData.employments[index], "position", $$v);
        },
        expression: "employmentData.employments[index].position"
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.LABEL_FOR_INDUSTRY,
        "prop": 'employments.' + index + '.industry',
        "rules": _vm.rules.industry,
        "data-selector": "industry-input-container"
      }
    }, [_c('el-select', {
      attrs: {
        "filterable": "",
        "placeholder": "Select"
      },
      model: {
        value: _vm.employmentData.employments[index].industry,
        callback: function ($$v) {
          _vm.$set(_vm.employmentData.employments[index], "industry", $$v);
        },
        expression: "employmentData.employments[index].industry"
      }
    }, [_c('div', {
      attrs: {
        "data-selector": 'industry-options-container_' + index
      }
    }, _vm._l(_vm.ALL_INDUSTRY_OPTIONS, function (data) {
      return _c('el-option', {
        key: data.key,
        attrs: {
          "label": data.label,
          "value": data.value
        }
      });
    }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.EMPLOYER_STATUS,
        "prop": 'employments.' + index + '.status',
        "rules": _vm.rules.status,
        "data-selector": "status-input-container"
      }
    }, [_c('el-select', {
      attrs: {
        "filterable": "",
        "placeholder": "Select"
      },
      model: {
        value: _vm.employmentData.employments[index].status,
        callback: function ($$v) {
          _vm.$set(_vm.employmentData.employments[index], "status", $$v);
        },
        expression: "employmentData.employments[index].status"
      }
    }, [_c('div', {
      attrs: {
        "data-selector": 'status-options-container_' + index
      }
    }, [_c('el-option', {
      attrs: {
        "label": "Full Time",
        "value": "Full Time"
      }
    }), _vm._v(" "), _c('el-option', {
      attrs: {
        "label": "Part Time",
        "value": "Part Time"
      }
    }), _vm._v(" "), _c('el-option', {
      attrs: {
        "label": "Casual",
        "value": "Casual"
      }
    }), _vm._v(" "), _c('el-option', {
      attrs: {
        "label": "Contract Role",
        "value": "Contract Role"
      }
    }), _vm._v(" "), _c('el-option', {
      attrs: {
        "label": "Self Employed",
        "value": "Self Employed"
      }
    })], 1)])], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
      attrs: {
        "gutter": 40
      }
    }, [_c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 6
      }
    }, [_c('el-form-item', {
      staticClass: "mb-0",
      attrs: {
        "label": _vm.EMPLOYER_TIME_AT_EMPLOYER_YEARS,
        "prop": 'employments.' + index + '.atYears',
        "rules": _vm.rules.years,
        "data-selector": "em-years-input-container"
      }
    }, [_c('el-select', {
      attrs: {
        "filterable": "",
        "placeholder": "Select"
      },
      on: {
        "change": function ($event) {
          return _vm.SetEmploymentstartdate(_vm.employmentData.employments, index);
        }
      },
      model: {
        value: _vm.employmentData.employments[index].atYears,
        callback: function ($$v) {
          _vm.$set(_vm.employmentData.employments[index], "atYears", $$v);
        },
        expression: "employmentData.employments[index].atYears"
      }
    }, [_c('div', {
      attrs: {
        "data-selector": 'em-years-options-container_' + index
      }
    }, _vm._l(_vm.dateRange.YEAR_OPTIONS, function (data) {
      return _c('el-option', {
        key: data.value,
        attrs: {
          "label": data.label,
          "value": data.value
        }
      });
    }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 6
      }
    }, [_c('el-form-item', {
      staticClass: "mb-0",
      attrs: {
        "label": _vm.EMPLOYER_TIME_AT_EMPLOYER_MONTHS,
        "prop": 'employments.' + index + '.atAdditionalMonths',
        "rules": _vm.rules.months,
        "data-selector": "em-months-input-container"
      }
    }, [_c('el-select', {
      attrs: {
        "filterable": "",
        "placeholder": "Select"
      },
      on: {
        "change": function ($event) {
          return _vm.SetEmploymentstartdate(_vm.employmentData.employments, index);
        }
      },
      model: {
        value: _vm.employmentData.employments[index].atAdditionalMonths,
        callback: function ($$v) {
          _vm.$set(_vm.employmentData.employments[index], "atAdditionalMonths", $$v);
        },
        expression: "\n                    employmentData.employments[index].atAdditionalMonths\n                  "
      }
    }, [_c('div', {
      attrs: {
        "data-selector": 'em-months-options-container_' + index
      }
    }, _vm._l(_vm.dateRange.MONTH_OPTIONS, function (data) {
      return _c('el-option', {
        key: data.value,
        attrs: {
          "label": data.label,
          "value": data.value
        }
      });
    }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 6
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.EMPLOYER_START_DATE,
        "prop": 'employments.' + index + '.startDate',
        "rules": _vm.rules.startDate,
        "data-selector": "start-date-input-container"
      }
    }, [_c('el-date-picker', {
      attrs: {
        "type": "date",
        "placeholder": "Pick a date",
        "default-value": "01-10-2010",
        "format": "dd/MM/yyyy",
        "picker-options": _vm.previousDateDisabled(_vm.employmentData.employments, index)
      },
      on: {
        "change": function ($event) {
          return _vm.SetTimeAtEmployer(_vm.employmentData.employments, index);
        }
      },
      model: {
        value: _vm.employmentData.employments[index].startDate,
        callback: function ($$v) {
          _vm.$set(_vm.employmentData.employments[index], "startDate", $$v);
        },
        expression: "employmentData.employments[index].startDate"
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 6
      }
    }, [_c('span', {
      staticClass: "demonstration"
    }, [_vm._v(" ")]), _vm._v(" "), _c('el-form-item', {
      staticStyle: {
        "padding-top": "3px !important"
      }
    }, [_c('el-checkbox', {
      attrs: {
        "data-selector": "curr-employment-label"
      },
      model: {
        value: _vm.employmentData.employments[index].isCurrent,
        callback: function ($$v) {
          _vm.$set(_vm.employmentData.employments[index], "isCurrent", $$v);
        },
        expression: "employmentData.employments[index].isCurrent"
      }
    }, [_vm._v(_vm._s(_vm.EMPLOYER_CURRENT_EMPLOYMENT))])], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
      attrs: {
        "gutter": 40
      }
    }, [_c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.EMPLOYER_FULL_ADDRESS,
        "prop": ""
      }
    }, [_c('VueGoogleAutocomplete', {
      staticClass: "form-control el-input el-input__inner",
      attrs: {
        "id": 'googleSearch_' + index,
        "placeholder": _vm.EMPLOYER_FULL_ADDRESS_PLACE_HOLDER,
        "country": ['au'],
        "name": "googleAddress",
        "value": _vm.employmentData.employments[index].address.address,
        "preloaded-data": _vm.employmentData.employments[index].address.address,
        "data-selector": "full-address-input"
      },
      on: {
        "placechanged": function ($event) {
          return _vm.getAddressData($event, index);
        }
      }
    })], 1)], 1), _vm._v(" "), _vm.isConsumer && index + 1 != Object.keys(_vm.employmentData.employments).length - 1 ? _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 6
      }
    }, [_c('span', {
      staticClass: "demonstration"
    }, [_vm._v(" ")])]) : _vm._e()], 1), _vm._v(" "), _c('el-row', {
      attrs: {
        "gutter": 40
      }
    }, [_c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.EMPLOYER_UNIT_STREET,
        "prop": 'employments.' + index + '.address.addressLine1',
        "data-selector": "unit-input-container"
      }
    }, [_c('el-input', {
      attrs: {
        "data-selector": "unit-input"
      },
      model: {
        value: _vm.employmentData.employments[index].address.addressLine1,
        callback: function ($$v) {
          _vm.$set(_vm.employmentData.employments[index].address, "addressLine1", $$v);
        },
        expression: "\n                    employmentData.employments[index].address.addressLine1\n                  "
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.EMPLOYER_SUBURB,
        "prop": 'employments.' + index + '.address.addressLine2',
        "data-selector": "suburb-input-container"
      }
    }, [_c('el-input', {
      attrs: {
        "data-selector": "suburb-input"
      },
      model: {
        value: _vm.employmentData.employments[index].address.addressLine2,
        callback: function ($$v) {
          _vm.$set(_vm.employmentData.employments[index].address, "addressLine2", $$v);
        },
        expression: "\n                    employmentData.employments[index].address.addressLine2\n                  "
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      staticClass: "mb-0",
      attrs: {
        "label": _vm.EMPLOYER_STATE,
        "prop": 'employments.' + index + '.address.state',
        "data-selector": "state-input-container"
      }
    }, [_c('el-select', {
      attrs: {
        "filterable": "",
        "placeholder": "Select"
      },
      model: {
        value: _vm.employmentData.employments[index].address.state,
        callback: function ($$v) {
          _vm.$set(_vm.employmentData.employments[index].address, "state", $$v);
        },
        expression: "employmentData.employments[index].address.state"
      }
    }, [_c('div', {
      attrs: {
        "data-selector": 'state-options-container_' + index
      }
    }, _vm._l(_vm.APPLICANT_STATE_OPTIONS, function (data) {
      return _c('el-option', {
        key: data.value,
        attrs: {
          "label": data.value,
          "value": data.value
        }
      });
    }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.EMPLOYER_POSTCODE,
        "prop": 'employments.' + index + '.address.postcode',
        "data-selector": "postcode-input-container"
      }
    }, [_c('el-input', {
      attrs: {
        "data-selector": "postcode-input"
      },
      model: {
        value: _vm.employmentData.employments[index].address.postcode,
        callback: function ($$v) {
          _vm.$set(_vm.employmentData.employments[index].address, "postcode", $$v);
        },
        expression: "employmentData.employments[index].address.postcode"
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.EMPLOYER_COUNTRY,
        "prop": 'employments.' + index + '.address.country',
        "data-selector": "country-input-container"
      }
    }, [_c('el-select', {
      attrs: {
        "filterable": "",
        "placeholder": "Select"
      },
      model: {
        value: _vm.employmentData.employments[index].address.country,
        callback: function ($$v) {
          _vm.$set(_vm.employmentData.employments[index].address, "country", $$v);
        },
        expression: "employmentData.employments[index].address.country"
      }
    }, [_c('div', {
      attrs: {
        "data-selector": 'country-options-container_' + index
      }
    }, _vm._l(_vm.COUNTRIES, function (data) {
      return _c('el-option', {
        key: data.code,
        attrs: {
          "label": data.value,
          "value": data.value
        }
      });
    }), 1)])], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
      attrs: {
        "gutter": 40
      }
    }, [_c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.EMPLOYER_CONTACT_NAME,
        "prop": 'employments.' + index + '.contactName',
        "data-selector": "emp-contact-name-input-container"
      }
    }, [_c('el-input', {
      attrs: {
        "data-selector": "emp-contact-name-input"
      },
      model: {
        value: _vm.employmentData.employments[index].contactName,
        callback: function ($$v) {
          _vm.$set(_vm.employmentData.employments[index], "contactName", $$v);
        },
        expression: "employmentData.employments[index].contactName"
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.EMPLOYER_PHONE,
        "prop": 'employments.' + index + '.phoneNumber',
        "rules": _vm.mobileNumberRules,
        "data-selector": "emp-phone-input-container"
      }
    }, [_c('el-input', {
      directives: [{
        name: "restrict",
        rawName: "v-restrict.number",
        modifiers: {
          "number": true
        }
      }],
      attrs: {
        "placeholder": "XXXXXXXXXXX",
        "type": "text",
        "maxlength": "11",
        "data-selector": "emp-phone-input"
      },
      model: {
        value: _vm.employmentData.employments[index].phoneNumber,
        callback: function ($$v) {
          _vm.$set(_vm.employmentData.employments[index], "phoneNumber", $$v);
        },
        expression: "employmentData.employments[index].phoneNumber"
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('text-input', {
      attrs: {
        "value": _vm.employmentData.employments[index].aBN,
        "placeholder": "XXXXXXXXXXX",
        "label": _vm.EMPLOYER_ABN,
        "accept-only-numbers": true,
        "form-rule": _vm.rules.aBN,
        "form-prop": 'employments.' + index + '.aBN',
        "data-selector": "emp-abn-input"
      },
      model: {
        value: _vm.employmentData.employments[index].aBN,
        callback: function ($$v) {
          _vm.$set(_vm.employmentData.employments[index], "aBN", $$v);
        },
        expression: "employmentData.employments[index].aBN"
      }
    })], 1)], 1)], 1);
  }), 0)], 1), _vm._v(" "), _vm.employmentForm ? [_vm.isConsumer && (_vm$employmentData3 = _vm.employmentData) !== null && _vm$employmentData3 !== void 0 && _vm$employmentData3.employments.length ? _c('p', {
    staticClass: "mb-4 border-t add-employment"
  }) : _vm._e(), _vm._v(" "), !_vm.isConsumer || ((_vm$employmentData4 = _vm.employmentData) === null || _vm$employmentData4 === void 0 ? void 0 : _vm$employmentData4.employments.length) > 0 ? _c('el-button', {
    staticClass: "font-extrabold w-auto center mr-4",
    class: {
      noHover: _vm.noPriorEmployments,
      'mt-6': !_vm.isConsumer
    },
    attrs: {
      "disabled": _vm.noPriorEmployments,
      "type": "default",
      "icon": "el-icon-plus",
      "plain": "",
      "data-selector": "add-employment-btn"
    },
    on: {
      "click": _vm.addEmployment
    }
  }, [_vm._v(_vm._s(_vm.EMPLOYER_ADD_EMPLOYMENT))]) : _vm._e(), _vm._v(" "), (_vm$employmentData5 = _vm.employmentData) !== null && _vm$employmentData5 !== void 0 && (_vm$employmentData5$e = _vm$employmentData5.employments) !== null && _vm$employmentData5$e !== void 0 && _vm$employmentData5$e.length ? _c('span', {
    staticClass: "divider-line mr-4"
  }, [_vm._v("|")]) : _vm._e(), _vm._v(" "), !_vm.isConsumer && (_vm$employmentData6 = _vm.employmentData) !== null && _vm$employmentData6 !== void 0 && (_vm$employmentData6$e = _vm$employmentData6.employments) !== null && _vm$employmentData6$e !== void 0 && _vm$employmentData6$e.length ? [_c('span', {
    staticClass: "prior-employment inline-block bg-primary"
  }, [_c('p', [_c('i', {
    staticClass: "el-icon-warning-outline"
  }), _vm._v("\n            " + _vm._s(_vm.LABEL_ADDING_PRIOR_EMPLOYMENT) + "\n            "), _c('b', [_vm._v(_vm._s(_vm.LABEL_EMPLOYMENT_OPTIONAL.toLowerCase()))])])])] : [_vm.shouldShowPriorEmployment ? _c('el-checkbox', {
    staticClass: "self-center no-prior-employment",
    attrs: {
      "data-selector": "npe-label"
    },
    on: {
      "change": _vm.changeEmployerPriorEmployment
    },
    model: {
      value: _vm.noPriorEmployments,
      callback: function ($$v) {
        _vm.noPriorEmployments = $$v;
      },
      expression: "noPriorEmployments"
    }
  }, [_vm._v(_vm._s(_vm.EMPLOYER_APPLICANT_NO_PRIOR_EMPLOYMENT))]) : _vm._e()]] : _vm._e()], 2), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 mb-8 justify-end flex"
  }, [_c('div', {
    staticClass: "border-r border-light-grey mr-6 pr-6"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "data-selector": "emp-back-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.back();
      }
    }
  }, [_vm._v(_vm._s(_vm.EMPLOYER_BACK_BUTTON))])], 1), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "disabled": _vm.isButtonDisable || _vm.isApplicationSettled,
      "plain": "",
      "data-selector": "emp-save-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(false);
      }
    }
  }, [_vm._v(_vm._s(_vm.EMPLOYER_SAVE_BUTTON))]), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary",
      "disabled": _vm.isButtonDisable || _vm.isApplicationSettled,
      "data-selector": "emp-save-next-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(true);
      }
    }
  }, [_vm._v(_vm._s(_vm.EMPLOYER_SAVE_AND_NEXT_BUTTON))])], 1), _vm._v(" "), _c('el-dialog', {
    staticClass: "employmentDialog",
    attrs: {
      "visible": _vm.noEmploymentDialog,
      "width": "460px",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "destroy-on-close": "",
      "show-close": false,
      "data-selector": "remove-employment-modal"
    },
    on: {
      "update:visible": function ($event) {
        _vm.noEmploymentDialog = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('el-button', {
          attrs: {
            "type": "text",
            "data-selector": "rm-m-cancel-btn"
          },
          on: {
            "click": function ($event) {
              return _vm.backToEmploymentForm();
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))]), _vm._v(" "), _c('el-button', {
          staticStyle: {
            "padding": "6.5px 12px"
          },
          attrs: {
            "type": "primary",
            "data-selector": "rm-m-yes-btn"
          },
          on: {
            "click": function ($event) {
              return _vm.clearAllEmployments();
            }
          }
        }, [_vm._v(_vm._s(_vm.LABEL_YES_PROCEED))])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/warning-employement.svg")
    }
  }), _vm._v(" "), _c('h3', {
    staticClass: "title text-sm mt-2 text-black",
    attrs: {
      "data-selector": "rm-emp-title"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_REMOVE_EMPLOYMENT))]), _vm._v(" "), _c('p', {
    staticClass: "description text-sm break-word mt-4"
  }, [_vm._v(_vm._s(_vm.REMOVE_EMPLOYMENT_TEXT))])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }