//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  ERROR_MESSAGE_PLEASE_ENTER_PROVIDER,
  ERROR_MESSAGE_FOR_LOAN_ID,
  ERROR_MESSAGE_FOR_MAX_20_CHARS,
} from '@/constants'
import {
  LABEL_FOR_CANCEL_BUTTON,
  LABEL_UPDATE,
  LABEL_LENDER,
  LABEL_LOAN_ID,
  LABEL_FOR_NAME,
} from '~/constants/applications'
import { axiosErrorMessageExtractor } from '~/utils/error'

export default {
  name: 'RequestAssetDetails',
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ERROR_MESSAGE_FOR_MAX_20_CHARS,
      ERROR_MESSAGE_FOR_LOAN_ID,
      LABEL_FOR_NAME,
      LABEL_LOAN_ID,
      LABEL_LENDER,
      LABEL_FOR_CANCEL_BUTTON,
      LABEL_UPDATE,
      ERROR_MESSAGE_PLEASE_ENTER_PROVIDER,
      LenderData: { ...this.sharedData },
      getIsDialogLoading: false,
      rules: {
        'loanDetails.lenderData.businessName': [
          {
            required: true,
            trigger: ['blur', 'change'],
          },
        ],
        'loanDetails.settlement.loanId': [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_LOAN_ID,
            trigger: ['blur', 'change'],
          },
          {
            max: 20,
            message: ERROR_MESSAGE_FOR_MAX_20_CHARS,
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  computed: {
    loanDetailsForm() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.LenderData = JSON.parse(JSON.stringify(this.sharedData))
      return { ...this.sharedData }
    },
  },
  mounted() {
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },
  methods: {
    closeDialog() {
      this.$nuxt.$emit('closeLenderDialog')
    },
    async submitForm() {
      try {
        const { id, applicationId } = this.$route.params
        let isValid = false
        this.$refs.LenderData.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          return
        }
        this.getIsDialogLoading = true
        await this.$store.dispatch('applications/updateLender', {
          sharedData: { ...this.LenderData },
          id,
          applicationId,
        })
        this.$nuxt.$emit('closeLenderDialog')
      } catch (err) {
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.getIsDialogLoading = false
      }
    },
  },
}
