//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  differenceInCalendarYears,
  differenceInCalendarMonths,
  sub,
} from 'date-fns'

import {
  ERROR_MESSAGE_FOR_UNIT_STREET,
  ERROR_MESSAGE_FOR_SUBURB,
  ERROR_MESSAGE_FOR_POSTCODE,
  ERROR_MESSAGE_FOR_COUNTRY,
  ERROR_MESSAGE_FOR_TIME_AT_YEARS,
  ERROR_MESSAGE_FOR_TIME_AT_MONTHS,
  ERROR_MESSAGE_FOR_LIVING_SITUATION,
  ERROR_MESSAGE_FOR_UNIT_STREET_LENGTH,
  ERROR_MESSAGE_FOR_CITY,
  ERROR_MESSAGE_FOR_CITY_LENGTH,
  ERROR_MESSAGE_FOR_STATE,
  ERROR_MESSAGE_FOR_OCCUPATION_NAME,
  ERROR_MESSAGE_FOR_MAX_LENGTH_OCCUPATION,
  ERROR_MESSAGE_FOR_STATUS,
  ERROR_MESSAGE_FOR_START_DATE,
  ERROR_MESSAGE_FOR_PHONE_NUMBER,
  ERROR_MESSAGE_FOR_CONTACT_NAME,
  ERROR_MESSAGE_FOR_EMPLOYER_NAME,
  ERROR_MESSAGE_FOR_EMPLOYER_ABN,
  ERROR_MESSAGE_FOR_MAX_ELEVEN_CHARACTERS,
  ERROR_MESSAGE_FOR_VALID_ABN,
  ERROR_MESSAGE_FOR_VALID_PHONE_NUMBER,
  ERROR_MESSAGE_FOR_INDUSTRY,
  ABN_REGEX,
  ERROR_MESSAGE_FOR_MOBILE,
  REGEX_FOR_AUS_MOBILES,
  APPLICATION_REQUEST_TYPE_COMMERCIAL,
} from '@/constants'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { COUNTRIES } from '@/constants/countries'
import {
  APPLICANT_STATE_OPTIONS,
  YEAR_OPTIONS,
  MONTH_OPTIONS,
  EMPLOYMENT_INFO,
  REMOVE_EMPLOYMENT_TEXT,
  MESSAGE_MINIMUM_THREE_YEARS_REQUIRED,
  MESSAGE_IS_APPLICANT_CUURENTLY_EMPLOYED,
  EMPLOYER_NAME,
  EMPLOYER_OCCUPATION,
  EMPLOYER_STATUS,
  EMPLOYER_TIME_AT_EMPLOYER_YEARS,
  EMPLOYER_TIME_AT_EMPLOYER_MONTHS,
  EMPLOYER_APPLICANT_NO_PRIOR_EMPLOYMENT,
  EMPLOYER_START_DATE,
  EMPLOYER_CURRENT_EMPLOYMENT,
  EMPLOYER_FULL_ADDRESS,
  EMPLOYER_NO_PRIOR_EMPLOYMENT,
  EMPLOYER_FULL_ADDRESS_PLACE_HOLDER,
  EMPLOYER_UNIT_STREET,
  EMPLOYER_SUBURB,
  EMPLOYER_STATE,
  EMPLOYER_POSTCODE,
  EMPLOYER_COUNTRY,
  EMPLOYER_CONTACT_NAME,
  EMPLOYER_PHONE,
  EMPLOYER_ABN,
  EMPLOYER_ADD_EMPLOYMENT,
  EMPLOYER_BACK_BUTTON,
  EMPLOYER_SAVE_BUTTON,
  EMPLOYER_SAVE_AND_NEXT_BUTTON,
  ALL_INDUSTRY_OPTIONS,
  LABEL_FOR_INDUSTRY,
  MAINTAB_INCOME_KEY,
  FINANCE_APPLICATION_COMMERCIAL_TAB_DOCUMENTS_KEY,
  FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
  MAINTAB_APPLICANT_KEY,
  SUBTAB_SECONDAY_PERSONAL_KEY,
  SUBTAB_PRIMARY_PERSONAL_KEY,
  SUBTAB_ASSETS_AND_LIABILITIES_KEY,
  LABEL_ADDING_PRIOR_EMPLOYMENT,
  LABEL_EMPLOYMENT_OPTIONAL,
  LABEL_ADD_EMPLOYMENT,
  LABEL_ADD_EMPLOYMENT_SECOND,
  LABEL_ADD_EMPLOYMENT_THIRD,
  LABEL_NO,
  LABEL_YES,
  LABEL_YES_PROCEED,
  LABEL_CANCEL,
  LABEL_REMOVE_EMPLOYMENT,
} from '@/constants/applications'
import { changeRoute } from '~/helpers/changeRoute'
import { scrollToElement } from '~/utils/scroll'
import { validateDistinctMobileNumber } from '~/helpers/validators'

export default {
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    applicationDetails: {
      type: Object,
      default: null,
    },
    primaryData: {
      type: Object,
      default: () => ({}),
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    activeSubTab: {
      type: String,
      default: 'Personal',
    },
    activeSecondSubTab: {
      type: String,
      default: '',
    },
    isApplicationSettled: {
      type: Boolean,
      default: false,
    },
    activeApplicantIndex: {
      type: Number,
      default: 0,
    },
    isConsumer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      labelPosition: LABEL_YES,
      employmentData: this.activeSecondSubTab
        ? { ...this.secondaryData[this.activeApplicantIndex] }
        : { ...this.primaryData },
      currentlyEmployed: LABEL_NO,
      noEmploymentDialog: false,
      employments: [],
      COUNTRIES,
      YEAR_OPTIONS,
      MONTH_OPTIONS,
      EMPLOYMENT_INFO,
      REMOVE_EMPLOYMENT_TEXT,
      MESSAGE_MINIMUM_THREE_YEARS_REQUIRED,
      MESSAGE_IS_APPLICANT_CUURENTLY_EMPLOYED,
      EMPLOYER_NAME,
      EMPLOYER_OCCUPATION,
      EMPLOYER_STATUS,
      EMPLOYER_TIME_AT_EMPLOYER_YEARS,
      EMPLOYER_TIME_AT_EMPLOYER_MONTHS,
      EMPLOYER_START_DATE,
      EMPLOYER_CURRENT_EMPLOYMENT,
      EMPLOYER_FULL_ADDRESS,
      EMPLOYER_NO_PRIOR_EMPLOYMENT,
      EMPLOYER_FULL_ADDRESS_PLACE_HOLDER,
      EMPLOYER_APPLICANT_NO_PRIOR_EMPLOYMENT,
      EMPLOYER_UNIT_STREET,
      EMPLOYER_SUBURB,
      EMPLOYER_STATE,
      EMPLOYER_POSTCODE,
      EMPLOYER_COUNTRY,
      EMPLOYER_CONTACT_NAME,
      EMPLOYER_PHONE,
      ABN_REGEX,
      EMPLOYER_ABN,
      APPLICANT_STATE_OPTIONS,
      EMPLOYER_ADD_EMPLOYMENT,
      EMPLOYER_BACK_BUTTON,
      EMPLOYER_SAVE_BUTTON,
      EMPLOYER_SAVE_AND_NEXT_BUTTON,
      ERROR_MESSAGE_FOR_STATE,
      ERROR_MESSAGE_FOR_UNIT_STREET,
      ERROR_MESSAGE_FOR_SUBURB,
      ERROR_MESSAGE_FOR_POSTCODE,
      ERROR_MESSAGE_FOR_COUNTRY,
      ERROR_MESSAGE_FOR_TIME_AT_YEARS,
      ERROR_MESSAGE_FOR_OCCUPATION_NAME,
      ERROR_MESSAGE_FOR_TIME_AT_MONTHS,
      ERROR_MESSAGE_FOR_LIVING_SITUATION,
      ERROR_MESSAGE_FOR_UNIT_STREET_LENGTH,
      ERROR_MESSAGE_FOR_CITY,
      ERROR_MESSAGE_FOR_CITY_LENGTH,
      ERROR_MESSAGE_FOR_MAX_LENGTH_OCCUPATION,
      ERROR_MESSAGE_FOR_STATUS,
      ERROR_MESSAGE_FOR_START_DATE,
      ERROR_MESSAGE_FOR_PHONE_NUMBER,
      ERROR_MESSAGE_FOR_CONTACT_NAME,
      ERROR_MESSAGE_FOR_EMPLOYER_NAME,
      ERROR_MESSAGE_FOR_EMPLOYER_ABN,
      ERROR_MESSAGE_FOR_MAX_ELEVEN_CHARACTERS,
      ERROR_MESSAGE_FOR_VALID_ABN,
      ERROR_MESSAGE_FOR_VALID_PHONE_NUMBER,
      ERROR_MESSAGE_FOR_INDUSTRY,
      ALL_INDUSTRY_OPTIONS,
      LABEL_FOR_INDUSTRY,
      LABEL_ADDING_PRIOR_EMPLOYMENT,
      LABEL_EMPLOYMENT_OPTIONAL,
      LABEL_ADD_EMPLOYMENT,
      LABEL_ADD_EMPLOYMENT_SECOND,
      LABEL_ADD_EMPLOYMENT_THIRD,
      MAINTAB_INCOME_KEY,
      APPLICATION_REQUEST_TYPE_COMMERCIAL,
      FINANCE_APPLICATION_COMMERCIAL_TAB_DOCUMENTS_KEY,
      MAINTAB_APPLICANT_KEY,
      SUBTAB_ASSETS_AND_LIABILITIES_KEY,
      SUBTAB_SECONDAY_PERSONAL_KEY,
      ERROR_MESSAGE_FOR_MOBILE,
      REGEX_FOR_AUS_MOBILES,
      getIsDialogLoading: false,
      isPrimary: true,
      noPriorEmployments: false,
      LABEL_NO,
      LABEL_YES,
      LABEL_YES_PROCEED,
      LABEL_CANCEL,
      LABEL_REMOVE_EMPLOYMENT,
      rules: {
        position: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_OCCUPATION_NAME,
            trigger: ['blur', 'change'],
          },
          {
            max: 30,
            message: ERROR_MESSAGE_FOR_MAX_LENGTH_OCCUPATION,
            trigger: ['blur', 'change'],
          },
        ],
        status: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_STATUS,
            trigger: ['blur', 'change'],
          },
        ],
        years: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_TIME_AT_YEARS,
            trigger: ['blur', 'change'],
          },
        ],
        months: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_TIME_AT_MONTHS,
            trigger: ['blur', 'change'],
          },
        ],
        startDate: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_START_DATE,
            trigger: ['blur', 'change'],
          },
        ],
        employerName: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_EMPLOYER_NAME,
            trigger: ['blur', 'change'],
          },
        ],
        industry: [
          {
            required: true,
            message: ERROR_MESSAGE_FOR_INDUSTRY,
            trigger: ['blur', 'change'],
          },
        ],
        aBN: [
          {
            pattern: ABN_REGEX,
            message: ERROR_MESSAGE_FOR_VALID_ABN,
            trigger: 'change',
          },
        ],
      },
      previousDateDisabled(data, index) {
        return {
          disabledDate(time) {
            if (index > 0 && !data[index].isCurrent) {
              return time.getTime() > new Date(data[index - 1].startDate)
            } else {
              return time.getTime() > Date.now()
            }
          },
        }
      },
    }
  },
  computed: {
    /* eslint-disable */
    employmentForm() {
      if (this.activeSecondSubTab) {
        if (this.secondaryData.length) {
          this.employmentData = JSON.parse(
            JSON.stringify(this.secondaryData[this.activeApplicantIndex])
          )
          return { ...this.secondaryData[this.activeApplicantIndex] }
        }
      } else {
        this.employmentData = JSON.parse(JSON.stringify(this.primaryData))
        return { ...this.primaryData }
      }
    },
    /* eslint-enable */
    dateRange() {
      return { YEAR_OPTIONS, MONTH_OPTIONS }
    },
    totalMonths() {
      return this.employmentData.employments
        ? this.employmentData.employments
            .reduce((totalMonths, curr) => {
              if (curr) {
                const years = (curr.atYears || 0) * 12
                const months = curr.atAdditionalMonths || 0
                totalMonths += years + months
              } else {
                totalMonths = 0
              }
              return totalMonths
            }, 0)
            .toFixed(2)
        : 0
    },
    mobileNumberRules() {
      return [
        {
          message: ERROR_MESSAGE_FOR_MOBILE,
          trigger: 'change',
        },
        {
          pattern: REGEX_FOR_AUS_MOBILES,
          validator: (_rule, value, callback) =>
            validateDistinctMobileNumber(_rule, value, callback),
          trigger: 'change',
        },
      ]
    },
    isButtonDisable() {
      return (
        this.employmentData.employments.length &&
        (this.totalMonths < 36 || this.currentlyEmployed === this.LABEL_NO) &&
        this.isConsumer &&
        !this.noPriorEmployments
      )
    },
    shouldShowPriorEmployment() {
      this.setPriorEmployment()
      return this.isConsumer && this.employmentData?.employments?.length
    },
  },

  mounted() {
    this.checkCurrentlyEmployed()
    this.ALL_INDUSTRY_OPTIONS = ALL_INDUSTRY_OPTIONS.sort((a, b) => {
      return a.value < b.value && -1
      return a.value > b.value && 1
      return 0
    })
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
    })
  },
  beforeDestroy() {
    clearTimeout(this.currentEmpCheck)
    this.$nuxt.$off('close-loader')
  },
  methods: {
    employementChange() {
      if (this.currentlyEmployed === this.LABEL_NO) {
        this.noEmploymentDialog = true
      } else {
        this.addEmployment()
      }
    },
    SetTimeAtEmployer(employments, index) {
      let data = employments[index]

      if (data) {
        let Year = differenceInCalendarYears(
          new Date(),
          new Date(data.startDate)
        )

        let Month =
          differenceInCalendarMonths(new Date(), new Date(data.startDate)) -
          Year * 12
        if (0 > Month) {
          if (0 < Year) {
            Year = Year - 1
            Month = 12 + Month
          }
        }
        if (data.startDate == null) {
          Year = 0
          Month = 0
        }
        data.atYears = Year
        data.atAdditionalMonths = Month
      } else if (employments.length > 1 && index > 0) {
        let Year = differenceInCalendarYears(
          new Date(employments[index - 1].startDate),
          new Date(employments[index].startDate)
        )
        let Month =
          differenceInCalendarMonths(
            new Date(employments[index - 1].startDate),
            new Date(employments[index].startDate)
          ) -
          Year * 12
        if (0 > Month) {
          if (0 < Year) {
            Year = Year - 1
            Month = 12 + Month
          }
        }
        if (data.startDate == null) {
          Year = 0
          Month = 0
        }
        data.atYears = Year
        data.atAdditionalMonths = Month
      }
    },

    SetEmploymentstartdate(employments, index) {
      let data = employments[index]

      if (data) {
        data.startDate = sub(new Date(), {
          years: data.atYears,
          months: data.atAdditionalMonths,
        })
      } else if (employments.length > 1 && index > 0) {
        data.startDate = sub(new Date(employments[index - 1].startDate), {
          years: data.atYears,
          months: data.atAdditionalMonths,
        })
      }
    },

    checkCurrentlyEmployed() {
      // eslint-disable-next-line array-callback-return
      const hasCurrentEmployment = this.employmentData.employments.some(
        (employment) => employment.isCurrent
      )
      if (hasCurrentEmployment) {
        this.currentlyEmployed = LABEL_YES
      }
    },

    gotoPreviousTab(tab) {
      changeRoute({
        route: this.$route,
        router: this.$router,
        query: {
          ...this.$route.query,
          [tab]: `${
            this.isPrimary
              ? 'primary_' + SUBTAB_ASSETS_AND_LIABILITIES_KEY
              : 'secondary_' +
                this.activeApplicantIndex +
                '_' +
                SUBTAB_ASSETS_AND_LIABILITIES_KEY
          }`,
          isPrimary: this.isPrimary,
          index: this.activeApplicantIndex,
        },
      })
    },
    async back() {
      let applicationType =
        this.applicationDetails?.applicationType ||
        this.sharedData?.applicationType
      if (applicationType === APPLICATION_REQUEST_TYPE_COMMERCIAL) {
        const subTab = this.activeSubTab
        this.isPrimary = subTab.indexOf('primary_') !== -1
        if (this.$route.query.secondsubtab) {
          await this.gotoPreviousTab('secondsubtab')
        } else {
          await this.gotoPreviousTab('subTab')
          this.$nuxt.$emit('changeContactsTab', {
            tab: FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
            subTab: `${
              this.isPrimary
                ? 'primary_' + SUBTAB_ASSETS_AND_LIABILITIES_KEY
                : 'secondary_' +
                  this.activeApplicantIndex +
                  '_' +
                  SUBTAB_ASSETS_AND_LIABILITIES_KEY
            }`,
            isPrimary: this.isPrimary,
            index: this.activeApplicantIndex,
          })
        }
      } else {
        if (this.activeSecondSubTab) {
          this.$nuxt.$emit('updateSubComponent', 'applicant|address')
        } else {
          this.$nuxt.$emit('updateComponent', 'applicant|address')
        }
      }
    },
    backToEmploymentForm() {
      this.noEmploymentDialog = false
      this.currentlyEmployed = LABEL_YES
    },
    async clearAllEmployments() {
      this.getIsDialogLoading = true
      this.currentlyEmployed = LABEL_NO
      this.employmentData.employments = []
      this.noEmploymentDialog = false

      const { id, applicationId } = this.$route.params
      if (this.activeSecondSubTab) {
        await this.$store.dispatch(
          'applications/updateSecondaryApplicationEmployments',
          {
            employments: this.employmentData.employments,
            id,
            applicationId,
            activeApplicantIndex: this.activeApplicantIndex,
          }
        )
      } else {
        await this.$store.dispatch(
          'applications/updateApplicationEmployments',
          {
            employments: this.employmentData.employments,
            id,
            applicationId,
          }
        )
      }
      this.getIsDialogLoading = false
    },
    removeEmployment(index) {
      this.employmentData.employments.splice(index, 1)
    },
    addEmployment() {
      let isValid = false
      this.$refs.employmentData.validate((result) => (isValid = result))
      if (!isValid) {
        return
      }
      this.employmentData.employments.push({
        isCurrent: false,
        isAdditionalJob: false,
        position: '',
        status: '',
        startDate: '',
        contactName: '',
        atYears: null,
        atAdditionalMonths: null,
        aBN: '',
        noPriorEmployment: false,
        address: {
          country: '',
          address: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          postcode: '',
        },
      })
      if (
        this.isConsumer &&
        this.employmentData?.employments.length >= 0 &&
        !this.employmentData?.employments[0]?.isCurrent
      ) {
        this.employmentData.employments[0].isCurrent = true
        this.currentlyEmployed = LABEL_YES
      }
    },
    /* eslint-disable */
    getAddressData: function (employmentData, index) {
      if (employmentData) {
        this.$set(
          this.employmentData.employments[index].address,
          'address',
          `${employmentData.route}, ${employmentData.locality} ${employmentData.administrative_area_level_1} ${employmentData.postal_code}`
        )
        this.$set(
          this.employmentData.employments[index].address,
          'addressLine1',
          `${
            employmentData.street_number ? employmentData.street_number : ''
          } ${employmentData.route}`
        )
        this.$set(
          this.employmentData.employments[index].address,
          'addressLine2',
          employmentData.locality
        )
        this.$set(
          this.employmentData.employments[index].address,
          'state',
          employmentData.administrative_area_level_1
        )
        this.$set(
          this.employmentData.employments[index].address,
          'postcode',
          employmentData.postal_code
        )
        this.$set(
          this.employmentData.employments[index].address,
          'country',
          employmentData.country
        )
        this.$set(
          this.employmentData.employments[index].address,
          'streetName',
          employmentData.route
        )
        if (employmentData?.street_number) {
          let data =
            employmentData.street_number.includes('/') &&
            employmentData.street_number.includes('-')
              ? employmentData.street_number.replace(/[/]/g, ' ')
              : employmentData.street_number.replace(/[-/]/g, ' ')
          data = data.split(/[\s]/).reverse()
          this.$set(
            this.employmentData.employments[index].address,
            'streetNumber',
            data[0] || ''
          )
          this.$set(
            this.employmentData.employments[index].address,
            'unitNumber',
            data[1] && /^\d+$/.test(data[1]) ? data[1] : ''
          )
        }
      }
    },
    submit(redirect) {
      const { id, applicationId } = this.$route.params
      let isValid = false
      this.$refs.employmentData.validate((result) => (isValid = result))
      if (!isValid) {
        const nodes = document.querySelectorAll('.employmentPersonal .is-error')
        if (nodes && nodes.length > 0 && nodes[0]) {
          this.$nextTick(() => {
            scrollToElement(nodes[0])
          })
        }
        return
      }

      if (this.activeSecondSubTab) {
        this.updateData(
          'updateSecondaryApplicationEmployments',
          {
            employments: this.employmentData.employments,
            id,
            applicationId,
            activeApplicantIndex: this.activeApplicantIndex,
          },
          'updateSubComponent',
          redirect
        )
      } else {
        this.updateData(
          'updateApplicationEmployments',
          {
            employments: this.employmentData.employments,
            id,
            applicationId,
          },
          'updateComponent',
          redirect
        )
      }
    },
    async updateData(actionName, dataObj, updateEvent, isRedirect) {
      const { id, applicationId } = this.$route.params
      this.getIsDialogLoading = true
      await this.$store.dispatch(`applications/${actionName}`, dataObj)
      this.getIsDialogLoading = false
      if (isRedirect) {
        let queryParams = null
        let emitName = null
        let emitString = null
        let applicationType =
          this.applicationDetails?.applicationType ||
          this.sharedData?.applicationType
        let status = applicationType === APPLICATION_REQUEST_TYPE_COMMERCIAL
        if (this.checkStatus(applicationType)) {
          queryParams = {
            maintab: this.getMainTab(applicationType),
          }
          emitName = status ? 'changeTab' : updateEvent
          emitString = status
            ? { tab: FINANCE_APPLICATION_COMMERCIAL_TAB_DOCUMENTS_KEY }
            : this.getMainTab(applicationType)
        } else {
          queryParams = {
            maintab: this.getMainTabForSamePage(applicationType),
          }
          if (status) {
            queryParams.subtab = this.getSubTabForSamePage(applicationType)
          } else {
            queryParams.secondsubtab =
              this.getSubTabForSamePage(applicationType)
          }
          emitName = 'updateSubComponent'
          emitString = `${this.getMainTabForSamePage(
            applicationType
          )}|${this.getSubTabForSamePage(applicationType)}`
        }
        await changeRoute({
          route: this.$route,
          router: this.$router,
          path: `/finance/applications/${id}/${applicationId}`,
          query: queryParams,
        })
        this.$nuxt.$emit(emitName, emitString)
      }
    },
    checkStatus(applicationType) {
      if (applicationType === APPLICATION_REQUEST_TYPE_COMMERCIAL) {
        return this.activeSecondSubTab
          ? this.activeApplicantIndex === this.secondaryData.length - 1
          : !this.secondaryData.length
      } else {
        return this.activeSecondSubTab || !this.secondaryData.length
      }
    },
    getMainTab(applicationType) {
      return applicationType === APPLICATION_REQUEST_TYPE_COMMERCIAL
        ? FINANCE_APPLICATION_COMMERCIAL_TAB_DOCUMENTS_KEY
        : MAINTAB_INCOME_KEY
    },
    getMainTabForSamePage(applicationType) {
      return applicationType === APPLICATION_REQUEST_TYPE_COMMERCIAL
        ? FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY
        : MAINTAB_APPLICANT_KEY
    },
    getSubTabForSamePage(applicationType) {
      let activeIndex = Number.isNaN(this.activeApplicantIndex)
        ? 0
        : this.activeApplicantIndex
      let index = this.activeSecondSubTab ? activeIndex + 3 : activeIndex + 2
      return applicationType === APPLICATION_REQUEST_TYPE_COMMERCIAL
        ? `secondary_${index}_${SUBTAB_PRIMARY_PERSONAL_KEY}`
        : SUBTAB_SECONDAY_PERSONAL_KEY
    },
    setPriorEmployment() {
      if (this.employmentData.employments?.length) {
        const hasPriorEmployment = this.employmentData.employments.some(
          (employment) => employment.noPriorEmployment
        )
        this.noPriorEmployments = hasPriorEmployment
      }
    },
    changeEmployerPriorEmployment() {
      const countEmployment = this.employmentData.employments?.length
      if (countEmployment) {
        this.employmentData.employments[countEmployment - 1].noPriorEmployment =
          this.noPriorEmployments
      }
    },
  },
}
