var render = function () {
  var _vm$sharedData, _vm$sharedData$loanDe, _vm$sharedData$loanDe2, _vm$sharedData2, _vm$sharedData2$loanD, _vm$sharedData2$loanD2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-row', {
    attrs: {
      "gutter": 24
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "md": 6,
      "lg": 4
    }
  }, [_c('el-menu', {
    staticClass: "el-menu-vertical-demo",
    attrs: {
      "default-active": _vm.getDefaltActive()
    }
  }, [_c('el-menu-item-group', [_c('el-menu-item', {
    attrs: {
      "index": "0",
      "data-selector": "ld-submission-subtab"
    },
    on: {
      "click": function ($event) {
        return _vm.sectionRedirect(_vm.MAINTAB_LOANDETAILS_SUBMISSION_KEY);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.MAINTAB_LOANDETAILS_SUBMISSION_LABEL))])]), _vm._v(" "), _c('el-menu-item', {
    attrs: {
      "disabled": !((_vm$sharedData = _vm.sharedData) !== null && _vm$sharedData !== void 0 && (_vm$sharedData$loanDe = _vm$sharedData.loanDetails) !== null && _vm$sharedData$loanDe !== void 0 && (_vm$sharedData$loanDe2 = _vm$sharedData$loanDe.submission) !== null && _vm$sharedData$loanDe2 !== void 0 && _vm$sharedData$loanDe2.submittedAt),
      "index": "1",
      "data-selector": "ld-decision-subtab"
    },
    on: {
      "click": function ($event) {
        return _vm.sectionRedirect(_vm.MAINTAB_LOANDETAILS_DECISION_KEY);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.MAINTAB_LOANDETAILS_DECISION_LABEL))])]), _vm._v(" "), _c('el-menu-item', {
    attrs: {
      "disabled": ((_vm$sharedData2 = _vm.sharedData) === null || _vm$sharedData2 === void 0 ? void 0 : (_vm$sharedData2$loanD = _vm$sharedData2.loanDetails) === null || _vm$sharedData2$loanD === void 0 ? void 0 : (_vm$sharedData2$loanD2 = _vm$sharedData2$loanD.approval) === null || _vm$sharedData2$loanD2 === void 0 ? void 0 : _vm$sharedData2$loanD2.outcomeOfAnApplication) !== _vm.APPLICATION_STATUS_APPROVED,
      "index": "2",
      "data-selector": "ld-presettlement-subtab"
    },
    on: {
      "click": function ($event) {
        return _vm.sectionRedirect(_vm.MAINTAB_LOANDETAILS_SETTLEMENT_KEY);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.MAINTAB_LOANDETAILS_PRE_SETTLEMENT_LABEL))])])], 1)], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "md": 18,
      "lg": 20
    }
  }, [_vm.activeSubTab === _vm.MAINTAB_LOANDETAILS_DECISION_KEY ? _c('LoanDecision', _vm._b({}, 'LoanDecision', Object.assign({}, _vm.$props), false)) : _vm._e(), _vm._v(" "), _vm.activeSubTab === _vm.MAINTAB_LOANDETAILS_SETTLEMENT_KEY ? _c('LoanSettlement', _vm._b({}, 'LoanSettlement', Object.assign({}, _vm.$props), false)) : _vm._e(), _vm._v(" "), _vm.activeSubTab !== _vm.MAINTAB_LOANDETAILS_DECISION_KEY && _vm.activeSubTab !== _vm.MAINTAB_LOANDETAILS_SETTLEMENT_KEY ? _c('LoanSubmission', _vm._b({}, 'LoanSubmission', Object.assign({}, _vm.$props), false)) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }