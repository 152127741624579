//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  CREDIT_FETCH_ERROR_DISCRIPTION,
  CREDIT_FETCH_ERROR_DISCRIPTION_SECOND,
  LABEL_UNABLE_FETCH_PDF,
  LABEL_FOR_CLOSE,
} from '@/constants/applications'

export default {
  name: 'LoanDetails',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CREDIT_FETCH_ERROR_DISCRIPTION,
      CREDIT_FETCH_ERROR_DISCRIPTION_SECOND,
      LABEL_UNABLE_FETCH_PDF,
      LABEL_FOR_CLOSE,
    }
  },
  methods: {
    closeDialog() {
      this.$nuxt.$emit('close-credit-fetch-dialog', false)
    },
  },
}
