export const tradeInLiabilityCommon = (
  tradeInID = null,
  arrayToCheck = null
) => {
  if (tradeInID && arrayToCheck.length) {
    return arrayToCheck.filter((liability) => {
      if (liability?.vehicle === tradeInID) {
        return liability?.totalRemaining
      }
    })
  }
  return []
}
