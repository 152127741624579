export default () => ({
  applications: [],
  tableSettings: {
    totalDocs: 0,
    page: 0,
    totalPages: 0,
    limit: 0,
    pagingCounter: 0,
  },
  isTableLoading: false,
})
