// plugins/socket.io.js
import Vue from 'vue'
import socketio from 'socket.io-client'

export default ({ app }) => {
  const socket = socketio(process.env.VUE_APP_SOCKET_URL, {
    transports: ['websocket', 'polling'],
  }) // Replace with your Socket.IO server URL

  // Add socket instance to the Vue prototype
  Vue.prototype.$socket = socket
  app.$socket = socket
}
