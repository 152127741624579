//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LABEL_FOR_CLOSE } from '@/constants/applications'

export default {
  name: 'LoanDetails',
  props: {
    hasLoanSubmissionErr: {
      type: Boolean,
      default: () => false,
    },
    loanSubmissionErrors: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      LABEL_FOR_CLOSE,
      getIsDialogLoading: false,
      title: 'Errors returned by lenders',
    }
  },
  mounted() {
    this.$nuxt.$on('close-loader', () => {
      this.loadingDialog = false
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },

  methods: {
    closeDialog() {
      this.$nuxt.$emit('close-loan-submission-error-dialog')
    },
  },
}
