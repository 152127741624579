export const COMMON_ROUTES = [
  'login',
  'login-email',
  'auth-email-login',
  'auth-login',
  'email-password-reset',
  'email-password-reset-accelerate',
  'change-password',
  'auth-verify-recovery',
  'auth-verify-invitation',
]
