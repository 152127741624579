import cloneDeep from 'lodash/cloneDeep'
import {
  ASSETS_TO_LIABILITY_MAPPING,
  APPLICATION_ASSETS_OTHER,
  FINANCE_APPLICATION_COMMERCIAL_TAB_BUSINESS_DETAILS_KEY,
  FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
  // FINANCE_APPLICATION_COMMERCIAL_TAB_GUARANTOR_KEY,
  FINANCE_APPLICATION_COMMERCIAL_TAB_DOCUMENTS_KEY,
  FINANCE_APPLICATION_COMMERCIAL_TAB_COMPLIANCE_KEY,
  FINANCE_APPLICATION_COMMERCIAL_TAB_CREDIT_REPORT_KEY,
  FINANCE_APPLICATION_COMMERCIAL_TAB_ASSET_DETAILS_KEY,
  FINANCE_APPLICATION_COMMERCIAL_TAB_LOAN_DETAILS_KEY,
  FINANCE_APPLICATION_COMMERCIAL_TAB_NOTES_KEY,
  FINANCE_APPLICATION_TAB_REQUIREMENTS_KEY,
  FINANCE_APPLICATION_TAB_APPLICANT_KEY,
  FINANCE_APPLICATION_TAB_INCOME_KEY,
  FINANCE_APPLICATION_TAB_EXPENSES_KEY,
  FINANCE_APPLICATION_TAB_ASSETS_AND_LIABILITIES_KEY,
  FINANCE_APPLICATION_TAB_DOCUMENTS_KEY,
  FINANCE_APPLICATION_TAB_COMPLIANCE_KEY,
  FINANCE_APPLICATION_TAB_CREDIT_REPORT_KEY,
  FINANCE_APPLICATION_TAB_ASSET_DETAILS_KEY,
  FINANCE_APPLICATION_TAB_LOAN_DETAILS_KEY,
  FINANCE_APPLICATION_TAB_NOTES_KEY,
  FINANCE_APPLICATION_TAB_SETTLEMENT_KEY,
  FINANCE_APPLICATION_COMMERCIAL_TABS,
  FINANCE_APPLICATION_TABS,
  FINANCE_APPLICATION_COMMERCIAL_TAB_MATCHES_KEY,
  RESIDENCY_STATUS_VISA_457_VALUE,
  RESIDENCY_STATUS_VISA_UNSPECIFIED_VALUE,
  RESIDENCY_STATUS_NON_RESIDENT_VALUE,
  RESIDENCY_STATUS_VISA_OTHER_VALUE,
  LABEL_AUSTRALIA,
  LABEL_LOAN_AMOUNT,
} from '~/constants/applications'
import Requirements from '~/components/FinanceApplication/Tabs/Requirements'
import Applicant from '~/components/FinanceApplication/Tabs/ApplicantTab'
import Income from '~/components/FinanceApplication/Tabs/Income'
import Expenses from '~/components/FinanceApplication/Tabs/Expenses'
import AssetsAndLiabilities from '~/components/FinanceApplication/Tabs/AssetsAndLiabilities'
import Documents from '~/components/Documents'
import Compliance from '~/components/FinanceApplication/Tabs/Compliance'
import CreditReport from '~/components/FinanceApplication/Tabs/CreditReport'
import AssetDetails from '~/components/FinanceApplication/Tabs/AssetDetails'
import LoanDetails from '~/components/FinanceApplication/Tabs/LoanDetails'
import Settlement from '~/components/Settlement'
import Notes from '~/components/FinanceApplication/Tabs/Notes'
import BusinessDetails from '~/components/FinanceApplication/Tabs/BusinessDetails'
// import Contacts from '~/components/FinanceApplication/Tabs/Contact'
// import Guarantor from '~/components/FinanceApplication/Tabs/Guarantor'
import ApplicationMatches from '~/components/Applications/ApplicationMatches'
import ApplicationComplianceTab from '~/components/Applications/ApplicationComplianceTab'
import ApplicantCreditReport from '~/components/Applications/ApplicantCreditReport'
import ApplicantAssetDetails from '~/components/Applications/ApplicantAssetDetail'
import { mongoObjectId } from '~/utils/mongo'
import {
  APPLICATION_REQUEST_TYPE_CONSUMER,
  APPLICATION_STATUS_STARTED,
  APPLICATION_STATUS_AWAITING_DOCS,
  APPLICATION_STATUS_READY_FOR_SUBMISSION,
  APPLICATION_STATUS_DOCUMENTS_RECEIVED,
  APPLICATION_STATUS_SUBMITTED_TO_LENDER,
} from '~/constants'
import { toCurrency } from '~/utils/string'
import { yearViewOption } from '~/utils/form'

const getAssociatedLiabilityBasedOnTypeOfAsset = (
  liabilities = [],
  assetType
) => {
  if (assetType) {
    return liabilities.find(
      (i) => i.type == ASSETS_TO_LIABILITY_MAPPING[assetType]
    )
  }
}

export const bindLiabilityWithAssetIfHas = (assets = [], liabilities = []) => {
  let clonedAssets = cloneDeep(assets)
  let clonedLiabilities = cloneDeep(liabilities)
  clonedAssets.forEach((asset) => {
    if (
      asset.hasLiability &&
      !asset.liability &&
      asset.type !== APPLICATION_ASSETS_OTHER
    ) {
      const associatedLiability = getAssociatedLiabilityBasedOnTypeOfAsset(
        clonedLiabilities,
        asset.type
      )
      if (associatedLiability) {
        const liabilityIndex = clonedLiabilities.findIndex(
          (i) => i.type === ASSETS_TO_LIABILITY_MAPPING[asset.type] && !i._id
        )
        if (liabilityIndex != -1) {
          const objectId = mongoObjectId()
          clonedLiabilities[liabilityIndex]._id = objectId
          asset.liability = objectId
        }
      }
    } else if (!asset.hasLiability && asset.liability) {
      delete asset.liability
    }
  })
  return {
    assets: clonedAssets,
    liabilities: clonedLiabilities,
  }
}

export default {
  FINANCE_APPLICATION_TAB_COMPONENT_MAP: () => ({
    [FINANCE_APPLICATION_TAB_REQUIREMENTS_KEY]: Requirements,
    [FINANCE_APPLICATION_TAB_APPLICANT_KEY]: Applicant,
    [FINANCE_APPLICATION_TAB_INCOME_KEY]: Income,
    [FINANCE_APPLICATION_TAB_EXPENSES_KEY]: Expenses,
    [FINANCE_APPLICATION_TAB_ASSETS_AND_LIABILITIES_KEY]: AssetsAndLiabilities,
    [FINANCE_APPLICATION_TAB_DOCUMENTS_KEY]: Documents,
    [FINANCE_APPLICATION_TAB_COMPLIANCE_KEY]: Compliance,
    [FINANCE_APPLICATION_TAB_CREDIT_REPORT_KEY]: CreditReport,
    [FINANCE_APPLICATION_TAB_ASSET_DETAILS_KEY]: AssetDetails,
    [FINANCE_APPLICATION_TAB_LOAN_DETAILS_KEY]: LoanDetails,
    [FINANCE_APPLICATION_TAB_SETTLEMENT_KEY]: Settlement,
  }),
  FINANCE_APPLICATION_COMMERCIAL_TAB_COMPONENT: () => ({
    [FINANCE_APPLICATION_COMMERCIAL_TAB_MATCHES_KEY]: ApplicationMatches,
    [FINANCE_APPLICATION_COMMERCIAL_TAB_BUSINESS_DETAILS_KEY]: BusinessDetails,
    [FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY]: Applicant,
    // [FINANCE_APPLICATION_COMMERCIAL_TAB_GUARANTOR_KEY]: Guarantor,
    [FINANCE_APPLICATION_COMMERCIAL_TAB_DOCUMENTS_KEY]: Documents,
    [FINANCE_APPLICATION_COMMERCIAL_TAB_COMPLIANCE_KEY]:
      ApplicationComplianceTab,
    [FINANCE_APPLICATION_COMMERCIAL_TAB_CREDIT_REPORT_KEY]:
      ApplicantCreditReport,
    [FINANCE_APPLICATION_COMMERCIAL_TAB_ASSET_DETAILS_KEY]:
      ApplicantAssetDetails,
    [FINANCE_APPLICATION_COMMERCIAL_TAB_LOAN_DETAILS_KEY]: LoanDetails,
    [FINANCE_APPLICATION_TAB_SETTLEMENT_KEY]: Settlement,
  }),
  getTabPageComponentByKeyId(keyId, isConsumer) {
    if (isConsumer) {
      return this.FINANCE_APPLICATION_TAB_COMPONENT_MAP()[keyId]
    }
    return this.FINANCE_APPLICATION_COMMERCIAL_TAB_COMPONENT()[keyId]
  },
  getFinanceApplicationTabs(isConsumer) {
    if (isConsumer) {
      return FINANCE_APPLICATION_TABS
    }
    return FINANCE_APPLICATION_COMMERCIAL_TABS
  },
}

export function doesAddressHaveCompleteMeta(address) {
  return (
    address.country === LABEL_AUSTRALIA &&
    address.state &&
    (!!address.city || !!address.postcode || !!address.addressLine1)
  )
}

export const getLatestApplication = (vehicleRequestGroup) => {
  const vrg = cloneDeep(vehicleRequestGroup)
  const applications = vrg.finance.applications.sort(
    (a, b) => b.createdAt - a.createdAt
  )
  return applications[0]
}

export const isApplicationConsumer = (application) => {
  return application.applicationType === APPLICATION_REQUEST_TYPE_CONSUMER
}

export const setVehicleYear = (year, value) => {
  let data = []
  for (let i = year; i >= year - value; i--) {
    data.push(i)
  }
  return data
}
export const setResidencyStatus = (data) => {
  if (
    [
      RESIDENCY_STATUS_VISA_457_VALUE,
      RESIDENCY_STATUS_VISA_UNSPECIFIED_VALUE,
      RESIDENCY_STATUS_NON_RESIDENT_VALUE,
    ].includes(data.residencyStatus)
  ) {
    data.visaOther = data.residencyStatus
    data.residencyStatus = RESIDENCY_STATUS_VISA_OTHER_VALUE
  }
}
export const getAmount = (applicationData, key) => {
  let checkArray = applicationData?.loanDetails
  if (checkArray) {
    if (checkArray?.settlement?.[key]) {
      return toCurrency(checkArray.settlement[key])
    } else if (checkArray?.approval?.[key]) {
      return toCurrency(checkArray.approval[key])
    } else if (checkArray?.submission?.[key]) {
      return toCurrency(checkArray?.submission[key])
    } else {
      const amount = applicationData[key] ? applicationData[key] : 0
      return toCurrency(amount)
    }
  } else {
    const amount = applicationData[key] ? applicationData[key] : 0
    return toCurrency(amount)
  }
}

export const getSentLinkStatus = (applicationData) => {
  return (
    applicationData?.applicationLinkSentAt &&
    [
      APPLICATION_STATUS_STARTED,
      APPLICATION_STATUS_AWAITING_DOCS,
      APPLICATION_STATUS_READY_FOR_SUBMISSION,
      APPLICATION_STATUS_DOCUMENTS_RECEIVED,
    ].includes(applicationData?.status)
  )
}

export const getAmountWithOutCurrency = (applicationData, key) => {
  let checkArray = applicationData?.loanDetails
  if (checkArray) {
    if (checkArray?.settlement?.[key]) {
      return checkArray.settlement[key]
    } else if (checkArray?.approval?.[key]) {
      return checkArray.approval[key]
    } else if (checkArray?.submission?.[key]) {
      return checkArray?.submission[key]
    } else {
      const amount = applicationData[key] ? applicationData[key] : 0
      return amount
    }
  } else {
    const amount = applicationData[key] ? applicationData[key] : 0
    return amount
  }
}

export const getAmountWithOutCurrencyForMatches = (applicationData, key) => {
  let checkArray = applicationData?.loanDetails
  if (checkArray) {
    if (checkArray?.settlement?.[key]) {
      if (
        [
          APPLICATION_STATUS_READY_FOR_SUBMISSION,
          APPLICATION_STATUS_SUBMITTED_TO_LENDER,
        ].includes(applicationData.status)
      ) {
        return checkArray?.submission[key]
      }
      return checkArray.settlement[key]
    } else if (checkArray?.approval?.[key]) {
      if ([APPLICATION_STATUS_STARTED].includes(applicationData.status)) {
        return checkArray?.submission[key]
      }
      return checkArray.approval[key]
    } else if (checkArray?.submission?.[key]) {
      return checkArray?.submission[key]
    } else {
      const amount = applicationData[key] ? applicationData[key] : 0
      return amount
    }
  } else {
    const amount = applicationData[key] ? applicationData[key] : 0
    return amount
  }
}

export const getLoanTermYears = (applicationData) => {
  let checkArray = applicationData?.loanDetails
  if (checkArray) {
    if (checkArray?.settlement?.loanTermYears) {
      return yearViewOption(checkArray.settlement.loanTermYears)
    } else if (checkArray?.approval?.loanTermYears) {
      return yearViewOption(checkArray.approval.loanTermYears)
    } else if (checkArray?.submission?.loanTermYears) {
      return yearViewOption(checkArray.submission.loanTermYears)
    } else {
      return yearViewOption(applicationData.loanTermYears)
    }
  } else {
    return yearViewOption(applicationData.loanTermYears)
  }
}
