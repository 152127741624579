export default {
  increment(state) {
    state.counter++
  },
  startTableLoading(state) {
    state.isTableLoading = true
  },
  stopTableLoading(state) {
    state.isTableLoading = false
  },
  setTableSettings(
    state,
    { totalDocs, page, totalPages, limit, pagingCounter }
  ) {
    state.tableSettings.totalDocs = totalDocs
    state.tableSettings.page = page
    state.tableSettings.totalPages = totalPages
    state.tableSettings.limit = limit
    state.tableSettings.pagingCounter = pagingCounter
  },
  setVehicleRequestGroupsTable(state, vrgs) {
    state.vehiceRequestGroupsTable = vrgs
  },
}
