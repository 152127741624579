var render = function () {
  var _vm$lenderDetailsData, _vm$lenderDetailsData2, _vm$loanDetails, _vm$loanDetails2, _vm$loanDetails2$vehi, _vm$loanDetails3, _vm$loanDetails3$vehi, _vm$loanDetails4, _vm$loanDetails5, _vm$loanDetails6, _vm$loanDetails7, _vm$loanDetails7$vehi, _vm$loanDetails8, _vm$loanDetails8$vehi, _vm$loanDetails9, _vm$loanDetails9$vehi, _vm$loanDetails10, _vm$loanDetails10$veh, _vm$loanDetails11, _this$lenderDetailsDa;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    staticClass: "lender-detail-dialog",
    staticStyle: {
      "margin": "0px"
    },
    attrs: {
      "visible": _vm.showDialog,
      "width": "672px",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "destroy-on-close": "",
      "center": "",
      "show-close": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.showDialog = $event;
      }
    }
  }, [_c('div', [_c('span', {
    staticClass: "font-bolder mx-1 header-legend text-extra-small"
  }, [_c('i', {
    staticClass: "el-icon-user font-bolder",
    staticStyle: {
      "padding": "5px"
    }
  }), _vm._v("\n      " + _vm._s(_vm.LABEL_SELECTED_BY_CUSTOMER))]), _vm._v(" "), (_vm$lenderDetailsData = _vm.lenderDetailsData) !== null && _vm$lenderDetailsData !== void 0 && _vm$lenderDetailsData.createdAt ? _c('el-card', {
    staticClass: "card-border"
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 18
    }
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 22
    }
  }, [_c('el-row', [_c('img', {
    attrs: {
      "height": "50px",
      "src": (_vm$lenderDetailsData2 = _vm.lenderDetailsData) === null || _vm$lenderDetailsData2 === void 0 ? void 0 : _vm$lenderDetailsData2.lenderLogoUrl
    }
  })]), _vm._v(" "), _c('el-row', {
    staticClass: "pt-125"
  }, [_c('h2', {
    staticClass: "text-xs font-bolder"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_REPAYMENTS))]), _vm._v(" "), _c('el-col', {
    staticClass: "mt-1",
    attrs: {
      "span": 8
    }
  }, [_c('h2', {
    staticClass: "font-bolder text-success text-md"
  }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.lenderDetailsData.monthlyRepayment) || '0'))]), _c('p', {
    staticClass: "text-xs mt-1 timeLineColor"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_MONTLY.charAt(0).toUpperCase() + _vm.LABEL_FOR_MONTLY.slice(1)))])]), _vm._v(" "), _c('el-col', {
    staticClass: "mt-1",
    attrs: {
      "span": 8
    }
  }, [_c('h2', {
    staticClass: "font-bolder text-success text-md"
  }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.lenderDetailsData.fortnightlyRepayment) || '0'))]), _c('p', {
    staticClass: "text-xs mt-1 timeLineColor"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_FORTNIGHT))])]), _vm._v(" "), _c('el-col', {
    staticClass: "mt-1",
    attrs: {
      "span": 8
    }
  }, [_c('h2', {
    staticClass: "font-bolder text-success text-md"
  }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.lenderDetailsData.weeklyRepayment) || '0'))]), _c('p', {
    staticClass: "text-xs mt-1 timeLineColor"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_WEEK))])])], 1), _vm._v(" "), _c('el-row', [_c('el-row', {
    staticClass: "mt-6"
  }, [_c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('h2', {
    staticClass: "text-xs fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_CUSTOMER_RATE))]), _vm._v(" "), _c('h2', {
    staticClass: "text-md mt-1"
  }, [_vm._v(_vm._s(parseFloat(_vm.lenderDetailsData.rate * 100).toFixed(2) || '0') + "\n                      %")]), _vm._v(" "), _c('p', {
    staticClass: "text-xs mt-1"
  }, [_vm._v(_vm._s(_vm.LABEL_FIXED_RATE))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('h2', {
    staticClass: "text-xs fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_COMPARISON_RATE_FOR_LENDER))]), _vm._v(" "), _c('h2', {
    staticClass: "text-md mt-1"
  }, [_vm._v(_vm._s(parseFloat(_vm.lenderDetailsData.comparisonRate * 100).toFixed(2) || '0') + "%")])])], 1)], 1), _vm._v(" "), _c('hr', {
    staticClass: "my-4",
    staticStyle: {
      "border": "1px solid #e8e8e8"
    }
  }), _vm._v(" "), _c('h2', {
    staticClass: "text-sm mt-6"
  }, [_vm._v(_vm._s(_vm.LABEL_LOAN_DETAILS))]), _vm._v(" "), _c('el-row', {
    staticClass: "mt-3"
  }, [_c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('h2', {
    staticClass: "text-xs fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE))]), _vm._v(" "), _c('h2', {
    staticClass: "text-sm mt-1"
  }, [_vm._v(_vm._s(_vm.numberWithCommas((_vm$loanDetails = _vm.loanDetails) === null || _vm$loanDetails === void 0 ? void 0 : _vm$loanDetails.purchasePrice) || '0'))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('h2', {
    staticClass: "text-xs fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_DEPOSIT))]), _vm._v(" "), _c('h2', {
    staticClass: "text-sm mt-1"
  }, [_vm._v("\n                    " + _vm._s(_vm.numberWithCommas((_vm$loanDetails2 = _vm.loanDetails) === null || _vm$loanDetails2 === void 0 ? void 0 : (_vm$loanDetails2$vehi = _vm$loanDetails2.vehicle) === null || _vm$loanDetails2$vehi === void 0 ? void 0 : _vm$loanDetails2$vehi.financeDepositAmount) || '0') + "\n                  ")])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('h2', {
    staticClass: "text-xs fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_TRADE_IN_EQUITY))]), _vm._v(" "), _c('h2', {
    staticClass: "text-sm mt-1"
  }, [_vm._v("\n                    " + _vm._s(_vm.numberWithCommas((_vm$loanDetails3 = _vm.loanDetails) === null || _vm$loanDetails3 === void 0 ? void 0 : (_vm$loanDetails3$vehi = _vm$loanDetails3.vehicle) === null || _vm$loanDetails3$vehi === void 0 ? void 0 : _vm$loanDetails3$vehi.financeDepositAmount) || '0'))])])], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-3"
  }, [_c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('h2', {
    staticClass: "text-xs fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_BALLOON_PERCENT))]), _vm._v(" "), _c('h2', {
    staticClass: "text-sm mt-1"
  }, [_vm._v("\n                    " + _vm._s((_vm$loanDetails4 = _vm.loanDetails) !== null && _vm$loanDetails4 !== void 0 && _vm$loanDetails4.balloonPct ? parseFloat(_vm.loanDetails.balloonPct).toFixed(2) + '%' : '--'))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('h2', {
    staticClass: "text-xs fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_LOAN_BALLOON_DOLLAR))]), _vm._v(" "), _c('h2', {
    staticClass: "text-sm mt-1"
  }, [_vm._v("\n                    " + _vm._s(_vm.numberWithCommas((_vm$loanDetails5 = _vm.loanDetails) === null || _vm$loanDetails5 === void 0 ? void 0 : _vm$loanDetails5.balloonAmount) || '0'))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('h2', {
    staticClass: "text-xs fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_LOAN_TREM))]), _vm._v(" "), _c('h2', {
    staticClass: "text-sm mt-1"
  }, [_vm._v("\n                    " + _vm._s(((_vm$loanDetails6 = _vm.loanDetails) === null || _vm$loanDetails6 === void 0 ? void 0 : _vm$loanDetails6.loanTermYears) || '--') + "\n                    " + _vm._s(_vm.LABEL_YEARS))])])], 1), _vm._v(" "), _c('hr', {
    staticClass: "mt-4",
    staticStyle: {
      "border": "1px solid #e8e8e8"
    }
  }), _vm._v(" "), _c('h2', {
    staticClass: "text-sm mt-6"
  }, [_vm._v(_vm._s(_vm.TITLE_FOR_ASSET_DETAILS))]), _vm._v(" "), _c('h2', {
    staticClass: "text-sm mt-3 fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_ASSET_TYPE_OPTION_VEHICLE))]), _vm._v(" "), _c('h2', {
    staticClass: "text-sm mt-3"
  }, [_vm._v(_vm._s(((_vm$loanDetails7 = _vm.loanDetails) === null || _vm$loanDetails7 === void 0 ? void 0 : (_vm$loanDetails7$vehi = _vm$loanDetails7.vehicle) === null || _vm$loanDetails7$vehi === void 0 ? void 0 : _vm$loanDetails7$vehi.description) || '--'))]), _vm._v(" "), _c('el-row', {
    staticClass: "mt-3"
  }, [_c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('h2', {
    staticClass: "text-xs fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_ASSET_YEAR))]), _vm._v(" "), _c('h2', {
    staticClass: "text-sm mt-1"
  }, [_vm._v("\n                    " + _vm._s(((_vm$loanDetails8 = _vm.loanDetails) === null || _vm$loanDetails8 === void 0 ? void 0 : (_vm$loanDetails8$vehi = _vm$loanDetails8.vehicle) === null || _vm$loanDetails8$vehi === void 0 ? void 0 : _vm$loanDetails8$vehi.year) || '--'))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('h2', {
    staticClass: "text-xs fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CONDITION))]), _vm._v(" "), _c('h2', {
    staticClass: "text-sm mt-1"
  }, [_vm._v("\n                    " + _vm._s(((_vm$loanDetails9 = _vm.loanDetails) === null || _vm$loanDetails9 === void 0 ? void 0 : (_vm$loanDetails9$vehi = _vm$loanDetails9.vehicle) === null || _vm$loanDetails9$vehi === void 0 ? void 0 : _vm$loanDetails9$vehi.condition) || '--'))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('h2', {
    staticClass: "text-xs fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_SALE_TYPE))]), _vm._v(" "), _c('h2', {
    staticClass: "text-sm mt-1"
  }, [_vm._v("\n                    " + _vm._s(((_vm$loanDetails10 = _vm.loanDetails) === null || _vm$loanDetails10 === void 0 ? void 0 : (_vm$loanDetails10$veh = _vm$loanDetails10.vehicle) === null || _vm$loanDetails10$veh === void 0 ? void 0 : _vm$loanDetails10$veh.purchaseSource) || '--'))])])], 1)], 1)], 1)], 1), _vm._v(" "), _c('el-col', {
    staticClass: "summary-box",
    attrs: {
      "sm": 24,
      "lg": 6
    }
  }, [_c('div', {
    staticClass: "py-4 px-1"
  }, [_c('p', {
    staticClass: "font-bolder"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_SUMMARY))]), _vm._v(" "), _c('div', {
    staticClass: "mt-4"
  }, [_c('h2', {
    staticClass: "text-sm font-bolder fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_LOAN_AMOUNT))]), _vm._v(" "), _c('h2', {
    staticClass: "text-md font-bolder mt-1"
  }, [_vm._v(_vm._s(_vm.numberWithCommas((_vm$loanDetails11 = _vm.loanDetails) === null || _vm$loanDetails11 === void 0 ? void 0 : _vm$loanDetails11.loanAmount) || '0'))])]), _vm._v(" "), _c('hr', {
    staticClass: "mt-4",
    staticStyle: {
      "border": "1px solid #e8e8e8"
    }
  }), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-extra-small font-bolder mt-4 fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CREATED_AT))]), _vm._v(" "), _c('p', {
    staticClass: "text-xs mt-1"
  }, [_vm._v(_vm._s(_vm.format(new Date((_this$lenderDetailsDa = this.lenderDetailsData) === null || _this$lenderDetailsDa === void 0 ? void 0 : _this$lenderDetailsDa.createdAt), 'dd/MM/yyyy hh:mm aaa') || '--'))])])])])], 1)], 1) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "dialog-footer text-center",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": function ($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CLOSE))])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }