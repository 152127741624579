//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  LABEL_LOAN_SEND_PROPSAL,
  LABEL_LOAN_SUBMIT_APPLICATION,
  LABEL_YES_PROCEED,
  LABEL_CANCEL,
  APPLICATION_STATUS_SUBMITTED_TO_LENDER,
} from '@/constants/applications'
import { axiosErrorMessageExtractor } from '~/utils/error'

export default {
  name: 'LoanDetails',
  props: {
    dialogOpened: {
      type: Boolean,
      default: () => false,
    },
    loanDetails: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: () => null,
    },
    subtitle: {
      type: String,
      default: () => null,
    },
    type: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      LABEL_LOAN_SEND_PROPSAL,
      LABEL_LOAN_SUBMIT_APPLICATION,
      LABEL_YES_PROCEED,
      LABEL_CANCEL,
      getIsDialogLoading: false,
    }
  },
  mounted() {
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
      this.closeDialog()
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },
  methods: {
    closeDialog() {
      this.$nuxt.$emit('close-loan-tab-dialog', this.type)
    },
    async submitForm() {
      const { id, applicationId } = this.$route.params
      const status = APPLICATION_STATUS_SUBMITTED_TO_LENDER
      try {
        this.getIsDialogLoading = true
        await this.$store.dispatch('applications/checkMissingFields', {
          id,
          applicationId,
          status,
        })
        if (this.type === this.LABEL_LOAN_SEND_PROPSAL) {
          this.$store
            .dispatch('applications/sendProposal', {
              id,
              applicationId,
            })
            .then(() => {
              this.getIsDialogLoading = false
              this.$nuxt.$emit('close-loan-tab-dialog', this.type)
            })
        } else if (this.type === this.LABEL_LOAN_SUBMIT_APPLICATION) {
          this.$store
            .dispatch('applications/submitLoanApplication', {
              id,
              applicationId,
              loanDetails: this.loanDetails,
            })
            .then(() => {
              this.getIsDialogLoading = false
              this.$nuxt.$emit('close-loan-tab-dialog', this.type)
            })
        }
      } catch (err) {
        this.getIsDialogLoading = false
        this.$nuxt.$emit('close-loan-tab-dialog', this.type)
        if (err?.response?.data?.errors) {
          const error = err?.response?.data?.errors
          this.$nuxt.$emit('open-missing-fields-modal-submission', error)
        } else {
          this.$notify({
            title: 'Error',
            message: axiosErrorMessageExtractor(err),
            type: 'error',
            duration: 5000,
          })
        }
      } finally {
        this.$nuxt.$emit('close-loan-tab-dialog', this.type)
        this.getIsDialogLoading = false
      }
    },
  },
}
