//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  REQUIRED_FIELDS,
  LABEL_APPLICANT_PERSONAL,
  LABEL_APPLICANT_ADDRESSES,
  LABEL_APPLICANT_EMPLOYMENT,
  LABEL_LOAN_SEND_PROPSAL,
  LABEL_LOAN_SUBMIT_APPLICATION,
  LABEL_GO_TO,
  LABEL_CANCEL,
  REQUIRED_FIELDS_COMMERCIAL,
  APPLICATION_STATUS_SUBMITTED_TO_LENDER,
  APPLICATION_STATUS_APPROVED,
  FINANCE_APPLICATION_TAB_REQUIREMENTS_LABEL,
  FINANCE_APPLICATION_TAB_EXPENSES_LABEL,
  LABEL_CONTACTS_PERSONAL,
  LABEL_CONTACTS_ADDRESSES,
  LABEL_CONTACTS_EMPLOYMENT,
  LABEL_LOAN_DETAILS_SUBMISSION,
  LABEL_LOAN_DETAILS_DECISION,
  LABEL_LOAN_DETAILS_SETTEMENT,
  BUSINESS_DETAILS_TAB_KEY,
  LABEL_PRIMARY_LOWERCASE,
  LABEL_SECONDARY_LOWERCASE,
  LABEL_LOAN_BUSINESS_DETAILS_DETAILS,
  LABEL_LOAN_BUSINESS_DETAILS_ADDRESS,
  SUBTAB_PRIMARY_ADDRESS_KEY,
  LABEL_CONTACTS_ASSETS_AND_LIABILITIES,
  LABEL_FOR_ASSET_DETAILS,
  MAINTAB_ASSETS_LABEL,
  MAINTAB_SETTLEMENT_KEY,
  LABEL_SETTLEMENT_SELLER,
  LABEL_SETTLEMENT_LOAN_ID,
  LABEL_SETTLEMENT_CHECKLIST,
} from '@/constants/applications'
import { APPLICATION_STATUS_SETTLED, TITLE_FOR_INCOME } from '~/constants'
import { LABEL_DOCUMENTS } from '~/constants/referrals'
import { changeRoute } from '~/helpers/changeRoute'

export default {
  name: 'LoanDetails',
  props: {
    dialogOpened: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => null,
    },
    subtitle: {
      type: String,
      default: () => null,
    },
    type: {
      type: String,
      default: () => null,
    },
    netValue: {
      type: Number,
      default: () => null,
    },
    loadingDialog: {
      type: Boolean,
      default: () => false,
    },
    errorsData: {
      default: '',
    },
    status: {
      type: String,
      default: () => null,
    },
    isCommercial: {
      type: Boolean,
      default: () => false,
    },
    applicationData: {
      type: Object,
      default: () => ({}),
    },
    isFromSettlement: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      LABEL_LOAN_SEND_PROPSAL,
      LABEL_LOAN_SUBMIT_APPLICATION,
      LABEL_GO_TO,
      LABEL_CANCEL,
      getIsDialogLoading: false,
      REQUIRED_FIELDS_COMMERCIAL,
      BUSINESS_DETAILS_TAB_KEY,
      REQUIRED_FIELDS,
      LABEL_PRIMARY_LOWERCASE,
      LABEL_SECONDARY_LOWERCASE,
      LABEL_CONTACTS_ASSETS_AND_LIABILITIES,
      LABEL_FOR_ASSET_DETAILS,
      MAINTAB_ASSETS_LABEL,
      MAINTAB_SETTLEMENT_KEY,
    }
  },
  computed: {
    errorTabs() {
      const tempListArr = []
      if (this.errorsData) {
        this.errorsData.map((e) => {
          if (this.isCommercial) {
            return REQUIRED_FIELDS_COMMERCIAL.some((fields) => {
              let updatedFieldValue = ''

              if (e.field.includes(fields.key)) {
                if (
                  fields?.userType === LABEL_SECONDARY_LOWERCASE &&
                  !e.field.includes('primaryApplicantData') &&
                  !e.field.includes('shared')
                ) {
                  const getUserIndex = e.field
                    .split('finance.application.otherApplicant.')[1]
                    .split('.')[0]

                  const secondarysApplicantName = `${this.applicationData.otherApplicants[getUserIndex].applicant.firstName} ${this.applicationData.otherApplicants[getUserIndex].applicant.lastName}`
                  updatedFieldValue = {
                    value: fields.value.replace(
                      'APPLICANT_NAME',
                      secondarysApplicantName
                    ),
                    userType: fields?.userType,
                    indexNum: getUserIndex,
                    ...(fields?.label && {
                      label: fields?.label,
                    }),
                  }
                } else {
                  const primaryApplicantName = `${this.applicationData.primaryApplicantData.primaryApplicant.firstName} ${this.applicationData.primaryApplicantData.primaryApplicant.lastName}`
                  updatedFieldValue = {
                    value: fields.value.replace(
                      'APPLICANT_NAME',
                      primaryApplicantName
                    ),
                    userType: LABEL_PRIMARY_LOWERCASE,
                    indexNum: null,
                    ...(fields?.label && {
                      label: fields?.label,
                    }),
                  }
                }

                tempListArr.push(updatedFieldValue)
              }
            })
          }

          return REQUIRED_FIELDS.some((fields) => {
            if (e.field.includes(fields.key)) {
              let updatedFieldValue = ''
              if (fields?.userType === LABEL_SECONDARY_LOWERCASE) {
                const secondarysApplicantName = `${this.applicationData?.otherApplicants[0]?.applicant?.firstName} ${this.applicationData?.otherApplicants[0]?.applicant?.lastName}`
                updatedFieldValue = {
                  value: fields.value.replace(
                    'APPLICANT_NAME',
                    secondarysApplicantName
                  ),
                  userType: fields?.userType,
                  ...(fields?.label && {
                    label: fields?.label,
                  }),
                }
              } else {
                const primaryApplicantName = `${this.applicationData?.primaryApplicantData?.primaryApplicant?.firstName} ${this.applicationData?.primaryApplicantData?.primaryApplicant?.lastName}`
                updatedFieldValue = {
                  value: fields.value.replace(
                    'APPLICANT_NAME',
                    primaryApplicantName
                  ),
                  userType: LABEL_PRIMARY_LOWERCASE,
                  ...(fields?.label && {
                    label: fields?.label,
                  }),
                }
              }

              tempListArr.push(updatedFieldValue)
              return fields.value
            }
          })
        })

        return [
          ...new Map(tempListArr.map((item) => [item['value'], item])).values(),
        ]
      } else {
        return []
      }
    },
  },
  methods: {
    closeDialog() {
      let eventName = ''
      switch (this.status) {
        case APPLICATION_STATUS_SUBMITTED_TO_LENDER:
          eventName = 'closeMissingFieldsModalSubmission'
          break
        case APPLICATION_STATUS_APPROVED:
          eventName = 'closeMissingFieldsModalApproval'
          break
        case APPLICATION_STATUS_SETTLED:
          eventName = 'closeMissingFieldsModalSettlement'
          break
      }
      this.$emit(eventName)
    },
    handleClick(obj) {
      const updatedVal = obj.value.replace(/>.*>/, '> APPLICANT_NAME >')

      if (this.isCommercial) {
        let tabName = {}
        switch (updatedVal) {
          case LABEL_LOAN_BUSINESS_DETAILS_DETAILS:
            tabName.tab = 'businessDetails'
            tabName.subTab = 'details'
            break
          case LABEL_LOAN_BUSINESS_DETAILS_ADDRESS:
            tabName.tab = 'businessDetails'
            tabName.subTab = 'address'
            break
          case LABEL_CONTACTS_PERSONAL:
            tabName.tab = 'contacts'
            tabName.subTab =
              obj.userType !== LABEL_SECONDARY_LOWERCASE
                ? 'primary_personal'
                : `secondary_${parseInt(obj.indexNum) + 2}_personal`
            break
          case LABEL_CONTACTS_ADDRESSES:
            tabName.tab = 'contacts'
            tabName.subTab =
              obj.userType !== LABEL_SECONDARY_LOWERCASE
                ? 'primary_address'
                : `secondary_${parseInt(obj.indexNum) + 2}_address`
            break
          case LABEL_CONTACTS_ASSETS_AND_LIABILITIES:
            tabName.tab = 'contacts'
            tabName.subTab =
              obj.userType !== LABEL_SECONDARY_LOWERCASE
                ? 'primary_assetsAndLiabilities'
                : `secondary_${parseInt(obj.indexNum) + 2}_assetsAndLiabilities`
            break
          case LABEL_CONTACTS_EMPLOYMENT:
            tabName.tab = 'contacts'
            tabName.subTab = 'primary_personal'
            break
          case LABEL_DOCUMENTS:
            tabName.tab = 'documents'
            break
          case LABEL_FOR_ASSET_DETAILS:
            tabName.tab = 'assetDetails'
            break
          case LABEL_LOAN_DETAILS_SUBMISSION:
            tabName.tab = 'loanDetails'
            tabName.subTab = 'submission'
            break
          case LABEL_LOAN_DETAILS_DECISION:
            tabName.tab = 'loanDetails'
            tabName.subTab = 'decision'
            break
          case LABEL_LOAN_DETAILS_SETTEMENT:
            tabName.tab = 'loanDetails'
            tabName.subTab = 'settlement'
            break
          case LABEL_SETTLEMENT_CHECKLIST:
            tabName.tab = 'settlement'
            break
          case LABEL_SETTLEMENT_SELLER:
            tabName.tab = 'settlement|sellerDetails'
            break
          case LABEL_SETTLEMENT_LOAN_ID:
            tabName.tab = 'settlement|loanDetails'
            break
        }
        this.closeDialog()
        let checkCommercialType = tabName.tab.split('|')
        if (tabName.tab === BUSINESS_DETAILS_TAB_KEY) {
          const { id, applicationId } = this.$route.params

          this.$nuxt.$emit(
            'updateBusinessDetailsTabComponent',
            `${BUSINESS_DETAILS_TAB_KEY}|${tabName.subTab}`
          )

          changeRoute({
            route: this.$route,
            router: this.$router,
            path: `/finance/applications/${id}/${applicationId}`,
            query: {
              maintab: BUSINESS_DETAILS_TAB_KEY,
              subtab: tabName.subTab,
            },
          })

          this.$nuxt.$emit('changeTab', {
            tab: BUSINESS_DETAILS_TAB_KEY,
            subTab: tabName.subTab,
          })
        } else if (
          checkCommercialType[0] === MAINTAB_SETTLEMENT_KEY &&
          this.isFromSettlement
        ) {
          this.$nuxt.$emit('updateSettlementSubComponent', tabName.tab)
        } else {
          this.$nuxt.$emit('changeTab', tabName)
        }
      } else {
        let tabName = ''

        if (updatedVal === FINANCE_APPLICATION_TAB_REQUIREMENTS_LABEL) {
          tabName = 'requirements'
        } else if (
          updatedVal === LABEL_APPLICANT_PERSONAL &&
          obj.userType === LABEL_PRIMARY_LOWERCASE
        ) {
          tabName = 'applicant|personal'
        } else if (
          updatedVal === LABEL_APPLICANT_ADDRESSES &&
          obj.userType === LABEL_PRIMARY_LOWERCASE
        ) {
          tabName = 'applicant|address'
        } else if (
          updatedVal === LABEL_APPLICANT_EMPLOYMENT &&
          obj.userType === LABEL_PRIMARY_LOWERCASE
        ) {
          tabName = 'applicant|employment'
        } else if (
          updatedVal === LABEL_APPLICANT_PERSONAL &&
          obj.userType === LABEL_SECONDARY_LOWERCASE
        ) {
          tabName = 'applicant|personalsecondary'
        } else if (
          updatedVal === LABEL_APPLICANT_ADDRESSES &&
          obj.userType === LABEL_SECONDARY_LOWERCASE
        ) {
          tabName = 'applicant|address'
        } else if (
          updatedVal === LABEL_APPLICANT_EMPLOYMENT &&
          obj.userType === LABEL_SECONDARY_LOWERCASE
        ) {
          tabName = 'applicant|employment'
        } else if (updatedVal === TITLE_FOR_INCOME) {
          tabName = 'income'
        } else if (updatedVal === FINANCE_APPLICATION_TAB_EXPENSES_LABEL) {
          tabName = 'expenses'
        } else if (updatedVal === MAINTAB_ASSETS_LABEL) {
          tabName = 'assetsLiabilities'
        } else if (updatedVal === LABEL_DOCUMENTS) {
          tabName = 'docs'
        } else if (updatedVal === LABEL_FOR_ASSET_DETAILS) {
          tabName = 'assetDetails'
        } else if (updatedVal === LABEL_LOAN_DETAILS_SUBMISSION) {
          tabName = 'loanDetails|submission'
        } else if (updatedVal === LABEL_LOAN_DETAILS_DECISION) {
          tabName = 'loanDetails|decision'
        } else if (updatedVal === LABEL_LOAN_DETAILS_SETTEMENT) {
          tabName = 'loanDetails|settlement'
        } else if (updatedVal === LABEL_SETTLEMENT_CHECKLIST) {
          tabName = 'settlement'
        } else if (updatedVal === LABEL_SETTLEMENT_SELLER) {
          tabName = 'settlement|sellerDetails'
        } else if (updatedVal === LABEL_SETTLEMENT_LOAN_ID) {
          tabName = 'settlement|loanDetails'
        }

        this.closeDialog()
        let checkConsumerType = tabName.split('|')
        if (obj.userType === LABEL_SECONDARY_LOWERCASE) {
          this.$nuxt.$emit(
            'updateSubComponent',
            `${checkConsumerType[0]}|${checkConsumerType[1]}`
          )
        } else if (
          checkConsumerType[0] === MAINTAB_SETTLEMENT_KEY &&
          this.isFromSettlement
        ) {
          this.$nuxt.$emit('updateSettlementSubComponent', tabName)
        } else {
          this.$nuxt.$emit('updateComponent', tabName)
        }
      }
    },
  },
}
