const URL_NAME_FOR_ESIGNATURE =
  'vrgId-finance-applications-applicationId-applicants-applicantId-esignatures'
const URL_NAME_FOR_EXPIRE_TOKEN = 'expireToken'

export const URLS_FOR_MIDDLE_THE_PORTAL_TITLE = [
  URL_NAME_FOR_ESIGNATURE,
  URL_NAME_FOR_EXPIRE_TOKEN,
]

export const URLS_FOR_HIDE_THE_PORTAL_TITLE = [
  URL_NAME_FOR_ESIGNATURE,
  URL_NAME_FOR_EXPIRE_TOKEN,
]

export const URLS_FOR_HIDE_NAVBAR_DROPDOWN_AND_SIDEBAR = [
  URL_NAME_FOR_ESIGNATURE,
  URL_NAME_FOR_EXPIRE_TOKEN,
]
