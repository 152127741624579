//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { axiosErrorMessageExtractor } from '~/utils/error'
import { changeRoute } from '~/helpers/changeRoute'
import {
  FINANCE_APPLICATION_COMMERCIAL_TAB_BUSINESS_DETAILS_KEY,
  LABEL_CANCEL,
  LABEL_YES_PROCEED,
} from '~/constants/applications'

export default {
  props: {
    dialogOpened: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => null,
    },
    subtitle: {
      type: String,
      default: () => null,
    },
    isCommercial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      getIsDialogLoading: false,
      FINANCE_APPLICATION_COMMERCIAL_TAB_BUSINESS_DETAILS_KEY,
      LABEL_CANCEL,
      LABEL_YES_PROCEED,
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeCloneApplcationDialog')
    },
    async cloneApplication() {
      try {
        this.getIsDialogLoading = true
        const { id, applicationId } = this.$route.params
        const response = await this.$store.dispatch(
          'applications/getCloneApplication',
          {
            id,
            applicationId,
          }
        )
        this.getIsDialogLoading = false
        this.$emit('closeCloneApplcationDialog')
        if (response) {
          this.$notify.success({
            title: 'Success',
            message: 'Application cloned successfully',
          })
        } else {
          return
        }
        const cloneApplicationData =
          this.$store.getters['applications/getCloneApplication']
        const cloneApplicationId = cloneApplicationData._id
        if (!this.isCommercial) {
          await changeRoute({
            route: this.$route,
            router: this.$router,
            path: `/finance/applications/${this.$route.params.id}/${cloneApplicationId}`,
            query: {
              maintab: 'requirements',
            },
          })
        } else {
          await changeRoute({
            route: this.$route,
            router: this.$router,
            path: `/finance/applications/${this.$route.params.id}/${cloneApplicationId}`,
            query: {
              maintab: FINANCE_APPLICATION_COMMERCIAL_TAB_BUSINESS_DETAILS_KEY,
            },
          })
        }
      } catch (err) {
        this.getIsDialogLoading = false
        this.$emit('closeCloneApplcationDialog')
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      }
    },
  },
}
