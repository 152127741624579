var render = function () {
  var _vm$requirementData, _vm$requirementData$s, _vm$numberWithCommas, _vm$numberWithCommas2, _vm$REASON_FOR_BALLOO, _vm$REASON_FOR_BALLOO2, _vm$requirementData2, _vm$requirementData3, _vm$requirementData4, _vm$requirementData4$, _this$requirementData, _this$requirementData2, _this$requirementData3, _this$requirementData4, _this$requirementData5, _this$requirementData6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loanDecisionForm ? _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }],
    staticClass: "application-form"
  }, [_c('el-form', {
    ref: "loanDecisionForm",
    staticClass: "loanDecision",
    attrs: {
      "model": _vm.requirementData,
      "rules": _vm.rules
    }
  }, [_vm.isApplicationSubmitted && !_vm.isApplicationApproved ? _c('NotificationBar', {
    attrs: {
      "message-type": "success",
      "title": _vm.LABEL_SUBMITTED,
      "description": _vm.LABEL_APPLICATION_IS + _vm.LABEL_SUBMITTED.toLowerCase() + _vm.LABEL_APPLICATION_IS_APPROVED_AT + _vm.format(new Date(_vm.requirementData.loanDetails.submission.submittedAt), 'dd/MM/yyyy hh:mm aaa'),
      "has-button": false,
      "button-label": _vm.LABEL_WITHDRAW_APPLICATION
    },
    on: {
      "notificationEvent": _vm.handleModel
    }
  }) : _vm._e(), _vm._v(" "), _vm.isApplicationApproved ? _c('NotificationBar', {
    attrs: {
      "message-type": "success",
      "title": _vm.requirementData.loanDetails.approval.outcomeOfAnApplication,
      "description": _vm.LABEL_APPLICATION_IS + _vm.LABEL_APPROVED.toLowerCase() + _vm.LABEL_APPLICATION_IS_APPROVED_AT + _vm.format(new Date(_vm.requirementData.loanDetails.approval.approvedAt), 'dd/MM/yyyy hh:mm aaa'),
      "has-button": false,
      "button-label": _vm.LABEL_WITHDRAW_APPLICATION
    },
    on: {
      "notificationEvent": _vm.handleModel
    }
  }) : _vm._e(), _vm._v(" "), _vm.isApplicationWithdrawn ? _c('NotificationBar', {
    attrs: {
      "message-type": "warning",
      "title": _vm.LABEL_WITHDRAWN,
      "description": _vm.LABEL_APPLICATION_IS + _vm.LABEL_WITHDRAWN.toLowerCase() + _vm.LABEL_APPLICATION_IS_APPROVED_AT + _vm.format(new Date(_vm.requirementData.loanDetails.approval.withdrawnAt), 'dd/MM/yyyy hh:mm aaa'),
      "has-button": false,
      "button-label": _vm.LABEL_WITHDRAW_APPLICATION
    },
    on: {
      "notificationEvent": _vm.handleModel
    }
  }) : _vm._e(), _vm._v(" "), _vm.isApplicationDeclined ? _c('NotificationBar', {
    attrs: {
      "message-type": "warning",
      "title": _vm.LABEL_DECLINED,
      "description": _vm.LABEL_APPLICATION_IS + ((_vm$requirementData = _vm.requirementData) === null || _vm$requirementData === void 0 ? void 0 : (_vm$requirementData$s = _vm$requirementData.status) === null || _vm$requirementData$s === void 0 ? void 0 : _vm$requirementData$s.toLowerCase()) + _vm.LABEL_APPLICATION_IS_APPROVED_AT + _vm.format(new Date(_vm.requirementData.loanDetails.approval.declinedAt), 'dd/MM/yyyy hh:mm aaa'),
      "has-button": false,
      "button-label": _vm.LABEL_WITHDRAW_APPLICATION
    },
    on: {
      "notificationEvent": _vm.handleModel
    }
  }) : _vm._e(), _vm._v(" "), _vm.shouldDisplayWithdrawnBanner ? _c('div', [_c('p', {
    staticClass: "text-xs font-extrabold other-actions"
  }, [_vm._v(_vm._s(_vm.LABEL_OTHER_ACTIONS))]), _vm._v(" "), _c('NotificationBar', {
    attrs: {
      "message-type": "info",
      "title": _vm.LABEL_WITHDRAWN,
      "description": _vm.LABEL_WITHDRAW_APPROVED_APPLICATION,
      "has-button": true,
      "button-label": _vm.LABEL_WITHDRAW_APPLICATION
    },
    on: {
      "notificationEvent": _vm.handleModel
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.isApplicationWithdrawn || _vm.isApplicationDeclined ? _c('NotificationBar', {
    attrs: {
      "message-type": "info",
      "title": 'Clone',
      "description": _vm.LABEL_CLONE_SUBTITLE,
      "has-button": true,
      "button-label": _vm.CLONE_APPLICATION
    },
    on: {
      "notificationEvent": _vm.handleCloneModel
    }
  }) : _vm._e(), _vm._v(" "), _c('el-card', {
    attrs: {
      "body-style": _vm.isApplicationApproved || _vm.isApplicationDeclined || _vm.isApplicationWithdrawn ? {
        padding: '0px'
      } : ''
    }
  }, [!_vm.isApplicationApproved && !_vm.isApplicationDeclined && !_vm.isApplicationWithdrawn ? _c('div', {
    staticClass: "pt-4"
  }, [_vm.requirementData && _vm.requirementData.loanDetails && _vm.requirementData.loanDetails.approval ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-sm"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("* ")]), _vm._v(_vm._s(_vm.LABEL_OUTCOME_APPLICATION))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.approval.outcomeOfAnApplication",
      "data-selector": "outcome-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.outcomeOfAnApplication,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "outcomeOfAnApplication", $$v);
      },
      expression: "\n                  requirementData.loanDetails.approval.outcomeOfAnApplication\n                "
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "outcome-options-container"
    }
  }, _vm._l(_vm.OUTCOME_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.value,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.submittedApproved && !_vm.isApplicationApproved ? _c('div', [_c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-sm"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("* ")]), _vm._v(_vm._s(_vm.LABEL_EXPIRY_APPROVAL))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.approval.approvalExpiresAt",
      "data-selector": "approval-expiry-input-container"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "placeholder": "Today + 3 months",
      "format": "dd/MM/yyyy",
      "picker-options": _vm.pastPickerDateDisabled
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.approvalExpiresAt,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "approvalExpiresAt", $$v);
      },
      expression: "\n                  requirementData.loanDetails.approval.approvalExpiresAt\n                "
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-sm"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("* ")]), _vm._v(_vm._s(_vm.LABEL_APPROVAL_CONDITIONS))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.approval.approvalConditions",
      "data-selector": "approval-conditions-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "data-selector": "approval-conditions-input"
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.approvalConditions,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "approvalConditions", $$v);
      },
      expression: "\n                  requirementData.loanDetails.approval.approvalConditions\n                "
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-sm"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("* ")]), _vm._v(_vm._s(_vm.LABEL_APPROVAL_SETTLEMENT_ETA))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.approval.settlementEta",
      "data-selector": "settlement-eta-input-container"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "placeholder": "DD/MM/YYYY",
      "format": "dd/MM/yyyy",
      "picker-options": _vm.pastPickerDateDisabled
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.settlementEta,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "settlementEta", $$v);
      },
      expression: "requirementData.loanDetails.approval.settlementEta"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-sm"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("* ")]), _vm._v(_vm._s(_vm.LABEL_APPLICATION_SUBMITTED) + "\n            ")])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.approval.doesApprovedAsSubmitted",
      "data-selector": "approved-as-input-container"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.requirementData.loanDetails.approval.doesApprovedAsSubmitted,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "doesApprovedAsSubmitted", $$v);
      },
      expression: "\n                  requirementData.loanDetails.approval.doesApprovedAsSubmitted\n                "
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": true,
      "data-selector": "approved-as-yes"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_YES))]), _vm._v(" "), _c('el-radio-button', {
    attrs: {
      "label": false,
      "data-selector": "approved-as-no"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_NO))])], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.applicationDeclined && !_vm.isApplicationDeclined ? _c('div', [_c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-sm"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("*  ")]), _vm._v(_vm._s(_vm.LABEL_DECLINE_REASON))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "declinedReasonType",
      "data-selector": "declined-reason-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.requirementData.declinedReasonType,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData, "declinedReasonType", $$v);
      },
      expression: "requirementData.declinedReasonType"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "declined-reason-options-container"
    }
  }, _vm._l(_vm.LOAN_DECLINED_REASONS_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1)], 1), _vm._v(" "), _vm.isDeclinedOther ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    staticStyle: {
      "margin-left": "50%"
    },
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "loanDetails.approval.declinedReason",
      "data-selector": "declined-other-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "placeholder": "Write your text here ...",
      "autocomplete": "off",
      "data-selector": "declined-other-input"
    },
    model: {
      value: _vm.requirementData.declinedReason,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData, "declinedReason", $$v);
      },
      expression: "requirementData.declinedReason"
    }
  })], 1)], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), !_vm.isApplicationWithdrawn && _vm.applicationWithdrawn && _vm.requirementData.loanDetails.approval ? _c('div', [_c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-sm"
  }, [_c('span', {
    staticClass: "required"
  }, [_vm._v("*  ")]), _vm._v(_vm._s(_vm.LABEL_WITHDRAW_REASON))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "withdrawnReasonType",
      "data-selector": "withdrawn-reason-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.requirementData.withdrawnReasonType,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData, "withdrawnReasonType", $$v);
      },
      expression: "requirementData.withdrawnReasonType"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "withdrawn-reason-options-container"
    }
  }, _vm._l(_vm.LOAN_WITHDRAWN_REASONS_OPTIONS, function (item) {
    return _c('el-option', {
      key: item.key,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)])], 1)], 1)], 1), _vm._v(" "), _vm.isWithdrawnOther ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    staticStyle: {
      "margin-left": "50%"
    },
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "withdrawnReason",
      "data-selector": "withdrawn-other-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "placeholder": "Write your text here ...",
      "autocomplete": "off",
      "data-selector": "withdrawn-other-input"
    },
    model: {
      value: _vm.requirementData.withdrawnReason,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData, "withdrawnReason", $$v);
      },
      expression: "requirementData.withdrawnReason"
    }
  })], 1)], 1)], 1) : _vm._e()], 1) : _vm._e()]), _vm._v(" "), _vm.isApplicationApproved && !_vm.applicationSubmitted ? _c('div', [_c('el-card', {
    staticClass: "pb-child-none"
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "approval-details-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_APPROVAL_DETAILS))])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-xs font-extrabold other-actions p-0"
  }, [_vm._v(_vm._s(_vm.LABEL_EXPIRY))]), _vm._v(" "), _c('p', {
    staticClass: "text-sm",
    attrs: {
      "data-selector": "saved-expiry-date"
    }
  }, [_vm._v(_vm._s(_vm.requirementData.loanDetails.approval.approvalExpiresAt ? _vm.format(new Date(_vm.requirementData.loanDetails.approval.approvalExpiresAt), 'dd/MM/yyyy') : _vm.LABEL_NOT_AVAILABLE))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-xs font-extrabold other-actions p-0"
  }, [_vm._v(_vm._s(_vm.LABEL_SETTLEMENT_ETA))]), _vm._v(" "), _c('p', {
    staticClass: "text-sm",
    attrs: {
      "data-selector": "saved-settlement-eta"
    }
  }, [_vm._v(_vm._s(_vm.requirementData.loanDetails.approval.settlementEta ? _vm.format(new Date(_vm.requirementData.loanDetails.approval.settlementEta), 'dd/MM/yyyy') : _vm.LABEL_NOT_AVAILABLE) + "\n            ")])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-xs font-extrabold other-actions p-0"
  }, [_vm._v(_vm._s(_vm.LABEL_CONDITION))]), _vm._v(" "), _c('p', {
    staticClass: "text-sm",
    attrs: {
      "data-selector": "saved-approval-condition"
    }
  }, [_vm._v(_vm._s(_vm.requirementData.loanDetails.approval.approvalConditions))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('p', {
    staticClass: "text-xs font-extrabold other-actions p-0"
  }, [_vm._v(_vm._s(_vm.LABEL_APPROVED_AS_SUBMITTED))]), _vm._v(" "), _c('p', {
    staticClass: "text-sm",
    attrs: {
      "data-selector": "saved-approved-as-submitted"
    }
  }, [_vm._v(_vm._s(_vm.requirementData.loanDetails.approval.doesApprovedAsSubmitted ? _vm.LABEL_YES : _vm.LABEL_NO))])])], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isApplicationDeclined && !_vm.applicationSubmitted ? _c('div', [_c('el-card', {
    staticClass: "pb-child-none"
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "decline-details-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_DECLINE_DETAILS))])]), _vm._v(" "), _c('p', {
    staticClass: "text-xs font-extrabold other-actions p-0"
  }, [_vm._v(_vm._s(_vm.LABEL_REASON))]), _vm._v(" "), _c('p', {
    staticClass: "text-sm",
    attrs: {
      "data-selector": "saved-declined-reason"
    }
  }, [_vm._v(_vm._s(_vm.isDeclinedOther ? _vm.requirementData.declinedReason : _vm.requirementData.declinedReasonType))])])], 1) : _vm._e(), _vm._v(" "), _vm.isApplicationWithdrawn && !_vm.applicationSubmitted && _vm.requirementData.loanDetails.approval ? _c('div', [_c('el-card', {
    staticClass: "pb-child-none"
  }, [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "withdrawn-details-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_WITHDRAWN_DETAILS))])]), _vm._v(" "), _c('p', {
    staticClass: "text-xs font-extrabold other-actions p-0"
  }, [_vm._v(_vm._s(_vm.LABEL_REASON))]), _vm._v(" "), _c('p', {
    staticClass: "text-sm",
    attrs: {
      "data-selector": "saved-withdrawn-reason"
    }
  }, [_vm._v(_vm._s(_vm.isWithdrawnOther ? _vm.requirementData.withdrawnReason : _vm.requirementData.withdrawnReasonType))])])], 1) : _vm._e(), _vm._v(" "), _vm.isEditMode && !_vm.applicationSubmitted && !_vm.applicationDeclined && !_vm.applicationWithdrawn ? _c('div', [_c('el-card', [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "d-loan-details-header-text"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_LOAN_DETAILS))])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2",
    attrs: {
      "data-selector": "d-ls-saved-purchase-price"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.requirementData.loanDetails.approval.purchasePrice >= 0 ? _vm.numberWithCommas(_vm.requirementData.loanDetails.approval.purchasePrice) : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticStyle: {
      "font-size": "0.75rem",
      "font-weight": "bolder"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.LABEL_TRADE_IN_EQUITY) + "\n            ")]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2",
    attrs: {
      "data-selector": "d-ls-saved-trade-in-price"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.requirementData.loanDetails.approval.tradeInAmount >= 0 ? _vm.numberWithCommas(_vm.requirementData.loanDetails.approval.tradeInAmount) : _vm.LABEL_NOT_AVAILABLE) + "\n              ")])])]), _vm._v(" "), _vm.remainingLiability ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v("\n                " + _vm._s(_vm.LABEL_TRADING_LIABILITIES) + "\n              ")])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1"
  }, [_vm._v("\n                " + _vm._s(_vm.numberWithCommas(_vm.remainingLiability)) + "\n              ")])])]) : _vm._e(), _vm._v(" "), _vm.requirementData.vehicle ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_DOCUMENT_DEPOSIT))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2",
    attrs: {
      "data-selector": "d-ls-saved-deposit"
    }
  }, [_vm._v(_vm._s(_vm.requirementData.loanDetails.approval.depositAmount ? _vm.numberWithCommas(_vm.requirementData.loanDetails.approval.depositAmount) : 0))])])]) : _vm._e(), _vm._v(" "), _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_NAF))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2",
    attrs: {
      "data-selector": "d-ls-saved-naf"
    }
  }, [_vm._v("\n                " + _vm._s((_vm$numberWithCommas = _vm.numberWithCommas(_vm.requirementData.loanDetails.approval.netAmountFinanced)) !== null && _vm$numberWithCommas !== void 0 ? _vm$numberWithCommas : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('br'), _vm._v(" "), _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.VEHICLE_CONDITION_BALLOON) + " %")])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2",
    attrs: {
      "data-selector": "d-ls-saved-balloon-percent"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.requirementData.loanDetails.approval.balloonPct >= 0 ? parseFloat(_vm.requirementData.loanDetails.approval.balloonPct).toFixed(2) + '%' : _vm.LABEL_NOT_AVAILABLE) + "\n              ")])])]), _vm._v(" "), _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.VEHICLE_CONDITION_BALLOON) + " $")])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2",
    attrs: {
      "data-selector": "d-ls-saved-balloon-amount"
    }
  }, [_vm._v("\n                " + _vm._s((_vm$numberWithCommas2 = _vm.numberWithCommas(_vm.requirementData.loanDetails.approval.balloonAmount)) !== null && _vm$numberWithCommas2 !== void 0 ? _vm$numberWithCommas2 : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _vm.hasBalloonPctHasValue && !_vm.isCommercial ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_REASON_FOR_BALLOON_PAYMENT))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "d-ls-saved-reason-for-balloon"
    }
  }, [_vm._v(_vm._s((_vm$REASON_FOR_BALLOO = (_vm$REASON_FOR_BALLOO2 = _vm.REASON_FOR_BALLOON_PAYMENT_OPTIONS.find(function (reason) {
    return reason.value === _vm.requirementData.customerReasonForBalloon;
  })) === null || _vm$REASON_FOR_BALLOO2 === void 0 ? void 0 : _vm$REASON_FOR_BALLOO2.label) !== null && _vm$REASON_FOR_BALLOO !== void 0 ? _vm$REASON_FOR_BALLOO : _vm.LABEL_NOT_AVAILABLE))])])]) : _vm._e(), _vm._v(" "), _vm.requirementData.customerReasonForBalloon === _vm.LABEL_BALLOON_REPAYMENT_OTHER ? _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_OTHER_REASON_FOR_BALLOON_PAYMENT_VIEW))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "d-ls-saved-other-reason-balloon"
    }
  }, [_vm._v(_vm._s((_vm$requirementData2 = _vm.requirementData) !== null && _vm$requirementData2 !== void 0 && _vm$requirementData2.customerReasonForBalloonExplanation ? (_vm$requirementData3 = _vm.requirementData) === null || _vm$requirementData3 === void 0 ? void 0 : _vm$requirementData3.customerReasonForBalloonExplanation : _vm.LABEL_NOT_AVAILABLE))])])]) : _vm._e(), _vm._v(" "), _c('el-col', {
    staticClass: "mb-4",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_LOAN_TREM))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2",
    attrs: {
      "data-selector": "d-ls-saved-loan-term"
    }
  }, [_vm._v(_vm._s(_vm.requirementData.loanDetails.approval.loanTermYears ? _vm.yearViewOption(_vm.requirementData.loanDetails.approval.loanTermYears) : _vm.LABEL_NOT_AVAILABLE))])])])], 1)], 1), _vm._v(" "), _c('el-card', [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_RATE_AND_FEE))])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_BASE_RATE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2",
    attrs: {
      "data-selector": "d-ls-saved-lender-base-rate"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.requirementData.loanDetails.approval.baseRate >= 0 ? _vm.requirementData.loanDetails.approval.baseRate : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_MONTHLY_REPAYMENT_AMOUNT))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2",
    attrs: {
      "data-selector": "d-ls-saved-monthly-repayment"
    }
  }, [_vm._v(_vm._s(_vm.requirementData.loanDetails.approval.monthlyRepayment >= 0 ? _vm.numberWithCommas(_vm.requirementData.loanDetails.approval.monthlyRepayment) : _vm.LABEL_NOT_AVAILABLE))])])])], 1), _c('br'), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_CUSTOMER_RATE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2",
    attrs: {
      "data-selector": "d-ls-saved-customer-rate"
    }
  }, [_vm._v(_vm._s(_vm.requirementData.loanDetails.approval.customerRate >= 0 ? _vm.requirementData.loanDetails.approval.customerRate : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_LENDER_MONTHLY_FEE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2",
    attrs: {
      "data-selector": "d-ls-saved-account-keeping-fee"
    }
  }, [_vm._v(_vm._s(_vm.requirementData.loanDetails.approval.accountKeepingFee >= 0 ? _vm.numberWithCommas(_vm.requirementData.loanDetails.approval.accountKeepingFee) : _vm.LABEL_NOT_AVAILABLE))])])])], 1), _c('br'), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_LENDER_FEE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2",
    attrs: {
      "data-selector": "d-ls-saved-lender-est-fee"
    }
  }, [_vm._v(_vm._s(_vm.requirementData.loanDetails.approval.lenderEstablishmentFee >= 0 ? _vm.numberWithCommas(_vm.requirementData.loanDetails.approval.lenderEstablishmentFee) : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_PSPR_FEE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2",
    attrs: {
      "data-selector": "d-ls-saved-ppsr-fee"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.requirementData.loanDetails.approval.ppsrFee >= 0 ? _vm.numberWithCommas(_vm.requirementData.loanDetails.approval.ppsrFee) : _vm.LABEL_NOT_AVAILABLE))])])])], 1), _c('br'), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_ORIGINATION_FEE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2",
    attrs: {
      "data-selector": "d-ls-saved-broker-fee"
    }
  }, [_vm._v(_vm._s(_vm.requirementData.loanDetails.approval.brokerFee >= 0 ? _vm.numberWithCommas(_vm.requirementData.loanDetails.approval.brokerFee) : _vm.LABEL_NOT_AVAILABLE))])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_PARTNER_REFERRAL_FEE))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2",
    attrs: {
      "data-selector": "d-ls-saved-partner-fin-ref-fee"
    }
  }, [_vm._v(_vm._s(_vm.requirementData.loanDetails.approval.partnerFinanceReferralFee >= 0 ? _vm.numberWithCommas(_vm.requirementData.loanDetails.approval.partnerFinanceReferralFee) : _vm.LABEL_NOT_AVAILABLE))])])])], 1), _c('br'), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_LENDING_COMMISSION))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-2",
    attrs: {
      "data-selector": "d-ls-saved-lending-commission"
    }
  }, [_vm._v(_vm._s(_vm.requirementData.loanDetails.approval.lendingCommision >= 0 ? _vm.numberWithCommas(_vm.requirementData.loanDetails.approval.lendingCommision) : _vm.LABEL_NOT_AVAILABLE))])])])], 1)], 1)], 1) : _vm._e(), _vm._v(" "), !_vm.isEditMode && _vm.isEditMode !== undefined && !_vm.applicationSubmitted && !_vm.applicationDeclined && !_vm.applicationWithdrawn && _vm.requirementData.loanDetails.approval && _vm.requirementData.loanDetails.approval.outcomeOfAnApplication ? _c('div', [_c('el-card', [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "d-loan-details-header-text"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_LOAN_DETAILS))])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE,
      "prop": "loanDetails.approval.purchasePrice",
      "data-selector": "d-purchase-price-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "d-purchase-price-input"
    },
    on: {
      "input": function ($event) {
        return _vm.calculateBallonAmount();
      }
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.purchasePrice,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "purchasePrice", $$v);
      },
      expression: "requirementData.loanDetails.approval.purchasePrice"
    }
  })], 1)], 1), _vm._v(" "), ((_vm$requirementData4 = _vm.requirementData) === null || _vm$requirementData4 === void 0 ? void 0 : (_vm$requirementData4$ = _vm$requirementData4.tradeIn) === null || _vm$requirementData4$ === void 0 ? void 0 : _vm$requirementData4$.type) === _vm.APPLICATION_TRADE_IN_VEHICLE ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    staticClass: "child-lh-normal",
    attrs: {
      "label": "",
      "prop": "loanDetails.submission.tradeInAmount"
    }
  }, [_c('label', {
    staticClass: "inline-block mb-2 line-height-normal",
    attrs: {
      "for": "cheese"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.LABEL_TRADE_IN_EQUITY) + "\n              ")]), _vm._v(" "), _c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    model: {
      value: _vm.requirementData.loanDetails.approval.tradeInAmount,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "tradeInAmount", $$v);
      },
      expression: "requirementData.loanDetails.approval.tradeInAmount"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_vm.remainingLiability > 0 ? _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_TRADING_LIABILITIES
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.remainingLiability,
      callback: function ($$v) {
        _vm.remainingLiability = $$v;
      },
      expression: "remainingLiability"
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _vm.requirementData.vehicle ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_DEPOSIT,
      "prop": "loanDetails.approval.depositAmount",
      "data-selector": "d-deposit-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "d-deposit-input"
    },
    on: {
      "input": function ($event) {
        return _vm.onInput('loanDetails.approval.depositAmount');
      }
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.depositAmount,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "depositAmount", $$v);
      },
      expression: "requirementData.loanDetails.approval.depositAmount"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_NAF,
      "prop": "loanDetails.approval.netAmountFinanced",
      "data-selector": "d-naf-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "d-naf-input"
    },
    on: {
      "input": function ($event) {
        return _vm.onInput('loanDetails.approval.netAmountFinanced');
      }
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.netAmountFinanced,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "netAmountFinanced", $$v);
      },
      expression: "\n                  requirementData.loanDetails.approval.netAmountFinanced\n                "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_BALLOON,
      "prop": "loanDetails.approval.balloonPct",
      "data-selector": "d-balloon-percent-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrictdecimal",
      rawName: "v-restrictdecimal.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "text",
      "autocomplete": "off",
      "data-selector": "d-balloon-percent-input"
    },
    on: {
      "input": function ($event) {
        return _vm.calculateBallonAmount();
      }
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.balloonPct,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "balloonPct", $$v);
      },
      expression: "requirementData.loanDetails.approval.balloonPct"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_LOAN_BALLOON_DOLLAR,
      "prop": "loanDetails.approval.balloonAmount",
      "data-selector": "d-balloon-amt-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "d-balloon-amt-input"
    },
    on: {
      "input": function ($event) {
        return _vm.calculateBallonPct();
      }
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.balloonAmount,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "balloonAmount", $$v);
      },
      expression: "requirementData.loanDetails.approval.balloonAmount"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_LOAN_TREM,
      "prop": "loanDetails.approval.loanTermYears",
      "data-selector": "d-loan-term-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.loanTermYears,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "loanTermYears", $$v);
      },
      expression: "requirementData.loanDetails.approval.loanTermYears"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "d-loan-term-options-container"
    }
  }, _vm._l(_vm.LOAN_TERM_YEAR_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.value,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1), _vm._v(" "), _vm.hasBalloonPctHasValue && !_vm.isCommercial ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_REASON_FOR_BALLOON_PAYMENT,
      "prop": "customerReasonForBalloon",
      "data-selector": "d-reason-for-balloon-input-container"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.requirementData.customerReasonForBalloon,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData, "customerReasonForBalloon", $$v);
      },
      expression: "requirementData.customerReasonForBalloon"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "d-reason-for-balloon-options-container"
    }
  }, _vm._l(_vm.REASON_FOR_BALLOON_PAYMENT_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.value,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.requirementData.customerReasonForBalloon === _vm.LABEL_BALLOON_REPAYMENT_OTHER ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12,
      "md": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_REASON_FOR_BALLOON_PAYMENT,
      "prop": "customerReasonForBalloonExplanation"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "placeholder": _vm.LABEL_BALLOON_REPAYMENT_OTHER,
      "autocomplete": "off",
      "data-selector": "d-other-reason-balloon-input"
    },
    model: {
      value: _vm.requirementData.customerReasonForBalloonExplanation,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData, "customerReasonForBalloonExplanation", $$v);
      },
      expression: "requirementData.customerReasonForBalloonExplanation"
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c('el-card', [_c('div', {
    staticClass: "clearfix",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_RATE_AND_FEE))])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_BASE_RATE,
      "prop": "loanDetails.approval.baseRate",
      "data-selector": "d-lender-base-rate-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrictdecimal",
      rawName: "v-restrictdecimal.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "max": 100,
      "data-selector": "d-lender-base-rate-input"
    },
    on: {
      "input": function ($event) {
        return _vm.onInput('loanDetails.approval.baseRate');
      }
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.baseRate,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "baseRate", $$v);
      },
      expression: "requirementData.loanDetails.approval.baseRate"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_MONTHLY_REPAYMENT_AMOUNT,
      "prop": "loanDetails.approval.monthlyRepayment",
      "data-selector": "d-monthly-repayment-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "d-monthly-repayment-input"
    },
    on: {
      "input": function ($event) {
        return _vm.onInput('loanDetails.approval.monthlyRepayment');
      }
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.monthlyRepayment,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "monthlyRepayment", $$v);
      },
      expression: "\n                  requirementData.loanDetails.approval.monthlyRepayment\n                "
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_CUSTOMER_RATE,
      "prop": "loanDetails.approval.customerRate",
      "data-selector": "d-customer-rate-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrictdecimal",
      rawName: "v-restrictdecimal.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "max": 100,
      "data-selector": "d-customer-rate-input"
    },
    on: {
      "input": function ($event) {
        return _vm.onInput('loanDetails.approval.customerRate');
      }
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.customerRate,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "customerRate", $$v);
      },
      expression: "requirementData.loanDetails.approval.customerRate"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_LENDER_MONTHLY_FEE,
      "prop": "loanDetails.approval.accountKeepingFee",
      "data-selector": "d-account-keeping-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "number",
      "autocomplete": "off",
      "data-selector": "d-account-keeping-input"
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.accountKeepingFee,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "accountKeepingFee", $$v);
      },
      expression: "\n                  requirementData.loanDetails.approval.accountKeepingFee\n                "
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_LENDER_FEE,
      "prop": "loanDetails.approval.lenderEstablishmentFee",
      "data-selector": "d-lender-est-fee-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "d-lender-est-fee-input"
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.lenderEstablishmentFee,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "lenderEstablishmentFee", $$v);
      },
      expression: "\n                  requirementData.loanDetails.approval.lenderEstablishmentFee\n                "
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_PSPR_FEE,
      "prop": "loanDetails.approval.ppsrFee",
      "data-selector": "d-ppsr-fee-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "d-ppsr-fee-input"
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.ppsrFee,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "ppsrFee", $$v);
      },
      expression: "requirementData.loanDetails.approval.ppsrFee"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_ORIGINATION_FEE,
      "prop": "loanDetails.approval.brokerFee",
      "data-selector": "d-broker-fee-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "d-broker-fee-input"
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.brokerFee,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "brokerFee", $$v);
      },
      expression: "requirementData.loanDetails.approval.brokerFee"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_PARTNER_REFERRAL_FEE,
      "prop": "loanDetails.approval.partnerFinanceReferralFee",
      "data-selector": "d-partner-fin-ref-fee-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "disabled": true,
      "data-selector": "d-partner-fin-ref-fee-input"
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.partnerFinanceReferralFee,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "partnerFinanceReferralFee", $$v);
      },
      expression: "\n                  requirementData.loanDetails.approval\n                    .partnerFinanceReferralFee\n                "
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_LENDING_COMMISSION,
      "prop": "loanDetails.approval.lendingCommision",
      "data-selector": "d-lending-commission-input-container"
    }
  }, [_c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "data-selector": "d-lending-commission-input"
    },
    model: {
      value: _vm.requirementData.loanDetails.approval.lendingCommision,
      callback: function ($$v) {
        _vm.$set(_vm.requirementData.loanDetails.approval, "lendingCommision", $$v);
      },
      expression: "\n                  requirementData.loanDetails.approval.lendingCommision\n                "
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm._v(" "), !_vm.isApplicationApproved && !_vm.isApplicationDeclined && !_vm.isApplicationWithdrawn ? _c('el-row', {
    staticClass: "mt-4 mb-8 justify-end flex"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary",
      "loading": _vm.submitBtnLoading || _vm.btnLoading,
      "data-selector": "submit-decision-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.checkZeroValFields();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_SUBMIT_DECISION_DETAILS) + "\n      ")])], 1) : _vm._e()], 1), _vm._v(" "), _c('LoanWithdrawDialog', {
    attrs: {
      "type": "sendProposal",
      "decision-data": _vm.sharedData,
      "dialog-opened": _vm.withdrawDialog,
      "title": "Withdraw Application",
      "subtitle": "Are you sure you want to withdraw the application?",
      "reason": "Withdrawn Reason"
    }
  }), _vm._v(" "), _c('CloneApplicationDialog', _vm._b({
    attrs: {
      "dialog-opened": _vm.cloneApplicationDialog,
      "title": "Clone Application",
      "subtitle": "Are you sure you want to clone the current application?"
    },
    on: {
      "closeCloneApplcationDialog": _vm.handleCloneModel
    }
  }, 'CloneApplicationDialog', Object.assign({}, _vm.$props), false)), _vm._v(" "), _c('MissingFieldsModal', {
    attrs: {
      "dialog-opened": _vm.openMissingFieldsModal,
      "title": _vm.LABEL_TITLE_FOR_MISSING_FIELDS_MODEL,
      "subtitle": _vm.LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_FIRST + '<b> ' + _vm.APPLICATION_STATUS_APPROVED + '</b>' + _vm.LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_SECOND,
      "is-commercial": _vm.isCommercial,
      "status": _vm.APPLICATION_STATUS_APPROVED,
      "errors-data": _vm.errorsdata,
      "application-data": _vm.sharedData
    },
    on: {
      "closeMissingFieldsModalApproval": _vm.closeMissingFieldsModalApproval
    }
  }), _vm._v(" "), _c('ZeroConfirmationDialog', {
    attrs: {
      "dialog-opened": _vm.openZeroConfirmationDialog,
      "title": _vm.LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_TITLE,
      "subtitle": _vm.LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_DISCRIPTION,
      "zero-fields-data": _vm.allZeroValueFieldsData,
      "submission-type": _vm.MAINTAB_LOANDETAILS_DECISION_LABEL,
      "zero-value-reason": (_this$requirementData = this.requirementData) === null || _this$requirementData === void 0 ? void 0 : (_this$requirementData2 = _this$requirementData.loanDetails) === null || _this$requirementData2 === void 0 ? void 0 : (_this$requirementData3 = _this$requirementData2.submission) === null || _this$requirementData3 === void 0 ? void 0 : _this$requirementData3.zeroValueReason,
      "zero-value-reason-type": (_this$requirementData4 = this.requirementData) === null || _this$requirementData4 === void 0 ? void 0 : (_this$requirementData5 = _this$requirementData4.loanDetails) === null || _this$requirementData5 === void 0 ? void 0 : (_this$requirementData6 = _this$requirementData5.submission) === null || _this$requirementData6 === void 0 ? void 0 : _this$requirementData6.zeroValueReasonType
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }