// gets the last 6 characters of an ID
export const shortId = (str = '') => String(str).slice(-6)

export const upperCaseFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

export const formatNumber = (number = 0) =>
  new Intl.NumberFormat('en-AU', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(number)
    .replace(/\D00$/, '')

export const toCurrency = (number = 0, minFraction = 0, maxFraction = 2) =>
  new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: minFraction,
    maximumFractionDigits: maxFraction,
  })
    .format(number)
    .replace(/\D00$/, '')
