//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  LABEL_LOAN_SEND_PROPSAL,
  LABEL_LOAN_SUBMIT_APPLICATION,
  LABEL_GO_TO_CONTACT_LIST,
  LABEL_CANCEL,
  LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
  LABEL_ONE,
  LABEL_TWO,
} from '@/constants/applications'
import {
  TITLE_FOR_UPDATE_BUSINESS_STRUCTURE,
  DESCRIPTION_FOR_UPDATE_BUSINESS_STRUCTURE_FIRST_PART,
  DESCRIPTION_FOR_UPDATE_BUSINESS_STRUCTURE_SECOND_PART_ONE,
  DESCRIPTION_FOR_UPDATE_BUSINESS_STRUCTURE_SECOND_PART_TWO,
} from '~/constants'

export default {
  name: 'LoanDetails',
  props: {
    dialogOpened: {
      type: Boolean,
      default: () => false,
    },
    oldBusinessStructure: {
      type: String,
      default: () => null,
    },
    newBusinessStructure: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      LABEL_LOAN_SEND_PROPSAL,
      LABEL_LOAN_SUBMIT_APPLICATION,
      LABEL_GO_TO_CONTACT_LIST,
      LABEL_CANCEL,
      getIsDialogLoading: false,
      TITLE_FOR_UPDATE_BUSINESS_STRUCTURE,
      DESCRIPTION_FOR_UPDATE_BUSINESS_STRUCTURE_FIRST_PART,
      DESCRIPTION_FOR_UPDATE_BUSINESS_STRUCTURE_SECOND_PART_ONE,
      DESCRIPTION_FOR_UPDATE_BUSINESS_STRUCTURE_SECOND_PART_TWO,
      LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
    }
  },
  computed: {
    businessStructureDescription() {
      return (
        'A <b> ' +
        this.newBusinessStructure +
        ' </b> ' +
        DESCRIPTION_FOR_UPDATE_BUSINESS_STRUCTURE_SECOND_PART_ONE +
        ' <b> ' +
        (this.newBusinessStructure !== LABEL_BUSINESS_STRUCTURE_PARTNERSHIP
          ? LABEL_ONE
          : LABEL_TWO) +
        ' </b> ' +
        DESCRIPTION_FOR_UPDATE_BUSINESS_STRUCTURE_SECOND_PART_TWO
      )
    },
  },
  methods: {
    closeDialog() {
      this.$nuxt.$emit('close-alert-dialog', this.type)
    },
    handleClick() {
      this.$nuxt.$emit('update-business-structure')
    },
  },
}
