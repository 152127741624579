export const getBalloonPct = (
  balloonAmount = 0,
  purchasePrice = 0,
  tradeInAmount = 0,
  depositAmount = 0
) => {
  return (balloonAmount * 100) / (purchasePrice - tradeInAmount - depositAmount)
}

export const getBalloonPctWithApi = async (amount = 0, balloonAmount = 0) => {
  const responseData = await fetch(
    `${process.env.VUE_APP_TORQ_MATCHES_API_URL}/helpers/calc/balloon?amount=${amount}&balloonAmount=${balloonAmount}`,
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
      },
    }
  ).then((response) => {
    if (response.status !== 204) {
      return response.json()
    }
    return
  })
  return responseData.balloonPct >= 0 ? responseData.balloonPct : 0
}
