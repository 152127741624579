import { APPLICATION_STATUS_WITHDRAWN } from '~/constants'
import {
  LABEL_BLUE_TAG,
  LABEL_FOR_CALLBACK_TIME,
  LABEL_FOR_NO_OF_ATTEMPTS,
  LABEL_GREEN_TAG,
  LABEL_RED_TAG,
  LABEL_STATUS_INFO_TEXT_BLACK,
  LABEL_STATUS_INFO_TEXT_RED,
  REFERRAL_LEAD_STATUS_APPLICATION_IN_PROGRESS,
  REFERRAL_LEAD_STATUS_APPLICATION_WITHDRAWN,
  REFERRAL_LEAD_STATUS_APPROVED,
  REFERRAL_LEAD_STATUS_CALLBACK_REQUESTED,
  REFERRAL_LEAD_STATUS_DECLINED,
  REFERRAL_LEAD_STATUS_EXHAUSTED,
  REFERRAL_LEAD_STATUS_IN_PROGRESS,
  REFERRAL_LEAD_STATUS_LOST,
  REFERRAL_LEAD_STATUS_NEW,
  REFERRAL_LEAD_STATUS_PENDING_SETTLEMENT,
  REFERRAL_LEAD_STATUS_SUBMITTED_TO_LENDER,
  REFERRAL_LEAD_STATUS_UNQUALIFIED,
  REFERRAL_LEAD_STATUS_WON,
} from '~/constants/applications'
import { format } from 'date-fns'

export default {
  data() {
    return {
      breakpoints: {
        xs: 320,
        sm: 480,
        md: 768,
        lg: 992,
        xl: 1280,
        '2xl': 1536,
        mobile580: 580,
        xs: 0,
      },
      viewport: {
        width: Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        ),
        height: Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        ),
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.viewport = {
        width: Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        ),
        height: Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        ),
      }
    },
    status_tag(val) {
      if (
        [
          REFERRAL_LEAD_STATUS_NEW,
          REFERRAL_LEAD_STATUS_IN_PROGRESS,
          REFERRAL_LEAD_STATUS_CALLBACK_REQUESTED,
          REFERRAL_LEAD_STATUS_APPLICATION_IN_PROGRESS,
          REFERRAL_LEAD_STATUS_SUBMITTED_TO_LENDER,
          REFERRAL_LEAD_STATUS_PENDING_SETTLEMENT,
        ].includes(val)
      ) {
        return LABEL_BLUE_TAG
      } else if (
        [
          REFERRAL_LEAD_STATUS_LOST,
          REFERRAL_LEAD_STATUS_UNQUALIFIED,
          REFERRAL_LEAD_STATUS_EXHAUSTED,
          REFERRAL_LEAD_STATUS_APPLICATION_WITHDRAWN,
          REFERRAL_LEAD_STATUS_DECLINED,
        ].includes(val)
      ) {
        return LABEL_RED_TAG
      } else if (
        [REFERRAL_LEAD_STATUS_APPROVED, REFERRAL_LEAD_STATUS_WON].includes(val)
      ) {
        return LABEL_GREEN_TAG
      }
      return LABEL_BLUE_TAG
    },
    getStatusInfoStyle(val) {
      if (val === REFERRAL_LEAD_STATUS_UNQUALIFIED) {
        return LABEL_STATUS_INFO_TEXT_RED
      } else if (val === REFERRAL_LEAD_STATUS_LOST) {
        return LABEL_STATUS_INFO_TEXT_RED
      } else if (val === REFERRAL_LEAD_STATUS_APPLICATION_WITHDRAWN) {
        return LABEL_STATUS_INFO_TEXT_RED
      } else if (val === REFERRAL_LEAD_STATUS_IN_PROGRESS) {
        return LABEL_STATUS_INFO_TEXT_BLACK
      } else if (val === REFERRAL_LEAD_STATUS_CALLBACK_REQUESTED) {
        return LABEL_STATUS_INFO_TEXT_BLACK
      } else {
        return LABEL_STATUS_INFO_TEXT_BLACK
      }
    },
    getStatusInfo(val, referralData) {
      if (val === REFERRAL_LEAD_STATUS_UNQUALIFIED) {
        return referralData?.__original?.sFLead?.unqualifiedReason
      } else if (val === REFERRAL_LEAD_STATUS_LOST) {
        return referralData?.__original?.sFLead?.lostReason
      } else if (val === REFERRAL_LEAD_STATUS_APPLICATION_WITHDRAWN) {
        return referralData?.__original?.application?.status ===
          APPLICATION_STATUS_WITHDRAWN
          ? referralData?.__original?.application?.withdrawnReasonType
          : ''
      } else if (val === REFERRAL_LEAD_STATUS_IN_PROGRESS) {
        return referralData?.__original?.sFLead?.callAttemptsCount
          ? `${LABEL_FOR_NO_OF_ATTEMPTS} ${referralData?.__original?.sFLead?.callAttemptsCount}`
          : ''
      } else if (val === REFERRAL_LEAD_STATUS_CALLBACK_REQUESTED) {
        if (referralData?.__original?.sFLead?.callbackAt) {
          return (
            `${LABEL_FOR_CALLBACK_TIME} ` +
            format(
              new Date(referralData?.__original?.sFLead?.callbackAt),
              'dd-MM-yyyy hh:mm aaa'
            )
          )
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
  },
  computed: {
    isSmallMobile() {
      return this.viewport.width < this.breakpoints.xs
    },
    isMobile() {
      return !this.isTablet && !this.isDesktop
    },
    isLargeTablet() {
      return this.viewport.width >= this.breakpoints.md && !this.isDesktop
    },
    isTablet() {
      return this.viewport.width >= this.breakpoints.sm && !this.isDesktop
    },
    isDesktop() {
      return this.viewport.width >= this.breakpoints.xl
    },
    isMobile580() {
      return (
        this.viewport.width <= this.breakpoints.mobile580 && !this.isDesktop
      )
    },
  },
}
