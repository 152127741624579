var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-row', {
    staticClass: "mt-4 mb-8 justify-end flex"
  }, [_vm.showBackButton ? _c('div', {
    staticClass: "border-r border-light-grey mr-6 pr-6"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "data-selector": "back-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.goBack();
      }
    }
  }, [_vm._v("Back")])], 1) : _vm._e(), _vm._v(" "), _vm.showSaveBtn ? _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "loading": _vm.saveBtnLoading,
      "data-selector": "save-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm();
      }
    }
  }, [_vm._v(_vm._s(_vm.saveBtnLabel))]) : _vm._e(), _vm._v(" "), _vm.showNextButton ? _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary",
      "loading": _vm.saveAndNextBtnLoading,
      "data-selector": "save-next-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.saveAndNext();
      }
    }
  }, [_vm._v(_vm._s(_vm.saveAndNextBtnLabel))]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }