import orderBy from 'lodash/orderBy'
import dashboardService from '~/services/pagination.service'

export default {
  async fetchApplications(
    { commit },
    {
      status = 'All',
      currentPage = 1,
      search = '',
      type = 'Consumer',
      sortDirection = 'desc',
    }
  ) {
    try {
      commit('startTableLoading')
      const { data } = await dashboardService.getTableDataAPI({
        status,
        currentPage,
        search,
        sortOrder: sortDirection,
        type,
        isFinance: true,
        ...this,
      })
      commit('setTableSettings', {
        totalDocs: data?.totalDocs ?? 0,
        page: data?.page ?? 1,
        totalPages: data?.totalPages ?? 0,
        limit: data?.limit ?? 0,
        pagingCounter: data.pagingCounter,
      })

      let tableData = data.docs.map((vrg) => {
        vrg.Id = vrg.finance.applications[0].shortId
        vrg.Client = vrg.user.firstName + ' ' + vrg.user.lastName
        vrg.Type = vrg.finance.applications[0].applicationType
          ? vrg.finance.applications[0].applicationType
          : '--'
        vrg.Status = vrg?.finance?.applications[0]?.status ?? '--'
        vrg.Owner = '--'
        vrg.createdAt = new Date(vrg.createdAt).toDateString()
        if (!vrg.status) {
          vrg.status = 'Open'
        }
        return vrg
      })
      tableData = orderBy(
        tableData,
        'buying.requests.completedLeadApplicationAt',
        'desc'
      )
      commit('setApplications', tableData)
    } catch (error) {
      this.$notify.error({
        title: 'Error',
        message: 'Error getting the requests',
      })
    } finally {
      commit('stopTableLoading')
    }
  },
}
