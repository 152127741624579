import { USER_SESSION_DATA_KEY } from '~/constants'
import { changeRoute } from '~/helpers/changeRoute'
import { COMMON_LOGIN_ROUTE } from '~/helpers/portal'

export default {
  methods: {
    async onLogout() {
      await this.$auth.logout()
      this.$auth.$storage.removeUniversal(USER_SESSION_DATA_KEY)
      await changeRoute({
        route: this.$route,
        router: this.$router,
        path: COMMON_LOGIN_ROUTE,
      })
    },
    submit(data) {
      console.log('data ', data)
    },
    gotoNext(currentTab) {
      console.log('goTo Next ', currentTab)
    },
  },
}
