//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    className: {
      type: String,
      default: '',
    },
    formClassName: {
      type: String,
      default: '',
    },
    formProp: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    selectOptions: {
      type: Array,
      default: () => [],
    },
    formRule: {
      type: Array,
      default: () => [],
    },
    optionsContainerDs: {
      type: String,
      default: '',
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
    }
  },
  watch: {
    value() {
      this.inputValue = this.value
    },
  },
  methods: {
    onInput(selectedValue) {
      this.$emit('onInput', selectedValue)
    },
    onChange(selectedValue) {
      this.$emit('onChange', selectedValue)
    },
    onBlur(event) {
      this.$emit('onBlur', event)
    },
  },
}
