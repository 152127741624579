var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }],
    staticClass: "dailog-spacing-none",
    attrs: {
      "visible": _vm.dialogOpened,
      "width": "832px",
      "height": "904px",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "destroy-on-close": "",
      "show-close": false,
      "data-selector": "cpd-modal"
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogOpened = $event;
      }
    }
  }, [_vm.isSettlement || _vm.isPreSettlement ? _c('div', {
    staticClass: "text-center py-5",
    staticStyle: {
      "max-width": "600px",
      "margin": "auto"
    }
  }, [_c('h3', {
    staticClass: "text-sm text-black pb-4 break-word"
  }, [_vm._v(_vm._s(_vm.isSettlement ? _vm.LABEL_TITLE_FOR_CPD_VIEW_AND_SEND_SETTLEMENT : _vm.LABEL_TITLE_FOR_CPD_VIEW_AND_SEND) + "\n      "), _c('br'), _vm._v("\n      " + _vm._s(_vm.LABLE_TITLE_FOR_CPD_SEND) + "\n    ")]), _vm._v(" "), _c('p', {
    staticClass: "text-grey-dark",
    staticStyle: {
      "word-break": "break-word"
    }
  }, [_vm._v(_vm._s(_vm.isSettlement ? _vm.LABEL_DESCRIPTION_FOR_CPD_VIEW_AND_SEND_SETTLEMENT : _vm.LABEL_DESCRIPTION_FOR_CPD_VIEW_AND_SEND))])]) : _vm._e(), _vm._v(" "), _c('iframe', {
    attrs: {
      "src": _vm.getCpdUrl,
      "width": "100%",
      "height": "608px",
      "data-selector": "m-cpd-iframe"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "py-4 px-4 flex items-center"
  }, [_c('el-button', {
    attrs: {
      "type": "text",
      "data-selector": "m-cpd-cancel"
    },
    on: {
      "click": function ($event) {
        return _vm.closeDialog(false);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))]), _vm._v(" "), _c('el-checkbox', {
    staticClass: "mt-2 ml-auto",
    attrs: {
      "data-selector": "m-cpd-reviewed"
    },
    model: {
      value: _vm.checked,
      callback: function ($$v) {
        _vm.checked = $$v;
      },
      expression: "checked"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_I_HAVE_REVIEWED_COMPLETE_CPD))]), _vm._v(" "), _c('el-button', {
    staticClass: "ml-5",
    attrs: {
      "disabled": !_vm.checked,
      "type": "primary",
      "data-selector": "m-cpd-send"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm();
      }
    }
  }, [_vm._v(_vm._s(_vm.submitButtonLabel))])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }