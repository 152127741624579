var render = function () {
  var _vm$primaryApplicantD, _vm$primaryApplicantD2, _vm$primaryApplicantD3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-container', [_c('div', [(_vm.hasPartners || _vm.hasTrustCompany || _vm.isCompany) && !_vm.hasNoOfDirectorsMatched ? _c('NotificationBar', {
    staticClass: "el-alert--warning el-alert-message-warning mb-4",
    attrs: {
      "message-type": "warning",
      "description": _vm.warningMessage,
      "has-button": false,
      "data-selector": "num-dirs-mismatch-alert"
    }
  }) : _vm._e(), _vm._v(" "), _c('el-card', {
    staticClass: "application-form"
  }, [(_vm$primaryApplicantD = _vm.primaryApplicantData) !== null && _vm$primaryApplicantD !== void 0 && _vm$primaryApplicantD.userData ? _c('el-form', {
    ref: "primaryApplicantData",
    staticClass: "commercialPersonalForm",
    attrs: {
      "rules": _vm.rulesPrimary,
      "model": _vm.primaryApplicantData
    }
  }, [_vm.showBusinessDetailsSection ? [_c('div', {
    staticClass: "clearfix card-header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "business-details-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_BUSINESS_DETAILS))])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_SHAREHOLDING_PERCENTAGE,
      "prop": "shareholdingPercentage",
      "rules": _vm.rulesPrimary.shareholdingPercentage,
      "data-selector": "share-percent-input-container"
    }
  }, [_c('el-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "value": _vm.primaryApplicantData.shareholdingPercentage,
      "data-selector": "share-percent-input"
    },
    on: {
      "input": _vm.handleShareholdingPercentage
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_DIRECTORSHIP_START_DATE,
      "prop": "directorshipStartDate",
      "rules": _vm.rulesPrimary.directorshipStartDate,
      "data-selector": "dirship-sd-input-container"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "format": "dd/MM/yyyy",
      "picker-options": _vm.dateOfBirthSort,
      "placeholder": "DD/MM/YYYY"
    },
    model: {
      value: _vm.primaryApplicantData.directorshipStartDate,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData, "directorshipStartDate", $$v);
      },
      expression: "primaryApplicantData.directorshipStartDate"
    }
  })], 1)], 1)], 1)] : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "clearfix card-header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "basic-details-header"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_BASIC_DETAILS))])]), _vm._v(" "), (_vm$primaryApplicantD2 = _vm.primaryApplicantData) !== null && _vm$primaryApplicantD2 !== void 0 && _vm$primaryApplicantD2.userData ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_vm.primaryApplicantData && (_vm$primaryApplicantD3 = _vm.primaryApplicantData) !== null && _vm$primaryApplicantD3 !== void 0 && _vm$primaryApplicantD3.userData ? _c('el-form-item', {
    attrs: {
      "label": "Prefix",
      "prop": "userData.salutation",
      "data-selector": "prefix-input"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.primaryApplicantData.userData.salutation,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "salutation", $$v);
      },
      expression: "primaryApplicantData.userData.salutation"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "prefix-options-container"
    }
  }, _vm._l(_vm.APPLICANT_PREFIXES_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "First Name",
      "prop": "userData.firstName",
      "data-selector": "firstname-input"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.primaryApplicantData.userData.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "firstName", $$v);
      },
      expression: "primaryApplicantData.userData.firstName"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Middle name",
      "prop": "userData.middleName",
      "data-selector": "middlename-input"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.primaryApplicantData.userData.middleName,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "middleName", $$v);
      },
      expression: "primaryApplicantData.userData.middleName"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Last Name",
      "prop": "userData.lastName",
      "data-selector": "lastname-input"
    }
  }, [_c('el-input', {
    model: {
      value: _vm.primaryApplicantData.userData.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "lastName", $$v);
      },
      expression: "primaryApplicantData.userData.lastName"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Gender",
      "prop": "userData.gender",
      "data-selector": "gender-input"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.primaryApplicantData.userData.gender,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "gender", $$v);
      },
      expression: "primaryApplicantData.userData.gender"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "gender-options-container"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "Male",
      "value": "Male"
    }
  }), _vm._v(" "), _c('el-option', {
    attrs: {
      "label": "Female",
      "value": "Female"
    }
  }), _vm._v(" "), _c('el-option', {
    attrs: {
      "label": "Other",
      "value": "Other"
    }
  }), _vm._v(" "), _c('el-option', {
    attrs: {
      "label": "Unknown",
      "value": "Unknown"
    }
  })], 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Date of birth",
      "prop": "userData.dateOfBirth",
      "data-selector": "dob-input"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "format": "dd/MM/yyyy",
      "picker-options": _vm.dateOfBirthSort,
      "placeholder": "DD/MM/YYYY"
    },
    model: {
      value: _vm.primaryApplicantData.userData.dateOfBirth,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "dateOfBirth", $$v);
      },
      expression: "primaryApplicantData.userData.dateOfBirth"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "clearfix card-header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v("Contact details")])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "userData.email",
      "label": "Email",
      "data-selector": "email-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "name@domain.com",
      "disabled": _vm.isEditMode,
      "data-selector": "email-input"
    },
    model: {
      value: _vm.primaryApplicantData.userData.email,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "email", $$v);
      },
      expression: "primaryApplicantData.userData.email"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Mobile",
      "prop": "userData.mobile",
      "rules": _vm.mobileNumberRules,
      "data-selector": "phone-input-container"
    }
  }, [_c('el-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "text",
      "placeholder": "04XXXXXXXXX",
      "disabled": _vm.isEditMode,
      "data-selector": "phone-input"
    },
    model: {
      value: _vm.primaryApplicantData.userData.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "mobile", $$v);
      },
      expression: "primaryApplicantData.userData.mobile"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Phone (Home)",
      "prop": "userData.homePhone",
      "data-selector": "phone-home-input-container"
    }
  }, [_c('el-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "text",
      "placeholder": "XXXXXXXX",
      "disabled": _vm.isEditMode,
      "data-selector": "phone-home-input"
    },
    model: {
      value: _vm.primaryApplicantData.userData.homePhone,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "homePhone", $$v);
      },
      expression: "primaryApplicantData.userData.homePhone"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "clearfix card-header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v("Residency")])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Status",
      "prop": "residencyStatus",
      "data-selector": "residency-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.primaryApplicantData.residencyStatus,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData, "residencyStatus", $$v);
      },
      expression: "primaryApplicantData.residencyStatus"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "residency-options-container"
    }
  }, _vm._l(_vm.RESIDENCY_STATUS_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1), _vm._v(" "), _vm.primaryApplicantData.residencyStatus === _vm.RESIDENCY_STATUS_VISA_OTHER_VALUE ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_VISA_SUBCLASS,
      "prop": "visaOther",
      "data-selector": "visa-other-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "data-selector": "visa-other-input"
    },
    model: {
      value: _vm.primaryApplicantData.visaOther,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData, "visaOther", $$v);
      },
      expression: "primaryApplicantData.visaOther"
    }
  })], 1)], 1) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "clearfix card-header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v("Relationship")])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Status",
      "prop": "relationshipStatus",
      "data-selector": "relationship-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.primaryApplicantData.relationshipStatus,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData, "relationshipStatus", $$v);
      },
      expression: "primaryApplicantData.relationshipStatus"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "relationship-options-container"
    }
  }, _vm._l(_vm.APPLICANT_RELATIONSHIP_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Dependants",
      "prop": "numberOfDependants",
      "data-selector": "dependants-input"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "value": _vm.primaryApplicantData.numberOfDependants,
      "placeholder": "select"
    },
    on: {
      "change": _vm.handleDependantChange
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "dependants-options-container"
    }
  }, _vm._l(11, function (n, i) {
    return _c('el-option', {
      key: i,
      attrs: {
        "label": i,
        "value": i
      }
    });
  }), 1)])], 1), _vm._v(" "), _vm.primaryApplicantData.numberOfDependants ? _vm._l(parseInt(_vm.primaryApplicantData.numberOfDependants), function (data, i) {
    return _c('el-form-item', {
      key: i,
      attrs: {
        "label": 'Dependant ' + data + ' (Age)',
        "prop": 'dependant' + i,
        "data-selector": 'dependant-container_' + i
      }
    }, [_c('el-input', {
      attrs: {
        "data-selector": 'dependant-input_' + i
      },
      model: {
        value: _vm.primaryApplicantData.dependants[i].age,
        callback: function ($$v) {
          _vm.$set(_vm.primaryApplicantData.dependants[i], "age", $$v);
        },
        expression: "primaryApplicantData.dependants[i].age"
      }
    })], 1);
  }) : _vm._e()], 2)], 1), _vm._v(" "), _c('div', {
    staticClass: "clearfix card-header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v("Identification")])]), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "userData.idType"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.primaryApplicantData.userData.idType,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "idType", $$v);
      },
      expression: "primaryApplicantData.userData.idType"
    }
  }, [_c('el-radio', {
    attrs: {
      "label": _vm.ID_TYPE_DRIVER_LICENCE,
      "data-selector": "driver-licence-label"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_TYPE_DRIVERS_LICENCE))]), _vm._v(" "), _c('el-radio', {
    attrs: {
      "label": _vm.ID_TYPE_PASSPORT,
      "data-selector": "passport-label"
    }
  }, [_vm._v(_vm._s(_vm.ID_TYPE_PASSPORT))])], 1)], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 24
    }
  }), _vm._v(" "), _vm.isIdTypeLicence ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Licence type",
      "prop": "userData.driverLicenceType",
      "data-selector": "licence-type-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.primaryApplicantData.userData.driverLicenceType,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "driverLicenceType", $$v);
      },
      expression: "primaryApplicantData.userData.driverLicenceType"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "licence-type-options-container"
    }
  }, _vm._l(_vm.APPLICANT_DRIVER_LICENCE_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isIdTypeLicence ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Licence state",
      "prop": "userData.driverLicenceState",
      "data-selector": "licence-state-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Select"
    },
    model: {
      value: _vm.primaryApplicantData.userData.driverLicenceState,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "driverLicenceState", $$v);
      },
      expression: "primaryApplicantData.userData.driverLicenceState"
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "licence-state-options-container"
    }
  }, _vm._l(_vm.APPLICANT_STATE_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isIdTypeLicence ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Licence number",
      "prop": "userData.driverLicence",
      "data-selector": "licence-num-container"
    }
  }, [_c('el-input', {
    attrs: {
      "data-selector": "licence-num-input"
    },
    model: {
      value: _vm.primaryApplicantData.userData.driverLicence,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "driverLicence", $$v);
      },
      expression: "primaryApplicantData.userData.driverLicence"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isIdTypeLicence ? _c('el-col', {
    attrs: {
      "xs": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Licence date of expiry",
      "prop": "userData.driverLicenceExpiry",
      "data-selector": "licence-expiry-container"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "format": "dd/MM/yyyy",
      "placeholder": "DD/MM/YYYY"
    },
    model: {
      value: _vm.primaryApplicantData.userData.driverLicenceExpiry,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "driverLicenceExpiry", $$v);
      },
      expression: "primaryApplicantData.userData.driverLicenceExpiry"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isIdTypeLicence ? _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Card number",
      "prop": "userData.driverLicenceCardNumber",
      "data-selector": "card-num-container"
    }
  }, [_c('el-input', {
    attrs: {
      "data-selector": "card-num-input"
    },
    model: {
      value: _vm.primaryApplicantData.userData.driverLicenceCardNumber,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "driverLicenceCardNumber", $$v);
      },
      expression: "\n                  primaryApplicantData.userData.driverLicenceCardNumber\n                "
    }
  })], 1)], 1) : _vm._e()], 1), _vm._v(" "), !_vm.isIdTypeLicence ? _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Passport number",
      "prop": "userData.passportNumber",
      "data-selector": "passport-num-container"
    }
  }, [_c('el-input', {
    attrs: {
      "data-selector": "passport-num-input"
    },
    model: {
      value: _vm.primaryApplicantData.userData.passportNumber,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "passportNumber", $$v);
      },
      expression: "primaryApplicantData.userData.passportNumber"
    }
  })], 1)], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "xs": 24,
      "lg": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Passport date of expiry",
      "prop": "userData.passportDateOfExpiry",
      "data-selector": "passport-expiry-container"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "format": "dd/MM/yyyy",
      "placeholder": "DD/MM/YYYY"
    },
    model: {
      value: _vm.primaryApplicantData.userData.passportDateOfExpiry,
      callback: function ($$v) {
        _vm.$set(_vm.primaryApplicantData.userData, "passportDateOfExpiry", $$v);
      },
      expression: "primaryApplicantData.userData.passportDateOfExpiry"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c('tab-footer', {
    attrs: {
      "save-btn-loading": _vm.saveBtnLoading,
      "save-and-next-btn-loading": _vm.saveAndNextBtnLoading,
      "show-back-button": true
    },
    on: {
      "submitForm": _vm.submitForm,
      "saveAndNext": _vm.submitForm,
      "goBack": _vm.goBack
    }
  })], 2) : _vm._e()], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }