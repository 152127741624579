//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  URLS_FOR_HIDE_THE_PORTAL_TITLE,
  URLS_FOR_MIDDLE_THE_PORTAL_TITLE,
  URLS_FOR_HIDE_NAVBAR_DROPDOWN_AND_SIDEBAR,
} from '@/constants/navbar'
import {
  USER_TYPE_SUPER_ADMIN,
  USER_SESSION_DATA_KEY,
  USER_TYPE_REFERRER,
  ERROR_MESSAGE_FOR_PORTAL_LOGIN_NOT_SUPPORTED,
  USER_TYPE_ADMIN,
} from '@/constants'
import {
  FUEL,
  LABEL_ACCELERATE,
  LABEL_CHANGE_PASSWORD,
  LABEL_IMPERSONATE,
  LABEL_IMPERSONATE_ACCELERATE,
  LABEL_LOGOUT,
} from '@/constants/applications'
import { changeRoute } from '~/helpers/changeRoute'
import { axiosErrorMessageExtractor } from '~/utils/error'
import { REFERRAL_HOSTS } from '~/router/subdomainConfig'
import isMobile from '../mixins/device-mixin'
import { datadogRum } from '@datadog/browser-rum'
import { validateUserRoles } from '~/helpers/user'

export default {
  props: {
    isReferrals: {
      type: Boolean,
      default: false,
    },
    shouldRender: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: 'All',
      URLName: '',
      openImpersonateDialog: false,
      openImpersonateAccelerateDialog: false,
      systemRoleTypes: [],
      FUEL,
      LABEL_ACCELERATE,
      LABEL_IMPERSONATE,
      LABEL_CHANGE_PASSWORD,
      LABEL_LOGOUT,
      REFERRAL_HOSTS,
      LABEL_IMPERSONATE_ACCELERATE,
      openChangePasswordDialog: false,
    }
  },
  mixins: [isMobile],
  computed: {
    isReferralHost() {
      return REFERRAL_HOSTS.includes(window.location.host)
    },
    isPortalTitleVisible() {
      return !URLS_FOR_HIDE_THE_PORTAL_TITLE.includes(this.URLName)
    },
    setLogoPosition() {
      return {
        justifyContent: URLS_FOR_MIDDLE_THE_PORTAL_TITLE.includes(this.URLName)
          ? 'center'
          : 'space-between',
      }
    },
    isDropDownVisible() {
      return (
        this.$auth.loggedIn &&
        !URLS_FOR_HIDE_NAVBAR_DROPDOWN_AND_SIDEBAR.includes(this.URLName)
      )
    },
    doesImpersonateOptionVisible() {
      if (this.systemRoleTypes) {
        return validateUserRoles(this.systemRoleTypes, [USER_TYPE_SUPER_ADMIN])
      }
      return false
    },
    userName() {
      return this.$auth?.user?.firstName
    },
    partnerName() {
      return this.$auth?.user?.referralAccount?.businessName
        ? this.$auth?.user?.referralAccount?.businessName
        : ''
    },
  },
  mounted() {
    this.URLName = this.$route.name
    this.$nuxt.$on('openImpersonateDialog', () => {
      this.openImpersonateDialog = true
    })
    this.$nuxt.$on('closeImpersonateDialog', () => {
      this.openImpersonateDialog = false
    })
    this.$nuxt.$on('openImpersonateAccelerateDialog', () => {
      this.openImpersonateAccelerateDialog = true
    })
    this.$nuxt.$on('closeImpersonateAccelerateDialog', () => {
      this.openImpersonateAccelerateDialog = false
    })
    this.$auth.$storage.watchState('user', (newValue) => {
      if (newValue) {
        this.systemRoleTypes = newValue.systemRoleTypes
      }
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('openImpersonateDialog')
    this.$nuxt.$off('closeImpersonateDialog')
    this.$nuxt.$off('openImpersonateAccelerateDialog')
    this.$nuxt.$off('closeImpersonateAccelerateDialog')
  },
  methods: {
    logout() {
      console.log('logging out')
    },
    openDialog() {
      this.$nuxt.$emit('openImpersonateDialog')
    },
    openDialogForImpersonateAccelerate() {
      this.$nuxt.$emit('openImpersonateAccelerateDialog')
    },
    async impersonateAccelerateUser(phoneNumber) {
      try {
        /**
         * We need to call this API two times.
         * Reason:- We create another stretegy for login as impersonate. The default behaviour
         * is first logout and login with new email. If login fails then it will redirect to login page.
         * That is why we need to first call this API to check that is there any error or not.
         */
        await this.$axios.post('/user/impersonate', { mobile: phoneNumber })
        const response = await this.$axios.post('/user/impersonate', {
          mobile: phoneNumber,
        })
        const { user } = response.data
        if (
          !validateUserRoles(user.systemRoleTypes, [
            USER_TYPE_ADMIN,
            USER_TYPE_SUPER_ADMIN,
            USER_TYPE_REFERRER,
          ])
        ) {
          this.$notify({
            title: 'Error',
            message: ERROR_MESSAGE_FOR_PORTAL_LOGIN_NOT_SUPPORTED,
            type: 'error',
            duration: 3000,
          })
          this.$auth.$storage.removeUniversal(USER_SESSION_DATA_KEY)
          this.$auth.setUser(null)
          await this.$auth.logout()

          this.loginData.mobile = ''
          return
        }
        datadogRum.setUser({
          id: user._id,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          mobile: user.mobile,
          role: user.type,
        })

        window.open(
          `${process.env.VUE_APP_ACCELERATE_URL}/auth/login?token=${response.data.tokens.token}&refreshToken=${response.data.tokens.refreshToken}`
        )
      } catch (err) {
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.$nuxt.$emit('closeImpersonateAccelerateDialog')
      }
    },
    openCloseChangePasswordDialog() {
      this.openChangePasswordDialog = !this.openChangePasswordDialog
    },
    async impersonateUser(email) {
      try {
        /**
         * We need to call this API two times.
         * Reason:- We create another stretegy for login as impersonate. The default behaviour
         * is first logout and login with new email. If login fails then it will redirect to login page.
         * That is why we need to first call this API to check that is there any error or not.
         */
        await this.$axios.post('/user/impersonate', { email: email })
        const response = await this.$axios.post('/user/impersonate', {
          email: email,
        })
        const { user } = response.data
        this.$auth.setUser(user)
        this.$auth.$storage.setUniversal(USER_SESSION_DATA_KEY, user)
        datadogRum.setUser({
          id: user._id,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          mobile: user.mobile,
          role: user.type,
        })

        this.$auth.strategy.token.set(response.data.tokens.token)
        this.$auth.strategy.refreshToken.set(response.data.tokens.refreshToken)
        await changeRoute({
          route: this.$route,
          router: this.$router,
          path: '/finance/applications',
        })
        this.$router.go()
      } catch (err) {
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.$nuxt.$emit('closeImpersonateDialog')
      }
    },
  },
}
