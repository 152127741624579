//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN } from '~/constants'
import {
  SIDEBAR_MENU_ITEM_FINANCE,
  SIDEBAR_MENU_ITEM_APPLICATIONS,
  SIDEBAR_MENU_UTILITIES,
  SIDEBAR_MENU_ITEM_RRP,
  SIDEBAR_MENU_USERS,
  SIDEBAR_MENU_NOTIFICATIONS,
  SIDEBAR_MENU_SETTINGS,
  SIDEBAR_MENU_WHITELISTED_IP_ADDRESSES,
  SIDEBAR_MENU_QUICK_QUOTE,
} from '~/constants/sidebar'
import Vehiclerrp from './Vehicle-rrp.vue'
import { validateUserRoles } from '~/helpers/user'
export default {
  components: {
    Vehiclerrp,
  },
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SIDEBAR_MENU_ITEM_FINANCE,
      SIDEBAR_MENU_ITEM_APPLICATIONS,
      SIDEBAR_MENU_UTILITIES,
      SIDEBAR_MENU_ITEM_RRP,
      SIDEBAR_MENU_USERS,
      SIDEBAR_MENU_NOTIFICATIONS,
      SIDEBAR_MENU_SETTINGS,
      SIDEBAR_MENU_WHITELISTED_IP_ADDRESSES,
      SIDEBAR_MENU_QUICK_QUOTE,
      isShowVehiclerrpDialog: false,
      isActiveMenuIndex: null,
      USER_TYPE_ADMIN,
      USER_TYPE_SUPER_ADMIN,
    }
  },
  computed: {
    checkTypeAdmin() {
      return validateUserRoles(
        this.$auth.$storage.getUniversal('user').systemRoleTypes,
        [USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN]
      )
    },
  },
  mounted() {
    if (this.$route.path.includes('applications') !== -1) {
      this.isActiveMenuIndex = '1'
    } else {
      this.isActiveMenuIndex = '2'
    }
  },
  methods: {
    appNavigation() {
      this.$router
        .push({ name: 'finance-applications' })
        .catch((redundantNavigation) => {
          if (redundantNavigation) {
            this.$nuxt.$emit('finance-application-navigation', true)
          }
        })
    },
    openCloseVehiclerrpDialog() {
      this.isShowVehiclerrpDialog = !this.isShowVehiclerrpDialog
    },
  },
}
