import { datadogRum } from '@datadog/browser-rum'
import { version } from '../package.json'

export default () => {
  const service = window['fuel'] ? 'Fuel' : 'Accelerate'
  datadogRum.init({
    applicationId: process.env.VUE_APP_DD_APPLICATION_ID,
    clientToken: process.env.VUE_APP_DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: service,
    env: process.env.VUE_APP_ENVIRONMENT,
    version: version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  })

  datadogRum.startSessionReplayRecording()
}
