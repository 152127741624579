//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  BUTTON_TYPE_PRIMARY,
  BUTTON_NATIVE_TYPE_BUTTON,
} from '~/constants/button'
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isPlain: {
      type: Boolean,
      default: false,
    },
    isRound: {
      type: Boolean,
      default: false,
    },
    btnType: {
      type: String,
      default: BUTTON_TYPE_PRIMARY,
    },
    nativeType: {
      type: String,
      default: BUTTON_NATIVE_TYPE_BUTTON,
    },
  },
  methods: {
    onClick(e) {
      this.$emit('onClick', e)
    },
  },
}
