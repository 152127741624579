var render = function () {
  var _vm$lenderData, _vm$lenderData2, _vm$lenderData3, _vm$lenderData4, _vm$lenderData5, _vm$lenderData5$stage, _vm$lenderData6, _vm$lenderData6$quote, _vm$lenderData7, _vm$lenderData7$quote, _vm$lenderData8, _vm$lenderData8$incom, _vm$lenderData9, _vm$lenderData9$incom, _vm$lenderData10, _vm$lenderData10$inco, _vm$lenderData11, _vm$lenderData12, _vm$lenderData13, _vm$lenderData13$stag, _vm$lenderData14, _vm$lenderData14$quot, _vm$lenderData15, _vm$lenderData15$quot, _vm$lenderData16, _vm$lenderData16$quot, _vm$lenderData17, _vm$lenderData17$quot, _vm$lenderData18, _vm$lenderData19, _vm$lenderData19$comm, _vm$lenderData20, _vm$lenderData20$feat, _vm$lenderData21, _vm$lenderData21$lend, _vm$lenderData21$lend2, _vm$lenderData22, _vm$lenderData22$lend, _vm$lenderData22$lend2, _vm$lenderData23, _vm$lenderData23$lend, _vm$lenderData23$lend2, _vm$lenderData24, _vm$lenderData24$docu, _vm$lenderData25;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-col', {
    staticClass: "lender-match",
    staticStyle: {
      "min-width": "375px",
      "width": "351px"
    },
    attrs: {
      "xs": 24,
      "sm": 12,
      "lg": 10
    }
  }, [_vm.lenderData ? _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.matchSettingLoading,
      expression: "matchSettingLoading"
    }],
    staticClass: "my-6 rounded-2 match-Setting-Loading"
  }, [_c('el-row', {
    staticClass: "flex items-center"
  }, [_c('el-col', {
    style: ("\n          min-width: 140px;\n          min-height: 45px;\n          background: url('" + ((_vm$lenderData = _vm.lenderData) === null || _vm$lenderData === void 0 ? void 0 : _vm$lenderData.lenderLogoUrl) + "') no-repeat;\n          background-position: center left;\n          background-size: 60% auto;\n          "),
    attrs: {
      "span": 12
    }
  }), _vm._v(" "), _c('el-col', {
    staticStyle: {
      "text-align": "end"
    },
    attrs: {
      "span": 12
    }
  }, [_c('h2', {
    staticClass: "font-bolder text-success text-xl"
  }, [_vm._v(_vm._s(_vm.numberWithCommas(_vm.getRepayments())))]), _vm._v(" "), _c('h5', {
    staticClass: "text-xs timeLineColor font-normal mt-1"
  }, [_vm._v("\n          " + _vm._s(_vm.repaymentsType.charAt(0).toUpperCase() + _vm.repaymentsType.slice(1)))])])], 1), _vm._v(" "), _c('el-row', {
    staticClass: "flex bg-primary mt-3"
  }, [_c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('h2', {
    staticClass: "text-md my-2 px-1"
  }, [_vm._v(_vm._s(((_vm$lenderData2 = _vm.lenderData) === null || _vm$lenderData2 === void 0 ? void 0 : _vm$lenderData2.productName) || ''))])])], 1), _vm._v(" "), _c('div', {
    staticClass: "mt-4 flex justify-between"
  }, [_c('div', [_c('h2', {
    staticClass: "text-xs font-bolder text-grey-darker"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_BASE_RATE_FOR_LENDER))]), _vm._v(" "), _c('h2', {
    staticClass: "text-md font-bolder text-black mt-1"
  }, [_vm._v(_vm._s(parseFloat(_vm.lenderData.baseRate).toFixed(2)) + "%")])]), _vm._v(" "), _c('div', [_c('h2', {
    staticClass: "text-xs font-bolder text-grey-darker"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CUSTOMER_RATE_FOR_LENDER))]), _vm._v(" "), _c('h2', {
    staticClass: "text-md font-bolder text-black mt-1"
  }, [_vm._v(_vm._s(parseFloat((_vm$lenderData3 = _vm.lenderData) !== null && _vm$lenderData3 !== void 0 && _vm$lenderData3.customerRate ? (_vm$lenderData4 = _vm.lenderData) === null || _vm$lenderData4 === void 0 ? void 0 : _vm$lenderData4.customerRate : (_vm$lenderData5 = _vm.lenderData) === null || _vm$lenderData5 === void 0 ? void 0 : (_vm$lenderData5$stage = _vm$lenderData5.stagedChanges) === null || _vm$lenderData5$stage === void 0 ? void 0 : _vm$lenderData5$stage.customerRate).toFixed(2) || 0) + "%")]), _vm._v(" "), _c('p', {
    staticClass: "text-xs font-bolder timeLineColor mt-1"
  }, [_vm._v("Fixed rate")])]), _vm._v(" "), _c('div', [_c('h2', {
    staticClass: "text-xs font-bolder text-grey-darker"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_COMPARISON_RATE_FOR_LENDER))]), _vm._v(" "), _c('h2', {
    staticClass: "text-md font-bolder text-black mt-1"
  }, [_vm._v(_vm._s(parseFloat((_vm$lenderData6 = _vm.lenderData) === null || _vm$lenderData6 === void 0 ? void 0 : (_vm$lenderData6$quote = _vm$lenderData6.quote) === null || _vm$lenderData6$quote === void 0 ? void 0 : _vm$lenderData6$quote.comparisonRate).toFixed(2)) + "%")])])]), _vm._v(" "), _c('hr', {
    staticClass: "my-4",
    staticStyle: {
      "border-top": "0.1px"
    }
  }), _vm._v(" "), _c('el-row', {
    staticClass: "my-4"
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('h2', {
    staticClass: "text-xs font-bolder text-grey-darker"
  }, [_vm._v(_vm._s(_vm.LABEL_NAF))]), _vm._v(" "), _c('h2', {
    staticClass: "text-md font-bolder text-black mt-1"
  }, [_vm._v(_vm._s(_vm.numberWithCommas((_vm$lenderData7 = _vm.lenderData) === null || _vm$lenderData7 === void 0 ? void 0 : (_vm$lenderData7$quote = _vm$lenderData7.quote) === null || _vm$lenderData7$quote === void 0 ? void 0 : _vm$lenderData7$quote.netAmountFinanced)))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('h2', {
    staticClass: "text-xs font-bolder text-grey-darker"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_TOTAL_FINANCE_INCOME))]), _vm._v(" "), _c('h2', {
    staticClass: "text-md font-bolder text-black mt-1"
  }, [_vm._v(_vm._s(_vm.numberWithCommas((_vm$lenderData8 = _vm.lenderData) === null || _vm$lenderData8 === void 0 ? void 0 : (_vm$lenderData8$incom = _vm$lenderData8.income) === null || _vm$lenderData8$incom === void 0 ? void 0 : _vm$lenderData8$incom.total)))])])], 1), _vm._v(" "), _c('el-row', [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('h2', {
    staticClass: "text-xs font-bolder text-grey-darker capitalise"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_LENDING_COMMISSION))]), _vm._v(" "), _c('h2', {
    staticClass: "text-md font-bolder text-black mt-1"
  }, [_vm._v(_vm._s(_vm.numberWithCommas((_vm$lenderData9 = _vm.lenderData) === null || _vm$lenderData9 === void 0 ? void 0 : (_vm$lenderData9$incom = _vm$lenderData9.income) === null || _vm$lenderData9$incom === void 0 ? void 0 : _vm$lenderData9$incom.brokerageCommissionIncGst)))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('h2', {
    staticClass: "text-xs font-bolder text-grey-darker capitalise"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_ORIGINATION_FEE))]), _vm._v(" "), _c('h2', {
    staticClass: "text-md font-bolder text-black mt-1"
  }, [_vm._v("\n          " + _vm._s(_vm.numberWithCommas(parseInt((_vm$lenderData10 = _vm.lenderData) === null || _vm$lenderData10 === void 0 ? void 0 : (_vm$lenderData10$inco = _vm$lenderData10.income) === null || _vm$lenderData10$inco === void 0 ? void 0 : _vm$lenderData10$inco.originationFeeIncGST))) + "\n        ")])])], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4"
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('h2', {
    staticClass: "text-xs font-bolder text-grey-darker capitalise"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_BALLOON_PERCENT) + " (Max\n          " + _vm._s(((_vm$lenderData11 = _vm.lenderData) !== null && _vm$lenderData11 !== void 0 && _vm$lenderData11.balloonMaxPct ? (_vm$lenderData12 = _vm.lenderData) === null || _vm$lenderData12 === void 0 ? void 0 : _vm$lenderData12.balloonMaxPct : (_vm$lenderData13 = _vm.lenderData) === null || _vm$lenderData13 === void 0 ? void 0 : (_vm$lenderData13$stag = _vm$lenderData13.stagedChanges) === null || _vm$lenderData13$stag === void 0 ? void 0 : _vm$lenderData13$stag.balloonMaxPct) || 0) + "%)")]), _vm._v(" "), _c('h2', {
    staticClass: "text-md font-bolder text-black mt-1"
  }, [_vm._v(_vm._s((_vm$lenderData14 = _vm.lenderData) !== null && _vm$lenderData14 !== void 0 && (_vm$lenderData14$quot = _vm$lenderData14.quote) !== null && _vm$lenderData14$quot !== void 0 && _vm$lenderData14$quot.balloonPct ? (_vm$lenderData15 = _vm.lenderData) === null || _vm$lenderData15 === void 0 ? void 0 : (_vm$lenderData15$quot = _vm$lenderData15.quote) === null || _vm$lenderData15$quot === void 0 ? void 0 : _vm$lenderData15$quot.balloonPct : 0.0) + "%")])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('h2', {
    staticClass: "text-xs font-bolder text-grey-darker capitalise"
  }, [_vm._v(_vm._s(_vm.LABEL_LOAN_BALLOON_DOLLAR))]), _vm._v(" "), _c('h2', {
    staticClass: "text-md font-bolder text-black mt-1"
  }, [_vm._v("\n          " + _vm._s((_vm$lenderData16 = _vm.lenderData) !== null && _vm$lenderData16 !== void 0 && (_vm$lenderData16$quot = _vm$lenderData16.quote) !== null && _vm$lenderData16$quot !== void 0 && _vm$lenderData16$quot.balloonAmount ? _vm.numberWithCommas(parseFloat((_vm$lenderData17 = _vm.lenderData) === null || _vm$lenderData17 === void 0 ? void 0 : (_vm$lenderData17$quot = _vm$lenderData17.quote) === null || _vm$lenderData17$quot === void 0 ? void 0 : _vm$lenderData17$quot.balloonAmount).toFixed(2)) : '$0') + "\n        ")])])], 1), _vm._v(" "), _c('hr', {
    staticClass: "my-4",
    staticStyle: {
      "border-top": "0.1px"
    }
  }), _vm._v(" "), _vm.matchSettings ? _c('el-form', {
    ref: "matchSettings",
    staticClass: "match-settings",
    attrs: {
      "model": _vm.matchSettings,
      "rules": _vm.matchSettingsRules
    }
  }, [_c('div', {
    staticStyle: {
      "min-width": "300px"
    }
  }, [_c('el-form-item', {
    staticClass: "text-xs",
    attrs: {
      "label": _vm.LABEL_FOR_LOAN_TERM,
      "prop": "loanTermYears"
    }
  }, [_c('label', {
    staticClass: "el-form-item__label",
    attrs: {
      "for": "originationFee"
    }
  }, [_c('b', [_vm._v("(Max " + _vm._s((_vm$lenderData18 = _vm.lenderData) === null || _vm$lenderData18 === void 0 ? void 0 : _vm$lenderData18.maxLoanTermYears) + " years)")])]), _vm._v(" "), _c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    on: {
      "change": _vm.adjustIndividualMatch
    },
    model: {
      value: _vm.matchSettings.loanTermYears,
      callback: function ($$v) {
        _vm.$set(_vm.matchSettings, "loanTermYears", $$v);
      },
      expression: "matchSettings.loanTermYears"
    }
  }, _vm._l(_vm.loanTermYearsList, function (data) {
    return _c('el-option', {
      key: data,
      attrs: {
        "label": data + (data === 1 ? ' year' : ' years'),
        "value": data
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c('div', {
    staticStyle: {
      "min-width": "300px"
    }
  }, [_c('el-form-item', {
    staticClass: "mb-6 text-xs",
    attrs: {
      "prop": "originationFee",
      "label": _vm.LABEL_FOR_ORIGINATION_FEE_CAPITAL
    }
  }, [_c('label', {
    staticClass: "el-form-item__label",
    attrs: {
      "for": "originationFee"
    }
  }, [_c('b', [_vm._v("(Max $" + _vm._s((_vm$lenderData19 = _vm.lenderData) === null || _vm$lenderData19 === void 0 ? void 0 : (_vm$lenderData19$comm = _vm$lenderData19.commission) === null || _vm$lenderData19$comm === void 0 ? void 0 : _vm$lenderData19$comm.brokerFeeLimit) + ")")])]), _vm._v(" "), _c('formatted-numeric-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }, {
      name: "debounce",
      rawName: "v-debounce:600ms",
      value: _vm.adjustIndividualMatch,
      expression: "adjustIndividualMatch",
      arg: "600ms"
    }],
    attrs: {
      "accept-only-numbers": true,
      "rows": 2
    },
    model: {
      value: _vm.matchSettings.originationFee,
      callback: function ($$v) {
        _vm.$set(_vm.matchSettings, "originationFee", $$v);
      },
      expression: "matchSettings.originationFee"
    }
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "dynamic-pricing-filters el-checkbox-group",
    staticStyle: {
      "min-width": "300px"
    }
  }, [_c('span', {
    staticClass: "el-form-item__label",
    staticStyle: {
      "text-align": "start"
    },
    attrs: {
      "for": "brokerLoading"
    }
  }, [_c('span', {
    staticClass: "required",
    staticStyle: {
      "padding-right": "5px !important"
    }
  }, [_vm._v("*")]), _vm._v(_vm._s(_vm.LABEL_FOR_BROKER_LOADING) + " %\n          "), _c('b', [_vm._v("(Max " + _vm._s(_vm.maxBrokerLoading) + "%)")])]), _vm._v(" "), _c('el-form-item', {
    staticClass: "el-checkbox-group text-xs",
    attrs: {
      "prop": "brokerLoading"
    }
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('el-input', {
    directives: [{
      name: "debounce",
      rawName: "v-debounce:600ms",
      value: _vm.adjustIndividualMatch,
      expression: "adjustIndividualMatch",
      arg: "600ms"
    }],
    staticClass: "mixed-input",
    staticStyle: {
      "max-width": "147px"
    },
    attrs: {
      "placeholder": "Please input",
      "type": "number"
    },
    on: {
      "input": _vm.changeToFloat
    },
    model: {
      value: _vm.matchSettings.brokerLoading,
      callback: function ($$v) {
        _vm.$set(_vm.matchSettings, "brokerLoading", $$v);
      },
      expression: "matchSettings.brokerLoading"
    }
  }), _vm._v(" "), _c('el-slider', {
    staticClass: "mixed-input-slider border border-color border-color-grey p-2 broker_loading_match",
    style: _vm.brokerLoadingisValid ? 'border-color : #dbdfe6' : 'border-color : #F56C6C',
    attrs: {
      "show-tooltip": false,
      "min": 0,
      "max": _vm.maxBrokerLoading,
      "step": "0.01"
    },
    on: {
      "change": function ($event) {
        return _vm.adjustIndividualMatch('fromSlider');
      }
    },
    model: {
      value: _vm.matchSettings.brokerLoading,
      callback: function ($$v) {
        _vm.$set(_vm.matchSettings, "brokerLoading", $$v);
      },
      expression: "matchSettings.brokerLoading"
    }
  })], 1)])], 1)]) : _vm._e(), _vm._v(" "), _c('hr', {
    staticClass: "my-4",
    staticStyle: {
      "border-top": "0.1px"
    }
  }), _vm._v(" "), _vm.showTabs ? _c('el-tabs', {
    staticClass: "lender-match-tabs",
    model: {
      value: _vm.activeTab,
      callback: function ($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('el-tab-pane', {
    attrs: {
      "label": _vm.LABEL_FOR_FEATURES,
      "name": _vm.KEY_FOR_FEATURES
    }
  }, [(_vm$lenderData20 = _vm.lenderData) !== null && _vm$lenderData20 !== void 0 && (_vm$lenderData20$feat = _vm$lenderData20.features) !== null && _vm$lenderData20$feat !== void 0 && _vm$lenderData20$feat.length ? _c('ul', {
    staticClass: "bullets green-bullets"
  }, _vm._l(_vm.lenderData.features, function (feature) {
    return _c('li', {
      key: feature,
      staticClass: "text-black"
    }, [_vm._v(_vm._s(feature))]);
  }), 0) : _vm._e()]), _vm._v(" "), _c('el-tab-pane', {
    attrs: {
      "label": _vm.LABEL_FOR_FEES,
      "name": _vm.KEY_FOR_FEES
    }
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('h2', {
    staticClass: "text-sm fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_LENDER_MONTHLY_FEE))]), _vm._v(" "), _c('h2', {
    staticClass: "text-md mt-1"
  }, [_vm._v(_vm._s(_vm.numberWithCommas((_vm$lenderData21 = _vm.lenderData) === null || _vm$lenderData21 === void 0 ? void 0 : (_vm$lenderData21$lend = _vm$lenderData21.lenderFees) === null || _vm$lenderData21$lend === void 0 ? void 0 : (_vm$lenderData21$lend2 = _vm$lenderData21$lend.requisite) === null || _vm$lenderData21$lend2 === void 0 ? void 0 : _vm$lenderData21$lend2.monthly)))])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('h2', {
    staticClass: "text-sm fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_PSPR))]), _vm._v(" "), _c('h2', {
    staticClass: "text-md mt-1"
  }, [_vm._v(_vm._s(_vm.numberWithCommas((_vm$lenderData22 = _vm.lenderData) === null || _vm$lenderData22 === void 0 ? void 0 : (_vm$lenderData22$lend = _vm$lenderData22.lenderFees) === null || _vm$lenderData22$lend === void 0 ? void 0 : (_vm$lenderData22$lend2 = _vm$lenderData22$lend.requisite) === null || _vm$lenderData22$lend2 === void 0 ? void 0 : _vm$lenderData22$lend2.ppsr)))])]), _vm._v(" "), _c('el-col', {
    staticClass: "mt-4",
    attrs: {
      "span": 12
    }
  }, [_c('h2', {
    staticClass: "text-sm fontColorLenderMatch"
  }, [_vm._v(_vm._s(_vm.LABEL_LENDER_FEE))]), _vm._v(" "), _c('h2', {
    staticClass: "text-md mt-1"
  }, [_vm._v(_vm._s(_vm.numberWithCommas((_vm$lenderData23 = _vm.lenderData) === null || _vm$lenderData23 === void 0 ? void 0 : (_vm$lenderData23$lend = _vm$lenderData23.lenderFees) === null || _vm$lenderData23$lend === void 0 ? void 0 : (_vm$lenderData23$lend2 = _vm$lenderData23$lend.requisite) === null || _vm$lenderData23$lend2 === void 0 ? void 0 : _vm$lenderData23$lend2.establishment)))])])], 1)], 1), _vm._v(" "), _c('el-tab-pane', {
    attrs: {
      "label": _vm.LABEL_FOR_DOCUMENTS,
      "name": _vm.KEY_FOR_DOCUMENTS
    }
  }, [(_vm$lenderData24 = _vm.lenderData) !== null && _vm$lenderData24 !== void 0 && (_vm$lenderData24$docu = _vm$lenderData24.documents) !== null && _vm$lenderData24$docu !== void 0 && _vm$lenderData24$docu.length ? _c('ul', {
    staticClass: "bullets green-bullets"
  }, _vm._l((_vm$lenderData25 = _vm.lenderData) === null || _vm$lenderData25 === void 0 ? void 0 : _vm$lenderData25.documents, function (documentsRequired) {
    return _c('li', {
      key: documentsRequired
    }, [_vm._v(_vm._s(documentsRequired))]);
  }), 0) : _vm._e()])], 1) : _vm._e()], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }