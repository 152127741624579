var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "message-code"
  }, _vm._l(_vm.values, function (digit, i) {
    return _c('div', {
      key: i,
      staticClass: "message-code-content",
      class: {
        'message-code-content--spacing': i < _vm.fields - 1
      }
    }, [_c('input', {
      ref: _vm.inputRefs[i],
      refInFor: true,
      staticClass: "message-code-input",
      class: {
        'message-code-input--disabled': _vm.disabled
      },
      attrs: {
        "inputmode": "numeric",
        "pattern": '[0-9]',
        "placeholder": "0",
        "autofocus": i === _vm.focusIndex,
        "disabled": _vm.disabled
      },
      domProps: {
        "value": digit
      },
      on: {
        "input": function ($event) {
          $event.preventDefault();
          return _vm.onValueChange.apply(null, arguments);
        },
        "focus": _vm.onFocus,
        "keydown": _vm.onKeyDown,
        "paste": function ($event) {
          $event.preventDefault();
          return _vm.onPaste.apply(null, arguments);
        }
      }
    })]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }