var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-dialog', {
    attrs: {
      "name": "addSecondaryApplicantDialog",
      "loading": _vm.loading
    }
  }, [_c('div', [_c('div', [_c('h3', {
    attrs: {
      "data-selector": "modal-name"
    }
  }, [_vm._v(_vm._s(_vm.pageTitle) + "\n        "), _vm.isConsumer ? _c('span', {
    staticClass: "text-grey-dark"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_SECONDARY))]) : _vm._e()])]), _vm._v(" "), _c('el-form', {
    ref: "ruleForm",
    staticClass: "demo-ruleForm mt-4",
    attrs: {
      "model": _vm.ruleForm,
      "rules": _vm.rules
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_FIRST_NAME,
      "prop": "firstName",
      "data-selector": "m-firstname-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text",
      "autocomplete": "off",
      "data-selector": "m-firstname-input"
    },
    model: {
      value: _vm.ruleForm.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "firstName", $$v);
      },
      expression: "ruleForm.firstName"
    }
  })], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_LAST_NAME,
      "prop": "lastName",
      "data-selector": "m-lastname-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text",
      "autocomplete": "off",
      "data-selector": "m-lastname-input"
    },
    model: {
      value: _vm.ruleForm.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "lastName", $$v);
      },
      expression: "ruleForm.lastName"
    }
  })], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_MOBILE,
      "prop": "mobile",
      "rules": _vm.mobileNumberRules(),
      "data-selector": "m-mobile-input-container"
    }
  }, [_c('el-input', {
    directives: [{
      name: "restrict",
      rawName: "v-restrict.number",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "text",
      "placeholder": "04XXXXXXXXX",
      "data-selector": "m-mobile-input"
    },
    model: {
      value: _vm.ruleForm.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "mobile", $$v);
      },
      expression: "ruleForm.mobile"
    }
  })], 1), _vm._v(" "), _c('el-form-item', {
    attrs: {
      "prop": "email",
      "label": _vm.LABEL_FOR_EMAIL,
      "data-selector": "m-email-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "name@domain.com",
      "data-selector": "m-email-input"
    },
    model: {
      value: _vm.ruleForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "email", $$v);
      },
      expression: "ruleForm.email"
    }
  })], 1), _vm._v(" "), _vm.isConsumer ? _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_RELATIONSHIP_WITH_PRIMARY_APPLICANT,
      "prop": "relationshipStatusWithPrimaryApplicant"
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.ruleForm.relationshipStatusWithPrimaryApplicant,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "relationshipStatusWithPrimaryApplicant", $$v);
      },
      expression: "ruleForm.relationshipStatusWithPrimaryApplicant"
    }
  }, _vm._l(_vm.APPLICANT_RELATIONSHIP_WITH_PRIMARY_APPLICANT_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c('el-form-item', {
    staticClass: "mb-0 border-t border-light-grey pt-5 child-w-full"
  }, [_c('el-button', {
    attrs: {
      "data-selector": "c-m-cancel-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CANCEL_BUTTON))]), _vm._v(" "), _c('el-button', {
    staticClass: "float-right",
    attrs: {
      "type": "primary",
      "data-selector": "c-m-add-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm('ruleForm');
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_ADD_BUTTON) + "\n        ")])], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }