var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.getIsDialogLoading,
      expression: "getIsDialogLoading"
    }],
    staticClass: "application-form beneficiary"
  }, [_c('el-form', {
    ref: "formData",
    attrs: {
      "rules": _vm.rules,
      "model": _vm.formData
    }
  }, [_vm.formData ? [_c('el-card', {
    staticClass: "add-ref-step-parent-cls repateble-none"
  }, [_c('div', {
    staticClass: "clearfix p-0",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold",
    attrs: {
      "data-selector": "share-benef-header"
    }
  }, [_vm._v(_vm._s(_vm.isBeneficiary ? _vm.LABEL_BENEFICIARIES_DETAILS : _vm.LABEL_SHARE_HOLDER_DETAILS))])]), _vm._v(" "), _vm.formData.length === 0 ? _c('el-divider') : _vm._e(), _vm._v(" "), _vm._l(_vm.formData, function (data, index) {
    return _c('div', {
      key: index
    }, [_c('el-row', {
      staticClass: "flex items-center",
      attrs: {
        "gutter": 40
      }
    }, [_c('el-col', {
      attrs: {
        "xs": 10,
        "lg": 5
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.LABEL_FOR_FIRST_NAME,
        "prop": index + '.firstName',
        "rules": _vm.rules.firstName,
        "data-selector": 'bs-firstname-container_' + index
      }
    }, [_c('el-input', {
      staticClass: "form-control",
      attrs: {
        "type": "text",
        "data-selector": 'bs-firstname-input_' + index
      },
      model: {
        value: data.firstName,
        callback: function ($$v) {
          _vm.$set(data, "firstName", $$v);
        },
        expression: "data.firstName"
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 10,
        "lg": 5
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.LABEL_FOR_LAST_NAME,
        "prop": index + '.lastName',
        "rules": _vm.rules.lastName,
        "data-selector": 'bs-lastname-container_' + index
      }
    }, [_c('el-input', {
      staticClass: "form-control",
      attrs: {
        "type": "text",
        "data-selector": 'bs-lastname-input_' + index
      },
      model: {
        value: data.lastName,
        callback: function ($$v) {
          _vm.$set(data, "lastName", $$v);
        },
        expression: "data.lastName"
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 10,
        "lg": 5
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": _vm.LABEL_DATE_OF_BIRTH,
        "prop": index + '.dob',
        "rules": _vm.rules.dob,
        "data-selector": 'bs-dob-container_' + index
      }
    }, [_c('el-date-picker', {
      attrs: {
        "type": "date",
        "format": "dd/MM/yyyy",
        "placeholder": "DD/MM/YYYY"
      },
      model: {
        value: data.dob,
        callback: function ($$v) {
          _vm.$set(data, "dob", $$v);
        },
        expression: "data.dob"
      }
    })], 1)], 1), _vm._v(" "), _c('el-col', {
      attrs: {
        "xs": 10,
        "lg": 5
      }
    }, [_c('img', {
      staticClass: "cursor-pointer",
      attrs: {
        "src": require("@/assets/icons/delete.svg"),
        "alt": "delete",
        "data-selector": 'bs-delete-icon_' + index
      },
      on: {
        "click": function ($event) {
          return _vm.removeData(index);
        }
      }
    })])], 1)], 1);
  }), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 mb-8"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "data-selector": "bs-add-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.addMoreData();
      }
    }
  }, [_vm._v(_vm._s(_vm.isBeneficiary ? _vm.LABEL_ADD_BENEFICIARY : _vm.LABEL_ADD_SHARE_HOLDER))])], 1)], 2), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 mb-8 justify-end flex"
  }, [_c('div', {
    staticClass: "border-r border-light-grey mr-6 pr-6"
  }, [_c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "data-selector": "bs-back-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.back();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_BACK_BUTTON))])], 1), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "plain": "",
      "data-selector": "bs-save-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(false);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_SAVE_BUTTON))]), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary",
      "plain": "",
      "data-selector": "bs-save-next-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submit(true);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_SAVE_AND_NEXT))])], 1)] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }