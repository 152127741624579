//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  LOAN_WITHDRAWN_REASONS_OPTIONS,
  LOAN_WITHDRAWN_REASON_OTHER,
  LABEL_YES_PROCEED,
  LABEL_CANCEL,
  LABEL_WITHDRAWN,
} from '~/constants/applications'

import { axiosErrorMessageExtractor } from '~/utils/error'
export default {
  props: {
    dialogOpened: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => null,
    },
    subtitle: {
      type: String,
      default: () => null,
    },
    decisionData: {
      type: Object,
      default: () => ({}),
    },
    reason: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      getIsDialogLoading: false,
      LOAN_WITHDRAWN_REASONS_OPTIONS,
      LOAN_WITHDRAWN_REASON_OTHER,
      getSelectedOutcomeOption: null,
      LABEL_WITHDRAWN,
      loanData: { ...this.decisionData },
      rules: {
        withdrawnReasonType: [
          {
            required: true,
            message: 'Withdraw reason type is required',
            trigger: ['blur', 'change'],
          },
        ],
        withdrawnReason: [
          {
            required: true,
            message: 'Withdraw reason field is required',
            trigger: ['blur', 'change'],
          },
        ],
      },
      LABEL_YES_PROCEED,
      LABEL_CANCEL,
    }
  },
  mounted() {
    this.$nuxt.$on('close-loader', () => {
      this.getIsDialogLoading = false
      this.closeDialog()
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('close-loader')
  },
  computed: {
    loanDetailsForm() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loanData = JSON.parse(JSON.stringify(this.decisionData))
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return { ...this.decisionData }
    },
    isWithdrawOther() {
      return this.loanData.withdrawnReasonType === LOAN_WITHDRAWN_REASON_OTHER
    },
  },
  methods: {
    closeDialog() {
      this.$nuxt.$emit('closeWithdrawDialog')
    },
    async submitForm() {
      try {
        this.getIsDialogLoading = true
        const { id, applicationId } = this.$route.params
        let isValid = false
        this.$refs.loanDetailsForm.validate((result) => {
          return (isValid = result)
        })
        if (!isValid) {
          this.getIsDialogLoading = false
          return
        }
        if (this.loanData?.loanDetails?.approval) {
          this.loanData.loanDetails.approval.outcomeOfAnApplication =
            LABEL_WITHDRAWN
          this.loanData.loanDetails.approval.withdrawnAt =
            new Date().toISOString()
        }
        await this.$store.dispatch('applications/setApplicationReasonTypes', {
          reasonTypes: { ...this.loanData },
          id,
          applicationId,
        })
        this.$nuxt.$emit('closeWithdrawDialog')
        if (this.loanData.loanDetails) {
          this.$nuxt.$emit(
            'withdrawSubmited',
            this.loanData.loanDetails.withdrawnAt
          )
        }
        this.getIsDialogLoading = false
      } catch (err) {
        this.getIsDialogLoading = false
        this.$notify({
          title: 'Error',
          message: axiosErrorMessageExtractor(err),
          type: 'error',
          duration: 5000,
        })
      }
    },
  },
}
