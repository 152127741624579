var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-form-item', {
    attrs: {
      "label": _vm.label
    }
  }, [_c('div', {
    staticClass: "block"
  }, [_c('el-radio-group', {
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "change": _vm.onChange,
      "input": _vm.onInput,
      "blur": _vm.onBlur
    },
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": true
    }
  }, [_vm._v(_vm._s(_vm.LABEL_YES))]), _vm._v(" "), _c('el-radio-button', {
    attrs: {
      "label": false
    }
  }, [_vm._v(_vm._s(_vm.LABEL_NO))])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }