var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-dialog', {
    attrs: {
      "name": "assetDetailsSummaryDialog",
      "title": _vm.LABEL_DOCUMENT_ASSET_DETAILS,
      "loading": _vm.loading
    }
  }, [_c('el-form', {
    ref: "assetDetailsSummaryForm",
    staticClass: "demo-ruleForm mt-4",
    attrs: {
      "model": _vm.assetDetailsSummaryForm,
      "rules": _vm.rules
    }
  }, [_vm.assetDetailsSummaryForm.vehicle && _vm.assetDetailsSummaryForm.vehicle.assetType ? _c('el-form-item', {
    class: _vm.assetDetailsSummaryForm.vehicle && _vm.assetDetailsSummaryForm.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE ? 'border-b border-light-grey pb-4 mb-4' : 'mb-4',
    attrs: {
      "label": _vm.LABEL_DOCUMENT_TYPE,
      "prop": "vehicle.assetType",
      "rules": _vm.rules.assetType
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    on: {
      "change": function ($event) {
        return _vm.checkAssetType(_vm.assetDetailsSummaryForm.vehicle);
      }
    },
    model: {
      value: _vm.assetDetailsSummaryForm.vehicle.assetType,
      callback: function ($$v) {
        _vm.$set(_vm.assetDetailsSummaryForm.vehicle, "assetType", $$v);
      },
      expression: "assetDetailsSummaryForm.vehicle.assetType"
    }
  }, _vm._l(_vm.ASSET_TYPE_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.assetDetailsSummaryForm.vehicle && _vm.assetDetailsSummaryForm.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE && _vm.assetDetailsSummaryForm.vehicle.assetType !== _vm.LABEL_ASSET_TYPE_OPTION_OTHER && _vm.assetDetailsSummaryForm.vehicle.assetType !== _vm.LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY ? _c('el-form-item', {
    staticClass: "radio-with_contnt mb-2"
  }, [_c('el-radio', {
    attrs: {
      "label": false
    },
    model: {
      value: _vm.manuallySelected,
      callback: function ($$v) {
        _vm.manuallySelected = $$v;
      },
      expression: "manuallySelected"
    }
  }, [_vm._v("\n        Select vehicle\n      ")]), _vm._v(" "), _c('el-radio', {
    attrs: {
      "label": true
    },
    model: {
      value: _vm.manuallySelected,
      callback: function ($$v) {
        _vm.manuallySelected = $$v;
      },
      expression: "manuallySelected"
    }
  }, [_vm._v("\n        Enter details manually\n      ")])], 1) : _vm._e(), _vm._v(" "), _vm.manuallySelected || _vm.assetDetailsSummaryForm.vehicle && _vm.assetDetailsSummaryForm.vehicle.assetType !== _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE ? _c('el-form-item', {
    class: _vm.assetDetailsSummaryForm.vehicle && _vm.assetDetailsSummaryForm.vehicle.makeCode && _vm.assetDetailsSummaryForm.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE ? 'border-b border-light-grey pb-4 mb-4' : 'mb-4',
    attrs: {
      "label": _vm.LABEL_FOR_VEHICLE_DESCRIPTION,
      "prop": "vehicle.customerNote",
      "rules": _vm.rules.customerNote
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 2,
      "placeholder": _vm.PLACEHOLDER_FOR_ASSET_DESCRIPTION
    },
    model: {
      value: _vm.assetDetailsSummaryForm.vehicle.customerNote,
      callback: function ($$v) {
        _vm.$set(_vm.assetDetailsSummaryForm.vehicle, "customerNote", $$v);
      },
      expression: "assetDetailsSummaryForm.vehicle.customerNote"
    }
  })], 1) : _vm._e(), _vm._v(" "), !_vm.manuallySelected ? _c('div', [_c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_vm.assetDetailsSummaryForm.vehicle && _vm.assetDetailsSummaryForm.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE ? _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_MAKE,
      "prop": "vehicle.makeCode",
      "rules": _vm.rules.makeCode
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    on: {
      "change": function ($event) {
        return _vm.setModels();
      }
    },
    model: {
      value: _vm.assetDetailsSummaryForm.vehicle.makeCode,
      callback: function ($$v) {
        _vm.$set(_vm.assetDetailsSummaryForm.vehicle, "makeCode", $$v);
      },
      expression: "assetDetailsSummaryForm.vehicle.makeCode"
    }
  }, _vm._l(_vm.MAKE, function (data) {
    return _c('el-option', {
      key: data.makeCode,
      attrs: {
        "label": data.description,
        "value": data.makeCode
      }
    });
  }), 1)], 1) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_vm.assetDetailsSummaryForm.vehicle && _vm.assetDetailsSummaryForm.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE ? _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_MODEL,
      "prop": "vehicle.familyCode",
      "rules": _vm.rules.familyCode
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "disabled": _vm.modelDisabled,
      "placeholder": "select"
    },
    on: {
      "change": function ($event) {
        return _vm.setYears();
      }
    },
    model: {
      value: _vm.assetDetailsSummaryForm.vehicle.familyCode,
      callback: function ($$v) {
        _vm.$set(_vm.assetDetailsSummaryForm.vehicle, "familyCode", $$v);
      },
      expression: "assetDetailsSummaryForm.vehicle.familyCode"
    }
  }, _vm._l(_vm.MODEL_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.familyCode,
      attrs: {
        "label": data.description,
        "value": data.familyCode
      }
    });
  }), 1)], 1) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_vm.assetDetailsSummaryForm.vehicle && _vm.assetDetailsSummaryForm.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE ? _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_YEAR,
      "prop": "vehicle.year",
      "rules": _vm.rules.year
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "disabled": _vm.yearDisabled,
      "placeholder": "select"
    },
    on: {
      "change": function ($event) {
        return _vm.setModelTypes();
      }
    },
    model: {
      value: _vm.assetDetailsSummaryForm.vehicle.year,
      callback: function ($$v) {
        _vm.$set(_vm.assetDetailsSummaryForm.vehicle, "year", $$v);
      },
      expression: "assetDetailsSummaryForm.vehicle.year"
    }
  }, _vm._l(_vm.YEARS_OPTIONS, function (data) {
    return _c('el-option', {
      key: data,
      attrs: {
        "label": data,
        "value": data
      }
    });
  }), 1)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _vm.assetDetailsSummaryForm.vehicle && _vm.assetDetailsSummaryForm.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE ? _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_MODEL_TYPE,
      "prop": "vehicle.codeDescription",
      "rules": _vm.rules.codeDescription
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "disabled": _vm.modelTypeDisabled,
      "placeholder": "select"
    },
    model: {
      value: _vm.assetDetailsSummaryForm.vehicle.codeDescription,
      callback: function ($$v) {
        _vm.$set(_vm.assetDetailsSummaryForm.vehicle, "codeDescription", $$v);
      },
      expression: "assetDetailsSummaryForm.vehicle.codeDescription"
    }
  }, _vm._l(_vm.MODEL_TYPES_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.code,
      attrs: {
        "label": data.description,
        "value": data.code
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.assetDetailsSummaryForm.vehicle && (_vm.assetDetailsSummaryForm.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_OTHER || _vm.assetDetailsSummaryForm.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN) ? _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_DETAILS,
      "prop": "vehicle.customerNote"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 2
    },
    model: {
      value: _vm.assetDetailsSummaryForm.vehicle.customerNote,
      callback: function ($$v) {
        _vm.$set(_vm.assetDetailsSummaryForm.vehicle, "customerNote", $$v);
      },
      expression: "assetDetailsSummaryForm.vehicle.customerNote"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_vm.assetDetailsSummaryForm.vehicle && _vm.assetDetailsSummaryForm.vehicle.condition && (_vm.assetDetailsSummaryForm.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE || _vm.assetDetailsSummaryForm.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_OTHER) ? _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_CONDITION,
      "prop": "vehicle.condition",
      "rules": _vm.rules.condition
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.assetDetailsSummaryForm.vehicle.condition,
      callback: function ($$v) {
        _vm.$set(_vm.assetDetailsSummaryForm.vehicle, "condition", $$v);
      },
      expression: "assetDetailsSummaryForm.vehicle.condition"
    }
  }, _vm._l(_vm.CONDITION_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_vm.assetDetailsSummaryForm.vehicle && _vm.assetDetailsSummaryForm.vehicle.purchaseSource && (_vm.assetDetailsSummaryForm.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE || _vm.assetDetailsSummaryForm.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_OTHER) ? _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_SALE_TYPE,
      "prop": "vehicle.purchaseSource",
      "rules": _vm.rules.purchaseSource
    }
  }, [_c('el-select', {
    staticClass: "w-full",
    attrs: {
      "filterable": "",
      "placeholder": "select"
    },
    model: {
      value: _vm.assetDetailsSummaryForm.vehicle.purchaseSource,
      callback: function ($$v) {
        _vm.$set(_vm.assetDetailsSummaryForm.vehicle, "purchaseSource", $$v);
      },
      expression: "assetDetailsSummaryForm.vehicle.purchaseSource"
    }
  }, _vm._l(_vm.SALE_TYPE_OPTIONS, function (data) {
    return _c('el-option', {
      key: data.key,
      attrs: {
        "label": data.label,
        "value": data.value
      }
    });
  }), 1)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c('el-row', {
    attrs: {
      "gutter": 40
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_vm.assetDetailsSummaryForm.vehicle && _vm.assetDetailsSummaryForm.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE ? _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_VIN,
      "prop": "vehicle.vIN",
      "rules": _vm.rules.vIN
    }
  }, [_c('el-input', {
    staticClass: "w-full",
    model: {
      value: _vm.assetDetailsSummaryForm.vehicle.vIN,
      callback: function ($$v) {
        _vm.$set(_vm.assetDetailsSummaryForm.vehicle, "vIN", $$v);
      },
      expression: "assetDetailsSummaryForm.vehicle.vIN"
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_vm.assetDetailsSummaryForm.vehicle && _vm.assetDetailsSummaryForm.vehicle.assetType === _vm.LABEL_ASSET_TYPE_OPTION_VEHICLE ? _c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_DOCUMENT_REGISTRATION_PLATE,
      "prop": "vehicle.registrationPlate",
      "rules": _vm.rules.registrationPlate
    }
  }, [_c('el-input', {
    staticClass: "w-full",
    model: {
      value: _vm.assetDetailsSummaryForm.vehicle.registrationPlate,
      callback: function ($$v) {
        _vm.$set(_vm.assetDetailsSummaryForm.vehicle, "registrationPlate", $$v);
      },
      expression: "assetDetailsSummaryForm.vehicle.registrationPlate"
    }
  })], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c('el-form-item', {
    staticClass: "mb-0 border-t border-light-grey pt-5 child-w-full"
  }, [_c('el-button', {
    on: {
      "click": function ($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_CANCEL_BUTTON))]), _vm._v(" "), _c('el-button', {
    staticClass: "float-right",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm();
      }
    }
  }, [_vm._v("Update\n      ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }