import { set } from 'lodash'

export default {
  setNewReferralApplication(state, vrgs) {
    state.referralApplication = vrgs
  },
  setDocumentData(state, args) {
    state.referralApplication.finance.application.primaryApplicantData.documents.push(
      args
    )
  },
  setSelectedDocumentData(state, args) {
    state.referralApplication.finance.application.primaryApplicantData.selectedDocuments.push(
      args
    )
  },
  removeDocument(state, args) {
    const findDocInd =
      state.referralApplication.finance.application.primaryApplicantData.documents.findIndex(
        (doc) => doc.filename === args
      )

    if (findDocInd !== -1)
      state.referralApplication.finance.application.primaryApplicantData.documents.splice(
        findDocInd,
        1
      )
  },
  removeSelectedDocument(state, args) {
    const findDocInd =
      state.referralApplication.finance.application.primaryApplicantData.selectedDocuments.findIndex(
        (doc) => doc.name === args
      )

    if (findDocInd !== -1)
      state.referralApplication.finance.application.primaryApplicantData.selectedDocuments.splice(
        findDocInd,
        1
      )
  },
  updateDocumentData(state, args) {
    state.referralApplication.finance.application.primaryApplicantData.documents =
      args
  },
  updateSelectedDocumentData(state, args) {
    state.referralApplication.finance.application.primaryApplicantData.selectedDocuments =
      args
  },
  startTableLoading(state) {
    state.isTableLoading = true
  },
  setAvgNAFAndTotalCommission(state, { avgNAF = 0, totalCommission = 0 }) {
    state.avgNAF = avgNAF
    state.totalCommission = totalCommission
  },
  stopTableLoading(state) {
    state.isTableLoading = false
  },
  setTableSettings(
    state,
    { totalDocs = 0, page = 0, totalPages = 0, limit = 0, pagingCounter = 0 }
  ) {
    state.tableSettings.totalDocs = totalDocs
    state.tableSettings.page = page
    state.tableSettings.totalPages = totalPages
    state.tableSettings.limit = limit
    state.tableSettings.pagingCounter = pagingCounter
  },
  setSettlementList(state, args) {
    if (!state.settlementList.length) state.settlementList = args
    else state.settlementList = state.settlementList.concat(args)
  },
  reSetSettlementList(state, args) {
    state.settlementList = args
  },
  setReferralListData(state, args) {
    if (!state.referralListData.length) state.referralListData = args
    else state.referralListData = state.referralListData.concat(args)
  },
  resetReferralListData(state, args) {
    state.referralListData = args
  },
  updateReferralListData(state, args) {
    const { data, status } = args
    state.referralListData[status] = data
  },
  resetReferralListDataHasNextPage(state, args) {
    const { data, status } = args
    state.referralListData[status].hasNextPage = data
  },
  setReferralList(state, args) {
    if (!state.referralList.length) state.referralList = args
    else state.referralList = state.referralList.concat(args)
  },
  resetReferralList(state, args) {
    state.referralList = args
  },
  setProp(state, { key, value }) {
    set(state, key, value)
  },
  setLiabilitiesTotal(state, value) {
    const path = `referralApplication.finance.application.primaryApplicantData.liabilitiesTotal`
    set(state, path, value)
  },
  clearReferralList(state) {
    state.referralList = []
  },
  clearReferralListData(state) {
    state.referralListData = []
  },
  clearSettlementList(state) {
    state.settlementList = []
  },
}
