var render = function () {
  var _vm$applicantData, _vm$applicantData2, _vm$applicantData3, _vm$applicantData3$em, _vm$applicantData4, _vm$applicantData5, _vm$applicantData6, _vm$applicantData7, _vm$applicantData8, _vm$applicantData8$ad, _vm$applicantData9, _vm$applicantData10, _vm$applicantData11, _vm$applicantData12, _vm$applicantData14, _vm$applicantData15, _vm$applicantData16, _vm$applicantData18, _vm$applicantData19, _vm$applicantData20, _vm$applicantData22, _vm$sortCreditEnquiri, _vm$applicantData23, _vm$applicantData24, _vm$applicantData25, _vm$applicantData26, _vm$applicantData27, _vm$sortCreditEnquiri2, _vm$applicantData28, _vm$sortCreditEnquiri3, _vm$applicantData29;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-tag', {
    staticClass: "font-bolder text-sm pt-2 tag-cls"
  }, [_vm._v("\n    " + _vm._s(_vm.isprimary ? _vm.getFullName((_vm$applicantData = _vm.applicantData) === null || _vm$applicantData === void 0 ? void 0 : _vm$applicantData.primaryApplicant) || '--' : _vm.getFullName((_vm$applicantData2 = _vm.applicantData) === null || _vm$applicantData2 === void 0 ? void 0 : _vm$applicantData2.applicant) || '--') + "\n  ")]), _vm._v(" "), _c('el-row', {
    staticClass: "mt-5 center-icon"
  }, [_c('el-col', {
    staticClass: "flex",
    attrs: {
      "sm": 24,
      "lg": 2
    }
  }, [_c('div', {
    staticClass: "quote-details-icons"
  }, [_c('img', {
    staticStyle: {
      "height": "14px",
      "margin": "6px"
    },
    attrs: {
      "src": require("@/assets/icons/user-credit-score.svg")
    }
  })])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 20
    }
  }, [_c('span', {
    staticClass: "font-bolder"
  }, [_vm._v(_vm._s(((_vm$applicantData3 = _vm.applicantData) === null || _vm$applicantData3 === void 0 ? void 0 : (_vm$applicantData3$em = _vm$applicantData3.employments[0]) === null || _vm$applicantData3$em === void 0 ? void 0 : _vm$applicantData3$em.status) || '--'))]), _vm._v(" "), _c('p', {}, [_vm._v(_vm._s(_vm.totalMonths((_vm$applicantData4 = _vm.applicantData) === null || _vm$applicantData4 === void 0 ? void 0 : _vm$applicantData4.employments)) + "\n        " + _vm._s(_vm.totalMonths((_vm$applicantData5 = _vm.applicantData) === null || _vm$applicantData5 === void 0 ? void 0 : _vm$applicantData5.employments) > 1 && _vm.totalMonths((_vm$applicantData6 = _vm.applicantData) === null || _vm$applicantData6 === void 0 ? void 0 : _vm$applicantData6.employments) ? _vm.LABEL_FOR_MONTHS : _vm.LABEL_FOR_MONTH))])])], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-5 center-icon"
  }, [_c('el-col', {
    staticClass: "flex",
    attrs: {
      "sm": 24,
      "lg": 2
    }
  }, [_c('div', {
    staticClass: "quote-details-icons"
  }, [_c('img', {
    staticStyle: {
      "height": "14px",
      "margin": "6px"
    },
    attrs: {
      "src": require("@/assets/icons/residency.svg")
    }
  })])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 20
    }
  }, [_c('div', {
    staticClass: "font-bolder"
  }, [_vm._v("\n        " + _vm._s(((_vm$applicantData7 = _vm.applicantData) === null || _vm$applicantData7 === void 0 ? void 0 : _vm$applicantData7.residencyStatus) || '--'))])])], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-5 center-icon"
  }, [_c('el-col', {
    staticClass: "flex",
    attrs: {
      "sm": 24,
      "lg": 2
    }
  }, [_c('div', {
    staticClass: "quote-details-icons"
  }, [_c('img', {
    staticStyle: {
      "height": "14px",
      "margin": "6px"
    },
    attrs: {
      "src": require("@/assets/icons/home.svg")
    }
  })])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 20
    }
  }, [_c('span', {
    staticClass: "font-bolder"
  }, [_vm._v(_vm._s(((_vm$applicantData8 = _vm.applicantData) === null || _vm$applicantData8 === void 0 ? void 0 : (_vm$applicantData8$ad = _vm$applicantData8.addresses[0]) === null || _vm$applicantData8$ad === void 0 ? void 0 : _vm$applicantData8$ad.livingSituation) || '--'))]), _vm._v(" "), _c('p', {}, [_vm._v("\n        " + _vm._s(_vm.totalMonths((_vm$applicantData9 = _vm.applicantData) === null || _vm$applicantData9 === void 0 ? void 0 : _vm$applicantData9.addresses) || '0') + "\n        " + _vm._s(_vm.totalMonths((_vm$applicantData10 = _vm.applicantData) === null || _vm$applicantData10 === void 0 ? void 0 : _vm$applicantData10.addresses) > 1 && _vm.totalMonths((_vm$applicantData11 = _vm.applicantData) === null || _vm$applicantData11 === void 0 ? void 0 : _vm$applicantData11.addresses) ? _vm.LABEL_FOR_MONTHS : _vm.LABEL_FOR_MONTH))])])], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-5 center-icon"
  }, [_c('el-col', {
    staticClass: "flex",
    attrs: {
      "sm": 24,
      "lg": 2
    }
  }, [_c('div', {
    staticClass: "quote-details-icons"
  }, [_c('img', {
    staticClass: "m-1",
    staticStyle: {
      "height": "14px",
      "margin": "6px"
    },
    attrs: {
      "src": require("@/assets/icons/business.svg")
    }
  })])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 20
    }
  }, [_c('div', {
    staticClass: "font-bolder"
  }, [_vm._v(_vm._s(_vm.LABEL_CREDIT_SCORES))]), _vm._v(" "), _c('span', {
    staticClass: "mb-1"
  }, [_vm._v(" " + _vm._s(_vm.getCreditScoreFromApplicant()))]), _vm._v(" "), _c('span', {
    staticClass: "text-extra-small text-grey-dark"
  }, [_vm._v("\n        " + _vm._s(_vm.LABEL_FROM_APPLICANT))])])], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 center-icon"
  }, [_c('el-col', {
    staticClass: "flex",
    attrs: {
      "sm": 24,
      "lg": 2
    }
  }), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 20
    }
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_c('p', {
    staticClass: "mb-1 text-xs font-extrabold"
  }, [_vm._v("\n            " + _vm._s(_vm.LABEL_FOR_COMPREHENSIVE) + "\n          ")]), _vm._v(" "), _c('p', {
    staticClass: "text-sm"
  }, [_vm.sortCreditEnquiries((_vm$applicantData12 = _vm.applicantData) === null || _vm$applicantData12 === void 0 ? void 0 : _vm$applicantData12.creditEnquiries) ? _c('span', {
    staticClass: "text-primary font-extrabold mb-1"
  }, [_c('a', {
    staticClass: "cursor-pointer",
    on: {
      "click": function ($event) {
        var _vm$applicantData13;

        _vm.previewFile(_vm.sortCreditEnquiries((_vm$applicantData13 = _vm.applicantData) === null || _vm$applicantData13 === void 0 ? void 0 : _vm$applicantData13.creditEnquiries).creditFileUrl);
      }
    }
  }, [_vm._v("\n                " + _vm._s(_vm.sortCreditEnquiries((_vm$applicantData14 = _vm.applicantData) === null || _vm$applicantData14 === void 0 ? void 0 : _vm$applicantData14.creditEnquiries).creditScore) + "\n                (" + _vm._s(_vm.getCreditScore(_vm.sortCreditEnquiries((_vm$applicantData15 = _vm.applicantData) === null || _vm$applicantData15 === void 0 ? void 0 : _vm$applicantData15.creditEnquiries).creditScore)) + ")")])]) : _c('span', [_vm._v("--")])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_c('p', {
    staticClass: "mb-1 text-xs font-extrabold"
  }, [_vm._v("\n            " + _vm._s(_vm.LABEL_FOR_ONE_SCORE) + "\n          ")]), _vm._v(" "), _c('p', {
    staticClass: "text-sm"
  }, [_vm.sortCreditEnquiries((_vm$applicantData16 = _vm.applicantData) === null || _vm$applicantData16 === void 0 ? void 0 : _vm$applicantData16.creditEnquiries) ? _c('span', {
    staticClass: "text-primary font-extrabold mb-1"
  }, [_c('a', {
    staticClass: "cursor-pointer",
    on: {
      "click": function ($event) {
        var _vm$applicantData17;

        _vm.previewFile(_vm.sortCreditEnquiries((_vm$applicantData17 = _vm.applicantData) === null || _vm$applicantData17 === void 0 ? void 0 : _vm$applicantData17.creditEnquiries).creditFileUrl);
      }
    }
  }, [_vm._v("\n                " + _vm._s(_vm.sortCreditEnquiries((_vm$applicantData18 = _vm.applicantData) === null || _vm$applicantData18 === void 0 ? void 0 : _vm$applicantData18.creditEnquiries).oneScore) + "\n                (" + _vm._s(_vm.getCreditScore(_vm.sortCreditEnquiries((_vm$applicantData19 = _vm.applicantData) === null || _vm$applicantData19 === void 0 ? void 0 : _vm$applicantData19.creditEnquiries).oneScore)) + ")")])]) : _c('span', [_vm._v("--")])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_c('p', {
    staticClass: "mb-1 text-xs font-extrabold"
  }, [_vm._v(" " + _vm._s(_vm.LABEL_VEDA) + " ")]), _vm._v(" "), _c('p', {
    staticClass: "text-sm"
  }, [_vm.sortCreditEnquiries((_vm$applicantData20 = _vm.applicantData) === null || _vm$applicantData20 === void 0 ? void 0 : _vm$applicantData20.creditEnquiries) ? _c('span', {
    staticClass: "text-primary font-extrabold mb-1"
  }, [_c('a', {
    staticClass: "cursor-pointer",
    on: {
      "click": function ($event) {
        var _vm$applicantData21;

        _vm.previewFile(_vm.sortCreditEnquiries((_vm$applicantData21 = _vm.applicantData) === null || _vm$applicantData21 === void 0 ? void 0 : _vm$applicantData21.creditEnquiries).creditFileUrl);
      }
    }
  }, [_vm._v(_vm._s(_vm.sortCreditEnquiries((_vm$applicantData22 = _vm.applicantData) === null || _vm$applicantData22 === void 0 ? void 0 : _vm$applicantData22.creditEnquiries).vedaScore1Dot1))])]) : _c('span', [_vm._v("--")])])])], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 center-icon"
  }, [_c('el-col', {
    staticClass: "flex",
    attrs: {
      "sm": 24,
      "lg": 2
    }
  }), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 20
    }
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_c('p', {
    staticClass: "mb-1 text-xs font-extrabold"
  }, [_vm._v("\n            " + _vm._s(_vm.isConsumer ? _vm.LABEL_FILE_AGE : _vm.LABEL_CREDIT_FILE_AGE) + "\n          ")]), _vm._v(" "), _c('p', {
    staticClass: "text-sm"
  }, [_vm._v("\n            " + _vm._s(_vm.getFileAge((_vm$sortCreditEnquiri = _vm.sortCreditEnquiries((_vm$applicantData23 = _vm.applicantData) === null || _vm$applicantData23 === void 0 ? void 0 : _vm$applicantData23.creditEnquiries)) === null || _vm$sortCreditEnquiri === void 0 ? void 0 : _vm$sortCreditEnquiri.fileAge)) + "\n          ")])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_c('p', {
    staticClass: "mb-1 text-xs font-extrabold"
  }, [_vm._v("\n            " + _vm._s(_vm.isConsumer ? _vm.LABEL_ENQUIRIES : _vm.LABEL_CREDIT_ENQUIRIES) + "\n          ")]), _vm._v(" "), _vm.sortCreditEnquiries((_vm$applicantData24 = _vm.applicantData) === null || _vm$applicantData24 === void 0 ? void 0 : _vm$applicantData24.creditEnquiries) ? _c('p', {
    staticClass: "text-sm"
  }, [_vm._v("\n            " + _vm._s(_vm.sortCreditEnquiries((_vm$applicantData25 = _vm.applicantData) === null || _vm$applicantData25 === void 0 ? void 0 : _vm$applicantData25.creditEnquiries).creditEnquiriesNumber) + "\n          ")]) : _c('p', [_vm._v("--")])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_c('p', {
    staticClass: "mb-1 text-xs font-extrabold"
  }, [_vm._v(" " + _vm._s(_vm.LABEL_DEFAULTS) + " ")]), _vm._v(" "), _vm.sortCreditEnquiries((_vm$applicantData26 = _vm.applicantData) === null || _vm$applicantData26 === void 0 ? void 0 : _vm$applicantData26.creditEnquiries) ? _c('p', {
    staticClass: "text-sm"
  }, [_vm._v("\n            " + _vm._s(_vm.sortCreditEnquiries((_vm$applicantData27 = _vm.applicantData) === null || _vm$applicantData27 === void 0 ? void 0 : _vm$applicantData27.creditEnquiries).defaultsNumber) + "\n          ")]) : _c('p', [_vm._v("--")])])], 1)], 1)], 1), _vm._v(" "), _c('el-row', {
    staticClass: "mt-4 center-icon"
  }, [_c('el-col', {
    staticClass: "flex",
    attrs: {
      "sm": 24,
      "lg": 2
    }
  }), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 20
    }
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_c('p', {
    staticClass: "mb-1 text-xs font-extrabold"
  }, [_vm._v("\n            " + _vm._s(_vm.LABEL_RHI_STATUS) + "\n          ")]), _vm._v(" "), _c('p', {
    staticClass: "text-sm"
  }, [_vm._v("--")])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_c('p', {
    staticClass: "mb-1 text-xs font-extrabold"
  }, [_vm._v("\n            " + _vm._s(_vm.LABEL_LAST_ENQUIRY) + "\n          ")]), _vm._v(" "), (_vm$sortCreditEnquiri2 = _vm.sortCreditEnquiries((_vm$applicantData28 = _vm.applicantData) === null || _vm$applicantData28 === void 0 ? void 0 : _vm$applicantData28.creditEnquiries)) !== null && _vm$sortCreditEnquiri2 !== void 0 && _vm$sortCreditEnquiri2.createdAt ? _c('p', {
    staticClass: "text-sm"
  }, [_vm._v("\n            " + _vm._s(_vm.format(new Date((_vm$sortCreditEnquiri3 = _vm.sortCreditEnquiries((_vm$applicantData29 = _vm.applicantData) === null || _vm$applicantData29 === void 0 ? void 0 : _vm$applicantData29.creditEnquiries)) === null || _vm$sortCreditEnquiri3 === void 0 ? void 0 : _vm$sortCreditEnquiri3.createdAt), 'dd/MM/yyyy')) + "\n          ")]) : _c('p', [_vm._v("--")])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }