import {
  ERROR_MESSAGE_FOR_BUDGETTED_MONTHLY_REPAYMENT,
  ERROR_MESSAGE_FOR_LOAN_FEATURES,
  ERROR_MESSAGE_FOR_PURPOSE_OF_OBTAINING,
  ERROR_MESSAGE_PLEASE_SELECT_FIELD,
} from '~/constants'
import { validatePositiveNumber } from '~/helpers/validators'

export const REQUIREMENTS_RULES = {
  customerExpectedLoanFeatures: [
    {
      required: true,
      message: ERROR_MESSAGE_FOR_LOAN_FEATURES,
      trigger: ['blur', 'change'],
    },
  ],
  purposeOfObtainingIncreasingOrRefinanceCredit: [
    {
      required: true,
      message: ERROR_MESSAGE_FOR_PURPOSE_OF_OBTAINING,
      trigger: ['blur', 'change'],
    },
  ],
  monthlyRepaymentBudget: [
    {
      required: true,
      validator: (rule, value, callback) =>
        validatePositiveNumber(value, callback),
      type: 'number',
      message: ERROR_MESSAGE_FOR_BUDGETTED_MONTHLY_REPAYMENT,
      trigger: ['blur', 'change'],
    },
  ],
  isCreditRequiredForAdditionalCosts: [
    {
      required: true,
      message: ERROR_MESSAGE_PLEASE_SELECT_FIELD,
      trigger: ['blur', 'change'],
    },
  ],
  isAwareOfFinancingCosts: [
    {
      required: true,
      message: ERROR_MESSAGE_PLEASE_SELECT_FIELD,
      trigger: ['blur', 'change'],
    },
  ],
}
