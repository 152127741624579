import {
  CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_EXCELLENT,
  CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_AVERAGE,
  CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_FAIR,
  CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_EXCELLENT_MESSAGE,
  CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_AVERAGE_MESSAGE,
  CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_FAIR_MESSAGE,
  CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_POOR_MESSAGE,
} from '@/constants/applications'
import {
  ERROR_TYPE_NO_EXISTING_CREDIT_FILE_FOUND,
  ERROR_TYPE_CREDIT_FILE_NOT_FOUND,
  HEADER_MESSAGE_FOR_ERROR_TYPE_CREDIT_FILE_NOT_FOUND,
  ERROR_TYPE_OTHER,
  HEADER_MESSAGE_FOR_ERROR_TYPE_UNEXPECTED_ERROR,
  HEADER_MESSAGE_FOR_ERROR_TYPE_FILE_NOT_FOUND,
  ERROR_TYPE_FILE_BAN,
  HEADER_MESSAGE_FOR_ERROR_TYPE_FILE_BAN,
} from '~/constants'
// TODO: rename to more meaningful function name
export const handleCreditScore = (_creditScore = 0) => {
  const creditScore = Number(_creditScore)

  switch (!isNaN(creditScore)) {
    case creditScore >= CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_EXCELLENT:
      return CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_EXCELLENT_MESSAGE

    case creditScore >= CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_AVERAGE &&
      creditScore < CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_EXCELLENT:
      return CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_AVERAGE_MESSAGE

    case creditScore >= CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_FAIR &&
      creditScore < CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_AVERAGE:
      return CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_FAIR_MESSAGE

    case creditScore >= 0 &&
      creditScore < CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_FAIR:
      return CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_POOR_MESSAGE

    default:
      return CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_POOR_MESSAGE
  }
}

export const getLatestCreditEnquiry = (creditEnquiries, includeErrors) => {
  if (!creditEnquiries || !creditEnquiries?.length) {
    return
  }
  return [...creditEnquiries]
    .filter(({ isError }) => (includeErrors ? true : !isError))
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    )[0]
}
export const mapErrorType = (errorType) => {
  let displayError = '--'
  switch (errorType) {
    case ERROR_TYPE_NO_EXISTING_CREDIT_FILE_FOUND:
      displayError = HEADER_MESSAGE_FOR_ERROR_TYPE_FILE_NOT_FOUND
      break
    case ERROR_TYPE_CREDIT_FILE_NOT_FOUND:
      displayError = HEADER_MESSAGE_FOR_ERROR_TYPE_CREDIT_FILE_NOT_FOUND
      break
    case ERROR_TYPE_FILE_BAN:
      displayError = HEADER_MESSAGE_FOR_ERROR_TYPE_FILE_BAN
      break
    case ERROR_TYPE_OTHER:
      displayError = HEADER_MESSAGE_FOR_ERROR_TYPE_UNEXPECTED_ERROR
      break
  }
  return displayError
}
