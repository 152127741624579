import Vue from 'vue'
import TextInput from '~/components/shared/Inputs/TextInput'
import RadioInput from '~/components/shared/Inputs/RadioInput'
import AppSelect from '~/components/shared/Inputs/AppSelect'
import AppButton from '~/components/shared/Buttons/AppButton'
import AppIconButton from '~/components/shared/Buttons/AppIconButton'
import DropdownEditButton from '~/components/shared/Buttons/DropdownEditButton'
import AppDialog from '~/components/AppDialog'
import FormattedNumericInput from '~/components/FormattedNumericInput.vue'
import MixedInput from '~/components/shared/Inputs/MixedInput'

const components = {
  TextInput,
  AppButton,
  AppIconButton,
  RadioInput,
  AppSelect,
  DropdownEditButton,
  AppDialog,
  FormattedNumericInput,
  MixedInput,
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
