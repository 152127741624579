/**
 * Create an URL for generate the signed URL.
 * @param {*} urlString
 * @param {*} $auth
 * @returns
 */
export const getSignedURL = (urlString = '', $auth) => {
  const { pathname } = new URL(urlString)
  const token = $auth.strategy.token.get()
  return (
    process.env.VUE_APP_SERVER_URL +
    `/assets/fetch-file?path=${pathname.replace(/\//, '')}&token=${token}`
  )
}
