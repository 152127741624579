//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BusinessDetails from '~/components/BusinessDetails'
import BusinessAddress from '~/components/BusinessAddress.vue'
import Accountant from '~/components/Accountant.vue'
import CommonBenefieciaryShareHolder from '~/components/CommonBenefieciaryShareHolder.vue'
import {
  APPLICATIONS_MAINTAB,
  MAINTAB_APPLICANT_KEY,
  BUSINESS_DETAILS_TAB_KEY,
  MAINTAB_COMPLIANCE_KEY,
  MAINTAB_INCOME_KEY,
  MAINTAB_ASSETS_KEY,
  MAINTAB_CREDIT_KEY,
  MAINTAB_ASSET_DETAILS_KEY,
  MAINTAB_NOTES_KEY,
  APPLICATION_STATUS_SUBMITTED_TO_LENDER,
  APPLICATION_STATUS_APPROVED,
  SUBTAB_PRIMARY_PERSONAL_KEY,
  MAINTAB_REQUIRE_KEY,
  SUBTAB_SECONDAY_PERSONAL_KEY,
  MAINTAB_LOANDETAILS_KEY,
  MAINTAB_DOCS_KEY,
  MAINTAB_EXPENSES_KEY,
  SUBTAB_BUSINESS_DETAILS_LABEL,
  SUBTAB_SECONDAY_ADDRESS_LABEL,
  SUBTAB_BUSINESS_SHAREHOLDER_LABEL,
  SUBTAB_BUSINESS_ACCOUNTANT_LABEL,
  SUBTAB_BUSINESS_DETAILS_KEY,
  SUBTAB_BUSINESS_ACCOUNTANT_KEY,
  SUBTAB_BUSINESS_SHAREHOLDER_KEY,
  SUBTAB_PRIMARY_ADDRESS_KEY,
  SUBTAB_PRIMARY_EMPLOYMENT_KEY,
  LABEL_BUSINESS_STRUCTURE_COMPANY,
  MAINTAB_LOANDETAILS_SETTLEMENT_KEY,
  MAINTAB_LOANDETAILS_DECISION_KEY,
  MAINTAB_LOANDETAILS_SUBMISSION_LABEL,
  MAINTAB_LOANDETAILS_DECISION_LABEL,
  MAINTAB_LOANDETAILS_SETTLEMENT_LABEL,
  SUBTAB_BUSINESS_BENEFICIARIES_KEY,
  SUBTAB_BUSINESS_BENEFICIARIES_LABEL,
  MAINTAB_LOANDETAILS_SUBMISSION_KEY,
  SUBTAB_PRIMARY_ADDRESS_LABEL,
  LABEL_BUSINESS_STRUCTURE_TRUST,
  LABEL_BUSINESS_TRUSTEE_TYPE_INDIVIDUAL,
} from '@/constants/applications'

import { changeRoute } from '~/helpers/changeRoute'
export default {
  components: {
    BusinessDetails,
    BusinessAddress,
    Accountant,
    CommonBenefieciaryShareHolder,
  },
  props: {
    primaryData: {
      type: Object,
      default: () => ({}),
    },
    secondaryData: {
      type: Array,
      default: () => [],
    },
    sharedData: {
      type: Object,
      default: () => ({}),
    },
    accountData: {
      type: Array,
      default: () => [],
    },
    loanDetails: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      APPLICATIONS_MAINTAB,
      MAINTAB_APPLICANT_KEY,
      BUSINESS_DETAILS_TAB_KEY,
      MAINTAB_COMPLIANCE_KEY,
      MAINTAB_INCOME_KEY,
      MAINTAB_ASSETS_KEY,
      MAINTAB_CREDIT_KEY,
      MAINTAB_ASSET_DETAILS_KEY,
      MAINTAB_NOTES_KEY,
      APPLICATION_STATUS_SUBMITTED_TO_LENDER,
      APPLICATION_STATUS_APPROVED,
      SUBTAB_PRIMARY_PERSONAL_KEY,
      MAINTAB_REQUIRE_KEY,
      SUBTAB_SECONDAY_PERSONAL_KEY,
      MAINTAB_LOANDETAILS_KEY,
      MAINTAB_DOCS_KEY,
      MAINTAB_EXPENSES_KEY,
      SUBTAB_BUSINESS_DETAILS_LABEL,
      SUBTAB_SECONDAY_ADDRESS_LABEL,
      SUBTAB_BUSINESS_SHAREHOLDER_LABEL,
      SUBTAB_BUSINESS_ACCOUNTANT_LABEL,
      SUBTAB_BUSINESS_DETAILS_KEY,
      SUBTAB_BUSINESS_ACCOUNTANT_KEY,
      SUBTAB_BUSINESS_SHAREHOLDER_KEY,
      SUBTAB_PRIMARY_ADDRESS_KEY,
      SUBTAB_PRIMARY_EMPLOYMENT_KEY,
      LABEL_BUSINESS_STRUCTURE_COMPANY,
      MAINTAB_LOANDETAILS_SETTLEMENT_KEY,
      MAINTAB_LOANDETAILS_DECISION_KEY,
      MAINTAB_LOANDETAILS_SUBMISSION_LABEL,
      MAINTAB_LOANDETAILS_DECISION_LABEL,
      MAINTAB_LOANDETAILS_SETTLEMENT_LABEL,
      SUBTAB_BUSINESS_BENEFICIARIES_KEY,
      SUBTAB_BUSINESS_BENEFICIARIES_LABEL,
      MAINTAB_LOANDETAILS_SUBMISSION_KEY,
      SUBTAB_PRIMARY_ADDRESS_LABEL,
      activeMainTab: BUSINESS_DETAILS_TAB_KEY,
      activeSubTab: SUBTAB_BUSINESS_DETAILS_KEY,
    }
  },

  mounted() {
    this.$nuxt.$on('updateBusinessDetailsTabComponent', (val) => {
      if (val.includes('|')) {
        this.activeMainTab = val.split('|')[0]
        this.activeSubTab = val.split('|')[1]
      } else {
        this.activeMainTab = val
      }
    })
    this.$nuxt.$on('updateBusinessDetailsSubTabSubComponent', (val) => {
      this.activeMainTab = val.split('|')[0]
      this.activeSecondSubTab = val.split('|')[1]
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('updateBusinessDetailsTabComponent')
    this.$nuxt.$off('updateBusinessDetailsSubTabSubComponent')
  },
  computed: {
    isCompanyStructure() {
      return (
        this.sharedData?.businessData?.businessStructure ===
          LABEL_BUSINESS_STRUCTURE_COMPANY ||
        this.sharedData?.businessData?.trusteeType ===
          LABEL_BUSINESS_STRUCTURE_COMPANY
      )
    },
    showBeneficiaries() {
      return (
        this.sharedData?.businessData?.businessStructure ===
          LABEL_BUSINESS_STRUCTURE_TRUST &&
        (this.sharedData?.businessData?.trusteeType ===
          LABEL_BUSINESS_TRUSTEE_TYPE_INDIVIDUAL ||
          this.sharedData?.businessData?.trusteeType ===
            LABEL_BUSINESS_STRUCTURE_COMPANY)
      )
    },
  },

  methods: {
    getBusinessDefaltActive() {
      return this.activeSubTab === SUBTAB_BUSINESS_DETAILS_KEY
        ? '0'
        : this.activeSubTab === SUBTAB_PRIMARY_ADDRESS_KEY
        ? '1'
        : this.activeSubTab === SUBTAB_BUSINESS_SHAREHOLDER_KEY
        ? '3'
        : this.activeSubTab === SUBTAB_BUSINESS_ACCOUNTANT_KEY
        ? '4'
        : this.activeSubTab === SUBTAB_BUSINESS_BENEFICIARIES_KEY
        ? '2'
        : '0'
    },
    async sectionRedirect(subTabName) {
      this.activeSubTab = subTabName
      const alreadySameTab = this.$route.query.subtab
      if (alreadySameTab !== subTabName) {
        const query = { ...this.$route.query, subtab: subTabName }
        await changeRoute({
          route: this.$route,
          router: this.$router,
          query,
        })
      }
      return
    },
  },
}
