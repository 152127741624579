var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-main', {
    staticClass: "bg-white"
  }, [_c('el-row', {
    attrs: {
      "type": "flex",
      "justify": "center"
    }
  }, [_c('el-col', {
    attrs: {
      "xs": 24,
      "sm": 12,
      "md": 12,
      "lg": 8,
      "xl": 6
    }
  }, [_c('div', {
    staticClass: "card-container authentication-wrapper"
  }, [_c('div', [_c('h1', {
    staticClass: "text-center mb-8"
  }, [_vm._v("Login with your details")]), _vm._v(" "), _c('div', [_c('el-form', {
    ref: "emailloginForm",
    staticClass: "card-view-form",
    attrs: {
      "model": _vm.loginData,
      "rules": _vm.rules,
      "hide-required-asterisk": ""
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "email",
      "label": "Email"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "name@domain.com",
      "data-selector": "email-input"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.authenticationEmailPass();
      }
    },
    model: {
      value: _vm.loginData.email,
      callback: function ($$v) {
        _vm.$set(_vm.loginData, "email", $$v);
      },
      expression: "loginData.email"
    }
  })], 1), _vm._v(" "), _c('el-form-item', {
    staticClass: "type-password",
    attrs: {
      "label": "Password",
      "prop": "password"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Enter Password",
      "data-selector": "password-input",
      "type": _vm.passwordType,
      "autocomplete": "off"
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.authenticationEmailPass();
      }
    },
    model: {
      value: _vm.loginData.password,
      callback: function ($$v) {
        _vm.$set(_vm.loginData, "password", $$v);
      },
      expression: "loginData.password"
    }
  }), _vm._v(" "), _c('el-link', {
    attrs: {
      "underline": false,
      "data-selector": "show-password"
    },
    on: {
      "click": _vm.togglePassword
    }
  }, [_vm.passwordType == 'password' ? _c('img', {
    attrs: {
      "src": require("../assets/icons/show-password.svg"),
      "width": "14",
      "height": "14",
      "alt": "Show Password"
    }
  }) : _c('img', {
    attrs: {
      "src": require("../assets/icons/hide-password.svg"),
      "width": "14",
      "height": "14",
      "alt": "Hide Password"
    }
  })])], 1), _vm._v(" "), _c('el-button', {
    staticClass: "button-md",
    attrs: {
      "type": "primary",
      "loading": _vm.isRequestCodeLoading,
      "disabled": _vm.codeRequested,
      "data-selector": "login-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.authenticationEmailPass();
      }
    }
  }, [_vm._v("\n                Login\n              ")]), _vm._v(" "), _c('div', {
    staticClass: "text-center"
  }, [_c('nuxt-link', {
    staticClass: "text-sm link-primary my-8 font-extrabold",
    attrs: {
      "to": "/email-password-reset",
      "data-selector": "forgot-password"
    }
  }, [_vm._v("Forgot password?")]), _vm._v(" "), _c('p', {
    staticClass: "text-sm mb-2 text-light-black"
  }, [_vm._v("For any technical assistance")]), _vm._v(" "), _c('a', {
    staticClass: "text-sm text-primary font-extrabold",
    attrs: {
      "href": ("mailto:tech-support@carclarity.com.au?subject=" + (_vm.LABEL_FOR_CONTACT_SUPPORT_FOR_FUEL)),
      "data-selector": "contact-support",
      "target": "_blank"
    }
  }, [_vm._v("\n                  Contact Support\n                ")])], 1)], 1)], 1)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }